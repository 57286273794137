import React, {useEffect, useRef, useState} from 'react'
import T from "../i18n";
import Heading from "react-bulma-components/lib/components/heading";
import { LocalIcon } from 'whitelables/wl-name/icons'

import Button from "react-bulma-components/lib/components/button";
import {useIsMobile} from "../is-mobile-context/is-mobile-context";

const FixedContainerFilter = ({show, setShow, hiddenFilter}) => {
	const containerRef = useRef(null);
	const [touchStartX, setTouchStartX] = useState(null);

	const {isMobileSize} = useIsMobile()

	const handleTouchStart = (event) => {
		setTouchStartX(event.touches[0].clientX);
	};

	const handleTouchMove = (event) => {
		if (touchStartX === null) {
			return;
		}

		const touchEndX = event.touches[0].clientX;
		const deltaX = touchEndX - touchStartX;

		if (deltaX > 70) {
			setShow();
			setTouchStartX(null);
		}
	};

	useEffect(() => {
		if(!isMobileSize) {
			const handleClickOutside = (event) => {
				if (containerRef.current && !containerRef.current.contains(event?.target) && show) {
					if(typeof event.target.className === 'string' && !event?.target?.className?.includes('mySelect')
						&& (typeof event.target.parentElement.className === 'string' && !event.target.parentElement.className.includes('mySelect'))
					) {
						setShow();
					}
				}
			}

			document.addEventListener('click', handleClickOutside);

			return () => {
				document.removeEventListener('click', handleClickOutside);
			};
		}
	}, [containerRef, show, setShow, isMobileSize]);

	return (
		<div
			ref={containerRef}
			onTouchStart={isMobileSize ? handleTouchStart : () => {}}
			onTouchMove={isMobileSize ? handleTouchMove : () => {}}
			className={`fixed-filter ${show ? 'shadow-box' : ''}`}
			style={{transform: `translateX(${show ? '-320px' : '0'})`}}
		>
				<Heading size={4} onClick={setShow} className='filter-header'>
					<LocalIcon icon="left-arrow" size="small"/>
					<span>
						{T('filter')}
					</span>
				</Heading>
				<hr/>
			<div className='wrapper-hidden-filter'>
				{hiddenFilter}
			</div>
			<div className='filter-button-close'>
				<Button size='large' fullwidth color='info' onClick={setShow}>
					{T('close')}
				</Button>
			</div>
		</div>
	);
};

export default FixedContainerFilter;