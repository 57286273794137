import React from "react";
import InfoWrapper from "../../../../components/info-wrapper";
import T from "../../../../components/i18n";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import { LocalIcon } from "whitelables/wl-name/icons";

const GeneralRowContent = ({ data, groupDate, onToggleOpen, isOpen }) => {
  let labelDate = data.label;

  if (groupDate === "days") {
    labelDate = new Date(parseInt(data.label)).toLocaleDateString();
  }

  const handleToggleOpen = (evt) => {
    evt.stopPropagation();
    if (onToggleOpen) {
      onToggleOpen();
    }
  };

  // const allExpenses =
  //   (data.amountExpenses || 0) +
  //   (data.shipmentExpenses || 0) +
  //   (data.expensesProfit || 0);
  //
  // const roi = (((data.profit || 0) - allExpenses) / (allExpenses || 1)) * 100;
  return (
    <tr onClick={handleToggleOpen} className={isOpen ? "selected-border" : ""}>
      <td>
        {onToggleOpen && (
          <div
            className={`display-flex-center black icon-open ${
              isOpen ? "active" : ""
            }`}
          >
            <LocalIcon icon="arrow-left" size="small" />
          </div>
        )}
      </td>

      <td>{data?.name || labelDate}</td>
      <td>
        <div>
            <div>
              <span className="silver">
                {T("sales-amounts")}({T("sales-amounts-revenue")}):{" "}
              </span>
              <InfoWrapper info={T("sales-profit-tip")}>
                <b>
                  <Money
                    signClassname
                    amount={data?.amount_sale || 0}
                    wrapper={DOM.span}
                  />
                </b>
              </InfoWrapper>
            </div>
        </div>
        <div>

            <div>
              <span className="silver">
                {T("dashboard-sales-profit")} ({T("gross-profit")}):{" "}
              </span>
              <InfoWrapper info={T("net-profit-tip")}>
                <b>
                  <Money
                    signClassname
                    amount={(data?.profit || 0) - (data?.expensesProfit || 0)}
                    wrapper={DOM.span}
                  />
                </b>
              </InfoWrapper>
            </div>
        </div>
        <div>
          <span className="silver">{T("other_replenish")}: </span>
          <b>
            <Money amount={data.receipt} wrapper={DOM.span} signClassname />
          </b>
        </div>
        <div>
          <span className="silver">{T("total-sales")}: </span>
          <b>{T("_orders", { count: data?.count || 0 })}</b>
        </div>
        <div>
          <span className="silver">{T("sold-units")}: </span>
          <b>
            {data?.quantity || 0} {T("items-e")}
          </b>
        </div>
        <div>
            <div>
              <span className="silver">{T("average-check")}: </span>
              <InfoWrapper info={T("average-receipt-tip")}>
                <b>
                  <Money
                    signClassName
                    amount={data?.averageCheck || 0}
                    wrapper={DOM.span}
                  />
                </b>
              </InfoWrapper>
            </div>
        </div>
        <div>
            <div>
              <span className="silver">{T("average-margin")}: </span>
              <InfoWrapper info={T("average-margin-tip")}>
                <b>{data?.margin || 0}%</b>
              </InfoWrapper>
            </div>
        </div>
      </td>
      <td>
        <div>
          <span className="silver">{T("expenses-shipment")}: </span>
          <b>
            <Money
              amount={data?.shipmentExpenses || 0}
              wrapper={DOM.span}
              className="has-text-danger"
            />
          </b>
        </div>
        <div>
          {!!data?.expensesProfit && (
            <InfoWrapper info={T("these-expenses-are-deducted-tip")}>
              <span className="silver">{T("expenses-profit-sales")}: </span>
              <b>
                <Money
                  amount={data?.expensesProfit || 0}
                  wrapper={DOM.span}
                  className="has-text-danger"
                />
              </b>
            </InfoWrapper>
          )}
        </div>
        <div>
          <span className="silver">{T("expenses-without-shipment")}: </span>
          <b>
            <Money
              amount={data?.amountExpenses || 0}
              wrapper={DOM.span}
              className="has-text-danger"
            />
          </b>
        </div>
        {!!data?.notCalcExpenses && (
          <div>
            <span className="silver">{T("expenses-not-deducted")}: </span>
            <InfoWrapper
              info={T('expenses-cost-of-sales-tip')}>
              <b>
                <Money amount={data?.notCalcExpenses || 0} wrapper={DOM.span} />
              </b>
            </InfoWrapper>
          </div>
        )}
      </td>
      <td>
        <div>
          <span className="silver">{T("expected_goods_worth")}: </span>
          <b>
            <Money amount={data.pendingAmount || 0} wrapper={DOM.span} />
          </b>
        </div>
        <div>
          <span className="silver">{T("received_goods_worth")}: </span>
          <b>
            <Money
              className={"has-text-success"}
              amount={data.completeAmount || 0}
              wrapper={DOM.span}
            />
          </b>
        </div>
        <div>
          <span className="silver">{T("not-paid")}: </span>
          <b>
            <Money
              className="has-text-danger"
              amount={
                (data.pendingAmount || 0) +
                (data.completeAmount || 0) -
                (data.paidAmount || 0)
              }
              wrapper={DOM.span}
            />
          </b>
        </div>
      </td>
      <td>
        <div>
          <span className="silver">{T("total-funds-received")}: </span>
          <b>
            <Money
              amount={(data.amount_sale || 0) + (data.receipt || 0)}
              signClassname
              wrapper={DOM.span}
            />
          </b>
        </div>
        <div>
          <span className="silver">{T("sales-profit")}: </span>
          <InfoWrapper info="income-from-sales-other-expenses-without-shipments">
            <b>
              <Money
                amount={
                  (data.profit || 0) +
                  (data.receipt || 0) -
                  (data.expensesProfit || 0) -
                  (data.amountExpenses || 0)
                }
                signClassname
                wrapper={DOM.span}
              />
            </b>
          </InfoWrapper>
        </div>
        <div>
          <span className="silver">{T("operating-profit")}: </span>
          <InfoWrapper info='total-funds-received-minus-expenditures'>
            <b>
              <Money
                amount={
                  (data.amount_sale || 0) +
                  (data.receipt || 0) -
                  (data.expensesProfit || 0) -
                  (data.amountExpenses || 0) -
                  (data.shipmentExpenses || 0) -
                  (data.expensesDeliveryShipment || 0)
                }
                signClassname
                wrapper={DOM.span}
              />
            </b>
          </InfoWrapper>
        </div>
        {/*<div>*/}
        {/*  <span className="silver">ROI: </span>*/}
        {/*  {Math.round(roi * 100) / 100 || 0}%*/}
        {/*</div>*/}
      </td>
    </tr>
  );
};

export default GeneralRowContent;
