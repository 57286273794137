import React, { useEffect } from "react";
import Box from "react-bulma-components/lib/components/box";
import { Button } from "react-bulma-components";
import Reports from "../../../../components/reports";
import RowDesktop from "./row-desktop";
import T from "../../../../components/i18n";
import AppStore from "../../../../app-store";
import AddMinBalance from "./modal/add-min-balance/add-min-balance";
import UserStore from "../../../../user/user-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductsActions from "../../../products/products-actions";
import RowMobile from "./row-mobile";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const EmptyMessage = () => {
  return <Box>{T("not-have-history")}</Box>;
};

const COLUMNS = ["name", "min-balance-products", "reports-actions"];

const MinBalanceProduct = () => {
  const {isMobileSize} = useIsMobile()
  const getMinStocks = UserStore.getMinStockProduct();

  const generateIgnoreValue =
    getMinStocks &&
    getMinStocks.reduce(
      (acc, cur) => {
        if (acc[cur?.type] && cur?.ids && cur?.type !== "general") {
          acc[cur.type] = [...acc[cur.type], ...cur.ids];
        } else if (cur?.type === "general") {
          acc[cur.type] = true;
        }
        return acc;
      },
      {
        category: [],
        brand: [],
        warehouse: [],
        general: false,
      }
    );

  useEffect(() => {
    ProductsActions.loadBrand();
    ProductsActions.loadCategory();
  }, []);

  return (
    <>
      <div align="right" className="margin-top-10 margin-bottom-10">
        <Button
          color="info"
          onClick={() =>
            AppStore.openModal(
              <AddMinBalance
                modal="min-balance"
                ignoreValuesList={generateIgnoreValue}
              />
            )
          }
          className="display-flex-row"
        >
          <FontAwesomeIcon icon="plus-circle" />
          {T("add-min-balance-products")}
        </Button>
      </div>
      <Reports
        emptyMessage={EmptyMessage}
        rows={getMinStocks}
        columns={COLUMNS}
      >
        {isMobileSize ? <RowMobile role="row" /> : <RowDesktop role="row" />}
      </Reports>
    </>
  );
};

export default MinBalanceProduct;
