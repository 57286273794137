import React, { useState} from 'react'
import {
	Control,
	Field,
	Label, Textarea
} from "react-bulma-components/lib/components/form";
import T from "../i18n";

const CommentField = ({comment, placeholder, onChange}) => {
	const [value, setValue] = useState(comment || '')

	const handleChange = (evt) => {
		const value = evt.target.value
		setValue(value)
		onChange(value)
	}

	return (
		<Field>
			<Label>{T('comment')}</Label>
			<Control>
				<Textarea  size="small"
									 name="comment"
									 type="text"
									 placeholder={T(placeholder) || T("comment")}
									 onChange={handleChange}
									 value={comment || value}
				/>
			</Control>
		</Field>)
};

export default CommentField;