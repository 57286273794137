import React from 'react'
import {Input, Label} from "react-bulma-components/lib/components/form";
import T from '../../../../components/i18n'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NumberInput from "../../../../components/number-input/number-input";
import InfoWrapper from "../../../../components/info-wrapper";

const SocialInput = ({name, label, value, index, disabledDelete, type, info, onChangeValue, onDeleteSocial}) => {

	const handleChange = ({target: {value}}) => {
		onChangeValue(name, index, value);
	}
	return (
		<div>
			{info ? (
				<div style={{marginBottom: "0.5em"}}>
					<InfoWrapper info={info}>
						<Label>{T(label)}{index >= 1 ? ` #${index + 1}` : ''}</Label>
					</InfoWrapper>
				</div>
			) : (
				<Label>{T(label)}{index >= 1 ? ` #${index + 1}` : ''}</Label>
			)}
			<div className='display-flex-row'>
				{type === 'number' ? (
					<NumberInput name={name} value={value} onChange={handleChange}/>
				) : (
					<Input name={name} value={value} onChange={handleChange} type={type}/>
				)}
				<span style={{width: 13}}>
					{disabledDelete && <FontAwesomeIcon icon='minus-circle' className='text-danger cursor-pointer' onClick={onDeleteSocial}/>}
				</span>
			</div>
		</div>
	);
};

export default SocialInput;