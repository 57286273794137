import React from 'react'
import { LocalIcon } from 'whitelables/wl-name/icons'
import List from "react-bulma-components/lib/components/list";
import {Field} from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import {Link} from "react-router-dom";
import AppStore from "../../../../app-store";
import PriceTagSettingPrintModal from "./price-tag-setting-print-modal";

const SettingCustom = ({data, onSubmit}) => {
	return (
		<List>
			<List.Item>
				<Field className='display-flex-row-gap'>
					{T('price-tag-setting-print')}
					<Link
						className='link-icon-centred'
						to='#'
						onClick={() => AppStore.openModal(<PriceTagSettingPrintModal onSubmit={onSubmit} data={data}/>)}
					>
						<LocalIcon size='small' icon='gear'/>
					</Link>
				</Field>
			</List.Item>
		</List>
	);
};

export default SettingCustom;