import React, { useEffect, useState } from "react";
import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../i18n";
import List from "react-bulma-components/lib/components/list";
import SelectedItem from "./selected-item";
import Button from "react-bulma-components/lib/components/button";
import PickItem from "./pick-item";
import UserActions from "../../../user/user-actions";
import {
  findNonUniquePositions,
  getLabelsWithMissingPositions,
} from "../utils";
import ErrorMessageControlHook from "../../error-message-block/error-message-control.hook";
import SubmitButton from "../../submit-button";

const CustomizeColumnsModal = ({
  customizeColumns,
  customizeColumnsActive = {},
  columnsName,
  withButtonReset,
  onClose,
}) => {
  const {
    errorMessage,
    handleAutoRemoveError,
    ErrorMessageBlock,
    setErrorMessage,
  } = ErrorMessageControlHook(10000);
  // const [waiting, setWaiting] = useState(false)

  const globalItem = customizeColumns.reduce((acc, curr) => {
    if (!!curr.is_global) {
      acc[curr.label] = {
        label: curr.label,
        is_global: true,
        is_active: true,
        position: curr.position,
        id: curr?.id || null
      };
    }

    return acc;
  }, {});

  const defaultColumn = !!Object.keys(customizeColumnsActive).length
    ? { ...globalItem, ...customizeColumnsActive }
    : customizeColumns.reduce((acc, cur) => {
        acc[cur.label] = cur;
        return acc;
      }, {});

  const [activeColumn, setActiveColumn] = useState({
    ...defaultColumn,
  });

  const handleChangeActive = ({ name, value }) => {
    setActiveColumn((prev) => ({ ...prev, [name]: value }));
  };

  const handleDeleteItem = (name) => {
    setActiveColumn((prev) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          is_active: false,
        },
      };
    });
  };

  const handleChangePosition = ({ name, value }) => {
    setActiveColumn((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        position: parseInt(value),
      },
    }));
  };

  const handleRemoveAll = () => {
    setActiveColumn(globalItem);
  };
  const handleSelectAll = () => {
    const items = customizeColumns.reduce((acc, cur) => {
      acc[cur.label] = {
        ...cur,
        position: activeColumn[cur.label]?.position || cur?.position,
        is_active: true,
      };
      return acc;
    }, {});
    setActiveColumn(items);
  };

  const handleSubmit = () => {
    if (columnsName) {
      const checkUniquePosition = findNonUniquePositions(activeColumn);
      const checkValue = getLabelsWithMissingPositions(activeColumn);

      if (!!checkValue.length) {
        handleAutoRemoveError(
          T("error-message-position-value", {
            field: checkValue.map((item) => T(item)).join(", "),
          })
        );
      } else if (checkUniquePosition) {
        handleAutoRemoveError(checkUniquePosition);
      } else {
        UserActions.setCustomColumns(columnsName, activeColumn);
      }
    }
  };

  useEffect(() => {
    const addCompleted = UserActions.setCustomColumns.completed.listen(() => {
      onClose();
    });

    return () => {
      addCompleted();
    };
  }, [onClose]);

  const handleReset = () => {
    if(withButtonReset) {
      UserActions.deleteCustomColumns(columnsName);
      onClose()
    }
  }

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box className="wrapper">
        <Heading size={3}>{T("customize-columns")}</Heading>
        <Columns>
          <Columns.Column size={7} className="pick-column">
            <div>
              <Button
                className="button-link-without-style"
                onClick={handleSelectAll}
              >
                {T("select-all")}
              </Button>
            </div>
            <div className="customize-pick-list">
              {customizeColumns.map(
                (item,index) =>
                  !item?.is_global && (
                    <PickItem
                      key={`custom-${index}-${item.label}-${item?.id}`}
                      label={item.label}
                      position={activeColumn[item.label]?.position}
                      defaultPosition={item?.position}
                      isActive={activeColumn[item.label]?.is_active}
                      id={item?.id}
                      onChange={handleChangeActive}
                      onChangePosition={handleChangePosition}
                    />
                  )
              )}
            </div>
          </Columns.Column>
          <Columns.Column size={5} className="selected-column">
            <div
              className="display-flex-row-gap"
              style={{ alignItems: "center" }}
            >
              <span className="selected">
                {Object.keys(activeColumn).reduce((acc, curr) => {
                  if (activeColumn[curr].is_active) acc += 1;
                  return acc;
                }, 0)}{" "}
                {T("selected")}
              </span>
              <Button
                className="button-link-without-style"
                onClick={handleRemoveAll}
              >
                {T("remove-all")}
              </Button>
            </div>
            <List className="customize-selected-list">
              {Object.keys(activeColumn).map(
                (item) =>
                  activeColumn[item]?.is_active && (
                    <SelectedItem
                      key={item}
                      label={item}
                      is_global={activeColumn[item]?.is_global}
                      onDelete={handleDeleteItem}
                    />
                  )
              )}
            </List>
          </Columns.Column>
        </Columns>
        <div className='display-flex-row-gap'>
          <div style={{flex: 1}}>
            {withButtonReset && <Button color="warning" size="small" rounded onClick={handleReset}>
              {T("reset-to-default-columns")}
            </Button>}
          </div>
          <div className='display-flex-row'>
            <Button color="light" size="small" rounded onClick={onClose}>
              {T("close")}
            </Button>
            <SubmitButton
              color="info"
              size="small"
              rounded
              submit={handleSubmit}
              fullwidth={false}
              text={T("save-btn-label")}
            />
          </div>
        </div>
      </Box>
    </>
  );
};

export default CustomizeColumnsModal;
