import React from 'react'
import Level from "react-bulma-components/lib/components/level";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import ChangeAccount from "../../change-account";

const CodButton = ({accountID, amount, orderID}) => {
	return (
		<Level.Item>
			<OpenInsideModal size={"small"} color="success" icon="money-bill-alt" text={'pick-up-transfer'}>
				<ChangeAccount
					fromAccount={{'value': accountID}}
					amount={amount}
					complete={true}
					oid={orderID} />
			</OpenInsideModal>
		</Level.Item>
	);
};

export default CodButton;