import React from 'react'
import {
	Control, Field,
	Input,
	Label
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import Button from "react-bulma-components/lib/components/button";
import {generateKey} from "../../../../../tools/genarete-key";

const ApiKeyInputWithGenerate = ({value, isActive, lengthKey, onClickGenerateKey}) => {
	return (
		<Field>
			<Control>
				<Label>{T("api-key-integration-label")}</Label>
				<Input  size="large"
								name="api_key"
								type="text"
								placeholder={T('api-key-integration-label')}
								autoComplete="nope"
								onChange={() => {}}
								value={value}
				/>
				{!isActive && <div align="right">
					<Button onClick={() => onClickGenerateKey(generateKey(lengthKey || 32)) }  className="button_link sml">{T('api-key-integration-generate-key')}</Button>
				</div>}
			</Control>
		</Field>
	);
};

export default ApiKeyInputWithGenerate;