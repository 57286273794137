import React, { Component } from "react";
import { connectToStores } from "tools/reflux-tools";

import T from "components/i18n";
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import Columns from 'react-bulma-components/lib/components/columns';
import List from 'react-bulma-components/lib/components/list';
import Level from 'react-bulma-components/lib/components/level';

import Messages from 'components/messages';

import OpenModalButton from "components/modal/open-modal-button";

import Actions from 'user/user-actions';
import UserStore from 'user/user-store';
import WarehouseStore from 'modules/warehouse/warehouse-store';
import ExpensesStore from 'modules/expenses/expenses-store';
    
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import AccessDenied from "../../dashboard/access_denied"; // Import css
import 'react-confirm-alert/src/react-confirm-alert.css';
import OrdersStore from "../../orders/orders-store";
import {Button} from "react-bulma-components";
import {parseJSON} from "../../../tools/error-handler";
import yajax from "yajax";
import {confirmDelete} from "../../../tools/confirm-delete"; // Import css

class PermissionTab extends Component {
	constructor(props) {
        super(props);
        this.state = {
          message: null,
          users: []
        };

    }


    componentDidMount() {
        Actions.getSubUsers();

        this.getSubCompl = Actions.getSubUsers.completed.listen((data) => {
            this.setState({
              'users': data['users'] ? data['users'] : []
            })
        });
    }

    componentWillUnmount() {
      if (typeof(this.getSubCompl) == "function") {
        this.getSubCompl();
      }
    }

  delete(type, _id) {
        confirmAlert({
          title: T('confirm-delete'),
          message: T('are-you-sure-to-delete-this-catalog'),
          buttons: [
            {
              label: T('delete'),
              onClick: () => Actions.deleteCatalog(type, _id)
            },
            {
              label: T('no-delete'),
              onClick: () => {}
            }
          ]
        });
    }

    getPermissions(data, type) {
        let res = [];

        for (let perm in data) {
            if (data[perm]) {
                if (perm !== "cinstock" && perm !== "warehouse") {
                    res.push(T(perm));
                }
            }
        }
        type === "modules" ? res.sort() : res.sort((a, b) => b.localeCompare(a))
        return res.join(", ");
    }

    getWarehouses(data) {

        if (!data || data === "0" || data === "[0]") {
            return T('all');
        }

        let res = [];

        data = JSON.parse(data);
        for (let w in data) {
            let wid = data[w]
            res.push(WarehouseStore.getWarehouseName(wid))
        }

        return res.join(", ");
    } 

    getAccounts(data) {

        if (!data || data === "0" || data === "[0]") {
            return T('all');
        }

        let res = [];
        data = JSON.parse(data);
        for (let w in data) {
            let aid = data[w]
            res.push(ExpensesStore.getAccountName(aid))
        }

        return res.join(", ");
    }

    deactivateHandle(user) {
      const data = {
        "email": user.email,
        "name": user.name,
        "password": '',
        "phone": user.phone,
        "lang": UserStore.getLang(),
        "group": user.group,
        "settings": JSON.stringify({
          modules: user.modules,
          permissions: user.permissions,
          more_setting: user.more_setting
        }),
        "user_id": user.user_id,
      }

      if(user.warehouse) {
        data.warehouse = JSON.stringify(parseJSON(user.warehouse).map(item => ({value: item})))
      }
      if(user.accounts) {
        data.accounts = JSON.stringify(parseJSON(user.accounts).map(item => ({value: item})))
      }

      if(user.is_active) {
        data.is_active = 0
      } else  {
        data.is_active = 1
      }

      yajax.post("/userprofile/personnel", data).then(() => Actions.getSubUsers())
    }

    confirmDeleteSubUser(user) {
      if(user.is_active) {
        confirmDelete(
          () => this.deactivateHandle(user),
          'confirm-deactivation',
          false,
          'deactivate-btn',
          'undo')
      } else {
        confirmDelete(
          () => this.deactivateHandle(user),
          'confirm-activate',
          false,
          'btn-active',
          'undo')

      }
    }

    getUserList() {
        const data = this.state.users;

        data.sort((a,b) => b.is_active - a.is_active)

        let response = [];

        for (let i in data) {
            const row = data[i];
          const statusesValue = OrdersStore.getStatusesValueLabelByIds(row?.more_setting?.statuses, 'label')
          const customPriceValue = UserStore.getCustomPriceValueLabelByIds(row?.more_setting?.custom_prices, 'label')
          response.push (
            <List.Item key={row['user_id']} className={row.is_active ? '' : 'has-background-light-black'}>
                <Columns>
                    <Columns.Column >
                        <b>{row['name']}</b> <br /> {row['email']} <br />
                        {this.getPermissions(row['modules'], 'modules')}
                    </Columns.Column>
                    <Columns.Column>
                        {UserStore.getGroupName(row['group'])}
                        <div><b>{T('Warehouse')}</b>: {this.getWarehouses(row['warehouse'])}</div>
                        <div><b>{T('sale-account')}</b>: {this.getAccounts(row['accounts'])}</div>
                      {!!row?.modules?.sales && <div>
                        <b>{T('statuses')}</b>: {statusesValue.join(statusesValue.length > 1 ? ', ' : '')}
                      </div>}
                      {!!row?.modules?.sales && <div>
                        <b>{T('custom-prices')}</b>: {customPriceValue.join(customPriceValue.length > 1 ? ', ' : '')}
                      </div>}
                    </Columns.Column>
                    <Columns.Column>
                        {this.getPermissions(row['permissions'], 'permissions')}
                    </Columns.Column>
                    <Columns.Column>
                      <div className='display-flex-center' style={{flexDirection: 'column'}}>
                        <OpenModalButton size="small" link={{
                                          pathname: '/settings/add-user',
                                          state: {'user_id': row['user_id']}
                                        }} text={T('edit-btn')} />
                        <Button
                          color={row.is_active ? 'danger' : 'warning'}
                          size='small'
                          rounded
                          onClick={() => this.confirmDeleteSubUser(row)}>
                          {row.is_active ? T('deactivate-btn')  : T('btn-active')}
                        </Button>
                      </div>
                    </Columns.Column>
                </Columns>
             </List.Item>
            );
        }

        return response;
    }

    render() {
        return UserStore.isMainUser() || UserStore.isAdmin() ? (
        	<Box>
               <Messages message={this.state.message} close={() => this.setState({message: null})} />
               <Level>
                        <Level.Side align="left">

                        </Level.Side>
                        <Level.Side align="right">
                            <Level.Item>
                                <OpenModalButton link="/settings/add-user" text={T('create-new-user')} icon="plus-circle"/>
                            </Level.Item>
                        </Level.Side>
                </Level>

                <Box>
            	   <Heading>{T('users')}</Heading>

                   <List>
                        {this.getUserList()}
                    </List>
                </Box>
        	</Box>
        ) : <AccessDenied/>
    }
}

export default connectToStores(PermissionTab, { 
    users: UserStore
  });
