import React, {useEffect, useState} from 'react';
import T from "components/i18n";
import LoadFiles from "components/load-files";

import Box from 'react-bulma-components/lib/components/box';
import Actions from './clients-actions'
import ClientsActions from "./clients-actions";
import errorMessageControlHook
  from "../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../tools/error-handler";


const ImportClientsModal = ({onClose}) => {
  const {
    handleAutoRemoveError,
    errorMessage,
    setErrorMessage,
    ErrorMessageBlock
  } = errorMessageControlHook()

  const [isWaiting, setIsWaiting] = useState(false)

  const handleSubmit = (file) => {
    if(file) {
      setIsWaiting(true)
      Actions.importClients(file);
    } else {
      handleAutoRemoveError('error-file')
    }
  }

  useEffect(() => {
    const importClients = ClientsActions.importClients.completed.listen((data) => {
      if (typeof(onClose) == "function") {
        onClose();
      }
    });

    const importClientsFail = ClientsActions.importClients.failed.listen((res) => {
      setIsWaiting(false)
      const message = errorHandler(res)

      handleAutoRemoveError(message)
    })

    return () => {
      importClients()
      importClientsFail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = [
    { value: '0', label: T('new-file') },
  ];


  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
      <Box>
        <LoadFiles
          submit={handleSubmit}
          templates={options}
          base_text='load-clients-excel-desk'
          not_warehouse={true}
          button_text='load-clients-from-excel-button'
          waiting={isWaiting}
        />
      </Box>
    </>
  );
};

export default ImportClientsModal;