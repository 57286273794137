import React, { useEffect, useState } from "react";
import T from "components/i18n";
import Box from "react-bulma-components/lib/components/box";
import ProductsActions from "../../products-actions";
import ProductsStore from "../../products-store";
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";
import {  withRouter } from "react-router-dom";
import {
  Label,
  Field,
  Control,
  Textarea,
  Input,
} from "react-bulma-components/lib/components/form";
import UserStore from "user/user-store";
import PriceItem from "./price-item";
import PriceActions from "./price-actions";
import { filteringFilterValue } from "./utils";
import ShowFilters from "./show-filters";
import errorMessageControlHook from "../../../../components/error-message-block/error-message-control.hook";
import SortingFilter from "./sorting-filter";
import { errorHandler } from "../../../../tools/error-handler";
import HiddenColumn from "./hidden-column";
import CustomPrice from "./custom-price";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const PriceModal = ({ onClose }) => {
  const {isMobileSize} = useIsMobile()
  const {
    errorMessage,
    setErrorMessage,
    handleAutoRemoveError,
    ErrorMessageBlock,
  } = errorMessageControlHook();

  const [lines, setLines] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [sort, setSort] = useState({
    value: { value: 0 },
    type: "asc",
  });
  const [hideField, setHideField] = useState([])
  const [customPrice, setCustomPrice] = useState([])

  const filterNow = filteringFilterValue(ProductsStore.getPrepareFilters());

  useEffect(() => {
    ProductsActions.loadPricesList();

    const listenLoadCompleted = ProductsActions.loadPricesList.completed.listen(
      (data) => {
        if (data["success"]) {
          const permissionWarehouse = UserStore.getPermissionWarehouse();
          const filterWithWarehouse = data["data"].filter((item) => {
            let result = true;
            const filters = JSON.parse(item.filters || "{}");
            if (permissionWarehouse?.length) {
              result =
                result &&
                permissionWarehouse.some(
                  (permissionID) => permissionID === parseInt(filters.mid)
                );
            }
            return result;
          });
          setLines(filterWithWarehouse);
        }
      }
    );

    const deletePriceList = ProductsActions.deletePriceList.completed.listen(
      () => {
        ProductsActions.loadPricesList();
      }
    );

    const setPriceList = ProductsActions.setPriceList.completed.listen(() => {
      setInputValues({});
      setSort({
        value: { value: 0 },
        type: "asc",
      });
      setHideField([])
      setCustomPrice([])
    });

    const setPriceListFail = ProductsActions.setPriceList.failed.listen(
      (res) => {
        const message = errorHandler(res);
        handleAutoRemoveError(message);
      }
    );

    return () => {
      listenLoadCompleted();
      deletePriceList();
      setPriceList();
      setPriceListFail();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeInput = ({ target }) => {
    const value = target.value,
      name = target.name;
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleChangeSelect = (selectValue) => {
    setSort((prev) => ({
      ...prev,
      value: selectValue,
    }));
  };

  const handleChangeSortType = ({ value }) => {
    setSort((prev) => ({
      ...prev,
      type: value,
    }));
  };

  const handleSubmit = () => {
    if (!inputValues?.name || inputValues?.name.length === 0) {
      handleAutoRemoveError("not all fields required");
    } else {
      const filters = JSON.parse(JSON.stringify(filterNow || "{}"));

      if (!!sort?.value?.value) {
        filters.sort = `${sort.value.value}|${sort.type}`;
      }


      if(hideField?.length || customPrice?.length) {
        if(!filters.params) filters.params = {};
        filters.params.hide_columns = hideField.map((item) => item.value)
        if(hideField?.find(item => item.value === 'price')) {
          filters.params.custom_price = []
        } else {
          filters.params.custom_price = customPrice.map((item) => item.value)
        }
      }

      let data = {
        name: inputValues.name,
        desc: inputValues.desc || "",
        filters: JSON.stringify(filters),
      };


      console.log("start send pricelist ", data);

      ProductsActions.setPriceList(data);
    }
  };

  const handleChangeHideColumns = (value) => {
    setHideField(value);
  }

  const handleChangeCustomPrice = (value) => {
    setCustomPrice(value)
  }

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading size={isMobileSize ? 5 : 2}>
          {T("get-price-link")} <br />
        </Heading>
        <List>
          {lines.map((item) => (
            <PriceItem key={item.id} line={item} filters={item.filters} />
          ))}
        </List>
        <Heading size={isMobileSize ? 5 : 3}>{T("generate-price-btn")}</Heading>
        <Columns>
          <Columns.Column>
            <Field>
              <Control>
                <Label>{T("name")}</Label>
                <Input
                  size="large"
                  name={"name"}
                  type="text"
                  onChange={handleChangeInput}
                  value={inputValues?.name}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <Label>{T("additional-info")}</Label>
                <Textarea
                  size="large"
                  name={"desc"}
                  type="text"
                  onChange={handleChangeInput}
                  value={inputValues?.desc}
                />
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>{T("filter")}</Label>
                <ShowFilters filters={filterNow} />
              </Control>
            </Field>
            <SortingFilter
              valueRadio={sort.type}
              selectValue={sort.value}
              onChangeRadio={handleChangeSortType}
              onChangeSelect={handleChangeSelect}
            />
            <HiddenColumn onChange={handleChangeHideColumns} value={hideField}/>
            {!hideField?.find(item => item.value === 'price') && <CustomPrice onChange={handleChangeCustomPrice}
                          value={customPrice}/>}
          </Columns.Column>
        </Columns>
        <PriceActions onClose={onClose} onSubmit={handleSubmit} />
      </Box>
    </>
  );
};

export default withRouter(PriceModal);