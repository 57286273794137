import React, { Component } from 'react';

import Box from 'react-bulma-components/lib/components/box';

import Heading from 'react-bulma-components/lib/components/heading';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';

import T from "components/i18n";
import Hero from 'react-bulma-components/lib/components/hero';


class AppBlock extends Component {
    render () {
        return (
        	<Box>
        	     <Hero gradient>
                  <Hero.Body>
                     <Container>
                          <Heading size={3}>
                                {T(this.props.title)}
                          </Heading>
                          <Columns>
                              <Columns.Column>
                                  <a href="https://play.google.com/store/apps/details?id=com.huprof.hugeprofit" rel="noopener noreferrer" target="_blank"><img src="/img/playmarket_ua@2x.webp" border="0" alt="HugeProfit in GooglePlay"/></a>
                              </Columns.Column>
                              <Columns.Column>
                                  <a href="https://apps.apple.com/app/hugeprofit-%D0%BE%D0%B1%D0%BB%D1%96%D0%BA-%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B6%D1%96%D0%B2/id1544248357" rel="noopener noreferrer" target="_blank"><img src="/img/appstore@2x.webp" alt="HugeProfit in AppStore" border="0" /></a>
                              </Columns.Column>
                          </Columns>
        		              <Heading size={5}>
                              {T('use-mobile-app')}
                          </Heading>
                      </Container>
                  </Hero.Body>
                </Hero>
            </Box>
			)}

      }

export default AppBlock;