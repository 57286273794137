import React from "react";
import {Box, Button, Heading, List} from "react-bulma-components";
import T from "../../../../components/i18n";
import AppStore from "../../../../app-store";
import ViewProductModal from "../view-product/view-product-modal";
import ProductStore from "../../products-store";
import WarehouseStore from "../../../warehouse/warehouse-store";
import usePaginationHook from "../../../../tools/pagination-hook";
const ListInfoProductModal = ({ pid_mid, onClose }) => {
	console.log(pid_mid)
  const list = Array.isArray(pid_mid) ? pid_mid : pid_mid.split(",");
	const {end,PaginationBlock,start,setPage,page,total} = usePaginationHook({listLength: list.length})


  return (
    <Box>
			<Heading size={4} >
				{T('list-products')}
			</Heading>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
			/>
      <List>
        {list.slice(start,end).map((item) => {
          const splitItem = item?.split("_");
          const pid = splitItem[0];
          const mid = splitItem[1];

          return (
						<List.Item key={item}>
							<Button
								className="button-link-without-style"
								onClick={() =>
									AppStore.openModal(
										<ViewProductModal pid={pid}/>
									)
								}
							>
								{ProductStore.getProductName(pid)}
							</Button>
							<div>
								<b>{T('history-data-marketplace_id')}:</b> {T(WarehouseStore.getWarehouseName(mid))}
							</div>
						</List.Item>
					);
				})}
			</List>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
			/>
			<div align="right">
				<Button onClick={onClose}>{T("close")}</Button>
      </div>
    </Box>
  );
};

export default ListInfoProductModal;
