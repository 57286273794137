import React from 'react'
import s from "./styles.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PreviewVideo = ({file, onRemove}) => {
	return (
		<div className={s.preview_image}>
			<video src={URL.createObjectURL(file)} controls />
			<span
				onClick={() => onRemove && onRemove(file)}
				className={s.preview_image_delete}
			>
        <FontAwesomeIcon icon="trash-alt" size="2x"/>
      </span>
		</div>
	);
};

export default PreviewVideo;