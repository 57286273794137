import React from 'react'
import {Heading} from "react-bulma-components";
import { BeforeBannerBenefits } from 'whitelables/wl-name/banners/banner-list'
import UserStore from "../../../../user/user-store";
import T from '../../../../components/i18n'

const BeforeBannerText = () => {
	return (
		<div>
			<Heading size={2}>{T('advertise-your-affiliate-link')}</Heading>
			<Heading subtitle>
				{T('advertise-your-affiliate-link-deck')}
			</Heading>
			{<BeforeBannerBenefits lang={UserStore.getLang()}/>}
		</div>
	);
};

export default BeforeBannerText;