import React from 'react'
import BaseFilterLayout from "../../../components/base-filter-layout";
import HistoryStore from "../history-store";
import HistoryActions from "../history-actions";
import HistoryFilter from "../history-conent/history-filter";

const FilterPanel = () => {
	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value).reverse()
		daysKey.forEach(item => {
			HistoryActions.filter(item, value[item]);
		})
	}
	const handleChangeSearch = ({ name, value }) => {
			if(name) {
				HistoryActions.filter('search', value);
			}
	}

	const handleRefresh = () => {
		HistoryStore.clearFilter()
		HistoryActions.load()
	}

	return (
		<BaseFilterLayout
			refresh={{
				onChange: handleRefresh
			}}
			searchStringSetting={{
				onChange: handleChangeSearch,
				defaultValue: HistoryStore.getFilter("search")
			}}
			dateRangeSetting={{
				onChange: handleChangeDate,
				date_start: HistoryStore.getFilter("date_start"),
				date_end: HistoryStore.getFilter("date_end"),
			}}
			hiddenFilter={<HistoryFilter/>}
		/>
	);
};

export default FilterPanel;