import React from 'react'
import {Link} from "react-router-dom";
import ExpensesStore from "../../expenses/expenses-store";
import Money from "../../../components/money";
import {handleStopPropagation} from "./utils";

const DebtLink = ({id, debt}) => {
	return (
		<Link to={{
			pathname: '/sales',
			state: {
				client_id: id,
				period: '30days',
				account_id: ExpensesStore.getDebtAccounts(),
			}
		}}
					className="text-danger text-underline"
					onClick={handleStopPropagation}
		>
			<Money amount={debt || 0}/>
		</Link>
	);
};

export default DebtLink;