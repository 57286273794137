import React, {useEffect, useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import SalesStore from "../../../../../sales/sales-store";
import SmsNotificationItem from "./sms-notification-item";
import SubmitButton from "../../../../../../components/submit-button";
import T from "../../../../../../components/i18n";
import Button from "react-bulma-components/lib/components/button";
import Heading from "react-bulma-components/lib/components/heading";
import UserActions from "../../../../../../user/user-actions";
import {errorHandler} from "../../../../../../tools/error-handler";
import ErrorMessageBlock
	from "../../../../../../components/error-message-block/error-message-block";

const SmsNotification = ({data, onClose}) => {
	const statusList = SalesStore.getStatusList()
	const [value, setValue] = useState(data?.ident?.settings || {})
	const [waiting, setWaiting] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const handleChangeValue = (value, nameID) => {
		setValue(prevState => ({
			...prevState,
			[nameID]: value
		}))
	}

	const handleAutoRemoveMessage = (message) => {
		setErrorMessage(message)
		setTimeout(()=>setErrorMessage(null), 4000)
	}

	const handleErrorCheckLength = () => {
		const resultCheckLength = Object.entries(value)
			.filter(([name, { active, text }]) => active && text.length <= 5)
			.map(([name]) => name);
		if(resultCheckLength.length) {
			const getName = resultCheckLength.map(id => statusList.find(status => status.id === id).name)
			const getRealName = getName.map(item => T(item))
			handleAutoRemoveMessage(T('min-length-text', [getRealName.join(', ')]))
			return false
		}

		return true
	}

	const handleSubmit = () => {
		if(handleErrorCheckLength()) {
			setWaiting(true)
			const copyData = JSON.parse(JSON.stringify(data))
			copyData.ident.settings = value

			copyData.ident = JSON.stringify(copyData.ident)

			UserActions.updateIntegrations(copyData)
		}
	}

	useEffect(() => {
		const updateIntegration = UserActions.updateIntegrations.completed.listen(() => {
			onClose()
		})
		const updateIntegrationFailed = UserActions.updateIntegrations.failed.listen((res) => {
			setWaiting(false)
			const message = errorHandler(res)
			handleAutoRemoveMessage(message)
		})

		return () => {
			updateIntegration()
			updateIntegrationFailed()
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading size={3}>{T('sms-nofifaction-setting')}</Heading>
				<p className='margin-bottom-10'>{T('setting-sms-help')}</p>
				{statusList.map(item => <SmsNotificationItem key={item.id} {...item} defaultValue={value[item.id]} onChange={handleChangeValue} />)}
				<Button.Group position='right'>
					<Button color='light' size='small' rounded onClick={onClose}>{T('close')}</Button>
					<SubmitButton size='small' color="info" text='save-sms' fullwidth={false} waiting={waiting} submit={handleSubmit}/>
				</Button.Group>
			</Box>
		</>
	);
};

export default SmsNotification;