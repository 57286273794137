import React, {useCallback, useEffect, useState} from 'react'
import {
	Control,
	Input
} from "react-bulma-components/lib/components/form";
import T from "../i18n";
import Icon from "react-bulma-components/lib/components/icon";
import { LocalIcon } from 'whitelables/wl-name/icons'
import StockFilter from "./stock-filter";
import s from "./styles.module.scss";
import {useIsMobile} from "../is-mobile-context/is-mobile-context";

const SearchString = ({ delay, defaultValue, isStockFilter, onChange }) => {
	const {isMobileSize} = useIsMobile()
	const [value, setValue] = useState({value: defaultValue || ''})

	const handleChange = (evt) => {
		const value = evt.target.value
		const name = evt.target.name
		setValue({name, value})
	}


	const memoizedOnChange = useCallback(
		(updatedValue) => {
			if (onChange) {
				onChange(updatedValue);
			}
		},
		[onChange]
	);

	useEffect(() => {
		const delayTime = setTimeout(() => {
			memoizedOnChange(value);
		}, delay || 300);

		return () => {
			clearTimeout(delayTime);
		};
	}, [value, delay, memoizedOnChange]);

	useEffect(() => {
		if (defaultValue) {
			setValue({value: defaultValue})
		} else {
			setValue({value: ""})
		}

	}, [defaultValue]);

	return (
		<div className='display-flex-column-gap'
				 style={isMobileSize ? {width: '100%'} : {}}>
			<div className={s.wrapper_search}>
				<Control iconLeft style={{width: '100%'}}>
					<Input
						name='search'
						value={value.value}
						onChange={handleChange}
						autoComplete="nope"
						type="text"
						placeholder={T('search')}
						style={{height: 30}}
					/>
					<Icon align="left" >
						<span style={{marginBottom: '2px'}}><LocalIcon icon={'search'}/></span>
					</Icon>
				</Control>
				{value.value.length > 0 &&
					<span className={s.delete_search} onClick={() => setValue({value: '', name: 'search'})}>
            <LocalIcon icon='close-fill'/>
          </span>}
			</div>
			{isStockFilter && <StockFilter/>}
		</div>
	);
};

export default SearchString;