import React from 'react';
import T from "components/i18n";
import Heading from 'react-bulma-components/lib/components/heading';
import Notification from 'react-bulma-components/lib/components/notification';
import {useIsMobile} from "../is-mobile-context/is-mobile-context";


const InfoBlock = ({title, text, color=''}) => {
	const {isMobileSize} = useIsMobile()
	return (
		<Notification className="left" color={color !== "" ? color : "warning"}>
			<Heading size={isMobileSize ? 6 : 4} className="left margin-bottom-0">{T(title)}</Heading>
		    	{T(text)}
		</Notification>
	)
}

export default InfoBlock;