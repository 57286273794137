import React, { Component } from 'react';
import T from "components/i18n";
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import User from 'user/user-store';
import Money from "components/money";
 import DOM from "react-dom-factories";
import OpenInsideModal from "components/modal/open-inside-modal";
import VideoHelpModal from "public/info/wl-name/video/";
import { Link } from "react-router-dom";
import OpenModalButton from "components/modal/open-modal-button";
import AddWarehouseModal from 'modules/warehouse/add-warehouse-modal';
import WarehouseStore from "../../warehouse/warehouse-store";
import {Button} from "react-bulma-components";
import AppStore from "../../../app-store";
import AddExpensesModal from "../../expenses/add-expenses-modal";

class OnBoardingMobileModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    getClassName(step) {
        let class_name = "StepProgress-item";

        const current = User.getOnBoaringStep();

        if (current >= step ) { 
          class_name += " is-done";
        }

        else if (step === (current + 1)) {
          class_name += " current";
        }

        return class_name;
    }

    render() {
        const step = User.getOnBoaringStep();

        return (
            <Box align="center">
                <Heading size={4}>{T('mobile-onboarding-text')} <Money amount={User.getOnBoaringStepCurrency(5)}  currency={User.getPaymentCurrency()} wrapper={DOM.span} /></Heading>
                <ul className="StepProgress">
                      <li className={this.getClassName(1)} >
                        <OpenInsideModal size="small" color="danger" text={T('see-video')} icon={['fab', 'youtube']}>
                            <VideoHelpModal video="https://www.youtube.com/embed/cV-65bc1Ogk" />
                        </OpenInsideModal>
                        <br />
                        <b>{T('bonus')}: <Money amount={User.getOnBoaringStepCurrency(1)} currency={User.getPaymentCurrency()} wrapper={DOM.span} /></b>
                      </li>
                      <li className={this.getClassName(2)} >
                      {/* <Link to="/warehouse">{T('change-warehouse')}</Link>  */}
                      <OpenInsideModal size="small" renderAs="a" text={T('change-name-store')}>
                            <AddWarehouseModal id={WarehouseStore.getDefaultWarehouse()}/>
                      </OpenInsideModal>
                      <br /> <b>{T('bonus')}: <Money amount={User.getOnBoaringStepCurrency(2)} currency={User.getPaymentCurrency()} wrapper={DOM.span} /></b></li>
                      <li className={this.getClassName(3)} ><Link to="/products">{T('add-new-product-btn')} </Link> <br /><b>{T('bonus')}: <Money amount={User.getOnBoaringStepCurrency(3)} currency={User.getPaymentCurrency()} wrapper={DOM.span} /></b></li>
                      <li className={this.getClassName(4)}><Link to="/sales">{T('add-sale-btn')}</Link> <br /> <b>{T('bonus')}: <Money amount={User.getOnBoaringStepCurrency(4)} currency={User.getPaymentCurrency()} wrapper={DOM.span} /> </b></li>
                      <li className={this.getClassName(5)}><Button onClick={() => AppStore.openModal(<AddExpensesModal/>)} style={{fontSize: 'inherit'}} className="button-link-without-style">{T('add-expenses-btn')}</Button> <br /> <b>{T('bonus')}: <Money amount={User.getOnBoaringStepCurrency(5)} currency={User.getPaymentCurrency()} wrapper={DOM.span} /></b></li>
                      <li className={this.getClassName(6)}>
                        <OpenModalButton size="small" text={T('get-bonus')} rounded link="/settings/subscribe" disabled={step !== 5 ? true : false} color="success" />
                      </li>
                </ul>
            </Box>
        )
    }
}

export default OnBoardingMobileModal;
