import React from "react";
import Level from "react-bulma-components/lib/components/level";
import {Link} from "react-router-dom";
import T from "../../../components/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const EditButton = ({data}) => {
	const {isMobileSize} = useIsMobile()
	return (
		<Level.Item key='edit-btn'>
			<Link to={{
				pathname: '/write-off/edit',
				state: { 'step': 0, disabledSelectionMid: true, 'data': data }
			}} title={T('btn-edit')}>
					<FontAwesomeIcon icon="edit" size={isMobileSize ? '2x' : '1x'} />
			</Link>
		</Level.Item>
	)
};

export default EditButton;