import React from "react";
import ShipmentsStore from "../shipments-store";
import Reports from "../../../components/reports";
import SupplierRowDesktop from "./supplier-row-desktop";
import Box from "react-bulma-components/lib/components/box";
import T from "../../../components/i18n";
import usePaginationHook from "../../../tools/pagination-hook";
import SupplierRowMobile from "./supplier-row-mobile";
import {filterSearch} from "../../../tools/filters-helper";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const EmptyMessage = () => {
  return <Box>{T("not-have-history")}</Box>;
};

const COLUMNS = ["","client-name","contact-link",'comment',"shipments", 'amount', 'not-paid','reports-actions'];

const ReportList = ({searchFilter}) => {
  const {isMobileSize} = useIsMobile()
  const list = ShipmentsStore.getSuppliersList();

  const filterList = list.filter(item => {
    return filterSearch(searchFilter, ['name', 'address', 'phone'], item);
  })

  const { end, start, total, page, setPage, PaginationBlock } =
    usePaginationHook({listLength: filterList.length});
  return (
    <>
      <PaginationBlock
        totalPage={total}
        setPage={setPage}
        currentPage={page}
        showPrevNext={false}
      />
      <Reports
        emptyMessage={EmptyMessage}
        columns={COLUMNS}
        hideFooterOnEmpty
        isLoading={ShipmentsStore.loadSuppliers}
        rows={filterList.slice(start, end)}
      >
        {isMobileSize ? <SupplierRowMobile role='row'/> : <SupplierRowDesktop role="row"/>}
      </Reports>
      <PaginationBlock
        totalPage={total}
        setPage={setPage}
        currentPage={page}
        showPrevNext={false}
      />
    </>
  );
};

export default ReportList;
