import React from 'react'
import Box from "react-bulma-components/lib/components/box";
import T from "../../../../components/i18n";
import Button from "react-bulma-components/lib/components/button";
import {useHistory} from "react-router-dom";

const StepOneAndThirdFinish = ({step}) => {
	const history = useHistory()
	return (
		<Box>
			<h1 className="title">{T(step === 1 ? 'write-off-created' : 'write-off-finished')}</h1>
			<Button size="large"
							rounded
							fullwidth
							color="success"
							onClick={() => history.go(-1)}
			>
				{T('close')}
			</Button>
		</Box>)
};

export default StepOneAndThirdFinish;