import MessageFormat from "messageformat";

const plurals = {
    ru(n) {
        const mod10 = n % 10;
        const mod100 = n % 100;

        return mod10 === 1 && mod100 !== 11 ? "one"
            : n === Math.floor(n) && mod10 >= 2 && mod10 <= 4 && (mod100 < 12 || mod100 > 14) ? "few"
                : mod10 === 0 || mod10 >= 5 || (mod100 >= 11 && mod100 <= 14 && n === Math.floor(n)) ? "many"
                    : "other";
    },
    ua(n) {
        const mod10 = n % 10;
        const mod100 = n % 100;

        return mod10 === 1 && mod100 !== 11 ? "one"
            : n === Math.floor(n) && mod10 >= 2 && mod10 <= 4 && (mod100 < 12 || mod100 > 14) ? "few"
                : mod10 === 0 || mod10 >= 5 || (mod100 >= 11 && mod100 <= 14 && n === Math.floor(n)) ? "many"
                    : "other";
    },
    en(n) {
        return n === 1 ? "one" : "other";
    }
};

const formatters = {
    numerical(value, lc) {
        const mod10 = value % 10;
        const mod100 = value % 100;
        let rest;

        if (lc[0] === "en" || lc === "en") {
            rest = mod10 === 1 && mod100 !== 11 ? "st"
                : mod10 === 2 && mod100 !== 12 ? "nd"
                    : mod10 === 3 && mod100 !== 13 ? "rd"
                        : "th";
        }

        return value + rest;
    }
};

export default new MessageFormat(plurals).addFormatters(formatters);