import React from 'react'
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import {Link} from "react-router-dom";

const EmptyMessage = () => {
	return (
		<Box align="center">
			<Heading>{T('not-have-orders')}</Heading>
			<Heading subtitle>{T('not-have-orders-desk')}</Heading>
			<div className="margin-bottom-40"><Link to="/settings/integrations">{T('set-integration')}</Link></div>
		</Box>

	)
}

export default EmptyMessage;