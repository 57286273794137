import React from "react";
import { Box } from "react-bulma-components";
import Reports from "../../../../components/reports";
import AccountSingleTable from "./account-single-table";
import User from "../../../../user/user-store";
import T from "../../../../components/i18n";

const EmptyMessage = () => {
  return (
    <Box>
      <h2>{T("not-have-history")}</h2>
    </Box>
  );
};

const AccountListTable = ({ list, onDeleteAccount, onEditAccount }) => {
  let COLUMNS = [
    "account",
    "balance",
    "start-period",
    "end-period",
    "count-transaction",
    "sum-coming",
    "sum-expense",
    "reports-actions",
  ];

  if (!User.getPermissionEnable("show-cashflow")) {
    COLUMNS = COLUMNS.filter(
      (item) => item !== "sum-coming" && item !== "sum-expense"
    );
  }
  if (!User.getPermissionEnable("showbalance")) {
    COLUMNS = COLUMNS.filter(
      (item) =>
        item !== "balance" &&
        item !== "start-period" &&
        item !== "end-period" &&
        item !== "reports-actions"
    );
  }

  if (!User.getPermissionEnable("edit-balance")) {
    COLUMNS = COLUMNS.filter((item) => item !== "reports-actions");
  }

  return (
    <Box style={{ overflow: "auto" }}>
      <Reports
        emptyMessage={EmptyMessage}
        columns={COLUMNS}
        rows={list}
        onHeadIsMobile={true}
        minWidthList={COLUMNS.length * 110}
      >
        <AccountSingleTable
          role="row"
          onDeleteAccount={onDeleteAccount}
          onEditAccount={onEditAccount}
        />
      </Reports>
    </Box>
  );
};

export default AccountListTable;
