import React from 'react'
import {LocalIcon} from 'whitelables/wl-name/icons'
import s from './styles.module.scss'

const ComparativeStatisticsBlock = ({value, title}) => {
	let color = ''

	if(value > 0) {
		color = s.success
	} else if(value < 0) {
		color = s.danger
	}

	return typeof value === 'number' && (
		<div className={`${s.com_wrapper} ${color}`} title={title}>
			<LocalIcon icon='left-arrow' size='very-small' className={value <= 0 ? s.icon_bottom : s.icon_top} />
			{value.toFixed(2)|| 0}%
		</div>
	);
};

export default ComparativeStatisticsBlock;