import React from 'react'
import List from "react-bulma-components/lib/components/list";
import T from "../../../components/i18n";
import {Link} from "react-router-dom";
import AppStore from "../../../app-store";
import ChangeAccount from "../change-account";
import numberFormat from "../../../tools/number-format";
import ExpensesStore from "../../expenses/expenses-store";
import Barcode from "react-barcode";
import User from "../../../user/user-store";
import ChannelList from "../common/channel-list";
import Money from "../../../components/money";
import DOM from "react-dom-factories";
import {getDeliveryName} from "./utils";
import ClientsStore from "../../clients/clients-store";
import AdditionalField from "./additional-field";
import formatDate from "../../../tools/format-date";
import ClientViewModal from "../../clients/modal/client-view/client-view-modal";
import Button from "react-bulma-components/lib/components/button";
import CommunicationClient from "../../../components/communication-client";
import CopyText from "../../../components/copy-text/copy-text";
import ClientInfoRow from "../../../components/client-info-row/client-info-row";
import AddCommentModal from "../modal/add-comment-modal/add-comment-modal";

const SalesDetailInfo = ({data, quantity, total, clientInfo, isRefund, isDeleted}) => {
	const {discount, discount_type, delivery_info, clients_delivery_id, comment} = data
	const prepaid = data['prepaid_amount'] || 0
	const accountID = data.account_id
	const orderID = data.oid
	const totalOrder = data.amount_sale
	const status = data.order_status
	const prepaidAccountID = data.prapaid_account_id
	const tracking = data.tracking_number
	const channel_tax = data.fee
	const discount_row = !!discount && (
		<span> | {T('sales-discount')}: {discount}{discount_type === "stable" ? User.getCurrency() : discount_type}</span>
	)
	const prepaid_text = (
		<div>
			<b>{T("prepaid")}</b>: <Money amount={prepaid} aid={prepaidAccountID} wrapper={DOM.span} />
			<b>
				{T('account')}:
				{isRefund ? T(ExpensesStore.getAccountName(prepaidAccountID)) : <Link to="#"
							 onClick={() => AppStore.openModal(<ChangeAccount
								 fromAccount={{'value': prepaidAccountID}}
								 amount={numberFormat(prepaid)} oid={orderID}/>)}
				>
					{T(ExpensesStore.getAccountName(prepaidAccountID))}</Link>}
			</b>
			<br />
			<b>{T("need-balance")}</b>: <Money amount={(total-prepaid) || 0} aid={accountID} wrapper={DOM.span}  />
			<b>
				{T('account')}:
				{isRefund ? T(ExpensesStore.getAccountName(accountID)) : <Link to="#"
							 onClick={() => AppStore.openModal(<ChangeAccount
								 fromAccount={{'value': accountID}}
								 amount={numberFormat(total - prepaid)} oid={orderID}/>)}
				>
					{T(ExpensesStore.getAccountName(accountID))}
				</Link>}
			</b>
		</div>
	);

	return (
		<List align='left'>
			<List.Item>
				<b>{T('date')}: </b>{formatDate(data.purchase_date)}
			</List.Item>
			{!prepaid  && (
				<List.Item className="has-text-success">
					<b>{T('account')}:
						{(isRefund || isDeleted) ? <span> {T(ExpensesStore.getAccountName(accountID))}</span> : <Link
							to='#'
							onClick={() => AppStore.openModal(<ChangeAccount
								fromAccount={{'value': accountID}}
								amount={totalOrder} oid={orderID}/>)}
						> {T(ExpensesStore.getAccountName(accountID))}</Link>}
					</b>
				</List.Item>
				)}
			{!!data?.channel_id?.length && (
				<List.Item>
					<b>{T('sale-channel')}: <ChannelList channels={data.channel_id}/></b>
				</List.Item>)}
			{!!channel_tax && (
				<List.Item>
					<b>{T("commission-sales-chanel")}</b>: <Money amount={channel_tax} aid={accountID} wrapper={DOM.span}/>
				</List.Item>)}

			<List.Item>
				<b>{T('sold-units')}:</b> {quantity}
				{discount_row}
			</List.Item>

			{User.get("count_subusers") > 0 && (
			<List.Item>
				 <b>{T('who-add-sale')}</b>: {User.getResponsebleName(data.responsible_user_id)}
			</List.Item>)}

			{(status === "reserve_sale" || prepaid > 0) && (
				<List.Item>
					{prepaid_text}
				</List.Item>
				)}
			{!!clientInfo?.client?.name && !clients_delivery_id && User.getModuleEnable("clients") && (
				<List.Item position="center">
					<b>{T('customer')}: </b>
					<span className='display-flex-row' style={{display: 'inline-flex'}}>
						<ClientInfoRow clientID={clientInfo.client?.id} withTags/>
						{clientInfo?.client && (
							<CommunicationClient
								nickname={clientInfo.client.nickname}
								phone={clientInfo.client.phone}
								style={{width: 'fit-content'}}
							/>)}
					</span>
				</List.Item>
			)}

			{!!tracking && (
				<List.Item position="center">
					<b>{T('tracking-number')}:</b> <CopyText text={tracking}/>
					<div align="center"><Barcode value={tracking} /></div>
				</List.Item>
			)}
			{!!delivery_info && (
				<List.Item>
					<b>{T('delivery_info')}</b>: {getDeliveryName(delivery_info)}
				</List.Item>
				)}
			{!!clientInfo?.client?.name && !!clients_delivery_id && (
				<List.Item>
								<span>
									<b>{T('delivery-address')}</b>:
									<br />
									<span className='display-flex-row' style={{display: 'inline-flex'}}>
											<Button className='button-link-without-style'
															onClick={() => clientInfo.client?.id && AppStore.openModal(<ClientViewModal clientID={clientInfo.client?.id}/>)}>
												{clientInfo?.client?.name}
											</Button>
											{clientInfo?.client && (
												<CommunicationClient
													nickname={clientInfo.client.nickname}
													phone={clientInfo.client.phone}
													style={{width: 'fit-content'}}
												/>)}
									</span>
									<br />
									{clientInfo?.delivery_address && !!Object.keys(clientInfo?.delivery_address).length
										&& (
											<>
											{ClientsStore.getClientDeliveryNane(clientInfo?.delivery_address, User.getLang())}
											</>)
									}
								</span>
				</List.Item>)}
			{<List.Item>
				{!!comment && <div>
					<b>{T('comment')}: </b>
					{comment}
				</div>}
				{!isDeleted && <div align="center">
					<Button
						color='success'
						rounded
						size='small'
						onClick={() => AppStore.openModal(<AddCommentModal
							comment={data["comment"]}
							oid={data['oid']}/>)}>{data['comment'] ? T('edit-comment') : T('add-comment')}
					</Button>
				</div>}
			</List.Item>}
			<AdditionalField af={data.af}/>
		</List>
	);
};

export default SalesDetailInfo;