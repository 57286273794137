import React from 'react'
import {Button} from "react-bulma-components";
import AppStore from "../../../../app-store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import T from "../../../../components/i18n";
import Reports from "../../../../components/reports";
import EmptyMessage from "../common/empty-message";
import usePaginationHook from "../../../../tools/pagination-hook";
import UserStore from "../../../../user/user-store";
import RowMobile from "./row-mobile";
import RowDesktop from "./row-desktop";
import AddCustomPrice from "../../modal/add-custom-price";
import {
	useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const COLUMNS = ["name", "reports-actions"];

const ProductAddPrices = () => {
	const {isMobileSize} = useIsMobile()
	const getCustomPrices = UserStore.getCustomPriceForList();

	const { start, end, page, total, setPage, PaginationBlock } =
		usePaginationHook({ listLength: getCustomPrices?.length, perPage: 50 });

	return (
		<>
			<div align="right" className="margin-top-10 margin-bottom-10">
				<Button
					color="info"
					onClick={() =>
						AppStore.openModal(<AddCustomPrice modal='custom-prices'/>)
					}
					className="display-flex-row"
				>
					<FontAwesomeIcon icon="plus-circle" />
					{T("add-custom-price-btn")}
				</Button>
			</div>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
			/>
			<Reports
				emptyMessage={EmptyMessage}
				rows={getCustomPrices?.slice(start, end) || []}
				columns={COLUMNS}
			>
				{isMobileSize ? <RowMobile role="row" /> : <RowDesktop role="row" />}
			</Reports>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
			/>
		</>
	);
};

export default ProductAddPrices;