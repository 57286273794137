import React from 'react'
import Level from "react-bulma-components/lib/components/level";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import FinishReservModal from "../../finish-reserv-modal";

const ReservButton = ({orderNumber, accountID,prepaidAccountID, prepaid, totalOrder, orderID}) => {
	return (
		<Level.Item>
			<OpenInsideModal color="success" text={'finish-reserv-title'}>
				<FinishReservModal
					modal="finish-reserv"
					number={orderNumber || orderID}
					aid={prepaidAccountID || accountID}
					prepaid={prepaid}
					total_order={totalOrder}
					oid={orderID}
				/>
			</OpenInsideModal>
		</Level.Item>
	);
};

export default ReservButton;