import Reflux from 'reflux';

const AppActions = Reflux.createActions({

    init: { asyncResult: true },
    set: { asyncResult: true },
});


export default AppActions;
