import React, {useState} from 'react'
import ProductsStore from "../../modules/products/products-store";
import {Box, Button, Heading, List} from "react-bulma-components";
import WarehouseStore from "../../modules/warehouse/warehouse-store";
import T from '../i18n'
import {Radio} from "react-bulma-components/lib/components/form";
import Money from "../money";
import DOM from "react-dom-factories";

const SelectWarehouseProduct = ({productID, onChange, onClose}) => {
	const [radioValue, setRadioValue] = useState("");

	const product = ProductsStore.getProduct(productID)

	const handleSubmit = () => {
		if(radioValue) {
			onChange([{value: `${productID}_${radioValue}`}])
			onClose && onClose()
		}
	}

	const handleChangeRadio = ({target: {value}}) => {
		setRadioValue(value);
	}

	return (
		<Box>
			<Heading>{T('select-warehouse-label')}</Heading>
			<Heading subtitle>
				{product.name} <br />{" "}
				<p style={{ fontSize: "1rem" }}>
					ID: {productID}.{product.sku ? ` ${product.sku}. ` : ""}
				</p>
			</Heading>
			<List>
				{Object.keys(product.stock).map((item) => {
					const getProduct = ProductsStore.getProductByMid(productID, item);
					const isDropshipping = WarehouseStore.isDropshipping(item) && getProduct.type_product !== 4
					return <List.Item key={item}>
						<Radio style={{ display: "flex", alignItems: "center" }} onChange={handleChangeRadio} name='warehouse' value={`${item}`} checked={parseFloat(item) === parseFloat(radioValue)} >
							<b>{WarehouseStore.getWarehouseName(item)}. {ProductsStore.getStockUnits(isDropshipping ? "∞" : getProduct['instock'], getProduct['units'])}. <Money amount={getProduct.sale_price || getProduct.price} wrapper={DOM.span}/></b>
						</Radio>
					</List.Item>
				})}
			</List>
			<div className='display-flex-row' style={{justifyContent: 'flex-end'}}>
				<Button color='success' onClick={handleSubmit}>{T('confirm-label')}</Button>
				<Button onClick={onClose}>{T('close')}</Button>
			</div>
		</Box>
	);
};

export default SelectWarehouseProduct;