import React, {useState} from 'react'
import {Field, Label, Radio} from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";

const FiscalizeAccountType = ({accountType, onChange}) => {
	const [activeRadio, setActiveRadio] = useState(accountType || 'cash')

	const handleChangeRadio = (evt) => {
		const name = evt.target.name
		const checked = evt.target.checked

		if(checked) setActiveRadio(name)
		if(onChange) onChange(name)
	}

	return (
		<Field>
			<Label className='margin-top-10'>{T('fiscalize-as-label')}</Label>
			<Radio
				name='cash'
				onChange={handleChangeRadio}
				checked={activeRadio === 'cash'}
			>
				{T('fiscalize-cash')}
			</Radio>
			<Radio
				name='non-cash'
				onChange={handleChangeRadio}
				checked={activeRadio === 'non-cash'}
			>
				{T('fiscalize-non-cash')}
			</Radio>
		</Field>
	);
};

export default FiscalizeAccountType;