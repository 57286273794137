


const isValidPhone = (phone) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

    if (!re.test(String(phone))) {
        return false;
    }

    return true;
}

export default isValidPhone;