import React, { Component } from 'react';

import Content from 'react-bulma-components/lib/components/content';
import Section from 'react-bulma-components/lib/components/section';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import FooterBlock from '../common/footer'
import AppStore from 'app-store'
import { backLink } from 'tools/back-link' 


class AgreementUA extends Component {
    render () {
        return (
        	<Section>
        	{backLink()}
        	<Box>
        	<Content align="left">
				<Heading>Публічний договір</Heading>

			<div align="right">1 вересня 2020 року</div>

 				<p>Web-сайт https://h-profit.com (далі – Сервіс) належить на праві власності {AppStore.getContactData("company")} (далі – Компанія), пропонує будь-якій фізичній / юридичній особі / підприємцю, іменованому надалі «Користувач», разом іменовані надалі «Сторони», а окремо, – "Сторона", укласти цей Публічний договір, викладений в стандартній і незмінній для всіх форм у вигляді публічної оферти, шляхом прийняття в цілому і безумовно наступних умов:</p>

				<p>Терміни, що використовується у договорі і їх значення:</p>
				<ul>
<li>a) Сервіс — програмне забезпечення "HugeProfit", призначене для простого ведення складского та фінансового обліку. Виняткові права на Сервіс належать Компанії. Передача виключних прав (повністю або в частині) не є предметом цього Договору. Опис властивостей Сервісу доступно Користувачу на сайті сервісу за адресою h-profit.com.</li>

<li>b) Тарифы — прайс-лист Компанії, розташований в мережі Інтернет за адресою https://h-profit.com/pricing/.</li>

<li>с) Вибранний Тариф — Тариф який вибрав та оплатив Користувач з розділу Тарифи, який регулює обьєм послуг який надається Користувачу.</li>

<li>d) Сайт Компанії — сайт (Сервіс), розташований в мережі Інтернет за адресою h-profit.com.</li>

<li>e) Обліковий запис — спосіб фіксації статистичної, облікової та іншої інформації, яка є для ідентифікації Користувача з метою цього Договору. До облікового запису прив'язана інформація, що відноситься до Користувачу, в тому числі інформація, зазначена при реєстрації, настройки роботи з Сервісом, дані, внесені Користувачом до Сервісу.</li>

<li>f) Логін і Пароль — унікальний набір символів, який створюється Користувачом при проходженні реєстрації, призначений для доступу до Сервісу.</li>

<li>g) Обліковий період — період часу, не менше одного календарного місяця, на який оплачен доступ до сервисі.</li>

<li>h) Сервер — фізичний сервер, призначений для установки Сервісу.</li>

<li>i) Автопродовження підписки -сервіс автоматичного продовження Вибранного Тарифа.</li>
</ul>


<Heading subtitle size={5}>1. Предмет договору</Heading>
<p>1.1. Відповідно до умов цього Договору Компанія надає Користувачу доступ до Сервісу, а Користувач зобов'язується використовувати зазначений Сервіс на умовах, визначених цим Договором та оплачувати доступ до Сервісу в порядку, визначеному Договором.</p>
<p>1.2. Повною і безумовною прийняттям умов цього Договору вважається реєстрація Користувачом в Сервісі Компанії. Якщо Користувач не згоден з умовами цього Договору, він не повинен використовувати Сервіс.</p>
<p>1.3. Територія наданих прав - всі країни світу.</p>
<p>1.4. Актуальна редакція Договору завжди знаходиться на сайті Компанії за адресою https://h-profit.com/agreement/.</p>

<Heading subtitle size={5}>2. Права і обов'язки сторін</Heading>
<p>2.1. Компанія має право:
</p><p>2.1.1. отримувати від Користувача інформацію про дотримання умов цього Договору та (або) інформацію, необхідну для виконання цього Договору та додаткових угод до нього;

</p><p>2.1.2. отримувати винагороду відповідно до умов цього Договору;

</p><p>2.1.3. вносити зміни до Сервісу, в тому числі публікувати нові версії Сервісу, без попереднього повідомлення Користувача;

</p><p>2.1.5. в односторонньому порядку розірвати Договір і (або) блокувати доступ Користувача до Сервісу у випадках порушення Користувачом законодавства України та (або) умов цього Договору;

</p><p>2.1.6. проводити профілактичні роботи на Сервері, для чого тимчасово припиняти доступ до Сервісу;

</p><p>2.1.7. видалити обліковий запис і всі дані Користувача після закінчення періоду, зазначеного в п.2.2.3. цього Договору, або при отриманні запиту від Користувачом видалення даних, або в разі порушення Користувачом умов цього Договору.</p>

<Heading subtitle size={5}>2.2. Компанія зобов'язана:</Heading>
<p>2.2.1. надати щоденний цілодобовий доступ до Сервісу, за винятком випадків, зазначених у цьому Договорі;

</p><p>2.2.2. забезпечити конфіденційність даних Користувача з урахуванням чинного законодавства України;

</p><p>2.2.3. забезпечити збереження даних, введених Користувачом до Сервісу, протягом терміну дії цього Договору та 1 (одного) року з моменту припинення дії Договору, за винятком випадків, зазначених у цьому Договорі;

</p><p>2.2.4. в розумні терміни усувати збої в роботі Сервісу, що виникли з вини Компаніяа;

</p><p>2.2.5. проводити профілактичні роботи по можливості в нічний час і у вихідні дні;

</p><p>2.2.6. здійснювати резервне копіювання даних і інформації Користувача.

</p><p>2.2.7. після отримання запиту відповідно до п. 2.3.4. цього Договору в термін не пізніше 10 робочих днів і в рамках технічної можливості Компанії надати Користувачу вивантаження його даних.</p>

<Heading subtitle size={5}>2.3. Користувач має право:</Heading>
<p>2.3.1. використовувати Сервіс відповідно до умов Вибранного Тарифа та цим Договором;

</p><p>2.3.2. отримувати щоденний і цілодобовий доступ до Сервера, за винятком випадків, зазначених у цьому Договорі;

</p><p>2.3.3. в будь-який час направити Компаніяу запит про видалення свого облікового запису і всіх введених в Сервіс даних.
</p>
<Heading subtitle size={5}>2.4. Користувач зобов'язаний:</Heading>
<p>Дотримуватись обмежень щодо використання Сервісу, зазначені в цьому Договорі;

</p><p>2.4.2. сплачувати винагороду за Вибранним Тарифом Компанії в розмірі та в строки, встановлені цим Договором та Тарифами;

</p><p>2.4.3. забезпечити конфіденційність Логіна і Пароля;

</p><p>2.4.4. негайно повідомляти Компаніяа про будь-якому недозволеному Користувачом використанні свого Логіна і (або) Паролю або будь-яке інше порушення безпеки;

</p><p>2.4.5. дотримуватися законодавства про захист персональних даних, які обробляються за допомогою Сервісу (в тому числі отримувати згоди на обробку персональних даних);

</p><p>2.4.6. періодично знайомиться з актуальною редакцією цього Договору;

</p><p>2.4.7. не надавати ні за яких обставин будь-якій третій особі право на використання Сервісу (субліцензію).
</p>
<Heading subtitle size={5}>3. Порядок і умови використання програми</Heading>
<p>3.1. Для початку використання Сервісу Користувач заповнює реєстраційні форми на сайті Компаніяа.

</p><p>3.1.1. В процесі заповнення реєстраційних форм Користувач самостійно вибирає Логін і Пароль, необхідні для подальшого доступу до Сервісу.

</p><p>3.1.2. При реєстрації Користувач вводить такі дані: логін, пароль, адресу електронної пошти та іншу інформацію.

</p><p>3.2. Операції, які виконуються з використанням комбінації Логіна та Пароля або електронної пошти Користувача, вважаються діями, виконаними самим Користувачом.

</p><p>3.3. Права на Сервіс надаються Користувачу в термін, що не перевищують 2 (двох) робочих днів з моменту зарахування грошових коштів на розрахунковий рахунок Компаніяа на строк, що дорівнює облікового періоду.

</p><p>3.3.1. У разі дострокової оплати Вибранного Тарифа  (з урахуванням положень п 4.7. Договору) за наступний обліковий період, доступ до Сервісу надаються не раніше закінчення терміну дії попередньої проплати.

</p><p>3.4. Факт надання Користувачу Вибранного Тарифу підтверджується відкриттям повного доступу Користувачові до Сервісу відповідно до п.3.3 цього Договору.

</p><p>3.5. Компанія не перевіряє надану Користувачом з метою виконання цього Договору інформацію (дані), не несе відповідальності перед будь-якими третіми особами за точність і достовірність даних.

</p><p>3.7. З питань технічної підтримки Користувач може звертатися за контактами, вказаними на Сайті Компаніяа за адресою h-profit.com.
</p>
<Heading subtitle size={5}>4. Вартість Вибранного Тарифа, порядок розрахунків і умови відшкодування</Heading>
<p>4.1. Вартість Вибранного Тарифа
</p><p>4.1.1. Вартість Вибранного Тарифа визначається згідно з Тарифами Компанії.

</p><p>4.1.2. Компанія має право на свій розсуд змінювати вартість Вибранного Тарифа та умови зміни тарифних планів шляхом їх публікації в новій редакції на Сайті Компанії за адресою h-profit.com/pricing/. Нові Тарифи вступають в силу з дати їх публікації.

</p><p>4.1.3. У разі якщо Користувач продовжує використання Сервісу після вступу в силу нових Тарифів, вважається, що він прийняв такі зміни Тарифів та дав свою згоду на продовження співпраці відповідно до нових Тарифів.

</p><p>4.1.4. У будь-якому випадку, перерахунок Вибранного Тарифа за оплачені періоди не проводиться.
</p>
<Heading subtitle size={5}>4.2. Порядок розрахунків</Heading>
<p>4.2.1. Оплата ліцензійного винагороди проводиться відповідно до обраного Тарифу, авансовим платежем у розмірі 100% від суми обраного тарифу, не менше ніж за два робочі дні до бажаної дати початку використання Сервісу.

</p><p>4.2.2. Способи оплати вказані на сайті Компанії за адресою https://h-profit.com/pricing/ і в Сервісі.

</p><p>4.2.3. Моментом виконання Користувачом обов'язків по оплаті вважається день зарахування грошових коштів на розрахунковий рахунок Компанії.

</p><p>4.2.4. Користувачі що оплачують Вибранний тариф, надають згоду на послуги автоматичного регулярного продовження Вибранного тарифа на новий термін (автоподовження Вибранного Тарифа). Після активації даної послуги оплата нового строку Вибранного тарифа здійснюється шляхом автоматичного безакцептного списання коштів з рахунку Користувача. Послуга автоподовження Вибранного Тарифа надається до тих пір, поки Користувач не відмінить підписку на Вибранний Тариф в Сервисі Компанії.

</p><p>4.2.5.  Послуга активується при здійсненні першої оплати Вибранного Тарифа, що означає згоду Користувача з тим, що після закінчення оплаченого строку дії Вибранного тарифа Договір автоматично пролонгується на тих же умовах на той же термін з автоматичним списанням вартості Вибранного Тарифу з рахунку Користувача;

</p><p>4.2.6. У разі недостатності коштів на рахунку Користувача або неможливості проведення платежу Вибранний Тариф на новий строк не надається.

</p><p>4.2.7. При зміні Компанією Тарифів автоподовження Вибранного Тарифа на черговий новий строк, наступний за періодом, протягом якого відбулася така зміна, буде здійснено за новим тарифом.

</p><p>4.2.8. Всі комісії, що стягуються кредитними організаціями / іншими третіми особами при здійсненні платежу, оплачує Сторона-платник.
</p>
<Heading subtitle size={5}>4.3. Умови відшкодування</Heading>
<p>4.3.1. Компанія гарантує Користувачу можливість повернення грошових коштів, внесених в якості оплати Вибранного Тарифа, протягом 14 (чотирнадцяти) календарних днів з дати здійснення оплати, шляхом надсилання електронного листа про дострокове розірвання цього Договору та повернення сплачених в якості авансу грошових коштів з зареєстрованої електронної пошти Користувача на електронну пошту Компанії h.info@h-profit.com.

По закінченню 14 (чотирнадцяти) календарних днів з дати здійснення оплати повернення не проводиться.

</p><p>4.3.2.Компанія, у разі отримання офіційної заяви в обумовлений в п. 4.3.1. Договору термін, бере на себе обов'язок розглянути зазначену заяву і повернути кошти на розрахунковий рахунок Користувача, з якого були сплачені кошти, протягом 30 (тридцяти) календарних днів з дати отримання листа. У випадках, коли повернення на рахунок Користувача, з якого були сплачені кошти, неможливе, Користувач вказує новий рахунок у листі про повернення сплачених в якості авансу грошових коштів.

</p><p>4.3.3. Повернення грошових коштів означає дострокове розірвання цього Договору.
</p>
<Heading subtitle size={5}>5. Обмеження</Heading>
<p>5.1. Сервіс може використовуватися виключно в господарській діяльності Користувача з урахуванням її функціонального призначення.

</p><p>5.2. Користувач не має права:
</p><p>5.2.1. намагатися обійти технічні обмеження в Сервісі;

</p><p>5.2.2. розкривати технологію, декомпілювати або деасемблувати Сервіс;

</p><p>5.2.3. використовувати компоненти Сервісу для виклику додатків, які не працюють на цій Сервісі;

</p><p>5.2.4. створювати копії Сервісу;

</p><p>5.2.5. публікувати Сервіс, дозволяючи іншим користувачам її скопіювати;

</p><p>5.2.6. надавати Сервіс в прокат, в оренду або в тимчасове користування;

</p><p>5.2.7. використовувати Сервіс для надання мережевих послуг на комерційній основі;

</p><p>5.2.8. здійснювати використання Сервісу або її частин, а також будь-яких інших онлайн сервісів компанії (API, онлайн чат користувачів) після закінчення терміну дії даного Договору, або його дострокового розірвання;

</p><p>5.2.9. робити спроби щодо отримання доступу до даних інших Користувачів.

</p><p>5.3. У разі розірвання цього Договору, Користувач зобов'язується негайно припинити експлуатацію Сервісу та / або її окремих частин, при цьому, дані Користувача в Сервісі зберігаються протягом 365 календарних днів з моменту розірвання договору або закінчення Періоду відстрочки.
</p>
<Heading subtitle size={5}>6. Відповідальність сторін</Heading>
<p>6.1. За невиконання та / або неналежне виконання зобов'язань за цим Договором Сторони несуть відповідальність відповідно до законодавства України.

</p><p>6.2. Сервіс надається за принципом «як є». Компанія не несе відповідальності за можливі збитки, прямо або побічно пов'язаний із застосуванням, невірним застосуванням або через непридатність Сервісу Користувачом, втрату або пошкодження даних.

</p><p>6.3. Відповідальність Компаніяа завжди обмежується сумою, сплаченою Користувачом.
</p>
<Heading subtitle size={5}>6.4. Компанія не несе відповідальності:</Heading>
<p>6.4.1. за неякісну роботу Сервісу (і завдані збитки) в разі, коли Користувачом (або третіми особами) самостійно були внесені зміни до Сервісу або такі збої викликані несумісністю Сервісу з обладнанням або іншим програмним забезпеченням Користувача;

</p><p>6.4.2. за будь-які дії Користувача, пов'язані з використанням Сервісу, документи створені в рамках використання Сервісу;

</p><p>6.4.3. за відсутність доступу до Сервісу, коли це пов'язано з діями Користувача, доступом Користувача до мережі Інтернет, діями третіх осіб, проведенням профілактичних робіт.

</p><p>6.5. Використання Користувачом Сервісу спосіб, не передбачений цим Договором, або після припинення дії Договору, або іншим чином за межами прав, наданих за цим Договором, яке тягне за собою відповідальність за порушення виключного права на результат інтелектуальної діяльності, встановлену законодавством України, а також право Компаніяа на односторонньо позасудове розірвання даного Договору і припинення Ліцензії.

</p><p>6.6. Компанія не несе відповідальності за обмеження доступу до Сервісу з урахуванням положень цього Договору.
</p>
<Heading subtitle size={5}>7. Вирішення спорів</Heading>
<p>7.1. Сторони будуть прагнути до вирішення всіх можливих суперечок і розбіжностей, які можуть виникнути за цим Договором або в зв'язку з ним, шляхом переговорів.

</p><p>7.2. Спори, не врегульовані шляхом переговорів, передаються на розгляд до відповідного суду м.Харкова та підлягають вирішенню в установленому законодавством України і належним чином ратифікованих міжнародних договорів порядку.

</p><p>7.3. Сторони визнають обов'язковість досудового претензійного порядку перед зверненням до суду.

</p><p>7.4. Компанія зобов'язаний відповісти на досудову претензію протягом 30 (Тридцяти) робочих днів з моменту її отримання.
</p>
<Heading subtitle size={5}>8. Форс-мажорні обставини</Heading>
<p>8.1. У разі виникнення обставин непереборної сили (форс-мажорних обставин), а саме: війни, військових дій, блокади, ембарго, пожеж, повеней, іншого стихійного лиха чи природних явищ, які унеможливлюють виконання Стороною своїх зобов'язань за цим Договором, Сторона, що зазнала дію таких обставин, звільняється від відповідальності за таке невиконання та / або неналежне виконання своїх зобов'язань за Договором на час дії зазначених обставин.

</p><p>8.2. Сторона, яка не має можливості виконати умови Договору внаслідок дії форс-мажорних обставин, зобов'язана повідомити іншу Сторону про виникнення або припинення зазначених обставин не пізніше 2 (двох) календарних днів з моменту їх виникнення або припинення, в письмовій формі. Несвоєчасне повідомлення про виникнення форс-мажорних обставин позбавляє потерпілу Сторону права посилатися на ці обставини в майбутньому.

</p><p>8.3. Наступ дії і припинення дії форс-мажорних обставин підтверджується довідкою, виданою Торгово-промисловою палатою або іншим компетентним органом країни, резидентом якої є Сторона, яка постраждала від дії форс-мажорних обставин.

</p><p>8.4. У разі коли дія зазначених обставин триває більш ніж один Обліковий період поспіль та впливає на виконання Сторонами своїх обов'язків за цим Договором, кожна зі Сторін має право на дострокове розірвання Договору за умови, якщо вона повідомить про це іншу Сторону не пізніше ніж за 10 (десять ) календарних днів до передбачуваної дати розірвання.
</p>
<Heading subtitle size={5}>9. Конфіденційність</Heading>
<p>9.1. Сторони зобов'язуються зберігати конфіденційну інформацію, що надається однією Стороною іншій Стороні.

</p><p>9.2. Для условий данного Договора Стороны согласовали следующие определения:

«Конфіденційна інформація» означає відомості, будь-яку науково-технічну, технологічну, виробничу, юридичну, фінансово-економічну чи іншу інформацію, в тому числі комерційну таємницю і ноу-хау, що має дійсну або потенційну комерційну цінність в силу її невідомості третім особам, до неї немає вільного доступу на законній підставі, і Сторона вживає заходів до охорони її конфіденційності.

</p><p>9.3. Сторони зобов'язуються не здійснювати розкриття Конфіденційної інформації третім особам, за винятком випадків, коли Конфіденційна інформація може бути розкрита з попередньої письмової згоди Сторони-власника інформації або в інших випадках, передбачених законодавством України. У будь-якому випадку, Сторони повинні повідомляти один одного про необхідність розкриття конфіденційної інформації за запитом державних органів.

</p><p>9.4. Жодна із Сторін цього Договору не буде намагатися прямо або побічно, контактувати або співпрацювати з партнером, консультантом, контрагентом, клієнтом іншої Сторони з питань, пов'язаних з взаємовідносинами Сторін; за винятком дій через цю іншу Сторону або отримання письмової згоди на такі дії цієї іншої Сторони, а також не буде намагатися обійти іншу Сторону будь-яким способом, призначеним для того, щоб обійти винагороду, дохід і / або прибуток, які повинні бути отримані кожної із Сторін в результаті взаємин Сторін.

</p><p>9.5. Сторони гарантують, що персональні дані їх засновників, співробітників, які представляють інтереси Сторін, або інших представників будуть використовуватися Сторонами з метою прийняття рішень і укладення між Сторонами будь-яких договорів, контрактів, угод та інших документів, спрямованих на вчинення дій, що породжують юридичні наслідки для сторін. Сторони гарантують забезпечення конфіденційності і безпеки персональних даних відповідно до законодавства України.
</p>
<Heading subtitle size={5}>10. Порядок укладення, термін дії, порядок розірвання договору</Heading>
<p>10.1. Цей Договір укладається (акцептується) шляхом реєстрації Користувача на сервері Компанії і оплати Користувачом Тарифу і діє протягом терміну, обраного Користувачом. Датою укладення Договору є дата зарахування грошових коштів на розрахунковий рахунок Компаніяа в розмірі, визначеному згідно з Тарифами.

</p><p>10.2. Оферта (пропозиція на укладення цього Договору) діє необмежено в часі з урахуванням умов, викладених у цій оферті, - Ліцензійна угоді, поки Компанія не припинить її дію або не замінить цю пропозицію новою редакцією. Зміна умов оферти не тягне зміни умов цього Договору, якщо Користувач його уклав до введення в дію таких змін або її припинення.

</p><p>10.3. Цей Договір поширюється на оновлення і нові версії Сервісу.

</p><p>10.4. За винятком випадків, прямо обумовлених в цьому Договорі, Договір розривається за взаємною згодою Сторін.

</p><p>10.5. Видалення облікового запису Користувача автоматично припиняє дію цього Договору.
</p>
<Heading subtitle size={5}>11. Заключні положення</Heading>
<p>11.1. Цей Договір є змішаним і містить елементи договорів приєднання, публічного договору.

</p><p>11.2. Користувач підтверджує, що він, до укладення цього Договору, ознайомився з його умовами, що вони йому зрозумілі, і свідомо без примусу уклав цей Договір.

</p><p>11.3. Користувач не може пропонувати свої умови, а може тільки приєднатися до запропонованої оферті.

</p><p>11.4. Всі додатки та додаткові угоди до цього Договору є його невід'ємною частиною.

</p><p>11.5. Сторони домовилися, що всі документи з факсимільним відтворенням підпису за допомогою засобів механічного або іншого копіювання, електронно-цифрового підпису або іншого аналога власноручного підпису мають силу таку ж, як і документи з власноручним підписом.

</p><p>11.6. Компанія залишає за собою право вносити зміни в цей Договір в односторонньому порядку. У разі якщо Користувач не згоден із внесеними змінами, він зобов'язаний протягом 5 робочих днів з моменту зміни Договору припинити використання Сервісу та розірвати цей Договір в односторонньому порядку.

</p><p>11.7. Реквізити та контактні дані Компаніяа вказуються в Розділі 12 цього Договору.

</p><p>11.8. Контактні дані Користувача вказуються при заповненні реєстраційних форм, в Особистому кабінеті в Сервісі або іншим узгодженим Сторонами способом. Користувач несе ризик повідомлення невірних (недійсних) контактних даних.
</p>
<Heading subtitle size={5}>12. Реквізити компаніяа</Heading>
<p>{AppStore.getContactData("company")}
 

</p><p>Рахунок отримувача у форматі відповідно до стандарту IBAN <br />
UA023515330000026009052243590 < br />
АТ КБ "ПРИВАТБАНК"

</p><p>Електронна пошта: h.info@h-profit.com</p>
			</Content>
			</Box>
			<FooterBlock />
			</Section>
			)}

}

export default AgreementUA;