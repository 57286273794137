import React from 'react'
import {
	Control,
	Field,
	Input, Label
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import {Icon} from "react-bulma-components";

const InputsGroupField = ({dataInput, inputValues,styleField, onChange}) => {
	return dataInput.map(({name, placeholder, label,icon,type, ...rest}) => (
			<Field className="margin-top-20" key={name} style={styleField ? styleField : {}}>
				{label && <Label>{T(label)}</Label>}
				<Control iconRight={!!icon}>
					<Input
						size="large"
						type={type ? type : "text"}
						name={name}
						value={inputValues[name] || ''}
						placeholder={T(placeholder)}
						onChange={onChange}
						{...rest}
					/>
					{icon && <Icon align='right'>
						<span>
							{icon}
						</span>
					</Icon>}
				</Control>
			</Field>
		))
};

export default InputsGroupField;