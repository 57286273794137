import React from "react";
import { connectToStores } from "tools/reflux-tools";

import OpenModalButton from "components/modal/open-modal-button";
import T from "components/i18n";
import Reports from "components/reports";

import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";

import MovingRow from "./moving-row";
import MovingStore from "./moving-store";
import Actions from "./moving-actions";
import WarehouseStore from "../warehouse/warehouse-store";
import PaginationBlock from "../../components/pagination";
import MovingActions from "./moving-actions";
import ButtonActions from "./common/button-actions";
import FilterPanel from "./common/filter-panel";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";

const COLUMNS = ["info", "status", "responseble", ""];

const EmptyMessage = () => {
  return (
    <Box align="center">
      <Heading>{T("not-have-moving")}</Heading>
      <OpenModalButton
        link="/moving/add-moving"
        text={T("add-first-moving-btn")}
      />
    </Box>
  );
}

const Moving = () => {
  const {isMobileSize} = useIsMobile()
  const getList = MovingStore.getPageList(false,false,'rows')
  return (
    <>
      {!isMobileSize && <FilterPanel/>}
      {WarehouseStore.getWarehouseShipmentList().length > 1 && <ButtonActions/>}
      <Box className="moving-report">
        {isMobileSize && <FilterPanel/>}
        <PaginationBlock
          setPage={MovingActions.setPage}
          totalPage={MovingStore.get("count_page")}
          currentPage={MovingStore.get("page")}
        />
        <Reports
          load={Actions.load}
          emptyMessage={EmptyMessage}
          columns={COLUMNS}
          hideFooterOnEmpty
          rows={getList}
        >
          <MovingRow role="row" />
        </Reports>
        <PaginationBlock
          setPage={MovingActions.setPage}
          totalPage={MovingStore.get("count_page")}
          currentPage={MovingStore.get("page")}
        />
      </Box>
    </>
  );
};

export default connectToStores(Moving, { _: MovingStore });
