import React from "react"
import Banner1 from './banner-1.PNG'
import Banner2 from './banner-2.PNG'
import Banner3 from './banner-3.jpg'
import Banner4 from './banner-4.jpg'
import Banner5 from './banner-5.jpg'
import Banner6 from './nbanner3.jpg'
import Banner7 from './nbanner2.jpg'
import Banner8 from './nbanner1.jpg'
import Banner9 from './nbanner4.jpg'
import Banner10 from './nbanner5.jpg'
import Banner11 from './nbanner6.jpg'
import {Box, Heading} from "react-bulma-components";

const bannerList = [
	Banner1,
	Banner2,
	Banner3,
	Banner4,
	Banner5,
	Banner6,
	Banner7,
	Banner8,
	Banner9,
	Banner10,
	Banner11,
]

const textWithLang = {
	ru: {
		heading: 'HugeProfit - Украинская CRM для товарного бизнеса. Учет продаж, товаров, расходов, отправок и клиентов.',
		benefit1: 'Вести учет своих товаров на разных складах и торговых точках: количество, цену закупки, цену продаж.',
		benefit2: 'Оформлять поставки товаров в разных валютах и с учетом изменившихся цен.',
		benefit3: 'Фиксировать продажи в 2 клика, как вручную, так и с помощью сканера штрих-кодов или мобильного телефона.',
		benefit4: 'Печатать как фискальные чеки, так и расходные накладные.',
		benefit5: 'Создавать ТТН, формировать список заказов на отправку.',
		benefit6: 'Отслеживать все Ваши отправки, количество денег в наложенном платеже, забрали или отказались от посылки.',
		benefit7: 'Добавлять все расходы и дополнительные доходы в бизнесе.',
		benefit8: 'Следить за остатками на любом количестве счетов.',
		benefit9: 'Вести клиентскую базу с адресами и возможностью создания программы лояльности.',
		benefit10: 'Получайте заказы и контролируйте остатки на WooCommerce, Prom, Rozetka, Хорошоп OpenCart прямо из кабинета HugeProfit',

	},
	en: {
		heading: 'HugeProfit - Ukrainian CRM for commodity business. Accounting of sales, goods, expenses, shipments and customers.',
		benefit1: 'Keep records of your goods in different warehouses and outlets: quantity, purchase price, sales price.',
		benefit2: 'Make deliveries of goods in different currencies and with changed prices.',
		benefit3: 'Capture sales in 2 clicks, either manually, with barcode scanner or cell phone.',
		benefit4: 'Print both fiscal receipts and sales invoices.',
		benefit5: 'Create sales invoices, create a list of orders for shipment. ',
		benefit6: 'Keep track of all your shipments, the amount of money in cash on delivery, whether the parcel was picked up or refused.',
		benefit7: 'Add all expenses and extra income in the business.',
		benefit8: 'Keep track of balances on any number of accounts.',
		benefit9: 'Maintain a customer base with addresses and the ability to create a loyalty program.',
		benefit10: 'Receive orders and control balances on WooCommerce, Prom, Rozetka, Wellshop OpenCart directly from the HugeProfit cabinet',
	},
	ua: {
		heading: 'HugeProfit - Українська CRM для товарного бізнесу. Облік продажів, товарів, витрат, відправок та клієнтів.',
		benefit1: 'Вести облік своїх товарів на різних складах та торгових точках: кількість, ціну закупівлі, ціну продажів.',
		benefit2: 'Оформляти поставки товарів у різних валютах і з урахуванням цін, що змінилися.',
		benefit3: 'Фіксувати продаж у 2 кліки, як вручну, так і за допомогою сканера штрих-кодів чи мобільного телефона.',
		benefit4: 'Друкувати як фіскальні чеки, так і видаткові накладні.',
		benefit5: 'Створювати ТТН, формувати список замовлень на відправку.',
		benefit6: 'Відстежувати всі Ваші відправки, кількість грошей у післяплатах, забрали або відмовилися від посилки.',
		benefit7: 'Додаватити всі витрати та додаткові доходи у бізнесі.',
		benefit8: 'Слідкувати за залишками на будь-якій кількості рахунків.',
		benefit9: 'Вести клієнтську базу з адресами та можливістю створення програми лояльності.',
		benefit10: 'Отримуйте замовлення та контролюйте залишки на WooCommerce, Prom, Rozetka, Хорошоп OpenCart прямо з кабінету HugeProfit',
	}
}

const BeforeBannerBenefits = ({lang}) => {
	return (
		<Box>
			<Heading size={4} style={{margin: 0}}>
				{textWithLang[lang || 'ua'].heading}
			</Heading>
			<ul style={{listStyle: 'inside', textAlign: 'left', display: 'grid', gridTemplateColumns: '1fr 1fr', margin: '10px 0'}}>
				<li>
					{textWithLang[lang || 'ua'].benefit1}
				</li>
				<li>
					{textWithLang[lang || 'ua'].benefit2}
				</li>
				<li>
					{textWithLang[lang || 'ua'].benefit3}
				</li>
				<li>
					{textWithLang[lang || 'ua'].benefit4}
				</li>
				<li>
					{textWithLang[lang || 'ua'].benefit5}
				</li>
				<li>
					{textWithLang[lang || 'ua'].benefit6}
				</li>
				<li>
					{textWithLang[lang || 'ua'].benefit7}
				</li>
				<li>
					{textWithLang[lang || 'ua'].benefit8}
				</li>
				<li>
					{textWithLang[lang || 'ua'].benefit9}
				</li>
				<li>
					{textWithLang[lang || 'ua'].benefit10}
				</li>
			</ul>
		</Box>
	)
}

export {
	bannerList,
	BeforeBannerBenefits
}