import React, {useEffect, useState} from 'react'
import {Box} from "react-bulma-components";
import T from "../../../components/i18n";
import {
	Control,
	Field, Input,
	Label
} from "react-bulma-components/lib/components/form";
import SubmitButton from "../../../components/submit-button";
import ChatsActions from "../chats-actions";
import ErrorMessageControlHook
	from "../../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../../tools/error-handler";
import ChatsStore from "../chats-store";
import NumberInput from "../../../components/number-input/number-input";

const AddChatsStatus = ({id, isEdit, name,onClose}) => {
	const getStatus = ChatsStore.getChatsStatusById(id);

	const [value, setValue] = useState(name || getStatus?.name || '')
	const [colorState, setColorState] = useState(getStatus?.color || '#36bcff')
	const [position, setPosition] = useState(getStatus?.position || '')
	const [waiting, setWaiting] = useState(false)
	const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = ErrorMessageControlHook()

	const handleChange = ({target: {value}}) => {
		setValue(value)
	}
	const handleColor = ({target: {value}}) => {
		setColorState(value)
	}
	const handlePosition = ({target: {value}}) => {
		setPosition(value)
	}

	const handleSubmit = () => {
		if(value && value.trim()) {
			setWaiting(true)
			const data = {
				name: value,
				color: colorState,
			}

			if(position) {
				data.position = position
			}

			if(id) {
				data.id = id
			}

			ChatsActions.addChatStatus(data)
		} else {
			handleAutoRemoveError('not all fields required')
		}
	}

	useEffect(() => {
		const addCompleted = ChatsActions.addChatStatus.completed.listen(() => {
			onClose()
		})
		const addFailed = ChatsActions.addChatStatus.failed.listen((res) => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		return () => {
			addCompleted()
			addFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Field>
					<Control>
						<Label>{T('name')}</Label>
						<Input
							name='name'
							value={value}
							onChange={handleChange}
						/>
					</Control>
				</Field>
				<Field>
					<Control>
						<Label>{T('position')}</Label>
						<NumberInput
							name='position'
							placeholder='1'
							onChange={handlePosition}
							value={position}
						/>
					</Control>
				</Field>
				<Field>
					<Control>
						<Label>{T('t-color')}</Label>
						<Input
							name='color'
							type='color'
							value={colorState}
							onChange={handleColor}
							style={{width: '44px'}}
						/>
					</Control>
				</Field>
				<SubmitButton submit={handleSubmit} text={isEdit ? 'btn-edit' : 'add'} waiting={waiting}/>
			</Box>
		</>
	);
};

export default AddChatsStatus;