import React, {useEffect, useState} from 'react'
import Tabs from "react-bulma-components/lib/components/tabs";
import T from "../../../components/i18n";
import {useHistory, useParams} from "react-router-dom";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const DATA_TABS = [
	{
		tab: 'all'
	},
	{
		tab: 'products'
	},
	{
		tab: 'sales'
	},
	{
		tab: 'Payments'
	},
	{
		tab: 'clients'
	},
	{
		tab: 'additional-fields'
	},
	{
		tab: 'other_receipt'
	}

]

const CatalogTabs = () => {
	const {isMobileSize} = useIsMobile()
	const {catalogID} = useParams()
	const history = useHistory()

	const [activeTab, setActiveTab] = useState(catalogID || 'all')

	const handleNavigate = (tab) => {
		setActiveTab(tab)

		if(tab === 'all') {
			history.push('/settings/catalog/')
		} else {
			history.push(`/settings/catalog/${tab}`)
		}
	}

	useEffect(() => {
		if(!DATA_TABS.some(item => item.tab === catalogID) && catalogID) {
			history.push('/settings/catalog/')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Tabs type='boxed' fullwidth={false} size={isMobileSize ? "small" : "medium"}>
			{DATA_TABS.map(({tab}) => (
				<Tabs.Tab
					key={tab}
					active={activeTab === tab}
					onClick={() => handleNavigate(tab)}
				>
					{T(tab)}
				</Tabs.Tab>))}
		</Tabs>
	);
};

export default CatalogTabs;