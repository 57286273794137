import UserActions from "../../../../user/user-actions";
import {confirmAlert} from "react-confirm-alert";
import T from "../../../../components/i18n";

export const handleUpdateDownload = (type_loads, upload, download, data, isAll) => {
	const copyData = JSON.parse(JSON.stringify(data))

	if (typeof(copyData.ident) === "string") {
		copyData.ident = JSON.parse(copyData.ident);
	}

	if (upload) {
		copyData.ident.is_load = 2;
	} else if (download) {
		copyData.ident.is_load = 1;
	}

	if(isAll) {
		copyData.ident.only_load = 'all';
	}

	if (type_loads) {
		copyData['type_loads'] = type_loads;
	}

	copyData.ident = JSON.stringify(copyData.ident)
	UserActions.updateIntegrations(copyData)
}

export const loadProductsConfirm = (data) => {
	confirmAlert({
		title: T('loading-products'),
		message: T('loading-products-desc'),
		buttons: [
			{
				label: T('update-all-balances'),
				onClick: () =>  handleUpdateDownload(false, false, true, data)
			},
			{
				label: T('download-products'),
				onClick: () =>  handleUpdateDownload('without_stock', false, true, data)
			},
			{
				label: T('download-products-simple'),
				onClick: () =>  handleUpdateDownload('without_create', false, true, data)
			},
			{
				label: T('link-existing-products-without-creating'),
				onClick: () =>  handleUpdateDownload('only_sync', false, true, data)
			},
			{
				label: T('close'),
				onClick: () => {}
			}
		]
	});
}
