import React from 'react'
import {parseJSON} from "../../../../tools/error-handler";

const ReceiptViewContent = ({response}) => {
	const responseParse = parseJSON(response)
	return (
			<pre style={{textAlign: "center", width: "fit-content", margin: '0 auto'}}>
				{responseParse.receipt}
			</pre>
	);
};

export default ReceiptViewContent;