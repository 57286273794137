import React, {useEffect, useState} from 'react'
import Dropdown from "react-bulma-components/lib/components/dropdown";
import T from '../../../../components/i18n'
import NumberInput from "../../../../components/number-input/number-input";

const DiscountDropdownItem = ({discount_product, pid, onChange, refresh}) => {
	const [value, setValue] = useState((discount_product && Math.abs(discount_product)) || '0')
	const [active, setActive] = useState(!!discount_product || false)

	const [valueSelect, setValueSelect] = useState(discount_product >= 0 ? "%" : 'fix')

	const handleChangeSelectValue = (event) => {
		event.preventDefault()
		event.stopPropagation()
		setValueSelect(event.target.value);
		handleSubmit(event.target.value)
	}



	const handleChange = (evt) => {
		const value = evt.target.value
		if(value) {
			setValue(value)
		} else {
			setValue(value === 0 ? 0 : '')
		}
		setActive(false)
		onChange && pid && onChange({pid, type: 'discount', value: 0})
	}

	const handleSubmit = (selectValue) => {
		if(!active && !!parseFloat(value)) {
			let changeValue = parseFloat(value)
			if(selectValue === 'fix') {
				changeValue = 0 - changeValue
			}
			onChange && pid && onChange({pid, type: 'discount', value: changeValue})
			refresh(prev => prev + 1)
		} else {
			onChange && pid && onChange({pid, type: 'discount', value: 0})
		}

		setActive(prevState => {
			return !!(!prevState && !!parseFloat(value));
		})
	}

	const handleClickEnter = (e) => {
		if(e.key === 'Enter') {
			handleSubmit(valueSelect)
		}
	}

	useEffect(() => {
		if(!discount_product) {
			setActive(false)
		}
	}, [discount_product]);


	return (
		<Dropdown.Item value='discount' key={T('discount')} className={active ? `active-discount` : ''}>
			<div className='dropdown-custom-price-item' onClick={() => handleSubmit(valueSelect)} onKeyDown={handleClickEnter}>
              <span>
                {T('sales-discount')}
              </span>
						<NumberInput
							size='small'
							type='text'
							className='dropdown-discount-input'
							value={value}
							onChange={handleChange}
							onClick={(evt) => evt.stopPropagation()}
						/>
				<select onChange={handleChangeSelectValue} value={valueSelect} style={{height: 24.38, width:52}}>
					<option value='%'>
						%
					</option>
					<option value='fix'>
						{T('fix')}
					</option>
				</select>
			</div>
		</Dropdown.Item>
	)
};

export default DiscountDropdownItem;