import React from "react";
import UserStore from "../../../../user/user-store";
import ExpensesStore from "../../../expenses/expenses-store";
import SubmitButton from "../../../../components/submit-button";
import T from "../../../../components/i18n";
import SalesStore from "../../sales-store";
import AppStore from "../../../../app-store";
import PrepaymentFiscalization from "../../modal/prepayment-fiscalization/prepayment-fiscalization";
import DpsCheckModal from "../../modal/dps-check-modal/dps-check-modal";
import PrintElement from "../../../../tools/print/elem";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const FCheckButton = ({
  checkId,
  typeCheck,
  orderId,
  account,
  prepaidAccount,
  prepaidAmount,
  waiting,
}) => {
  const {isMobileSize} = useIsMobile()
  const listPrro = UserStore.getPRROList(orderId);

  const handleSubmit = (isWithPrepaid) => {
    SalesStore.closeModal();
    if (isWithPrepaid) {
      AppStore.openModal(
        <PrepaymentFiscalization
          modal='fiscal'
          oid={orderId}
          amount={prepaidAmount}
          accountId={prepaidAccount}
        />
      );
    } else {
      AppStore.openModal(<DpsCheckModal modal='fiscal' oid={orderId} />);
    }
  };

  if (!checkId && !!listPrro.length) {
    return (
      <>
        {!ExpensesStore.isDebt(account) && (
          <SubmitButton
            fullwidth={false}
            text={T("send-to-dps")}
            size={isMobileSize ? "small" : "medium"}
            color="success"
            waiting={waiting}
            submit={() => handleSubmit()}
          />
        )}
        {prepaidAmount > 0 && !ExpensesStore.isDebt(prepaidAccount) && (
          <SubmitButton
            fullwidth={false}
            text={T("send-to-dps-prepaid")}
            size={isMobileSize ? "small" : "medium"}
            color="success"
            waiting={waiting}
            submit={() => handleSubmit(true)}
          />
        )}
      </>
    );
  }
  if(checkId) {
    let url = ''
    if(typeCheck === 'vchasno') {
      url = SalesStore.getConvertPdfToImage(SalesStore.getVchasnoKasaPrintUrl(checkId, 'pdf'))
    } else {
      url = SalesStore.getCheckboxPrintSlip(checkId)
    }
    return <PrintElement url={url} text='print-fiscal-slip' />
  }

  return null
};

export default FCheckButton;
