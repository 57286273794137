import React, { Component } from "react";
import User from 'user/user-store';
import T from "components/i18n";
import { Button } from 'react-bulma-components';
import { printPdf } from "tools/print";
import Loader from "react-loader-spinner";
import {
    withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";


class PrintAction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }

        this.url = props.url;
        this.text = props.text;
        this.size = props.size || this.props.isMobileSize ? "small": "medium"
        this.timeout = null

        this.onClickButton = this.onClickButton.bind(this)
    }

    componentDidMount() {
        if(this.props.isAutoPrint) {
            this.onClickButton()
        }
    }

    onClickButton() {
        this.setState({
            isLoading: true
        })

        if(User.isMobileApp()) {
            User.postMessage("print-url::"+this.url)
        } else {
            printPdf(this.url)
        }


        this.timeout = setTimeout(() => this.setState({isLoading: false}) ,5000)
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    defaultButton(){
        return (
            <Button size={this.size} rounded color="light" onClick={this.onClickButton} disabled={this.state.isLoading} >
                {this.state.isLoading && <Loader type="Circles" className="button_loader" color="#000" height={30} width={30}/>}
                {T(this.text)}
            </Button>
        )
    }
    render() {
        return this.defaultButton();
    }
}


export default withIsMobileSize(PrintAction);
