const splitExps = {};

function toFixed(value) {
    let parts, e, i, f;

    parts = String(value).split("e");
    e = +parts[1];
    parts = parts[0].split(".");
    i = parts[0] || "0";
    f = parts[1] || "";

    if (e > 0) {
        i = i + f.substr(0, e) + "0".repeat(Math.max(0, e - f.length));
        f = f.substr(e);
    } else if (e < 0) {
        f = "0".repeat(Math.max(0, -i.length - e)) + i.substr(e) + f;
        i = i.substr(0, i.length + e) || "0";
    }

    return i + (f.length ? "." + f : "");
}

function toFloat(value, precision) {
    const _precision = isFinite(precision) ? precision : 0;
    let pow10, parts, f;
    if (String(value).indexOf("e") !== -1) {
        value = toFixed(value);
    } else if (isFinite(precision) && _precision >= 0) {
        pow10 = Math.pow(10, _precision + 2);
        value = Math.round(value * pow10) / pow10;
    }

    parts = String(value).split(".");
    f = parts[1] || "";

    if (isFinite(precision) && _precision >= 0) {
        f = (f + "0".repeat(Math.max(0, _precision - f.length))).substr(0, _precision);
    }

    return parts[0] + (f.length ? "." + f : "");
}

function groupDigits(value, groupBy, groupSeparator) {
    let re;

    value = String(value);

    if (groupBy > 0 && groupBy < Number.POSITIVE_INFINITY) {
        re = splitExps.hasOwnProperty(groupBy) ? splitExps[groupBy]
            : (splitExps[groupBy] = new RegExp("\\B(?=(\\d{" + groupBy + "})+(?!\\d))", "g"));

        value = value.replace(re, groupSeparator || "");
    }

    return value;
}

function formatNumber(value, params) {
    const parts = toFloat(value, params.precision || 0).split(".");

    return (params.sign && value > 0 ? "+" : "") + groupDigits(parts[0], params.groupBy, params.groupSeparator)
        + (parts.length > 1 ? (params.dot || ".") + parts[1] : "");
}


export default function numberFormat(value, params) {
    if (typeof params !== "object") {
        if (typeof(params) === "undefined") {
            params = 2
        }
        params = {
            precision: params,
            sign: arguments[2],
            groupBy: 3,
            groupSeparator: " "
        };
    }

    return typeof value === "number" && !isFinite(value) ? String(value) : formatNumber(+value || 0, params);
};
