import React, {useEffect, useState} from "react";
import { Control, Label } from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import {Icon} from "react-bulma-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalIcon } from "whitelables/wl-name/icons";
import NumberInput from "../../../../components/number-input/number-input";
import s from './styles.module.scss'
import ExpensesStore from "../../../expenses/expenses-store";

const CommissionBlock = ({
  commission,
  price,
  fee,
  defaultFee,
  currency,
  onChangeFee,
  accountId,
  preAccountId,
  preAmount,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const [defaultPrice, setDefaultPrice] = useState(price)
  const [defaultCommission, setDefaultCommission] = useState(commission.fee)
  const commissionFee = parseFloat(fee || (((price || 0) * commission.fee) / 100).toFixed(2))
  const [feeInputState, setFeeInputState] = useState(commissionFee)


  useEffect(() => {
    if(parseFloat(defaultPrice) !== parseFloat(price)) {
      setFeeInputState(((price * commission.fee) / 100).toFixed(2))
      setDefaultPrice(price)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]);

  const handleChangeInput = (evt) => {
    const value = evt.target.value;
    setFeeInputState(value);
  };


  useEffect(() => {
      if(defaultCommission !== commission.fee) {
        setFeeInputState(((price * commission.fee) / 100).toFixed(2))
        setDefaultCommission(commission.fee)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commission]);

  const handleSubmitFee = () => {
    const evt = {
      target: {
        name: "fee",
        value: (+feeInputState).toFixed(2),
      },
    };
    setFeeInputState(evt.target.value);
    setIsEdit(false);
    onChangeFee(evt);
  };

  const handleRevertChange = () => {
    const evt = {
      target: {
        name: "fee",
      },
    };

    if(parseFloat(defaultPrice) === parseFloat(price) && defaultFee) {
      evt.target.value = defaultFee
    } else {
      evt.target.value = ((price * commission.fee) / 100).toFixed(2)
    }

    setFeeInputState(evt.target.value)
    onChangeFee(evt);
    setIsEdit(false);
  };

  const handleDecline = () => {
    setIsEdit(false);
    if(parseFloat(defaultPrice) === parseFloat(price)) {
      setFeeInputState(fee)
    } else {
      setFeeInputState(((price * commission.fee) / 100).toFixed(2))
    }
  };

  const commissionAccountId = ExpensesStore.getAccountCommissionToByOidInSale(accountId);
  const commissionPreAccountId = ExpensesStore.getAccountCommissionToByOidInSale(preAccountId)

  const amountWithoutPreAmount = (parseFloat(price) || 0) - (parseFloat(preAmount) || 0);

  const commissionAccountAmount = parseFloat(amountWithoutPreAmount) * commissionAccountId / 100
  const commissionPreAccountAmount = parseFloat(preAmount) * commissionPreAccountId / 100

  const activeAccountCommission =  !!commissionAccountId || !!commissionPreAccountId
  const activeChannelCommission = commission.fee > 0

  const activeAllAccountCommission = !!commissionAccountId && !!commissionPreAccountId

  const allCommissionActive = activeChannelCommission && activeAccountCommission

  return (
    <div align="right" className="commission-wrapper">
			<div className={s.wrapper}>
				<div className={`${s.wrapper_commission} ${allCommissionActive ? s.active : ''}`}>
          {allCommissionActive && <div className={s.commission_heading}>
            <span>{T('commission-label')}: </span> {((parseFloat(feeInputState) || 0) + (commissionAccountAmount || 0) + (commissionPreAccountAmount || 0)).toFixed(2)} {currency}
          </div>}
					{activeAccountCommission && (
						<div className="commission-block">
								<div className="commission-block">
									<Label align="right" style={allCommissionActive ? {paddingLeft: '10px'} : {}}>
										{commission.fee > 0 ? activeAllAccountCommission ? T('sale-accounts') :  T("sale-account") : activeAllAccountCommission ? T('accounts-commission-label') : T('account-commission-label')}: {((commissionAccountAmount || 0) + (commissionPreAccountAmount || 0)).toFixed(2)} {currency}
									</Label>
								</div>
						</div>
					)}
					{activeChannelCommission && <>{ isEdit ? (
              <div className="commission-block">
                <Control iconRight>
                  <NumberInput
                    size="small"
                    name="fee"
                    placeholder="0"
                    max={price}
                    onChange={handleChangeInput}
                    value={feeInputState}
                  />
                  <Icon align="right" className="discount_type_icon">
                    <span>{currency}</span>
                  </Icon>
                </Control>
                <span className="icon-action close" onClick={handleDecline}>
                  <LocalIcon icon="close-fill" width={14} />
                </span>
                <span className="icon-action confirm" onClick={handleSubmitFee}>
                  <LocalIcon icon="checkmark" width={14} />
                </span>
              </div>
          ) : (
						<div className="commission-block">
								<div className="commission-block">
									<Label align="right" style={allCommissionActive ? {paddingLeft: '10px'} : {}}>
										{activeAccountCommission ? T("sale-channel") : T('commission-sales-chanel')}: {feeInputState || 0} {currency}
									</Label>
									<span
										className="icon-action link"
										onClick={() => setIsEdit((prevState) => !prevState)}
									>
										<FontAwesomeIcon icon="edit" size="1x" />
									</span>
									<span className="icon-action link" onClick={handleRevertChange}>
										<FontAwesomeIcon icon="undo" size="1x" />
									</span>
								</div>
						</div>
					)}
          </>}
				</div>
			</div>
    </div>
  );
};

export default CommissionBlock;
