import React from 'react'
import User from "../user/user-store";
import T from "../components/i18n";
import s from './styles.module.scss'
import {useHistory} from "react-router-dom";
import UserStore from "../user/user-store";

const SidebarTarif = ({color, colorTarif}) => {
	const history = useHistory()
	const dataTarif = User.getTarifDataAccount()
	return (
		<div className={s.wrapper}>
			<span className={`${s.tarif} ${UserStore.isMainUser() ? s.tarif_with_hover : ''}`} style={{color: colorTarif || ''}} onClick={() => UserStore.isMainUser() && history.push('/settings/subscribe')}>
				{<b>{T('tarif-label')}: {dataTarif['name'] ? T('tarif-plan-' + dataTarif['name']) : "undefined"}</b>}
			</span>
			<div className={s.sales} style={{color: color || 'rgba(255,255,255,0.2)'}}>
				{T('sales-count-subscribe', {count: User.getCurrentState('sales') || 0})}
			</div>
		</div>
	);
};

export default SidebarTarif;