import React from "react";

import T from "components/i18n";
import OpenInsideModal from "components/modal/open-inside-modal";
import ChangeNetPriceModal from '../change-netprice-modal';


const getCalcNetPrice = (pid, mid, price) => {
        return (

            <OpenInsideModal size="small" text={T('calculate-netcost')} renderAs="a">
                <ChangeNetPriceModal pid={pid} mid={mid} price={price}/>
            </OpenInsideModal>
        )
};

export default getCalcNetPrice;

