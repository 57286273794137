import React from "react";
import User from "../../../../user/user-store";
import Level from "react-bulma-components/lib/components/level";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import DpsCheckModal from "../../modal/dps-check-modal/dps-check-modal";
import ExpensesStore from "../../../expenses/expenses-store";

const FisclizationButton = ({ orderID, dps, accountID }) => {
  return (
    <>
      {User.isButtonDps({ data: dps, orderID }) && !ExpensesStore.isDebt(accountID) && (
        <Level.Item className="sales-buttons-item">
          <OpenInsideModal
            className="margin-left-5"
            color="success"
            text={"send-to-dps"}
          >
            <DpsCheckModal oid={orderID} modal='fiscal'/>
          </OpenInsideModal>
        </Level.Item>
      )}
    </>
  );
};

export default FisclizationButton;
