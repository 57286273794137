import { createActions } from "tools/reflux-tools";


const ProductsActions = createActions({
    loadStock: { asyncResult: true },
    loadHistory: { asyncResult: true },
    setPrice: { asyncResult: true },
    setGroupPrice: { asyncResult: true },
    loadExcel: { asyncResult: true },
    loadXml: { asyncResult: true },
    uploadImages: { asyncResult: true },
    loadCategory: { asyncResult: true },
    changeStock: { asyncResult: true },
    getImagesList: { asyncResult: true },
    addProduct: { asyncResult: true },
    deleteProduct: { asyncResult: true },
    addBrand: { asyncResult: true },
    loadBrand: { asyncResult: true },
    addCategory: { asyncResult: true },
    openProductModal: { asyncResult: true },
    deleteImage: { asyncResult: true },
    needClearImageCache: { asyncResult: true },
    deleteCatalog: { asyncResult: true },
    loadAttr: { asyncResult: true },
    deleteAttr: { asyncResult: true },
    addAttr: { asyncResult: true },
    filter: { asyncResult: true },
    needReload: { sync: true },
    setSort: { sync: true },
    getBundle: { asyncResult: true },
    setProductFromScanner: { asyncResult: true },
    importFromRemote: { asyncResult: true },
    loadNetPrices: { asyncResult: true },
    setGroupNetPricesPrice: { asyncResult: true },
    printTags: { asyncResult: true },
    setPriceList: { asyncResult: true },
    deletePriceList: { asyncResult: true },
    loadPricesList: { asyncResult: true },
    remoteProducts: { asyncResult: true },
    changeSelectRemote: { asyncResult: true },
    remoteProductByPid: {asyncResult: true},
    loadByPid: {asyncResult: true},
    rotateImage: {asyncResult: true },
    controlImage: {asyncResult: true },
    updateCashRotate: {asyncResult: true },
    loadAdditionalInfoProduct: { asyncResult: true },
    copyStock: { asyncResult: true },
});


export default ProductsActions;