import React from 'react'
import T from "../i18n";
import s from "./styles.module.scss";
import { LocalIcon } from 'whitelables/wl-name/icons'

const GroupButtonsWrapperDesktop = ({count,onDeSelect, children}) => {
	return (
		<div className={s.wrapper}>
			<div className="display-flex-row">
				{onDeSelect && (
					<span className='text-danger cursor-pointer' onClick={onDeSelect}>
						<LocalIcon icon='circle-close'/>
					</span>)}
				<b className='nowrap'>{T('select-items')}: {count}</b>
			</div>
			<div className={s.buttons}>
			{children}
			</div>
		</div>
	);
};

export default GroupButtonsWrapperDesktop;