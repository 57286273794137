import React, {useEffect, useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import {
	Control,
	Field, Input,
	Label
} from "react-bulma-components/lib/components/form";
import T from "../../../components/i18n";
import Button from "react-bulma-components/lib/components/button";
import SubmitButton from "../../../components/submit-button";
import ErrorMessageBlock
	from "../../../components/error-message-block/error-message-block";
import WriteOffStore from "../write-off-store";
import {connectToStores} from "../../../tools/reflux-tools";
import WriteOffActions from "../write-off-actions";
import {errorHandler} from "../../../tools/error-handler";

const AddWriteOffCategoryModal = ({id,name, onClose}) => {
	const [inputValue, setInputValue] = useState(name)
	const [errorMessage, setErrorMessage] = useState(null)
	const [waiting, setWaiting] = useState(false)

	const handleAutoRemoveError = (value) => {
		setTimeout(()=> setErrorMessage(null), 4000)
		setErrorMessage(value)
	}

	const handleChange = (evt) => {
		setInputValue(evt.target.value)
	}

	const handleError = () => {
		let error = ''
		if(!inputValue?.length) {
			error = T('not all fields required')
		}

		if(error) {
			handleAutoRemoveError(error)
		}

		return !error
	}

	const handleSubmit = () => {
		const data = {name: inputValue}
		if(id) data.id = id

		if(handleError()) {
			WriteOffActions.addCategory(data)
			setWaiting(true)
		}
	}

	useEffect(() => {
		const addCategoryCompleted = WriteOffActions.addCategory.completed.listen(() => {
			onClose()
		})
		const addCategoryFailed = WriteOffActions.addCategory.failed.listen((res) => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		return () => {
			addCategoryCompleted()
			addCategoryFailed()
		}

	}, [onClose]);

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Field>
					<Control>
						<Label>{T('write-off-category-name-label')}</Label>
						<Input  size="large"
										name="name"
										type="text"
										placeholder={T('write-off-category-placeholder')}
										onChange={handleChange}
										color={errorMessage ? "danger" : "primary"}
										value={inputValue}
						/>
					</Control>
				</Field>
				<Button.Group>
						<SubmitButton
							text={T(id ? 'btn-edit' : 'add-category-btn-label')}
							waiting={waiting}
							submit={handleSubmit}
						/>
				</Button.Group>
			</Box>
		</>

	);
};

export default connectToStores(AddWriteOffCategoryModal, {
	store: WriteOffStore
});