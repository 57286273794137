import React from "react";
import T from "components/i18n";
import Money from "components/money";
import Columns from 'react-bulma-components/lib/components/columns';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';


const PartnersStats = ({partners}) => {
  const getBalanceTotal = () => {
    let balance = 0;
    for (let partner of partners) {
      balance += parseFloat(partner.balance)
    }
    return parseFloat(balance)
  }

  const getPayoutTotal = () => {
    let payout = 0;
    for (let partner of partners) {
      payout += parseFloat(partner.payout)
    }
    return parseFloat(payout)
  }

  return (
      <Columns className="statisctic">
        <Columns.Column>
          <Box>
            <Heading heading>
              {T('partners-balance-total')}: 
            </Heading> 
            <Heading>
              <Money amount={getBalanceTotal()} />
            </Heading>
          </Box>
        </Columns.Column>
        <Columns.Column>
            <Box>
              <Heading heading>
                {T('partners-pay-out-total')}: 
              </Heading>
              <Heading>
                <Money amount={getPayoutTotal()} />
              </Heading>
            </Box>
        </Columns.Column>
      </Columns>
    )
}

export default PartnersStats;