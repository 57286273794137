import {createActions} from "../../tools/reflux-tools";

const HistoryActions = createActions({
    load: { asyncResult: true },
    filter: { sync: true },
    filterDeleted: { sync: true },
    loadProductsDeleted: {asyncResult: true},
    restoreProduct: {asyncResult: true},
    stockMarketplaces: {asyncResult: true},
    repeatStockMarketplaces: {asyncResult: true},
    loadSalesDeleted: {asyncResult: true},
    filterDeletedSales: {asyncResult: true},
});

export default HistoryActions;