import React from "react";
import { Box, Heading, List } from "react-bulma-components";
import T from "../../../../components/i18n";
import ClientsStore from "../../../clients/clients-store";
import Money from "../../../../components/money";
import Tag from "../../../../components/tag";
const SaleClientInfo = ({ clientID, accountID }) => {
  const client = ClientsStore.getClientById(clientID);
  const tagsGroup = ClientsStore.getGroupClientTagByClientId(clientID);
  return (
    <Box>
      <Heading size={4}>{T("client-info-label")}:</Heading>
      <div
        className="display-flex-row"
        style={{ alignItems: "flex-start", gap: 10 }}
      >
        <List style={{ flex: 1 }}>
          {client?.first_name && (
            <List.Item>
              <b>{T("client-name")}: </b>
              {client.first_name}
            </List.Item>
          )}
          {client?.last_name && (
            <List.Item>
              <b>{T("last-name")}: </b>
              {client.last_name}
            </List.Item>
          )}
          {client?.phone && (
            <List.Item>
              <b>{T("phone")}: </b>
              {client.phone}
            </List.Item>
          )}

          {!!tagsGroup?.length && (
            <List.Item className="display-flex-row">
              <b>{T("client-tags")}: </b>
              {tagsGroup.map((item) => (
                <Tag
                  key={`tag-${item.id}`}
                  color={item.color}
                  name={item.name}
                />
              ))}
            </List.Item>
          )}
        </List>
        <List style={{ flex: 1 }}>
          <List.Item className="display-flex-row">
            <b>{T("clients-total_amount")}: </b>
            <Money
              aid={accountID}
              amount={client.total_amount}
              className="text-success"
            />
          </List.Item>
          <List.Item className="display-flex-row">
            <b>{T("clients-debt")}: </b>
            <Money
              aid={accountID}
              amount={client?.debt || 0}
              className="text-danger"
            />
          </List.Item>
          <List.Item className="display-flex-row">
            <b>{T("balance")}: </b>
            <Money aid={accountID} amount={client?.balance || 0} />
          </List.Item>
        </List>
      </div>
    </Box>
  );
};

export default SaleClientInfo;
