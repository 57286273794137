import React from 'react'
import T from '../../../components/i18n'
import Button from "react-bulma-components/lib/components/button";
import {changeStatus} from "./actions-func";
import OrdersStore from "../orders-store";

const StatusButton = ({data, isRefund}) => {
	const statusCompleted = data['status'] === 'completed'
	const getStatusName = OrdersStore.getStatusById(data.status)?.name || data.status
	return statusCompleted && !isRefund ? (
		<div className={"order-status order-status-"+data['status']} style={{marginLeft: 0}}>
			{T(getStatusName)}
		</div>
	) : (
		<Button
			size="small"
			onClick={(evt) => changeStatus({evt, data})}
			color='info'
			rounded
			style={{marginLeft: 0}}
			className={`${isRefund ? 'order-status order-status-refund' : `order-status order-status-${data['status']}`}`}
		>
			{T(getStatusName)}
		</Button>
	);
};

export default StatusButton;