import React from "react";
import Container from "../../common/container/container";
import s from "./styles.module.scss";
import T from "../../../components/i18n";
import Title from "../../common/title/title";
import SubTitle from "../../common/sub-title/sub-title";

const IntegrationItem = ({ src, alt, soon }) => {
  return (
    <span className={soon ? s.soon : ""}>
      <img className={s.integration} src={src} alt={alt} />
      {soon && <p className={s.soon_block}>{T("_soon")}</p>}
    </span>
  );
};

const IntegrationBlock = ({list, title, subTitle}) => {
  return (
    <Container classNameWrapper={s.wrapper} classNameContainer={s.section}>
      <Title>{T(title)}</Title>
      <SubTitle>{T(subTitle)}</SubTitle>
      <section className={s.integration_wrapper}>
        {list?.map((item) => (
          <IntegrationItem
            key={item.alt}
            src={item.src}
            alt={item.alt}
            soon={item.soon}
          />
        ))}
      </section>
    </Container>
  );
};

export default IntegrationBlock;
