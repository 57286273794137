import React from 'react'
import Button from "react-bulma-components/lib/components/button";
import User from "../../../user/user-store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SalesActions from "../../sales/sales-actions";
import LightStore from "../light-store";
import T from "../../../components/i18n";
import OpenInsideModal from "../../../components/modal/open-inside-modal";
import AddToShipment from "../add-to-shipment";
import AddToMoving from "../add-to-moving";
import ChangePriceModal from "../modal/change-price-modal/change-price-modal";
import ChangeStockModal from "../change-stock-modal";
import {deleteProduct} from "./function";
import AppStore from "../../../app-store";
import PriceTagCountModal
	from "../../../components/price-tag-count-modal/price-tag-count-modal";
import {Link} from "react-router-dom";
import ProductsStore from "../products-store";
import {createMobileAppLink} from "../../../tools/create-mobile-app-link";
import CopyProductToMid from "../modal/copy-product-to-mid/copy-product-to-mid";

const GroupButtonList = () => {
	LightStore.getProductsIdsWithMidForDelete()
	return (
		<>
			{User.isCanAddSale() ?
				<Button
					size="small" rounded color="info" icon={<FontAwesomeIcon icon="trash-alt" size="1x" />}
					onClick={() => SalesActions.openSaleModal(LightStore.getProductsSelectOptions())}
				>{T('add-sale-btn')}</Button> : null}

			{User.getModuleEnable("shipments") && !!LightStore.getCountWithoutDropAndServicesProduct()?
				<OpenInsideModal size="small" color="info" text={T('add-to-shipment-btn')}>
					<AddToShipment />
				</OpenInsideModal> : null}

			{User.getModuleEnable("moving") && !!LightStore.getCountWithoutDropAndServicesProduct()?
				<OpenInsideModal size="small" color="info" text={T('add-to-moving')}>
					<AddToMoving modal='moving'/>
				</OpenInsideModal> : null}

			{User.getPermissionEnable("editproducts") && User.getPermissionEnable("salesprice") ?
				<OpenInsideModal size="small" color="primary" text={T('change-price-btn')}>
					<ChangePriceModal />
				</OpenInsideModal> : null}
			{User.getPermissionEnable("editproducts") && User.getPermissionEnable("incomeinformation") ?
				<OpenInsideModal size="small" color="primary" text={T('change-netcost-btn')}>
					<ChangePriceModal type='netcost'/>
				</OpenInsideModal> : null}

			{User.getPermissionEnable("editinstock") && !!LightStore.getCountWithoutDropAndServicesProduct() ?
				<OpenInsideModal size="small" color="primary" text={T('change-stock-btn')}>
					<ChangeStockModal group={true}/>
				</OpenInsideModal> : null}
			{User.getModuleEnable("pricetags") ?
				// <Link  to={ProductsStore.getPrintTabLink(LightStore.getProductsIdsWithMid())} target="_blank" title={T('print-tags')}>
					<Button size="small" rounded color="primary"
									onClick={() => AppStore.openModal(<PriceTagCountModal products={LightStore.getProductsIdsWithMid()} />)}
					>
						{T('print-tags')}
					</Button>
				// </Link>
				: null}
			<Button
				size="small"
				rounded
				color="primary"
				renderAs={Link}
				onClick={(evt) => createMobileAppLink(ProductsStore.getExportCsv(LightStore.getProductsIdsWithMid()), evt)}
				to={ProductsStore.getExportCsv(LightStore.getProductsIdsWithMid())}
				target='_blank'
			>
				{T('export-excel')}
			</Button>
			{!!LightStore.getProductsIdsWithMid(3).length && <Button
				size="small"
				rounded
				color="primary"
				onClick={() => {
					AppStore.openModal(<CopyProductToMid modal='without-scroll'
																							 data={LightStore.getProductsIdsWithMid(3)}/>)
				}}
			>
				{T('copy-to-warehouse')}
			</Button>}
			{User.getPermissionEnable("deleteproducts") && !!LightStore.getProductsIdsWithMidForDelete().length ?
				<Button
					size="small" rounded color="warning" icon={<FontAwesomeIcon icon="trash-alt" size="1x" />}
					onClick={() => deleteProduct(LightStore.getProductsIdsWithMidForDelete(), null, LightStore.get("count"))}
				>{T('delete')}</Button> : null}

		</>
	);
};

export default GroupButtonList;