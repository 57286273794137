import React from "react";
import s from "./styles.module.scss";
import {
  Checkbox,
  Control,
  Field,
  Input,
  Label,
  Textarea,
} from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import NumberInput from "../../../../components/number-input/number-input";
import { Icon } from "react-bulma-components";

const SettingBlock = ({
  type,
  values,
  label,
  subTitle,
  listCheckbox,
  listInput,
  onChangeCheckbox,
  onChangeInput,
}) => {
  const handleChangeCheckbox = ({ target: { name, checked } }) => {
    onChangeCheckbox && onChangeCheckbox(name, checked, type);
  };

  const handleChangeInput = ({ target: { name, value } }) => {
    console.log(name, value);
    onChangeInput && onChangeInput(name, value, type);
  };

  return (
    <div className={s.wrapper}>
      <Label size="large" className={s.heading}>
        {T(label)}
      </Label>
      {subTitle && (
        <p style={{ textAlign: "center", width: "100%" }}>{T(subTitle)}</p>
      )}
      {listInput?.map((item) => (
        <Field key={item.name} style={{ width: "100%" }}>
          {item.label && <Label align="left">{T(item.label)}</Label>}
          <Control iconRight={!!item.icon}>
            {item.type === "number" && (
              <NumberInput
                name={item.name}
                value={values[item.name]}
                onChange={handleChangeInput}
              />
            )}
            {item.type === "text" && (
              <Input
                name={item.name}
                value={values[item.name]}
                onChange={handleChangeInput}
              />
            )}
            {item.type === "textarea" && (
              <Textarea
                rows={3}
                name={item.name}
                value={values[item.name]}
                onChange={handleChangeInput}
              />
            )}
            {!!item.icon && (
              <Icon align="right" style={{ top: "3px" }}>
                <span>{item.icon}</span>
              </Icon>
            )}
          </Control>
        </Field>
      ))}
      {!!listCheckbox?.length && (
        <Label align="left">{T("which-fields-show")}</Label>
      )}
      <div className={s.checkbox_wrapper}>
        {listCheckbox?.map((item) => (
          <Field key={item.value}>
            <Checkbox
              name={item.value}
              checked={!!values?.settings[item.value]}
              onChange={handleChangeCheckbox}
            >
              {T(item.label)}
            </Checkbox>
          </Field>
        ))}
      </div>
    </div>
  );
};

export default SettingBlock;
