import React from 'react'
import { LocalIcon } from "whitelables/wl-name/icons";
import {Button} from "react-bulma-components";
import {useHistory} from "react-router-dom";

const IconVote = ({size, style}) => {
	const history = useHistory()

	const handleOpenVotePage = () => {
		history.push('/voting')
	}

	return (
		<Button className='button-link-icon' onClick={handleOpenVotePage} style={style}>
			<LocalIcon icon="vote" size={size || "large"}/>
		</Button>
	);
};

export default IconVote;