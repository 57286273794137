import React from "react";
import s from "./styles.module.scss";
import AppStore from "../../app-store";
import UserStore from "../../user/user-store";
import {parsePhoneNumber} from "../../tools/parse-phone-number";
import {isAndroid} from "react-device-detect";

const CommunicationClient = ({phone, nickname, style, size }) => {
  const realPhone = parsePhoneNumber(phone, isAndroid)
  const isTelegram = nickname && nickname[0] === "@";
  const handleClickViber = (evt) => {
    if (AppStore.isMobileApp()) {
      evt.stopPropagation();
      evt.preventDefault();
      UserStore.postMessage(`open-url::viber://chat?number=${realPhone}`);
    }
  };

  const handleClickTelegram = (evt) => {
    if (AppStore.isMobileApp()) {
      evt.stopPropagation();
      evt.preventDefault();
      UserStore.postMessage(
        `open-url::tg://resolve?domain=${nickname.slice(1)}`
      );
    }
  };

  return (
    (isTelegram || phone) && (
      <div className={s.wrapper} style={style}>
        {phone && (
          <a
            className={`${s.link} ${size === "large" ? s.large : ""}`}
            href={`viber://chat?number=${realPhone}`}
            onClick={handleClickViber}
            target='_blank'
            rel="noopener noreferrer"
          >
            <img src="/img/Viber_logo.svg" alt={realPhone} />
          </a>
        )}
        {isTelegram && (
          <a
            className={`${s.link} ${size === "large" ? s.large : ""}`}
            href={`tg://resolve?domain=${nickname.slice(1)}`}
            rel="noopener noreferrer"
            onClick={handleClickTelegram}
          >
            <img src="/img/Telegram_logo.svg" alt={phone} />
          </a>
        )}
      </div>
    )
  );
};

export default CommunicationClient;
