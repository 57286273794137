const filterSearch = (search, field_list, data)  => {
     function quote(regex) {
       return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
     }

     let result = {};
     if (search !== "" && search.length >= 2) {

      const text = field_list.map((field) => (data[field]) ? data[field].toString().toLowerCase().trim() : "" || '').join(' '),
     	      search_term = search.toLowerCase().split(" ");

     		  result = true;
       		for (let j in search_term) {
              const term = quote(search_term[j]);
    	     		if (text.search(term) === -1) {
    	            result = false;
    	        } 
          }
        
     }

     return result;
};

export default filterSearch;