import React, { Component } from 'react';
import T from 'components/i18n';
import WarehouseStore from 'modules/warehouse/warehouse-store'
import SalesStore from 'modules/sales/sales-store'
import OrdersStore from 'modules/orders/orders-store'
import ChatsStore from 'modules/chats/chats-store'

import { connectToStores } from "tools/reflux-tools";

import {NavLink, withRouter} from "react-router-dom";
import Menu from 'react-bulma-components/lib/components/menu';

// import Image from 'react-bulma-components/lib/components/image';
import User from 'user/user-store';
import AppStore from 'app-store';

import { LocalIcon } from 'whitelables/wl-name/icons'
import Button from 'react-bulma-components/lib/components/button';
import {getDatePeriod} from "../tools/date-range";
import UserStore from "user/user-store";


class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        isSubmenuShown: {
          products: false,
          sales: false,
          reports: false
          },
        isProductsSubpageOpen: false
        };
      this.onBtnClick = this.onBtnClick.bind(this);
      this.checkLocation = this.checkLocation.bind(this);
    }

  modules = ['start', 'dashboard', 'orders',
    'chats', 'sales', 'products', 'clients',
    'reports', 'transactions', 'history'];

  onBtnClick(e, name) {
    e.stopPropagation();
    this.setState(prevState => ({ isSubmenuShown: { ...prevState.isSubmenuShown, [name]: !prevState.isSubmenuShown[name] } }))
  }

  componentDidMount() {
    this.checkLocation()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.location.pathname !== prevProps.location.pathname) {
      this.checkLocation();
    }
  }

  checkLocation() {
      // let path = window.location.pathname;
      const path = this.props.location.pathname

      if (path.startsWith("/products") || path.startsWith("/shipments") || path.startsWith("/moving") || path.startsWith("/inventory") || path.startsWith("/production") || path.startsWith("/write-off")){
      this.setState({ isSubmenuShown: { sales: false, products: true, reports: false }, isProductsSubpageOpen: true })
      } else if (path.startsWith("/sales")) {
      this.setState({ isSubmenuShown: { products: false, sales: true, reports: false }, isProductsSubpageOpen: false })
      } else if(path.startsWith("/reports")) {
        this.setState({ isSubmenuShown: { products: false, sales: false, reports: true }, isProductsSubpageOpen: false })
      } else {
      this.setState({ isSubmenuShown: {
          products: false,
          sales: false,
        }, isProductsSubpageOpen: false} )
    }
    }

    renderProductsItem() {
        // if (!User.getPermissionEnable("editinstock")) return null;

        return (
        <>    {User.getModuleEnable("shipments") ?
                <li key='shipments'>
                      <NavLink activeClassName="sidebar-active" strict to='/shipments'>{T('shipments')} </NavLink>
              </li> : null}
                {User.isMovingEnable() ? 
                <li key='moving'>
                      <NavLink activeClassName="sidebar-active" strict to='/moving'>{T('moving')} </NavLink>
                </li> : null}
                {User.getModuleEnable("inventory") ?
                <li key='inventory'>
                      <NavLink activeClassName="sidebar-active" strict to='/inventory'>{T('inventory')} </NavLink>
                </li> : null}
                {User.getModuleEnable("production") && AppStore.isEnableForWhiteLable('production') ?
                <li key='production'>
                      <NavLink activeClassName="sidebar-active" strict to='/production'>{T('production-'+AppStore.getWlName())} </NavLink>
                </li> : null}
                {User.getModuleEnable("write-off") ?
                <li key='write-off'>
                  <NavLink activeClassName="sidebar-active" strict to='/write-off'>{T('write-off-label')} </NavLink>
                </li> : null}
        </>

      )
    }

    renderSaleItem(name) { 
        if (!User.getModuleEnable("reserv") && !User.isDeliveryEnable("reserv")) {
          return null;
        }
        const count_ = SalesStore.getCountDelivery();
      
        let custom_statuses = [];

        for (let st in count_) {
          if (!SalesStore.isDefaultStatus(st)) {
            const statusName = OrdersStore.getStatusNameById(st) || `${T("status")} ${st}`;
            custom_statuses.push(
                    <li key={st}>
                        <NavLink activeClassName="sidebar-active" strict to={{
                                          pathname: '/sales/'+st,
                                          state: {
                                            period: getDatePeriod(93),
                                          }
                                        }} >{statusName}  <i className="number_c">({count_[st]})</i></NavLink>
                    </li>
                )
            }
        }

        return (
                <>{User.getModuleEnable("reserv") && count_['reserve_sale'] > 0 ? 
                <li key='reserv'>
                        <NavLink activeClassName="sidebar-active"  strict to={{
                              pathname: '/sales/reserv',
                              state: {
                                period: getDatePeriod(93),
                              }
                            }}>{T('reserv')} <i className="number_c">({count_['reserve_sale']})</i></NavLink>
                </li>
                : null}
                {User.isDeliveryEnable() ? 
                <li key='delivery'>
                        <NavLink activeClassName="sidebar-active"  strict to={{
                                          pathname: '/sales/delivery',
                                          state: {
                                            period: getDatePeriod(93),
                                          }
                                        }}>{T('wait-delivery')} <i className="number_c">({count_['delivery']})</i></NavLink>
                </li> : null }
                {User.isDeliveryEnable() && count_['delivering'] > 0 ? 
                <li key='delivering'>
                        <NavLink activeClassName="sidebar-active"  strict to={{
                                          pathname: '/sales/delivering',
                                          state: {
                                            period: getDatePeriod(93),
                                          }
                                        }}>
                          {T('delivering')}
                          <i className="number_c">({count_['delivering']})</i></NavLink>
                </li>
                : null}
                {User.isDeliveryEnable() && count_['cod'] > 0 ? 
                <li key='cod'>
                        <NavLink activeClassName="sidebar-active"  strict to={{
                                          pathname: '/sales/cod',
                                          state: {
                                            period: getDatePeriod(93),
                                          }
                                        }} >{T('need-make-cod')} <i className="number_c">({count_['cod']})</i></NavLink>
                </li>
                : null}

                {User.isDeliveryEnable() && count_['refund'] > 0 ? 
                <li key='refund'>
                        <NavLink activeClassName="sidebar-active"  strict to={{
                                          pathname: '/sales/refund-list',
                                          state: {
                                            period: getDatePeriod(93),
                                          }
                                        }} >{T('need-make-refund')} <i className="number_c">({count_['refund']})</i></NavLink>
                </li>
                : null}
                <li key='returns'>
                        <NavLink activeClassName="sidebar-active"  strict to={{
                                          pathname: '/sales/returns',
                                          state: {
                                            period: new Date(),
                                          }
                                        }} >{T('status-refund-label')} <i className="number_c">({SalesStore.getRefund().length})</i></NavLink>
                </li>


            {custom_statuses}
            </>
        )
    }

    renderReportItem() {
      return (
        <>
          <li>
            <NavLink activeClassName="sidebar-active"  strict to='/reports/products'>{T('products')}</NavLink>
          </li>
          {!!UserStore.getSubUsersDataList().length && <li>
            <NavLink activeClassName="sidebar-active" strict
                     to='/reports/employees'>{T('employees')}</NavLink>
          </li>}
          {User.getModuleEnable("payments") && <li>
            <NavLink activeClassName="sidebar-active" strict
                     to='/reports/finance-category'>{T('finance-category')}</NavLink>
          </li>}
        </>)
    }

    getSubItem(name) {
      let content;
        switch (name) {
            case 'sales':
              content = this.renderSaleItem()
              break;
            case 'products':
              content = this.renderProductsItem()
              break;
          case 'reports':
            content = this.renderReportItem()
            break;
            case '':
              default:
              break;
        }
      
      let className = "sub-menu"
      if (!this.state.isSubmenuShown[name]) {
        className += " is-closed"
      }

      const isMenuCollapsed = this.props.showCollapseMenu;

      return (
        <Menu.List className={className}>
            <li key='sub-menu-title'>
              <div className="sub-title-menu">
              <NavLink strict to={`/${name}`}>{T(`${name}`)}</NavLink>
              <hr />
              </div>
            </li>
          {isMenuCollapsed ? content : null}
          {(this.state.isSubmenuShown[name] && !isMenuCollapsed) ? content : null}
        </Menu.List>
      )
    }
  
    getToggleSubmenuBtn(name) {
      const { isSubmenuShown } = this.state;

      return (
      <>
        <Button className='toggle-submenu-btn' onClick={(e) => this.onBtnClick(e, name)}>
            <LocalIcon icon={isSubmenuShown[name] ? 'chevron-up' : 'chevron-down'} />
        </Button>
        </>
    )
    }

    getMenuItem(name) {
        const link = "/"+name;

        let count = null;

      if (name === "start" && (!User.isStartModule() || !User.isMainUser() )) {
        return null;
      }
      
      // if (["reports", "dashboard"].indexOf(name) > -1 && User.isCashier()) {
      //   return null;
      // }

      if (name === "orders" && !User.isIntegrationEnable()) {
        return null;
      }

      if (name === "orders") {
         let statuces = OrdersStore.get("statuses"),
            cunt_or = 0;

         const calc_status = ['processing', 'on-hold', 'pending', 'Нове замовлення'];
         for (let i in calc_status) {
             if (typeof(statuces[calc_status[i]]) !== "undefined") {
                    cunt_or += statuces[calc_status[i]]['count'];
             }
         }


         count = "("+cunt_or+")"; 
      }

      if (name === "chats" && !User.isIntegrationChats()) {
        return null;
      }

      if (name === "chats") {
        let not_read = ChatsStore.get("not_read");

        if (not_read > 0) {
            count = "("+not_read+")"; 
        }
      }

      let class_name = ""
      if (["products", "sales", 'reports'].indexOf(name) > -1) {
         class_name += " has-submenu";
      }
      if(User.getModuleEnable(name)) {
        return (
          <li key={name} className={class_name}>
            <NavLink
              activeClassName="sidebar-active"
              className={name === "products" && this.state.isProductsSubpageOpen ? "sidebar-active" : ""}
              to={link}>
              <LocalIcon icon={name} />
              <span>{T(name)}&nbsp;{count}</span>
            </NavLink>
            <span>{name === "sales" || name === "products" || name === 'reports' ? this.getToggleSubmenuBtn(name) : null}</span>
            {(name === "sales" || name === "products" || name === 'reports') ? this.getSubItem(name) : null}
          </li>
        )
      }

    }

    // {User.isNeedShipment()

			        // <li>
			        //   	<NavLink to="/ppc">{T('PPC')}</NavLink>
			        // </li>
    render () {
    	let links = [];

    	for (let name in this.modules) {
    		links.push(this.getMenuItem(this.modules[name]));
    	}

      return (
			<Menu>
			      <Menu.List>
			      		{links}
			      </Menu.List>
			</Menu>
			)}

      }

export default connectToStores(withRouter(SideBar), {
    products: WarehouseStore,
    orders: OrdersStore,
    chats: ChatsStore,
});