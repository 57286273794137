import T from "../../components/i18n";

export const parseJSON = (json) => {
	let result = {}

	try {
		result = JSON.parse(json)
	} catch (err) {
		result = json
	}

	return result
}


export const errorHandler = (res) => {
	let data = {};
	if (res && typeof(res['response']) != "undefined") {

		if(res.status === 404) {
			data = {"error": "unhandled_error"}
		} else if(res.status === 504) {
			data = {"error": "HTTP 504: Gateway Timeout"}
		} else {
			const parseError = parseJSON(res['response'])
			if(typeof(parseError.error) === 'string') {
				data = parseJSON(res['response']);
			} else if(parseError?.error && parseError.error.code) {
				data = {
					"error": parseError.error.code,
				}
			} else {
				data = {
					'error': "unhandled_error"
				}
			}
		}

	} else {
		data = {
			'error': "unhandled_error"
		}
	}

	return T(data['error']) || null
}