import React from "react";

import T from "components/i18n";

import Heading from "react-bulma-components/lib/components/heading";
import Level from "react-bulma-components/lib/components/level";
import Box from "react-bulma-components/lib/components/box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserStore from "user/user-store";
// import AppStore from 'app-store';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { isIOS } from "react-device-detect";

const getBonusData = (i, amount) => {
  const bonus = UserStore.get("bonus"),
    step = UserStore.getOnBoaringStep();

  if (
    bonus === 0 ||
    i === 0 ||
    i === 1 ||
    UserStore.getTarif() !== 1 ||
    step !== 5
  ) {
    return null;
  }

  const bonus_days = UserStore.calcBonusDays(amount);

  let return_data = "";
  if (isIOS) {
    return_data = (
      <Heading size={5} className="has-text-success">
        {T("FREE")} <br /> 14 {T("_days")}
      </Heading>
    );
  } else if (bonus_days > 65) {
    return_data = (
      <Heading size={5} className="has-text-success">
        {T("FREE")} <br /> {T("3-month")}
      </Heading>
    );
  } else if (bonus_days > 31) {
    return_data = (
      <Heading size={5} className="has-text-success">
        {T("FREE")} <br />
        {bonus_days} {T("_days")}
      </Heading>
    );
  } else if (bonus_days <= 31 && bonus > amount) {
    return_data = (
      <Heading size={5} className="has-text-success">
        {T("FREE")} <br /> 1{" "}
        <span style={{ textTransform: "lowercase" }}>{T("month")}</span>
      </Heading>
    );
  }

  return return_data;
};

const TarifBlock = ({ button }) => {
  let cur_id = UserStore.getPaymentCurrency();

  let getItem = (i, data) => {
    const amount = data["amount"][cur_id];

    return (
      <Level.Item key={i} style={{minWidth: '300px', maxWidth: '500px'}}>
        <Box>
          <Heading size={5}>{T("tarif-plan-" + data["name"])}</Heading>
          <Box>
            {getBonusData(i, amount)}
            <div className="big-number">{amount}</div>
            <div>{T("cur-month-" + cur_id)}</div>
          </Box>
          <b>{T("you-can-add")}:</b>
          <br />
          <FontAwesomeIcon icon={faCheckCircle} /> {data["sales"]}{" "}
          {T("sales-month")} <br />
          <FontAwesomeIcon icon={faCheckCircle} /> {data["products"]}{" "}
          {T("tarif-products")} <br />
          <div className="margin-top-10">{getButton(i) || ""}</div>
        </Box>
      </Level.Item>
    );
  };

  let getButton = () => "";

  if (button) {
    getButton = button;
  }

  const tarif_data = UserStore.get("tarif_data");
  let html = [],
    style = { color: "#485fc7" };

  let sortable = [];
  for (var vehicle in tarif_data) {
    if (tarif_data[vehicle]["active"] === 0) continue;
    sortable.push([vehicle, tarif_data[vehicle]]);
  }

  sortable.sort(function (a, b) {
    var x = a[1]["sort"];
    var y = b[1]["sort"];
    return x < y ? -1 : x > y ? 1 : 0;
  });

  for (let i in sortable) {
    let tarif = sortable[i][1],
      tarif_id = sortable[i][0];
    if (tarif_id === "1") {
      continue;
    }

    html.push(getItem(tarif_id, tarif));
  }

  let free = tarif_data[1];

  return (
    <>
      {UserStore.getTarif() === 1 ? (
        <Box>
          <Heading style={style}>{T("free-tarif")}</Heading>
          <Heading style={style} subtitle size={4}>
            {T("free-tarif-desc")}
          </Heading>
          <b>{T("you-can-add-full")}:</b>
          <br />
          <FontAwesomeIcon icon={faCheckCircle} /> {!!free && !!free["sales"] && free["sales"]}{" "}
          {T("sales-month")} <br />
          <FontAwesomeIcon icon={faCheckCircle} /> {!!free && !!free["products"] && free["products"]}{" "}
          {T("tarif-products")} <br />
        </Box>
      ) : null}
      <Level style={{flexWrap: 'wrap', gap: '10px', justifyContent: 'center', alignItems: 'flex-start'}}>{html}</Level>
    </>
  );
};

export default TarifBlock;
