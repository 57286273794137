import React from 'react'
import Tabs from "react-bulma-components/lib/components/tabs";
import T from "../../../../components/i18n";
import Box from "react-bulma-components/lib/components/box";
import {
	useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

let tabs = ['all', 'delivery', 'marketplaces', 'cms', 'sms', 'chats', 'prro', 'other_receipt'];

const WHITELABLE = process.env.REACT_APP_WHITELABLE;


const TabsIntegration = ({active, onChange}) => {
	const {isMobileSize} = useIsMobile()
	const handleChangeTab = (value) => {
		if(onChange) onChange(value)
	}

	if(WHITELABLE === 'modeler') {
		tabs = tabs.filter(item => item !== 'delivery' && item !== 'prro')
	}

	return (
		<Box style={{borderRadius: '0 0 16px 16px'}}>
			<Tabs type='boxed' fullwidth={false} size={isMobileSize ? "small" : "medium"}>
				{
					tabs.map(tab => (
						<Tabs.Tab key={tab} active={active === tab} onClick={() => handleChangeTab(tab)}>
						{T(tab)}
					</Tabs.Tab>))
				}
			</Tabs>
		</Box>

	);
};

export default TabsIntegration;