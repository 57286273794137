import React, {useState} from 'react'
import ProductsStore from "../../../products/products-store";
import T from "../../../../components/i18n";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import DiscountDropdownItem from "./discount-dropdown-item";
import { LocalIcon } from 'whitelables/wl-name/icons'
import CustomPriceDropdownItem from "./custom-price-dropdown-item";
import UserStore from "../../../../user/user-store";

const CustomPriceDropdown = ({pid_mid, typePrice, discount, onChangeCost, onChangeTypeDiscount, disabledDiscount, withNetPrice, positionCenter}) => {
	const [refresh, setRefresh] = useState(0)
		const pid = pid_mid.split('_')[0]
		const mid = pid_mid.split('_')[1]
		const customPriceList = ProductsStore.getProductCustomPriceListByPid(pid)

		const getProduct = ProductsStore.getProductByMid(pid, mid)
		const base_price_item = {value: getProduct.price, name: T('basic-price-label')}
		const net_price_item = {value: getProduct.netprice, name: T('netcost')}

		const dataPrice = [base_price_item, ...customPriceList]

		if(withNetPrice) {
			dataPrice.unshift(net_price_item)
		}

		const classNameDropdown = `dropdown-custom-price ${positionCenter ? 'position-center' : ''}`

		return (
			<Dropdown
				key={refresh}
				label={<LocalIcon icon='arrow-left'/> }
				className={classNameDropdown}
				right={!positionCenter}
			>
				{dataPrice.map((item, index) => (
					<CustomPriceDropdownItem
						key={`${item.name} + ${index}`}
						{...item}
						pid_mid={pid_mid}
						typePrice={typePrice}
						onChange={onChangeCost}
						onChangeType={onChangeTypeDiscount}
						refresh={setRefresh}
					/>))}
				{UserStore.getPermissionEnable('edit-discount') && !disabledDiscount ? <DiscountDropdownItem
					discount_product={discount}
					pid={pid_mid}
					onChange={onChangeTypeDiscount}
					refresh={setRefresh}
				/> : <></>}
			</Dropdown>)
};

export default CustomPriceDropdown;