//It's a very important to have variable 'messages'
//because our "lang compiler" expects such format

const messages = {
    // form controls
    "login-btn-label": {
        ru: "Войти",
        ua: "Увійти",
        en: "Log in"
    },
    "login-placeholer": {
        ru: "Логин",
        ua: "Логін",
        en: "Login"
    },
    "email-or-phone-placeholer": {
        ru: "Email или номер телефона",
        ua: "Email або номер телефону",
        en: "Email or phone number"
    },
    "password-placeholder": {
        ru: "Пароль",
        ua: "Пароль",
        en: "Password"
    }, 
    "invalid-email": {
        ru: "Такого E-mail нет в нашей базе :( Вы можете создать новый аккаунт",
        en: "This E-mail is not in our database :( You can create a new account",
        ua: "Такого E-mail немає в нашій базі :( Ви можете створити новий обліковий запис"
    },
    "invalid-code": {
        ru: "Ошибочный секретный код. Скопируйте его прямо с письма, или нажмите на кнопку Изменить пароль в письме.",
        ua: "Помилковий секретний код. Скопіюйте його прямо з листа, або натисніть на кнопку Змінити пароль в листі.",
        en: "Wrong secret code. Copy it directly from the letter, or click on the Change password button in the letter."
    },
    "forgot-password-btn-label": {
        ru: "Забыли пароль?",
        ua: "Забули пароль?",
        en: "Forgot password?"
    },    
    "recovery-code": {
        ru: "Секретный код",
        ua: "Секретний код",
        en: "Secret code"
    },      
    "email-not-send": {
        ru: "E-mail не отправлен. Обратитесь в онлайн-поддержку",
        ua: "E-mail не відправлено. Зверніться в онлайн-підтримку",
        en: "E-mail was not sent. Contact online support"
    },   
    "recovery-code-text": {
        ru: "Секретный код, который вы получили по E-mail",
        ua: "Секретний код, який ви отримали по E-mail",
        en: "The secret code that you received by e-mail"
    },      
    "recovery-password": {
        ru: "Восстановление пароля",
        ua: "Відновлення паролю",
        en: "Password recovery"
    },    
    "recovery-password-btn": {
        ru: "Восстановить пароль",
        ua: "Відновити пароль",
        en: "Restore password"
    },
    "auth-register-btn": {
        ru: "Зарегистрироваться",
        ua: "Зареєструватися",
        en: "Registration"
    },
    "auth-modal-title": {
        ru: "Вход",
        ua: "Вхід",
        en: "Log in"
    },

    // messages
    "password-format-error": {
        ru: "Поле заполнено некорректно",
        ua: "Поле заповнене некоректно",
        en: "Incorrect field entry"
    },
    "password-confirm-equal-error": {
        ru: "Пароли не совпадают. Повторите попытку",
        en: "Passwords do not match. Please try againg"
    },
    "password-tooltip-title": {
        ru: "Надёжность пароля",
        en: "Password strength"
    },
    "password-tooltip-text": {
        ru: "Минимальная длина пароля – 8 символов. Рекомендуем использовать строчные и заглавные буквы (a-z и A-Z),"
        + " цифры (0-9) и символы ( ~ ` ! @ # $ % ^ & * ( ) _ \\ - + = | { } [ ] ' \" ; : / ? . > , < ^ № ).",
        en: "Minimum password length - 8 symbols. We recommend using lowercase and uppercase letters (a-z and A-Z), "
        + "numbers (0-9), and symbols ( ~ ` ! @ # $ % ^ & * ( ) _ \\ - + = | { } [ ] ' \" ; : / ? . > , < ^ № )."
    },
    "auth-error-invalid-credentials": {
        ru: "Неверный логин или пароль",
        ua: "Невірний логін або пароль",
        en: "Wrong login or password"
    },
    "auth-error-blocked": {
        ru: "Возникли проблемы при авторизации. Обратитесь в Службу поддержки",
        ua: "Виникли проблеми при авторизації. Зверніться в службу підтримки",
        en: "Problems with authorization. Please contact Customer Support"
    },
    "auth-error-user-blocked": {
        ru: "Аккаунт заблокирован",
        en: "Account blocked"
    },
    "auth-error-multi-login": {
        ru: "Уважаемый пользователь, кто-то вошел на сайт, используя ваши логин и пароль."
        + " Если это не вы, обратитесь в Службу поддержки",
        en: "Dear user, somebody logged on to the site using your login and password."
        + " If it was not you, please contact Customer Support"
    },
    "auth-title-logout": {
        ru: "Выход",
        ua: "Вихід",
        en: "Logout"
    },
    "alert-authorization": {
        ru: "Ошибка авторизации",
        ua: "Помилка авторизації",
        en: "Authorization error"
    },
    "alert-input-phone": {
        ru: "Введите номер телефона",
        ua: "Введіть номер телефону",
        en: "Enter the phone number"
    },
    "alert-pin-code": {
        ru: "Введите одноразовый пароль",
        en: "Enter PIN"
    },
    "wrong-phone": {
        ru: "Неверный формат номера телефона",
        ua: "Невірний формат номера телефону",
        en: "Invalid phone number format"
    },
    "auth-error-pin-wrong": {
        ru: "Неверный одноразовый пароль",
        en: "Invalid PIN"
    },
    "log-in": {
        ru: "Авторизоваться",
        ua: "Авторизуватись",
        en: "Log in"
    },
    auth: {
        ru: "Авторизация",
        en: "Log in"
    },
    "new-password": {
        ru: "Получить новый пароль",
        en: "Send new password"
    },
    "new-pin-send": {
        ru: "Новый Пин-Код отправлен",
        en: "A new PIN has been sent"
    },
    "get-password": {
        ru: "Получить пароль",
        en: "Send password"
    },
    "keep-me-logged-in": {
        ru: "Запомнить меня",
        en: "Keep me logged in"
    },
    "deposit-prompt-title": {
        ru: "Добро пожаловать :)",
        en: "Welcome :)"
    }
};

export default messages;
