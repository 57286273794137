import React from "react";
import SelectComponent from "../../../tools/select-component";
import ProductionStore from "../production-store";
import WarehouseStore from "../../warehouse/warehouse-store";

const TemplateFilter = ({ filters, onChangeFilters }) => {
  const handleChange = (selectValues, meta) => {
    onChangeFilters && onChangeFilters(meta.name, selectValues);
  };

  return (
    <>
      <SelectComponent
        name="category"
        label="production-category"
        onChange={handleChange}
        value={filters?.category}
        list={ProductionStore.getFormattingCategoryList}
        empty="all"
      />
      <SelectComponent
        name="mid"
        empty="all-warehouse"
        label="history-data-to_mid"
        onChange={handleChange}
        value={filters?.mid}
        list={WarehouseStore.getWarehouseList}
      />
    </>
  );
};

export default TemplateFilter;
