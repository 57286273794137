import React from "react";
import T from "../../../../components/i18n";
import formatDate from "../../../../tools/format-date";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import SingleSaleModal from "../../../sales/mobile/single-sale-modal";

const SaledOrder = ({ data }) => {
  return (
    <OpenInsideModal
      size="small"
      renderAs="a"
      text={
        <b>
          {T("purchase")}: #{data["order_number"]} {T("from")}{" "}
          {formatDate(data["purchase_date"])}
          <br />
        </b>
      }
    >
      <SingleSaleModal order={data} />
    </OpenInsideModal>
  );
};

export default SaledOrder;
