import { useState } from "react";

const useControlSettings = ({
  table,
  heading,
  slipName,
  footer,
  isTermoPrint,
}) => {
  const [tableState, setTableState] = useState(table);
  const [headingState, setHeadingState] = useState(heading);
  const [slipNameState, setSlipNameState] = useState(slipName);
  const [footerState, setFooterState] = useState(footer);
  const [isTermoPrintState, setIsTermoPrintState] = useState(isTermoPrint);

  const handleChangeCheckbox = (name, checked, type) => {
    let setState = null;
    if (type === "heading") {
      setState = setHeadingState;
    } else if (type === "table") {
      setState = setTableState;
    }

    if (setState) {
      setState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [name]: checked,
        },
      }));
    }
  };

  const handleChangeInput = (name, value, type) => {
    let setState = null;
    if (type === "heading") {
      setState = setHeadingState;
    } else if (type === "table") {
      setState = setTableState;
    } else if (type === "name") {
      setState = setSlipNameState;
    }
    if (setState) {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleChangeCheckboxFooter = (name, checked) => {
    const data = checked ? { font: "8" } : false;

    setFooterState((prev) => ({
      ...prev,
      [name]: data,
    }));
  };

  const handleChangeInputFooter = (name, value, globalName) => {
    setFooterState((prev) => ({
      ...prev,
      [globalName]: {
        ...prev[globalName],
        [name]: value,
      },
    }));
  };

  const handleChangeAllValue = ({
    table,
    heading,
    slipName,
    footer,
    isTermoPrint,
  }) => {
    setHeadingState(heading);
    setFooterState(footer);
    setSlipNameState(slipName);
    setTableState(table);
    setIsTermoPrintState(isTermoPrint);
  };

  return {
    heading: headingState,
    setHeadingState,
    footer: footerState,
    table: tableState,
    slipName: slipNameState,
    isTermoPrint: isTermoPrintState,
    setIsTermoPrint: setIsTermoPrintState,
    handleChangeCheckbox,
    handleChangeInput,
    handleChangeCheckboxFooter,
    handleChangeInputFooter,
    handleChangeAllValue,
  };
};

export default useControlSettings;
