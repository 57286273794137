import React, { Component } from 'react';
import T from "components/i18n";
// import { connectToStores } from "tools/reflux-tools";


import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
// import Actions from './start-actions' 
// import StartStore from './start-store' 

import SettingsTab from 'modules/settings/settings-tabs/settings';
import { redirectTo } from 'tools/redirect-to'


class FirstRegModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          finish: false
        };

        // this.onChange = this.onChange.bind(this);
        // this.submit = this.submit.bind(this);
    }

    render() {
        return (
              <Box className="start-block">
                <SettingsTab />
                {this.state.finish ? redirectTo('/dashboard') : ""}
                <Button.Group>
                  <Button fullwidth size="large" rounded color="primary"
                    onClick={() => {this.setState({"finish": true})}}
                    >{T('start-work')}</Button>
                </Button.Group>
              </Box>
        );
    }

}

export default FirstRegModal;
