import React, { Component } from 'react';
import T from "components/i18n";
import Money from "components/money";
import DOM from "react-dom-factories";
import { redirectTo } from 'tools/redirect-to'


import Columns from 'react-bulma-components/lib/components/columns';

import Heading from 'react-bulma-components/lib/components/heading';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import SalesActions from 'modules/sales/sales-actions'

import ExpensesActions from 'modules/expenses/expenses-actions'
import ExpensesStore from 'modules/expenses/expenses-store'

import SelectComponent from 'tools/select-component';

import SubmitButton from 'components/submit-button';
import Messages from 'components/messages';
import SalesStore from "./sales-store";
import {
  Control,
  Field,
  Label
} from "react-bulma-components/lib/components/form";
import NumberInput from "../../components/number-input/number-input";
import User from "../../user/user-store";
import UserStore from "../../user/user-store";

class ChangeAccountModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          start_amount: props.amount,
          amount: props.amount,
          fromAccount: props.fromAccount,
          complete: props.complete,
          toAccount: null,
          message: null,
          waiting: null,
          oid: props.oid,
          commission: '',
          commissionType: '%',
          close: false,
          step: 0
        };

        this.onChangeAccount = this.onChangeAccount.bind(this);
        this.onChange = this.onChange.bind(this);
        this.calcCommission = this.calcCommission.bind(this);
        this.submit = this.submit.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.renderStepSecond = this.renderStepSecond.bind(this);
        this.onChangeCommissionType = this.onChangeCommissionType.bind(this);

    }

    componentWillUnmount() {
      if (typeof(this.tranferFundListen) == "function") {
        this.tranferFundListen();
      }

      if (typeof(this.listenerFail) == "function") {
          this.listenerFail();
      }
    }

    componentDidMount() {
      this.tranferFundListen = ExpensesActions.transferFunds.completed.listen(() => {
          this.setState({step: 1});
          if (this.state.complete) {
            SalesActions.finishParcel(this.state.oid, false, 'cash_on_delivery');
          }
          if(this.props?.oid) {
            SalesStore.setAccountInSale(this.state.oid, this.state.toAccount.value, this.props?.isPrepaid);
          }
        });

        this.listenerFail = ExpensesActions.transferFunds.failed.listen((res) => {
            let data = {};

            if (res && typeof(res['response']) != "undefined") {
              data = JSON.parse(res['response']);
            } else {
              data = {
                'error': "unhandled_error"
              }
            }

            this.setState({
              'waiting': false,
              'message': T(data['error'])
            });
        });
    }

    onChange(evt) {
      const value = evt.target.value,
        name = evt.target.name;

      this.setState({
        [name]: value
      });

      setTimeout(this.calcCommission, 10);
    }

  calcCommission(amount) {
        if (typeof(amount) == "undefined") {
          amount = this.state.start_amount;
        }

        let commission_from = 0,
            commission_to = this.state.commission || 0,
            amount_to = amount || 0,
            amount_from = amount_to;
        if (this.state.fromAccount) {
          const from_account = ExpensesStore.getAccount(this.state.fromAccount.value);
          commission_from = from_account['commission_from'];
        }

        if (commission_from > 0) {
          amount_from = parseFloat(amount_from) + (amount_from/100*commission_from);
        }



        if (this.state.toAccount) {
          const account = ExpensesStore.getAccount(this.state.toAccount.value);
          if(!this.state.commission && this.state.commission !== '' && this.state.commissionType !== 'fix') {
            commission_to = account['commission_to'];
          }
        }

        if (commission_to > 0 && this.state.commissionType !== 'fix') {
          amount_to = amount_to - (amount_to/100*commission_to);
        } else if(this.state.commissionType === 'fix') {
          amount_to = amount_to - commission_to;
        }
        this.setState({
          "commission_from": commission_from || 0,
          "commission_to": commission_to || 0,
          "commission": commission_to || '',
          "amount_from": amount_from,
          "amount": amount_to
        });
    }


    onChangeAccount(selectedOption, actions) {
      if (!selectedOption) return;

      this.setState({
        [actions.name]: selectedOption,
        commission: 0
      });

      setTimeout(this.calcCommission, 10);
    }

    onChangeCommissionType({target: {value}}) {
      const data = {
        commissionType: value,
      }
      if(value === '%' && this.state.commission_to > 100) {
          data.commission = 100;
          data.commission_to = 100;
      } else {
          data.commission = '';
          data.commission_to = 0;
      }
      this.setState(data)

      setTimeout(this.calcCommission, 10);
    }


    submit() {
        this.setState({"waiting": true});

        const commission = this.state.commissionType === 'fix'? -this.state.commission : this.state.commission;

        ExpensesActions.transferFunds(
          this.state.fromAccount.value,
          this.state.toAccount.value,
          this.state.start_amount,
          this.state.oid,
          null,
          null,
          null,
          null,
          commission,
          this.props?.isPrepaid
        );
    }

    renderStepOne() {
      return (
          <Box>
              <Heading>
                  {T('amount-transfer')}: <Money amount={this.state.start_amount}  wrapper={DOM.span}/>
              </Heading>
              <Columns>
                      <Columns.Column>
                        <SelectComponent
                          name="fromAccount"
                          label="from-account"
                          onChange={this.onChangeAccount}
                          value={this.state.fromAccount}
                          readOnly={true}
                          list={() => ExpensesStore.getAccountsList()}
                          load={ExpensesActions.loadAccounts}
                          creatable={false}/>
                      </Columns.Column>
                      <Columns.Column>
                        <SelectComponent
                          name="toAccount"
                          label="to-account"
                          onChange={this.onChangeAccount}
                          value={this.state.toAccount}
                          list={()=>ExpensesStore.getAccountsList([1, 4], User.get("currency_id"), true)}
                          load={ExpensesActions.loadAccounts}
                          creatable={false}/>
                      </Columns.Column>
              </Columns>
              <Field>
                <Label>{T('transfer-fund-commissin-to')}</Label>
                <Control className='display-flex-row'>
                  <NumberInput
                    size="large"
                    name="commission"
                    max={this.state.commissionType === 'fix' ? this.state.start_amount : 100}
                    type="number"
                    onChange={this.onChange}
                    value={this.state.commission}
                  />
                  <select
                    onChange={this.onChangeCommissionType}
                          value={this.state.commissionType}
                          style={{height: 40, width: 52, borderRadius: 12}}>
                    <option value='%'>
                      %
                    </option>
                    <option value='fix'>
                      {T('fix')}
                    </option>
                  </select>
                </Control>
              </Field>
            <Box>
              <div>{T('transfer-fund-commissin-from')}: {this.state.commission_from}%</div>
              <div>{T('transfer-fund-amount-from')}: <Money
                amount={this.state.amount_from} wrapper={DOM.span}/></div>

              <div>{T('transfer-fund-commissin-to')}: {this.state.commission_to} {this.state.commissionType === 'fix'? T(`currency-${UserStore.get('currency_id')}`) : '%'}</div>
              <div>{T('transfer-fund-finish-amount')}: <Money amount={this.state.amount}  wrapper={DOM.span}/></div>
              </Box>
              <Button.Group>
                    <SubmitButton text='move-money-btn' waiting={this.state.waiting} submit={this.submit}/>
              </Button.Group>
          </Box>
      );
    }

    renderStepSecond() {
      return (
        <Box>
          <Heading className="title">{T('transfer-success')}</Heading>
          <Button.Group>

              <Button fullwidth size="large"  rounded color="primary"
                onClick={() => {typeof(this.props.onClose) === "function" ? this.props.onClose() : this.setState({close: true})}}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    render() {
        const stepNaviganor = [this.renderStepOne, this.renderStepSecond],
              currentStep = stepNaviganor[this.state.step];


        return (
              <div>
                <Messages message={this.state.message} close={() => this.setState({message: null})} />
                {currentStep()}
                {this.state.close ? redirectTo("/sales") : ""}
              </div>
        );

    }
}

export default ChangeAccountModal;