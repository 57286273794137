import React, {useState} from 'react'
import Button from "react-bulma-components/lib/components/button";
import ProductsStore from "../../../products/products-store";
import T from '../../../../components/i18n'
import Spinner from "../../../../templates/spinner";
import UserStore from "../../../../user/user-store";
import {
	useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const IframeComponent = ({onSubmit, isLoad}) => {
	const {isMobileSize} = useIsMobile()
	const [isLoaded, setIsLoaded] = useState(false)

	let src;

	const product = ProductsStore.getList();

	if (product && product.length > 0) {
		let f = product[0],
			mid = Object.keys(f['stock'])[0];
		src = ProductsStore.getIdent(f['id'], mid);
	}

	const handleSubmit = () => {
		if(onSubmit) onSubmit({isClose:true})

			setIsLoaded(true)
			setTimeout(() => setIsLoaded(false), 500)
	}


	return (
		<>
			{!isMobileSize && isLoaded && <div style={{width: '100%', height: '175px', position: 'relative'}}>
				<Spinner/>
			</div>}
			{!isMobileSize && !isLoaded && <iframe
				title='price'
				src={ProductsStore.getPrintTabLink([src])}
				width="100%"
				height="170"
			>
			</iframe>}
			<Button.Group position='centered'>
				<Button rounded size='small' color='info' onClick={handleSubmit}>
					<span onClick={() => isMobileSize && UserStore.postMessage("open-url::"+ ProductsStore.getPrintTabLink([src]))}>
						{T('save-and-view-btn')}
					</span>
				</Button>
			</Button.Group>
		</>
	);
};

export default IframeComponent;