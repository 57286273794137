import React, { Component } from 'react';

// import Columns from 'react-bulma-components/lib/components/columns';
// import Section from 'react-bulma-components/lib/components/section';
import Heading from 'react-bulma-components/lib/components/heading';
import Level from 'react-bulma-components/lib/components/level';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import List from 'react-bulma-components/lib/components/list';
import T from "components/i18n";
import Image from 'react-bulma-components/lib/components/image';

import { LocalIcon } from 'whitelables/wl-name/icons'
import { Link } from "react-router-dom";

// import OpenModalButton from "components/modal/open-modal-button";

import User from 'user/user-store';
import AppStore from 'app-store';


class Contacts extends Component {
    openChat () {
      const button = document.getElementById("cleversite_chat");
      if (button) {
        button.click();
      }
    }

       // <Button.Group position="centered">
       //          <a href="https://t.me/HugeProfitBot" className="margin-side-10" target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Telegram_logo.svg" border="0" /></a>
       //          <a href="viber://pa/?chatURI=hugeprofit&context=testnew" target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Viber-Icon.svg" border="0" /></a>
       //         </Button.Group>

    renderNavkolo() {
        return (
        <Box>
            <List >
                          <List.Item value='viber'>
                              <span><LocalIcon icon={'viber'} /> </span><Link to={{ pathname: "https://www.viber.com/navkoloone_bot" }} target="_blank">{T('viber')}</Link>
                          </List.Item>
                          <List.Item value='telegram'>
                            <span><LocalIcon icon={'telegram'} /></span> <Link to={{ pathname: "https://t.me/navkoloone_bot" }} target="_blank">{T('telegram')}</Link>
                          </List.Item>
                          <List.Item value={T('offerta')}>
                            <span><LocalIcon icon={'privacy'} /></span> <Link to={{ pathname: AppStore.getAgreementLink() }} target="_blank">{T('public-offer')}</Link>
                          </List.Item>
                          <List.Item value="phone">
                             ID: {User.getUserId()} <br />
                             {AppStore.getContactData('phone')} <i>(9:00-21:00)</i>
                          </List.Item>
             </List>
        </Box>

        )
    }

    renderApp () {
        return (
          <Box>
    			<Heading>{T('Contacts')}</Heading>

               <p>{T('contact-text')}</p>
               
                <Level className="home-social-network is-mobile">
                    <Level.Item>
                    <Image size={64} onClick={() => User.postMessage("open-url::"+AppStore.getContactData('telegram'))} src="/img/Telegram_logo.svg" border="0" />
                    </Level.Item>
                    <Level.Item>
                    <Image size={64} onClick={() => User.postMessage("open-url::"+AppStore.getContactData('viber'))} src="/img/Viber-Icon.svg" border="0" />
               </Level.Item>
                 </Level>

               <Heading size={5}>{AppStore.getContactData('phone')}</Heading>

               <hr />
               <Heading>{T('connect-to-socialnetwork')}</Heading>
                 <Level className="home-social-network is-mobile">
                    <Level.Item>
                        <Image size={64} onClick={() => User.postMessage("open-url::https://www.facebook.com/H-Profit-100740618389312")} src="/img/Facebook_F_icon.svg" border="0" />
                    </Level.Item>
                    <Level.Item>
                        <Image size={64} onClick={() => User.postMessage("open-url::https://t.me/huge_profit")}  src="/img/Telegram_logo.svg" border="0" />
                    </Level.Item>
                 </Level>
          </Box>
		)
    }

    render () {
        if (AppStore.isMobileApp()) return this.renderApp();
        if (AppStore.getWlName() === "navkolo") { return this.renderNavkolo(); }

        return (
          <Box>
                <Heading>{T('Contacts')}</Heading>

               <p>{T('contact-text')}</p>
               
               <Button.Group position="centered">
                <a href={AppStore.getContactData('telegram')} className="margin-side-10" target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Telegram_logo.svg" border="0" /></a>
                <a href={AppStore.getContactData('viber')} target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Viber-Icon.svg" border="0" /></a>
               </Button.Group> 
               
               <Heading size={5}>{AppStore.getContactData('phone')}</Heading>

               <hr />
               <Heading>{T('connect-to-socialnetwork')}</Heading>
                 <Level className="home-social-network is-mobile">
                    <Level.Item>
                        <a href="https://www.facebook.com/H-Profit-100740618389312" target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Facebook_F_icon.svg" border="0" /></a>
                    </Level.Item>
                    <Level.Item>
                        <a href="https://t.me/huge_profit" target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Telegram_logo.svg" border="0" /></a>
                    </Level.Item>
                 </Level>
          </Box>
        )
    }
}

export default Contacts;


