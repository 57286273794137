import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";

import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Content from 'react-bulma-components/lib/components/content';
import Progress from 'react-bulma-components/lib/components/progress';
import Columns from 'react-bulma-components/lib/components/columns';


import ExpensesStore from '../expenses/expenses-store' 
import ExpensesActions from '../expenses/expenses-actions' 
import SalesStore from '../sales/sales-store' 
import SalesActions from '../sales/sales-actions' 

import User from 'user/user-store';

import { 
    Select 
} from 'react-bulma-components/lib/components/form';

import T from "components/i18n";
import Money from "components/money";
import InfoWrapper from 'components/info-wrapper';

const colors = ['danger', 'warning', 'success'];

class ProfitAndLoss extends Component {
  constructor(props) {
        super(props);

        this.state = {
          period: "month-current",
          income: 0,
          sales_amount: 0,
          receipt_amount: 0,
          sales_profit_expenses: 0,
          expenses: 0,
          expenses_line: [],
        };

        this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
     this.listenLoadCompleted = ExpensesActions.load.completed.listen(() => {
            this.updateData(this.state.period);   
     });

     this.listenLoadSalesCompleted = SalesActions.load.completed.listen(() => {
            this.updateData(this.state.period);   
     });

     this.listenLoadReportsCompleted = SalesActions.loadReports.completed.listen(() => {
            this.updateData(this.state.period);   
     });

     this.updateData(this.state.period);   
  }

  componentWillUnmount() {
      if (typeof(this.listenLoadCompleted) == "function") {
          this.listenLoadCompleted();
      }
      if (typeof(this.listenLoadSalesCompleted) == "function") {
          this.listenLoadSalesCompleted();
      }      
      if (typeof(this.listenLoadReportsCompleted) == "function") {
          this.listenLoadReportsCompleted();
      }
    }
    

  onChange(evt) {
    const value = evt.target.value;
    this.updateData(value);
  }

  getExpensesLine(i, _id, amount) {
    return (
          <Columns key={_id} className="is-mobile  padding-left-30">
                  <Columns.Column align="left"  size="half" >
                        <Money amount={amount*-1} signClassname={true} className="middle-money"/>
                        <div className="is-size-7">{ExpensesStore.getCategoryName(_id)}</div>
                  </Columns.Column>
                  <Columns.Column>
                      <Progress max={this.state.max || 100} value={amount} color={colors[i]} size="large" />
                  </Columns.Column>
          </Columns>

      );
  }

  updateData(period) {
      const [start, end] = this.props.makeDate(period),
            expenses = ExpensesStore.getExpensesByCategory(start, end),
            receipt = ExpensesStore.getReceiptByDate(start, end, ),
            // sales = SalesStore.getSalesByDate(start, end),
            data = this.props.data(period);

      let expenses_amount = 0,
          expenses_profit = 0,
          expenses_not_calc = 0,
          expenses_shipment = 0,
          expenses_line = [],
          expenses_delivery_shipment = 0;

      for (let i in expenses) {
        const categoryID = expenses[i][0]
        const amount = expenses[i][1]

        const category = ExpensesStore.getCategoryById(expenses[i][0]);

        if(parseInt(categoryID) === ExpensesStore.SHIPMENT_CATEGORY) {
          expenses_shipment += amount
        } else if(parseInt(categoryID) === 2) {
          expenses_delivery_shipment += amount
          expenses_not_calc += amount
        } else if(category.is_profit === 1) {
          expenses_profit += amount;
          expenses_line.push(this.getExpensesLine(i, categoryID, amount))
        } else if(category.is_profit === 2) {
          expenses_not_calc += amount
        } else {
          expenses_amount += amount;
        }
      }

      let sales_profit = data['sales_profit'];
      // for (let i in sales) {
      //     let sale = sales[i];
      //     if (ExpensesStore.isNotAllowToCalc(sale['account_id'])) {
      //       continue;
      //     }
      //     for (let j in sale['items']) {
      //       sales_profit += sale['items'][j]['profit'];
      //     }
      // }

      let sales_amount = data['gross_income'];
      // for (let i in sales) {
      //     if (ExpensesStore.isNotAllowToCalc(sales[i]['account_id'])) {
      //       continue;
      //     }
      //     sales_amount += sales[i]['amount_sale'];
      // }

      let receipt_amount = 0;
      for (let i in receipt) {
          const rec = receipt[i];
          let amount = rec['amount'];
          if (ExpensesStore.getAccount(rec['account_id'])['currency_id'] !== User.get("currency_id")) {
                amount = ExpensesStore.getAmountByRate(rec['account_id'], amount);
          }          

          receipt_amount += amount;
      }

      this.setState({
            "period": period,
            "income": sales_amount + receipt_amount,
            "sales_amount": sales_amount,
            "receipt_amount": receipt_amount,
            "sales_profit": sales_profit,
            "sales_profit_expenses": sales_profit-expenses_profit,
            "expenses": expenses_amount,
            "expenses_profit": expenses_profit,
            expenses_not_calc,
            expenses_shipment,
            "expenses_line": expenses_line,
            expenses_delivery_shipment
      })
  }


  renderView() {  
        // profit = this.state.income - this.state.expenses,
        const max = parseInt(Math.max(this.state.income || 0, this.state.expenses || 0, this.state.sales_profit || 0)),
             sales_amount = (max === 0) ? 100 : this.state.sales_amount || 0,
             receipt_amount = (max === 0) ? 100 : this.state.receipt_amount || 0,
             expenses = (max === 0) ? 100 : (this.state.expenses || 0),
             sales_profit = (max === 0) ? 100 : this.state.sales_profit || 0,
             // roi =  profit/(this.state.expenses || 1) * 100,
             real_profit = (max === 0) ? 100 : this.state.sales_profit_expenses || 0;

        // const notification = this.props.notification.front;     
        // console.log("render notification, state: ", this.props.notification.front)
        return (
            <Box className="fullwidth">
              <Columns>
                  <Columns.Column align="left">
                        <Heading size={5}>{T('profit-and-loss')}
                          {/*<br /><InfoWrapper info={T("roi-tip")} defaultPosition="-20"><span>(ROI: {(Math.round(roi * 100)/100) || 0}%)</span></InfoWrapper>*/}
                        </Heading>
                  </Columns.Column>
                  <Columns.Column align="right">
                        <Select onChange={this.onChange} name="period" value={this.state.period}>
                          <option value="month-last">{T('month-last')}</option>
                          <option value="month-current">{T('month-current')}</option>
                          <option value="7days">{T('7days')}</option>
                          <option value="30days">{T('30days')}</option>
                          <option value="this-year">{T('this-year')}</option>
                          <option value="last-year">{T('last-year')}</option>
                        </Select>
                  </Columns.Column>
              </Columns>
              <Content align="left">
              <InfoWrapper info={T("operating-profit-tip")}>
                <Money
                  amount={this.state.income - (this.state.expenses || 0) - (this.state.expenses_profit || 0) - (this.state.expenses_shipment || 0) - (this.state.expenses_delivery_shipment || 0)}
                  signClassname={true}
                  className="good-money"
                  tooltip="Tooltip Text"/>
              </InfoWrapper>
              <div className="is-size-7">{T('net-income-for')} {T(this.state.period)}</div>
              <Columns className="margin-top-10 is-mobile">
                  <Columns.Column align="left" size="half">
                        <InfoWrapper info={T("sales-profit-tip")}><Money amount={this.state.sales_amount} className="middle-money"/></InfoWrapper>
                        <div className="is-size-7">{T('sales-amounts-revenue-sale')}</div>
                  </Columns.Column>
                  <Columns.Column>
                      <Progress max={max || 100} value={sales_amount} color="success" size="large" />
                  </Columns.Column>
              </Columns>
              <Columns className="is-mobile">
                  <Columns.Column align="left" size="half">
                        <Money amount={this.state.receipt_amount} className="middle-money"/>
                        <div className="is-size-7">{T('other_replenish')}</div>
                  </Columns.Column>
                  <Columns.Column>
                      <Progress max={max || 100} value={receipt_amount} color="success" size="large" />
                  </Columns.Column>
              </Columns>
              <Columns className="is-mobile">
                  <Columns.Column align="left" size="half">
                    <Money amount={this.state.expenses + this.state.expenses_profit} className="middle-money"/>
                    <div className="is-size-7">{T('expenses')}</div>
                  </Columns.Column>
                  <Columns.Column>
                      <Progress max={max || 100} value={expenses} color="danger" size="large" />
                  </Columns.Column>
              </Columns>
                <Columns className="is-mobile">
                  <Columns.Column align="left" size="half">
                    <Money amount={this.state.expenses_not_calc  + (this.state.expenses_shipment || 0)}
                           className="middle-money"/>
                    <InfoWrapper
                      info={T('expenses-cost-of-sales-tip')}>
                      <div
                        className="is-size-7">{T('expenses-not-deducted')}</div>
                    </InfoWrapper>

                  </Columns.Column>
                  <Columns.Column>
                    <Progress max={max || 100}
                              value={this.state.expenses_not_calc  + (this.state.expenses_shipment || 0)}
                              color="danger" size="large"/>
                  </Columns.Column>
                </Columns>
              <Columns className="is-mobile">
                  <Columns.Column align="left" size="half">
                        <InfoWrapper info={T("net-profit-tip")}><Money amount={this.state.sales_profit_expenses} signClassname={true} className="middle-money"/></InfoWrapper>
                        <div className="is-size-7 bold-text">{T('from-sales-profit')}</div>
                  </Columns.Column>
                  <Columns.Column>
                      <Progress max={max || 100} value={real_profit} color="success" size="large" />
                  </Columns.Column>
              </Columns> 
              {this.state.sales_profit_expenses !== this.state.sales_profit ? 
              <>
              <Columns className="is-mobile padding-left-30 ">
                  <Columns.Column align="left" size="half" className="has-text-success">
                        <Money amount={this.state.sales_profit} signClassname={true} className="middle-money"/>
                        <div className="is-size-7 bold-text">{T('from-sales-profit')}</div>
                  </Columns.Column>
                  <Columns.Column>
                      <Progress max={max || 100} value={sales_profit} color="success" size="large" />
                  </Columns.Column>
              </Columns>
              {this.state.expenses_line}   
              </>
              : null}           
              </Content>
            </Box>
        );
    }

  render() {
    return !User.getPermissionEnable("incomeinformation") ? null : this.renderView();
  }
}



// export default ProfitAndLoss;
export default connectToStores(ProfitAndLoss, { expenses: ExpensesStore, 'sales': SalesStore });

