import React from 'react'
import {Button} from "react-bulma-components";
import T from '../../../components/i18n'
import AppStore from "../../../app-store";
import MassRestoreModal from "./modal/mass-restore-modal";
import SelectStore from "../../sales/select-store";

const GroupButtonList = () => {
	return (
		<>
			<Button color="info" size='small' rounded onClick={() => AppStore.openModal(<MassRestoreModal data={SelectStore.getListSelect()}/>)}>
				{T('restore-label')}
			</Button>
		</>
	);
};

export default GroupButtonList;