import React from "react";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../components/i18n";
import OpenModalButton from "../../components/modal/open-modal-button";
import Box from "react-bulma-components/lib/components/box";

const EmptyMessage = () => {
	return (
		<Box align='center'>
			<Heading>{T('not-have-write-off')}</Heading>
			<OpenModalButton link="/write-off/add-write-off" text={T('add-first-write-off-btn')} />
		</Box>
	);
};

export default EmptyMessage;