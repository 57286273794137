import Reflux from 'reflux';

const TransactionsActions = Reflux.createActions({
    load: { asyncResult: true },
    setPage: { sync: true },
    setCountPerPage: { sync: true },
    addAccount: { asyncResult: true },
    filter: { sync: true },

});

export default TransactionsActions;