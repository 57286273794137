import React, {useEffect, useState} from "react";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import AppStore from "../../../../app-store";
import AddAccrualSales from "./add-accrual-sales";
import { getActiveIdsItem } from "./utils";
import T from "../../../../components/i18n";
import AccrualList from "./accrual-list";
import UserStore from "../../../../user/user-store";
import SubmitButton from "../../../../components/submit-button";
import UserActions from "../../../../user/user-actions";
import {errorHandler} from "../../../../tools/error-handler";
import errorMessageControlHook
  from "../../../../components/error-message-block/error-message-control.hook";

const AddAccrualSalesUser = () => {
  const accrualData = UserStore.getMoreSetting("accrual");
  const [isEdit, setIsEdit] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const {handleAutoRemoveError,errorMessage,setErrorMessage,ErrorMessageBlock} = errorMessageControlHook()

  const [accrual, setAccrual] = useState(
    accrualData
      ? accrualData
      : {
          general: "",
          general_type: "",
          category: [],
          brand: [],
          accrual_type: "turnover",
        }
  );

  const handleChangeAccrual = (name, value) => {
    setIsEdit(true);

    if (name === "general") {
      setAccrual((prev) => ({
        ...prev,
        general: value.value,
        general_type: value.type,
      }));
    } else {
      setAccrual((prev) => ({
        ...prev,
        [name]: [...prev[name], value],
      }));
    }
  };

  const handleEditAccrual = (name, index, value) => {
    setIsEdit(true);
    const accrualData = JSON.parse(JSON.stringify(accrual));
    if (name === "general") {
      accrualData.general = value.value;
      accrualData.general_type = value.type;
    } else if (name === "accrual_type") {
      accrualData.accrual_type = value;
    } else {
      if (accrualData[name][index]) {
        accrualData[name][index] = value;
      }
    }

    setAccrual(accrualData);
  };

  const handleDeleteAccrual = (name, index) => {
    setIsEdit(true);
    const accrualData = JSON.parse(JSON.stringify(accrual));
    if (name === "general") {
      accrualData.general = "";
      accrualData.general_type = "";
    } else {
      if (accrualData[name][index]) {
        accrualData[name] = [
          ...accrualData[name].slice(0, index),
          ...accrualData[name].slice(index + 1),
        ];
      }
    }

    setAccrual(accrualData);
  };

  const handleSubmit = () => {
    UserStore.setMoreSetting(accrual, "accrual");
    setWaiting(true)
  };

  useEffect(() => {
    const updateSetting = UserActions.updateSettings.completed.listen(() => {
      setWaiting(false)
      setIsEdit(false);
    })
    const updateSettingFailed = UserActions.updateSettings.failed.listen(res => {
      const message = errorHandler(res)
      setWaiting(false)
      handleAutoRemoveError(message)
    })
    return () => {
      updateSetting()
      updateSettingFailed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
      <Box style={{textAlign: 'left'}}>
        <div className="display-flex-center">
          <div align="left">{T("add-accrual-of-sales-text")}</div>
          <div align="right" className="margin-bottom-10">
            <Button
              color="success"
              onClick={() =>
                AppStore.openModal(
                  <AddAccrualSales
                    modal="accrual"
                    deleteGeneral={!!accrual.general}
                    onSubmit={handleChangeAccrual}
                    activeBrand={getActiveIdsItem(accrual.brand)}
                    activeCategory={getActiveIdsItem(accrual.category)}
                  />
                )
              }
            >
              {T("add-accrual-of-sales")}
            </Button>
          </div>
        </div>
        <AccrualList
          data={accrual}
          onDelete={handleDeleteAccrual}
          onEdit={handleEditAccrual}
        />
        {isEdit && (
          <div>
            <SubmitButton
              submit={handleSubmit}
              waiting={waiting}
              color="info"
              text="save-btn-label"
            />
          </div>
        )}
      </Box>
    </>
  );
};

export default AddAccrualSalesUser;
