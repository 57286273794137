import React, { Component } from "react";
import T from "components/i18n";
import Money from "components/money";
import Columns from "react-bulma-components/lib/components/columns";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";

import User from "user/user-store";
import ShipmentsStore from "../shipments-store";
import ExpensesStore from "../../expenses/expenses-store";

class ShipmentsTotal extends Component {
  _sumAmountReport(data) {
    let response = {
      amount: 0,
      shipments: 0,
      quantity: 0,
      sold: 0,
      not_paid: 0,
      payments: 0,
      pendingAmount: 0,
      completeAmount: 0,
      paidAmount: 0
    };

    const filterStatus = ShipmentsStore.getFilter("status");
    const filterCurrency = ShipmentsStore.getFilter("currency");

    for (let i in data) {
      const shipment = data[i];

      if (filterStatus.value === "pending") {
        if (shipment["shipment_status"] !== "pending") {
          continue;
        }
      } else if (filterStatus.value === "complete") {
        if (shipment["shipment_status"] !== "complete") {
          continue;
        }
      } else if (filterStatus.value === "saled") {
        if (shipment["shipment_status"] !== "saled") {
          continue;
        }
      }

      response["quantity"] += parseInt(shipment["quantity"]);
      response["sold"] += parseInt(shipment["sold"]);
      response["shipments"] += 1;

      if (shipment.shipment_status === "pending") {
        if(filterCurrency.value === 0) {
          response.pendingAmount += ((shipment.amount || 0) * shipment.currency_rate);
        } else {
          response.pendingAmount += shipment.amount
        }
      } else if (shipment.shipment_status === "complete") {
        if(filterCurrency.value === 0) {
          response.completeAmount += ((shipment.amount || 0) * shipment.currency_rate);
        } else {
          response.completeAmount += shipment.amount
        }
      }

      if (Array.isArray(shipment.expenses)) {
        shipment.expenses.forEach((expense) => {
          if(filterCurrency.value === 0) {
            response.paidAmount += expense.amount * (shipment?.currency_rate || 1);
          } else {
            response.paidAmount += expense.amount
          }
        });
      }


      if (filterCurrency.value === 0) {
        response["amount"] +=
          parseFloat(shipment["received_amount"]) * shipment["currency_rate"];

        for (let i in shipment["expenses"]) {
          const exp = shipment["expenses"][i];
          response["payments"] +=
            parseFloat(exp["amount"]) * shipment["currency_rate"];
        }
      } else {
        response["amount"] += parseFloat(shipment["received_amount"]);
        for (let i in shipment["expenses"]) {
          const exp = shipment["expenses"][i];
          const getAccountExp = ExpensesStore.getAccountById(exp.account_id);

          if (getAccountExp.currency_id === filterCurrency.value) {
            response["payments"] += parseFloat(exp["amount"]);
          } else {
            response["payments"] +=
              parseFloat(exp["amount"]) / shipment["currency_rate"];
          }
        }
      }

      response["not_paid"] = response["amount"] - response["payments"];
    }

    return response;
  }

  render() {
    const { shipments } = this.props,
      data = this._sumAmountReport(shipments);

    const filterCurrency = ShipmentsStore.getFilter("currency");

    return (
      <Columns className="statisctic">
        <Columns.Column>
          <Box>
            <Heading heading>{T("dashboard-total-shipments")}</Heading>
            <Heading>{data["shipments"]}</Heading>
          </Box>
        </Columns.Column>
        <Columns.Column>
          <Box>
            <Heading heading>{T("quantity-res-mob")}</Heading>
            <Heading>
              {data["quantity"]} {T("items-e")}
            </Heading>
          </Box>
        </Columns.Column>
        {!User.getPermissionEnable("incomeinformation") ? null : (
          <>
            <Columns.Column>
              <Box>
                <Heading heading>{T("expected-amount-of")}</Heading>
                <Heading>
                  <Money
                    amount={data["pendingAmount"]}
                    currency={filterCurrency.value}
                  />
                </Heading>
              </Box>
            </Columns.Column>
            <Columns.Column>
              <Box>
                <Heading heading>{T("got-it-on")}</Heading>
                <Heading>
                  <Money
                    amount={data["completeAmount"]}
                    currency={filterCurrency.value}
                  />
                </Heading>
              </Box>
            </Columns.Column>
              <Columns.Column>
                <Box>
                  <Heading heading>{T("not-paid")}</Heading>
                  <Heading>
                    <Money
                      signClassname
                      amount={data["completeAmount"] + data["pendingAmount"] - data['paidAmount']}
                      currency={filterCurrency.value}
                    />
                  </Heading>
                </Box>
              </Columns.Column>
          </>
        )}
        <Columns.Column>
          <Box>
            <Heading heading>{T("saled")}</Heading>
            <Heading>
              {data["sold"]} {T("items-e")}
            </Heading>
          </Box>
        </Columns.Column>
      </Columns>
    );
  }
}

export default ShipmentsTotal;
