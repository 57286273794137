import React from "react";
import Section from "react-bulma-components/lib/components/section";
import Box from "react-bulma-components/lib/components/box";
import Content from "react-bulma-components/lib/components/content";
import Heading from "react-bulma-components/lib/components/heading";
import FooterBlock from "../common/footer";

const ReturnUa = () => {
  return (
    <Section>
      <Box>
        <Content>
          <Heading>Оплата. Повернення. Гарантії</Heading>
          <Heading subtitle size={5}>
            Вартість обраного Тарифу, порядок розрахунків та умови відшкодування
          </Heading>
          <p>
            Вартість обраного Тарифу визначається згідно з Тарифами Компанії.
          </p>{" "}
          <p>
            Компанія має право на власний розсуд змінювати вартість обраного
            Тарифу та умови зміни тарифних планів шляхом їхньої публікації в
            новій редакції на Сайті Компанії за адресою h-profit.com/pricing/.
            Нові тарифи набувають чинності з дати їхньої публікації.
          </p>{" "}
          <p>
            У разі якщо Користувач продовжує використання Сервісу після набрання
            чинності новими Тарифами, вважається, що він прийняв такі зміни
            Тарифів і дав свою згоду на продовження співпраці відповідно до
            нових Тарифів.
          </p>{" "}
          <p>
            У будь-якому разі, перерахунок Обраного Тарифу за оплачені періоди
            не здійснюється. не здійснюється.
          </p>
          <Heading subtitle size={5}>
            Порядок розрахунків
          </Heading>
          <p>
            Оплата ліцензійної винагороди здійснюється відповідно до обраного
            Тарифу, авансовим платежем у розмірі 100% від суми обраного тарифу,
            не менше ніж за два робочих дні до бажаної дати початку використання
            Сервісу.
          </p>
          <p>
            Усі Способи оплати вказані на сайті Компанії за адресою
            <a href="https://h-profit.com/pricing/">
							{" "}https://h-profit.com/pricing/
            </a>{" "}
            і в акаунті користувача.
          </p>{" "}
          <p>
            Моментом виконання Користувачем обов'язків з оплати вважається день
            зарахування грошових коштів на розрахунковий рахунок Компанії.
          </p>{" "}
          <p>
            Користувачі, які оплачують Обраний тариф, надають згоду на послуги
            автоматичного регулярного автоматичного продовження обраного Тарифу
            на новий термін (автопродовження обраного Тарифу). Після активації
            даної послуги оплата новий строк обраного тарифу здійснюється шляхом
            автоматичного безакцептного списання коштів з рахунку користувача.
            Послуга автопродовження обраного Тарифу надається доти, доки
            Користувач не скасує підписку на Обраний Тариф у сервісі компанії.
          </p>{" "}
          <p>
            Послуга активується під час здійснення першої оплати обраного
            Тарифу, що означає згоду користувача з тим, що після закінчення
            оплаченого строку дії обраного Тарифу Договір автоматично
            пролонгується на тих самих умовах на той самий строк з автоматичним
            списанням вартості обраного Тарифу з рахунку Користувача
          </p>{" "}
          <p>
            У разі недостатності коштів на рахунку користувача або неможливості
            проведення платежу Обраний Тариф на новий строк не надається.
            надається.
          </p>{" "}
          <p>
            При зміні Компанією Тарифу автопродовження обраного Тарифу на
            черговий новий строк, наступний за періодом, протягом якого
            відбулася така зміна буде здійснено за новим тарифом.
          </p>{" "}
          <p>
            Усі комісії, що стягуються кредитними організаціями / іншими третіми
            особами при здійсненні платежу, оплачує Сторона-платник.
          </p>
          <Heading subtitle size={5}>
            Умови відшкодування
          </Heading>
          <p>
            Компанія гарантує Користувачеві можливість повернення грошових
            коштів, внесених як оплата обраного Тарифу, протягом 14
            (чотирнадцяти) календарних днів з дати здійснення оплати шляхом
            надсилання електронного листа про дострокове розірвання цього
            Договору та повернення сплачених як аванс грошових коштів із
            зареєстрованої електронної пошти користувача на електронну пошту
            Компанії h.info@h-profit.com. Після закінчення 14 (чотирнадцяти)
            календарних днів з дати здійснення оплати повернення не
            здійснюється.
          </p>{" "}
          <p>
            Компанія, у разі отримання офіційної заяви в обумовлений у п. 4.3.1.
            Договору строк, бере на себе обов'язок розглянути зазначену заяву та
            повернути кошти на розрахунковий рахунок користувача, з якого були
            сплачені кошти, протягом 30 (тридцяти) календарних днів з дати
            отримання листа. У випадках, коли повернення на рахунок Користувача,
            з якого було сплачено кошти, неможливе. кошти, неможливе, Користувач
            зазначає новий рахунок у листі про повернення сплачених як аванс
            грошових коштів.
          </p>{" "}
          <p>
            Повернення грошових коштів означає дострокове розірвання цього
            Договору.
          </p>
          <Heading subtitle size={5}>
            Гарантія безпеки платежів
          </Heading>
          <p>
            Наш сайт підключений до інтернет-еквайрингу, і Ви можете оплатити
            Передплату банківськими картами. Після підтвердження обраної
            передплати відкриється захищене вікно з платіжною сторінкою
            процесингового центру, де Вам необхідно ввести дані Вашої
            банківської картки. Для додаткової аутентифікації власника картки
            використовується протокол 3D Secure. Якщо Ваш Банк підтримує цю
            технологію, Ви будете перенаправлені на його сервер для додаткової
            ідентифікації. Інформацію про правила та методи додаткової
            ідентифікації уточнюйте в Банку, що видав Вам банківську картку.
          </p>
          <p>
            <b>Гарантії безпеки</b>
          </p>
          <p>
            Процесинговий центр захищає та обробляє дані Вашої банківської
            картки за стандартом безпеки PCI DSS 3.2. Передача інформації в
            платіжний шлюз відбувається із застосуванням технології шифрування
            SSL. Подальша передача інформації відбувається по закритих
            банківських мережами, що мають найвищий рівень надійності.
            Процесинговий центр не передає дані Вашої картки нам та іншим третім
            особам. Для додаткової аутентифікації власника картки
            використовується протокол 3D Secure.
          </p>
          <p>
            У разі, якщо у Вас є питання щодо здійсненого платежу, Ви можете
            звернутися до служби підтримки клієнтів платіжного сервісу.
          </p>
          <p>
            <b>Безпека онлайн платежів</b>
          </p>
          <p>
            Надана Вами персональна інформація (ім'я, адреса, телефон, e-mail,
            номер кредитної картки) є конфіденційною і не підлягає розголошенню.
            Дані Вашої кредитної картки передаються тільки у зашифрованому
            вигляді і не зберігаються на нашому Web-сервері.
          </p>
          <p>
            Ми рекомендуємо вам перевірити, що ваш браузер достатньо безпечний
            для проведення платежів онлайн, на спеціальній сторінці.
          </p>
          <p>
            Безпеку обробки Інтернет-платежів гарантує процесинговий центр. Усі
            операції з платіжними картками відбуваються відповідно до вимог VISA
            International, MasterCard та інших платіжних систем. Під час
            передачі інформації використовуються спеціальні технології безпеки
            карткових онлайн-платежів, обробка даних ведеться на безпечному
            високотехнологічному сервері процесингової компанії.
          </p>
        </Content>
      </Box>
      <FooterBlock />
    </Section>
  );
};

export default ReturnUa;
