import React, { Component } from 'react';

import T from "components/i18n";
import Heading from 'react-bulma-components/lib/components/heading';
import Content from 'react-bulma-components/lib/components/content';
import Box from 'react-bulma-components/lib/components/box';


class NpApiInfo extends Component {

    render () {
        return (
        	<Box>
        		<Content>
        			<Heading>{T('where-i-can-get-np')}</Heading>
        			<p>1. Для получения API, Вам неоходимо авторизоваться на сайте Новой Почты: <a href="https://my.novaposhta.ua/" target="_blank" rel="noopener noreferrer nofollow">https://my.novaposhta.ua/</a>. </p>
        			<p><img src='/info/np1.jpg' alt=""/></p>
        			<p>2. Перейти в раздел <b>"Налаштування"</b> в верхнем меню. (см.Рис 2) </p>
        			<p>3. На открывшейся странице перейдите в раздел <b>"Безпека"</b> </p>
        			<p><img src='/info/np2.jpg' alt="" /></p>
        			<p>4.Далее нажмите кнопку <b>"Створити ключ"</b>. Чуть выше над кнопкой появится новый созданный ключ, который Вы можете скопировать и использовать его на нашем сервисе.</p>
        			<p><img src='/info/np3.jpg' alt="" /></p>
        		</Content>
        	</Box>
			)}

      }

export default NpApiInfo;

