import React, {useEffect, useState} from 'react'
import {calculateBrightness} from "./utils";
import T from "../i18n";

const Tag = ({color, name, className}) => {
	const [textColor, setTextColor] = useState('white');

	useEffect(() => {
		if(color) {
			const brightness = calculateBrightness(color);

			if (brightness >= 128) {
				setTextColor('black');
			} else {
				setTextColor('white');
			}
		}
	}, [color]);

	return (
		<span
			className={`tag-wrapper ${className ? className : ''}`}
			style={ className ? {} : {background: color, color: textColor}}>
			{T(name)}
		</span>
	);
};

export default Tag;