import React from "react";
import { Box, Button, Columns, Heading } from "react-bulma-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOM from "react-dom-factories";
import Money from "../../../../components/money";
import ExpensesStore from "../../../expenses/expenses-store";
import T from "../../../../components/i18n";
import User from "../../../../user/user-store";

const AccountSingleBlock = ({
  data,
  statistic,
  onDeleteAccount,
  onEditAccount,
}) => {
  return (
    <Box style={{ position: "relative", minWidth: 275, width: "100%" }} className='with-border'>
      <Heading size={5} style={{ margin: "0 46px 10px" }}>
        {data?.name}
        {User.getPermissionEnable("showbalance") && (
          <span className="nowrap">
            {" "}
            (
            <Money
              amount={data["balance"]}
              aid={data["id"]}
              wrapper={DOM.span}
            />
            )
          </span>
        )}
      </Heading>
      {!ExpensesStore.isSystemAccount(data["id"]) &&
        User.getPermissionEnable("showbalance") && User.getPermissionEnable("edit-balance") && (
          <div
            style={{ position: "absolute", top: 16, right: 16 }}
            className="display-flex-row"
          >
            <Button
              title={T('edit-btn')}
              className="button-link-without-style"
              onClick={() => onEditAccount(data)}
            >
              <FontAwesomeIcon icon="edit" size="1x" />
            </Button>
            {data.types !== 1 && <Button
              title={T('delete')}
              className="button-link-without-style"
              onClick={() => onDeleteAccount(data.id)}
            >
              <FontAwesomeIcon icon="trash-alt" size="1x"/>
            </Button>}
          </div>
        )}
      <Columns>
        {User.getPermissionEnable("showbalance") && (
          <Columns.Column>
            <Box>
              <b>{T("start-period")}</b>
              <br />
              <Money
                amount={statistic?.period_first}
                aid={data["id"]}
                offDept
              />
            </Box>
          </Columns.Column>
        )}
        {User.getPermissionEnable("showbalance") && (
          <Columns.Column>
            <Box>
              <b>{T("end-period")}</b>
              <br />
              <Money amount={statistic?.period_last} aid={data["id"]} offDept />
            </Box>
          </Columns.Column>
        )}
        <Columns.Column>
          <Box>
            <b>{T("count-transaction")}</b>
            <br />
            {statistic?.count || 0}
            {T("items-p")}
          </Box>
        </Columns.Column>
        {User.getPermissionEnable("show-cashflow") && (
          <Columns.Column>
            <Box>
              <b>{T("sum-coming")}</b>
              <br />
              <Money
                className="text-success"
                amount={statistic?.coming}
                aid={data["id"]}
                offDept
              />
            </Box>
          </Columns.Column>
        )}
        {User.getPermissionEnable("show-cashflow") && (
          <Columns.Column>
            <Box>
              <b>{T("sum-expense")}</b>
              <br />
              <Money
                className="text-danger"
                amount={Math.abs(statistic?.expense || 0)}
                aid={data["id"]}
                offDept
              />
            </Box>
          </Columns.Column>
        )}
      </Columns>
    </Box>
  );
};

export default AccountSingleBlock;
