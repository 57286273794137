
export const getActiveIdsItem = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }
  return data.reduce((accumulator, obj) => {
    if (obj && obj.data && Array.isArray(obj.data) && obj.data.length > 0) {
      accumulator = accumulator.concat(
        obj.data.map((innerObj) => innerObj.value)
      );
    }

    return accumulator;
  }, []);
};

export const getFilteredListWithoutActive = (
  list,
  activeCategory,
  activeItem
) => {
  return list.filter((item) => {
    const isIgnoredItem =
      activeItem &&
      activeItem.data &&
      Array.isArray(activeItem.data) &&
      activeItem.data.some((obj) => obj.value === item.id);

    if (isIgnoredItem) {
      return true;
    }

    return activeCategory ? !activeCategory.includes(item.id) : true;
  });
};
