import React from "react";
import Container from "../../common/container/container";
import s from "./styles.module.scss";
import T from '../../../components/i18n'
import Title from "../../common/title/title";


const PartnerItem = ({ src, alt }) => {
  return (
    <span className={s.partner_item}>
      <img src={src} alt={alt} className={s.partner_img} />
    </span>
  );
};

const PartnerSliderBlock = ({list, title}) => {
  return (
    <Container classNameWrapper={s.wrapper} classNameContainer={s.section}>
      <Title>
        {T(title)}
      </Title>
      <section className={s.partners}>
        {list?.map((item, index) => (
          <PartnerItem key={index} src={item.src} alt={item.alt} />
        ))}
        {list?.map((item, index) => (
          <PartnerItem key={index} src={item.src} alt={item.alt} />
        ))}
      </section>
    </Container>
  );
};

export default PartnerSliderBlock;
