import React, { Component } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";

export default function connectToStores(Cmp, stores, noCopy = false, additionalProps = {}) {
    const componentName = Cmp.displayName || Cmp.name;
    class StoreConnector extends Component {
        static displayName = componentName + "StoreConnector";
        _state = null;
        _listeners = [];

        constructor(props, context) {
            super(props, context);
            const state = {};

            for (const alias in stores) {
                if (stores.hasOwnProperty(alias)) {
                    if (alias === "_") {
                        Object.assign(state, stores[alias].getInitialState());
                    } else {
                        if (typeof (stores[alias].getInitialState) !== "function") {
                            throw Error("Missed 'getInitialState' for " + alias + " at: " + componentName);
                        }
                        state[alias] = stores[alias].getInitialState();
                    }
                }
            }

            this._state = state;
            this.state = state;
        }


        //static getDerivedStateFromProps(props, state) {
        componentDidMount() {
            this._listeners = [];
            for (const alias in stores) {
                if (stores.hasOwnProperty(alias)) {
                    this._listeners.push(stores[alias].listen(this._onStoreChange.bind(this, alias)));
                }
            }
        }

        componentWillUnmount() {
            while (this._listeners.length) {
                this._listeners.pop()();
            }
        }

        _onStoreChange(alias, data) {
            if (!this._state) {
                this._state = {};
            }

            if (alias === "_") {
                Object.assign(this._state, data);
                return this.setState(this._state);
            }

            this._state[alias] = data;
            this.setState(this._state);
        }

        render() {
            const state = noCopy ? this.state : _copy1lvl(this.state);
            return React.createElement(Cmp, Object.assign({}, additionalProps, this.props, state));
        }
    }

    hoistNonReactStatics(StoreConnector, Cmp);
    return StoreConnector;
}


function _copy1lvl(obj) {
    const result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            result[key] = _copy(obj[key]);
        }
    }

    return result;
}

function _copy(value) {
    if (!value || typeof (value) !== "object") {
        return value;
    }

    if (value instanceof Array) {
        return value.slice(0);
    }

    if (value instanceof Date) {
        return new Date(+value);
    }

    if (value.constructor !== Object) {
        // we can't use Object.assign here, because it will spoil the value
        return value;
    }

    return Object.assign({}, value);
}
