import React from 'react'
import Button from "react-bulma-components/lib/components/button";
import T from '../../../../components/i18n'
import SubmitButton from "../../../../components/submit-button";
const ActionsButton = ({isLoading, onSubmit, onClose}) => {
	return (
		<div className='display-flex-row margin-top-10' style={{justifyContent: 'center'}}>
			<SubmitButton text={T('btn-save')} submit={onSubmit} color='success' size='large' fullwidth={false} waiting={isLoading}/>
			<Button onClick={onClose} size='large' rounded>{T('close')}</Button>
		</div>
	);
};

export default ActionsButton;