import { Component } from 'react';
// import PaginationBlock from "components/pagination";
import confirm from 'tools/confirm'
import T from "components/i18n";

class BaseForm extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
        this.onChangeSimple = this.onChangeSimple.bind(this);
    }

    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: {
            'value': value
          }
        });
    }

    onChangeSimple(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: value
        });
    }

    onChangeCheckbox(evt) { 
        const value = evt.target.checked,
              name = evt.target.name

        this.setState({
            [name]: value
        });

    }

    checkVal(value) {
      let val = typeof value === 'string' ? value.trim() : value, last_char = value[value.length-1];
        if (last_char === "," || last_char === "." || last_char === "0") {
          val = value.replace(",",".")
          let count = val.split(".").length - 1;
          if (count > 1) {
            val = val.slice(0, -1);
          }
        } else {
          val = parseFloat(value);

          if (isNaN(val)) {
            val = "";
          }
        }

        return val
    }

    onChangeNumber(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
            [name]: this.checkVal(value)
        });
    } 

    onChangeSelect(selectedOption, actionMeta) {
        this.setState({
          [actionMeta.name]: selectedOption
        })
    }

    confrimDeleted(action) {
      // console.log("action: ", action);
        confirm('confirm-delete', T('are-you-sure-to-delete'), action, T('continue-btn'))
    }
} 


export default BaseForm;