import React from 'react'
import Container from "../../common/container/container";
import RegForm from "../../../modules/registration";
import s from './styles.module.scss'
import AppBlock from "../../common/app-block/app-block";
import T from "components/i18n";
import AppStore from 'app-store';


const FooterRegistration = ({android, ios, title, subTitle}) => {
	return (
		<Container classNameContainer={s.section} classNameWrapper={s.wrapper}>
			<section className={s.content}>
				<h2 className={s.title}>{T(title)}{AppStore.getAppName()}</h2>
				<h4 className={s.sub_title}>{T(subTitle)}</h4>
				<AppBlock ios={ios} android={android}/>
			</section>
			<section className={s.content}>
				<RegForm/>
			</section>
		</Container>
	);
};

export default FooterRegistration;