import React from 'react'
import T from '../i18n'
import s from './styles.module.scss'

const TypeProductTag = ({short, typeProduct}) => {
	if(typeProduct === 4) {
		return (
			<span className={s.services}>
			{short ? T('services-tag-short') : T('services-tag')}
		</span>)
	}

	if(typeProduct === 3) {
		return (
			<span className={s.bundle}>
			{short ? T('bundle-tag-short') : T('bundle-tag')}
		</span>)
	}

	return null

};

export default TypeProductTag;