import React, {useEffect, useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import {
	Control,
	Field,
	Input, Label
} from "react-bulma-components/lib/components/form";
import ActionsModal from "./actions-modal";
import T from "../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import ClientsActions from "../clients-actions";
import {errorHandler} from "../../../tools/error-handler";
import ErrorMessageBlock
	from "../../../components/error-message-block/error-message-block";

const AddClientsTagModal = ({id, color, name, isEdit, onClose}) => {
	const [valueName, setValueName] = useState(name || '')
	const [valueColor, setValueColor] = useState(color || '#FFFFFF')
	const [waiting, setWaiting] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const handleChangeInput = (evt) => {
		const value = evt.target.value
		const name = evt.target.name

		if(name === 'color') setValueColor(value)
		if(name === 'name') setValueName(value)
	}

	const handleSubmit = () => {
		const data = {
			name: valueName,
			color: valueColor,
		}
		if(id) data.tid = id
		setWaiting(true)
		ClientsActions.addClientsTags(data)
	}

	useEffect(() => {
		const addClientsTagCompleted = ClientsActions.addClientsTags.completed.listen(() => {
			setWaiting(false)

			if(onClose) onClose()
		})

		const addClientsTagFailed = ClientsActions.addClientsTags.failed.listen((res) => {
			const message = errorHandler(res)
			setWaiting(false)
			setErrorMessage(message)

			setTimeout(() => setErrorMessage(null),4000)
		})

		return () =>  {
			addClientsTagCompleted()
			addClientsTagFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading size={3} align='center'>{isEdit ? T('edit-clients-tags') : T('add-clients-tags')}</Heading>
				<Field>
					<Control>
						<Label>{T('tag-name')}</Label>
						<Input
							name='name'
							value={valueName}
							onChange={handleChangeInput}
						/>
					</Control>
				</Field>
				<Field>
					<Control>
						<Label>{T('tag-color')}</Label>
						<Input
							name='color'
							type='color'
							value={valueColor}
							onChange={handleChangeInput}
							style={{width: '44px'}}
						/>
					</Control>
				</Field>
				<ActionsModal onSubmit={handleSubmit} waitingSubmit={waiting} onClose={onClose} isEdit={isEdit}/>
			</Box>
		</>
	);
};

export default AddClientsTagModal;