import ProductsStore from "../../products/products-store";

export const sumAccruals = (statistics) =>
  Object.values(statistics.accrual || {}).reduce(
    (acc, cur) =>
      acc +
      Object.values(cur).reduce(
        (accValues, curValue) => accValues + curValue.amount,
        0
      ),
    0
  ) + statistics.accrual_general.amount || 0;

const calculatePercentage = (amount, percent) =>
  (parseFloat(amount) * parseFloat(percent)) / 100;

const regenerateToObject = (data) => {
  if (!Array.isArray(data)) return {};

  return data.reduce((acc, cur) => {
    cur.data.forEach((item) => {
      acc[item.value] = {
        value: parseFloat(cur.value),
        type: cur?.type || "%"
      };
    });
    return acc;
  }, {});
};

const reduceCalcAmount = (array) => {
  if (!Array.isArray(array) && !array.length) return 0;
  return array.reduce((acc, cur) => acc + cur.amount, 0);
};

export const calcStatisticsEmployees = (userData, salesData) => {
  const regenerateData = userData.reduce((acc, cur) => {
    acc[cur.user_id] = {
      category: regenerateToObject(cur.more_setting?.accrual?.category),
      brand: regenerateToObject(cur.more_setting?.accrual?.brand),
      general: parseFloat(cur.more_setting?.accrual?.general) || 0,
      general_type: cur?.more_setting?.accrual?.general_type || '%',
      accrual_type: cur.more_setting?.accrual?.accrual_type || 'turnover',
      user_id: cur.user_id,
      name: cur.name,
      phone: cur.phone,
      email: cur.email,
      is_active: cur.is_active,
      sales_units: 0,
      sales_total: 0,
      sales_amount: 0,
      sales_profit: 0,
      numDays: 0,
      accrual_general: {
        amount: 0,
        units: 0,
        sales: 0,
        items_amount: 0,
        items_profit: 0,
      },
      accrual: {
        category: {},
        brand: {},
      },
    };

    return acc;
  }, {});
  const salesPerData = {};
  salesData.forEach((sale) => {
    const responsibleUserId = sale.responsible_user_id;
    if (regenerateData[responsibleUserId]) {
      regenerateData[responsibleUserId].sales_total += 1;
      regenerateData[responsibleUserId].sales_amount += parseFloat(`${sale.amount_sale}`);
      if (!salesPerData[responsibleUserId]) {
        salesPerData[responsibleUserId] = {};
      }

      const salesPerDate = new Date(sale.purchase_date * 1000).toDateString();
      salesPerData[responsibleUserId][salesPerDate] = true;

      let isCategoryCount = false
      let isBrandCount = false
      let isGeneralCount = false

      sale.items.forEach((product, index) => {
        regenerateData[responsibleUserId].sales_units += product.quantity;
        regenerateData[responsibleUserId].sales_profit += product.profit;

        const getProductById = ProductsStore.getProductByMid(
          product.product_id,
          product.mid
        );

        const productPrice = regenerateData[responsibleUserId].accrual_type === 'profits' ? product.profit : product.amount;

        const productCategoryID = getProductById.category_id;
        const productBrandID = getProductById.brand_id;

        const userCategoryPercentageData =
          regenerateData[responsibleUserId].category;
        const userBrandPercentageData = regenerateData[responsibleUserId].brand;

        const userGeneralPercentageData =
          regenerateData[responsibleUserId].general;

        const isHavePercentageBrand = userBrandPercentageData[productBrandID]?.value;
        const isHavePercentageCategory =
          userCategoryPercentageData[productCategoryID]?.value;

        const typeBrand = userBrandPercentageData[productBrandID]?.type
        const typeCategory = userCategoryPercentageData[productCategoryID]?.type
        const generalType = regenerateData[responsibleUserId]?.general_type

        const userCategoryAccrualData =
          regenerateData[responsibleUserId].accrual.category;
        const userBrandAccrualData =
          regenerateData[responsibleUserId].accrual.brand;

        if (!userBrandAccrualData[productBrandID]) {
          userBrandAccrualData[productBrandID] = {
            amount: 0,
            units: 0,
            sales: 0,
            items_amount: 0,
            items_profit: 0,
          };
        }
        if (!userCategoryAccrualData[productCategoryID]) {
          userCategoryAccrualData[productCategoryID] = {
            amount: 0,
            units: 0,
            sales: 0,
            items_amount: 0,
            items_profit: 0,
          };
        }
        if (
          userGeneralPercentageData &&
          !isHavePercentageBrand &&
          !isHavePercentageCategory
        ) {
          if(generalType === 'fix') {
            regenerateData[responsibleUserId].accrual_general.amount += userGeneralPercentageData
          } else {
            regenerateData[responsibleUserId].accrual_general.amount +=
              calculatePercentage(productPrice, userGeneralPercentageData);
          }
          regenerateData[responsibleUserId].accrual_general.units +=
            product.quantity;
          regenerateData[responsibleUserId].accrual_general.items_amount += product.amount;
          regenerateData[responsibleUserId].accrual_general.items_profit += product.profit;

          if(!isGeneralCount) {
            regenerateData[responsibleUserId].accrual_general.sales += 1;
            isGeneralCount = true;
          }

        } else {
          if (isHavePercentageCategory && !isHavePercentageBrand) {
            if(typeCategory === 'fix') {
              userCategoryAccrualData[productCategoryID].amount += isHavePercentageCategory
            } else {
              userCategoryAccrualData[productCategoryID].amount +=
                calculatePercentage(productPrice, isHavePercentageCategory);
            }

            userCategoryAccrualData[productCategoryID].units +=
              product.quantity;
            userCategoryAccrualData[productCategoryID].items_amount +=
              product.amount;
            userCategoryAccrualData[productCategoryID].items_profit +=
              product.profit;
            if(!isCategoryCount) {
              userCategoryAccrualData[productCategoryID].sales += 1;
              isCategoryCount = true;
            }

          } else if (!isHavePercentageCategory && isHavePercentageBrand) {
            if(typeBrand === 'fix') {
              userBrandAccrualData[productBrandID].amount += isHavePercentageBrand
            } else {
              userBrandAccrualData[productBrandID].amount += calculatePercentage(
                productPrice,
                isHavePercentageBrand
              );
            }

            userBrandAccrualData[productBrandID].units += product.quantity;
            userBrandAccrualData[productBrandID].items_amount += product.amount;
            userBrandAccrualData[productBrandID].items_profit += product.profit;
            if(!isBrandCount) {
              userBrandAccrualData[productBrandID].sales += 1;
              isBrandCount = true;
            }

          } else if (isHavePercentageCategory && isHavePercentageBrand) {
            const isBrandMore =
              isHavePercentageBrand > isHavePercentageCategory;
            if (isBrandMore) {
              if(typeBrand === 'fix') {
                userBrandAccrualData[productBrandID].amount += isHavePercentageBrand
              } else {
                userBrandAccrualData[productBrandID].amount +=
                  calculatePercentage(productPrice, isHavePercentageBrand);
              }

              userBrandAccrualData[productBrandID].units += product.quantity;
              userBrandAccrualData[productBrandID].items_amount += product.amount;
              userBrandAccrualData[productBrandID].items_profit += product.profit;

              if(!isBrandCount) {
                userBrandAccrualData[productBrandID].sales += 1;
                isBrandCount = true;
              }

            } else {
              if(typeCategory === 'fix') {
                userCategoryAccrualData[productCategoryID].amount += isHavePercentageCategory
              } else {
                userCategoryAccrualData[productCategoryID].amount +=
                  calculatePercentage(productPrice, isHavePercentageCategory);
              }
              userCategoryAccrualData[productCategoryID].units +=
                product.quantity;
              userCategoryAccrualData[productCategoryID].items_amount +=
                product.amount;
              userCategoryAccrualData[productCategoryID].items_profit += product.profit;

              if(!isCategoryCount) {
                userCategoryAccrualData[productCategoryID].sales += 1;
                isCategoryCount = true;
              }
            }
          }
        }
        if(index === sale.items.length - 1) {
          isGeneralCount = false
          isBrandCount = false
          isCategoryCount = false
        }
      });
    }
  });

  Object.keys(salesPerData).forEach((item) => {
    regenerateData[item].numDays = Object.keys(salesPerData[item]).length;
  });

  const calcSumStatisticsUser = Object.values(regenerateData).map((user) => {
    user.sumAccrual =
      reduceCalcAmount(Object.values(user.accrual.brand)) +
      reduceCalcAmount(Object.values(user.accrual.category)) +
      user.accrual_general.amount;

    return user;
  });

  const activeUser = calcSumStatisticsUser.filter(item => !!item.is_active)
  const notActiveUser = calcSumStatisticsUser.filter(item => !item.is_active)

  activeUser.sort((a, b) => b.sumAccrual - a.sumAccrual)


  return [...activeUser, ...notActiveUser];

};
