import React from "react";
import { Columns } from "react-bulma-components";
import AccountSingleBlock from "./account-single-block";

const AccountListBlock = ({ list, onDeleteAccount, onEditAccount }) => {
  return (
    <>
      <Columns>
        {list.map((item) => (
          <Columns.Column key={item.id}>
            <AccountSingleBlock
              data={item}
              statistic={item.statistics || {}}
              onDeleteAccount={onDeleteAccount}
              onEditAccount={onEditAccount}
            />
          </Columns.Column>
        ))}
      </Columns>
    </>
  );
};

export default AccountListBlock;
