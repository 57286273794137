import React from "react";
import { Button } from "react-bulma-components";
import T from "../../../../components/i18n";
import AppStore from "../../../../app-store";
import EditSalesModal from "../../edit-sales-modal";
import PrintModal from "../../print-modal";
import ChangeAccount from "../../change-account";
import FinishReservModal from "../../finish-reserv-modal";
import ProcessDeliveryModal from "../../process-delivery-modal";
import User from "../../../../user/user-store";

const SaleViewActions = ({
  onClose,
  saleID,
  saleNumber,
  accountID,
  prepaidAccountID,
  clientsDeliveryID,
  amountSale,
  prepaidAmount,
  status,
  dps,
  isRefund
}) => {
  if (!User.getModuleEnable("slip")) return null;

  const handleEditModal = () => {
    onClose && onClose();
    AppStore.openModal(<EditSalesModal oid={saleID} isRefund={isRefund}/>);
  };

  const handleOpenPrintModal = () => {
    AppStore.openModal(<PrintModal oid={saleID} dps={dps} />);
  };

  const handleOpenCodModal = () => {
    AppStore.openModal(
      <ChangeAccount
        fromAccount={{ value: accountID }}
        amount={amountSale - prepaidAmount}
        complete={true}
        oid={saleID}
      />
    );
  };

  const handleOpenReserv = () => {
    AppStore.openModal(
      <FinishReservModal
        number={saleNumber || saleID}
        aid={prepaidAccountID || accountID}
        prepaid={prepaidAmount}
        total_order={amountSale}
        oid={saleID}
      />
    );
  };

  const handleOpenDeliveryModal = () => {
    AppStore.openModal(
      <ProcessDeliveryModal
        aid={accountID}
        prepaid={prepaidAmount}
        total_order={amountSale}
        prepaid_account_id={prepaidAccountID}
        clients_delivery_id={clientsDeliveryID}
        oid={saleID}
      />
    );
  };

  let buttonAction = <></>;

  switch (status) {
    case "reserve_sale":
      buttonAction = (
        <Button size="medium" rounded color="info" onClick={handleOpenReserv}>
          {T("finish-reserv-title")}
        </Button>
      );
      break;
    case "cod":
      buttonAction = (
        <Button size="medium" rounded color="info" onClick={handleOpenCodModal}>
          {T("pick-up-transfer")}
        </Button>
      );
      break;
    case "delivery" || "delivering":
      buttonAction = (
        <Button
          size="medium"
          rounded
          color="info"
          onClick={handleOpenDeliveryModal}
        >
          {T("to-send")}
        </Button>
      );
      break;
    default:
      buttonAction = (
        <Button
          size="medium"
          rounded
          color="info"
          onClick={handleOpenPrintModal}
        >
          {T("documents")}
        </Button>
      );
  }

  return (
    <div style={{ padding: 10 }} className="display-flex-row-gap flex-wrap">
      <div>{buttonAction}</div>
      <div className="display-flex-row">
        <Button size="medium" rounded color="success" onClick={handleEditModal}>
          {isRefund ? T('restore-label') : T("edit-btn")}
        </Button>
        <Button onClick={onClose} size="medium" rounded>
          {T("close")}
        </Button>
      </div>
    </div>
  );
};

export default SaleViewActions;
