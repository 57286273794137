import React from "react";
import Container from "../../common/container/container";
import s from "./styles.module.scss";
import T from "../../../components/i18n";
import { Link } from "react-router-dom";
import AppStore from "../../../app-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import UserStore from "../../../user/user-store";
import { isIOS } from "react-device-detect";
import Title from "../../common/title/title";

const TarifCard = ({
  title,
  subTitle,
  price,
  currency,
  sales,
  products,
  color,
  popular,
  tarif,
}) => {
  return (
    <article
      className={`${s.wrapper_card} ${color ? s[color] : s.green} ${
        popular ? s.popular : ""
      }`}
    >
      <h4 className={s.card_title}>{T(title)}</h4>
      <h5 className={s.card_sub_title}>{T(subTitle)}</h5>
      <span className={s.card_price_wrapper}>
        <span className={s.card_price}>{price ? price : "FREE"}</span>
        <span className={s.card_currency}>
          {T(`currency-${currency}`)} / {T("month")}
        </span>
      </span>
      <Link to={{
        pathname: AppStore.getLink("registration"),
        tarif: price ? tarif : false
      }} className={`${s.link} tarif-${tarif}`}>
        {price ? T("buy-now") : T("start-label")}
      </Link>
      <b>{T("you-can-add")}:</b>
      <span className={s.you_add}>
        <FontAwesomeIcon icon={faCheckCircle} /> {sales} {T("sales-month")}
      </span>
      <span className={s.you_add}>
        <FontAwesomeIcon icon={faCheckCircle} /> {products}{" "}
        {T("tarif-products")}
      </span>
      <span className={s.you_add}>
        <FontAwesomeIcon icon={faCheckCircle} /> {T("unlimited-employees")}
      </span>
      <span className={s.you_add}>
        <FontAwesomeIcon icon={faCheckCircle} /> {T("unlimited-warehouse")}
      </span>
      <span className={s.you_add}>
        <FontAwesomeIcon icon={faCheckCircle} /> {T("unlimited-integrations")}
      </span>
      {popular && <span className={s.popular}>{T('most-popular')}</span>}
    </article>
  );
};

const WHITELABLE = process.env.REACT_APP_WHITELABLE;

const TarifBlock = ({ list }) => {
  let currencyId = UserStore.get("currency_id");

  if (isIOS) {
    currencyId = 1;
  }

  if (WHITELABLE === "modeler") {
    currencyId = 1;
  }

  return (
    <Container
      id="price"
      classNameWrapper={s.wrapper}
      classNameContainer={s.section}
    >
      <Title color='secondary'>
        {T("tarif-heading")}
      </Title>
      <section className={s.tarif_wrapper}>
        {list?.map((item) => (
          <TarifCard
            key={item.name}
            sales={item.sales}
            products={item.products}
            currency={currencyId}
            price={item?.amount[currencyId]}
            color={item.color}
            title={item.name}
            tarif={item.index}
            popular={item?.popular}
            subTitle={
              item.desc ||
              "Обирайте найкращий план та розвивайте свій бізнес разом з нами"
            }
          />
        ))}
      </section>
    </Container>
  );
};

export default TarifBlock;
