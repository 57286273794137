import React, {useState} from "react";
import {Box, Heading, List} from "react-bulma-components";
import ProductsStore from "../../../products/products-store";
import {
  Checkbox,
  Control,
  Field,
} from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import ActionsModal from "./actions-modal";

const PriceTagSettingAttrFieldModal = ({active, activeList,onSubmit, onClose}) => {
  const list = ProductsStore.getAttrList();
  const stateActiveList = activeList && activeList?.length && activeList?.reduce((acc,cur) => {
    acc[cur] = true
    return acc
  },{})
  const defaultActive = list && list?.length && list.reduce((acc,curr) => {
    acc[curr.id] = true
    return acc
  }, {})

  const defaultCheckboxValues = active ? activeList?.length ? stateActiveList : defaultActive : {}

  const [checkboxValues, setCheckboxValues] = useState(defaultCheckboxValues)

  const handleChangeCheckbox = ({target: { name, checked}}) => {
    setCheckboxValues(prev => ({
      ...prev,
      [name]: checked
    }))
  }

  const handleListItemClick = (itemId) => {
    const isChecked = checkboxValues[itemId]
    const target = {
      name: itemId,
      checked: !isChecked
    }
    handleChangeCheckbox({target})
  }

  const handleSubmit = () => {
    if(onSubmit) {
      const activeList = Object.keys(checkboxValues).filter(item => checkboxValues[item])
      if(activeList.length === list.length) {
        onSubmit(true)
      } else if(!activeList.length) {
        onSubmit(false)
      } else {
        onSubmit(activeList)
      }
      onClose && onClose()
    }
  }

  return (
    <Box>
      <Heading>{T('detailed-customization')}: {T('attr-list')}</Heading>
      <List style={{maxHeight: 400, overflow: 'auto'}}>
        {!!list && !!list.length ? list.map((item) => (
          <List.Item key={item.id} className='cursor-pointer' onClick={() => handleListItemClick(item.id)} >
            <Field>
              <Control>
                <Checkbox
                  name={`${item.id}`}
                  onChange={handleChangeCheckbox}
                  className="termo_print"
                  checked={checkboxValues[item.id] || false}
                >
                  {T(item.name)}
                </Checkbox>
              </Control>
            </Field>
          </List.Item>
        )) : <List.Item>{T('not-have-attr')}</List.Item>}
      </List>
      <ActionsModal onClose={onClose} onSubmit={handleSubmit} />
    </Box>
  );
};

export default PriceTagSettingAttrFieldModal;
