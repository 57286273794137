import {createActions} from "../../tools/reflux-tools";

const ProductionActions = createActions({
    add: { asyncResult: true },
    delete: { asyncResult: true },
    load: { asyncResult: true },
    loadHistory: { asyncResult: true },
    loadCategory: {asyncResult: true },
    addCategory: { asyncResult: true },
    deleteCategory: {asyncResult: true },
    cancelProduction: {asyncResult: true },
    restore: {asyncResult: true },
});

export default ProductionActions;