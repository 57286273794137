import React, { Component } from 'react';
import Image from 'react-bulma-components/lib/components/image';
import Box from 'react-bulma-components/lib/components/box';

class InfoImage extends Component { 
  	render () {
  		const {text, link} = this.props;
		return (
			<div align="center">
				<Box  className="help-image is-centered">
					<Image src={link}/>
					<b>{text}</b>
				</Box>
			</div>
		);
	}
}

export default InfoImage;