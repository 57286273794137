import React, { useState } from "react";
import UserStore from "../../../user/user-store";
import { Select } from "react-bulma-components/lib/components/form";
import T from "../../../components/i18n";
import { Button } from "react-bulma-components";
import InfoIcon from "../../../components/info-wrapper/Info-icon";

const MassChangeTypePrice = ({ value, onChangeValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const customPriceList = UserStore.getCustomPriceForList();
  const base_price_item = { name: T("basic-price-label"), id: "0" };
  return (
    Array.isArray(customPriceList) &&
    !!customPriceList.length && (
      <div
        className="display-flex-row"
        style={{ justifyContent: "flex-end" }}
      >
        {!isOpen && (
          <Button
            className="button-link-without-style"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <span className='surrender'>
              {T('change-price-type-bulk')}
            </span>
          </Button>
        )}
        {isOpen && (
          <div className="display-flex-row" style={{ width: "50%" }}>
            <Select
              name="globalTypePrice"
              value={value}
              onChange={onChangeValue}
            >
              {[base_price_item, ...customPriceList].map((item, index) => (
                <option key={`${item.name}-${index}`} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
            <InfoIcon info="change-price-type-bulk-tip" />
          </div>
        )}
      </div>
    )
  );
};

export default MassChangeTypePrice;
