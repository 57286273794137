import React, {useEffect, useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from '../../../components/i18n'
import RadioButtonsList
	from "../../../components/radio-buttons-list/radio-buttons-list";
import {Field, Input, Label} from "react-bulma-components/lib/components/form";
import SubmitButton from "../../../components/submit-button";
import Button from "react-bulma-components/lib/components/button";
import ErrorMessageBlock
	from "../../../components/error-message-block/error-message-block";
import UserStore from "../../../user/user-store";
import UserActions from "../../../user/user-actions";
import {errorHandler} from "../../../tools/error-handler";

const RADIO_LIST = [
	{
		title: 'products',
		value: 'product'
	},
	// {
	// 	title: 'orders',
	// 	value: 'order'
	// },
	{
		title: 'sales',
		value: 'sale'
	}
]

const AddAdditionalField = ({onClose, type, name, disabledRadio}) => {
	const [typeState, setTypeState] = useState(type || 'product')
	const [additionalFieldName, setAdditionalFieldName] = useState(name || '')

	const [errorMessage, setErrorMessage] = useState(null)
	const [waiting, setWaiting] = useState(false)

	const handleAutoRemoveMessage = (message) => {
		setErrorMessage(T(message))
		setTimeout(() => setErrorMessage(null), 4000)
	}

	const handleChangeType = ({value}) => {
		setTypeState(value)
	}

	const handleChangeValueInput = (evt) => {
		const target = evt.target
		const value = target.value
		setAdditionalFieldName(value)
	}

	const handleError = () => {
		const getList = UserStore.getAdditionalFieldsList(typeState)

		if(getList.includes(additionalFieldName) && !name) {
			handleAutoRemoveMessage('field-already-exists ')
			return false
		}
		if(!additionalFieldName){
			handleAutoRemoveMessage('not all fields required')
			return false
		}

		return true
	}

	const handleSubmit = () => {
		if(handleError()) {
			setWaiting(true)
			UserStore.setAdditionalFieldByName(typeState, additionalFieldName, name)
		}
	}

	useEffect(() => {
		const updateSettings = UserActions.updateSettings.completed.listen(() => {
			onClose()
		})

		const updateSettingsFailed = UserActions.updateSettings.failed.listen(res => {
			setWaiting(false)
			const message = errorHandler(res)
			handleAutoRemoveMessage(message)
		})

		return () => {
			updateSettings()
			updateSettingsFailed()
		}
// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading size={3}>{T(name ? 'edit-additional-field-label' : 'add-additional-field-btn')}</Heading>
				<RadioButtonsList
					list={RADIO_LIST}
					name='type'
					label='where-add-additional-field'
					defaultValue={typeState}
					onChange={handleChangeType}
					disabledRadio={disabledRadio}
				/>
				<Field>
					<Label>{T('additional-field')}</Label>
					<Input
						value={additionalFieldName}
						onChange={handleChangeValueInput}
						placeholder={T('additional-field')}
					/>
				</Field>
				<Button.Group position='right'>
					<Button
						size='large'
						rounded
						color='light'
						onClick={onClose}
					>
						{T('close')}
					</Button>
					<SubmitButton fullwidth={false} size='large' text={name ? 'edit-additional-field-btn' : 'add-additional-field-btn'} waiting={waiting} submit={handleSubmit}/>
				</Button.Group>
			</Box>
		</>
	);
};

export default AddAdditionalField;