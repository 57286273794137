import React, {useEffect} from "react";
import Reports from "../../../components/reports";
import Actions from "../production-actions";
import ProductionRow from "./production-row";
import EmptyMessage from "../common/empty-message";
import BaseFilterLayout from "../../../components/base-filter-layout";
import ProductionStore from "../production-store";
import { Box } from "react-bulma-components";
import ActionButton from "../common/action-button";
import PaginationHook from "../../../tools/pagination-hook";
import TemplateFilter from "./template-filter";
import { applyFilter } from "../utils";
import T from "../../../components/i18n";
import useFilterHook from "../../../tools/use-filter-hook/use-filter-hook";
import ProductionActions from "../production-actions";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const COLUMNS = ["info", "status", ""];

const Template = ({isDeleted}) => {
  const {isMobileSize} = useIsMobile()
  const {
    filters,
    onChangeFilter,
    onChangeSearch,
    onClearFilter,
  } = useFilterHook({
    defaultValues: {
      category: { value: 0, label: T("all") },
      search: "",
      mid: { value: 0, label: T("all-warehouse") },
    },
  });

  const data = isDeleted ? ProductionStore.get('productionDeleted') : ProductionStore.get("production");
  const withFilter = data?.filter((item) => applyFilter(item, filters, false, true));
  const { page, total, start, end, PaginationBlock, setPage } = PaginationHook({
    listLength: withFilter?.length || 0,
  });

  const handleRefresh = () => {
    onClearFilter();
    ProductionActions.load();
  };

  const filterPanel = (
    <BaseFilterLayout
      searchStringSetting={{
        onChange: onChangeSearch,
        defaultValue: filters.search,
      }}
      refresh={{
        onChange: handleRefresh,
      }}
      hiddenFilter={
        <TemplateFilter filters={filters} onChangeFilters={onChangeFilter} />
      }
    />
  );

  useEffect(() => {
    if(isDeleted) {
      ProductionActions.load(false, true);
    } else {
      ProductionActions.load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isMobileSize && filterPanel}
      {!isDeleted && <ActionButton/>}
      <Box className="production-report">
        {isMobileSize && filterPanel}
        <PaginationBlock
          setPage={setPage}
          totalPage={total}
          currentPage={page}
        />
        <Reports
          load={Actions.load}
          emptyMessage={EmptyMessage}
          columns={COLUMNS}
          hideFooterOnEmpty
          rows={withFilter ? withFilter.slice(start, end) : []}
        >
          <ProductionRow role="row" isDeleted={isDeleted}/>
        </Reports>
        <PaginationBlock
          setPage={setPage}
          totalPage={total}
          currentPage={page}
        />
      </Box>
    </>
  );
};

export default Template;
