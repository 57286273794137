export default function roundSignificant(number, precision) {
  if (!number || number === "") {
    return "";
  }
  if (number % 1 === 0) { // Если число целое, возвращаем как есть
    return number;
  }

  const multiplier = Math.pow(10, precision || 0);
  let roundedNumber = Math.round(number * multiplier) / multiplier;

  // Удаление незначащих нулей
  const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${precision}}[^0])?`);
  roundedNumber = roundedNumber.toString().match(regex)[0];

  return parseFloat(roundedNumber);
}
