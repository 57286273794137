export const splitArrayChunks= (array, chunkSize = 200) =>  {
	const result = [];
	if(array?.length) {
		for (let i = 0; i < array.length; i += chunkSize) {
			result.push(array.slice(i, i + chunkSize));
		}
	} else {
		return array
	}

	return result;
}