import React from "react";
import List from "react-bulma-components/lib/components/list";
import { LocalIcon } from "whitelables/wl-name/icons";
import T from "../../i18n";
import User from "../../../user/user-store";

const SelectedItem = ({ label, is_global, onDelete }) => {
  return (
    <List.Item>
      {T(label, {'curr': User.getCurrency()})}
      {!is_global && (
        <span onClick={() => onDelete(label)} className="cursor-pointer">
          <LocalIcon icon="close" />
        </span>
      )}
    </List.Item>
  );
};

export default SelectedItem;
