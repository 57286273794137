
export default function getQueryParams(name) {
    let query = window.location.search;

	let p = query.replace("?","").split("&");

	let args = {};

	for (let i in p) {
		let c = p[i].split("=");
		args[c[0]] = c[1];
	}

    return (typeof(args[name]) != "undefined" ? args[name] : false);
};