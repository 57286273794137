import React, {useEffect, useState} from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import T from "../../../../components/i18n";
import SelectComponent from "../../../../tools/select-component";
import WarehouseStore from "../../../warehouse/warehouse-store";
import SubmitButton from "../../../../components/submit-button";
import ProductsActions from "../../products-actions";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../../../tools/error-handler";
import ProductsStore from "../../products-store";

const CopyProductToMid = ({data, onClose}) => {
	const [selectWarehouse, setSelectWarehouse] = useState({value: 0, label: T('select-warehouse-label')})
	const [waiting, setWaiting] = useState(false)
	const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = ErrorMessageControlHook()

	const dictData = data.reduce((acc, cur) => {
		const ident = cur.split("_")
		const pid = ident[0]
		const mid = ident[1]

		if(acc[pid]) {
			acc[pid].push(mid)
		} else {
			acc[pid] = [mid]
		}

		return acc
	}, {})

	const handleChangeSelect = (selectValue) => {
		setSelectWarehouse(selectValue)
	}

	const handleSubmit = () => {
			const submitData = Object.entries(dictData).reduce((acc, [pid, mids]) => {
				let selectMid = !!mids.find(item => parseInt(item) === parseInt(selectWarehouse.value))

				const product = ProductsStore.getProduct(pid)

				if(product) {
					selectMid = product.stock[selectWarehouse.value]
				}

				if(!selectMid) {
					acc.push(`${pid}_${mids[0]}`)
				}

				return acc
			}, [])

		if(!selectWarehouse.value) {
			handleAutoRemoveError('select-warehouse-label')
		} else if(!submitData.length) {
			handleAutoRemoveError('no-products-available-to-copy')
		} else {
			setWaiting(true)
			ProductsActions.copyStock({pid_mid: submitData, target_mid: selectWarehouse.value})
		}
	}

	const countProduct = Object.entries(dictData).reduce((acc, [pid, mids]) => {
		let findSelectMid = !!mids.find(item => parseInt(item) === parseInt(selectWarehouse.value))

		const product = ProductsStore.getProduct(pid)

		if(product) {
			findSelectMid = product.stock[selectWarehouse.value]
		}

		if(!findSelectMid) {
			acc.push(`${pid}_${mids[0]}`)
		}

		return acc
	}, [])

	useEffect(() => {
		const copyStock = ProductsActions.copyStock.completed.listen(() => {
			onClose()
		})
		const copyStockFailed = ProductsActions.copyStock.failed.listen((res) => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		return () => {
			copyStock()
			copyStockFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<>
			<ErrorMessageBlock onRemove={setErrorMessage} message={errorMessage}/>
			<Box>
				<Heading size={3}>{T('mass-copying-warehouse')}</Heading>
				<Heading subtitle className='text-danger'>{T('count-copy-items', {count: countProduct.length})}</Heading>
				<SelectComponent
					name="mid"
					empty='select-warehouse-label'
					label='warehouse-which-copied'
					onChange={handleChangeSelect}
					value={selectWarehouse}
					list={WarehouseStore.getWarehouseList}
				/>
				<div className='display-flex-row' style={{justifyContent: 'flex-end'}}>
					<Button size='medium' rounded onClick={onClose}>
						{T("close")}
					</Button>
					<SubmitButton fullwidth={false} color='success' text='copy' submit={handleSubmit} waiting={waiting} size='medium'/>
				</div>
			</Box>
		</>
	);
};

export default CopyProductToMid;