import React, { Component } from "react";
import { connectToStores } from "tools/reflux-tools";

import MobileTopBar from "./templates/mobile/topbar.js";
import MobileBottomBar from "./templates/mobile/bottombar.js";
import Columns from "react-bulma-components/lib/components/columns";
import Section from "react-bulma-components/lib/components/section";
// import Content from 'react-bulma-components/lib/components/content';
// import Box from 'react-bulma-components/lib/components/box';

import AccessDenied from "modules/dashboard/access_denied";

import { redirectTo } from "tools/redirect-to";

import { Switch, Route } from "react-router-dom";

import routes from "./components/routes/account-routes";
import routes_component from "./components/routes/account-routes-component";
import modals from "./components/routes/account-modals";

import UserStore from "user/user-store";
import UserActions from "user/user-actions";
import AppStore from "./app-store";
import SalesActions from "./modules/sales/sales-actions";
import ProductsActions from "./modules/products/products-actions";
import WarehouseActions from "./modules/warehouse/warehouse-actions";
import ExpensesActions from "./modules/expenses/expenses-actions";
import ClientsActions from "./modules/clients/clients-actions";
import OrdersActions from "./modules/orders/orders-actions";
import NotificationActions from "./modules/notification/notification-actions";
import SalesStore from "./modules/sales/sales-store";
import ProductsStore from "./modules/products/products-store";
import ChatsStore from "./modules/chats/chats-store";
import InfoProductModal from "./modules/products/info-product-modal";

import AddSaleModal from "./modules/sales/add-sales-modal";
import OpenModalFactory from "./components/modal/open-modal-factory";
import NotificationBlock from "./modules/notification/notification-block";

import WrapperSidebar from "./whitelables/wl-name/sidebar";
import AccountTopBar from "./whitelables/wl-name/account-topbar";
import { SVGSource } from "./whitelables/wl-name/icons";
import Cookies from "universal-cookie";

// import { Link } from "react-router-dom";

// import T from 'components/i18n';

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// import OpenModalFactoryComponent from 'components/modal/open-modal-factory-component';

import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faFileExcel,
  faPowerOff,
  faCheckCircle,
  faTimesCircle,
  faChartLine,
  faChartBar,
  faTrashAlt,
  faMinusCircle,
  faPlusCircle,
  faCog,
  faEye,
  faSignOutAlt,
  faPrint,
  faChartArea,
  faQuestionCircle,
  faComments,
  faUndo,
  faCheck,
  faHome,
  faMoneyBillAlt,
  faEdit,
  faExchangeAlt,
  faCartArrowDown,
  faMoneyCheckAlt,
  faThList,
  faReceipt,
  faSort,
  faTags,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import ViewProductModal from "./modules/products/modal/view-product/view-product-modal";
import AddProductsModal from "./modules/products/modal/add-product/add-products-modal";
import WelcomeMessage from "./components/welcome-message/welcome-message";
import {
  withIsMobileSize
} from "./components/is-mobile-context/is-mobile-context";
import ClientPopupList from "./components/client-popup-list/client-popup-list";
import {isMobile} from "react-device-detect";
import ChatsActions from "./modules/chats/chats-actions";
import ScreenNotification
  from "./components/screen-notification/screen-notification";
import VotingActions from "./modules/voting/voting-actions";
import VotingStore from "./modules/voting/voting-store";

library.add(
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
  faChartLine,
  faChartBar,
  faEdit,
  faFileExcel,
  faPowerOff,
  faTrashAlt,
  faCog,
  faQuestionCircle,
  faPrint,
  faChartArea,
  faExchangeAlt,
  faComments,
  faUndo,
  faCheck,
  faHome,
  faCartArrowDown,
  faMoneyCheckAlt,
  faEye,
  faReceipt,
  faThList,
  faTimesCircle,
  faSignOutAlt,
  faYoutube,
  faMoneyBillAlt,
  faSort,
  faTags,
  faCopy
);

const ENTER_KEY = 13;

class AccountLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addSale: false,
      collapse: false,
      mobile_scanner: "",
      needRedirect: false,
      go_main: false,
      isLoading: true,
      isLoadingSales: false,
      isLoadingProducts: false,
      isLoadingWarehouse: false,
      isLoadingAccounts: false,
      isLoadingDelivery: false,
      navbar: false,
    };

    this.cookies = new Cookies();
    this._timeoutHandler = 0;
    this._inputString = "";
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.detectScanner = this.detectScanner.bind(this);
    this.onChangeScanner = this.onChangeScanner.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.showCollapseMenu = this.showCollapseMenu.bind(this);
    // this.openInsedeModal = this.openInsedeModal.bind(this);
  }

  toggleMenu() {
    this.setState({
      navbar: !this.state.navbar,
    });
  }

  closeMenu() {
    this.setState({
      navbar: false,
    });
  }

  onChangeScanner(evt) {
    if (evt.data && typeof evt.data === "string") {
      let res = evt.data.split(":");
      if (res[0] === "profit-security") {
        this._inputString = res[1];
        this.detectScanner();
      } else if (res[0] === "subscription") {
        // UserActions.paymentSuccess(res[1])
        // alert( "получено: " + evt.data );
      } else if (res[0] === "app-message") {
        AppStore.setIsMobileApp(true);
      }
    }
    // alert( "получено: " + evt.data );
  }

  detectScanner() {
    if (this._inputString.length <= 3 || isNaN(this._inputString)) {
      this._inputString = "";
      return;
    }

    this._inputString = this._inputString.replace(/\r?\n/g, "");

    if (!AppStore.get("openModal") && !SalesStore.get("openModal")) {
      console.log("start procceess barcode ", this._inputString);
      let path = window.location.pathname;
      if (
        path.indexOf("shipments") > -1 ||
        path.indexOf("inventory") > -1 ||
        path.indexOf("moving") > -1 ||
        path.indexOf("write-off") > -1 ||
        path.indexOf("productions") > -1
      ) {
        ProductsActions.setProductFromScanner(this._inputString);
      } else if (window.location.pathname === "/products") {
        console.log("open product");
        const findProduct = ProductsStore.findProductByBarcode(
          this._inputString
        );
        if (findProduct) {
          AppStore.openModal(<ViewProductModal pid={findProduct.id} />);
        } else {
          AppStore.openModal(<AddProductsModal barcode={this._inputString} />);
        }
      } else {
        console.log("open sale... %s", this._inputString);

        const product = ProductsStore.findProductByBarcode(this._inputString);
        if(product) {
          if(Object.keys(product.stock).length > 1) {

            SalesActions.openSaleModal()
            ProductsActions.setProductFromScanner(this._inputString)
          } else {
            const mid = Object.keys(product.stock)[0]
            const select_product = {"select_product": {'value': ProductsStore.getIdent(product.id, mid)}}
            SalesActions.openSaleModal(select_product);
          }
        } else {
          let select_product = ProductsStore.getProductFromScanner(
            this._inputString
          );
          SalesActions.openSaleModal(select_product);
        }
      }
    } else {
      if (SalesStore.get("openModal")) {
        ProductsActions.setProductFromScanner(this._inputString);
      } else {
        if (
          document.activeElement &&
          document.activeElement.tagName === "INPUT"
        ) {
          document.activeElement.value = this._inputString;
        } else {
          ProductsActions.setProductFromScanner(this._inputString);
          console.log(
            "try open scanbarcode, but modal already opeen ",
            this._inputString
          );
        }
      }
    }

    this._inputString = "";
  }


  _handleKeyDown(event) {
    if (!event) return;


    if (event.ctrlKey && event.keyCode === ENTER_KEY) {
      SalesActions.openSaleModal();
      return;
    } else if (event.code && event.code.indexOf("Digit") > -1) {
      if (event.target.nodeName === "INPUT") {

      } else {
        if (this._timeoutHandler) {
          clearTimeout(this._timeoutHandler);
        }

        this._inputString += String.fromCharCode(event.which);
        this._timeoutHandler = setTimeout(this.detectScanner, 30);
      }
    }

    // switch(event.keyCode) {
    //     case ENTER_KEY:
    //         redirectTo("/sales/add-sale")
    //         break;
    //     default:
    //         break;
    // }
  }

  checkSound() {
    if (ChatsStore.get("new_message")) {
      let playPromise = ChatsStore.sound.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () {
            console.log("Automatic playback started!");
          })
          .catch(function (error) {
            console.log("Automatic playback failed.", error);
            // Show a UI element to let the user manually start playback.
          });
      }

      ChatsStore.soundPlayFinish();
    }
  }

  componentWillUnmount() {
    if (typeof this.listenLogout == "function") {
      this.listenLogout();
    }

    if (typeof this.listenPayment == "function") {
      this.listenPayment();
    }

    if (typeof this.listenLoadAccounts == "function") {
      this.listenLoadAccounts();
    }

    if (typeof this.listenLoadProducts == "function") {
      this.listenLoadProducts();
    }

    if (typeof this.listenLoadSales == "function") {
      this.listenLoadSales();
    }

    if (typeof this.listenLoadClientsDelivery == "function") {
      this.listenLoadClientsDelivery();
    }
    if (typeof this.listenLoadWarehouse == "function") {
      this.listenLoadWarehouse();
    }
    if (typeof this.loadUser == "function") {
      this.loadUser();
    }

    // clearInterval(this.intervalIDLoadUser)

    this.setState({
      needRedirect: false,
    });

    if (window.removeEventListener) {
      window.removeEventListener("message", this.onChangeScanner);
    } else {
      window.detachEvent("onmessage", this.onChangeScanner);
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);
    document.addEventListener("click", this.checkSound);
    // console.log("Action: ", action);
    // console.log("RESPONSE", Object.getOwnPropertyNames(ExpensesActions.loadAccounts));
    console.log("START LOAD, ", UserStore.isGuest());
    WarehouseActions.load(true);
    ClientsActions.loadDelivery();
    // ProductsActions.load();
    ProductsActions.loadAttr();
    ExpensesActions.loadCategory();
    ExpensesActions.loadAccounts(true);
    NotificationActions.load();
    UserActions.getCurrentStateTarif();
    setTimeout(ClientsActions.loadDiscounts, 100);
    SalesActions.load();
    OrdersActions.loadStatus();
    NotificationActions.loadMessages();
    OrdersActions.load();
    ClientsActions.load()

    //       const loaders = [
    //       	ExpensesActions.loadAccounts.completed.listen,
    //       	WarehouseActions.load.completed.listen,
    //       	ProductsActions.load.completed.listen,
    //       	NotificationActions.load.completed.listen,
    //       	SalesActions.loadReports.completed.listen
    //       ]

    //       Promise.all(loaders).then((values) => {
    //   this.setState({
    //   	isLoading: false
    //   });
    //   console.log("ALL LOAD COMPLETE!!!")
    // });


    this.loadUser = UserActions.loadProfile.completed.listen((res) => {
      if (AppStore.isMobileApp()) {
        const showBarcode = UserStore.getMoreSetting("scanBarcode");
        if (!showBarcode && showBarcode !== undefined) {
          UserStore.postMessage("hide-barcode");
        }
      }

      if(!UserStore.getMoreSetting('sm') && AppStore.getAppName() === 'HugeProfit') {
        AppStore.openModal(<WelcomeMessage/>)
      }


      if(UserStore.isIntegrationChats() && UserStore.getModuleEnable('chats')) {
        ChatsActions.load()
        ChatsActions.loadBindingClients()
      }

      VotingActions.load()


    });

    this.listenLoadProducts = ProductsActions.load.completed.listen((res) => {
      this.setState({
        isLoadingProducts: true,
      });

      this.initFinish();
    });

    this.listenPayment = UserActions.paymentSuccess.completed.listen((res) => {
      console.log("SUCCESS PAYMENT, ", res);
      this.setState({
        go_main: true,
      });
    });

    this.listenLoadClientsDelivery =
      ClientsActions.loadDelivery.completed.listen((res) => {
        this.setState({
          isLoadingDelivery: true,
        });

        this.initFinish();
      });

    this.listenLoadAccounts = ExpensesActions.loadAccounts.completed.listen(
      (res) => {
        this.setState({
          isLoadingAccounts: true,
        });

        this.initFinish();
      }
    );

    this.listenLoadWarehouse = WarehouseActions.load.completed.listen((res) => {
      ProductsActions.load();
      this.setState({
        isLoadingWarehouse: true,
      });
    });

    this.listenLogout = UserActions.logout.completed.listen((res) => {
      console.log("LOGOUT SUCCESS LISTEN");
      this.setState({
        needRedirect: true,
      });
    });

    if (window.addEventListener) {
      window.addEventListener("message", this.onChangeScanner);
    } else {
      window.attachEvent("onmessage", this.onChangeScanner);
    }

    setTimeout(ChatsStore.initSocket, 3000);

    // this.intervalIDLoadUser = setInterval(() => UserActions.loadProfile(true), 5*60*1000);

    setTimeout(() => {
      if(!!UserStore.getBinotelList().length && !isMobile) {
        ChatsStore.initSocketBinotel();
      }

      if(!!UserStore.getRingostatList().length && !isMobile) {
        ChatsStore.initSocketRingostat();
      }
    }, 3000)

  }

  initFinish() {
    if (
      this.state.isLoadingProducts &&
      this.state.isLoadingAccounts &&
      this.state.isLoadingWarehouse &&
      this.state.isLoadingDelivery
    ) {
      this.setState({
        isLoading: false,
      });
      console.log("ALL LOAD COMPLETE!!!");
      AppStore.checkVersion(true);
      UserActions.cacheSync(true)
    }
  }

  refreshPage() {
    window.location.href = AppStore.isMobileApp()
      ? AppStore.getLink("login")
      : AppStore.getLink("/");
  }

  changeCollapse() {
    let val = !this.state.collapse;
    this.cookies.set("collapse-menu", val ? 1 : 0, { path: "/" });
    this.setState({
      collapse: val,
    });
  }

  showCollapseMenu() {
    if (this.cookies.get("collapse-menu") === "0") {
      return false;
    }

    if (this.cookies.get("collapse-menu") === "1") {
      return true;
    }

    if (window.innerWidth <= 1230) {
      return true;
    }

    return false;
  }

  renderDefault() {
    const isGuest = UserStore.isGuest(),
      { openModal } = this.props["sales"];

    let sidebarClass =
      "is-2 is-narrow-mobile is-fullheight is-hidden-mobile account-left-menu";

    if (this.showCollapseMenu()) {
      sidebarClass += " collapse";
    }

    let isMainClass = ["main"];
    const isMobileSize = this.props.isMobileSize
    if (isMobileSize) {
      isMainClass.push("is-mobile");
    }
    if (UserStore.isOnBoarding()) {
      isMainClass.push("onboarding");
    }
    return (
      <>
        <Columns multiline={false} className={isMainClass.join(" ")}>
          {isMobileSize ? null : (
            <Columns.Column
              className={sidebarClass}
              id="account-sidebar-column"
            >
              <WrapperSidebar
                changeCollapse={() => this.changeCollapse()}
                showCollapseMenu={() => this.showCollapseMenu()}
              />
            </Columns.Column>
          )}
          <Columns.Column
            id="right-side"
            className={isMobileSize ? "mobile" : ""}
          >
            {isMobileSize ? (
              <MobileTopBar
                navbar={this.state.navbar}
                toggleMenu={this.toggleMenu}
              />
            ) : (
              <AccountTopBar />
            )}
            <Section>
              <NotificationBlock />
              <Switch>
                {routes_component.map((route, index) => {
                  if (route.auth && isGuest) {
                    // return redirectTo("/");
                    return null
                  } else {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        exact
                        component={
                          !UserStore.getRouteEnable(route.path)
                            ? AccessDenied
                            : route.component
                        }
                      />
                    );
                  }
                })}
                {routes.map((route, index) => {
                  if (route.auth && isGuest) {
                    // return redirectTo("/");
                    return null
                  } else {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        children={
                          !UserStore.getRouteEnable(route.path) ? (
                            <AccessDenied />
                          ) : (
                            <route.main />
                          )
                        }
                      />
                    );
                  }
                })}
              </Switch>
              {modals.map((route, index) => {
                if (route.auth && isGuest) {
                  // return redirectTo("/");
                  return null
                } else {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      component={
                        !UserStore.getRouteEnable(route.path)
                          ? AccessDenied
                          : route.main
                      }
                    />
                  );
                }
              })}
            </Section>
            {isMobileSize ? (
              <MobileBottomBar closeMenu={this.closeMenu} />
            ) : null}
          </Columns.Column>
          {this.state.go_main ? redirectTo("/") : ""}

          {openModal
            ? OpenModalFactory(
                <AddSaleModal
                  modal="sale-modal"
                  barcode={SalesStore.get("scannedBarCode")}
                  {...SalesStore.get("dataModal")}
                />,
                SalesStore.closeModal
              )
            : ""}

          {ProductsStore.get("openModal") === "info"
            ? OpenModalFactory(
                <InfoProductModal product={ProductsStore.get("modalInfo")} />,
                ProductsStore.closeModal
              )
            : ""}

          {AppStore.get("openInsideModal") ? this.openInsideModal() : null}
        </Columns>
        <iframe src="" title="iframeID" id="iframeID" />
        <SVGSource />
        <ClientPopupList/>
        <ScreenNotification/>
      </>
    );
  }

  openInsideModal() {
    let response = [];

    const childs = AppStore.get("childModal");

    for (let name in childs) {
      response.push(
        OpenModalFactory(childs[name], () => AppStore.closeModal(name))
      );
    }

    return response;
  }

  render() {
    return this.state.isLoading ? (
      <Section>
        <div className="is-vcentered my-vcenter">
          <Loader type="Rings" color="#00BFFF" height={80} width={80} />
        </div>
      </Section>
    ) : (
      this.renderDefault()
    );
  }
}

export default connectToStores(withIsMobileSize(AccountLayout), {
  sales: SalesStore,
  app: AppStore,
  user: UserStore,
  products: ProductsStore,
  VotingStore
});
