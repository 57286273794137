import React, {useState} from 'react'
import IntegrationControlHook from "../common/integration-control.hook";
import IntegrationListenerHook from "../common/integration-listener.hook";
import {handleDeleteIntegration, isValidKeys, parseObj} from "../utils";
import UserActions from "../../../../../user/user-actions";
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";
import InputsGroupField from "../common/inputs-group-field";
import ActionsModal from "../common/actions-modal";
import {confirmDelete} from "../../../../../tools/confirm-delete";
import ModalItsOK from "../../../../../components/modal-its-ok";

const DATA_INPUT = [
	{
		name: "api_key",
		placeholder: "token"
	},
	{name: "name", placeholder: "name"},
	{name: "avatar", placeholder: "link-to-avatar-placeholder"},
	{name: "welcome", placeholder: 'welcome-message-placeholder'},
]

const Viber = ({data, wooID, onClose}) => {
	const {api_key, id, is_active, name, ident} = data
	let identParse = typeof ident === 'string' ? parseObj(ident) : ident

	const {
		handleAutoRemoveError,
		setErrorMessage,
		errorMessage,
		inputValues,
		handleChangeInputValues,
	} = IntegrationControlHook({
		inputDefaultValue: {
			api_key: api_key || null,
			name: name || '',
			welcome: identParse?.welcome || '',
			avatar: identParse?.avatar || ''
		},
	})

	const [waiting, setWaiting] = useState(false)
	const {submitOK, additionalMessage} = IntegrationListenerHook({handleError: handleAutoRemoveError, setWaiting})

	const handleError = () => {
		let errorMessage;

		if(!isValidKeys(inputValues, ['api_key'], 50)){
			errorMessage = 'wrong-key'
		}

		if(errorMessage) {
			handleAutoRemoveError(errorMessage)
			return false
		}

		return  true
	}

	const handleSubmit = ({deactivate}) => {
		const data = {
			"integration_id": wooID,
			"api_key": inputValues.api_key,
			"name": inputValues?.name,
			"ident": JSON.stringify({
				welcome: inputValues?.welcome,
				avatar: inputValues?.avatar
			}),
			is_active: 1
		}

		if(id) data.id = id
		if(deactivate) data.is_active = 0

		if(handleError()) {
			setWaiting(true)
			UserActions.updateIntegrations(data)
		}
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!submitOK && <Box>
				<Heading size={3}>{T('setting-integration', [T(`integration-${wooID}`)])}</Heading>
				<InputsGroupField inputValues={inputValues} dataInput={DATA_INPUT}  onChange={handleChangeInputValues}/>
				<ActionsModal
					onSubmit={handleSubmit}
					onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
					onClose={onClose}
					isEdit={!!id}
					isActive={is_active}
					waiting={waiting}/>
			</Box>}
			{submitOK && <ModalItsOK onClose={onClose} title={submitOK} additionalMessage={additionalMessage}/>}
		</>
	);
};

export default Viber;