import React, { useEffect, useState } from "react";
import { Box } from "react-bulma-components";
import ChartsControl from "./charts-control";
import User from "../../../../user/user-store";
import { Bar, Line } from "react-chartjs-2";
import T from "../../../../components/i18n";
import { getRandomColor } from "../../../../tools/get-random-color/get-random-color";
import ClientsStore from "../../../clients/clients-store";
import WarehouseStore from "../../../warehouse/warehouse-store";
import SalesStore from "../../../sales/sales-store";
import {connectToStores} from "../../../../tools/reflux-tools";
import ExpensesStore from "../../../expenses/expenses-store";

const ChartsWithDisplay = ({ data, options, display }) => {
  if (display === "bar") {
    return <Bar data={data} options={options} />;
  } else if (display === "combain") {
    return <Bar data={data} options={options} />;
  } else if (display === "line") {
    return <Line data={data} options={options} />;
  }
};

const generateDefaultChart = (data, daysGroup, filter, display) => {
  const dataForChart = Object.entries(data).reduce(
    (acc, [key, value]) => {
      let labelDate = key;
      if (daysGroup === "days") {
        labelDate = new Date(parseInt(key)).toLocaleDateString();
      }

      acc.datasets.expenses.data.unshift(
        (value.amountExpenses || 0) +
          (value.shipmentExpenses || 0) +
          (value.expensesProfit || 0)
      );
      acc.datasets.amount.data.unshift(value?.amount_sale || 0);
      acc.datasets.profit.data.unshift(
        (value?.profit || 0) - (value?.expensesProfit || 0)
      );
      acc.datasets.netProfit.data.unshift(
        (value?.profit || 0) + (value?.receipt || 0)
      );

      acc.labels.unshift(labelDate);

      if (filter && filter.clients) {
        filter.clients.forEach((item) => {
          if (!acc.datasets[item.value]) {
            const client = ClientsStore.getClientById(item.value);
            acc.datasets[item.value] = {
              label: `${T('t-sales-main')}: ${T('client')} ${client.name}`,
              type: display === 'combain' ? "line" : display,
              backgroundColor: item.color,
              borderColor: item.color,
              fill: false,
              data: [],
            };
          }
          if(value.clients && value.clients[item.value]) {
            acc.datasets[item.value].data.unshift(
              value?.clients[item.value]?.amount_sale || 0
            );
          } else {
            acc.datasets[item.value].data.unshift(0);
          }
        });
      }
      if (filter && filter.mid) {
        filter.mid.forEach((item) => {
          if (!acc.datasets[item.value]) {
            const midName = WarehouseStore.getWarehouseName(item.value);
            acc.datasets[item.value] = {
              label: `${T('t-sales-main')}: ${midName}`,
              type: display === 'combain' ? "line" : display,
              backgroundColor: item.color,
              borderColor: item.color,
              fill: false,
              data: [],
            };
          }
          if(value.mid && value.mid[item.value]) {
            acc.datasets[item.value].data.unshift(
              value?.mid[item.value]?.amount_sale || 0
            );
          } else {
            acc.datasets[item.value].data.unshift(0);
          }
        });
      }
      if (filter && filter.channels) {
        filter.channels.forEach((item) => {
          if (!acc.datasets[item.value]) {
            const channelName = SalesStore.getChannelsName(item.value);
            acc.datasets[item.value] = {
              label: `${T('t-sales-main')}: ${T(channelName)}`,
              type: display === 'combain' ? "line" : display,
              backgroundColor: item.color,
              borderColor: item.color,
              fill: false,
              data: [],
            };
          }
          if(value.channels && value.channels[item.value]) {
            acc.datasets[item.value].data.unshift(
              value?.channels[item.value]?.amount_sale || 0
            );
          } else {
            acc.datasets[item.value].data.unshift(0);
          }
        });
      }

      return acc;
    },
    {
      datasets: {
        expenses: {
          label: T("Expenses"),
          type: display === 'combain' ? "bar" : display,
          backgroundColor: "#ff1313",
          borderColor: "#ff1313",
          fill: false,
          data: [],
        },
        amount: {
          label: T("sales-amounts"),
          type: display === 'combain' ? "bar" : display,
          backgroundColor: "#4e82bd",
          borderColor: "#4e82bd",
          fill: false,
          data: [],
        },
        netProfit: {
          label: T("net-profit"),
          type: display === 'combain' ? "bar" : display,
          backgroundColor: "#ffd700",
          borderColor: "#ffd700",
          fill: false,
          data: [],
        },
        profit: {
          label: T("dashboard-sales-profit"),
          type: display === 'combain' ? "bar" : display,
          backgroundColor: "#01aa4d",
          borderColor: "#01aa4d",
          fill: false,
          data: [],
        },
      },
      labels: [],
    }
  );
  const result = {};

  result.labels = dataForChart.labels;
  result.datasets = Object.values(dataForChart.datasets);

  return result;
};

const Charts = ({ daysGroup, onChangeDaysGroup, data }) => {
  const [displayName, setDisplayName] = useState("combain");
  const [options] = useState({
    scales: {
      y: {
        ticks: {
          callback: function (value, index, values) {
            return value + " " + User.getCurrency();
          },
        },
      },
    },
    plugins: {
      title: {
        display: false,
        text: "Custom Chart Title",
      },
    },
  });

  const [additionalLines, setAdditionalLines] = useState({
    clients: null,
    mid: null,
    channels: null,
  });
  const [chartInfo, setChartInfo] = useState({ labels: [], datasets: [] });


  useEffect(() => {
    setChartInfo(generateDefaultChart(data, daysGroup, additionalLines, displayName));
  }, [data, displayName, additionalLines, daysGroup]);

  const handleChangeAdditionalLines = ({name, value}) => {
    setAdditionalLines(prev => {
      let values = value
      if(prev[name]) {
        value.map(item => {
          if(!item.color) {
            item.color = getRandomColor()
          }

          return item
        })
      } else {
        value.map(item => item.color = getRandomColor())
      }


      return {
        ...prev,
        [name]: values,
      }
    })
  }

  return (
    !!chartInfo.datasets.length && (
      <Box>
        <ChartsControl
          display={displayName}
          onChangeDisplay={setDisplayName}
          daysGroup={daysGroup}
          onChangeDaysGroup={onChangeDaysGroup}
          valueSelect={additionalLines}
          onChangeSelect={handleChangeAdditionalLines}
        />
        <ChartsWithDisplay
          display={displayName}
          options={options}
          data={chartInfo}
        />
      </Box>
    )
  );
};

export default connectToStores(Charts, {expenses: ExpensesStore,sales: SalesStore});
