import React, { Component } from "react";
// import { connectToStores } from "tools/reflux-tools";

import { Field, Control, Input, Label } from 'react-bulma-components/lib/components/form';
import Messages from "components/messages";
import UserAction from 'user/user-actions';

// import InfoBslock from 'components/info-block';
import T from "components/i18n";
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Button from 'react-bulma-components/lib/components/button';
import User from 'user/user-store';
import AppStore from "../../app-store";


class ChangePassword extends Component {
     constructor(props) {
        super(props);
        this.state = {
            password: "",
            new_password: "",
            confirm_password: "",
            message: "",
            color: "danger"
        };

        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {

        UserAction.changePasswd.completed.listen((data) => {
            if (data.success) {
                this.setState({
                  "message": T('password-success-change'),
                  'color': 'success'
                });
            } else {
                this.setState({
                  "message": T(data.error.code || 'unhandled_error')
                });           
            }
        });
    }

    submit() {
        if (!this.state.password.value || this.state.password.value.length < 3) {
            this.setState({
              "message": T('enter-passwrod-error'),
            });
            return false;
        }        

        if (!this.state.new_password.value || this.state.new_password.value.length < 3) {
            this.setState({
              "message": T('enter-passwrod-error'),
            });
            return false;
        }        

        // if (this.state.new_password.value !== this.state.confirm_password.value) {
        //     this.setState({
        //       "message": T('password-confirm-equal-error'),
        //     });
        //     return false;
        // }


        UserAction.changePasswd(this.state.password.value, 
          this.state.new_password.value, 
          this.state.confirm_password.value);
    }

    onChange(evt) {
          const value = evt.target.value,
                name = evt.target.name;

          this.setState({
            [name]: {
              'value': value
            }
          });
    }

    render () {
        return (
          <Box>
            <Messages message={this.state.message}
                      color={this.state.color || 'danger'}
                      close={() => this.setState({message: null})}/>
            <div align="left">
              <b>ID</b>: {User.get("id")}</div>
            <div align="left" className="margin-bottom-10">
              <b>{T('your-email')}</b>: {User.get("email")}</div>
            {AppStore.getWlName() === 'hugeprofit' && <>
              <Heading>{T('change-password')}</Heading>
              <Field>
                <Control>
                  <Label>{T('old-password-placeholder')}</Label>
                  <Input size="large"
                         name="password" type="password"
                         placeholder={T('old-password-placeholder')}
                         onChange={this.onChange}
                         color={(this.state.password.error) ? "danger" : "primary"}
                         value={this.state.password.value}
                  />
                </Control>
              </Field>
              <Field>
                <Control>
                  <Label>{T('new-password-placeholder')}</Label>
                  <Input size="large"
                         name="new_password" type="password"
                         placeholder={T('new-password-placeholder')}
                         onChange={this.onChange}
                         color={(this.state.new_password.error) ? "danger" : "primary"}
                         value={this.state.new_password.value}
                  />
                </Control>
              </Field>
              <Field>
                <Control>
                  <Label>{T('confirm-password')}</Label>
                  <Input size="large"
                         name="confirm_password" type="password"
                         placeholder={T('placeholder-confirm-password')}
                         onChange={this.onChange}
                         color={(this.state.confirm_password.error) ? "danger" : "primary"}
                         value={this.state.confirm_password.value}
                  />
                </Control>
              </Field>

              <Button.Group position="right">
                <Button size="large" rounded color="danger"
                        onClick={this.submit}
                >{T('change-password')}</Button>
              </Button.Group>
            </>}
          </Box>
        )
    }
}


export default ChangePassword;
