import React from "react";
import T from "components/i18n";
import Money from "components/money";
import Columns from 'react-bulma-components/lib/components/columns';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import UserStore from 'user/user-store';
import ProductsStore from "./products-store";
import WarehouseStore from "../warehouse/warehouse-store";


const ProductsTotal = ({ statistics }) => {
  if (!UserStore.getPermissionEnable("show-cashflow")) return null;

  const instockFilter = ProductsStore.getFilter('instock')
  const midFilter = ProductsStore.getFilter('mid')

  const isDropMid = WarehouseStore.isDropshipping(midFilter.value)

  return (
      <Columns className="statisctic">
        {instockFilter !== '1' && <Columns.Column>
          <Box>
            <Heading heading>
              {T('total-products')}:
            </Heading>
            <Heading>
              {instockFilter === '2' ? statistics.count_not_available : statistics.count}
            </Heading>
          </Box>
        </Columns.Column>}
        {instockFilter !== '2' && <Columns.Column>
          <Box>
            <Heading heading>
              {T('In-stock')}:
            </Heading>
            <Heading>
              {isDropMid ? "∞" : T('items-count', {'count': statistics.available})}
            </Heading>
          </Box>
        </Columns.Column>}
        {instockFilter !== '0' && <Columns.Column>
          <Box>
            <Heading heading>
              {T('history-data-reserv')}:
            </Heading>
            <Heading>
              {T('units-count', {'count': statistics.reserv})}
            </Heading>
          </Box>
        </Columns.Column>}
        {instockFilter !== '2' && <Columns.Column>
          <Box>
            <Heading heading>
              {T('available')}:
            </Heading>
            <Heading>
              {isDropMid ? "∞" : T('units-count', {'count': statistics['quantity'].toFixed(2)})}
            </Heading>
          </Box>
        </Columns.Column>}
        {!UserStore.getPermissionEnable("incomeinformation") ? null :
          <><Columns.Column>
            <Box>
              <Heading heading>
                {T('stored-for')}: 
              </Heading>
              <Heading>
                {isDropMid ? "∞" : <Money amount={statistics['total_cost']} />}
              </Heading>
            </Box>
          </Columns.Column>
            <Columns.Column>
              <Box>
              <Heading heading>
                {T('possible-profit')}: 
              </Heading>
              <Heading>
                {isDropMid ? "∞" : <Money amount={statistics['total_profit']}/>}
              </Heading>
            </Box>
          </Columns.Column></>}
        </Columns>
    )
}

export default ProductsTotal;