import {useEffect, useState} from "react";
import WarehouseStore from "../../warehouse/warehouse-store";
import T from "../../../components/i18n";
import ProductsStore from "../../products/products-store";
import roundSignificant from 'tools/round-significant';

export const useProductsWriteOffData = (data) => {
	const [errorMessage, setErrorMessage] = useState(null)


	const [stateMid, setStateMid] = useState(0)

	const [selectedProductsIDs, setSelectedProductsIDs] = useState([])

	const [productsData, setProductsData] = useState([])

	const [allQuantity, setAllQuantity] = useState(0)

	const [amount, setAmount] = useState(0)

	const [comment, setComment] = useState(null)
	const [writeOffCategory, setWriteOffCategory] = useState({value: 0})

	useEffect(() => {
		if(data) {
			data.items.forEach(item => {
				handleAddProduct(
					[{...item, value:`${item.product_id}_${data.from_marketplace_id}`, quantity_write_off: item.quantity_shipped}], true)
			})
		}

		const defaultMid = data ? {'value': data?.from_marketplace_id, label: WarehouseStore.getWarehouseName(data?.from_marketplace_id)} : WarehouseStore.getDefaultWarehouse()
		setStateMid(defaultMid)

		if(data?.comment) {
			setComment(data.comment)
		}

		if(data?.category_id) {
			setWriteOffCategory({value: data.category_id})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	const handleChangeMid = (value) => {
			setStateMid(value)
	}

	const generateErrorMessage = (text) => {
		setTimeout(() => setErrorMessage(null), 4000)

		setErrorMessage(text)
	}

	const handleAddProductInList = (id) => {
		setSelectedProductsIDs(prevState => [...prevState, id])
	}

	const handleRemoveProductInList = (id) => {
		setSelectedProductsIDs(prevState => prevState.filter(itemID => itemID !== id))
	}

	const handleAddProductData = ({id, mid, price, quantity_write_off, quantity_was, quantity_became}) => {
		const product = {
			id,
			mid,
			price,
			quantity_write_off,
			quantity_was, quantity_became
		}
		setProductsData(prevState => [...prevState, product])
	}

	const handleRemoveProductData = ({id}) => {
		setProductsData(prevState => prevState.filter(item => item.id !== id))
		handleRemoveProductInList(id)
	}

	const handleChangeQuantityProduct = ({pid,mid, quantity_write_off}) => {

		const product = ProductsStore.getProductByMid(pid, mid)

		if(product.stock[mid]?.quantity < quantity_write_off) {
				generateErrorMessage(T('fewer-products-available'))
		}

		setProductsData(prevState => {
			return prevState.map(item => {
				if (item.id === pid) {
					const updatedQuantity = Math.min(product.stock[mid].quantity, quantity_write_off);
					return {
						...item,
						quantity_write_off: updatedQuantity
					};
				}
				return item;
			});
		});
	}


	const handleAddProduct = (value, isStock) => {
		let productInfo
		if(value.select_product) {
			const info = value.select_product.value.split('_')
			productInfo = {
				id: info[0],
				mid: info[1]
			}
		} else {
			productInfo = value.map(item => {
				const info = item.value.split('_')
				return {
					id: info[0],
					mid: info[1]
				}
			})[0]
		}

		const product = ProductsStore.getProductByMid(productInfo.id, productInfo.mid)
		if(!product)  {
			handleAddProductInList(productInfo.id)
			handleAddProductData(productInfo)
			return
		}
		productInfo.price = product.netprice
		productInfo.quantity_write_off = value[0]?.quantity_write_off || 1

		if(value[0]?.quantity_was) {
			productInfo.quantity_was = value[0]?.quantity_was
 		}

		if(value[0]?.quantity_became) {
			productInfo.quantity_became = value[0]?.quantity_became
		}


		if(product.stock[productInfo.mid]?.quantity > 0 || isStock) {
			const selectedProduct = productsData.find(item =>  item.id === productInfo.id)
			if(selectedProduct) {
				handleChangeQuantityProduct({pid: productInfo.id, mid: productInfo.mid, quantity_write_off: selectedProduct.quantity_write_off + 1})
			} else {
				handleAddProductInList(productInfo.id)
				handleAddProductData(productInfo)
			}
		} else {
			generateErrorMessage(T('not-have-stock'))
		}
	}

	useEffect(() => {
		const countData = productsData.reduce((acc, curr) => {
			let count = (acc?.count || 0) + Number(curr.quantity_write_off)
			let amount = (acc?.amount || 0) + curr.quantity_write_off * curr.price

			amount = Math.round(amount * 100) / 100;
      		count = roundSignificant(count, 3);

			return {
				amount,
				count
			}

		}, {})

		setAllQuantity(countData.count)
		setAmount(countData.amount)
	}, [productsData]);

	return {
			comment,
			setComment,
			writeOffCategory,
			setWriteOffCategory,
			allQuantity,
		  amount,
			errorMessage,
			setErrorMessage,
			generateErrorMessage,
			stateMid,
			handleChangeMid,
			selectedProductsIDs,
			productsData,
			handleAddProductInList,
			handleRemoveProductInList,
			handleAddProductData,
			handleRemoveProductData,
			handleChangeQuantityProduct,
			handleAddProduct
	}
}