/**
 * Options able to pass to the {@see StorageObject#createPropery}
 *
 * @typedef {Object} PropertyOptions
 *
 * @property {string} key
 *      Storage key to access stored property value. If omitted property name will be used.
 *      If {@see PropertyOptions#omitPrefix} is passed then the storage key is a raw key value and
 *      concatenation of the STORE_PREFIX and the specified key otherwise.
 *
 * @property {boolean} [omitPrefix=false]
 *      Specifies whether should use prefix to access stored value or not.
 *      By deafult all stored keys have prefix to avoid collisions.
 *
 * @property {string} [type]
 *      Type of stored value. String by default.
 *
 * @property {function(key, type, defaultValue, ...)} [read]
 *      Custom reader.
 *
 * @property {function(key, type, value, ...)} [write]
 *      Custom writer.
 */

let STORE_PREFIX = "";

function isDefined(value) {
    return value !== null && value !== undefined;
}

function parse(value, type) {
    if (isDefined(value)) {
        switch (type) {
            case "int":
            case "integer":
                return parseInt(value, 10);
            case "float":
                return parseFloat(value);
            case "bool":
            case "boolean":
                return value === "true";
            case "json":
                try {
                    return value !== "" ? JSON.parse(value) : null;
                } catch (e) {
                    return null;
                }
            default:
                return value;
        }
    }

    return value;
}

function stringify(value, type) {
    let stringValue;

    switch (type) {
    case "int":
    case "integer":
        stringValue = (+value || 0).toFixed(0);
        break;
    case "float":
        stringValue = +value || 0;
        break;
    case "bool":
    case "boolean":
        stringValue = !!value;
        break;
    case "json":
        return JSON.stringify(value);
    default:
        stringValue = value;
        break;
    }

    return String(stringValue);
}

/** @class */
function MockStorage() {
    this.clear();
}

Object.assign(MockStorage.prototype, /** @lends MockStorage.prototype */ {
    clear() {
        this._items = {};
    },

    getItem(key) {
        return this._items[key];
    },

    setItem(key, val) {
        this._items[key] = val;
    },

    removeItem(key) {
        delete this._items[key];
    }
});


/** @class */
function StorageObject(storage) {
    if (storage) {
        try {
            storage.setItem("test", "test");
        } catch (e) {
            storage = null;
        }
    }

    this._storage = storage || new MockStorage();
}

Object.assign(StorageObject.prototype, /** @lends StorageObject.prototype */ {
    _reset() {
        this._storage.clear();
    },

    _getKey(key, omitPrefix) {
        return omitPrefix ? key : STORE_PREFIX + key;
    },

    readValue(key, type, defaultValue) {
        let value = parse(this._storage.getItem(key), type);

        if (!isDefined(value)) {
            value = typeof defaultValue === "function" ? defaultValue(key, type) : defaultValue;
        }

        return value;
    },

    writeValue(key, type, value) {
        this._storage.setItem(key, stringify(value, type));

        return value;
    },

    removeValue(key) {
        this._storage.removeItem(key);
    },

    /**
     * Returns stored value.
     *
     * @param {string} key Key without prefix
     * @param {string} [type]
     * @param {*} [defaultValue]
     * @return {*}
     */
    getItem(key, type, defaultValue) {
        return this.readValue(this._getKey(key), type, defaultValue);
    },

    /**
     * Stores value.
     *
     * @param {string} key
     * @param {*} value
     * @param {string} [type]
     * @return {*}
     */
    setItem(key, value, type) {
        return this.writeValue(this._getKey(key), type, value);
    },

    removeItem(key) {
        return this.removeValue(this._getKey(key));
    },

    getData(key, defaultValue) {
        return this.getItem(key, "json", defaultValue);
    },

    setData(key, value) {
        return this.setItem(key, value, "json");
    },

    _getGetter(opts) {
        return (...rest) => {
            const getter = opts.read || this.readValue;
            const key = this._getKey(opts.key, opts.omitPrefix);

            return getter.call(this, key, opts.type, opts.defaultValue, ...rest);
        };
    },

    _getSetter(opts) {
        return (value, ...rest) => {
            const setter = opts.write || (isDefined(value) ? this.writeValue : this.removeValue);
            const key = this._getKey(opts.key, opts.omitPrefix);

            return setter.call(this, key, opts.type, value, ...rest);
        };
    },

    /**
     * Creates accessors which provide ability to operate stored value.
     *
     * @param {string} name Property name
     * @param {PropertyOptions} opts Options
     */
    createProperty(name, opts) {
        const nameCap = name.charAt(0).toUpperCase() + name.substr(1);

        opts = Object.assign({ key: name }, opts);

        this["get" + nameCap] = this._getGetter(opts);
        this["set" + nameCap] = this._getSetter(opts);
    }
});


export const localStorage = new StorageObject(window.localStorage);
export const sessionStorage = new StorageObject(window.sessionStorage);
export const memoryStorage = new StorageObject();
export const setPrefix = (prefix) => {
    STORE_PREFIX = prefix;

    return prefix;
};
export const getPrefix = () => STORE_PREFIX;
