import React from 'react'
import Button from "react-bulma-components/lib/components/button";
import T from '../../../../../components/i18n'
import SubmitButton from "../../../../../components/submit-button";

const ActionsModal = ({isEdit, waiting, isActive, onDelete, onSubmit, disabledSubmit, onClose}) => {
	let buttonSubmitText

	if(isEdit && !!isActive) {
		buttonSubmitText = 'btn-edit'
	} else if(isEdit && isActive === 0) {
		buttonSubmitText = 'btn-active'
	} else {
		buttonSubmitText = 'btn-save'
	}


	return (
		<div className='display-flex-row-gap margin-top-20'>
			{isEdit ?
				<Button.Group position='right' className='add-group-style'>
					<SubmitButton
						color="danger"
						submit={onDelete}
						waiting={waiting}
						fullwidth={false}
						size='small'
						text='delete'
					/>
					{isActive !== 0 && <SubmitButton
						color="warning"
						submit={() => onSubmit({deactivate: true})}
						waiting={waiting}
						fullwidth={false}
						size='small'
						text='deactivate-btn'
					/>}
				</Button.Group>
				: <div />}
			<Button.Group position='right'>
				<Button
					color='light'
					size='small'
					rounded
					onClick={onClose}
				>
					{T('close')}
				</Button>
				{onSubmit && !disabledSubmit && <SubmitButton
					color="info"
					submit={onSubmit}
					waiting={waiting}
					fullwidth={false}
					size='small'
					text={T(buttonSubmitText)}
				/>}

			</Button.Group>
		</div>
	);
};

export default ActionsModal;