import React from "react";
import Money from "../../../components/money";
import { LocalIcon } from "whitelables/wl-name/icons";
import { Button } from "react-bulma-components";
import AppStore from "../../../app-store";
import EmployeesStatisticsModal from "./employees-statistics-modal";
import T from '../../../components/i18n'

const EmployeesRowMobile = ({ data }) => {
  return (
    <tr style={data.is_active ? {} : { opacity: "0.5" }}>
      <td colSpan={6}>
        <div
          style={{ padding: 10, gap: "20px" }}
          className="display-flex-row-gap"
        >
          <div className="display-flex-column" style={{ flex: 1 }}>
            <span className="product-name">
              {data.name} {data.phone && `(${data.phone})`}
            </span>
            <small className="silver">{data.email}</small>
          </div>
          <div style={{ textAlign: "right" }}>
            <span>{T('accruals')}</span>
            <br />
            <span className="font-size-120 bold">
              <Money amount={data.sumAccrual} signClassname={true} />
            </span>
          </div>
          <div className="display-flex-center" style={{ height: "auto" }}>
            <Button
              className="button-icon"
              onClick={() =>
                AppStore.openModal(<EmployeesStatisticsModal data={data} />)
              }
            >
              <LocalIcon icon="statistics" />
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default EmployeesRowMobile;
