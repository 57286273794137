import React from 'react'
import s from "./styles.module.scss";
import { LocalIcon } from 'whitelables/wl-name/icons'

const ScreenNotificationIcon = ({color}) => {
	let classNameType = s.newsType;
	let iconType = "news";

	if (color === "danger") {
		classNameType = s.errorType;
		iconType = "error";
	} else if (color === "warning") {
		classNameType = s.warningType;
		iconType = "warning";
	} else if (color === "success") {
		classNameType = s.successType;
		iconType = "success-icon";
	}

	return (
		<span className={classNameType}>
			<LocalIcon icon={iconType} className={s.icon}/>
		</span>
	);
};

export default ScreenNotificationIcon;