import React, {useState} from 'react'
import s from './styles.module.scss'
import UserStore from "../../../../user/user-store";
import AppStore from "../../../../app-store";
import Button from "react-bulma-components/lib/components/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import {
	useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";
import { LocalIcon } from 'whitelables/wl-name/icons'
import T from '../../../../components/i18n'


const CopyBanner = ({img}) => {
	const {isMobileSize} = useIsMobile()
	const encodedUserId = btoa(UserStore.get("realId"));
	const link = AppStore.formatingLink(`/?partner_id=${encodedUserId}`);
	const {handleAutoRemoveError, errorMessage, ErrorMessageBlock, setErrorMessage} = ErrorMessageControlHook()
	const [messageColor, setMessageColor] = useState('danger')
	const imgSrc = `${window.location.origin}${img}`
	const bannerLink =
		`
		<a 
			href="${link}" 
			target="_blank"
		>
			<img 
				src='${imgSrc}' 
				alt='${link}'
			/>
		</a>
		`

	const handleCopy = () => {
			navigator.clipboard.writeText(bannerLink)
				.then(() => {
					setMessageColor('success')
					handleAutoRemoveError("link-copied");
				})
				.catch((error) => {
					setMessageColor('danger')
					handleAutoRemoveError("unable-to-copy-link");
					// console.error(error)
				})
	};

	return (
		<div className={`display-flex-row ${s.wrapper}`}>
			<div className={s.wrapper_image}>
				<img src={img} alt={img} className={s.image}/>
			</div>
			<div className={s.wrapper_textarea}>
				{!isMobileSize && <Button onClick={handleCopy} className='button-link-icon'>
					<FontAwesomeIcon icon={"copy"}/>
				</Button>}
				<textarea disabled value={bannerLink} className={s.textarea}/>
				<p className='margin-top-10'>
					{T('share')}
				</p>
				<div className='display-flex-row'>
					<a
						className={s.link_share}
						href={`https://t.me/share/url?url=${encodeURIComponent(imgSrc)}&text=Для реєстрації та отримання 300 грн бонусу перейдіть по посиланню: ${link}`}
						target='_blank' rel="noopener noreferrer">
						<LocalIcon icon='telegram' size='large'/>
					</a>
					<a
						className={s.link_share}
						href={`viber://forward?text=${encodeURIComponent(imgSrc)} Для реєстрації та отримання 300 грн бонусу перейдіть по посиланню: ${link}`}
						target='_blank' rel="noopener noreferrer">
						<LocalIcon icon='viber' size='large'/>
					</a>
					{/*<a*/}
					{/*	href={`https://www.facebook.com/sharer/sharer.php?u=test`}*/}
					{/*	target='_blank'>*/}
					{/*	<LocalIcon icon='facebook' size='large'/>*/}
					{/*	facebook*/}
					{/*</a>*/}
					<a
						className={s.link_share}
						href={`https://x.com/intent/tweet?url=${encodeURIComponent(imgSrc)}&text=Для реєстрації та отримання 300 грн бонусу перейдіть по посиланню: ${link}`}
						target='_blank' rel="noopener noreferrer">
						<LocalIcon icon='twitter-x' size='large'/>
					</a>
				</div>
				<div className={s.message}>
					<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}
														 disabledScroll color={messageColor}/>
				</div>
			</div>
		</div>
	)
		;
};

export default CopyBanner;