import {confirmAlert} from "react-confirm-alert";
import T from "../../components/i18n";

export const confirmDelete = (onClickConfirm, title, message, titleConfirm, titleNotConfirm) => {
	confirmAlert({
		title: T(title || 'confirm-delete'),
		message: T(message) || '',
		buttons: [
			{
				label: T(titleConfirm || 'delete'),
				onClick: onClickConfirm
			},
			{
				label: T(titleNotConfirm || 'no-delete'),
				onClick: () => {}
			}
		]
	});
}