import React from "react";
import List from "react-bulma-components/lib/components/list";
import T from "../../../../../components/i18n";
import ShipmentsStore from "../../../../shipments/shipments-store";
import formatDate from "../../../../../tools/format-date";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import User from "../../../../../user/user-store";
import Money from "../../../../../components/money";
import DOM from "react-dom-factories";
import ProductsStore from "../../../products-store";
import { Heading } from "react-bulma-components";
import FieldDesc from "../common/field-desc";

const ShipmentProductRow = ({ data, units }) => {
  return (
    <List.Item>
      <Heading className="margin-bottom-0" size={5}>
        ID: {data["id"]}. {data["name"]}
        {!!data["supplier_id"] &&
          `(${T("from")} ${T(
            ShipmentsStore.getSupplierName(data["supplier_id"])
          )})`}
      </Heading>
      <><small>{formatDate(data["created_at"])}</small> | <small>{User.getResponsebleName(data["responsible_user_id"])}</small> | <small>{T(WarehouseStore.getWarehouseName(data["marketplace_id"]))}</small> </>
      <div className="display-flex-row-gap">
        <div className="display-flex-column">
          <span>
            <FieldDesc
              name="delivery-delivery"
              value={formatDate(data["delivered_date"]) || T("supply")}
            />
          </span>
          <span>
                <FieldDesc
                  name="shipments-amount"
                  value={
                    <Money
                      currency={data["currency_id"]}
                      amount={data["item_amount"]}
                      className="has-text-danger"
                      wrapper={DOM.span}
                    />
                  }
                />
          </span>
        </div>
        <div className="display-flex-column">
          {User.getPermissionEnable("incomeinformation") && (
            <>
              <span>
                <FieldDesc
                  name="shipments-price-item"
                  value={
                    <Money
                      currency={data["currency_id"]}
                      amount={data["purchase_price"]}
                      className="has-text-danger"
                      wrapper={DOM.span}
                    />
                  }
                />
              </span>
              {!!data["fee_total"] && <span>
                <FieldDesc
                  name="delivery-amount"
                  value={
                    <Money
                      currency={data["currency_id"]}
                      amount={data["fee_total"] / data['currency_rate']}
                      className="has-text-danger"
                      wrapper={DOM.span}
                    />
                  }
                />
              </span>}
            </>
          )}
          {parseInt(data["currency_id"]) !==
            parseInt(User.get("currency_id")) && (
            <span>
              <FieldDesc
                name="exchange-rate"
                value={
                  <Money amount={data["currency_rate"]} wrapper={DOM.span} />
                }
              />
            </span>
          )}
        </div>
        <div className="display-flex-column">
          <span>
            <FieldDesc
              name="received"
              value={ProductsStore.getStockUnits(
                data["quantity_received"],
                units
              )}
            />
          </span>
          <span>
            <FieldDesc
              name="involved"
              value={ProductsStore.getStockUnits(data["quantity_sold"], units)}
            />
          </span>
        </div>
      </div>
    </List.Item>
  );
};

export default ShipmentProductRow;
