import React, {useEffect, useRef, useState} from "react";
import s from "./styles.module.scss";
import { connectToStores } from "../../tools/reflux-tools";
import NotificationStore from "../../modules/notification/notification-store";
import { LocalIcon } from "whitelables/wl-name/icons";
import T from '../i18n'
import {useHistory} from "react-router-dom";
import AppStore from "../../app-store";

const ScreenNotification = ({ screenNotifications }) => {
  const [message, setMessage] = useState([]);
  const timeoutRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const notificationArray = Object.values(screenNotifications)

    setMessage(notificationArray);

    if(notificationArray.length > 5) {
      handleCloseMessage(notificationArray[0].id)
    }

    if(!!notificationArray.length) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        handleCloseMessage(notificationArray[0].id);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [screenNotifications]);


  const handleCloseMessage = (id, evt) => {
    if(evt) {
      evt.stopPropagation()
    }
    setMessage(prev =>
      prev.map(item =>
        item.id === id ? {...item, isVisible: false} : item
      )
    );
    setTimeout(() => NotificationStore.removeScreenNotification(id), 520);
  };

  const handleClickElement = (url, modal, id) => {
    if(url) {
      history.push(url)
    } else if(modal) {
      AppStore.openModal(modal)
    }
    if(url || modal) {
      handleCloseMessage(id)
    }
  }


  return (
    <div className={s.wrapper}>
      {message?.map((item) => {
        return (
          <div
            key={item.id}
            className={`${s.popup} ${item.isVisible ? s.visible : s.visible_close}`}
            onClick={() => handleClickElement(item?.url, item?.modal, item.id)}
          >
            <span className={s.name_wrapper}>
              {item?.icon && <span className={s.icon_name}>
                {item?.icon}
              </span>}
              <span className={s.name}>
                {T(item.name)}
              </span>
            </span>
            <small className={s.message}>
              {(T(item.message)).length > 110 ? `${(T(item.message)).slice(0, 110)}...` : T(item.message)}
            </small>
            <span
              className={s.close}
              onClick={(evt) => handleCloseMessage(item.id, evt)}
            >
              <LocalIcon icon="close-fill" size="small" />
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default connectToStores(ScreenNotification, { _: NotificationStore });
