import React, { useState } from "react";
import DynamicTabList from "../../../components/dynamic-tab-list/dynamic-tab-list";
import DocumentSettingsTemplate from "./document-settings-template";
import {Box} from "react-bulma-components";

const list = [
  {
    label: "product_slip",
    value: "sales_receipt",
  },
  {
    label: "packing-list",
    value: "delivery_note",
  },
  {
    label: "payment-invoice",
    value: "invoice_payment",
  },
  {
    label: "sales-invoice",
    value: "bill_lading",
  },
];

const DocumentSetting = () => {
  const [isActiveTab, setIsActiveTab] = useState("sales_receipt");

  const handleChangeTab = (tabs) => {
    setIsActiveTab(tabs);
  };

  return (
    <Box>
      <DynamicTabList
        list={list}
        onChangeTab={handleChangeTab}
        defaultTab={isActiveTab}
      />
      {list.map(
        (item) =>
          isActiveTab === item.value && (
            <DocumentSettingsTemplate
              key={item.value}
              name={item.value}
              label={item.label}
            />
          )
      )}
    </Box>
  );
};

export default DocumentSetting;
