import React, {useEffect, useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import {Input, Label} from "react-bulma-components/lib/components/form";
import T from '../../../../components/i18n'
import ActionsButton from "./actions-button";
import ProductsActions from "../../products-actions";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../../../tools/error-handler";

const AddAttrProductModal = ({name, id, onClose}) => {
	const [nameState, setNameState] = useState(name)
	const [isLoading, setIsLoading] = useState(false)
	const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = ErrorMessageControlHook({})

	useEffect(() => {
		const addAttr = ProductsActions.addAttr.completed.listen(() => {
			onClose()
		})

		const addAttrFailed = ProductsActions.addAttr.failed.listen((res) => {
			setIsLoading(false)
			const error = errorHandler(res)
			handleAutoRemoveError(error)
		})

		return () => {
			addAttr()
			addAttrFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const handleChangeInput = ({target: {value}}) => {
		setNameState(value)
	}

	const handleSubmit = () => {
		setIsLoading(true)

		const data = {
			name: nameState
		}

		if(id) {
			data.id = id
		}
		ProductsActions.addAttr(data)
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Label>
					{T('attr-name')}
				</Label>
				<Input
					onChange={handleChangeInput}
					value={nameState}
					placeholde={T('add-attr-placeholder')}
				/>
				<ActionsButton onClose={onClose} onSubmit={handleSubmit} isLoading={isLoading}/>
			</Box>
		</>
	);
};

export default AddAttrProductModal;