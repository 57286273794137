import React from 'react'
import ChatsStore from "../chats-store";

const ChatAvatar = ({uid, remote_id, sis, not_read}) => {
	let className="avatar system-"+sis;
	if (not_read > 0) {
		className += " not_read";
	}
	return (
		<div className={className} data-unread={not_read}>
			<img src={ChatsStore.getAvatar(uid, remote_id)} alt={sis}/>
		</div>
	)
};

export default ChatAvatar;