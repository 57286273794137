import React from "react";
import { Box, Button } from "react-bulma-components";
import AppStore from "../../../../app-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmDelete } from "../../../../tools/confirm-delete";
import UserStore from "../../../../user/user-store";
import AddCustomPrice from "../../modal/add-custom-price";

const RowMobile = ({ data }) => {
	return (
		<tr>
			<td>
				<Box style={{ display: "flex", alignItems: "center" }}>
          <span style={{ flex: 1 }} className="font-size-110 bold">
            {data.name}
          </span>
					<div className="display-flex-row">
						<Button
							className="button-link-without-style"
							onClick={() =>
								AppStore.openModal(<AddCustomPrice modal='custom-prices' {...data}/>)
							}
						>
							<FontAwesomeIcon icon="edit" size="2x" />
						</Button>
						<Button
							className="button-link-without-style"
							onClick={() =>
								confirmDelete(
									() => UserStore.deleteCustomPrice(data.id),
									"confirm-delete",
									"are-you-sure-to-delete-this-catalog"
								)
							}
						>
							<FontAwesomeIcon icon="trash-alt" size="2x" />
						</Button>
					</div>
				</Box>
			</td>
		</tr>
	);
};

export default RowMobile;
