import React, { Component } from "react";
import { printElem } from "tools/print";
import UserStore from "user/user-store";
import SubmitButton from "../../components/submit-button";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";

class PrintElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: false,
      url: props.url,
      text: props.text,
    };

    this.size = props.size || this.props.isMobileSize ? "small" : "medium";

    this.onClickButton = this.onClickButton.bind(this);
  }

  componentDidMount() {
    const isAutoPrint = UserStore.getMoreSetting("is_auto_fiscal_print");
    if (isAutoPrint) {
      this.onClickButton();
    }
  }

  onClickButton() {
    printElem(this.state.url);
    this.setState({
      waiting: true,
    });

    setTimeout(
      () =>
        this.setState({
          waiting: false,
        }),
      3000
    );
  }

  render() {
    return (
      <SubmitButton
        text={this.state.text}
        submit={this.onClickButton}
        color="light"
        rounded
        size={this.size}
        fullwidth={false}
        waiting={this.state.waiting}
      />
    );
  }
}

export default withIsMobileSize(PrintElement);
