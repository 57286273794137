import React, { useEffect, useState } from "react";
import {Box, Button} from "react-bulma-components";
import TerminalStore from "./terminal-store";
import { connectToStores } from "../../../tools/reflux-tools";
import T from "../../../components/i18n";
import UserStore from "../../../user/user-store";
import Tabs from "react-bulma-components/lib/components/tabs";
import TerminalListTab from "./terminal-tab/terminal-list-tab";
import TerminalReceiptsTab from "./receipts-tab/terminal-receipts-tab";
import TerminalActions from "./terminal-actions";
import {Link} from "react-router-dom";
import AppStore from "../../../app-store";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const tabs = ["terminal-list", "terminal-receipts"];

const TerminalTab = () => {
  const {isMobileSize} = useIsMobile()
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    TerminalActions.load();
    TerminalActions.loadTerminalReceipt();
  }, []);

  return (
    <>
      <Box style={{ borderRadius: "0 0 16px 16px" }}>
        <Tabs
          type="boxed"
          fullwidth={false}
          size={isMobileSize ? "small" : "medium"}
        >
          {tabs.map((tab) => (
            <Tabs.Tab
              key={tab}
              active={activeTab === tab}
              onClick={() => handleChangeTab(tab)}
            >
              {T(tab)}
            </Tabs.Tab>
          ))}
        </Tabs>
      </Box>
      <div align='left' className='margin-bottom-5'>
        <Button renderAs={Link}
                size="small"
                rounded
                color="warning"
                target="_blank" to={{pathname: AppStore.getDocsLink('terminal')}}
        >
          {T('how-connect')}
        </Button>
      </div>
      {activeTab === "terminal-list" && <TerminalListTab />}
      {activeTab === "terminal-receipts" && <TerminalReceiptsTab />}
    </>
  );
};

export default connectToStores(TerminalTab, {
  _: TerminalStore,
  user: UserStore,
});
