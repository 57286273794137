import React from 'react'
import s from './styles.module.scss'
const Badge = ({label,hidden,children}) => {
	return (
		<div className={s.wrapper}>
			{children}
			{!hidden && (
				<div className={s.badge}>
					{label}
				</div>
			)}
		</div>
	);
};

export default Badge;