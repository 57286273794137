import React from "react";
import ClientsStore from "../../clients-store";
import AppStore from "../../../../app-store";
import UserStore from "../../../../user/user-store";

const socialInfo = {
  telegram: {
    icon: "/img/Telegram_logo.svg",
    link: (value) => {
      if(value[0] === '@') {
        return `tg://resolve?domain=${value.slice(1)}`
      } else {
        return `tg://resolve?domain=${value}`
      }
    },
  },
  addPhone: {
    icon: "/img/Viber_logo.svg",
    link: (value) => `viber://chat?number=+${value}`,
  },
  facebook: {
    icon: "/img/Facebook_f_logo.png",
    link: (value) => `https://www.facebook.com/${value}`,
  },
  instagram: {
    icon: "/img/Instagram_logo.webp",
    link: (value) => `https://www.instagram.com/${value}/`,
  },
  linkedin: {
    icon: "/img/linkedin-logo.svg",
    link: (value) => `https://www.linkedin.com/in/${value}/`,
  },
  addEmail: {
    link: (value) => `mailto:${value}`,
  },
};

const SocialRender = ({ clientID }) => {
  const social = ClientsStore.getSocialClient(clientID);
  const socialData = Object.entries(social || {});

  const handleClickTelegram = (evt, link) => {
    if (AppStore.isMobileApp()) {
      evt.stopPropagation();
      evt.preventDefault();
      UserStore.postMessage(
        `open-url::${link}`
      );
    }
  };

  return (
    !!socialData.length && (
      <div
        className="display-flex-row flex-wrap"
        style={{ gap: 20, marginBottom: "1.5rem", alignItems: "flex-start" }}
      >
        {socialData.map(([key, value]) => {
          return (
            <div key={key} className="display-flex-column" style={{ gap: 5 }}>
              {value.map((item) => (
                <a
                  key={`${key}-${item}`}
                  href={socialInfo[key].link(item)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="display-flex-row"
                  style={{ alignItems: "center" }}
                  onClick={(evt) => handleClickTelegram(evt, socialInfo[key].link(item))}
                >
                  {socialInfo[key].icon && (
                    <img
                      src={socialInfo[key].icon}
                      alt={socialInfo[key].link(item)}
                      style={{ width: 20 }}
                    />
                  )}
                  {key === 'addPhone' ? '+' : ''}{item}
                </a>
              ))}
            </div>
          );
        })}
      </div>
    )
  );
};

export default SocialRender;
