import React from 'react'
import Box from "react-bulma-components/lib/components/box";
import T from "../../../components/i18n";

const EmptyMessage = () => {
	return (
		<Box>
			<h2>{T('not-have-transactions')}</h2>
		</Box>
	);
};

export default EmptyMessage;