import React from "react";
import s from "./styles.module.scss";
import ChatsStore from "../../chats-store";
import ButtonsCreate from "./buttons-create";
import ClientInfo from "./client-info";
import ClientOrders from "./client-orders";
import ButtonArrow from "../common/button-arrow";
import {parseJSON} from "../../../../tools/error-handler";
import InfoOlx from "./info-olx";

const ActiveClient = ({ id, isActiveClient, isFloat, onClose }) => {
  const [activeChat] = ChatsStore.getChatsById(id);

  const extra = parseJSON(activeChat?.extra);
  return (
    isActiveClient && (
      <div
        className={`${s.active_user_info} 
      ${isFloat ? s.is_float : ""}`}
      >
        {isFloat && (
          <div>
            <ButtonArrow onClick={onClose} orientation="right" />
          </div>
        )}
        <div style={{ minWidth: "260px" }}>
          {!activeChat?.client_id && <ButtonsCreate activeChat={activeChat} />}
          {!!activeChat?.client_id && (
            <>
              <ClientInfo
                clientID={activeChat?.client_id}
                activeChat={activeChat}
              />
              <ClientOrders clientId={activeChat.client_id} />
            </>
          )}
          {!!Object.keys(extra || {}).length && activeChat.system_id === 19 && <InfoOlx data={extra?.advert}/>}
        </div>
      </div>
    )
  );
};

export default ActiveClient;
