import React from 'react'
import {LocalIcon} from 'whitelables/wl-name/icons'
import {Link} from "react-router-dom";
import ChatsActions from "../../modules/chats/chats-actions";

const GoToChatClient = ({chatID, onClose}) => {

	const handleClick = () => {
		onClose && onClose()
		ChatsActions.loadByChatId(chatID)
	}

	return chatID ? (
		<Link className='cursor-pointer' to={`/chats/${chatID}`} onClick={handleClick}>
			<LocalIcon icon='chats' width={21} height={21}/>
		</Link>
	): null;
};

export default GoToChatClient;