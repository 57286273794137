import React, { useEffect, useState } from "react";
import { Box, Button } from "react-bulma-components";
import T from "../../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import Loader from "react-loader-spinner";
import TerminalActions from "../terminal-actions";
import ErrorMessageControlHook from "../../../../components/error-message-block/error-message-control.hook";
import Money from "../../../../components/money";

const PayOnTerminalModal = ({
  terminalID,
  amount,
  account_id,
  onClose,
  onResponseTerminal,
}) => {
  const {
    errorMessage,
    ErrorMessageBlock,
    handleAutoRemoveError,
    setErrorMessage,
  } = ErrorMessageControlHook();
  const [isFailed, setIsFailed] = useState(false);
  const [isWaiting, setIsWaiting] = useState(true);
  const [isCompletedError, setIsCompletedError] = useState(false);
  const [responseTerminal, setResponseTerminal] = useState({});
  const [numberTransactions, setNumberTransactions] = useState("");

  useEffect(() => {
    TerminalActions.purchase({ terminalID, amount });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const terminalPurchaseCompleted = TerminalActions.purchase.completed.listen(
      (data) => {
        setResponseTerminal(data.result);
        TerminalActions.addTerminalReceipt(data?.result, account_id);
      }
    );

    const terminalPurchaseFailed = TerminalActions.purchase.failed.listen(
      (res) => {
        setIsWaiting(false);
        setIsFailed(true);
        const parseError = JSON.parse(res?.response || "{}");

        if (parseError?.error) {
          handleAutoRemoveError(parseError.error);
        } else {
          handleAutoRemoveError("unhandled_error");
        }
      }
    );

    const terminalAddReceiptCompleted =
      TerminalActions.addTerminalReceipt.completed.listen((data) => {
        onResponseTerminal(data.id, responseTerminal.rrn);
        onClose();
      });

    const terminalAddReceiptFailed =
      TerminalActions.addTerminalReceipt.failed.listen(() => {
        setIsWaiting(false);
        setIsFailed(true);
        setIsCompletedError(true);
        handleAutoRemoveError("error-not-completed-payment");
        setNumberTransactions(responseTerminal.rrn);
        // onResponseTerminal(false, responseTerminal.rrn);
      });

    return () => {
      terminalPurchaseCompleted();
      terminalPurchaseFailed();
      terminalAddReceiptCompleted();
      terminalAddReceiptFailed();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseTerminal]);

  const handleTryAgainPurchase = () => {
    setIsFailed(false);
    setIsWaiting(true);
    TerminalActions.purchase({ terminalID, amount });
  };

  const handleTryAgainAddTerminalReceipt = () => {
    setIsWaiting(true)
    setIsFailed(false)
    setIsCompletedError(false)
    TerminalActions.addTerminalReceipt(responseTerminal, account_id);
  };

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading align="center">
          {isFailed && T("error-payment")}
          {isWaiting && T("pending-payment")}
        </Heading>
        <div
          className="display-flex-row bold font-size-120"
          style={{ justifyContent: "center", marginBottom: "10px" }}
        >
          <b>{T("transaction-amount")}: </b>{" "}
          <Money className="text-success" amount={amount} aid={account_id} />
        </div>
        {isWaiting && (
          <div align="center">
            <Loader type="Rings" color="#00BFFF" height={80} width={80} />
          </div>
        )}
        {numberTransactions && (
          <div className="big-text" align="center">
            {T("rrn-transaction")}:
            <span className="text-success"> {numberTransactions}</span>
          </div>
        )}
        {isFailed && !isCompletedError && (
          <div align="center">
            <Button
              size="large"
              onClick={handleTryAgainPurchase}
              color="info"
              rounded
            >
              {T("repeat-payment")}
            </Button>
          </div>
        )}
        {isCompletedError && (
          <Button
            size="large"
            onClick={handleTryAgainAddTerminalReceipt}
            color="info"
            rounded
          >{T('try-again-add-receipt')}</Button>
        )}
        {(isFailed || isCompletedError) && (
          <div align="right">
            <Button onClick={onClose}>{T("close")}</Button>
          </div>
        )}
      </Box>
    </>
  );
};

export default PayOnTerminalModal;
