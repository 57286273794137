import React from 'react'
import {
	Checkbox,
	Control,
	Field, Label
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import InfoWrapper from "../../../../../components/info-wrapper";

const CheckboxGroupShopSupervision = ({dataCheckbox, checkboxValue, label, onChange}) => {
	return (
		<Field>
			{label && <Label>{T(label)}</Label>}
			{dataCheckbox.map(item => {
				const field =
					<Control>
						<Checkbox
							name={item.name}
							onChange={onChange}
							checked={checkboxValue[item.name] || false}
						>{T(item.title)}</Checkbox>
					</Control>


				if(item.info) {
					return <Field className="margin-top-20" key={item.name}><InfoWrapper info={item.info}>{field}</InfoWrapper></Field>
				}

				return <Field className="margin-top-20" key={item.name}>{field}</Field>
			})}
		</Field>
	);
};

export default CheckboxGroupShopSupervision;