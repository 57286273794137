import React from "react";
import {isMobile} from "react-device-detect";

const getClassNameForMargin = (num) => {
    let className = ""
    if (num <= 15) {
      className = "danger";
    } else if (num > 15 && num <= 25) {
      className = "warning";
    } else {
      className = "success";
    }

    return className;
} 

const getClassNameForNumber = (num) => {
    let className = ""
    if (num < 1) {
      className = "danger";
    } else if (num >= 1 && num <= 2) {
      //className = "warning";
      className = "success";
    } else {
      className = "success";
    }

    return className;
} 

const getClassNameForMoney = (num) => {
    let className = ""
    if (parseFloat(num) <= 0) {
      className = "danger";
    } else {
      className = "success";
    }

    return className;
} 

const getSimpleNumber = (b) => {
  if (String(b).indexOf(" ") === -1) {
    return b;
  }
  return parseFloat(String(b).replace(/ /g, ""))
}

const getStringChildren = (element) => {
  let result = [];

  const traverse = (element) => {
    if (typeof element === 'string') {
      result.push(element.trim());
    } else if (typeof element === 'number') {
      result.push(element);
    } else if (React.isValidElement(element) && element.props.children) {
      React.Children.forEach(element.props.children, traverse);
    }
  }

  traverse(element);
  return result.join('');
}

const getClassNameFontSizeWithLength = (string) => {
  if(typeof string === 'string') {
    if(string.length === 6 || string.length === 5) {
      return 'font-size-20'
    }else if(string.length >= 7) {
      return 'font-size-18'
    }
  }

  return ''
}

const ColorControl = (num, simple, _money, margin, small, isDanger) => {
  let className = "";

  if (!small) {
    className += "big-number";
  }


  className += " text-";

  if(isDanger) {
    className += "danger";
  } else if (simple) {
    className += "success";
  } else {
    if (_money) {
      className += getClassNameForMoney(getSimpleNumber(num));
    } else if (margin) {
      className += getClassNameForMargin(num);
    } else {
      className += getClassNameForNumber(getSimpleNumber(num));
    }

  }

  if(!isMobile) {
    if(React.isValidElement(num)) {
      const result = getStringChildren(num)
      const fontSize = getClassNameFontSizeWithLength(result)
      className += ` ${fontSize}`
    } else {
      const fontSize = getClassNameFontSizeWithLength(num)
      className += ` ${fontSize}`
    }
  }

  if (small) {
    className += " bold-text";
    return (
      <span className={className}>
             {num}
        </span>
    );
  }

  return (
        <div className={className}>
            {num}
        </div>
      );
};

export default ColorControl;