import React, {useEffect, useState} from 'react'
import {
	Checkbox, Field,
	Label,
} from "react-bulma-components/lib/components/form";
import T from '../../../../../components/i18n'

const FiscalizeTypeCheckbox = ({type, onChange}) => {
	const [activeType, setActiveType] = useState(type || ['all'])


	const handleChangeRadio = (evt) => {
		const name = evt.target.name
		const checked = evt.target.checked

		setActiveType(prevState => {
			if(name === 'all') return ['all']

			if(!checked) {
				if((name === 'rid' && !prevState.includes('mid')) || (name === 'mid' && !prevState.includes('rid'))) {
					return ['all']
				}
			}

			if(checked) {
				return [...prevState.filter(item => item !=='all'), name]
			} else {
				return prevState.filter(item => item !== name)
			}
		})

	}

	useEffect(() => {
		if(onChange) onChange(activeType)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeType]);

	return (
		<Field>
			<Label>{T('fiscalize-label')}</Label>
			<Checkbox
				name='all'
				onChange={handleChangeRadio}
				checked={activeType.includes('all')}
				className='margin-right-10'
			>
				{T('all')}
			</Checkbox>
			<Checkbox
				name='rid'
				onChange={handleChangeRadio}
				checked={activeType.includes('rid')}
				className='margin-right-10'
			>
				{T('fiscalize-user')}
			</Checkbox>
			<Checkbox
				name='mid'
				onChange={handleChangeRadio}
				checked={activeType.includes('mid')}
			>
				{T('fiscalize-warehouse')}
			</Checkbox>
		</Field>
	);
};

export default FiscalizeTypeCheckbox;