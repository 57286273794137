import React, {useState} from "react";
import User from "../../../../user/user-store";
import ProductStore from "../../products-store";
import Image from "react-bulma-components/lib/components/image";
import T from "../../../../components/i18n";
import UserStore from "../../../../user/user-store";
import SalesStore from "../../../sales/sales-store";
import LightActions from "../../light-actions";
import Media from "react-bulma-components/lib/components/media";
import {
	Checkbox,
	Control,
	Field
} from "react-bulma-components/lib/components/form";
import LightStore from "../../light-store";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import EditProductsModal from "../../edit-products-modal";
import Content from "react-bulma-components/lib/components/content";
import ExpirationDateTag
	from "../../../../components/expiration-date-tag/expiration-date-tag";
import Level from "react-bulma-components/lib/components/level";
import {getStockDesktop} from "../../common/function";
import Box from "react-bulma-components/lib/components/box";
import ColorControl from "../../../../tools/color-control";
import SingleRender from "./single-render";
import TypeProductTag
	from "../../../../components/type-product-tag/type-product-tag";
import DateReportChange
	from "../../common/date-report-change/date-report-change";

const NewProductVariable = ({data}) => {
	const [isOpen, setIsOpen] = useState(false)
	const firstDataItem = data[0]

	let img = ProductStore.makeImageUrl(firstDataItem.parent_id, 0, 64);
	ProductStore.makeImageUrl(data.id, 0, 0);
	if (ProductStore.needRemoveImageCache(data.id)) {
		img += "?" + Math.random();
	}

	const img_o = <Image src={img} size={64} />;

	const info = [];
	info.push("ID: " + firstDataItem.parent_id);
	if (firstDataItem["brand"] && firstDataItem["brand"] !== "") {
		info.push(T("product-brand") + ": " + firstDataItem["brand"]);
	}
	if (firstDataItem["category_id"] && firstDataItem["category_id"] !== "") {
		info.push(
			T("category") + ": " + ProductStore.getCategoryName(firstDataItem["category_id"])
		);
	}

	const sumInstock = data.reduce((acc, cur) => {
		const sumStockCalc = Object.values(cur.stock || {}).reduce(
			(accStock, curStock) => {
				accStock.quantity += curStock.quantity;
				accStock.waiting += curStock.waiting;
				accStock.instock += curStock.instock;

				const isMinMax = UserStore.isMinimalStock({mid: curStock.marketplace_id, pid: curStock.product_id, instock: curStock.instock});

				if(isMinMax) {
					accStock.isMinMaxStock = true
				}

				const salesReport = SalesStore.getSaleReportsByProduct(
					curStock.product_id,
					curStock.marketplace_id
				);
				accStock.items_count += salesReport.items_count;

				accStock.childrenIdsWithMid.push(`${curStock.product_id},${curStock.marketplace_id}`);

				return accStock;
			},
			{ waiting: 0, instock: 0, quantity: 0, items_count: 0, isMinMaxStock: false, childrenIdsWithMid: [] }
		);

		acc.waiting += sumStockCalc.waiting
		acc.instock += sumStockCalc.instock
		acc.quantity += sumStockCalc.quantity
		acc.items_count += sumStockCalc.items_count

		if(sumStockCalc.isMinMaxStock) {
			acc.isMinMaxStock = true
		}

		sumStockCalc.childrenIdsWithMid.forEach(item => {
			acc.childrenIdsWithMid.push(item)
		})

		return acc
	},{ waiting: 0, instock: 0, quantity: 0, items_count: 0, isMinMaxStock: false, childrenIdsWithMid: [] })

	const handleChangeCheckbox = (evt) => {
		const value = evt.target.checked;

		LightActions.setVariableProductsGroup(sumInstock.childrenIdsWithMid, value)
	}

	return <>
		<tr onClick={() => setIsOpen(prev => !prev)}>
			<td>
				<Media>
					<Media.Item position="left" className='position-relative'>
						<Field className="absolute">
							<Control>
								<Checkbox
									name="is_parent_products"
									onChange={handleChangeCheckbox}
									onClick={(evt) => evt.stopPropagation()}
									checked={LightStore.getCheckedParentChecked(sumInstock.childrenIdsWithMid)
									}
								/>
							</Control>
						</Field>
						{!User.getPermissionEnable("editproducts") ? (
							<div className="product-name">{img_o}</div>
						) : (
							<OpenInsideModal
								className="product-name"
								size="small"
								text=""
								img={img_o}
								renderAs="b"
							>
								<EditProductsModal pid={firstDataItem.parent_id} step={1} />
							</OpenInsideModal>
						)}
					</Media.Item>
					<Media.Item>
						<Content>
							<p className="product-name">
								<b><ExpirationDateTag pid={data.id}/></b>
								<b><TypeProductTag
									typeProduct={firstDataItem.type_product}/> {firstDataItem.real_name} ({T('variable-count', {count: data.length})})</b>
							</p>
							<p>{info.join(" | ")}</p>
						</Content>
						<Level renderAs="nav" className="margin-top-10">
							<Level.Side align="left">
								{!User.getPermissionEnable("editproducts") ? null : (
									<Level.Item>
										<OpenInsideModal size="small" text="edit-btn">
											<EditProductsModal pid={firstDataItem.parent_id} step={0} />
										</OpenInsideModal>
									</Level.Item>
								)}
							</Level.Side>
						</Level>
					</Media.Item>
				</Media>
			</td>
			<td>
				{data.type_product === 4 ?
					(
						<Box className="padding-left-right5">
							<div>{T('available')}</div>
							{ColorControl("∞")}
							<div>{T(data["units"])}</div>
						</Box>
					) : (
						<Box
							style={sumInstock.isMinMaxStock ? {border: '1px solid red'} : {}}>
							<div>{T("available")}</div>
							<strong>
								{getStockDesktop(
									sumInstock.instock,
									sumInstock.quantity,
									sumInstock.waiting,
									false,
									false,
									sumInstock.instock !== 0 && sumInstock.instock % 1 !== 0 && true,
									sumInstock.instock <= 0
								)}
							</strong>
							<div>{T(firstDataItem["units"])}</div>
						</Box>)}
			</td>
			{User.getPermissionEnable("incomeinformation") && (
				<td>
					<Box className="padding-bottom0">
						<div>{T("quantity")}</div>
						{ColorControl(sumInstock.items_count)}
						<div>{T("of-sales")}</div>
						<DateReportChange/>
					</Box>
				</td>
			)}
			<td></td>
			<td></td>
			<td></td>
		</tr>
		{isOpen && data.map(item => <SingleRender key={item.id} data={item}
																							backgroundLightBlack isVariable/>)}
	</>
}

export default NewProductVariable