// import moment from "moment";

import {uaSort} from "./ua-sort";

export default function sortByKey(array, key) {
    if (typeof(array) === "undefined") return array;

    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        if (key === "created_at") {
        	return new Date(y).getTime() - new Date(x).getTime();
        } else if (typeof x === 'string' && typeof y === 'string') {
					return uaSort(x, y);
				} else {
        	return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    	}
    });
}