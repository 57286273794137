import React from 'react';
import T from "components/i18n";
import { Field, Control, Input, Label } from 'react-bulma-components/lib/components/form';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Actions from './products-actions' 
import BaseForm from 'components/modules/base-form';
import SubmitButton from 'components/submit-button';


class AddBrandModal extends BaseForm  {
    constructor(props) {
        super(props);
        this.state = {
          id:  props.id || 0,
          name: {
                error: false,
                value: props.name || ""
          },
          waiting: false
        };

        this.submit = this.submit.bind(this);
    }
  
    componentDidMount() {
        const { onClose } = this.props;
        
        this.addBrand = Actions.addBrand.completed.listen((data) => {
            this.setState({
                'waiting': false
            });
            if (typeof(onClose) == "function") {
                onClose();
            }
        
        });

        this.addBrandFail = Actions.addBrand.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            this.setState({
                'waiting': false,
                'message': {
                'msg': T(data['error'])
                }
            })
        }); 
    }
  
  componentWillUnmount() {
        if (typeof (this.addBrand) === "function") {
            this.addBrand();
        }

        if (typeof(this.addBrand) === "function") {
            this.addBrand();
        }
    }
  
    submit() {
      if (this.state.name.value.trim() === "") {
          this.setState({
                name: {...this.state.name, error: true}
          });
        return
      }
      
      this.setState({ "waiting": true });

      const data = { 'name': this.state.name.value.trim() }
      if (this.state.id) { data['id'] = this.state.id; }
      
      Actions.addBrand(data);
    }

    render () {
        return (
              <Box>
                <Field>
                  <Control>
                    <Label>{T('brand-name-label')}</Label>
                    <Input  size="large" 
                          name="name" type="text" 
                          placeholder={T('brand-name-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.name.error) ? "danger" : "primary"}
                          value={this.state.name.value}
                          />
                  </Control>
                </Field>
            <Button.Group>
              <SubmitButton text={T(this.state.id ? 'btn-edit' : 'add-brand-btn-label')} waiting={this.state.waiting} submit={this.submit}/>
                </Button.Group>
              </Box>
        );
    }

}

export default AddBrandModal;