/* eslint-disable no-param-reassign */
import Reflux from "reflux-core";
import baseStore from "components/modules/base-store";

const _defaultStateGetter = function _defaultStateGetter() {
    return this.state;
};

const _defaultTriggerChange = function _defaultTriggerChange() {
    return this.trigger(this.getState());
};

const _defaultStateSetter = function _defaultStateSetter(field, value, silent) {
    let isChanged = false;
    if (typeof (field) === "object") {
        silent = !!value;
        for (const k in field) {
            if (field.hasOwnProperty(k)) {
                isChanged = this.setState(k, field[k], true) || isChanged;
            }
        }
    } else if (this.state[field] !== value) {
        if (value === undefined) {
            delete this.state[field];
        } else {
            this.state[field] = value;
        }
        isChanged = true;
    }

    if (!silent && isChanged) {
        this.triggerChange();
    }

    return isChanged;
};

export default function createStore(desc) {
    if (typeof (desc.getState) !== "function" && typeof (desc.state) === "undefined") {
        throw new Error("getState method or state property is required for every store");
    }

    if (typeof (desc.getState) !== "function") {
        desc.getState = _defaultStateGetter;
    }

    if (!desc.getInitialState) {
        desc.getInitialState = desc.getState;
    }

    if (typeof (desc.triggerChange) !== "function") {
        desc.triggerChange = _defaultTriggerChange;
    }

    if (typeof (desc.state) === "object") {
        desc.setState = _defaultStateSetter;
    }

    desc.replaceState = function replaceState(state) {
        if (this.state !== state) {
            this.state = state;
            this.triggerChange();
        }
    };

    // const baseStore = JSON.parse(JSON.stringify(_baseStore));

    let base_state = JSON.parse(JSON.stringify(baseStore.state));

    let new_desc = {...baseStore, ...desc}

    new_desc.state = {...base_state, ...desc.state}

    const store = Reflux.createStore(new_desc);
    const { listen: storeListen } = store;

    store.listen = function listen(callback, ...rest) {
        if (!callback) {
            throw Error("Invalid callback");
        }

        return storeListen.call(this, callback, ...rest);
    };

    return store;
}
