import React from "react";
import Reports from "../../../../../components/reports";
import ReportWithWarehouseRowDesktop from "./report-with-warehouse-row-desktop";
import usePaginationHook from "../../../../../tools/pagination-hook";
import User from "../../../../../user/user-store";
import ReportWithWarehouseRowMobile from "./report-with-warehouse-row-mobile";
import {
  useIsMobile
} from "../../../../../components/is-mobile-context/is-mobile-context";

let COLUMNS = [
  "warehouse",
  "products-netcost",
  "product-info-price",
  "available",
  "saled",
  "sales-profit",
];

const ReportWithWarehouse = ({ pid, stocks }) => {
  const {isMobileSize} = useIsMobile()
  const { page, end, start, total, setPage, PaginationBlock } =
    usePaginationHook({ listLength: Object.values(stocks).length, perPage: 5 });

  if (!User.getPermissionEnable("incomeinformation")) {
    COLUMNS = COLUMNS.filter(
      (item) => item !== "products-netcost" && item !== "sales-profit" && item !== 'saled'
    );
  }
  if(!User.getPermissionEnable("salesprice")) {
    COLUMNS = COLUMNS.filter(
      (item) => item !== 'product-info-price'
    );
  }
  if (isMobileSize) {
    COLUMNS = [];
  }

  return (
    <>
      <Reports
        rows={Object.values(stocks).slice(start, end)}
        columns={COLUMNS}
        hideFooterOnEmpty
      >
        {isMobileSize ? (
          <ReportWithWarehouseRowMobile role="row" pid={pid} />
        ) : (
          <ReportWithWarehouseRowDesktop role="row" pid={pid} />
        )}
      </Reports>
      <div className="display-flex-center">
        <PaginationBlock
          totalPage={total}
          setPage={setPage}
          currentPage={page}
          showPrevNext={false}
        />
      </div>
    </>
  );
};

export default ReportWithWarehouse;
