import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import T from "../../../components/i18n";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import {Link} from "react-router-dom";
import User from 'user/user-store';
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const PrintButton = ({id}) => {
	const {isMobileSize} = useIsMobile()
	return (
		<Dropdown
			up={isMobileSize}
			label={<FontAwesomeIcon icon="print" size={isMobileSize ? '2x' : '1x'}  title={T('print-shipment')}/>}
		>
			<Dropdown.Item renderAs={Link} to={"/bprint/write_off?id="+id+"&token="+encodeURIComponent(User.getToken())} target="_blank" title={T('inventory-write-off-act')} value="item">
				{T('inventory-write-off-act')}
			</Dropdown.Item>
		</Dropdown>
	);
};

export default PrintButton;