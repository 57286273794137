import React from "react";
import BaseFilterLayout from "../../../components/base-filter-layout";
import SalesStore from "../../sales/sales-store";
import SalesActions from "../../sales/sales-actions";
import ShipmentsActions from "../../shipments/shipments-actions";
import { getDatePeriod } from "../../../tools/date-range";

const period = getDatePeriod(30);

const GeneralReportFilter = () => {
  const handleChangeDate = (value) => {
    const daysKey = Object.keys(value).reverse();
    daysKey.forEach((item) => {
      SalesActions.filter(item, value[item]);
      ShipmentsActions.filter(item, value[item]);
    });
  };

  return (
    <BaseFilterLayout
      dateRangeSetting={{
        onChange: handleChangeDate,
        date_start: period || SalesStore.getFilter("date_start"),
        date_end: SalesStore.getFilter("date_end"),
      }}
    />
  );
};

export default GeneralReportFilter;
