import React, {useEffect} from "react";
import {Box, Button, Columns, Heading} from "react-bulma-components";
import SalesStore from "../../sales-store";
import T from "../../../../components/i18n";
import Card from "react-bulma-components/lib/components/card";
import Money from "../../../../components/money";
import Content from "react-bulma-components/lib/components/content";
import SaleClientInfo from "./sale-client-info";
import SaleInfo from "./sale-info";
import SaleProductsInfo from "./sale-products-info";
import ExpensesStore from "../../../expenses/expenses-store";
import DOM from "react-dom-factories";
import InfoWrapper from "../../../../components/info-wrapper";
import SaleViewActions from "./sale-view-actions";
import User from "../../../../user/user-store";
import SalesActions from "../../sales-actions";
import {connectToStores} from "../../../../tools/reflux-tools";

const SaleViewModal = ({ saleID,isRefund, onClose }) => {
  let data = SalesStore.getSalesByOid(saleID);
  if(isRefund) {
    data = SalesStore.getRefundById(saleID);
  }

  const isLoadingSale = SalesStore.get('isLoading')

  useEffect(() => {
    SalesActions.loadByOid(saleID)
  }, [saleID]);

  if(!Object.values(data || {}).length && !isLoadingSale) {
    return <Box>
      {T('we-find-the-sale-deleted')}
      <div align='right'>
        <Button onClick={onClose}>
          {T('close')}
        </Button>
      </div>
    </Box>
  }

  return !isLoadingSale && (
    <Card>
      <Card.Header
        style={{ position: "sticky", top: 0, background: "inherit" }}
      >
        <Card.Header.Title>
          <Heading size={4}>
            {T("product-cost")} #{data?.order_number_text}
          </Heading>
        </Card.Header.Title>
      </Card.Header>
      <Card.Content>
        <Columns className="is-mobile product-mobile-block">
          {User.getPermissionEnable("salesprice") && (
            <Columns.Column>
              <Box className="small-number-box">
                <b>{T("amount")}</b>:
                <br />
                <Money
                  aid={data["account_id"]}
                  amount={data.amount_sale}
                  offDept
                />
              </Box>
            </Columns.Column>
          )}

          {User.getPermissionEnable("incomeinformation") && (
            <Columns.Column>
              <Box className="small-number-box">
                <b>{T("sales-profit")}</b>:
                <br />
                <Money
                  aid={data["account_id"]}
                  amount={data.amount_sale - (data?.fee || 0)}
                  className="text-success"
                  offDept
                />
              </Box>
            </Columns.Column>
          )}
          {!!data?.fee && (
            <Columns.Column>
              <Box className="small-number-box">
                <InfoWrapper info={T("commission-sum-sales-chanel")}>
                  <b>{T("commission")}</b>:
                </InfoWrapper>
                <br />
                <Money
                  aid={data["account_id"]}
                  amount={data?.fee || 0}
                  className="text-danger"
                  offDept
                />
              </Box>
            </Columns.Column>
          )}
          {!!data?.discount && (
            <Columns.Column>
              <Box className="small-number-box">
                <b>{T("sales-discount")}</b>:
                <br />
                {data?.discount_type === "%" ? (
                  <span>{data?.discount}%</span>
                ) : (
                  <Money
                    aid={data["account_id"]}
                    amount={data?.discount}
                    offDept
                  />
                )}
              </Box>
            </Columns.Column>
          )}
          {User.getPermissionEnable("salesprice") &&
            data?.prepaid_amount > 0 && (
              <>
                <Columns.Column size="half">
                  <Box className="small-number-box">
                    <b>
                      {T("sale-paid-label")}
                      {T(ExpensesStore.getAccountName(data.account_id))}:
                    </b>
                    <br />
                    <Money
                      aid={data.account_id}
                      amount={data?.amount_sale - data?.prepaid_amount}
                      wrapper={DOM.span}
                    />
                  </Box>
                </Columns.Column>
                <Columns.Column size="half">
                  <Box className="small-number-box">
                    <b>
                      {T("sale-prepaid-label")}{" "}
                      {T(ExpensesStore.getAccountName(data?.prapaid_account_id))}
                      :
                    </b>
                    <br />
                    <Money
                      aid={data?.prapaid_account_id}
                      amount={data?.prepaid_amount}
                    />
                  </Box>
                </Columns.Column>
              </>
            )}
        </Columns>
        <hr />
        <Content>
          <SaleInfo data={data} />
          <SaleProductsInfo
            products={data?.items}
            accountID={data?.account_id}
          />
          {!!data?.client_id && User.getModuleEnable("clients") && (
            <SaleClientInfo
              clientID={data.client_id}
              accountID={data.account_id}
            />
          )}
        </Content>
      </Card.Content>
      <Card.Footer
      >
        <SaleViewActions
          isRefund={isRefund}
          onClose={onClose}
          saleID={data?.oid}
          saleNumber={data?.order_number}
          accountID={data?.account_id}
          clientsDeliveryID={data?.clients_delivery_id}
          prepaidAccountID={data?.prapaid_account_id}
          status={data?.order_status}
          amountSale={data?.amount_sale}
          prepaidAmount={data?.prepaid_amount || 0}
          dps={data?.dps}
        />
      </Card.Footer>
    </Card>
  );
};

export default connectToStores(SaleViewModal, {_: SalesStore});
