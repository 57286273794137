import {createActions} from "../../tools/reflux-tools";

const NotificationActions = createActions({

    load: { asyncResult: true },
    add: { asyncResult: true },
    addFrontNotification: { asyncResult: true },
    removeFrontNotification: { asyncResult: true },
    loadMessages: {asyncResult: true },
    readMessage: {asyncResult: true },
    removeMessage: {asyncResult: true },
    addScreenNotification: {asyncResult: true}
});


export default NotificationActions;