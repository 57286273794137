import React, { Component } from 'react';

import T from "components/i18n";
import Heading from 'react-bulma-components/lib/components/heading';
import Content from 'react-bulma-components/lib/components/content';


class WarehouseInfo extends Component {

    render () {
        return (
        		<Content>
        			<Heading>Раздел склад</Heading>
        			{T('hello')}
        			<p>На этой странице представлены все основные данные по состоянию Вашей торговли.</p>
        		</Content>
			)}

      }

export default WarehouseInfo;