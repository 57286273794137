import React from "react";
import { parseJSON } from "../../../../tools/error-handler";
import T from "../../../../components/i18n";
import { getFilterValue } from "./utils";

const ShowFilters = ({ filters }) => {
  const parseFilters = parseJSON(filters || "{}");

  const list = Object.keys(parseFilters).filter(item => item !== 'ruid')
  return (
    <div>
      {list.map((name) => (
        <span key={name}>
          {name !== 'params' && <><b>{T(name)}</b>:</>} {getFilterValue(name, parseFilters[name])} <br />
        </span>
      ))}
      {!list?.length && <p>{T("all-products")}</p>}
    </div>
  );
};

export default ShowFilters;
