import React, { Component } from "react";
import T from "components/i18n";
// import Money from "components/money";
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Columns from 'react-bulma-components/lib/components/columns';
import OrdersStore from "../orders/orders-store";

// import User from 'user/user-store';



class PreOrder extends Component {
  // constructor(props) {
  //       super(props);

  //       // this.renderFinish = this.renderFinish.bind(this);
    
  // }

  getOrders(data, curr) {
        let name = [];
        for (let i in data) {
            name.push(
            <Columns key={"o-"+i} className="is-mobile list-sales-order">
                <Columns.Column>{data[i]['name']}</Columns.Column>
                <Columns.Column size="one-fifth">{data[i]['quantity']}{T('items-p')}</Columns.Column>
                <Columns.Column size="one-fifth">{data[i]['total']}</Columns.Column>
            </Columns>
            );
        } 

        return name;
    }

  getIsPaid(data) {
    return (
            <>
            <span className="text-success font-size-120 bold">
              {data.price} {data.currency}
            </span>
            {!!data?.order_data[0] &&
            !!data?.order_data[0]["is_paid"] ? (
              <div>
                <span className="channel_block has-background-success">
                  {T("paid")}
                </span>
                <br />
                {!!data?.order_data[0]?.payment_type && (
                  <b>{data?.order_data[0]?.payment_type}</b>
                )}
              </div>
            ) : (
              <div>
                {!!data?.order_data[0] &&
                  !!data?.order_data[0]?.payment_type && (
                    <b>{data?.order_data[0]?.payment_type}</b>
                  )}
              </div>
            )}
          </>

      )
  }

  render() {
        const {data} = this.props;
        const product_info = data['order_data'],
            order_data = JSON.parse(data['address_1']);

        const getStatusName = OrdersStore.getStatusNameById(data['status'])
        return (
          <Box>
              <Heading size={6}>{T('order')}: {data['order_id']}</Heading>
              <div className="margin-bottom-10">
                {this.getOrders(product_info, data['currency'])}
              </div>
              <Columns>
                  <Columns.Column>
                      <Box>
                        {data['phone']}. {data['first_name']} {data['last_name']}. <br />
                        {order_data['city']}. {order_data['address_1']}. {order_data['email']}
                      </Box>
                  </Columns.Column>
                  <Columns.Column size="one-third">
                      <b>{T('status')}:</b> {getStatusName ? getStatusName : T(data['status'])} <br />
                      {this.getIsPaid(data)} <br />
                      <big>{data['discount'] ? <p><b>{T('sales-discount')}:</b> {Math.abs(data['discount'])} </p> : null}</big>
                  </Columns.Column>
              </Columns>
              
          </Box>
        );
    }

}


export default PreOrder;

