import React, { Component } from "react";
import { connectToStores } from "tools/reflux-tools";
import T from "components/i18n";
import AppStore from "app-store";
import User from "user/user-store";
import Tabs from "react-bulma-components/lib/components/tabs";
import { redirectTo } from "tools/redirect-to";
import Subscribe from "./subscribe";
import SettingsTab from "./settings-tabs/settings";
import CatalogTab from "./catalog-nw/catalog";
import IntegrationsTab from "./integrations-nw";
import PartnersTab from "./partners/partners";
import PermissionTab from "./permissions/permission";
import UserStore from "user/user-store";
import TerminalTab from "./terminal";
import DocumentSetting from "./document-settings";
import ProductsTab from "./products";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";

class Settings extends Component {
  constructor(props) {
    super(props);

    let { tab } =
      typeof props.location.state !== "undefined"
        ? this.props.location.state
        : "subscribe";

    if (!tab && props.location.pathname) {
      let l = props.location.pathname.split("/");
      if (l.some((item) => item === "catalog")) {
        tab = "catalog";
      } else {
        tab = l[l.length - 1];
      }
    }

    this.state = {
      tab: tab || "subscribe",
      new_tab: false,
    };

    this.page_tab = {
      settings: <SettingsTab />,

    };
    this.page_tab['print'] = <DocumentSetting/>
    this.page_tab["catalog"] = <CatalogTab />
    this.page_tab["products"] = <ProductsTab />
    this.page_tab['permission']= <PermissionTab />

    if (AppStore.isEnableForWhiteLable("subscribe")) {
      this.page_tab["subscribe"] = <Subscribe />;
    }

    this.page_tab["integrations"] = <IntegrationsTab />;

    if (AppStore.isEnableForWhiteLable("partners")) {
      this.page_tab["partners"] = <PartnersTab />;
    }

    if (UserStore.getModuleEnable("terminal")) {
      this.page_tab["terminal"] = <TerminalTab />;
    }
  }


  getTabs() {
    let response = [];
    for (let tab in this.page_tab) {
      if (tab === "subscribe" && !UserStore.isMainUser()) {
        continue;
      }

      if (
        tab === "permission" &&
        !UserStore.isMainUser() &&
        !UserStore.isAdmin()
      ) {
        continue;
      }

      response.push(
        <Tabs.Tab
          key={tab}
          active={this.state.tab === tab}
          onClick={() => this.changeTab(tab)}
        >
          {T(tab)}
        </Tabs.Tab>
      );
    }

    return response;
  }

  changeTab(tab) {
    this.tab = tab;
    this.new_url = "/settings/" + tab;
    this.setState({
      new_tab: true,
    });
  }
  render() {
    // const props = this.props;
    // const stepNaviganor = [this.renderStepOne, this.renderStepSecond, this.renderStepThird],
    //       currentStep = stepNaviganor[this.state.step];

    if (UserStore.getModuleEnable("terminal")) {
      this.page_tab["terminal"] = <TerminalTab />;
    } else {
      delete this.page_tab['terminal']
    }

    const tabs = this.getTabs();
    return (
      <div className="setting-page">
        <Tabs
          type="boxed"
          fullwidth={false}
          size={this.props.isMobileSize ? "small" : "medium"}
        >
          {tabs}
        </Tabs>
        {this.page_tab[this.state.tab]}

        {this.state.new_tab ? redirectTo(this.new_url, { tab: this.tab }) : ""}
      </div>
    );
  }
}

export default connectToStores(withIsMobileSize(Settings), { _: User });
