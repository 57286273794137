import React from 'react'
import s from './styles.module.scss'

const SubTitle = ({color,children}) => {
	return (
		<h3 className={`${s.sub_title} ${color ? s[color] : s.main}`}>
			{children}
		</h3>
	);
};

export default SubTitle;