import React from "react";
import BaseFilterLayout from "../../../components/base-filter-layout";
import ExpensesStore from "../../expenses/expenses-store";
import ExpensesActions from "../../expenses/expenses-actions";

const FinanceCategoryFilter = ({ search, setSearch }) => {
  const handleChangeDate = (value) => {
    const daysKey = Object.keys(value).reverse();
    daysKey.forEach((item) => {
      ExpensesActions.filter(item, value[item]);
    });
  };

  const handleSearch = ({ value, name }) => {
    if (name) {
      setSearch(value);
    }
  };

  const handleRefresh = () => {
    ExpensesStore.clearFilter()
    setSearch('')
    ExpensesActions.load()
  }

  return (
    <BaseFilterLayout
      searchStringSetting={{
        onChange: handleSearch,
        defaultValue: search,
      }}
      refresh={{
        onChange: handleRefresh,
      }}
      dateRangeSetting={{
        onChange: handleChangeDate,
        date_start: ExpensesStore.getFilter("date_start"),
        date_end: ExpensesStore.getFilter("date_end"),
      }}
      // hiddenFilter={<div></div>}
    />
  );
};

export default FinanceCategoryFilter;
