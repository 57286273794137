import React from "react";
import List from "react-bulma-components/lib/components/list";
import ProductsStore from "../../../products-store";
import FieldDesc from "../common/field-desc";
import s from "../styles.module.scss";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import User from "../../../../../user/user-store";

const AddProductRow = ({ data, units }) => {
  const comment = JSON.parse(data["comment"]);

  const midName = WarehouseStore.getWarehouseName(data?.marketplace_id || comment?.marketplace_id);

  return (
    <List.Item>
      <div><small>{data["created_at"]} </small> | <small>{User.getResponsebleName(data["responsible_user_id"])}</small></div>
      <div  className="display-flex-row-gap flex-wrap">
      <div className={s.flex_min}>
        {midName && <b>
          {midName}
        </b>} 
        {comment["comment"] !== "" ? comment["comment"] : ""}
      </div>
      <div className={s.flex_min}>
        <FieldDesc
          name="added"
          value={ProductsStore.getStockUnits(comment["total"], units)}
        />
      </div>
      <div className={s.flex_min}>
        <FieldDesc name="it-was" value={<span>{comment["old"]}</span>} />
        {", "}
        <FieldDesc name="result" value={<span>{comment["new"]}</span>} />
      </div>
      </div>
    </List.Item>
  );
};

export default AddProductRow;
