import {useEffect, useState} from "react";
import UserActions from "../../../../../user/user-actions";
import {errorHandler} from "../../../../../tools/error-handler";
import UserStore from "../../../../../user/user-store";

const IntegrationListenerHook = ({handleError, setWaiting}) => {
	const [submitOK, setSubmitOK] = useState('')
	const [additionalMessage, setAdditionalMessage] = useState('')
	const [newData, setNewData] = useState({})

	useEffect(() => {
		let listenUserProfile
		const listenAdd = UserActions.updateIntegrations.completed.listen((data) => {

			if(data?.message && typeof data.message === 'object') {
				const getLang = UserStore.getLang()
				const localesMessage = data.message[getLang || 'ua']
				if(localesMessage) {
					setAdditionalMessage(localesMessage)
				}
			}

			listenUserProfile = UserActions.loadProfile.completed.listen(() => {
				if(data && data?.id) {
					const integration = UserStore.getIntegrationById(data.id)
					if(integration) {
						setNewData(integration)
					}
				}
				setWaiting(false)
			})

			if(data?.error) {
				handleError(data.error)
			} else {
				if(data?.is_active === 0) {
					setSubmitOK('deactivate-integration-completed-title')
				} else {
					setSubmitOK('add-integration-completed-title')
				}
			}
		})

		const listenAddFailed = UserActions.updateIntegrations.failed.listen((res) => {
			setWaiting(false)
			const message = errorHandler(res)
			handleError(message)
		})

		const listenDelete = UserActions.deleteIntegration.completed.listen(() => {
			setWaiting(false)
			setSubmitOK('delete-integration-completed-title')
		})

		const listenDeleteFailed = UserActions.deleteIntegration.failed.listen((res) => {
			setWaiting(false)
			const message = errorHandler(res)
			handleError(message)
		})

		return () => {
			listenAdd()
			listenAddFailed()
			listenDelete()
			listenDeleteFailed()
			if(typeof listenUserProfile === 'function') {
				listenUserProfile()
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {submitOK, additionalMessage, newData}
}

export default IntegrationListenerHook