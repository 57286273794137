import React, {useEffect, useState} from 'react'
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import SalesStore from "../sales-store";
import errorMessageControlHook
	from "../../../components/error-message-block/error-message-control.hook";
import SalesActions from "../sales-actions";
import SelectStore from "../select-store";
import {errorHandler} from "../../../tools/error-handler";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import List from "react-bulma-components/lib/components/list";
import {
	Control,
	Field,
	Input,
	Label
} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import SubmitButton from "../../../components/submit-button";
import Money from "../../../components/money";
import ExpensesStore from "../../expenses/expenses-store";
import DOM from "react-dom-factories";
import SelectComponent from "../../../tools/select-component";
import ExpensesActions from "../../expenses/expenses-actions";

const MassFinishReserv = ({massList,onClose}) => {
	const {isMobileSize} = useIsMobile()
	const getSales = massList.map((item) => SalesStore.getSalesByOid(item));
	const list = getSales.reduce((acc, curr) => {
		acc[curr.oid] = {
			oid: curr.oid,
			amount: curr.amount_sale - curr.prepaid_amount,
			aid: {value: curr.prapaid_account_id || curr.account_id}
		}
		return acc;
	}, {});

	const {
		errorMessage,
		handleAutoRemoveError,
		ErrorMessageBlock,
		setErrorMessage,
	} = errorMessageControlHook();

	const [currentList, setCurrentList] = useState(list);
	const [waiting, setWaiting] = useState(false);
	const [massAccount, setMassAccount] = useState({value: 0, label: T('select-account')})

	const handleChangeInput = (value, oid) => {
		setCurrentList((prev) => ({
			...prev,
			[oid]: {
				...prev[oid],
				amount: value,
			},
		}));
	};

	const handleChangeSelect = (selectOption, oid) => {
		setCurrentList(prev => ({
			...prev,
			[oid]: {
				...prev[oid],
				aid: selectOption
			}
		}))
	}

	const updateAidForAll = (obj, newAid) => {
		return Object.keys(obj).reduce((acc, key) => {
			acc[key] = { ...obj[key], aid: newAid };
			return acc;
		}, {});
	};

	const handleChangeMassAccount = (selectOption) => {
		setMassAccount(selectOption);
		setCurrentList(prev => updateAidForAll(prev, selectOption));
	};

	const handleSubmit = () => {
		const submitData = Object.values(currentList).map(item => ({...item, aid: item.aid.value}))
		SalesActions.finishReserv({bulk: JSON.stringify(submitData)})
		setWaiting(true);
	};

	useEffect(() => {
		const finishReserv = SalesActions.finishReserv.completed.listen(() => {
			SelectStore.clearSelectGroup()
			onClose();
		});

		const finishReservFail = SalesActions.finishReserv.failed.listen(
			(res) => {
				setWaiting(false);
				const message = errorHandler(res);
				handleAutoRemoveError(message);
			}
		);

		return () => {
			finishReserv();
			finishReservFail();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
			<Box>
				<Heading size={4}>{T("mass-finish-reserv-label")}</Heading>
				<Heading subtitle className="has-text-danger"
								 size={isMobileSize ? 6 : 5}>
					{T('count-edit-sale', {count: getSales.length})}
				</Heading>
				<div className="margin-bottom-10">
					<SelectComponent
						name="to_aid"
						label="change-mass-account"
						epmty="select-account"
						onChange={handleChangeMassAccount}
						value={massAccount}
						list={ExpensesStore.getPersonalAccounts}
					/>
				</div>
				<List style={{overflow: 'auto', maxHeight: '70vh'}}>
					{getSales.map((item) => (
						<List.Item
							key={item.oid}
							className="display-flex-row-gap"
							style={{alignItems: "center"}}
						>
							<div>
								<span className="silver">{T('order')}: </span>
								<b>{item.order_number}</b>
								<br/>
								<span className="silver">{T('prepaid')}: </span> <Money
								aid={item.prapaid_account_id || item.account_id}
								amount={item.amount_sale - item.prepaid_amount}
								wrapper={DOM.span}/>
								<br/>
								<span
									className="silver">{T('to-account')}: </span>{T(ExpensesStore.getAccountName(item.prapaid_account_id || item.account_id))}
								<br/>
							</div>
							<Field style={{margin: 0}}>
								<Control>
									<Label>{T('need-balance')}</Label>
									<Input size="large"
												 name="amount"
												 type="number"
												 onChange={({target: {value}}) => handleChangeInput(value, item.oid)}
												 value={currentList[item.oid].amount}
									/>
								</Control>
							</Field>
							<div style={{minWidth: '30%'}}>
								<SelectComponent
									name="fromAccount"
									label="to-account"
									onChange={(selectOption) => handleChangeSelect(selectOption, item.oid)}
									value={currentList[item.oid].aid}
									list={ExpensesStore.getPersonalAccounts}
									load={ExpensesActions.loadAccounts}
								/>
							</div>
						</List.Item>
					))}
				</List>
				<Button.Group position="right">
					<SubmitButton
						text="finish-reserv-title"
						size="large"
						submit={handleSubmit}
						fullwidth={false}
						waiting={waiting}
					/>
					<Button color="light" rounded onClick={onClose} size="large">
						{T("close")}
					</Button>
				</Button.Group>
			</Box>
		</>
	);
};

export default MassFinishReserv;