import React from 'react'
import UserStore from "../../../../user/user-store";
import SelectComponent from "../../../../tools/select-component";

const CustomPrice = ({value, onChange}) => {
	const customList = UserStore.getCustomPrice()

	const list = [
		{name: 'basic-price-label', id: "Ціна"},
		...customList.map(item => ({name: item, id: item})),
	]


	return (
		<SelectComponent
			onChange={onChange}
			placeholder='custom-prices'
			isMulti
			label='custom-prices'
			className='full-size-select'
			value={value}
			list={() => list}
		/>
	);
};

export default CustomPrice;