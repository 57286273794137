//It's a very important to have variable 'messages'
//because our "lang compiler" expects such format

const messages = {
    "not-valid-date": {
        ru: "Заполните дату полностью",
        en: "Fill full date"
    },
    "calendar-today": {
        ru: "Сегодня",
        ua: "Cьогодні",
        en: "Today"
    },
    "calendar-choose": {
        ru: "Дата",
        en: "Date"
    },
    "calendar-choose-day": {
        ru: "Дата",
        en: "Date"
    },
    "calendar-choose-month": {
        ru: "Месяц",
        en: "Month"
    },
    "calendar-choose-year": {
        ru: "Год",
        ua: "Рік",
        en: "Year"
    },
    "month-1": {
        ru: "Январь",
        ua: "Cічень",
        en: "January"
    },
    "month-2": {
        ru: "Февраль",
        ua: "Лютий",
        en: "February"
    },
    "month-3": {
        ru: "Март",
        ua: "Березень",
        en: "March"
    },
    "month-4": {
        ru: "Апрель",
        ua: "Квітень",
        en: "April"
    },
    "month-5": {
        ru: "Май",
        ua: "Травень",
        en: "May"
    },
    "month-6": {
        ru: "Июнь",
        ua: "Червень",
        en: "June"
    },
    "month-7": {
        ru: "Июль",
        ua: "Липень",
        en: "July"
    },
    "month-8": {
        ru: "Август",
        ua: "Серпень",
        en: "August"
    },
    "month-9": {
        ru: "Сентябрь",
        ua: "Вересень",
        en: "September"
    },
    "month-10": {
        ru: "Октябрь",
        ua: "Жовтень",
        en: "October"
    },
    "month-11": {
        ru: "Ноябрь",
        ua: "Листопад",
        en: "November"
    },
    "month-12": {
        ru: "Декабрь",
        ua: "Грудень",
        en: "December"
    },
    'currency-1': {
        ru: "USD",
        ua: "USD",
        en: "$"
    },
    'currency-2': {
        ru: "грн.",
        ua: "грн.",
        en: "uah."
    },
    'currency-3': {
        ru: "руб.",
        ua: "руб.",
        en: "rub."
    },
    'currency-4': {
        ru: "AED",
        ua: "AED",
        en: "AED"
    },    
    'currency-5': {
        ru: "EUR",
        ua: "EUR",
        en: "EUR"
    },    
    'currency-6': {
        ru: "KZT",
        ua: "KZT",
        en: "KZT"
    },    
    'currency-7': {
        ru: "GBP",
        ua: "GBP",
        en: "GBP"
    },
    'currency-8': {
        ru: "CNY",
        ua: "CNY",
        en: "CNY"
    },
    'currency-9': {
        ru: "AZN",
        ua: "AZN",
        en: "AZN"
    },
    'currency-10': {
        ru: "CZK",
        ua: "CZK",
        en: "CZK"
    },
    'currency-11': {
        ru: "GEL",
        ua: "GEL",
        en: "GEL"
    },
    'currency-12': {
        ru: "PLN",
        ua: "PLN",
        en: "PLN"
    },    
    'currency-13': {
        ru: "TJS",
        ua: "TJS",
        en: "TJS"
    },
    'currency-14': {
        ru: "UZS",
        ua: "UZS",
        en: "UZS"
    },
    'currency-15': {
        ru: "CHF",
        ua: "CHF",
        en: "CHF"
    },    
    'currency-16': {
        ru: "UGX",
        ua: "UGX",
        en: "UGX"
    },
    currency: {
        ru: "Валюта счёта",
        ua: "Валюта рахунку",
        en: "Account Currency"
    },
    'test': {

    }
};

export default messages;
