import React, { Component } from "react";
import { connectToStores } from "tools/reflux-tools";
import T from "components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import Actions from "modules/orders/orders-actions";
import OrdersStore from "modules/orders/orders-store";
import SalesActions from "modules/sales/sales-actions";
import Store from "modules/sales/sales-store";
import SelectComponent from "tools/select-component";
import SubmitButton from "components/submit-button";
import Messages from "components/messages";
import AppStore from "../../app-store";
import AddStatusModal from "./add-status-modal";
import InfoWrapper from "../../components/info-wrapper";
import SelectStore from "./select-store";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";

class ChangeStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_status: null,
      status: { value: props.status || 0 },
      message: null,
      waiting: null,
      massList: props.massList,
      oid: props.oid,

      close: false,
      step: 0,
    };

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    // this.renderStepSecond = this.renderStepSecond.bind(this);
  }

  componentWillUnmount() {
    if (typeof this.changeStatusListen == "function") {
      this.changeStatusListen();
    }

    if (typeof this.changeStatusListenFail == "function") {
      this.changeStatusListenFail();
    }
  }

  componentDidMount() {
    const { onClose } = this.props;

    this.changeStatusListen = SalesActions.changeStatus.completed.listen(() => {
      SelectStore.clearSelectGroup()
      onClose();
    });

    this.changeStatusListenFail = SalesActions.changeStatus.failed.listen(
      (res) => {
        let data = {};

        if (res && typeof res["response"] != "undefined") {
          data = JSON.parse(res["response"]);
        } else {
          data = {
            error: "unhandled_error",
          };
        }

        this.setState({
          waiting: false,
          message: T(data["error"]),
        });
      }
    );
  }

  onChange(selectedOption, actions) {
    if (!selectedOption) return;

    this.setState({
      [actions.name]: selectedOption,
    });
  }

  submit() {
    const { oid, massList } = this.props;
    this.setState({ waiting: true });
    if (massList?.length) {
      SalesActions.changeStatus(
        JSON.stringify(massList),
        this.state.status.value
      );
    } else {
      SalesActions.changeStatus(oid, this.state.status.value);
    }
  }

  render() {
    const { onClose, massList } = this.props;
    return (
      <div>
        <Messages
          message={this.state.message}
          close={() => this.setState({ message: null })}
        />
        <Box>
          {!!massList?.length ? (
            <>
              <InfoWrapper info={T('mass-change-status-tip')}>
                <Heading size={this.props.isMobileSize ? 5 : 3} style={{marginBottom: 0}}>
                  {T('mass-edit-order-status')} <br />
                </Heading>
              </InfoWrapper>
              <Heading subtitle className="has-text-danger" size={this.props.isMobileSize ? 6 : 4}>
                {T('count-edit-sale', {count: massList.length})}
              </Heading>
            </>
          ) : (
            <Heading>{T("change-order-status")}</Heading>
          )}

          <SelectComponent
            name="status"
            onChange={this.onChange}
            value={this.state.status}
            addItem={({ name }) =>
              AppStore.openModal(
                <AddStatusModal name={name} defaultType="sales" />
              )
            }
            addItemModal={() =>
              AppStore.openModal(<AddStatusModal defaultType="sales" />)
            }
            list={() => massList?.length ? Store.getAllStatusesList(['saled', 'refund']) : Store.getAllStatusesList()}
            load={Actions.loadStatus}
            creatable={true}
          />
          <Button.Group position="centered">
            <SubmitButton
              fullwidth={false}
              text="change-order-status"
              waiting={this.state.waiting}
              submit={this.submit}
            />
            <Button
              size="large"
              rounded
              color="light"
              onClick={() => {
                onClose();
              }}
            >
              {T("close")}
            </Button>
          </Button.Group>
        </Box>
      </div>
    );
  }
}

export default connectToStores(withIsMobileSize(ChangeStatusModal), {
  orders: OrdersStore,
  sales: Store,
});
