import React, {useEffect, useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import ErrorMessageControlHook
	from "../../../components/error-message-block/error-message-control.hook";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import {Field, Input, Label} from "react-bulma-components/lib/components/form";
import Button from "react-bulma-components/lib/components/button";
import SubmitButton from "../../../components/submit-button";
import UserStore from "../../../user/user-store";
import UserActions from "../../../user/user-actions";
import {errorHandler} from "../../../tools/error-handler";

const AddCustomPrice = ({name, onClose}) => {
	const {
		errorMessage,
		setErrorMessage,
		ErrorMessageBlock,
		handleAutoRemoveError
	} = ErrorMessageControlHook()

	const [customPriceName, setCustomPriceName] = useState(name || '')
	const [waiting, setWaiting] = useState(false)

	const handleChangeValueInput = (evt) => {
		const value = evt.target.value
		setCustomPriceName(value)
	}

	const handleError = () => {
		const getList = UserStore.getCustomPrice()

		if(getList.includes(customPriceName) && !name) {
			handleAutoRemoveError('field-already-exists ')
			return false
		}
		if(!customPriceName){
			handleAutoRemoveError('not all fields required')
			return false
		}

		return true
	}

	const handleSubmit = () => {
		if(handleError()) {
			setWaiting(true)
			UserStore.setCustomPrice(customPriceName, name)
		}
	}

	useEffect(() => {
		const updateSettings = UserActions.updateSettings.completed.listen(() => {
			onClose()
		})

		const updateSettingsFailed = UserActions.updateSettings.failed.listen(res => {
			setWaiting(false)
			const message = errorHandler(res)
			handleAutoRemoveError(message)
		})

		return () => {
			updateSettings()
			updateSettingsFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading size={3}>{T('add-custom-price-label')}</Heading>
				<Field>
					<Label>{T('custom-price')}</Label>
					<Input
						value={customPriceName}
						onChange={handleChangeValueInput}
						placeholder={T('custom-price')}
					/>
				</Field>
				<Button.Group position='right'>
					<Button
						size='large'
						rounded
						color='light'
						onClick={onClose}
					>
						{T('close')}
					</Button>
					<SubmitButton fullwidth={false} size='large' text='add-custom-price-btn' waiting={waiting} submit={handleSubmit}/>
				</Button.Group>
			</Box>
		</>

	);
};

export default AddCustomPrice;