import React, { useState } from "react";
import IntegrationListenerHook from "../common/integration-listener.hook";
import { Box } from "react-bulma-components";
import InputsGroupField from "../common/inputs-group-field";
import IntegrationControlHook from "../common/integration-control.hook";
import SelectComponent from "../../../../../tools/select-component";
import FiscalizeTypeCheckbox from "../common/fiscalize-type-checkbox";
import AdditionalField from "../common/additional-field";
import ErrorMessageBlock from "../../../../../components/error-message-block/error-message-block";
import ModalItsOK from "../../../../../components/modal-its-ok";
import ActionsModal from "../common/actions-modal";
import { confirmDelete } from "../../../../../tools/confirm-delete";
import { handleDeleteIntegration } from "../utils";
import UserActions from "../../../../../user/user-actions";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";
import { getActiveType } from "../checkbox/utils";
import { parseJSON } from "../../../../../tools/error-handler";
import {
  Checkbox,
  Control,
  Field, Label
} from "react-bulma-components/lib/components/form";
import NumberInput from "../../../../../components/number-input/number-input";

const DATA_INPUT = [
  {
    name: "api_key",
    placeholder: "name",
    label: "name",
  },
  {
    name: "api_pass",
    placeholder: "token",
    label: "token",
  },
];

const SELECT_LIST_CODE_GROUP = Array.from({ length: 10 }).map((_, i) => ({
  name: `taxgrp-${i + 1}`,
  id: i + 1,
}));


const VchasnoKasa = ({ onClose, data, wooID }) => {
  const { api_key, api_pass, ident, id, is_active } = data;

  const identParse = parseJSON(ident);

  const { activeType, defaultValue } = getActiveType(
    identParse?.mid,
    identParse?.rid
  );
  const [typeFiscalize, setTypeFiscalize] = useState(activeType || ["all"]);

  const {
    inputValues,
    handleChangeInputValues,
    selectValue,
    handleChangeSelect,
    errorMessage,
    handleAutoRemoveError,
    setErrorMessage,
    checkboxValue,
    setCheckboxValue,
  } = IntegrationControlHook({
    inputDefaultValue: {
      api_key: api_key || "",
      api_pass: api_pass || "",
      position: data?.position || "",
    },
    selectDefaultValue: {
      ...defaultValue,
      taxgrp: identParse?.taxgrp ? { value: identParse.taxgrp } : false,
    },
    checkboxDefaultValue: {
      is_auto: !!identParse?.is_auto || false,
      send_check: !!identParse?.send_check || false,
      received_cod: !!identParse?.received_cod || false,
      variable_attrs: !!identParse?.variable_attrs || false
    },
  });

  const [waiting, setWaiting] = useState(false);
  const { submitOK, additionalMessage } = IntegrationListenerHook({
    handleError: handleAutoRemoveError,
    setWaiting,
  });

  const handleError = () => {
    let error = "";

    const checkedInputValue = !Object.entries(inputValues).every(
      ([key, item]) => key === 'position' || !!item
    );

    const taxgrpValue = !selectValue.taxgrp.value;

    if (checkedInputValue || taxgrpValue) {
      error = T("not all fields required");
    }

    if (error) {
      handleAutoRemoveError(error);
      return false;
    }

    return true;
  };

  const handleSubmit = ({ deactivate }) => {
    const identData = {
      taxgrp: selectValue.taxgrp.value,
      is_auto: Number(checkboxValue.is_auto),
      send_check: Number(checkboxValue.send_check),
      received_cod: Number(checkboxValue.received_cod),
      variable_attrs: checkboxValue.variable_attrs || false,
    };

    if(inputValues?.position) {
      identData.position = inputValues.position;
    }

    if (typeFiscalize.includes("all")) {
      identData.mid = [0];
      identData.rid = [0];
    } else {
      if (typeFiscalize.includes("mid")) {
        if (selectValue.mid && Array.isArray(selectValue.mid)) {
          identData.mid = selectValue.mid.map(({ value }) => value);
        }
      }
      if (typeFiscalize.includes("rid")) {
        if (selectValue.rid && Array.isArray(selectValue.rid)) {
          identData.rid = selectValue.rid.map(({ value }) => value);
        }
      }
    }



    const dataSubmit = {
      integration_id: wooID,
      api_key: inputValues.api_key,
      api_pass: inputValues?.api_pass,
      ident: JSON.stringify(identData),
      is_active: 1,
    };

    if (id) dataSubmit.id = id;
    if (deactivate) dataSubmit.is_active = 0;

    if (handleError()) {
      setWaiting(true);
      UserActions.updateIntegrations(dataSubmit);
    }
  };

  const handleChangeCheckbox = (evt) => {
    const name = evt.target.name;
    const checked = evt.target.checked;
    if (name === "received_cod" && checked) {
      setCheckboxValue({
        [name]: checked,
        is_auto: checked,
      });
    } else {
      setCheckboxValue((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      {!submitOK && (
        <Box>
          <Heading size={3}>
            {T("setting-integration", [T(`integration-${wooID}`)])}
          </Heading>
          <InputsGroupField
            inputValues={inputValues}
            dataInput={DATA_INPUT}
            onChange={handleChangeInputValues}
          />
          <Field>
            <Label>{T('position')}</Label>
            <Control>
              <NumberInput value={inputValues.position} name='position' onChange={handleChangeInputValues} placeholder={T('position')} />
            </Control>
          </Field>
          <SelectComponent
            name="taxgrp"
            label="tax-group-codes"
            placeholder="tax-group-code-placeholder"
            onChange={handleChangeSelect}
            value={selectValue.taxgrp}
            list={() => SELECT_LIST_CODE_GROUP}
            creatable={false}
          />
          <FiscalizeTypeCheckbox
            type={typeFiscalize}
            onChange={setTypeFiscalize}
          />

          {typeFiscalize?.map((item) => (
            <AdditionalField
              key={item}
              type={item}
              value={selectValue && selectValue[item]}
              onChange={handleChangeSelect}
            />
          ))}
          <Field className="margin-top-20">
            <Control>
              <Checkbox
                name="variable_attrs"
                onChange={handleChangeCheckbox}
                checked={checkboxValue.variable_attrs}
              >
                {T("prro-variable-attrs")}
              </Checkbox>
            </Control>
          </Field>
          <Field className="margin-top-20">
            <Control>
              <Checkbox
                name="received_cod"
                onChange={handleChangeCheckbox}
                checked={checkboxValue.received_cod}
              >
                {T("fiscalization-on-delivery")}
              </Checkbox>
            </Control>
          </Field>

          <Field className="margin-top-20">
            <Control>
              <Checkbox
                name="is_auto"
                onChange={handleChangeCheckbox}
                checked={checkboxValue.is_auto}
                disabled={checkboxValue.received_cod}
              >
                {T("auto-send-to-checkbox")}
              </Checkbox>
            </Control>
          </Field> 

          <Field className="margin-top-20">
            <Control>
              <Checkbox
                name="send_check"
                onChange={handleChangeCheckbox}
                checked={checkboxValue.send_check}
              >
                {T("vchastno-alert-client")}
              </Checkbox>
            </Control>
          </Field>

          <ActionsModal
            onSubmit={handleSubmit}
            onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
            onClose={onClose}
            isEdit={!!id}
            isActive={is_active}
            waiting={waiting}
          />
        </Box>
      )}
      {submitOK && (
        <ModalItsOK
          onClose={onClose}
          title={submitOK}
          additionalMessage={additionalMessage}
        />
      )}
    </>
  );
};

export default VchasnoKasa;
