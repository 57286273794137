import React, {useEffect, useState} from "react";
import Box from "react-bulma-components/lib/components/box";
import Card from "react-bulma-components/lib/components/card";
import Money from "../../../../components/money";
import ClientsStore from "../../clients-store";
import Columns from "react-bulma-components/lib/components/columns";
import T from "../../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import DebtLink from "../../report-row/debt-link";
import UserStore from "../../../../user/user-store";
import Content from "react-bulma-components/lib/components/content";
import Tag from "../../../../components/tag";
import ClientDeliveryList from "../../common/client-delivery-list";
import SalesReportClient from "../../common/sales-report/sales-report-client";
import { Link } from "react-router-dom";
import { Button } from "react-bulma-components";
import CommunicationClient from "../../../../components/communication-client";
import {connectToStores} from "../../../../tools/reflux-tools";
import ChatsStore from "../../../chats/chats-store";
import GoToChatClient from "../../../../components/go-to-chat-client";
import AppStore from "../../../../app-store";
import AddClientModal from "../add-client-modal/add-client-modal";
import AddSalesModal from "../../../sales/add-sales-modal";
import ClientsActions from "../../clients-actions";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";
import BinotelCreateCall from "./binotel-create-call";
import SocialRender from "./social-render";
import RingostatCreateCall from "./ringostat-create-call";

const keyMap = {
  birth_day: {
    label: "client-birhday",
  },
  birth_day_child_1: {
    label: "client-birth_day_child_1",
  },
  birth_day_child_2: {
    label: "client-birth_day_child_2",
  },
  comment: {
    label: "comment",
  },
  company: {
    label: "company",
  },
  email: {
    label: "E-mail",
  },
  invoice: {
    label: "data-for-invoice",
  },
  nickname: {
    label: "nickname",
  },
  phone: {
    label: 'phone'
  },
  address: {
    label: "client-address",
  },
  city: {
    label: 'city'
  }
};

const DTO_client = (data) => {
  const keyMapKeys = Object.keys(keyMap);

  const filterValues = keyMapKeys.filter((item) => {
    if(item === 'city') {
      if(!data['address']) {
        return data[item]
      } else {
        return false
      }
    }
    return data[item]
  });

  return filterValues.map((item) => {
    let value = data[item];
    const label = keyMap[item].label;

    if (
      item === "birth_day" ||
      item === "birth_day_child_1" ||
      item === "birth_day_child_2"
    ) {
      value = value.split(" ")[0];
    }

    if(item === 'phone') {
      value = `+${value}`
    }

    if(item === 'address') {
      value = `${data.city ? `${data.city}, ` : ''}${value}`
    }

    return {
      label,
      value,
    };
  });
};

const ClientViewModal = ({ clientID, onClose }) => {
  const {isMobileSize} = useIsMobile()
  const data = ClientsStore.getClientById(clientID);

  const tagsGroup = ClientsStore.getGroupClientTagByClientId(clientID);

  const additionalInfo = DTO_client(data);
  const getClientChat = ChatsStore.getChatByClientID(clientID)

  const [getCountClients, setGetCountClients] = useState(ClientsStore.getClientCountById(clientID))

  const handleOpenEditClient = () => {
    onClose()
    AppStore.openModal(<AddClientModal
      edit={true}
      data={ClientsStore.getClientById(clientID)}
      client_id={clientID}
    />)
  }

  const handleAddSale = () => {
    AppStore.openModal(<AddSalesModal client_id={clientID} modal='sale-modal'/>)
    onClose()
  }

  useEffect(() => {
    ClientsActions.loadClientsCount([clientID])

    const clientsCountLoad = ClientsActions.loadClientsCount.completed.listen(() => {
      setGetCountClients(ClientsStore.getClientCountById(clientID))
    })

    return () => {
      clientsCountLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Card>
      <Card.Header
        style={{ position: "sticky", top: 0, background: "inherit" }}
      >
        <Card.Header.Title>
          <Heading size={4} className='display-flex-row-gap flex-wrap' style={{width: "100%", columnGap: '20px'}}>
              {data.first_name + " " + data.last_name}{' '}
            <span className='display-flex-row' style={{display: 'inline-flex'}}>
              {data.phone && `+${data.phone}`}
              <span style={isMobileSize ? {marginRight: '44px'}: {}}>
                {getClientChat ? (
                  <GoToChatClient chatID={getClientChat} onClose={onClose}/>
                ) :
                  <CommunicationClient phone={data.phone} nickname={data.nickname} style={{width: 'fit-content'}}/>
                }
              </span>
              {!!UserStore.getBinotelList().length && (UserStore.getMoreSetting('internalNumber') || UserStore.getAdminInternalNumberBinotel()) && <BinotelCreateCall phone={data.phone}/>}
              {data.phone && !!UserStore.getRingostatList().length && UserStore.isRingostatByUser() && <RingostatCreateCall phone={data.phone}/>}
            </span>
          </Heading>
        </Card.Header.Title>
      </Card.Header>
      <Card.Content>
        <Columns className="is-mobile product-mobile-block">
          <Columns.Column style={{minWidth: '130px'}}>
            <Box className="small-number-box">
              <b>{T("sales-discount")}</b>: <br />
              {data["discount"] || 0}%
            </Box>
          </Columns.Column>
          <Columns.Column style={{minWidth: '130px'}}>
            <Box className="small-number-box">
              <b>{T("purchased")}</b>:{" "}
              <Money amount={data["total_amount"] || 0} />
            </Box>
          </Columns.Column>
          <Columns.Column style={{minWidth: '130px'}}>
            <Box className="small-number-box">
              <b>{T("clients-debt")}</b>:{" "}
              {data["debt"] > 0 ? (
                DebtLink(data)
              ) : (
                <Money amount={data["debt"] || 0} />
              )}
            </Box>
          </Columns.Column>
          <Columns.Column style={{minWidth: '130px'}}>
            <Box className="small-number-box">
              <b>{T("clients-balance")}</b>:
              <Money
                amount={data["balance"]}
                currency={UserStore.get("currency_id")}
              />
            </Box>
          </Columns.Column>
          {UserStore.isIntegrationEnable() && <Columns.Column style={{minWidth: '130px'}}>
            <Box className="small-number-box">
              <b>{T("canceled-orders")}</b>:{" "}
              <div>
                {getCountClients.cancelled_remote_orders_count || 0}
              </div>
            </Box>
          </Columns.Column>}
          <Columns.Column style={{minWidth: '130px'}}>
            <Box className="small-number-box">
              <b>{T("quantity-returns")}</b>:{" "}
              <div>
                {getCountClients.is_refund_orders_count || 0}
              </div>
            </Box>
          </Columns.Column>
          <Columns.Column style={{minWidth: '130px'}}>
            <Box className="small-number-box">
              <b>{T("number-purchases")}</b>:{" "}
              <div>
                {getCountClients.non_delete_orders_count || 0}
              </div>
            </Box>
          </Columns.Column>
        </Columns>
        <hr />
        <SocialRender clientID={clientID}/>
        <Content style={{marginBottom: 0}}>
          <ClientDeliveryList clientID={clientID} name={data?.name} phone={data?.phone} />
          <div className="client-view-content">
            {!!tagsGroup.length && (
              <span>
                <b>{T("clients-tags")}: </b>
                {tagsGroup.map((item) => (
                  <Tag
                    key={`tag-${item.id}`}
                    color={item.color}
                    name={item.name}
                  />
                ))}
              </span>
            )}
            {!!additionalInfo.length &&
              additionalInfo.map((item) => item.label === 'E-mail' ? (
                <span key={item.label}>
                  <b>{T(item.label)}</b>: <a href={`mailto:${item.value}`}>{item.value}</a>
                </span>
              ) : (
                <span key={item.label}>
                  <b>{T(item.label)}</b>: {item.value}
                </span>
              ))}
          </div>
          <SalesReportClient clientID={clientID} />
        </Content>
      </Card.Content>
      <Card.Footer style={{justifyContent: "space-between",position: 'sticky', bottom: 0, background: 'inherit'}}>
        <div style={{padding: 10}} className='display-flex-row'>
          <Button size='medium' color='warning' onClick={handleAddSale}>
            {T('add-sale-btn')}
          </Button>
        </div>
        <div
          style={{padding: 10}}
          className='display-flex-row'
        >
          <Button
            color='success'
            size='medium'
            onClick={handleOpenEditClient}
          >
            {T('btn-edit')}

          </Button>
          <Button renderAs={Link} size='medium' to="#" onClick={() => onClose()}>
            {T("close")}
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default connectToStores(ClientViewModal, {_: ChatsStore});
