import React from "react";
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import T from "../../../components/i18n";
import User from "../../../user/user-store";
import { Link } from "react-router-dom";
import AppStore from "../../../app-store";
import Button from "react-bulma-components/lib/components/button";
import BindingProduct from "../binding-product";
import ProductsStore from "modules/products/products-store";
import { bindingSubmit } from "../common/actions-func";
import AddProductsModal from "../../products/modal/add-product/add-products-modal";
import ViewProductModal from "../../products/modal/view-product/view-product-modal";
import OrdersStore from "../orders-store";
import OrdersActions from "../orders-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {confirmDelete} from "../../../tools/confirm-delete";
import UserStore from "../../../user/user-store";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import OrdersActions from "../orders-actions";

const OrderSubRowDesktop = ({
  id,
  product_id,
  name,
  quantity,
  price,
  currency,
  pid,
  oid,
  integrationID,
  status,
  order_id,
  ...rest
}) => {
  const img = "/bimages/get/64-" + User.get("id") + "-" + pid + "-0.jpg";

  const nameRender = pid ? (
    <Link
      to="#"
      onClick={() => AppStore.openModal(<ViewProductModal pid={pid} />)}
    >
      {ProductsStore.getProductName(pid, true)}
    </Link>
  ) : (
    name
  );

  const handleBindingProduct = () => {
    AppStore.openModal(
      <BindingProduct
        data={{ name, remote_id: product_id, integration_id: integrationID }}
        submit={bindingSubmit}
      />
    );
  };

  const handleCreateProduct = () => {
    const integrationInfo = UserStore.getIntegrationById(integrationID);

    AppStore.openModal(<AddProductsModal name={name} vendorCode={rest?.sku || null} price={parseFloat(price)} mid={integrationInfo.ident.mid}/>);
  };

  const is_reserved = OrdersStore.getIsReserveOrdersByOidPid(oid, pid);

  const handleDeleteReserveProduct = () => {
    if(is_reserved) {
      const message = <div>{pid ? ProductsStore.getProductName(pid, true) : name} <b>{quantity} {T("items-p")}</b></div>
      confirmDelete(() => OrdersActions.deleteReserve([{pid: is_reserved.product_id, mid: is_reserved.marketplace_id, roid: order_id }]), 'confirm-delete-reserve', message, 'confirm-order-shift', 'cancel-label')
    }
  }

  return (
    <List.Item>
      <Columns vCentered>
        <Columns.Column size={1}>
          <img
            className="order-img"
            alt={name}
            src={img}
            style={{ margin: 0 }}
          />
        </Columns.Column>
        <Columns.Column align="left">
          {nameRender}
          <br />
          {is_reserved && status !== "completed" && (
            <div className='display-flex-row'>
              <b>{T("status-reserve_sale")}</b>
              <Button onClick={handleDeleteReserveProduct} className='button-link-icon' title={T('remove-reserv')}>
                <FontAwesomeIcon icon='undo'/>
              </Button>
            </div>
          )}
          {!pid && <b className="has-text-danger">{T("not-sync-product")}</b>}
        </Columns.Column>
        <Columns.Column size="one-fifth">
          <span className='silver'>{T("netprice-price")}: </span><b>{price} {currency}</b>
          <br />
          <span className='silver'>{T("quantity")}: </span><b>{quantity} {T("items-p")}</b>
        </Columns.Column>
        <Columns.Column align="right" size={3}>
          <Button.Group>
            {!pid && (
              <Button
                size="small"
                rounded
                color="info"
                onClick={handleBindingProduct}
              >
                {T("binding-products")}
              </Button>
            )}
            {!pid && (
              <Button
                size="small"
                rounded
                color="success"
                onClick={handleCreateProduct}
              >
                {T("add-new-product-btn")}
              </Button>
            )}
          </Button.Group>
        </Columns.Column>
      </Columns>
    </List.Item>
  );
};

export default OrderSubRowDesktop;
