import React from "react";
import AppStore from "../../../app-store";
import LinkAccountToTerminalModal from "./modal/link-account-to-terminal-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TerminalStore from "./terminal-store";
import ExpensesStore from "../../expenses/expenses-store";

const TerminalRow = ({ data }) => {
  const handleLinkAccountToTerminal = (terminalData) => {
    AppStore.openModal(
      <LinkAccountToTerminalModal
        modal="link-account-terminal"
        terminalData={terminalData}
      />
    );
  };

  const connectedAccountInfo = TerminalStore.getLinkAccountToTerminalById(
    data?.id
  );

  const accountName = ExpensesStore.getAccountName(
    connectedAccountInfo?.account_id
  );

  return (
    <tr>
      <td>{data.name}</td>
      <td>{data.model}</td>
      <td>
        {accountName ? (
          <b className="text-success">{accountName}</b>
        ) : (
          <span>-</span>
        )}
      </td>
      <td>
        <span
          onClick={() => handleLinkAccountToTerminal(data)}
          className="button_link cursor-pointer"
        >
          <FontAwesomeIcon icon="edit" />
        </span>
      </td>
    </tr>
  );
};

export default TerminalRow;
