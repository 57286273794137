import { createActions } from "tools/reflux-tools";


const ShipmentsActions = createActions({
    add: { asyncResult: true },
    delete: { asyncResult: true },
    addSupplier: { asyncResult: true },
    loadSuppliers: { asyncResult: true },
    addDeliveryService: { asyncResult: true },
    loadDelivery: { asyncResult: true },
    loadExcel: { asyncResult: true },
    loadByPid: { asyncResult: true },
});

export default ShipmentsActions;