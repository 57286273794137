import React from "react";
import Box from "react-bulma-components/lib/components/box";
import WriteOffInfoTitleBlock from "../UI/write-off-info-title-block";
import AddWriteOffList from "../add-write-off-list/add-write-off-list";
import ButtonBack from "../UI/button-back";
import Button from "react-bulma-components/lib/components/button";
import InfoBlockQuantityAmount from "../../../../components/info-block-quantity-amount";

const StepOnlyShow = ({productsData,mid, step,allQuantity, amount, onlyShow, onChangeQuantity, onRemove}) => {
	return (
		<Box>
			<WriteOffInfoTitleBlock mid={mid.value}/>
			<AddWriteOffList
				data={productsData}
				changeQuantity={onChangeQuantity}
				removeProduct={onRemove}
				onlyShow={onlyShow}
				step={step}
			/>
			<Button.Group position="centered" className='margin-top-10'>
				<ButtonBack/>
			</Button.Group>
			<InfoBlockQuantityAmount lengthProduct={productsData?.length} allQuantity={allQuantity} amount={amount} title='total-products'/>
		</Box>
	);
};

export default StepOnlyShow;