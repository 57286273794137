import React, {useEffect, useState} from 'react'
import {handleDeleteIntegration, parseObj} from "../utils";
import IntegrationControlHook from "../common/integration-control.hook";
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";
import InputsGroupField from "../common/inputs-group-field";
import {Box} from "react-bulma-components";
import IntegrationListenerHook from "../common/integration-listener.hook";
import ModalItsOK from "../../../../../components/modal-its-ok";
import UserActions from "../../../../../user/user-actions";
import ActionsModal from "../common/actions-modal";
import {confirmDelete} from "../../../../../tools/confirm-delete";
import UserStore from "../../../../../user/user-store";
import {connectToStores} from "../../../../../tools/reflux-tools";
import User from "../../../../../user/user-store";
import SelectComponent from "../../../../../tools/select-component";

const DATA_INPUT = [
	{
		name: "api_key",
		label: "Project id"
	},
	{
		name: "api_pass",
		label: 'Auth-key'
	},
]

const Ringostat = ({data, wooID ,onClose}) => {
	const {api_key, api_pass, id:idData, is_active, ident} = data
	let identParse = typeof ident === 'string' ? parseObj(ident) : ident

	const workersData = JSON.parse(JSON.stringify(identParse?.workers || {}))

	if(workersData) {
		Object.keys(workersData).forEach(item => {
			workersData[item].rid = workersData[item].rid ? {value: workersData[item].rid} : false
		})
	}
	const [id, setId] = useState(idData)

	const [waiting, setWaiting] = useState(false)
	const [isFinish, setIsFinish] = useState(false)
	const [workers, setWorkers] = useState(workersData || {})

	const {
		handleAutoRemoveError,
		setErrorMessage,
		errorMessage,
		inputValues,
		handleChangeInputValues,
	} = IntegrationControlHook({
		inputDefaultValue: {
			api_key: api_key || null,
			api_pass: api_pass || null,
		},
	})

	const {submitOK, additionalMessage} = IntegrationListenerHook({handleError: handleAutoRemoveError, setWaiting})

	const handleSubmit = ({deactivate}) => {
		const data = {
			"integration_id": wooID,
			"api_key": inputValues.api_key,
			"api_pass": inputValues?.api_pass,
			is_active: 1,
			name: inputValues.name || ''
		}

		if(id) data.id = id
		if(deactivate) data.is_active = 0

		if(Object.keys(workers).length) {
			const submitWorkers = JSON.parse(JSON.stringify(workers))

			Object.keys(submitWorkers).forEach(item => {
				submitWorkers[item].rid = submitWorkers[item].rid ? submitWorkers[item].rid.value : null
			})

			data.ident = JSON.stringify({...identParse, workers: submitWorkers})
		}

		setWaiting(true)
		UserActions.updateIntegrations(data)
	}

	useEffect(() => {
		let loadProfile

		const listenAdd = UserActions.updateIntegrations.completed.listen((res) => {
			loadProfile = UserActions.loadProfile.completed.listen(() => {
				if(Object.keys(workers).length) {
					setIsFinish(true)
				}

				const integration = UserStore.getRingostatIntegrationById(res.id)

				if(integration?.ident?.workers) {
					const workersData = JSON.parse(JSON.stringify(integration?.ident?.workers))

					if(workersData) {
						Object.keys(workersData).forEach(item => {
							workersData[item].rid = workersData[item].rid ? {value: workersData[item].rid} : false
						})
					}
					setWorkers(integration?.ident?.workers)
					setId(res.id)
				}
			})
		})

		return () => {
			listenAdd()
			loadProfile && loadProfile()
		}
	}, [workers]);

	useEffect(() => {
		if(submitOK === 'delete-integration-completed-title') {
			setIsFinish(true)
		}
	}, [submitOK])

	let list = User.getSubUsersList();


	const handleChangeWorkers = (selectValue, workerId) => {
		setWorkers(prev => ({
			...prev,
			[workerId]: {
				...prev[workerId],
				rid: selectValue,
			}
		}))
	}


	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!isFinish && <Box>
				<Heading size={3}>
					{T('setting-integration', [T(`integration-${wooID}`)])}
				</Heading>
				<InputsGroupField
					inputValues={inputValues}
					dataInput={DATA_INPUT}
					onChange={handleChangeInputValues}
				/>
				{Object.keys(workers || {}).map(item => <div key={item}>
					<SelectComponent
						name="rid"
						label={<span>{T('operator')}: {workers[item].fio}</span>}
						placeholder='operator'
						onChange={(selectValue) => handleChangeWorkers(selectValue, workers[item].workerID)}
						value={workers[item].rid ? workers[item].rid : false}
						list={() => list}
					/>
				</div>)}
				<ActionsModal
					onSubmit={handleSubmit}
					onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
					onClose={onClose}
					isEdit={!!id}
					isActive={is_active}
					waiting={waiting}/>
			</Box>}
				{isFinish && <ModalItsOK onClose={onClose} title={submitOK}
																 additionalMessage={additionalMessage}/>}
		</>
	);
};

export default connectToStores(Ringostat, {UserStore});