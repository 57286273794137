import React, {useCallback, useEffect,  useState} from "react";
import { Textarea } from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import s from "./styles.module.scss";
import { LocalIcon } from "whitelables/wl-name/icons";
import ChatsActions from "../../chats-actions";
import FileInput from "./clip-file-input";

const SendField = ({ chatId, triggerScrollDown }) => {
  const [message, setMessage] = useState("");
  const [disabledSend, setDisabledSend] = useState(false)

  useEffect(() => {
    return () => setMessage("");
  }, []);

  const handleChange = ({ target: { value } }) => {
    setMessage(value);
  };

  const handleSubmit = useCallback(() => {
    if (message === "") {
      return false;
    }

    let data = {
      message: message,
      chat_id: chatId,
    };
    ChatsActions.add(data);
    triggerScrollDown();
    setMessage("");
  }, [message, chatId, triggerScrollDown]);

  useEffect(() => {
    const handlePressKeyEnter = (event) => {
      if (!event) return;
      const ENTER_KEY = 13;
      if (event.keyCode === ENTER_KEY && !event.shiftKey) {
        handleSubmit();
        event.preventDefault();
        event.stopPropagation();
      }
    };
    if(disabledSend) {
      document.removeEventListener("keydown", handlePressKeyEnter);
    } else {
      document.addEventListener("keydown", handlePressKeyEnter);
    }


    return () => {
      document.removeEventListener("keydown", handlePressKeyEnter);
    };
  }, [handleSubmit, disabledSend]);

  const [blobFilesInPaste, setBlobFilesInPaste] = useState()

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;

    for (const item of items) {
      if (item.type.indexOf('image') !== -1) {
        const blob = item.getAsFile();
        setBlobFilesInPaste(blob)
      }
    }
  }

  return (
    <div className={s.send_field}>
      <FileInput
        chatId={chatId}
        blobFilesInPaste={blobFilesInPaste}
        message={message}
        onChangeMessage={setMessage}
        onDisabledSend={setDisabledSend}
      />
      <Textarea
        className="textarea-chat"
        placeholder={T("type-message-here")}
        name="message"
        onChange={handleChange}
        value={message}
        onPaste={handlePaste}
        autoFocus
      />
      <div onClick={handleSubmit} className="cursor-pointer">
        <LocalIcon icon="send" size="large" />
      </div>
    </div>
  );
};

export default SendField;
