import React from 'react'
import BaseFilterLayout from "../../components/base-filter-layout";
import ChatsFilters from "./chats-filters";
import ChatsActions from "./chats-actions";
import ChatsStore from "./chats-store";

const FilterPanel = () => {
	const handleRefresh = () => {
		ChatsActions.load(true);
		ChatsStore.clearFilter()
	}

	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value).reverse()
		daysKey.forEach(item => {
			ChatsActions.filter(item, value[item]);
		})
	}

	const handleChangeSearch = ({name, value}) => {
		if(name) {
			ChatsActions.filter(name, value);
		}
	}

	return <BaseFilterLayout
		searchStringSetting={{
			onChange: handleChangeSearch,
			defaultValue: ChatsStore.getFilter("search"),
		}}
		refresh={{
			onChange: handleRefresh
		}}
		dateRangeSetting={{
			onChange: handleChangeDate,
			date_start: ChatsStore.getFilter("date_start"),
			date_end: ChatsStore.getFilter("date_end"),
		}}
		hiddenFilter={<ChatsFilters/>}
	/>
};

export default FilterPanel;