import React from 'react';

class ScrollToTopOnMount extends React.Component {
  constructor(props) {
    super(props)
    this.childDiv = React.createRef()
  }

  componentDidMount = () => this.handleScroll()

  componentDidUpdate = () => this.handleScroll()

  handleScroll = () => {
    const { index, selected } = this.props
    if (index === selected) {
      setTimeout(() => {
        if (this.childDiv.current) {
          this.childDiv.current.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    }
  }

  render() {
    return <div id="scroll-top-1" ref={this.childDiv}></div> 
  }
}

export default ScrollToTopOnMount;