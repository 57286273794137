import React, { Component } from 'react';

// import T from "components/i18n";
import Heading from 'react-bulma-components/lib/components/heading';
import Content from 'react-bulma-components/lib/components/content';
import InfoImage from "public/common/image";


class SalesInfo extends Component {

    render () {
        return (
        		<Content>
        			<Heading>Добавление продажи</Heading>

					<p>Добавить первую продажу можно тремя способами:</p>
					<ul>
						<li>нажав кнопку “Добавить продажу” в верхней части страницы</li>
						<li>зайдя во вкладку “Продажи” и нажав кнопку “Добавить продажу” внизу страницы</li>
						<li>зайдя во вкладку “Товары”, выбрать нужный товар и нажать кнопку “Добавить продажу”</li>
						<li>сосканировав штрих-код товара с помощью штрих-код ридера или мобильного приложения HugeProfit, после чего на сайте откроется окно добавления продажи, если товар уже занесен в базу, или окно добавления товара, если в базе он отсутствует.</li>
					</ul>
					<InfoImage text="Добавление продажи" link="/screen/8.jpg" />

					<Heading>Удаление продажи</Heading>
					<p>Для удаления продажи необходимо нажать кнопку с изображением корзины, которая расположена сразу после столбца “Маржа”. Система попросит подтверждение действия, после чего надо нажать кнопку “Удалить”. </p>
					<InfoImage text="Удаление продажи" link="/screen/16.jpg" />

					<Heading>Три вида продажи</Heading>
					<p>В нашей системе предусмотрено 3 вида продажи:</p>
					<ul>
						<li><b>Продажа по факту</b>. Когда Вы просто делаете продажу с помощью сканера штрих-кода или вручную. </li>
						<li><b>Продажа с доставкой</b>. Если проданый товар Вам нужно отправить через почту, Вы можете оформить продажу с доставкой. </li>
						<li><b>Резерв товара</b>. Если клиент попросил зарезервировать для него какой-то товар, Вы можете отобразить это в системе.</li>
					</ul>
					<p>Подробнее о последних двух видах читайте в одноименных разделах. </p>

					<Heading>Доставка/Наложенный платеж (Продажа с доставкой)</Heading>
					<Heading size={4}>Процесс доставки</Heading>
					<p>После того, как вы оформите доставку товара, во вкладке “Продажи” в общую таблицу добавятся строки с пометкой “Отправить” (скрин 34). Также можно посмотреть таблицу с товарами на доставку во вкладке “Продаж” “На отправку”. (скрин 35)</p>
       		</Content>
			)}

      }

export default SalesInfo;