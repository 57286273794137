import React from "react";
import { Box, Button } from "react-bulma-components";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../components/i18n";
import TerminalContentMobile from "../common/terminal-content-mobile";
import Reports from "../../../../components/reports";
import EmptyMessageTerminalList from "./empty-message-terminal-list";
import TerminalStore from "../terminal-store";
import TerminalRow from "../terminal-row";
import TerminalActions from "../terminal-actions";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";
const COLUMNS = [
  "name",
  "model",
  "account-related",
  "reports-actions",
];

const TerminalListTab = () => {
  const {isMobileSize} = useIsMobile()
  const checkboxTerminalList = TerminalStore.getList();

  const handleReload = () => {
    TerminalActions.load();
  };

  return (
    <Box>
      <Heading>{T("checkbox-list-terminal-label")}</Heading>
      <div align="right">
        <Button size="small" rounded color="info" onClick={handleReload}>
          {T("reload-terminal")}
        </Button>
      </div>
      {isMobileSize ? (
        <TerminalContentMobile list={checkboxTerminalList} />
      ) : (
        <Reports
          emptyMessage={EmptyMessageTerminalList}
          columns={COLUMNS}
          hideFooterOnEmpty
          isLoading={TerminalStore.get("isLoading")}
          rows={checkboxTerminalList}
        >
          <TerminalRow role="row" />
        </Reports>
      )}
    </Box>
  );
};

export default TerminalListTab;
