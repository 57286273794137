import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bulma-components";
import { confirmDelete } from "../../../../tools/confirm-delete";
import AppStore from "../../../../app-store";
import ProductsActions from "../../../products/products-actions";
import AddAttrProductModal from "../../../products/modal/add-attr-product/add-attr-product-modal";
import T from "../../../../components/i18n";

const RowDesktop = ({ data }) => {
  return (
    <tr>
      <td style={{ width: "80%" }}>
        {data.is_global ? T(data.name) : data.name}
      </td>
      <td>
        {!data.is_global && (
          <div className="display-flex-row">
            <Button
              className="button-link-without-style"
              onClick={() =>
                AppStore.openModal(
                  <AddAttrProductModal modal="add-attr" {...data} />
                )
              }
            >
              <FontAwesomeIcon icon="edit" />
            </Button>
            <Button
              className="button-link-without-style"
              onClick={() =>
                confirmDelete(
                  () => ProductsActions.deleteAttr(data.id),
                  "confirm-delete",
                  "are-you-sure-to-delete-this-catalog"
                )
              }
            >
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
};

export default RowDesktop;
