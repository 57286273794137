import React from "react";
const loc = window.location;

let url = "";

if (loc.host === "localhost:3000") {
	url = "https://ac1.h-profit.com/";
}

const MessageItemVideo = ({ data }) => {

	return (
		<div>
			<video style={{maxHeight: '350px'}} controls src={`${url}${data.url}&type=video`} preload="metadata" poster={`${url}${data.url}`}/>
		</div>
	);
};

export default MessageItemVideo;