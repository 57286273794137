import React from 'react'
import Image from "react-bulma-components/lib/components/image";

const ImageModal = ({src, alt}) => {
	return (
		<div>
			<Image src={src} alt={alt} />
		</div>
	);
};

export default ImageModal;