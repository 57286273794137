import React from 'react'
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import T from "../../components/i18n";
import {getWriteOffName} from "./utils/getWriteOffName";
import WarehouseStore from "../warehouse/warehouse-store";
import User from "../../user/user-store";
import Heading from "react-bulma-components/lib/components/heading";
import NumberBlock from "../../components/number-block";
import Level from "react-bulma-components/lib/components/level";
import RegistrationButton from "./buttons/registration-button";
import EditButton from "./buttons/edit-button";
import DeleteButton from "./buttons/delete-button";
import RevertButton from "./buttons/revert-button";
import PrintButton from "./buttons/print-button";
import WriteOffStore from "./write-off-store";

const WriteOffRowMobile = ({data, onDelete}) => {
	const {id, from_marketplace_id, created_at, status, responsible_user_id, quantity, amount, currency, comment, category_id } = data

	const handleDelete = () => {
		onDelete(data)
	}

	return (
		<tr>
			<td colSpan="5">
				<Heading size={6} className="margin-top-10">
					{getWriteOffName({id, created_at})}
					<b className="has-text-success">({T(status)})</b>
				</Heading>
				<List>
					<List.Item>
						<b>{T("from-product-marketplace")}:</b> {T(WarehouseStore.getWarehouseName(from_marketplace_id))}
					</List.Item>
					{category_id && <List.Item>
						<b>{T("write-off-category")}:</b> {WriteOffStore.getNameWriteOffCategoryById(category_id)}
					</List.Item>}
					{comment && <List.Item>
						<b>{T("comment")}:</b> {comment}
					</List.Item>}
					<List.Item>
						<b>{T("responseble")}:</b> {User.getResponsebleName(responsible_user_id)}
					</List.Item>
				</List>
				<Columns className="is-mobile product-mobile-block">
					<Columns.Column size="half">
						<NumberBlock top='write-off-label' number={quantity || 0}  bottom="units" className="small-number-box" />
					</Columns.Column>
					<Columns.Column>
						<NumberBlock top="expected_goods_worth_short" number={amount || 0} bottom={User.getCurrency(currency)} money={true} className="small-number-box" />
					</Columns.Column>
				</Columns>
				<Level renderAs="nav"  breakpoint="mobile">
					<Level.Side align="left">
						<RegistrationButton status={status} data={data}/>
						<div className='write-off-mobile-actions'>
									{status === 'pending' ? (
										<>
											<EditButton data={data}/>
											<DeleteButton onClick={handleDelete}/>
										</>
									) : <RevertButton data={data}/>}
									<PrintButton />
						</div>
					</Level.Side>
				</Level>
			</td>
		</tr>
	);
};

export default WriteOffRowMobile;