import React, { useState } from "react";
import GeneralRowContent from "./general-row-content";
import GeneralSubReports from "../common/general-sub-reports";
import RadioButtonListControl from "../../../../components/radio-buttons-list/radio-button-list-control";
import SelectComponent from "../../../../tools/select-component";
import ClientsStore from "../../../clients/clients-store";
import SalesStore from "../../../sales/sales-store";
import T from "../../../../components/i18n";
import WarehouseStore from "../../../warehouse/warehouse-store";

const list = [
  {
    title: "fiscalize-warehouse",
    value: "mid",
  },
  {
    title: "clients",
    value: "clients",
  },
  {
    title: "sale-channel",
    value: "channels",
  },
];

const GeneralRowDesktop = ({ data, groupDate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState("mid");
  const [filter, setFilter] = useState({
    mid: [{ value: 0, label: T("all") }],
    clients: [{ value: 0, label: T("all") }],
    channels: [{ value: 0, label: T("all") }],
  });

  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleChangeActiveFilter = ({ value }) => {
    setIsActiveFilter(value);
  };

  const clientsListForSelect = Object.values(data.clients || {}).map((item) => {
    const client = ClientsStore.getClientById(item.name);

    return {
      name: client.name,
      id: item.name,
    };
  });
  const channelsListForSelect = Object.values(data.channels || {}).map(
    (item) => {
      const channelName = SalesStore.getChannelsName(item.name);
      return {
        name: T(channelName),
        id: item.name,
      };
    }
  );
  const midsListForSelect = Object.values(data.mid || {}).map((item) => {
    const midName = WarehouseStore.getWarehouseName(item.name);
    return {
      name: midName,
      id: item.name,
    };
  });

  const handleChangeFilter = (selectedOption, action) => {
    if (!selectedOption) {
      setFilter((prev) => ({
        ...prev,
        [action.name]: [{ value: 0, label: T("all") }],
      }));
    } else if (action.option?.value === 0) {
      setFilter((prev) => ({
        ...prev,
        [action.name]: [action.option],
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        [action.name]: selectedOption.filter((item) => item?.value !== 0),
      }));
    }
  };

  return (
    <>
      <GeneralRowContent
        data={data}
        groupDate={groupDate}
        onToggleOpen={handleToggleOpen}
        isOpen={isOpen}
      />
      {isOpen && (
        <tr className={isOpen ? "selected-border" : ""}>
          <td colSpan={6}>
            <RadioButtonListControl
              name={`show-on-${data.label}`}
              label="show-on"
              valueRadio={isActiveFilter}
              list={list}
              onChange={handleChangeActiveFilter}
            />
            {isActiveFilter === "mid" && midsListForSelect.length > 1 && (
              <SelectComponent
                name="mid"
                empty="all"
                className="full-size-select"
                list={() => midsListForSelect}
                onChange={handleChangeFilter}
                value={filter.mid}
                isMulti
              />
            )}
            {isActiveFilter === "clients" &&
              clientsListForSelect.length > 1 && (
                <SelectComponent
                  name="clients"
                  empty="all"
                  className="full-size-select"
                  list={() => clientsListForSelect}
                  onChange={handleChangeFilter}
                  value={filter.clients}
                  isMulti
                />
              )}
            {isActiveFilter === "channels" &&
              channelsListForSelect.length > 1 && (
                <SelectComponent
                  name="channels"
                  empty="all"
                  className="full-size-select"
                  list={() => channelsListForSelect}
                  onChange={handleChangeFilter}
                  value={filter.channels}
                  isMulti
                />
              )}
          </td>
        </tr>
      )}
      {isOpen && (
        <tr className={isOpen ? "selected-border" : ""}>
          <td colSpan={6}>
            <GeneralSubReports
              data={data[isActiveFilter]}
              activeFilter={isActiveFilter}
              filterList={filter}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default GeneralRowDesktop;
