import React from 'react';
import { connectToStores } from "tools/reflux-tools";
import Box from 'react-bulma-components/lib/components/box';
import T from "components/i18n";
import Button from 'react-bulma-components/lib/components/button';
import Columns from 'react-bulma-components/lib/components/columns';
import ExpensesStore from "./expenses-store";
import Actions from './expenses-actions';
import SelectComponent from 'tools/select-component';
import BaseFilter from "components/modules/base-filters";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";


class ExpensesFilter extends BaseFilter {
    constructor(props) {
        super(props);
        this.state = {
          showFilter: !this.props.isMobileSize
        };

        this.period_days = 7;
        this.store = ExpensesStore;
        this.actions = Actions;

        this.changeShowFilter = this.changeShowFilter.bind(this);
    }

    onChange(evt) { 
        const value = evt.target.value,
              name = evt.target.name

        Actions.filter(name, value);
    }

    onChangeSelect(selectedOption, actionMeta) {
      Actions.filter(actionMeta.name, selectedOption);
    }

    componentDidMount() {
        // Actions.load();
        let date_start = new Date();
        date_start.setDate(date_start.getDate() - 7);
        Actions.filter("date_start", date_start);
    }


    selectCategory() {
        return (
                <SelectComponent 
                          name="category"
                          empty="all-categories"
                          onChange={this.onChangeSelect} 
                          value={ExpensesStore.getFilter("category")} 
                          list={ExpensesStore.getAllCategory}
                          load={Actions.loadCategory}
                          creatable={false}/>
        );
    } 

    changeShowFilter() {
      this.setState({
        showFilter: !this.state.showFilter
      })
    }

     renderButton () {
        return (
            <div align="right" className="margin-bottom-10">
             <Button key="show-filter" size="small" color="success" rounded onClick={() => this.changeShowFilter()}>{T(this.state.showFilter ? 'hide-filter' : 'show-filter')}</Button>
            </div>
          );
     }

     renderFilter () {
        return (
          <Box className="filters search-filter">
            <Columns>
                <Columns.Column size="one-quarter">
                {this.searchString()}
                </Columns.Column>
                <Columns.Column size="one-fifth">
                  {this.selectAccount()}
                </Columns.Column>
                <Columns.Column size="one-fifth">
                  {this.selectCategory()}
                </Columns.Column>
                <Columns.Column className="filters-date">
                  {this.getDateRange()}
                </Columns.Column> 
            </Columns>
            
          </Box>			
        )
    }
    
    render () {
      const filter = this.renderFilter(), button = this.renderButton();
        return (
            <div>
              {this.props.isMobileSize ? button : null}
              {this.state.showFilter ? filter : ""}

            </div>
          )
     }

}

export default connectToStores(withIsMobileSize(ExpensesFilter), { _: ExpensesStore });