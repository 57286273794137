import React, {useState} from "react";
import ProductsStore from "../../../products-store";
import sortByKey from "../../../../../tools/sort-by-key";
import { applyFilter } from "../utils";
import List from "react-bulma-components/lib/components/list";
import {Box, Heading} from "react-bulma-components";
import T from "../../../../../components/i18n";
import AddProductRow from "./add-product-row";
import usePaginationHook from "../../../../../tools/pagination-hook";
import StatisticsBlock from "../common/statistics-block";
import {getDatePeriod} from "../../../../../tools/date-range";
import DateRange from "../../../../../components/base-filter-layout/date-range";

const AddProductBlock = ({  units }) => {
  const history = ProductsStore.get("history");
  const [date, setDate] = useState({
    date_start: getDatePeriod(30),
    date_end: new Date(),
  })
  const filtered = sortByKey(
    history.filter((data) => applyFilter(data, "created_at", 18,false, date)),
    "created_at"
  );

  const handleChangeDate = (value) => {
    setDate(value)
  };

  const { page, end, start, total, setPage, PaginationBlock } =
    usePaginationHook({ listLength: filtered.length, perPage: 7 });

  const calcStatistics = filtered.reduce(
    (acc, curr) => {
      const comment = JSON.parse(curr["comment"]);
      acc.count += comment["total"];
      return acc;
    },
    { count: 0 }
  );

  const statisticsList = [
    {
      name: "added",
      value: ProductsStore.getStockUnits(calcStatistics["count"], units),
    },
  ];
  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          borderRadius: "0 0 16px 16px",
        }}
      >
        <DateRange
          date_start={date.date_start}
          date_end={date.date_end}
          onChange={handleChangeDate}
        />
      </Box>
      <StatisticsBlock list={statisticsList} />
      <List>
        {filtered?.slice(start, end).map((item) => (
          <AddProductRow key={item.id} data={item} units={units} />
        ))}
        {filtered.length === 0 && (
          <div align="center" className="margin-10">
            <Heading size={4}>{T("not-have-added")}</Heading>
          </div>
        )}
      </List>
      <div className="display-flex-center">
        <PaginationBlock
          totalPage={total}
          setPage={setPage}
          currentPage={page}
          showPrevNext={false}
        />
      </div>
    </>
  );
};

export default AddProductBlock;
