import React, {useEffect} from "react";
import SalesStore from "../../../sales/sales-store";
import { connectToStores } from "../../../../tools/reflux-tools";
import { Box } from "react-bulma-components";
import SaledOrder from "./saled-order";
import NotSaledOrder from "./not-saled-order";
import T from "../../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import Button from "react-bulma-components/lib/components/button";
import SalesActions from "../../../sales/sales-actions";

const ClientOrders = ({ clientId }) => {
  const sales = SalesStore.getSalesByClientId(clientId);

  const filterSaled = sales?.filter((item) => item.order_status === "saled");
  const filterNotSaled = sales?.filter((item) => item.order_status !== "saled");

  useEffect(() => {
    const addSales = SalesActions.add.completed.listen(() => {
      SalesActions.getSalesByClientId(clientId)
    })

    return () => {
      addSales()
    }
  }, [clientId]);

  return (
    <>
      <Box>
        <Heading size={6}>{T("active-orders")}</Heading>
        {filterNotSaled.map((item) => (
          <NotSaledOrder key={item.oid} data={item} />
        ))}
        <Button
          size="small"
          rounded
          color="info"
          className="margin-top-10"
          onClick={() => SalesActions.openSaleModal({ client_id: clientId })}
        >
          {T("add-sale-btn")}
        </Button>
      </Box>
      <Box>
        <Heading size={6}>{T("old-orders")}</Heading>
        {filterSaled?.map((item) => (
          <SaledOrder key={item.oid} data={item} />
        ))}
      </Box>
    </>
  );
};

export default connectToStores(ClientOrders, { _: SalesStore });
