import React from "react";
import { Box, Heading, List } from "react-bulma-components";
import T from "../../../../components/i18n";
import SaleProductRow from "./sale-product-row";
const SaleProductsInfo = ({ products, accountID }) => {
  return (
    <Box>
      <Heading size={4}>{T("sale-products-info-label")}</Heading>
      <List style={{ maxHeight: 450, minHeight: 114, overflow: "auto" }}>
        {products?.map((product, index) => (
          <List.Item key={`${product.product_id}/${index}`}>
            <SaleProductRow data={product} accountID={accountID} />
          </List.Item>
        ))}
      </List>
    </Box>
  );
};

export default SaleProductsInfo;
