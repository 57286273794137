import React from 'react'
import {Dropdown} from "react-bulma-components";
import {Link} from "react-router-dom";
import {createMobileAppLink} from "../../../tools/create-mobile-app-link";
import { LocalIcon } from 'whitelables/wl-name/icons'
import InventoryStore from "../inventory-store";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";


const ExportDocumentDropdown = ({inventoryId}) => {
	const {isMobileSize} = useIsMobile()
	return (
		<Dropdown
			className='full-width-dropdown'
			right={!isMobileSize}
			label={<LocalIcon icon='export-products' size={isMobileSize ? 'large' : 'small'}/>}
		>
			<Dropdown.Item value='pdf'>
				<Link
					className='drop-item'
					to={InventoryStore.printInventoryURL('pdf', inventoryId)}
					target="_blank"
					onClick={(evt) => createMobileAppLink(InventoryStore.printInventoryURL('pdf', inventoryId), evt)}
				>
					<LocalIcon icon={'excel'} />  PDF
				</Link>
			</Dropdown.Item>
			<Dropdown.Item value='excel'>
				<Link
					className='drop-item'
					to={InventoryStore.printInventoryURL('xls', inventoryId)}
					target="_blank"
					download
					onClick={(evt) => createMobileAppLink(InventoryStore.printInventoryURL('xls', inventoryId), evt)}
				>
					<LocalIcon icon={'excel'} />  EXCEL
				</Link>
			</Dropdown.Item>
		</Dropdown>
	);
};

export default ExportDocumentDropdown;