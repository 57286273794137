import React, {useState} from "react";
import ProductsStore from "../../../products/products-store";
import usePaginationHook from "../../../../tools/pagination-hook";
import { Button } from "react-bulma-components";
import AppStore from "../../../../app-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import T from "../../../../components/i18n";
import Reports from "../../../../components/reports";
import EmptyMessage from "../common/empty-message";
import RowMobile from "./row-mobile";
import RowDesktop from "./row-desktop";
import AddAttrProductModal from "../../../products/modal/add-attr-product/add-attr-product-modal";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";
import {filterSearch} from "../../../../tools/filters-helper";
import SearchString
  from "../../../../components/base-filter-layout/search-string";

const COLUMNS = ["name", "reports-actions"];

const ProductAttr = () => {
  const {isMobileSize} = useIsMobile()
  const [search, setSearch] = useState('')
  const handleChangeSearch = ({name, value}) => {
    if(name) {
      setSearch(value)
    }
  }

  const getAttr = ProductsStore.getAttrList();

  const withFilter = getAttr.filter(item => {
    const searchData = {
      name: item.name.trim('')
    }
    return filterSearch(search,['name'], searchData)
  })

  const { start, end, page, total, setPage, PaginationBlock } =
    usePaginationHook({ listLength: withFilter?.length, perPage: 50 });

  return (
    <>
      <div className="margin-top-10 margin-bottom-10 display-flex-row-gap">
        <SearchString defaultValue={search} onChange={handleChangeSearch}/>
        <Button
          color="info"
          onClick={() =>
            AppStore.openModal(<AddAttrProductModal modal="add-attr"/>)
          }
          className="display-flex-row"
        >
          <FontAwesomeIcon icon="plus-circle"/>
          {T("add-attr")}
        </Button>
      </div>
      <PaginationBlock
        totalPage={total}
        setPage={setPage}
        currentPage={page}
        showPrevNext={false}
      />
      <Reports
        emptyMessage={EmptyMessage}
        rows={withFilter?.slice(start, end) || []}
        columns={COLUMNS}
      >
        {isMobileSize ? <RowMobile role="row" /> : <RowDesktop role="row" />}
      </Reports>
      <PaginationBlock
        totalPage={total}
        setPage={setPage}
        currentPage={page}
        showPrevNext={false}
      />
    </>
  );
};

export default ProductAttr;
