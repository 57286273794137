
import React, { Component } from "react";
// import { connectToStores } from "tools/reflux-tools";
import SalesStore from '../sales-store';
import Money from "components/money";
// import { Link } from "react-router-dom";
import ProductsStore from 'modules/products/products-store';
// import ExpensesStore from 'modules/expenses/expenses-store';
import calcMargin from 'tools/calc-margin';
import formatDate from 'tools/format-date';
// import ColorControl from 'tools/color-control';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DOM from "react-dom-factories";
import ClientsStore from 'modules/clients/clients-store';

import Image from 'react-bulma-components/lib/components/image';
import Content from 'react-bulma-components/lib/components/content';
// import Level from 'react-bulma-components/lib/components/level';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import User from 'user/user-store';

// import ColorControl from 'tools/color-control';
import NumberBlock from "components/number-block";

import List from 'react-bulma-components/lib/components/list';
import Columns from 'react-bulma-components/lib/components/columns';

import T from "components/i18n";
import { LocalIcon } from 'whitelables/wl-name/icons'

class SingleSaleModal extends Component {
    constructor(props) {
        super(props);

        const { oid, order } = this.props;

        let sales;
        if (order) {
            sales = order;
        } else {
            sales = SalesStore.getSalesByOid(oid);
        }

        this.sales = sales;

        this.mobileRow = this.mobileRow.bind(this);

    }

    mobileRow(i, barcode, date, name, quant, net, price, profit, 
        discount, discount_type, aid, cid, pid, total, oid, total_order, is_deleted, rid, 
        status, prepaid, client_id, comment, delivery_info, delivery_id, prepaid_aid, 
        tracking, isParent, parent_id, margin_r) {
        const img = "/bimages/get/64-"+User.get("id")+"-"+(parent_id || pid)+"-0.jpg", 
              response_name = User.getResponsebleName(rid),
              text_deleted = is_deleted === 1 ? (<span className='has-text-danger'> {T('product_deleted')} </span>)  : "",
              prepaid_text = (
                  <div><b>{T("prepaid")}</b>: <Money amount={prepaid} aid={aid}/>
                  <b>{T("need-balance")}</b>: <Money amount={(total-prepaid) || 0} aid={aid} /></div>
              );


      let deliverName = delivery_info
      let deliveryIcon = ''
      let isNoWrap = true

      if(parseInt(delivery_info) === 1){
          deliverName = 'nova_poshta'
        deliveryIcon = 'nova-poshta'
      } else if (parseInt(delivery_info) === 3) {
        deliverName = "ukr_poshta"
        deliveryIcon = 'ukr-poshta'
      } else if(parseInt(delivery_info) === 6) {
        deliverName = 'rozetka'
        deliveryIcon = 'rozetka'
      } else {
        if(`${delivery_info}`.length > 20){
          isNoWrap = false
        }
      }


        const data = this.sales;

        let client_string, client, delivery_address;

        // if (discount) {
        //     discount_row = (
        //         <span>| {T('sales-discount')}: {discount}{discount_type === "stable" ? User.getCurrency() : discount_type}</span>
        //     )
        // }

        if (client_id) {
              client = ClientsStore.getClientById(client_id);
              delivery_address = ClientsStore.getClientDeliveryById(client_id, delivery_id);
              client_string = (
                <span><b>{T("customer")}:</b> {client['name']}<br /></span>
                );
        }

        // if (comment) {
        //   comment_string = (
        //       <List.Item><b>{T('comment')}:</b> {comment}</List.Item>
        //     )
        // }

        let row_inside = [];
        if (isParent) {
              for (let i in data['items']) {
                let item = data['items'][i]; 
                let pr_d = ProductsStore.getProduct(item['product_id']);
                item['product_name'] = pr_d['name'];

                row_inside.push(this.buildMobileInside(i, item['barcode'],
                      item['product_name'],
                      item['quantity'],
                      item['net_price'],
                      item['price'],
                      item['profit'],
                      item['product_id'],
                      item['amount'],
                      item['product_deleted'],
                      item['is_refund'],
                      data['order_status'],
                      data['account_id'], data['oid'], item['oiid'], item['base_price'], item['discount'],
                      pr_d['units']
                  ));
              }
        }
        return (
        	<React.Fragment key={oid}>
                    <Content>
                        {!isParent ? 
                        <span>
                               <Image src={img} size={64} className="product-image"/>

                        <Heading size={6} style={{"textAlign": "left"}}>
                              {name} {text_deleted}
                        </Heading>
                        </span> : <Heading style={{"textAlign": "left"}} size={6}><b>{name}</b></Heading>
                        }

                        <div className="info-bar">
                           <Columns className="is-mobile">
                           
                             <Columns.Column align="left">
                                {formatDate(date)}
                             </Columns.Column>
                             <Columns.Column align="right">
                                <b>{T('who-add-sale')}:</b> {response_name}
                             </Columns.Column>
                           </Columns>
                            {client_string} 
                            {tracking ? 
                              <p><b>{T('tracking-number')}: </b> {tracking}</p>
                            : null}

                             {delivery_info &&
                                <div className='display-flex-row'>
                                  <b>{T('delivery-delivery')}:</b>
                                  <span className={`display-flex-row ${isNoWrap ? 'nowrap' : ''}`}>
                                        <LocalIcon icon={deliveryIcon} size='small'/>
                                        {T(deliverName)}
			                            </span>
                                </div>}
                            {client && delivery_id ? 
                            <p>
                              <span><b>{T('client-address')}</b>:<br />
                              {ClientsStore.getClientDeliveryNane(delivery_address, User.getLang())}
                              </span>
                            </p>
                            : null }

                        </div>
                    </Content>
                    <List>{row_inside}</List>
                    {status === "reserve_sale" || prepaid > 0? 
                        <List>
                            <List.Item>
                                {prepaid_text}
                            </List.Item>
                        </List>
                    : null}
                            {discount ?
                      <Columns className="is-mobile product-mobile-block">
                            <Columns.Column>
                                  <NumberBlock top="amount" number={data['amount'] || 0} bottom={User.getCurrency()} money={true}  className="small-number-box" /> 
                            </Columns.Column>
                            <Columns.Column>
                                   <NumberBlock top="clients-discount" number={discount} bottom={discount_type === "stable" ? User.getCurrency() : discount_type} money={true}  className="small-number-box" /> 
                            </Columns.Column>
                            <Columns.Column>
                                   <NumberBlock top="clients-to-pay" number={data['amount_sale'] || 0}  bottom={User.getCurrency()} money={true}  className="small-number-box green-border"/> 
                            </Columns.Column> 
                      </Columns>
              : <Columns className="is-mobile product-mobile-block">
                            <Columns.Column>
                                  <NumberBlock top="amount-of-sale" number={total || 0} bottom={User.getCurrency()} money={true}  className="small-number-box" /> 
                            </Columns.Column>
                      </Columns>}
            </React.Fragment>
        );

    }

    buildMobileInside(i, barcode, name, quant, net, price, profit, pid, total, is_deleted, is_refund, status, aid, oid, oiid, base_price, discount, units) {
        const text_deleted = is_deleted === 1 ? (<span className='has-text-danger'> {T('product_deleted')} </span>)  : "";

        const img = "/bimages/get/64-"+User.get("id")+"-"+pid+"-0.jpg",
              sales_text = (
                  <span><b>{T('product-info-price')}:</b> <Money amount={discount ? base_price : price || 0} aid={aid} wrapper={DOM.span} /> <br />
                  {!!discount && <><span><b>{T('sales-discount')}</b>: {discount}%</span><br/></>}
                  <b>{T('quantity')}:</b> {ProductsStore.getStockUnits(quant, units)} <br />
                  <b>{T('total')}:</b> <Money amount={total || 0} wrapper={DOM.span}/>
                  </span>
                );


        let className = "is-mobile";
        return (
            <List.Item key={pid}>
              <Columns className={className}>
                <Columns.Column  size="one-fifth" className="is-one-six" align="center">
                         <Image src={img} size={32} className="product-image"/>                    
                </Columns.Column> 
                <Columns.Column>
                  <div>
                        {name} {text_deleted}.
                  </div>
                  {is_refund ? <b className="has-text-danger">{T('item-refunded')}</b> : sales_text} <br />
                </Columns.Column>
              </Columns>
            </List.Item>
        );
    }   

    render() {
        const data = this.sales;

        const buildRow = this.mobileRow;
        
        let quantity = 0, net_price = 0, profit = 0, margin = 0, response = [];
          for (let i in data['items']) {
            const item = data['items'][i]; 
            quantity += parseInt(item['quantity']);
            net_price += parseFloat(item['net_price']);
            profit += parseFloat(item['profit']);
            margin += calcMargin(item['net_price'], item['price']);
          }

          margin = (Math.round((margin / data['items'].length) * 100)/100);


          response.push(buildRow(data['oid'], false, data['purchase_date'],
                  T('purchase')+" #"+data['oid']+" ("+T("items-count", {"count": data['items'].length})+")",
                  quantity,
                  net_price,
                  data['amount_sale'],
                  profit,
                  data['discount'],
                  data['discount_type'],
                  data['account_id'],
                  data['channel_id'],
                  false,
                  data['amount_sale'],
                  data['oid'],
                  data['amount_sale'],
                  0,
                  data['responsible_user_id'],
                  data['order_status'],
                  data['prepaid_amount'] || 0,
                  data['client_id'],
                  data['comment'],
                  data['delivery_info'],
                  data['clients_delivery_id'],
                  data['prapaid_account_id'],
                  data['tracking_number'],
                  true,
                  null,
                  margin
          ));


        return (
        	<Box>
        	{response}
        	<Button fullwidth size="large"  rounded color="primary"
                onClick={() => {typeof(this.props.onClose) === "function" ? this.props.onClose() : this.setState({close: true})}}
                >{T('close')}</Button>
        	</Box>
        );
    }
}

export default SingleSaleModal;
