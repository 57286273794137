import React, {useEffect, useRef, useState} from 'react'
import T from "../i18n";
import { LocalIcon } from 'whitelables/wl-name/icons'
import s from './styles.module.scss'

const GroupButtonsWrapperMobile = ({count,onDeSelect, children}) => {
	const [isOpen, setIsOpen] = useState(true)
	const [sizeBox, setSizeBox] = useState(0)
	const [heightBox, setHeightBox] = useState(0)

	const boxRef  = useRef(null);

	const toggleOpen = () => {
		setIsOpen(prevState => !prevState)
	}

	useEffect(() => {
		if(isOpen) {
			setSizeBox(0)
		}else {
			setSizeBox(-heightBox)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	useEffect(() => {
		if(boxRef.current) {
			const rect	= boxRef.current.getBoundingClientRect()
			setHeightBox(rect.height)
		}
	// eslint-disable react-hooks/exhaustive-deps
	}, []);

	return (
		<div ref={boxRef} className="mass-button-addition is-fixed-top" style={{transform: `translateY(${sizeBox}px)`, paddingBottom: isOpen ? 0 : '15px'}}>
			<div className="display-flex-row">
				{onDeSelect && (
					<span className='text-danger cursor-pointer' onClick={onDeSelect}>
						<LocalIcon icon='circle-close'/>
					</span>)}
				<b>{T('select-items')}: {count}</b>
			</div>
			<div className={s.buttons}>
				{children}
			</div>
			<div onClick={toggleOpen} className='position-relative'>
				<div className='hiding-arrow-wrapper'
						 style={isOpen ? {} : {bottom: -39}}>
					<LocalIcon icon='double-arrow' size='large' className={isOpen ? 'icon-rotate-90-left' : 'icon-rotate-90-right'}/>
				</div>
			</div>
		</div>
	);
};

export default GroupButtonsWrapperMobile;