import React, { useCallback, useEffect, useRef, useState } from "react";
import s from "./styles.module.scss";
import { Dropdown } from "react-bulma-components";
import { LocalIcon } from "whitelables/wl-name/icons";
import T from "../i18n";
import { useHistory, useLocation } from "react-router-dom";
import AppStore from "../../app-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DynamicTabList = ({
  list,
  defaultTab,
  settingModal,
  propsSettingModal,
  addModal,
  propsAddModal,
  onChangeTab,
}) => {
  const history = useHistory();
  const location = useLocation();
  const tabListRef = useRef();

  const currentPath = location.pathname;
  const root = currentPath.split("/")[1];
  const [tabs, setTabs] = useState(list);

  useEffect(() => {
    setTabs(list);
  }, [list]);

  const [visibleTabs, setVisibleTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(defaultTab || tabs[0]?.value);

  const isTabVisible = (tabElement) => {
    const containerRect = tabListRef.current.getBoundingClientRect();
    const tabRect = tabElement.getBoundingClientRect();
    return (
      tabRect.left >= containerRect.left && tabRect.right <= containerRect.right
    );
  };

  const updateVisibleTabs = useCallback(() => {
    const tabsElements = tabListRef.current.children;
    const visibleTabsArray = Array.from(tabsElements).filter((tabElement) =>
      isTabVisible(tabElement)
    );
    const visibleTabsIndexes = visibleTabsArray.map((tabElement) =>
      Array.from(tabsElements).indexOf(tabElement)
    );
    setVisibleTabs(visibleTabsIndexes);
  }, []);

  useEffect(() => {
    updateVisibleTabs();
  }, [updateVisibleTabs]);



  useEffect(() => {
    if (selectedTab) {
      const selectedTabIndex = tabs.findIndex(
        (tab) => `${tab.value}` === `${selectedTab}`
      );
      const tabElement = tabListRef.current.children[selectedTabIndex];
      if (tabElement) {
        const containerRect = tabListRef.current.getBoundingClientRect();
        const tabRect = tabElement.getBoundingClientRect();
        const offsetLeft = tabRect.left - containerRect.left;
        const offsetRight = containerRect.right - tabRect.right;

        if (offsetLeft < containerRect.width / 2) {
          tabListRef.current.scrollLeft -= containerRect.width / 2 - offsetLeft;
        } else if (offsetRight < containerRect.width / 2) {
          tabListRef.current.scrollLeft +=
            containerRect.width / 2 - offsetRight;
        }

        updateVisibleTabs();
      }
    }
  }, [selectedTab, tabs, updateVisibleTabs]);

  useEffect(() => {
    window.addEventListener("resize", updateVisibleTabs);

    return () => {
      window.removeEventListener("resize", updateVisibleTabs);
    };
  }, [tabs, selectedTab, updateVisibleTabs]);

  const handleSelectChange = ({ value, url, state }) => {
    if (value !== selectedTab) {
    onChangeTab && onChangeTab(value, state);
    setSelectedTab(value);
    if (url && url !== "all") {
      history.push({
        pathname: `/${root}/${url}`,
        state,
      });
    } else if (url === "" || url === 0 || url === "all") {
      history.push({
        pathname: `/${root}`,
        state,
      });
      }
    }
  };

  const handleScroll = () => {
    updateVisibleTabs();
  };

  const handleOpenModalSetting = (id) => {
    if (settingModal) {
      const Modal = settingModal;
      AppStore.openModal(<Modal id={id} {...propsSettingModal} />);
    }
  };
  const handleOpenAddSetting = () => {
    if (addModal) {
      const Modal = addModal;
      AppStore.openModal(<Modal {...propsAddModal} />);
    }
  };

  useEffect(() => {
    if(defaultTab) {
      setSelectedTab(defaultTab)
    }
  },[defaultTab])

  return (
    <div className={s.wrapper}>
      <div onScroll={handleScroll} ref={tabListRef} className={s.list}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`${s.tab} ${
              `${tab.value}` === `${selectedTab}` ? s.active : ""
            }`}
            onClick={() => handleSelectChange(tab)}
          >
            {T(tab.label)}
            {!!tab.count && `(${tab.count})`}
            {!!tab?.additionalCount && tab.additionalCount}
            {`${tab.value}` === `${selectedTab}` &&
              !tab.is_global &&
              settingModal && (
                <span
                  className={s.setting}
                  onClick={() => handleOpenModalSetting(tab.value)}
                >
                  <LocalIcon icon="gear" />
                </span>
              )}
          </div>
        ))}
      </div>
      <div className={s.wrapperDrop}>
        {addModal && (
          <div className={s.addItem} onClick={handleOpenAddSetting}>
            <FontAwesomeIcon icon="plus-circle" />
          </div>
        )}
        {visibleTabs.length !== tabs.length && (
          <Dropdown
            className="dropdown-custom-price dropdown-item-without-padding"
            label={<LocalIcon icon="ellipsis" size="large" />}
            right={true}
          >
            {tabs
              .filter((_, index) => !visibleTabs.includes(index))
              .map((tab, index) => (
                <Dropdown.Item key={index} value={tab}>
                  <div
                    className={s.dropItem}
                    onClick={() => handleSelectChange(tab)}
                  >
                    <span>{T(tab.label)}</span>
                    <span>{!!tab.count && `(${tab.count})`}</span>
                  </div>
                </Dropdown.Item>
              ))}
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default DynamicTabList;
