import React from "react";
import Level from "react-bulma-components/lib/components/level";
import IconButton from "../../../../components/modal/icon-button";
import T from "../../../../components/i18n";
import GroupDaysFilter from "../group-days-filter";
import SelectComponent from "../../../../tools/select-component";
import WarehouseStore from "../../../warehouse/warehouse-store";
import SalesStore from "../../../sales/sales-store";
import ClientsStore from "../../../clients/clients-store";

const ChartsControl = ({
  display,
  daysGroup,
  valueSelect,
  onChangeSelect,
  onChangeDaysGroup,
  onChangeDisplay,
}) => {
  const handleChangeSelect = (value, meta) => {
   onChangeSelect({name: meta.name,value})
  }

  return (
    <>
      <Level className="is-mobile">
        <Level.Side align="left">
          <Level.Item>{T("display-type")}:</Level.Item>
          <Level.Item>
            <IconButton
              size="small"
              color={display === "line" ? "success" : "light"}
              text={T("lines")}
              icon="chart-line"
              onClick={() => onChangeDisplay("line")}
            />
          </Level.Item>
          <Level.Item>
            <IconButton
              size="small"
              color={display === "bar" ? "success" : "light"}
              text={T("bars")}
              icon="chart-bar"
              onClick={() => onChangeDisplay("bar")}
            />
          </Level.Item>
          <Level.Item>
            <IconButton
              size="small"
              color={display === "combain" ? "success" : "light"}
              text={T("combain")}
              icon="chart-area"
              onClick={() => onChangeDisplay("combain")}
            />
          </Level.Item>
        </Level.Side>
      </Level>
      <Level className="is-mobile">
        <Level.Side align="left">
          <GroupDaysFilter group={daysGroup} onChange={onChangeDaysGroup} />
        </Level.Side>
      </Level>
      <div className="display-flex-row flex-wrap">
        <div style={{ flexGrow: 1, minWidth: "170px" }}>
          <SelectComponent
            name='mid'
            onChange={handleChangeSelect}
            value={valueSelect.mid}
            placeholder='click-to-select'
            isMulti
            label='history-data-marketplace_id'
            list={WarehouseStore.getWarehouseList}
          />
        </div>
        <div style={{ flexGrow: 1, minWidth: "170px" }}>
          <SelectComponent
            name='channels'
            onChange={handleChangeSelect}
            label='sale-channel'
            placeholder='click-to-select'
            isMulti
            value={valueSelect.channels}
            list={SalesStore.getChannelsList}
          />
        </div>
        <div style={{ flexGrow: 1, minWidth: "170px" }}>
          <SelectComponent
            onChange={handleChangeSelect}
            name='clients'
            label='clients'
            placeholder='click-to-select'
            isMulti
            value={valueSelect.clients}
            list={ClientsStore.getClientsList}
          />
        </div>
      </div>
    </>
  );
};

export default ChartsControl;
