import React, { useState } from "react";
import { Box, Button, Heading, List } from "react-bulma-components";
import ProductsStore from "../../../products/products-store";
import WarehouseStore from "../../../warehouse/warehouse-store";
import T from "../../../../components/i18n";
import { Radio } from "react-bulma-components/lib/components/form";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";

const ChangeWarehouseProductSaleModal = ({ mids, product, onClose, onChange }) => {
  const [radioValue, setRadioValue] = useState("");
  const handleSubmit = () => {
    if(onChange && radioValue) {
      onChange({pid: product.pid, mid: radioValue, oldMid: product.mid});
      onClose && onClose()
    }
  };

	const handleChangeRadio = ({target: {value}}) => {
		setRadioValue(value);
	}

  const getProduct = ProductsStore.getProductByMid(product.pid, product.mid);

  return (
    <Box>
      <Heading>{T('warehouse-change')}</Heading>
      <Heading subtitle>
        {product.name} <br />{" "}
        <p style={{ fontSize: "1rem" }}>
          ID: {product.pid}.{getProduct.sku ? ` ${getProduct.sku}. ` : ""}
          {WarehouseStore.getWarehouseName(product.mid)}.
        </p>
      </Heading>
      <List>
        {mids.map((item) => {
          const getProduct = ProductsStore.getProductByMid(product.pid, item.mid);
          const isDropshipping = WarehouseStore.isDropshipping(item.mid) && getProduct.type_product !== 4

          return <List.Item key={item.mid}>
            <Radio style={{ display: "flex", alignItems: "center" }} onChange={handleChangeRadio} name='warehouse' value={`${item.mid}`} checked={parseFloat(item.mid) === parseFloat(radioValue)} >
              <b>{WarehouseStore.getWarehouseName(item.mid)}. {ProductsStore.getStockUnits(isDropshipping ? "∞" : getProduct['instock'], getProduct['units'])}. <Money amount={getProduct.sale_price || getProduct.price} wrapper={DOM.span}/></b>
            </Radio>
          </List.Item>
        })}
      </List>
      <div className="display-flex-row" style={{ justifyContent: "flex-end" }}>
        <Button onClick={handleSubmit} color="success">
          {T("change")}
        </Button>
        <Button onClick={onClose}>{T("close")}</Button>
      </div>
    </Box>
  );
};

export default ChangeWarehouseProductSaleModal;
