import ExpensesStore from "../../expenses/expenses-store";
import T from "../../../components/i18n";
import User from "../../../user/user-store";
import ShipmentsStore from "../../shipments/shipments-store";

const getLabel = (_date, display) => {
  let label;
  let date = new Date(_date * 1000);

  if (display === "days") {
    date.setHours(0, 0, 0, 0);
    label = date.getTime();
  } else if (display === "weeks") {
    let monday = date.getMonday();
    label = monday.getDate() + "." + monday.getRealMonth();
    monday.setDate(monday.getDate() + 6);
    label += " - " + monday.getDate() + "." + monday.getRealMonth();
  } else if (display === "months") {
    label = T("month-" + (date.getMonth() + 1)) + " " + date.getFullYear();
  } else if (display === "years") {
    label = date.getFullYear();
  }

  return label;
};

export const calcMargin = (amount, profit) => {
  return (
    Math.round((parseFloat(profit) / parseFloat(amount)) * 100 * 100) / 100
  );
};

export const mergeObjects = (...objects) => {
  const isObject = (obj) => obj && typeof obj === 'object' && !Array.isArray(obj);

  const mergedObject = {};

  objects.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (isObject(obj[key])) {
        if (!mergedObject[key]) {
          mergedObject[key] = {};
        }
        mergedObject[key] = mergeObjects(mergedObject[key], obj[key]);
      } else {
        mergedObject[key] = obj[key];
      }
    });
  });

  return mergedObject;
};

export const calcReportSale = (data, group) => {
  return data.reduce((acc, sale) => {
    if (
      ExpensesStore.isNotAllowToCalc(sale["account_id"]) ||
      sale["order_status"] === "reserve_sale" || ExpensesStore.isDebt(sale["account_id"])
    ) {
      return acc;
    }

    const label = getLabel(sale.purchase_date, group);
    if (!acc[label]) {
      acc[label] = {
        label,
        amount_sale: 0,
        count: 0,
        profit: 0,
        quantity: 0,
        mid: {},
        clients: {},
        channels: {},
      };
    }

    acc[label].amount_sale += sale.amount_sale;
    acc[label].count += 1;

    if (sale.client_id) {
      if (!acc[label].clients[sale.client_id]) {
        acc[label].clients[sale.client_id] = {
          amount_sale: 0,
          count: 0,
          profit: 0,
          quantity: 0,
          name: sale.client_id,
        };
      }
      acc[label].clients[sale.client_id].amount_sale += sale.amount_sale;
      acc[label].clients[sale.client_id].count += 1;
    }

    if (Array.isArray(sale.channel_id) && sale.channel_id.length) {
      sale.channel_id.forEach((channel) => {
        if (!acc[label].channels[channel]) {
          acc[label].channels[channel] = {
            amount_sale: 0,
            count: 0,
            profit: 0,
            quantity: 0,
            name: channel,
          };
        }
        acc[label].channels[channel].amount_sale += sale.amount_sale;
        acc[label].channels[channel].count += 1;
      });
    }

    const keyMapDict = {};

    sale.items.forEach(
      (item) => {
        acc[label].profit += item.profit;
        acc[label].quantity += item.quantity;

        if (sale.client_id) {
          acc[label].clients[sale.client_id].profit += item.profit;
          acc[label].clients[sale.client_id].quantity += item.quantity;
        }

        if (Array.isArray(sale.channel_id) && sale.channel_id.length) {
          sale.channel_id.forEach((channel) => {
            acc[label].channels[channel].profit += item.profit;
            acc[label].channels[channel].quantity += item.quantity;
          });
        }

        if (!acc[label].mid[item.mid]) {
          acc[label].mid[item.mid] = {
            name: item.mid,
            amount_sale: 0,
            count: 0,
            profit: 0,
            quantity: 0,
          };
        }

        if (!keyMapDict[item.mid]) {
          acc[label].mid[item.mid].count += 1;
          keyMapDict[item.mid] = true;
        }

        acc[label].mid[item.mid].profit += item.profit;
        acc[label].mid[item.mid].quantity += item.quantity;
        acc[label].mid[item.mid].amount_sale += item.amount;
        acc[label].mid[item.mid]["averageCheck"] =
          acc[label].mid[item.mid]["amount_sale"] /
          acc[label].mid[item.mid]["count"];
        acc[label].mid[item.mid].margin = calcMargin(
          acc[label].mid[item.mid]["amount_sale"],
          acc[label].mid[item.mid]["profit"]
        );
        return acc;
      },
      {
        profit: 0,
        quantity: 0,
      }
    );

    if (sale.client_id) {
      acc[label].clients[sale.client_id].margin = calcMargin(
        acc[label].clients[sale.client_id]["amount_sale"],
        acc[label].clients[sale.client_id]["profit"]
      );
      acc[label].clients[sale.client_id]["averageCheck"] =
        acc[label].clients[sale.client_id]["amount_sale"] /
        acc[label].clients[sale.client_id]["count"];
    }

    if (Array.isArray(sale.channel_id) && sale.channel_id.length) {
      sale.channel_id.forEach((channel) => {
        acc[label].channels[channel].margin = calcMargin(
          acc[label].channels[channel]["amount_sale"],
          acc[label].channels[channel]["profit"]
        );
        acc[label].channels[channel]["averageCheck"] =
          acc[label].channels[channel]["amount_sale"] /
          acc[label].channels[channel]["count"];
      });
    }

    acc[label].margin = calcMargin(
      acc[label]["amount_sale"],
      acc[label]["profit"]
    );
    acc[label]["averageCheck"] =
      acc[label]["amount_sale"] / acc[label]["count"];

    return acc;
  }, {});
};

export const calcReportExpenses = (data, group) => {
  return data.reduce((acc, expense) => {
    const label = getLabel(expense.created_at, group);

    if (!acc[label]) {
      acc[label] = {
        label,
        receipt: 0,
        shipmentExpenses: 0,
        notCalcExpenses: 0,
        expensesProfit: 0,
        amountExpenses: 0,
        expensesDeliveryShipment: 0,
        mid: {}
      };
    }

    const categoryInfo = ExpensesStore.getCategoryById(expense.category_id);

    const currency_id = ExpensesStore.getAccount(expense["account_id"])[
      "currency_id"
    ];


    const rate = User.getCurrencyRates(currency_id);

    if(expense?.marketplace_id) {
      if(!acc[label].mid[expense.marketplace_id]) {
        acc[label].mid[expense.marketplace_id] = {
          shipmentExpenses: 0,
          amountExpenses: 0,
          notCalcExpenses: 0,
          expensesDeliveryShipment: 0,
          expensesProfit: 0,
          name: expense.marketplace_id
        }
      }
    }


    if (expense.is_receipt) {
      acc[label].receipt += expense.amount * rate;
    } else if (expense.category_id === ExpensesStore.SHIPMENT_CATEGORY) {
      const shipment = ShipmentsStore.getShipmentById(expense.shipment_id);
      acc[label].shipmentExpenses += (expense.amount * (shipment?.currency_rate || rate || 1));
      if(expense?.marketplace_id) {
        acc[label].mid[expense.marketplace_id].shipmentExpenses += (expense.amount * (shipment?.currency_rate || rate || 1));
      }
    } else if (expense.category_id === 2) {
      acc[label].notCalcExpenses += (expense.amount * rate);
      acc[label].expensesDeliveryShipment += (expense.amount * rate);
    } else if (categoryInfo.is_profit === 2) {
      acc[label].notCalcExpenses += (expense.amount * rate);
      if(expense?.marketplace_id) {
        acc[label].mid[expense.marketplace_id].notCalcExpenses += (expense.amount * rate);
      }
    } else if (categoryInfo.is_profit === 1) {
      acc[label].expensesProfit += expense.amount * rate;
      if(expense?.marketplace_id) {
        acc[label].mid[expense.marketplace_id].expensesProfit += (expense.amount * rate);
      }
    } else {
      acc[label].amountExpenses += expense.amount * rate;
      if(expense?.marketplace_id) {
        acc[label].mid[expense.marketplace_id].amountExpenses += (expense.amount * rate);
      }
    }
    return acc;
  }, {});
};

export const calcReportShipment = (data, group) => {
  return data.reduce((acc, shipment) => {
    const label = getLabel(shipment.created_at, group);

    if (!acc[label]) {
      acc[label] = {
        pendingAmount: 0,
        completeAmount: 0,
        pendingCountShipment: 0,
        completeCountShipment: 0,
        paidAmount: 0,
      };
    }

    if (shipment.shipment_status === "pending") {
      acc[label].pendingAmount +=
        (shipment.amount || 1) * shipment.currency_rate;
      acc[label].pendingCountShipment += shipment.expected_quantity;
    } else if (shipment.shipment_status === "complete") {
      acc[label].completeAmount +=
        (shipment.amount || 1) * shipment.currency_rate;
      acc[label].completeCountShipment += shipment.quantity;
    }

    if (Array.isArray(shipment.expenses)) {
      shipment.expenses.forEach((expense) => {
        acc[label].paidAmount +=
          expense.amount * (shipment?.currency_rate || 1);
      });
    }

    return acc;
  }, {});
};
