import React, { Component } from "react";
import T from "components/i18n";
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import { redirectTo } from 'tools/redirect-to'
import trackEvent from 'tools/track-events';

class PaymentFailed extends Component {
	constructor(props) {
        super(props);
        this.state = {
          redirect: false
        };

        this.redirect = this.redirect.bind(this);
    }

    componentDidMount() {
        trackEvent('users', 'payment', 'failed');
    	setTimeout(this.redirect, 5000)
    }

    redirect() {
    	this.setState({
    		"redirect": true
    	})
    }

    render() {
        return (
        	<Box>
        	<Heading className="has-text-danger">{T('subscribe-failed')}</Heading>
        	{this.state.redirect ? redirectTo("/dashboard") : "" }
        	</Box>
        );
    }
}

export default PaymentFailed;