import React from "react";
import { Button } from "react-bulma-components";
import { LocalIcon } from "whitelables/wl-name/icons";
import s from "./styles.module.scss";

const ToggleButtonGroup = ({ list, isActive, onChange }) => {
  return (
    <div className={s.wrapper}>
      {list.map((item) => (
        <Button
          key={item.id}
          className={`${s.button} ${item.id === isActive ? s.active : ""}`}
          onClick={() => onChange(item.id)}
        >
          <LocalIcon icon={item.icon} size="middle" />
        </Button>
      ))}
    </div>
  );
};

export default ToggleButtonGroup;
