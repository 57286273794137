import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";

import ProductsStore from './products-store'

import OpenModal from 'components/modal/modal';
import ViewProductModal from "./modal/view-product/view-product-modal";

class ReportProductModal extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {pid} = this.props.location.state;

        let backModal = e => {
          window.history.back();
        }

        return (
          <OpenModal modal={{ closeOnBlur: false }} close={backModal}>
              <ViewProductModal
              	pid={pid}
              	/>
          </OpenModal>
        );
    }

}


export default connectToStores(ReportProductModal, { 
    products: ProductsStore 
  });