import React from "react";
import T from "../../../components/i18n";
import AppStore from "../../../app-store";
import Container from "../../common/container/container";
import s from "./styles.module.scss";
import { Link } from "react-router-dom";
import Title from "../../common/title/title";
import SubTitle from "../../common/sub-title/sub-title";

const OfferBlock = ({ title, subTitle }) => {
  return (
    <Container classNameWrapper={s.wrapper}>
      <Title color='black'>{T(title)}</Title>
      <SubTitle>
        {T(subTitle)}
      </SubTitle>
      <Link to={AppStore.getLink("registration")} className={s.link}>
        {T("try-free")}
      </Link>
    </Container>
  );
};

export default OfferBlock;
