import Decimal from "decimal.js";
import ExpensesStore from "../../modules/expenses/expenses-store";

export const roundToNearest10Kopecks = (amount, account) => {
	if(account) {
		const dataAccount = ExpensesStore.getAccount(account);
		if(dataAccount && parseInt(dataAccount.types) === 0) {
			let result = 0;
			const remainder = amount % 0.1;

			if (remainder >= 0 && remainder <= 0.04) {
				result = Math.floor(amount * 10) / 10;
			} else if (remainder > 0.04 && remainder <= 0.09) {
				result = Math.ceil(amount * 10) / 10;
			} else {
				result = amount;
			}

			return Decimal(result || 0).toFixed(2);
		} else {
			return amount
		}
	}
}