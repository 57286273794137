import React from 'react'
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";

const EmptyList = () => {
	return (
		<Heading size={5} className="has-text-danger ">
			{T("select-min-one-item")}
		</Heading>
	);
};

export default EmptyList;