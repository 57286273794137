import React, {useEffect, useRef, useState} from 'react'
import { LocalIcon } from 'whitelables/wl-name/icons'
import List from "react-bulma-components/lib/components/list";
import SortItem from "./sort-item";

const SortMobile = ({data,defaultActive, onChange}) => {
	const [isOpen, setIsOpen] = useState(false)
	const containerRef = useRef(null);
	const handleChangeSort = ({value, status}) => {
		onChange && onChange({[value]: status})
		setIsOpen(false)
	}

	useEffect(() => {
		const handleClickOutside =(event) => {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		}

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<div className='display-flex-row position-relative' ref={containerRef}>
			<span onClick={() => setIsOpen(prevState => !prevState)}>
				<LocalIcon icon='sort' size='large'/>
			</span>
			{isOpen && (
				<List className='sort-list'>
					{data.map(item => <SortItem key={item.value} {...item} defaultActive={defaultActive && defaultActive[item.value]} onClick={handleChangeSort}/> )}
				</List>
			)}
		</div>
	);
};

export default SortMobile;