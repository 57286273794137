import React from "react";
import ChatAvatar from "./chat-avatar";
import { formatDate, getSystemId } from "../utils";
import UserStore from "../../../user/user-store";
import ClientsStore from "../../clients/clients-store";
import StatusButton from "./status-button";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import {parseJSON} from "../../../tools/error-handler";
import s from './styles.module.scss'

const ChatUserInfo = ({ data, isActive }) => {
  const system_id = getSystemId(data);
  const {isMobileSize} = useIsMobile()

  data.name =
    data.name && data.name !== "  " ? data.name : data.remote_client_name;

  const getClient = data?.client_id && ClientsStore.getClientById(data.client_id)


  const extra = parseJSON(data?.extra);

  return (
    <div
      style={{ padding: "15px 10px", gap: 25}}
      className="display-flex-row cursor-pointer"
    >
      <ChatAvatar
        uid={UserStore.get("id")}
        not_read={data.not_read}
        remote_id={data.remote_chat_id}
        sis={system_id}
      />
      <div className="display-flex-column" style={{ flexGrow: 1, gap: 5, width: '100px' }}>
        <div className="display-flex-row-gap">
          <p className="name bold font-size-120 overflow-ellipsis">{getClient ? getClient.simple_name : data.name}</p>
          <div className="timer-wrap">
            <div className="timer">{formatDate(data.last_date)}</div>
          </div>
        </div>
        {extra?.advert?.title && (
          <p className={`${s.title_order} ${isActive ? s.title_order_active : ''} overflow-ellipsis`}>
                {extra?.advert?.title}
          </p>)}
        <div className="display-flex-row" style={{justifyContent: "flex-end"}}>
          {data.last_message && (
            <p className='overflow-ellipsis'
               style={{textAlign: "left", flex: 1}}>
            <span>
              {data.last_message.indexOf("<photo") > -1
                ? "photo"
                : data.last_message}
            </span>
            </p>
          )}
          {isMobileSize && <StatusButton name={data.status} chatId={data?.id}/>}
        </div>
      </div>
    </div>
  );
};

export default ChatUserInfo;
