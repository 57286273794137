import React from 'react'
import VotingStore from "./voting-store";
import {connectToStores} from "../../tools/reflux-tools";
import VoteList from "./vote-list/vote-list";

const Voting = () => {
	const data = VotingStore.getVote()

	return (
		<>
			{data.map(item => <VoteList key={item.id} data={item} voteResult={VotingStore.getVoteResult(item.id)} />)}
		</>
	);
};

export default connectToStores(Voting, {_: VotingStore});