import React, {Component} from 'react';
// import Reflux from 'reflux';
// import T from "../../components/i18n";
import UserActions from '../user-actions';
// import UserStore from '../user-store';
// import { redirectTo } from 'tools/redirect-to'

// import { Field, Control, Input, Label, Help } from 'react-bulma-components/lib/components/form';
// import Box from 'react-bulma-components/lib/components/box';
// import Heading from 'react-bulma-components/lib/components/heading';

// import AppStore from 'app-store';


class RemoteLogin extends Component {
  constructor(props) {
        super(props);
        this.state = {
        };


  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = atob(urlParams.get('t'));

    UserActions.changeToken(token);

    this.loginComplete = UserActions.changeToken.completed.listen((data) => {
        setTimeout(()=>{window.location.href="/start";},100);
    });

    setTimeout(()=>{window.location.href="/start";},500);

    //  this.loginFailed = UserActions.login.failed.listen((data) => {
    //       this.setState({
    //         'email': {
    //           'error': T(data),
    //           'value': this.state.email.value
    //         }
    //       });
    //  });
  }

  componentWillUnmount() {
    if (typeof(this.loginComplete) == "function") {
          this.loginComplete();
    }

    // if (typeof(this.loginFailed) == "function") {
    //       this.loginFailed();
    // }
  }

  render () {
        return (
          <b></b>
        );
    }
};

export default RemoteLogin;

