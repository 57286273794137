import React, { Component } from 'react';
import T from "components/i18n";

import { Field, Control, Label, InputFile, Input } from 'react-bulma-components/lib/components/form';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Icon from 'react-bulma-components/lib/components/icon';
import Content from 'react-bulma-components/lib/components/content';

import SelectComponent from 'tools/select-component'
import SubmitButton from 'components/submit-button';
// import User from 'user/user-store';

import WarehouseStore from 'modules/warehouse/warehouse-store'
import WarehouseActions from 'modules/warehouse/warehouse-actions'

class LoadFilesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          template: {
                error: "",
                value: "0"
          },
          selectedFile: null,
          enteredUrl: null,
          waiting: props.waiting || false,
          mid: { value: props.mid || WarehouseStore.getDefaultWarehouse() },
          sid: { value: props.sid}
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onChangeUrl = this.onChangeUrl.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
      this.setState({
        waiting: nextProps.waiting
      })
    }

    submit() {
        const template = this.state.template.value,
            mid = this.state.mid.value,
            sid = this.state.sid.value,
            {submit} = this.props;

      this.setState({ "waiting": true });
      this.props.add_xml ? submit(this.state.selectedFile, this.state.enteredUrl, mid) :
        sid ? submit(sid, mid, this.state.selectedFile) :
        submit(this.state.selectedFile, mid, template);
    }

    onChangeFile(event) {
      console.log(event.target.files[0])
        this.setState({
          selectedFile: event.target.files[0],
          loaded: 0,
        })
    }
  
    onChangeUrl(event) {
        this.setState({
          enteredUrl: event.target.value,
        })
    }

    onChangeSelect(selectedOption, actions) {
      this.setState({
          [actions.name]:  selectedOption,
      });
    }


    onChange(selectedOption) {
        this.setState({
          'template': {
            'value': selectedOption
          }
        });
    }

                // <Field>
                //   <Control>
                //     <Label>{T('file-templates')}</Label>
                //     <Select 
                //         onChange={this.onChange} 
                //         defaultValue="0"
                //         isSearchable
                //         name="template"
                //         value={this.state.template.value}
                //         options={templates} />
                //   </Control>
                // </Field>

    render () {
      const {base_text, not_warehouse, button_text, add_xml, sid} = this.props;
        return (
              <Box>
            <Content>
              {base_text ? T(base_text) : T('load-excel-desk')}
                 </Content>
            {
              // User.getModuleEnable("warehouse") &&
                !not_warehouse && !sid ? 
                  <Field>
                      <SelectComponent 
                          name="mid"
                          label="product-marketplace"
                          onChange={this.onChangeSelect} 
                          value={this.state.mid} 
                          list={WarehouseStore.getWarehouseList}
                          load={WarehouseActions.load}
                          creatable={false}/>
                  </Field>
              : ""}
            {add_xml ? <><Field>
              <Label>{T('link')}</Label>
              <Control>
                <Input
                  onChange={this.onChangeUrl}
                  name='url'
                  type="text"
                  value={this.state.enteredUrl || ""}
                  placeholder={T('enter-url')} />
              </Control>
            </Field><p className={'text-align-center'}>{T('or-option')}</p></> : null}
                <Field>
                  <Label>{T('file')}</Label>
                  <Control>
                    <InputFile 
                      onChange={this.onChangeFile} 
                      accept={add_xml ? "application/xml, text/xml" : ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                      icon={<Icon icon="upload" />} boxed placeholder={T('choose-a-file')} />
                  </Control>
                </Field>
                <Button.Group>
                  <SubmitButton fullwidth size="large" text={button_text || 'load-from-excel-button'} waiting={this.state.waiting} submit={this.submit}/>
                </Button.Group>
              </Box> 
        );
    }

}

export default LoadFilesForm;