import React from 'react';
import T from "components/i18n";

import Button from 'react-bulma-components/lib/components/button';
import Loader from 'react-loader-spinner'


const SubmitButton = ({submit, text, waiting, size, fullwidth, renderAs, color, disabled, style}) => {
	if (typeof(fullwidth) === "undefined") {
		fullwidth = true;
	}

	if (typeof(renderAs) === "undefined") {
		renderAs = 'button';
	}
	return (
		<Button fullwidth={fullwidth} renderAs={renderAs} size={size || size === "" ? size : "large"}
			 disabled={waiting || disabled}  rounded color={color || "primary"}
						style={style}
            onClick={submit}>{waiting ? <Loader type="Circles" className="button_loader" color="#FFFFFF" height={30} width={30} /> : null} {T(text)}</Button>
	)

}

export default SubmitButton;