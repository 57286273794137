import React, {useState} from 'react'
import PaginationBlock from "../../../components/pagination";
import OrdersActions from "../orders-actions";
import OrdersStore from "../orders-store";
import Button from "react-bulma-components/lib/components/button";
import T from "../../../components/i18n";
import Reports from "../../../components/reports";
import EmptyMessage from "./empty-message";
import OrderRowMobile from "../mobile/order-row-mobile";
import OrderRowDesktop from "../desktop/order-row-desktop";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const COLUMNS = [
	"",
	"id",
	"order",
	"customer",
	"product-info-price",
	"status",
	"reports-actions",
];

const ReportOrder = () => {
	const {isMobileSize} = useIsMobile()
	const [isOpenRow, setIsOpenRow] = useState(false);

	return (
		<>
			<PaginationBlock
				setPage={OrdersActions.setPage}
				totalPage={OrdersStore.get("count_page")}
				currentPage={OrdersStore.get("page")}
			/>

			{isMobileSize && OrdersStore.getPageList() > 1 && (
				<div align="right">
					<Button
						onClick={() => setIsOpenRow((prev) => !prev)}
						style={{ fontSize: "12px" }}
					>
						{isOpenRow ? T("hide-all-orders-btn") : T("show-all-order-btn")}
					</Button>
				</div>
			)}

			<Reports
				emptyMessage={EmptyMessage}
				columns={COLUMNS}
				hideFooterOnEmpty
				isStriped={false}
				isLoading={OrdersStore.get("waiting")}
				load={OrdersActions.load}
				rows={OrdersStore.getPageList()}
			>
				{isMobileSize ? (
					<OrderRowMobile
						role="row"
						columnLength={COLUMNS.length}
						triggerIsOpen={isOpenRow}
					/>
				) : (
					<OrderRowDesktop role="row" />
				)}
			</Reports>
			<PaginationBlock
				setPage={OrdersActions.setPage}
				totalPage={OrdersStore.get("count_page")}
				currentPage={OrdersStore.get("page")}
			/>
		</>
	);
};

export default ReportOrder;