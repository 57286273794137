import React from "react";
import { Box, Heading } from "react-bulma-components";
import T from "../../../components/i18n";

const EmptyMessage = () => {
  return (
    <Box align="center">
      <Heading>{T("not-have-chats")}</Heading>
    </Box>
  );
};

export default EmptyMessage;
