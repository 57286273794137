import React from "react";
import Tabs from "react-bulma-components/lib/components/tabs";
import TabItem from "./tab-item";
import {useIsMobile} from "../is-mobile-context/is-mobile-context";

const TabList = ({ list, isActive, onChange }) => {
  const {isMobileSize} = useIsMobile()
  return (
    !!list.length && (
      <Tabs type="boxed" fullwidth={false} size={isMobileSize ? "small" : "medium"}>
        {list.map((item) => (
          <TabItem
            key={item.tab}
            tab={item.tab}
            label={item.label}
            count={item?.count}
            isActive={isActive}
            onChange={onChange}
          />
        ))}
      </Tabs>
    )
  );
};

export default TabList;
