import React from 'react'
import CatalogSingleList from "./catalog-single-list";
import {Label} from "react-bulma-components/lib/components/form";
import T from '../../../../components/i18n'

const CatalogMultiList = ({lists, modal, ...rest}) => {
	return lists.map(item => (
		<React.Fragment key={item.label}>
			<Label align='left'>{T(item.label)}</Label>
			<CatalogSingleList list={item.list} modal={modal} type={item.type} {...rest}/>
		</React.Fragment>
	))
};

export default CatalogMultiList;