import React, {Component} from 'react';
// import Reflux from 'reflux';
import T from "../../components/i18n";
import UserActions from '../user-actions';
import { redirectTo } from 'tools/redirect-to'
import { backLink } from 'tools/back-link'

import { Field, Control, Input } from 'react-bulma-components/lib/components/form';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Button from 'react-bulma-components/lib/components/button';

import AppStore from 'app-store';
import Messages from 'components/messages';


class RecoveryPassForm extends Component {
  constructor(props) {
        super(props);
        this.state = {
          email: {
                error: "",
                value: ""
          },
          redirect: false
        };

        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.loginComplete = UserActions.recoveryPass.completed.listen((data) => {
        console.log("data", data)
        if (data['status'] === "OK") {
          this.setState({
            redirect: true
          });
        }
     });

     this.loginFailed = UserActions.recoveryPass.failed.listen((res) => {
          const data = JSON.parse(res['response']);
          this.setState({
            'error':T(data['error']['code'])
          });
     });

  }

  componentWillUnmount() {
    if (typeof(this.loginComplete) == "function") {
          this.loginComplete();
    }

    if (typeof(this.loginFailed) == "function") {
          this.loginFailed();
    }
  }

  submit() {
    const email = this.state.email.value;

    console.log("start recovery...");
    UserActions.recoveryPass(email)
  }


  onChange(evt) {
      const value = evt.target.value,
            name = evt.target.name;

      this.setState({
        [name]: {
          'value': value
        }
      });
  }

  render () {
        return (
            <>
              <Box>
                <Messages message={this.state.message || this.state.error} color={this.state.error ? 'danger' : 'success'} close={() => this.setState({message: null, error: null})} />
                <Heading>{T('recovery-password')}</Heading>
                <Field>
                  <Control>
                    <Input size="large" 
                          name="email" type="text" 
                          placeholder="E-mail"
                          onChange={this.onChange} 
                          color={(this.state.email.error) ? "danger" : "primary"}
                          value={this.state.email.value}
                          />
                  </Control>
                </Field>
                <Button.Group>
                  <Button fullwidth size="large"  rounded color="primary"
                    onClick={this.submit}
                    >{T('recovery-password-btn')}</Button>
                </Button.Group>
                {this.state.redirect ? redirectTo(AppStore.getLink("recovery-change-pass")) : null}
              </Box>

              {AppStore.isMobileApp() ? backLink() : null}
            </>

        );
    }
};

export default RecoveryPassForm;