import React, { Component } from 'react';

import T from "components/i18n";

import { getDefaultWarehouseValue, buildStock } from '../common/function'

import WarehouseStore from 'modules/warehouse/warehouse-store'
import WarehouseActions from 'modules/warehouse/warehouse-actions'

import Columns from 'react-bulma-components/lib/components/columns';
import SelectComponent from 'tools/select-component'
import calcMargin from 'tools/calc-margin';

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Actions from '../products-actions'
import ProductsStore from '../products-store'

import { 
    Field, 
    Control, 
    Input, 
    Label, 
} from 'react-bulma-components/lib/components/form';
import Button from 'react-bulma-components/lib/components/button';

import User from 'user/user-store';
import confirm from 'tools/confirm'
import ShipmentsActions from "../../shipments/shipments-actions";
import ShipmentsStore from "../../shipments/shipments-store";
import {connectToStores} from "../../../tools/reflux-tools";
import {
  withIsMobileSize
} from "../../../components/is-mobile-context/is-mobile-context";

class RenderWarehouseBlock extends Component {
    constructor(props) {
        super(props);
        const {onChange} = this.props;
        const stock = buildStock(props);
        this.state = {
          warehouses: stock,
          pid: props.pid,
          price: props?.price || 0,
        };
        this.availableWarehouses = WarehouseStore.getWarehouseList()

        this.onChangeSelectWarehouse = this.onChangeSelectWarehouse.bind(this);
        this.onChangeWarehouse = this.onChangeWarehouse.bind(this);
        this.addNewWarehouse = this.addNewWarehouse.bind(this);
        this.filterMidForSelect = this.filterMidForSelect.bind(this);
        this.isDelete = this.isDelete.bind(this);
        this.onChange = onChange.bind(this);
    }

    componentDidMount() {
      if(this.props.pid) {
        ShipmentsActions.loadByPid(this.props.pid);
      }
    }

  onChangeSelectWarehouse(selectedOption, actions) {
      let name = actions.name,
        index = name.split("_")[2],
        warehouses = this.state.warehouses;

      warehouses[index]['mid'] = selectedOption;

      this.setState({
          "warehouses":  warehouses,
      });

      this.onChange(warehouses);
    }

    onChangeWarehouse(evt) {
        let value = evt.target.value,
            raw_name = evt.target.name,
            warehouses = this.state.warehouses,
            parts = raw_name.split("_"),
            index = parts[2],
            name = parts[1];

        warehouses[index][name] = {
          'value': value,
          'error': ""
        };

        this.setState({
          warehouses: warehouses,
        });

        this.onChange(warehouses);
    }

    addNewWarehouse() {
      let warehouses = this.state.warehouses;

      const netprice = this.state.warehouses[0].netprice.value || false;
      const price = this.state.warehouses[0].price.value || false;

      warehouses.push({...getDefaultWarehouseValue({netprice: netprice, price: price, mid: this.availableWarehouses[0].id, isNew: true})});
      this.setState({
        warehouses: warehouses
      })

      this.onChange(warehouses);
    }

    renderWarehouseBlock() {
      let response = [];
      for (let i in this.state.warehouses) {
          response.push(this.renderPriceBlock(i))
      }

      return response;
    }

    filterMidForSelect(list, warehouses, ignoreID) {
        const filterList = list.filter(({id: midID}) => {
          return !warehouses.some(({mid:valueMid}) => midID === valueMid.value) || ignoreID === midID
        })


      return filterList

    }

    renderMidAndQuantity(i) {
      let list =  WarehouseStore.getWarehouseList()
          list = this.filterMidForSelect(list, this.state.warehouses, this.state.warehouses[i]['mid'].value)
      this.availableWarehouses = list.filter(item => item.id !== this.state.warehouses[i]['mid'].value)
      return (
          <>
          {/* {User.getModuleEnable("warehouse") ?  */}
                  <Columns.Column>
                    <Field style={{minWidth: 200}}>
                      <Control>
                        <SelectComponent
                          name={"warehouse_mid_"+i}
                          label="product-marketplace"
                          onChange={this.onChangeSelectWarehouse} 
                          value={this.state.warehouses[i]['mid'] || {value: 0}}
                          list={() => list}
                          readOnly={this.state.warehouses[i]['wid'] ? true : false}
                          // isOptionDisabled={option => disableWid.indexOf(option.value)}
                          load={WarehouseActions.load}
                          creatable={false}/>
                      </Control>
                    </Field>
                  </Columns.Column>
                  {/* : "" } */}

                  {WarehouseStore.isDropshipping(this.state.warehouses[i].mid.value) || !User.getPermissionEnable("editinstock") || parseInt(this.props.typeProduct || 0) === 4  ? null :
                  <Columns.Column>
                      <Field>
                        <Control>
                          <Label>{T('product-quantity')}</Label>
                          <Input  size="large" 
                                name={"warehouse_quantity_"+i}
                                type="number"
                                min="0"
                                onWheel={(e) => e.target.blur()}
                                onChange={this.onChangeWarehouse} 
                                color={(this.state.warehouses[i].quantity.error) ? "danger" : "primary"}
                                value={this.state.warehouses[i].quantity.value}
                                />
                        </Control>
                      </Field>
                  </Columns.Column>
                  }

          </>
        )
    }

    isNetDisabled(i) {
      return this.state.pid && (this.state.warehouses[i]['current_sid'] || this.state.warehouses[i]['is_calc']);
    }

    deleteItem(i) {
      this.state.warehouses.splice(i, 1)
      this.setState({
        warehouses: this.state.warehouses
      })
    }

    deleteButton(i, pid) {
      const stock = this.state.warehouses[i];

      let func = () => confirm('confirm-delete', T('are-you-sure-to-delete-this-product'), () => {this.deleteItem(i); if (stock['wid']) {Actions.deleteProduct(pid);}}, T('delete'))

      return (
          <Link to="#" onClick={func} title={T('delete')}>
                {this.props.isMobileSize ? <span><FontAwesomeIcon icon="trash-alt" size="1x" /> {T('delete-from-warehouse')} </span> : <FontAwesomeIcon icon="trash-alt" size="1x" />}
          </Link>
        )
    }

    isDelete(index) {
      const warehouse = this.state.warehouses

      const warehouseFilterIsNotNew = warehouse.filter(item => !item?.isNew)

      if(this.state.pid && User.getPermissionEnable("deleteproducts")) {
        if(warehouse[index].isNew) {
          return true
        } else if(warehouseFilterIsNotNew.length > 1) {
          return true
        }
      }

      return false
    }

    renderNetandPrice(i) {
      const {onClose, onCloseProduct} = this.props;
      const stock = this.state.warehouses[i];
      const margin = calcMargin(stock.netprice.value, stock.price.value);
      const sid = stock['current_sid'];

      return (
          <>
          {!User.getPermissionEnable("rawcost") ? null : 
                  <Columns.Column>
                      <Field>
                        <Control>
                          <Label>{T('netcost')}</Label>
                          <Input  size="large" 
                                name={"warehouse_netprice_"+i}
                                readOnly={this.isNetDisabled(i) ? true : false}
                                disabled={this.isNetDisabled(i) ? true : false}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                onChange={this.onChangeWarehouse}
                                color={(stock.netprice.error) ? "danger" : "primary"}
                                value={stock.netprice.value}
                                />
                          {sid && this.state.pid && <div><Link to={{
                            pathname: `/shipments`,
                            search: `sid=${sid}`,
                            state: {
                              period: ShipmentsStore.getShipmentDeliveryDate(this.state.pid, sid)
                            }
                          }} onClick={() => {
                            onClose()
                            onCloseProduct && onCloseProduct()
                          }}>{T('shipment')} №{sid}</Link></div>}
                        </Control>
                      </Field>
                  </Columns.Column>
                  }
                  {User.getPermissionEnable("salesprice") && <Columns.Column>
                    <Field>
                      <Control>
                        <Label>{T('products-price')}</Label>
                        <Input size="large"
                               name={"warehouse_price_" + i}
                               type="number"
                               onWheel={(e) => e.target.blur()}
                               onChange={this.onChangeWarehouse}
                               color={(stock.price.error) ? "danger" : "primary"}
                               value={stock.price.value}
                        />
                        {!User.getPermissionEnable("incomeinformation") ? null :
                          <div align="right">{T('margin')} {margin}%</div>
                        }
                      </Control>
                    </Field>
                  </Columns.Column>}
                  {this.isDelete(i) && !this.props.isMobileSize ?
                  <Columns.Column className="delete-on-sale">
                    {this.deleteButton(i, ProductsStore.getIdent(this.state.pid, stock.mid.value))}
                  </Columns.Column> : null}
          </>
        )
    }

    renderPriceBlock(i) {
      return this.props.isMobileSize ? this.renderPriceBlockMobile(i) : this.renderPriceBlockDesktop(i);
    }

    renderPriceBlockMobile(i) {
      const stock = this.state.warehouses[i];
      return (
        <div key={"priceBlock"+i}>
            <Columns  className="is-mobile">
                  {this.renderMidAndQuantity(i)}
            </Columns>
            <Columns  className="is-mobile">
                  {this.renderNetandPrice(i)}
            </Columns>
            {this.isDelete(i) ? <div align="right">{this.deleteButton(i, ProductsStore.getIdent(this.state.pid, stock.mid.value))}</div> : null}
            <hr />
        </div>
      );
    }

    renderPriceBlockDesktop(i) {
      return (
        <div key={"priceBlock"+i} className="price-block">
            <Columns  className={this.props.isMobileSize ? "is-mobile" : ""}>
                  {this.renderMidAndQuantity(i)}
                  {this.renderNetandPrice(i)}
            </Columns>

        </div>
      );
    }

    render() {
       const {source} = this.props;
        const warehouse_list = WarehouseStore.getWarehouseList();

        return (
          <div>
              {this.renderWarehouseBlock()}
              {warehouse_list.length > 1 && source !== "sales" && Object.keys(this.state.warehouses).length < warehouse_list.length ? 
                <Button.Group position="right">
                     <Button size="small" rounded color="info" onClick={this.addNewWarehouse}>{T('add-to-warehouse')}</Button>
                </Button.Group>
            : null}
          </div>
        );
    }
}

export default connectToStores(withIsMobileSize(RenderWarehouseBlock), {ship: ShipmentsStore})
