import React from 'react'
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import OpenInsideModal from "../../../components/modal/open-inside-modal";
import AddProductions from "../add-production";

const EmptyMessage = () => {
	return (
		<Box align="center">
			<Heading>{T('not-have-production')}</Heading>
			<OpenInsideModal text={T('create-production')} icon="plus-circle">
				<AddProductions />
			</OpenInsideModal>

		</Box>
	);
};

export default EmptyMessage;