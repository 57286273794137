import React from "react";
import Container from "../../common/container/container";
import s from "./styles.module.scss";
import T from "../../../components/i18n";
import Title from "../../common/title/title";

const InfoItem = ({ img, title, text }) => {
  return (
    <article className={s.item}>
      <img src={img} alt={T(text)} className={s.img} />
      <h4 className={s.title_item}>{T(title)}</h4>
      <p>{T(text)}</p>
    </article>
  );
};

const InfoBlock = ({list, title}) => {
  return (
    <Container classNameContainer={s.section}>
      {title && <Title>{T(title)}</Title>}
      <section className={s.list}>
        {list.map((item) => (
          <InfoItem key={item.text} {...item} />
        ))}
      </section>
    </Container>
  );
};

export default InfoBlock;
