import React from 'react'
import SalesStore from "../../../../sales/sales-store";
import ChannelBlock
	from "../../../../../components/channel-block/channel-block";
import User from "../../../../../user/user-store";
import AppStore from "../../../../../app-store";
import BindingIntegrationProductsModal
	from "../../../binding-integration-products-modal";
import UserStore from "../../../../../user/user-store";

const IntegrationField = ({iid, pid}) => {
	let channel_id = SalesStore.foundChannelsByIntegrationId(iid);
	const getIntegration = UserStore.getIntegrationById(iid);
	return (
		<ChannelBlock channel_id={channel_id} onClick={() => {
			User.isAdmin() ?  AppStore.openModal(<BindingIntegrationProductsModal modal='binding-modal' product_id={pid} product_mid={getIntegration.ident.mid} integration_id={iid}/>) : console.log("false")}}/>
	);
};

export default IntegrationField;