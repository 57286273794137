import React from "react";
import ShipmentsStore from "../../../../shipments/shipments-store";
import {applyFilter} from "../utils";
import List from "react-bulma-components/lib/components/list";
import {Box, Heading} from "react-bulma-components";
import T from "../../../../../components/i18n";
import usePaginationHook from "../../../../../tools/pagination-hook";
import ShipmentProductRow from "./shipment-product-row";
import StatisticsBlock from "../common/statistics-block";
import User from "../../../../../user/user-store";
import Money from "../../../../../components/money";
import DOM from "react-dom-factories";
import ProductsStore from "../../../products-store";
import DateRange from "../../../../../components/base-filter-layout/date-range";
import ShipmentsActions from "../../../../shipments/shipments-actions";

const ShipmentProductBlock = ({ pid, units }) => {
  const shipments = ShipmentsStore.getShipmentByProductId(pid);
  const filtered = shipments.filter((data) =>
    applyFilter(data, "delivered_date", false, ShipmentsStore, false, true)
  );

  const handleChangeDate = (value) => {
    const daysKey = Object.keys(value).reverse();
    daysKey.forEach((item) => {
      ShipmentsActions.filter(item, value[item]);
    });
  };

  const { page, end, start, total, setPage, PaginationBlock } =
    usePaginationHook({ listLength: filtered.length, perPage: 4 });
  const calcStatistics = filtered.reduce(
    (acc, curr) => {
      acc.count += 1;
      acc.saled += curr["quantity_sold"];
      acc.received += curr["quantity_received"];
      acc.item_amount += curr["item_amount"];
      return acc;
    },
    {
      count: 0,
      received: 0,
      saled: 0,
      item_amount: 0,
    }
  );

  const statisticsList = [
    { name: "dashboard-total-shipments", value: calcStatistics.count },
    {
      name: "quantity-res-mob",
      value: ProductsStore.getStockUnits(calcStatistics["received"], units),
    },
    {
      name: "involved",
      value: ProductsStore.getStockUnits(calcStatistics["saled"], units),
    },
  ];

  if (User.getPermissionEnable("incomeinformation")) {
    statisticsList.push({
      name: "on-amount",
      value: (
        <Money
          amount={calcStatistics["item_amount"]}
          className="has-text-danger"
          wrapper={DOM.span}
        />
      ),
    });
  }

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          borderRadius: "0 0 16px 16px",
        }}
      >
        <DateRange
          date_start={ShipmentsStore.getFilter("date_start")}
          date_end={ShipmentsStore.getFilter("date_end")}
          onChange={handleChangeDate}
        />
      </Box>
      <StatisticsBlock list={statisticsList} />
      <List>
        {filtered?.slice(start, end).map((item) => (
          <ShipmentProductRow key={item.id} data={item} units={units} />
        ))}
        {filtered.length === 0 && (
          <div align="center" className="margin-10">
            <Heading size={4}>{T("not-have-shipments")}</Heading>
          </div>
        )}
      </List>
      <div className="display-flex-center">
        <PaginationBlock
          totalPage={total}
          setPage={setPage}
          currentPage={page}
          showPrevNext={false}
        />
      </div>
    </>
  );
};

export default ShipmentProductBlock;
