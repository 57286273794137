import React, {useEffect, useState} from "react";
import s from "./styles.module.scss";
import Box from "react-bulma-components/lib/components/box";
import { Label } from "react-bulma-components/lib/components/form";
import T from "../i18n";
import Button from "react-bulma-components/lib/components/button";
import PreviewImage from "./preview-image";
import PreviewDocument from "./preview-document";
import ErrorMessageControlHook from "../error-message-block/error-message-control.hook";
import ProductsActions from "../../modules/products/products-actions";
import {errorHandler} from "../../tools/error-handler";

const LoadFileImage = ({ multiple,allowedTypes, onSubmit, onClose }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const {
    setErrorMessage,
    ErrorMessageBlock,
    errorMessage,
    handleAutoRemoveError,
  } = ErrorMessageControlHook();

  const isFileValid = (file) => {
    const maxSize = 10 * 1024 * 1024; // 10 MB
    if (file.size > maxSize) {
      handleAutoRemoveError("size-file-not-valid");
    }
    return allowedTypes.includes(file.type) && file.size <= maxSize;
  };

  const handleFiles = (files) => {
    const newFiles = Array.from(files).filter((file) => {
      const isValid = isFileValid(file);
      if (!isValid) {
        console.error(`Invalid file: ${file.name}`);
      }
      return isValid;
    });
    if(multiple) {
      setSelectedFiles([...selectedFiles, ...newFiles]);
    } else {
      setSelectedFiles(newFiles);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const renderPreviews = () => {
    return selectedFiles.map((file, index) => (
      <div key={index}>
        {file.type.includes("image") ? (
          <PreviewImage file={file} onRemove={handleRemoveFile} />
        ) : (
          <PreviewDocument file={file} onRemove={handleRemoveFile} />
        )}
      </div>
    ));
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files).filter((file) => {
      const isValid = isFileValid(file);
      if (!isValid) {
        console.error(`Invalid file: ${file.name}`);
      }
      return isValid;
    });

    if(multiple) {
      setSelectedFiles([...selectedFiles, ...newFiles]);
    } else {
      setSelectedFiles(newFiles);
    }
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit({ files: selectedFiles });
  };

  useEffect(() => {
    const loadImages = ProductsActions.uploadImages.completed.listen(() => {
      onClose()
    })
    const loadImagesFailed = ProductsActions.uploadImages.failed.listen((res) => {
      const error = errorHandler(res);
      handleAutoRemoveError(error)
    })
    return () => {
      loadImagesFailed()
      loadImages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <div className={s.file_wrapper}>
        <Box>
          <Label>{T("select-files", { count: selectedFiles.length })}</Label>
          {selectedFiles.length > 0 && (
            <div className={s.wrapper_preview} style={{}}>
              {renderPreviews()}
            </div>
          )}
          <input
            type="file"
            id="file"
            accept={allowedTypes.join(",")}
            className={s.file_input}
            onChange={handleFileChange}
            multiple={multiple}
          />
          <label
            htmlFor="file"
            className={s.label_file}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            {T("choose-a-file-10mb")}
          </label>
          <Button.Group position="centered">
            <Button size="medium" rounded color="light" onClick={onClose}>
              {T("undo")}
            </Button>
            <Button
              size="medium"
              rounded
              color="success"
              onClick={handleSubmit}
            >
              {T("save-btn-label")}
            </Button>
          </Button.Group>
        </Box>
      </div>
    </>
  );
};

export default LoadFileImage;
