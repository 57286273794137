import React from "react";
import UserStore from "../../../../../user/user-store";
import {
  Field,
  Input,
  Label,
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import Columns from "react-bulma-components/lib/components/columns";

const CustomPrice = ({ values, onChange }) => {
  const getCustomPriceField = UserStore.getCustomPrice();

  const chunkArray = (array, size) => {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }
    return chunked_arr;
  };

  const chunkedPriceFields = chunkArray(getCustomPriceField, 4);

  return (
    !!chunkedPriceFields.length && (
      <>
        <Label size="large">{T("custom-prices")}</Label>
        {chunkedPriceFields.map((chunk, chunkIndex) => (
          <Columns key={chunkIndex}>
            {chunk.map((name) => (
              <Columns.Column key={name}>
                <Field>
                  <Label>{name}</Label>
                  <Input
                    size="large"
                    name={name}
                    type="number"
                    autoComplete="nope"
                    onChange={onChange}
                    value={values[name]}
                  />
                </Field>
              </Columns.Column>
            ))}
          </Columns>
        ))}
      </>
    )
  );
};

export default CustomPrice;
