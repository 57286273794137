import React from "react";
import { connectToStores } from "tools/reflux-tools";

import SalesStore from "./sales-store";
import Actions from "./sales-actions";

import ProductsStore from "modules/products/products-store";
import SelectComponent from "tools/select-component";
import WarehouseActions from "modules/warehouse/warehouse-actions";
import User from "user/user-store";
import BaseFilter from "components/modules/base-filters";
import UserStore from "user/user-store";
import ClientsStore from "../clients/clients-store";

class SalesFilter extends BaseFilter {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      showStatus: true,
      buttonMoreFilter: true,
      showMoreFilter: false,
    };

    this.period_days = 1;
    this.store = SalesStore;
    this.actions = Actions;
  }

  selectChannel() {
    return (
      <SelectComponent
        name="channel"
        label="sale-channel"
        empty="all"
        onChange={this.onChangeSelect}
        value={SalesStore.getFilter("channel")}
        list={SalesStore.getChannelsList}
        load={Actions.loadChannels}
        creatable={false}
      />
    );
  }

  getUsersFilter() {
    let list = User.getSubUsersList();
    if (list.length <= 1) return null;

    return (
      UserStore.getPermissionEnable("show-all-sales") && (
        <SelectComponent
          name="rid"
          label="manager"
          empty="all"
          onChange={this.onChangeSelect}
          value={SalesStore.getFilter("rid")}
          list={User.getSubUsersList}
          load={WarehouseActions.load}
          creatable={false}
        />
      )
    );
  }

  selectCategory() {
    let list = ProductsStore.getCategoryList();
    if (list.length <= 1) return null;

    return (
      <SelectComponent
        name="category"
        label="product-category"
        empty={"all-categories"}
        onChange={this.onChangeSelect}
        value={SalesStore.getFilter("category")}
        list={ProductsStore.getFormattingCategoryList}
        load={Actions.loadCategory}
        creatable={false}
      />
    );
  }
  filterDeliver() {
    return (
      <SelectComponent
        name="delivery"
        label="delivery-service"
        empty="all"
        onChange={this.onChangeSelect}
        value={SalesStore.getFilter("delivery")}
        list={UserStore.getDeliveryServiceBase}
      />
    );
  }

  filterDeliverCity() {
    return (
      !!ClientsStore.getDeliveryListCity()?.length && (
        <SelectComponent
          name="deliveryCity"
          label="delivery-city"
          empty="all"
          onChange={this.onChangeSelect}
          value={SalesStore.getFilter("deliveryCity")}
          list={ClientsStore.getDeliveryListCity}
        />
      )
    );
  }

  filterDeliveredKeeperDate() {
    const list = [
      { name: "paid-storage", id: "paid-storage" },
      { name: "paid-storage-coming-soon", id: "paid-storage-coming-soon" },
    ];
    return (
      <SelectComponent
        name="deliveredKeeperDate"
        label="paid-storage-product"
        empty="all"
        onChange={this.onChangeSelect}
        value={SalesStore.getFilter("deliveredKeeperDate")}
        list={() => list}
      />
    );
  }

  filterCommission() {
    const list = [
      { name: "with-commission", id: "with-commission" },
      { name: "without-commission", id: "without-commission" },
    ];

    return (
      <SelectComponent
        name="commission"
        label="commission"
        empty="all"
        onChange={this.onChangeSelect}
        value={SalesStore.getFilter("commission")}
        list={() => list}
      />
    );
  }

  render() {
    return (
      <>
        {this.selectAccount()}
        {this.selectChannel()}
        {this.selectClients()}
        {this.getWarehouseFilter("history-data-marketplace_id")}
        {this.getUsersFilter()}
        {this.selectCategory()}
        {this.filterDeliver()}
        {this.filterDeliverCity()}
        {this.filterDeliveredKeeperDate()}
        {this.filterCommission()}
      </>
    );
  }
}

export default connectToStores(SalesFilter, { _: SalesStore });
