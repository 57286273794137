import React from "react";
import { Link } from "react-router-dom";
import AppStore from "../../app-store";
import s from "./style.module.scss";
import T from "../../components/i18n";
import OpenModalButton from "../../components/modal/open-modal-button";
import SelectLang from "../common/select-lang";
import NavMenu from "./nav-menu/nav-menu";
import { LocalIcon } from "whitelables/wl-name/icons";

const Header = ({linkList, logo,addLogoComponent}) => {
  return (
    <header className={s.wrapper}>
      <Link to={AppStore.getLink("/")} className={s.logo}>
        <img src={logo.src} alt={logo.alt} className={s.logo_img} />
        {addLogoComponent}
      </Link>
      <NavMenu list={linkList} />

      <section className={s.wrapper_actions}>
        {AppStore.getContactData("telegram_page") && <a
          className={s.join_telegram}
          href={AppStore.getContactData("telegram_page")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={s.telegram}>
            <LocalIcon icon="telegram" size="small"/>
          </span>
          {T("join-telegram-channel")}
        </a>}
        <article className={s.actions}>
          <OpenModalButton
            link={AppStore.getLink("registration")}
            text={T("try-free")}
            color="info"
          />
          <OpenModalButton
            link={AppStore.getLink("login")}
            text={T("login-btn-label")}
            className={"is-info-light"}
          />
        </article>
        <SelectLang />
      </section>
    </header>
  );
};

export default Header;
