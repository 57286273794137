import React from "react";
import s from "./styles.module.scss";
const loc = window.location;

let url = "";

if (loc.host === "localhost:3000") {
  url = "https://ac1.h-profit.com/";
}

const MessageItemImage = ({ data }) => {
  return (
    <div className={s.message_image_content}>
      <img
        className={s.message_image}
        src={`${url}${data.url}`}
        alt={data.name}
      />
    </div>
  );
};

export default MessageItemImage;
