import React, { useState } from "react";
import { connectToStores } from "tools/reflux-tools";
import ProductionStore from "./production-store";
import DynamicTabList from "../../components/dynamic-tab-list/dynamic-tab-list";
import Template from "./template/template";
import History from "./history/history";

const GeneralTabs = ({ tabs, onChange }) => {
  const list = [
    {
      label: "production-template",
      value: "production",
    },
    {
      label: "history-production",
      value: "history",
    },
    {
      label: "production-template-deleted",
      value: "production-deleted",
    },
  ];

  return (
    <DynamicTabList list={list} defaultTab={tabs} onChangeTab={onChange} />
  );
};

const Production = () => {
  const [generalTab, setGeneralTab] = useState("production");

  const handleChangeTab = (tab) => {
    setGeneralTab(tab);
  };


  return (
    <>
      <GeneralTabs tabs={generalTab} onChange={handleChangeTab} />
      {generalTab === "production" && <Template />}
      {generalTab === "history" && <History />}
      {generalTab === "production-deleted" && <Template isDeleted/>}
    </>
  );
};

export default connectToStores(Production, { _: ProductionStore });
