import React, {useEffect, useState} from 'react'
import SelectComponent from "../../../tools/select-component";
import ChatsStore from "../chats-store";
import {Box, Button, Heading} from "react-bulma-components";
import {connectToStores} from "../../../tools/reflux-tools";
import ChatsActions from "../chats-actions";
import T from '../../../components/i18n'
import SubmitButton from "../../../components/submit-button";
import ErrorMessageControlHook
	from "../../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../../tools/error-handler";
import AppStore from "../../../app-store";
import AddChatsStatus from "./add-chats-status";

const ChangeChatStatusModal = ({chatId,status, onClose}) => {
	const [value, setValue] = useState(status === 'created' ? false : {value: status})
	const [waiting, setWaiting] = useState(false)
	const {setErrorMessage,ErrorMessageBlock,errorMessage,handleAutoRemoveError} = ErrorMessageControlHook()

	const list = ChatsStore.getChatsStatus()

	const handleChange = (selectValue) => {
		setValue(selectValue)
	}

	const handleSubmit = () => {
		if(value.value) {
			const data = {
				id: value.value,
				chat_id: chatId,
			}
			setWaiting(true)
			ChatsActions.syncUserChatStatus(data)
		} else {
			handleAutoRemoveError('not all fields required')
		}
	}

	useEffect(() => {
		const syncChatCompleted = ChatsActions.syncUserChatStatus.completed.listen(() => {
			onClose()
		})
		const syncChatFailed = ChatsActions.syncUserChatStatus.failed.listen((res) => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})
		return () => {
			syncChatCompleted()
			syncChatFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
			<Box>
				<Heading size={4}>{T('change-order-status')}</Heading>
				<SelectComponent
					label='status'
					value={value}
					list={() => list}
					onChange={handleChange}
					placeholder='select-status'
					addItem={({name}) => AppStore.openModal(<AddChatsStatus name={name}/>)}
					addItemModal={() => AppStore.openModal(<AddChatsStatus/>)}
					creatable
				/>
				<div className='display-flex-row' style={{ justifyContent: 'flex-end' }}>
					<SubmitButton submit={handleSubmit} waiting={waiting} color='success' fullwidth={false} text='save-btn-label' />
					<Button onClick={onClose} size='large' rounded>{T('close')}</Button>
				</div>
			</Box>
		</>
	);
};

export default connectToStores(ChangeChatStatusModal, {ChatsStore});