import React from 'react';
import { connectToStores } from "tools/reflux-tools";
import T from "components/i18n";
import HistoryStore from "../history-store";
import Actions from '../history-actions';
import SelectComponent from 'tools/select-component';
import SelectProducts from 'components/select-products';
import BaseFilter from "components/modules/base-filters";
import User from "../../../user/user-store";
import UserStore from "../../../user/user-store";
import WarehouseActions from "../../warehouse/warehouse-actions";


class HistoryFilter extends BaseFilter {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.store = HistoryStore;
        this.actions = Actions;
    }

    onChangeSelectProduct(selectedOption) {
       Actions.filter("product", selectedOption);
    }

    searchString() {
        return (
            <SelectProducts
              onChange={this.onChangeSelectProduct}
              value={HistoryStore.getFilter("product")}
              single={true}
              quantity={1} />
        );
    }

    selectOperation() {
        return (
                <SelectComponent 
                          name="action"
                          label="history-actions"
                          empty="all"
                          isMulti={true}
                          placeholder={T('all')}
                          onChange={this.onChangeSelect} 
                          value={HistoryStore.getFilter("action")} 
                          list={HistoryStore.getActionList}
                          className='full-size-select'
                          creatable={false}/>
        );
    }
    getUsersFilter() {
        let list = User.getSubUsersList();
        if (list.length <= 1) return null;

        return UserStore.getPermissionEnable('show-all-sales') && (
          <SelectComponent
            name="rid"
            label="manager"
            empty="all"
            onChange={this.onChangeSelect}
            value={HistoryStore.getFilter("rid")}
            list={User.getSubUsersList}
            load={WarehouseActions.load}
            creatable={false}/>
        )
    }

    filterSource() {
        const generateIntegrationList = Array.from({length: 17}, (_, index) => ({id: index + 1, name: `integration-${index + 1}`}))
        const deleteUnnecessary = generateIntegrationList.filter((_, index) => ![0,2,8,9,11,13].includes(index) )
        const list = [
            {id: 'manual', name: 'manual'},
            {id: 'auto', name: 'auto'},
            {id: 'shipment_excel', name: 'shipment_excel'},
            {id: 'moving', name: 'moving'},
            {id: 'production', name: 'production'},
            {id: 'xml', name: 'xml'},
            {id: 'excel', name: 'excel'},
            {id: 'integration-1', name: 'delivery-1'},
            {id: 'integration-3', name: 'delivery-3'},
            {id: 'set_price', name: 'set_price'},
            {id: 'mass_change_price_increase', name: 'mass_change_price_increase'},
            {id: 'mass_change_price_decrease', name: 'mass_change_price_decrease'},
            {id: 'increase_update_mass_price', name: 'increase_update_mass_price'},
            {id: 'decrease_update_mass_price', name: 'decrease_update_mass_price'},
            {id: 'mass_change_price_exact', name: 'mass_change_price_exact'},
            ...deleteUnnecessary
        ]

        return <SelectComponent
          name="source"
          label="source"
          empty="all"
          onChange={this.onChangeSelect}
          value={HistoryStore.getFilter("source")}
          list={() => list}
          load={WarehouseActions.load}
          creatable={false}/>
    }

    render () {
        return (
            <>
              {this.searchString()}
              {this.filterSource()}
              {this.selectAccount()}
              {this.selectOperation()}
              {this.getWarehouseFilter('mid')}
              {this.getUsersFilter()}
              {this.getCategoryFilter('product-category')}
              {this.getBrandFilter('brand')}
            </>
          )
     }

}

export default connectToStores(HistoryFilter, { _: HistoryStore });