import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";
import SelectComponent from 'tools/select-component';
import ClientsStore from "modules/clients/clients-store";
import ClientsActions from 'modules/clients/clients-actions';
import ProductsStore from "modules/products/products-store";
import WarehouseStore from "modules/warehouse/warehouse-store";
import WarehouseActions from 'modules/warehouse/warehouse-actions';
import SalesStore from "modules/sales/sales-store";
import Actions from 'modules/sales/sales-actions';
import ShipmentsStore from "../../shipments/shipments-store";
import ShipmentsActions from "../../shipments/shipments-actions";
import ExpensesStore from "../../expenses/expenses-store";
import ExpensesActions from "../../expenses/expenses-actions";
import T from "../../../components/i18n";
import ProductsActions from "../../products/products-actions";

class ProductsFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showStatus: true
        };

    }

    componentWillUnmount() {
      ShipmentsStore.clearFilter()
      SalesStore.clearFilter()
    }

  onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name

        Actions.filter(name, value);
    }

    onChangeSelect(selectedOption, actionMeta) {
          Actions.filter(actionMeta.name, selectedOption);
    }

    componentDidUpdate(prevProps){
      const { status } = this.props;

      if(prevProps.status !== status ){
          if (status) {
              Actions.filter('status', status);
          }
      }
    }


    componentDidMount() {
      const { status } = this.props;


      if (status) {
          Actions.filter('status', status);

          if (status !== "all") {
            this.setState({
              "showStatus": false
            });
          }
      }
        // Actions.load();
    }

    onChangeSupplier(selectedOption, actionMeta) {
      ShipmentsActions.filter(actionMeta.name, selectedOption);
    }

    onChangeAttrFilter(value, {name}) {
      const attrFilter = ProductsStore.getFilter('attr')

      attrFilter[name] = value

      ProductsActions.filter('attr', attrFilter)
    }

    selectClients() {
        return (
                <SelectComponent 
                          name="clients"
                          label="clients"
                          empty="all"
                          onChange={this.onChangeSelect} 
                          value={SalesStore.getFilter("clients")} 
                          list={ClientsStore.getClientsList}
                          load={ClientsActions.load}
                          async={true}
                          creatable={false}/>
        );
    } 

    selectCategory() {
        return (
              <SelectComponent 
                              name="category"
                              label="product-category"
                              empty={'all-categories'}
                              onChange={this.onChangeSelect} 
                              value={SalesStore.getFilter("category")} 
                              list={ProductsStore.getFormattingCategoryList}
                              load={Actions.loadCategory}
                              creatable={false}/>
        );
    } 

    selectBrand() {
        return (
              <SelectComponent 
                              name="brand"
                              label="product-brand"
                              empty={'all-brands'}
                              onChange={this.onChangeSelect} 
                              value={SalesStore.getFilter("brand")}
                              list={ProductsStore.getBrandsList}
                              load={Actions.loadBrand}
                              creatable={false}/>
        );
    } 

    selectMarketplace() {
        return (
            <SelectComponent
                name="mid"
                label="product-marketplace"
                empty="all-warehouse"
                onChange={this.onChangeSelect}
                value={SalesStore.getFilter("mid")}
                list={WarehouseStore.getWarehouseList}
                load={WarehouseActions.load}
                creatable={false}/>
        );
    }

    supplierFilter() {
      return <SelectComponent
        name="supplier"
        label="suppliers"
        empty="all"
        onChange={this.onChangeSupplier}
        value={ShipmentsStore.getFilter("supplier")}
        list={ShipmentsStore.getSuppliersList}
        load={ShipmentsStore.load}
        creatable={false}/>
    }

    selectChannel() {
      return (
        <SelectComponent
          name="channel"
          label="sale-channel"
          empty="all"
          onChange={this.onChangeSelect}
          value={SalesStore.getFilter("channel")}
          list={SalesStore.getChannelsList}
          load={Actions.loadChannels}
          creatable={false}/>
      );
    }

    accountFilter() {
      return <SelectComponent
        name="account"
        label='sale-account'
        empty="all-account"
        onChange={this.onChangeSelect}
        value={SalesStore.getFilter("account")}
        list={() => ExpensesStore.getAccountsList([4], false, true)}
        load={ExpensesActions.loadAccounts}
        creatable={false}/>
    }

  filterAttr(getListAttr) {
    return getListAttr?.map(item => {
      const getValueItem = ProductsStore.getFilter('attr')[item.id] || {value: 0, label: T('all')}
      return !!ProductsStore.getListDescByAttr(item.id) && (
        <SelectComponent
          empty='all'
          key={item.id}
          name={item.id}
          label={item.name}
          onChange={this.onChangeAttrFilter}
          value={getValueItem}
          list={() => ProductsStore.getListDescByAttr(item.id)}
          creatable={false}
        />)
    })
  }

  render () {
        return (
            <>
              {this.accountFilter()}
              {this.selectCategory()}
              {this.selectBrand()}
              {this.selectMarketplace()}
              {this.selectClients()}
              {this.supplierFilter()}
              {this.selectChannel()}
              {this.filterAttr(ProductsStore.getAttrList())}
            </>
          )
     }

}

export default connectToStores(ProductsFilter, { _: SalesStore });