import React, { Component } from 'react';
import T from "components/i18n";

import { Field, Control, Label, InputFile } from 'react-bulma-components/lib/components/form';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Icon from 'react-bulma-components/lib/components/icon';


class LoadImagesForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedFile: null
        };

        this.onChangeFile = this.onChangeFile.bind(this);
        // this.submit = this.submit.bind(this);
        this.back = this.back.bind(this);
        this.done = this.done.bind(this);
    }

    // submit() {
    //     const {submit} = this.props;
    //     submit(this.state.selectedFile);
    // }

    back() {
        const {back} = this.props;
        back();
    }

    done() {
        const {done} = this.props;
        done();
    }

    onChangeFile(event) {
      const {submit} = this.props;
      submit(event.target.files[0]);

      // this.setState({
      //     selectedFile: event.target.files[0],
      //     loaded: 0,
      // })

    }

    render () {
        // const disabled = (!this.state.selectedFile) ? true : false;
        return (
              <Box>
                <Field>
                  <Label>{T('images')}</Label>
                  <Control>
                    <InputFile 
                      label={T('choose-a-images')} 
                      onChange={this.onChangeFile} 
                      multiple
                      fullwidth
                      className="text-align-center"
                      size="large"
                      inputProps={{
                        accept: "image/jpeg, image/png, image/webp, image/gif, image/svg+xml",
                        multiple: true
                      }}
                      icon={<Icon icon="upload" />} 
                      boxed 
                      />

                  </Control>
                </Field>
                <Button.Group position="centered">
                  <Button size="medium" rounded color="light"
                    onClick={this.back}
                    >{T('back-btn')}</Button>
                  <Button size="medium" rounded color="light"
                    onClick={this.done}
                    >{T('btn-done')}</Button>
                </Button.Group>
              </Box>
        );
    }

}

/*
                      <label className="file-label">
                        <input
                          accept: "image/jpeg, image/png, image/webp, image/gif, image/svg+xml"
                          multiple
                          name="imagesFiles"
                          type="file"
                          className="file-input"
                          onChange={this.onChange}
                        />
                        <span className="file-cta">
                          {icon && <span className="file-icon">{icon}</span>}
                          <span className="file-label">{label}</span>
                        </span>
                        {fileName && filename && (
                          <span className="file-name">{filename}</span>
                        )}
                      </label>*/

export default LoadImagesForm;