import React, { Component } from 'react';

import Content from 'react-bulma-components/lib/components/content';
import Section from 'react-bulma-components/lib/components/section';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import FooterBlock from '../common/footer'
import { backLink } from 'tools/back-link' 

class AgreementRU extends Component {
    render () {
        return (
            <Section>
            {backLink()}
            <Box>
            <Content align="left">
                <Heading> Публичный договор </Heading>
                <div align = "right"> 1 сентября 2020 </div>

                <p> Web-сайт https://h-profit.com (далее - Сервис) принадлежит на праве аренды ИП Шевкунов Александр Сергеевич, код ИНН 616612404282 (далее - Компания), предлагает любому физическому / юридическому лицу / предпринимателю, именуемом в дальнейшем «Пользователь», вместе именуемые в дальнейшем «Стороны», а отдельно - "Сторона", заключить этот Публичный договор, изложенный в стандартной и неизменной для всех форм в виде публичной оферты, путем принятия в целом и безусловно следующих условий: </p>

                <p> Сроки, используемый в договоре и их значения: </ p>
                <ul>
<li> a) Сервис - программное обеспечение "HugeProfit", предназначенное для простого ведения складского и финансового учета. Исключительные права на Сервис принадлежат Компании. Передача исключительных прав (полностью или в части) не является предметом настоящего Договора. Описание свойств Сервиса доступно пользователю на сайте сервиса по адресу h-profit.com. </li>

<li> b) Тарифы - прайс-лист компании, расположенный в сети Интернет по адресу https://h-profit.com/pricing/. </li>

<li> с) Выбранный Тариф - тариф выбравший и оплатил Пользователь из раздела Тарифы, регулирующий обьем услуг предоставляемый Пользователю. </li>

<li> d) Сайт Компании - сайт (Сервис), расположенный в сети Интернет по адресу h-profit.com. </li>

<li> e) Учетная запись - способ фиксации статистической, учетной и другой информации, которая является для идентификации пользователя с целью настоящего Договора. Учетной записи привязана информация, относящаяся к Пользователю, в том числе информация, указанная при регистрации, настройки работы с Сервисом, данные, внесенные пользователями Сервиса. </li>

<li> f) Логин и Пароль - уникальный набор символов, который создается пользователем при прохождении регистрации, предназначенный для доступа к Сервису. </li>

<li> g) Учетный период - период времени, не менее одного календарного месяца, на который оплачен доступ к сервисам. </li>

<li> h) Сервер - физический сервер, предназначенный для установки Сервиса. </li>

<li> i) Автопродление подписки - сервис автоматического продления выбранного Тарифа. </li>
</ul>


<Heading subtitle size={5}> 1. Предмет договора </Heading>
<p> 1.1. Согласно условиям этого договора Компания предоставляет Пользователю доступ к Сервису, а Пользователь обязуется использовать указанный Сервис на условиях, определенных настоящим Договором и оплачивать доступ к Сервису в порядке, определенном Договором. </p>
<p> 1.2. Полной и безусловной принятием условий настоящего Договора считается регистрация пользователя в Сервисе Компании. Если Пользователь не согласен с условиями настоящего Договора, он не должен использовать Сервис. </p>
<p> 1.3. Территория предоставленных прав - все страны мира. </p>
<p> 1.4. Актуальная редакция Договора всегда находится на сайте Компании по адресу https://h-profit.com/agreement/. </p>

<Heading subtitle size={5}> 2. Права и обязанности сторон </Heading>
<p> 2.1. Компания имеет право:
</p> <p> 2.1.1. получать от пользователя информацию о соблюдении условий этого договора и (или) информацию, необходимую для исполнения настоящего Договора и дополнительных соглашений к нему;

</p> <p> 2.1.2. получать вознаграждение в соответствии с условиями настоящего Договора;

</p> <p> 2.1.3. вносить изменения в Сервиса, в том числе публиковать новые версии Сервиса без предварительного уведомления Пользователя

</p> <p> 2.1.5. в одностороннем порядке расторгнуть Договор и (или) блокировать доступ Пользователя к Сервису в случаях нарушения пользователем законодательства Украины и (или) условий настоящего Договора;

</p> <p> 2.1.6. проводить профилактические работы на сервере, для чего временно прекращать доступ к Сервису;

</p> <p> 2.1.7. удалить учетную запись и все данные пользователя после окончания периода, указанного в п.2.2.3. настоящего Договора, либо при получении запроса от Удаление данных, или в случае нарушения пользователем условий настоящего Договора. </ p>

<Heading subtitle size={5}> 2.2. Компания обязана </Heading>
<p> 2.2.1. предоставить ежедневный круглосуточный доступ к Сервису, за исключением случаев, указанных в настоящем Договоре;

</p> <p> 2.2.2. обеспечить конфиденциальность данных пользователя с учетом действующего законодательства Украины;

</p> <p> 2.2.3. обеспечить сохранность данных, введенных пользователем в Сервиса, в течение срока действия настоящего Договора и 1 (одного) года с момента прекращения действия Договора, за исключением случаев, указанных в настоящем Договоре;

</p> <p> 2.2.4. в разумные сроки устранять сбои в работе Сервиса, возникших по вине компания является;

</p> <p> 2.2.5. проводить профилактические работы по возможности в ночное время и в выходные дни;

</p> <p> 2.2.6. осуществлять резервное копирование данных и информации пользователя.

</p> <p> 2.2.7. после получения запроса в соответствии с п. 2.3.4. настоящего Договора в срок не позднее 10 рабочих дней и в рамках технической возможности Компании предоставить пользователю выгрузки его данных. </ p>

<Heading subtitle size={5}> 2.3. Пользователь имеет право: </Heading>
<p> 2.3.1. использовать Сервис в соответствии с условиями выбранной Тарифа и настоящим Договором;

</p><p>2.3.2. получать ежедневный и круглосуточный доступ к Серверу, за исключением случаев, указанных в настоящем Договоре;

</p> <p> 2.3.3. в любое время направить компания является запрос об удалении учетной записи и всех введенных в Сервис данных.
</p>
<Heading subtitle size={5}> 2.4. Пользователь обязан: </Heading>
<p> Необходимо соблюдать ограничения на использование Сервиса, указанные в настоящем Договоре;

</p> <p> 2.4.2. платить вознаграждение за выбранный тариф компании в размере и в сроки, установленные настоящим Договором и Тарифами;

</p> <p> 2.4.3. обеспечить конфиденциальность Логина и пароля;

</p> <p> 2.4.4. немедленно сообщать компания является о любом неположенном Использование своего Логина и (или) Пароля или любое другое нарушение безопасности;

</p> <p> 2.4.5. соблюдать законодательство о защите персональных данных, обрабатываемых с помощью Сервиса (в том числе получать согласия на обработку персональных данных);

</p> <p> 2.4.6. периодически знакомится с актуальной редакцией настоящего Договора;

</p> <p> 2.4.7. не предоставлять ни при каких обстоятельствах любой третьему лицу право на использование Сервиса (сублицензии).
</p>
<Heading subtitle size={5}> 3. Порядок и условия использования программы </Heading>
<p> 3.1. Для начала использования Сервиса Пользователь заполняет регистрационные формы на сайте компанияй.

</p> <p> 3.1.1. В процессе заполнения регистрационных форм Пользователь самостоятельно выбирает логин и пароль, необходимые для дальнейшего доступа к Сервису.

</p> <p> 3.1.2. При регистрации Пользователь вводит следующие данные: логин, пароль, адрес электронной почты и другую информацию.

</p> <p> 3.2. Операции, выполняемые с использованием комбинации Логина и пароля или электронной почты пользователя, считаются действиями, выполненными самим пользователем.

</p> <p> 3.3. Права на Сервис предоставляются пользователю в срок, не превышают 2 (двух) рабочих дней с момента зачисления денежных средств на расчетный счет компания является на срок, равный учетного периода.

</p> <p> 3.3.1. В случае досрочной оплаты выбранной Тарифа (с учетом положений п 4.7. Договора) за следующий учетный период, доступ к Сервису предоставляются не ранее окончания срока действия предыдущей оплаты.

</p> <p> 3.4. Факт предоставления Пользователю избранное Тарифа подтверждается открытием полного доступа пользователей к Сервису в соответствии с п.3.3 настоящего Договора.

</p> <p> 3.5. Компания не проверяет предоставленную пользователем с целью выполнения настоящего Договора информацию (данные), не несет ответственности перед любыми третьими лицами за точность и достоверность данных.

</p> <p> 3.7. По вопросам технической поддержки Пользователь может обращаться по контактам, указанным на Сайте компания является по адресу h-profit.com.
</p>
<Heading subtitle size={5}> 4. Стоимость избранное Тарифа, порядок расчетов и условия возмещения </Heading>
<p> 4.1. Стоимость выбранного Тарифа
</p> <p> 4.1.1. Стоимость выбранного Тарифа определяется согласно Тарифам Компании.

</p> <p> 4.1.2. Компания имеет право по своему усмотрению изменять стоимость выбранной Тарифа и условия изменения тарифных планов путем их публикации в новой редакции на Сайте Компании по адресу h-profit.com/pricing/. Новые тарифы вступают в силу с даты их публикации.

</p> <p> 4.1.3. В случае если Пользователь продолжает использования Сервиса после вступления в силу новых Тарифов, считается, что он принял такие изменения Тарифов и дал свое согласие на продолжение сотрудничества в соответствии с новыми Тарифами.

</p> <p> 4.1.4. В любом случае, перерасчет избранное Тарифа за оплаченные периоды не производится.
</p>
<Heading subtitle size={5}> 4.2. Порядок расчетов </Heading>
<p> 4.2.1. Оплата лицензионного вознаграждения производится в соответствии с выбранным Тарифа, авансовым платежом в размере 100% от суммы выбранного тарифа, не менее чем за два рабочих дня до желаемой даты начала использования Сервиса.

</p> <p> 4.2.2. Способы оплаты указаны на сайте Компании по адресу https://h-profit.com/pricing/ и в Сервисе.

</p> <p> 4.2.3. Моментом исполнения Пользователь обязанностей по оплате считается день зачисления денежных средств на расчетный счет Компании.

</p> <p> 4.2.4. Пользователи оплачивающих Выбранный тариф, предоставляют согласие на услуги автоматического регулярного продолжение выбранной тарифа на новый срок (автопродление выбранного Тарифа). После активации данной услуги оплата новый срок избранное тарифа осуществляется путем автоматического безакцептного списания средств со счета пользователя. Услуга автопродления избранное Тарифа предоставляется до тех пор, пока Пользователь не отменит подписку на Выбранный Тариф в сервисе компании.

</p> <p> 4.2.5. Услуга активируется при осуществлении первой оплаты выбранной Тарифа, что означает согласие пользователя с тем, что после окончания оплаченного срока действия выбранной тарифа Договор автоматически пролонгируется на тех же условиях на тот же срок с автоматическим списанием стоимости выбранной Тарифа со счета Пользователя

</p> <p> 4.2.6. В случае недостаточности средств на счете пользователя или невозможности проведения платежа Выбранный Тариф на новый срок не предоставляется.

</p> <p> 4.2.7. При изменении Компанией Тарифы автопродления избранное Тарифа на очередной новый срок, следующий за периодом, в течение которого произошло такое изменение будет осуществлено по новому тарифу.

</p> <p> 4.2.8. Все комиссии, взимаемые кредитными организациями / другими третьими лицами при осуществлении платежа, оплачивает Сторона-плательщик.
</p>
<Heading subtitle size={5}> 4.3. Условия возмещения </Heading>
<p> 4.3.1. Компания гарантирует Пользователю возможность возврата денежных средств, внесенных в качестве оплаты выбранной Тарифа, в течение 14 (четырнадцати) календарных дней с даты осуществления оплаты путем отправки электронного письма о досрочном расторжении настоящего Договора и возврата уплаченных в качестве аванса денежных средств с зарегистрированной электронной почты пользователя на электронную почту Компании h.info@h-profit.com.

По окончании 14 (четырнадцати) календарных дней с даты осуществления оплаты возврат не производится.

</p> <p> 4.3.2.Компания, в случае получения официального заявления в оговоренный в п. 4.3.1. Договора срок, берет на себя обязанность рассмотреть указанное заявление и вернуть средства на расчетный счет пользователя, с которого были уплачены средства, в течение 30 (тридцати) календарных дней с даты получения письма. В случаях, когда возврат на счет пользователя, с которого были уплачены средства, невозможно, Пользователь указывает новый счет в письме о возврате уплаченных в качестве аванса денежных средств.

</p> <p> 4.3.3. Возврат денежных средств означает досрочное расторжение настоящего Договора.
</p>
<Heading subtitle size={5}> 5. Ограничение </Heading>
<p> 5.1. Сервис может использоваться исключительно в хозяйственной деятельности пользователя с учетом его функционального назначения.

</p> <p> 5.2. Пользователь не вправе:
</p> <p> 5.2.1. пытаться обойти технические ограничения в Сервисе;

</p> <p> 5.2.2. раскрывать технологию, декомпилировать или деассемблировать Сервис;

</p> <p> 5.2.3. использовать компоненты Сервиса для вызова приложений, которые не работают на этом Сервисе;

</p> <p> 5.2.4. создавать копии Сервиса;

</p> <p> 5.2.5. публиковать Сервис, позволяя другим пользователям ее скопировать;

</p> <p> 5.2.6. предоставлять Сервис в прокат в аренду или во временное пользование;

</p> <p> 5.2.7. использовать Сервис предоставления сетевых услуг на коммерческой основе;

</p> <p> 5.2.8. осуществлять использование Сервиса или ее частей, а также любых других онлайн сервисов компании (API, онлайн чат пользователей) после окончания срока действия настоящего Договора, или его досрочного расторжения;

</p> <p> 5.2.9. делать попытки по получению доступа к данным других Пользователей.

</p> <p> 5.3. В случае расторжения настоящего Договора, Пользователь обязуется немедленно прекратить эксплуатацию Сервиса и / или ее отдельных частей, при этом, данные пользователя в Сервисе сохраняются в течение 365 календарных дней с момента расторжения договора или окончания Периода отсрочки.
</p>
<Heading subtitle size={5}> 6. Ответственность сторон </Heading>
<p> 6.1. За неисполнение и / или ненадлежащее исполнение обязательств по настоящему Договору Стороны несут ответственность в соответствии с законодательством Украины.

</p> <p> 6.2. Сервис предоставляется по принципу «как есть». Компания не несет ответственности за возможный ущерб, прямо или косвенно связан с применением, неверным применением или невозможностью применения сервиса пользователи, утрату или повреждение данных.

</p> <p> 6.3. Ответственность компания является всегда ограничивается суммой, уплаченной пользователя.
</p>
<Heading subtitle size={5}> 6.4. Компания не несет ответственности </Heading>
<p> 6.4.1. за некачественную работу Сервиса (и причиненные убытки) в случае, когда пользователь (или третьими лицами) самостоятельно были внесены изменения в Сервиса или такие сбои вызваны несовместимостью Сервиса с оборудованием или иным программным обеспечением пользователя;

</p> <p> 6.4.2. за любые действия пользователя, связанные с использованием Сервиса, документы созданные в рамках использования Сервиса;

</p> <p> 6.4.3. за отсутствие доступа к Сервису, когда это связано с действиями пользователя, доступ пользователя к сети Интернет, действиями третьих лиц, проведением профилактических работ.

</p> <p> 6.5. Использование пользовательских сервисов способом, не предусмотренным настоящим Договором, или после прекращения действия Договора, или иным образом за пределами прав, предоставленных по настоящему Договору, которое влечет за собой ответственность за нарушение исключительного права на результат интеллектуальной деятельности, установленную законодательством Украины, а также право компания является на односторонне внесудебное расторжение настоящего Договора и прекращения лицензии.

</p> <p> 6.6. Компания не несет ответственности за ограничение доступа к Сервису с учетом положений настоящего Договора.
</p>
<Heading subtitle size={5}> 7. Разрешение споров </Heading>
<p> 7.1. Стороны будут стремиться к решению всех возможных споров и разногласий, которые могут возникнуть по настоящему Договору или в связи с ним, путем переговоров.

</p> <p> 7.2. Споры, не урегулированные путем переговоров, передаются на рассмотрение в соответствующий суд г.Харькова и подлежат разрешению в установленном законодательством Украины и должным образом ратифицированных международных договоров порядке.

</p> <p> 7.3. Стороны признают обязательность досудебного претензионного порядка перед обращением в суд.

</p> <p> 7.4. Компания обязан ответить на досудебную претензию в течение 30 (Тридцати) рабочих дней с момента ее получения.
</p>
<Heading subtitle size={5}> 8. Форс-мажорные обстоятельства </Heading>
<p> 8.1. В случае возникновения обстоятельств непреодолимой силы (форс-мажорных обстоятельств), а именно: войны, военных действий, блокады, эмбарго, пожаров, наводнений, другого стихийного бедствия или природных явлений, которые делают невозможным выполнение Стороной своих обязательств по настоящему Договору, Сторона, подвергшейся действие таких обстоятельств, освобождается от ответственности за такое неисполнение и / или ненадлежащее исполнение своих обязательств по Договору на время действия указанных обстоятельств.

</p> <p> 8.2. Сторона, которая не имеет возможности выполнить условия договора вследствие действия форс-мажорных обстоятельств, обязана уведомить другую Сторону о возникновении или прекращении указанных обстоятельств не позднее 2 (двух) календарных дней с момента их возникновения или прекращения, в письменной форме. Несвоевременное сообщение о возникновении форс-мажорных обстоятельств лишает потерпевшую Сторону права ссылаться на эти обстоятельства в будущем.

</p> <p> 8.3. Наступление действия и прекращения действия форс-мажорных обстоятельств подтверждается справкой, выданной Торгово-промышленной палатой или иным компетентным органом страны, резидентом которой является Сторона, пострадавшая от действия форс-мажорных обстоятельств.

</p> <p> 8.4. В случае если действие указанных обстоятельств продолжается более одного Учетный период подряд и влияет на выполнение Сторонами своих обязательств по настоящему Договору, каждая из Сторон имеет право на досрочное расторжение Договора при условии, если она сообщит об этом другую Сторону не позднее чем за 10 (десять) календарных дней до предполагаемой даты расторжения.
</p>
<Heading subtitle size={5}> 9. Конфиденциальность </Heading>
<p> 9.1. Стороны обязуются хранить конфиденциальную информацию, предоставляемую одной Стороной другой Стороне.

</p> <p> 9.2. Для условий данного Договора Стороны согласовалы следующие определения:

«Конфиденциальная информация» означает сведения, любую научно-техническую, технологическую, производственную, юридическую, финансово-экономическую или иную информацию, в том числе коммерческую тайну и ноу-хау, имеющая действительную или потенциальную коммерческую ценность в силу ее неизвестности третьим лицам, к ней нет свободного доступа на законном основании, и Сторона принимает меры к охране ее конфиденциальности.

</p> <p> 9.3. Стороны обязуются не осуществлять раскрытие Конфиденциальной информации третьим лицам, за исключением случаев, когда Конфиденциальная информация может быть раскрыта с предварительного письменного согласия стороны-владельца информации или в иных случаях, предусмотренных законодательством Украины. В любом случае, Стороны должны уведомлять друг друга о необходимости раскрытия конфиденциальной информации по запросу государственных органов.

</p> <p> 9.4. Ни одна из Сторон настоящего Договора не будет пытаться прямо или косвенно, контактировать или сотрудничать с партнером, консультантом, контрагентом, клиентом другой Стороны по вопросам, связанным с взаимоотношениями Сторон; за исключением действий через эту другую Сторону или получения письменного согласия на такие действия этой другой Стороны, а также не будет пытаться обойти другую Сторону любым способом, предназначенным для того, чтобы обойти вознаграждение, доход и / или прибыль, которые должны быть получены каждой Стороны в результате взаимоотношений Сторон.

</p> <p> 9.5. Стороны гарантируют, что персональные данные их учредителей, сотрудников, представляющих интересы сторон или других представителей будут использоваться Сторонами в целях принятия решений и заключения между Сторонами любых договоров, контрактов, соглашений и других документов, направленных на совершение действий, порождающих юридические последствия для сторон. Стороны гарантируют обеспечение конфиденциальности и безопасности персональных данных в соответствии с законодательством Украины.
</p>
<Heading subtitle size={5}> 10. Порядок заключения, срок действия, порядок расторжения договора </Heading>
<p> 10.1. Настоящий Договор заключается (акцептуется) путем регистрации пользователя на сервере Компании и оплаты пользователей тарифа и действует в течение срока, выбранного пользователем. Датой заключения Договора является дата зачисления денежных средств на расчетный счет компания является в размере, определенном в соответствии с Тарифами.

</p> <p> 10.2. Предложения (предложение на заключение настоящего Договора) действует неограниченно по времени с учетом условий, изложенных в этой оферте, - Лицензионное сделке, пока Компания не прекратит ее действие или не заменит это предложение новой редакции. Изменение условий оферты не влечет изменения условий настоящего Договора, если Пользователь его заключил до введения в действие таких изменений или ее прекращения.

</p> <p> 10.3. Настоящий Договор распространяется на обновления и новые версии Сервиса.

</p> <p> 10.4. За исключением случаев, прямо оговоренных в настоящем Договоре, Договор расторгается по взаимному согласию Сторон.

</p> <p> 10.5. Удаление учетной записи пользователя автоматически прекращает действие настоящего Договора.
</p>
<Heading subtitle size={5}> 11. Заключительные положения </Heading>
<p> 11.1. Настоящий Договор является смешанным и содержит элементы договоров присоединения, публичного договора.

</p> <p> 11.2. Пользователь подтверждает, что он, заключения настоящего Договора, ознакомился с его условиями, они ему понятны, и сознательно без принуждения заключил настоящий Договор.

</p> <p> 11.3. Пользователь не может предлагать свои условия, а может только присоединиться к предложенной оферте.

</p> <p> 11.4. Все приложения и дополнительные соглашения к настоящему Договору являются его неотъемлемой частью.

</p> <p> 11.5. Стороны договорились, что все документы с факсимильного воспроизведения подписи с помощью средств механического или иного копирования, электронно-цифровой подписи либо иного аналога собственноручной подписи имеют силу такую ​​же, как и документы с собственноручной подписью.

</p> <p> 11.6. Компания оставляет за собой право вносить изменения в настоящий Договор в одностороннем порядке. В случае если Пользователь не согласен с внесенными изменениями, он обязан в течение 5 рабочих дней с момента изменения договора прекратить использование Сервиса и расторгнуть настоящий Договор в одностороннем порядке.

</p> <p> 11.7. Реквизиты и контактные данные компания является указываются в разделе 12 настоящего Договора.

</p> <p> 11.8. Контактные данные пользователя указываются при заполнении регистрационных форм, в Личном кабинете в Сервисе или иным согласованным Сторонами способом. Пользователь несет риск сообщения неверных (недействительных) контактных данных.
</p>
<Heading subtitle size={5}> 12. Реквизиты компании </Heading>
<p>ИП Шевкунов Александр Сергеевич <br />
ИНН 616612404282  <br />
ОГРН 311619332600085 <br />
ОКПО 0152967303 <br />
ОАО КБ «Центр-инвест» <br />
БИК 046015762 <br />
р/сч 40802810304100000095 <br />
к/сч 30101810100000000762 <br />
+79034858017 <br />
</p>
<p>Електронна пошта: h.info@h-profit.com</p>
            </Content>
            </Box>
            <FooterBlock />
            </Section>
            )}

}

export default AgreementRU;