import React from "react";
import OpenModalButton from "../../../components/modal/open-modal-button";
import T from "../../../components/i18n";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
// import { Button } from "react-bulma-components";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import AppStore from "../../../app-store";
// import MovingAllModal from "../modal/moving-all-modal";

const ButtonActions = () => {
  const {isMobileSize} = useIsMobile()
  return (
    <div
      className="display-flex-row margin-bottom-10 top-button"
      style={{ justifyContent: "flex-end" }}
    >
{/*      <Button
        onClick={() =>
          AppStore.openModal(<MovingAllModal modal="moving-all" />)
        }
        color="success"
        rounded
        size={isMobileSize ? "small" : "large"}
        className="display-flex-row"
      >
        <FontAwesomeIcon icon="plus-circle" />
        {T("move-all-products")}
      </Button>*/}
      <OpenModalButton
        link="/moving/add-moving"
        text={T("create-moving")}
        icon="plus-circle"
        size={isMobileSize ? "small" : "medium"}
      />
    </div>
  );
};

export default ButtonActions;
