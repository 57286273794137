export const DTO_products = (data) => {
	return {
		"id": data['j'],
		"asin": data['d'],
		"attr_desc": data['a'] || null,
		"attr_dict": data['o'] || null,
		"brand_id": data['p'] || null,
		"category_id": data['r'] || null,
		"cat_name": data['e'] || "",
		"af": data['g'] || "{}",
		"description": data['c'] || "",
		"image": data['q'] || 0,
		"is_deleted": data['w'] || 0,
		"name": data['t'] || "",
		"parent_id": data['z'] || null,
		"responsible_user_id": data['y'] || null,
		"type_product": data['l'] || 0,
		"brand": data['s'] || "",
		"units": data['n'] || "units",
		"user_id": data['b'],
		"size": data['v'] || "xxx",
		"mp": data['m'] || [],
		"expirated_date": data['f'] || null,
		full_name: data['full_name'] || "",
		stock: data['i'],
		ukzt: data['u'] || null,
	}
}

export const DTO_stock_products = (data) => {

	const obj = {
		"id": data['o'],
		"product_id": data['n'],
		"marketplace_id": data['p'],
		"quantity": data['m'] ||0,
		"instock": data['i'] || 0,
		"net_price": data['a'] || 0,
		"sale_price": data['y'] || null,
		"price": data['r'] || 0,
		"sku": data['t'] || "",
		"is_ff": data['s'] || 0,
		"waiting": data['w'] || 0,
		"raw_net_price": data['f'] || 0,
		"main_net_price": data['b'] || 0,
		"is_calc": data['q'] || false
	}

	if(data['c']) {
		obj.currency_id = data['c']
	}
	if(data['d']) {
		obj.base_net_price = data['d']
	}
	if(data['e']) {
		obj.current_sid = data['e']
	}
	if(data['g']) {
		obj.med_sid_price = data['g']
	}
	if(data['h']) {
		obj.shipment_prices = data['h']
	}
	if(data['j']) {
		obj.sid_quantity = data['j']
	}

	return obj
}

