import React from "react";
import AppStore from "../../../../../app-store";
import CheckReservShipModal from "../../../check-reserv-ship-modal";

const InstokBlock = ({
  pid,
  mid,
  instock,
  quantity,
  waiting,
  withoutCursor,
}) => {

  let value = instock;

  if(instock !== 0 && instock % 1 !== 0) {
    value = instock.toFixed(3)
  }

  if (instock !== quantity) {
    value = <span className="text-success">{instock + "/" + quantity}</span>;
  }

  if (waiting) {
    value = (
      <span>
        {value}
        <span style={{ color: "#d4d741" }}>({waiting})</span>
      </span>
    );
  }

  return (
    <span
      className={
        (instock !== quantity || waiting) && !withoutCursor
          ? "cursor-pointer"
          : ""
      }
      onClick={
        (instock !== quantity || waiting) && !withoutCursor
          ? () =>
              AppStore.openModal(
                <CheckReservShipModal
                  pid={pid}
                  reservLength={quantity - instock}
                  mid={mid}
                  realQuantity={quantity}
                />
              )
          : () => {}
      }
    >
      {value}
    </span>
  );
};

export default InstokBlock;
