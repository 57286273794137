import React, { useState } from "react";
import T from "components/i18n";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalIcon } from 'whitelables/hugeprofit/icons';
import Dropdown from 'react-bulma-components/lib/components/dropdown';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import {Button} from "react-bulma-components";
import AppStore from "../../../app-store";

const ButtonsDropdownList = ({ icon, local_icon, className, text, items }) => {
  const [isActive, setIsActive] = useState();
  const isIcon = icon || local_icon;
  
  const labelMarkup = (<>
    {isIcon ? <span className={icon ? 'margin-right-5' : ""}>
      {icon ? <FontAwesomeIcon icon={icon} /> : <LocalIcon icon={local_icon}/>}
    </span> : null}
    <span className='margin-right-5'>{T(text)}</span>
    <span className='toggle-icon'>{isActive ? <LocalIcon icon='chevron-up' /> : <LocalIcon icon='chevron-down' />}</span></>)

  const dropdownItems = items && !!items.length
    ? items.map(item => {
      if(item.modal) {
        return (
          <Dropdown.Item
            title={T(item.title)}
            value={item.title}
            key={item.title}
          >
            <Button className='button-link-without-style' style={{color: '#4a4a4a'}} onClick={() => AppStore.openModal(item.modal)}>
              {isIcon ? <span className='margin-right-5'>{icon ?
                <FontAwesomeIcon icon={icon}/> :
                <LocalIcon icon={local_icon}/>}</span> : null}
              {T(item.title)}
            </Button>
          </Dropdown.Item>
        )
      }

      return (
        <Dropdown.Item
          renderAs={Link}
          to={item.link}
          title={T(item.title)}
          value={item.title}
          key={item.title}
          target={item.target || ""}
        >
          {isIcon ? <span className='margin-right-5'>{icon ?
            <FontAwesomeIcon icon={icon}/> :
            <LocalIcon icon={local_icon}/>}</span> : null}
          {T(item.title)}
        </Dropdown.Item>)
    }) : null

  return (
    <Dropdown
      label={labelMarkup} className={className || "import-products"} onClick={() => setIsActive(!isActive)} >
      {dropdownItems}
    </Dropdown>
        )
}
    
export default ButtonsDropdownList;