import React, {useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import T from "../../../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import ModalItsOK from "../../../../../components/modal-its-ok";
import UserActions from "../../../../../user/user-actions";
import ActionsModal from "../common/actions-modal";
import ApiKeyInputWithGenerate
	from "../common/api-key-input-with-generate";
import IntegrationListenerHook from "../common/integration-listener.hook";
import {handleDeleteIntegration} from "../utils";
import {confirmDelete} from "../../../../../tools/confirm-delete";
import {generateKey} from "../../../../../tools/genarete-key";

const ApiModal = ({data, wooID, onClose,}) => {
	const {id, api_key, is_active} = data
	const [errorMessage, setErrorMessage] = useState(null)
	const [waiting, setWaiting] = useState(false)
	const [apiKey, setApiKey] = useState(api_key || generateKey( 32))

	const handleAutoRemoveError = (message) => {
		setTimeout(() => setErrorMessage(null), 4000)
		setErrorMessage(T(message))
	}

	const {submitOK, additionalMessage} = IntegrationListenerHook({handleError: handleAutoRemoveError, setWaiting})

	const handleSubmit = ({deactivate}) => {
		const data = {
			integration_id: 15,
			api_key: apiKey,
			is_active: 1
		}
		if(id) data.id = id
		if(deactivate) data.is_active = 0

		if(!!apiKey && apiKey.length === 32) {
			UserActions.updateIntegrations(data)
		} else {
			handleAutoRemoveError('not all fields required')
		}
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!submitOK && <Box>
				<Heading size={3}>{T('setting-integration', [T(`integration-${wooID}`)])}</Heading>
				<ApiKeyInputWithGenerate value={apiKey} label='api-key-integration-label' onClickGenerateKey={setApiKey}  />
				<ActionsModal
					onClose={onClose}
					waiting={waiting}
					isEdit={id}
					isActive={is_active}
					onSubmit={handleSubmit}
					onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
				/>
			</Box>}
			{submitOK && <ModalItsOK onClose={onClose} title={submitOK} additionalMessage={additionalMessage}/>}
		</>

	);
};

export default ApiModal;