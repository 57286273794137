import React, { Component } from 'react';

class Ppc extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render () {
        return (
			<h1> Ppc Modules</h1>
			)}

      }

export default Ppc;