import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoBox from "./Info-box";
import s from "./styles.module.scss";

const InfoIcon = ({ info }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rectIcon, setRectIcon] = useState({});

  const wrapperRef = useRef(null);
  const iconRef = useRef(null);

  const handleToggleOnClick = (e) => {
    e.stopPropagation();
    setTimeout(() => setIsOpen((prevState) => !prevState), 200);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleResizeOrScroll = () => {
    const icon = iconRef?.current;
    if (icon) {
      const rectIcon = icon.getBoundingClientRect();
      setRectIcon(rectIcon);
    }
  };

  useEffect(() => {
    handleResizeOrScroll();

    window.addEventListener("resize", handleResizeOrScroll);
    window.addEventListener("scroll", handleResizeOrScroll);

    return () => {
      window.removeEventListener("resize", handleResizeOrScroll);
      window.removeEventListener("scroll", handleResizeOrScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="radio-with-info-wrapper" ref={wrapperRef}>
      <span className={s.info_icon} onClick={handleToggleOnClick} ref={iconRef}>
        <FontAwesomeIcon icon="question-circle" size="1x" />
      </span>
      {isOpen && <InfoBox rectIcon={rectIcon} info={info} />}
    </div>
  );
};

export default InfoIcon;
