import React, { Component } from 'react';
import T from 'components/i18n';

import {
  NavLink
} from "react-router-dom";

import Menu from 'react-bulma-components/lib/components/menu';



class InfoMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render () {
        return (
				<Menu>
				      <Menu.List>
				        <li>
				        	<NavLink activeClassName="sidebar-active" strict to="/help/start">{T('how-start')}</NavLink>
				        </li>
				        <li>
				        	<NavLink activeClassName="sidebar-active" strict to="/help/dashboard">{T('dashboard')}</NavLink>
				        </li>
				      	<li>
				          	<NavLink activeClassName="sidebar-active" strict to="/help/sales">{T('sales')}</NavLink>
				        </li>
				        <li>
				          	<NavLink activeClassName="sidebar-active" strict to="/help/expenses">{T('Payments')}</NavLink>
				        </li> 
				        <li>
				          	<NavLink activeClassName="sidebar-active"  strict to="/help/products">{T('products')}</NavLink>
				        </li> 
				        <li>
				          	<NavLink activeClassName="sidebar-active"  strict to="/help/clients">{T('Clients')}</NavLink>
				        </li>
				        <li>
				          	<NavLink activeClassName="sidebar-active"  strict to="/help/warehouse">{T('Warehouse')}</NavLink>
				        </li>
				      </Menu.List>

				</Menu>
			)}

      }

export default InfoMenu;