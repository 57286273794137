import React from 'react';
import T from "components/i18n";
import Box from 'react-bulma-components/lib/components/box';
import LightStore from './light-store'
import ShipmentsStore from '../shipments/shipments-store'
import ShipmentsActions from '../shipments/shipments-actions'
import Button from 'react-bulma-components/lib/components/button';
import Heading from 'react-bulma-components/lib/components/heading';
import {
    Field, 
} from 'react-bulma-components/lib/components/form';
import BaseModule from "components/modules/base-module";
import SelectComponent from 'tools/select-component'
import OpenModalButton from "components/modal/open-modal-button";
import ProductsStore from "./products-store";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";

class AddToShipment extends BaseModule {
    constructor(props) {
        super(props);

        this.state = {};
        this.onChangeSelectShipment = this.onChangeSelectShipment.bind(this);
    }

    onChangeSelectShipment(selectedOption, action) {
      let data = ShipmentsStore.getShipmentById(selectedOption.value);

      this.setState({
        [action.name]: selectedOption,
        'data': data
      });

    }

    render () {
        const {onClose} = this.props;
        return (
              <Box>
                <Heading size={this.props.isMobileSize ? 5 : 3}>
                    {T('add-to-shipment-btn')} <br />
                </Heading>

                <Field>
                     <SelectComponent 
                          name="shipment"
                          label="select-shipment-products"
                          onChange={this.onChangeSelectShipment} 
                          value={this.state.shipment} 
                          list={() => ShipmentsStore.getList().filter(shipment=>shipment['shipment_status'] === "pending")}
                          load={ShipmentsActions.load}
                          creatable={false}/>
                </Field>

                <div align="right" className="margin-bottom-10">
                <OpenModalButton size={"small"}  color="info" link={{
                              pathname: '/shipments/add-shipment',
                              state: {
                                'step': 0,
                                'received': false,
                                'data': ProductsStore.getFilter('mid').value ? {marketplace_id: ProductsStore.getFilter('mid').value} : null,
                                'edit': false,
                                'group_select': LightStore.getProductsSelectOptionsWithoutDropAndServicesAndBundle()
                              }
                      }} text={T('create-product-delivery')} />

                </div>
                
                <Button.Group position="centered">
                    <OpenModalButton size={this.props.isMobileSize ? "small": "medium"} disabled={this.state.shipment && this.state.shipment.value ? false : true} color="success" link={{
                              pathname: '/shipments/edit-shipment',
                              state: {'step': 1, 'received': false, 'edit': true, 'group_select': LightStore.getProductsSelectOptionsWithoutDropAndServicesAndBundle(), 'data': this.state.data }
                      }} text={T('add-to-shipment-btn')} />

                    <Button size={this.props.isMobileSize ? "small": "medium"} rounded color="light"
                      onClick={onClose}
                      >{T('close')}</Button>
                </Button.Group>
              </Box>
        );
    }

}

export default withIsMobileSize(AddToShipment);