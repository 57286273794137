import React, {useEffect, useState} from "react";
import { Box, Button } from "react-bulma-components";
import ChatsActions from "../../../../chats/chats-actions";
import ActionsModal from "../common/actions-modal";
import { confirmDelete } from "../../../../../tools/confirm-delete";
import { handleDeleteIntegration } from "../utils";
import IntegrationListenerHook from "../common/integration-listener.hook";
import ModalItsOK from "../../../../../components/modal-its-ok";
import ErrorMessageControlHook
	from "../../../../../components/error-message-block/error-message-control.hook";
import UserActions from "../../../../../user/user-actions";
import T from '../../../../../components/i18n'
import Heading from "react-bulma-components/lib/components/heading";
import {Field, Input, Label} from "react-bulma-components/lib/components/form";

const OlxChats = ({ data, onClose, wooID }) => {
	const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = ErrorMessageControlHook()

	const [waiting, setWaiting] = useState(false)
  const { submitOK, additionalMessage } = IntegrationListenerHook({
    handleError: handleAutoRemoveError,
    setWaiting,
  });
	const [name, setName] = useState(data.name || '');

  useEffect(() => {
    const authOlxCompl = ChatsActions.authOlx.completed.listen((data) => {
      if (data.link) {
        window.location.href = data.link;
      }
    });

    return () => {
      authOlxCompl();
    };
  }, []);

  const handleAuthOlx = () => {
    ChatsActions.authOlx(data?.id);
  };

	const handleSubmit = ({deactivate}) => {

		const result = {
			"integration_id": wooID,
			"api_key": data?.api_key || 'OLX',
			"api_pass": data?.api_pass,
			ident: JSON.stringify(data.ident),
			is_active: 1,
			name: name || '',
			id: data.id
		}

		if(deactivate) result.is_active = 0

		setWaiting(true)
		UserActions.updateIntegrations(result)
	}

	const handleChangeName = ({target: {value}}) => {
		setName(value)
	}

  return (
    <>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
      {!submitOK && (
        <Box>
					<Heading size={3}>
						{T('setting-integration', [T(`integration-${wooID}`)])}
					</Heading>
					<Field>
						<Label>{T('name')}</Label>
						<Input
							name='name'
							value={name}
							onChange={handleChangeName}
						/>
					</Field>

					<div className='display-flex-center'>
						<Button onClick={handleAuthOlx} size="large" color='success'>
							{data.api_pass ? T('re-auth-olx') : T("auth-olx")}
						</Button>
					</div>
          <ActionsModal
            onDelete={() =>
              confirmDelete(() => handleDeleteIntegration(data.id))
            }
						waiting={waiting}
            onClose={onClose}
            isEdit={!!data.id}
            isActive={data.is_active}
						onSubmit={handleSubmit}
						disabledSubmit={!data.api_pass}
          />
        </Box>
      )}
      {submitOK && (
        <ModalItsOK
          onClose={onClose}
          title={submitOK}
          additionalMessage={additionalMessage}
        />
      )}
    </>
  );
};

export default OlxChats;
