import yajax from "yajax";
import React from "react";
import { createStore } from "../../tools/reflux-tools";
import Actions from "./chats-actions";
// import ProductActions from '../products/products-actions';
import User from "../../user/user-store";
import Sound from "./common/message.mp3";
import UserStore from "../../user/user-store";
import ClientsStore from "../clients/clients-store";
import NotificationStore from "../notification/notification-store";
import T from '../../components/i18n'
import {getDatePeriod} from "../../tools/date-range";
import {isMobile} from "react-device-detect";

var loc = window.location,
  new_uri,
  new_ringostat
if (loc.protocol === "https:") {
  new_uri = "wss:";
  new_ringostat = "wss:";
} else {
  new_uri = "wss:";
  new_ringostat = "wss:";
  // new_uri = "ws:";
}

if (loc.host === "localhost:3000") {
  // new_uri += "//0.0.0.0:9132/bchats/ws/messages";
  new_uri += "//ac1.h-profit.com/bchats/ws/messages";
  new_ringostat += "//ac1.h-profit.com/bringostat/ws";
} else {
  new_uri += "//" + loc.host + "/bchats/ws/messages";
  new_ringostat += "//" + loc.host + "/bringostat/ws";
}

const CLEAR_FILTER = () => ({
  source: {value: 0, label: T('all')},
  date_start: getDatePeriod(30),
  date_end: new Date(),
  status: 0,
  search: '',
  tags: {value: 0, label: T('all')},
})

const ChatsStore = createStore({
  listenables: Actions,
  _prefix: "/bchats/",
  sound: new Audio(Sound),
  socket: null,
  limit: 25,
  state: {
    chats: null,
    messages: [],
    lastAdd: 0,
    offset: 0,
    active_chat: false,
    error: 10,
    isLoaded: false,
    messagesLoading: false,
    isLoadedDelivery: false,
    loadingStatus: false,
    internalNumbersBinotel: null,
    listCall: {},
    statuses: null,
    bindingClients: {},
    _filter: CLEAR_FILTER()
  },

  constructor() {
    // this.initSocket();
  },

  get(field) {
    return this.state[field];
  },

  setActiveChat(chatID) {
    this.setState({
      activeChatID: chatID,
    });
  },

  clearFilter() {
    this.setState({
      _filter: CLEAR_FILTER()
    })
  },

  onFilter(name, value) {
    let filters = {};

    for (let i in this.state['_filter']) {
      filters[i] = this.state['_filter'][i];
    }

    let date_start = filters['date_start'];

    filters[name] = value;

    this.setState({
      _filter: filters
    });

    if (name === "date_start" && value < date_start) {
      Actions.load();
    }
  },

  initSocket() {
    if (!this.isEnable()) return;
    console.log("start init socket: %s", new_uri);
    this.socket = new WebSocket(new_uri);

    this.socket.onerror = (e) => {
      // an error occurred
      console.log("SOCKET ERROR", e);
      // setTimeout(ChatsStore.initSocket, 5000);
    };

    this.socket.onclose = (e) => {
      // connection closed
      console.log("conecection close", e.code, e.reason);
      if (this.reconectTimeout) {
        console.log("clear timeout on close");
        clearTimeout(this.reconectTimeout);
        this.reconectTimeout = setTimeout(ChatsStore.initSocket, 1000 * 10);
        return;
      }

      this.reconectTimeout = setTimeout(ChatsStore.initSocket, 1000 * 10);
    };

    this.socket.onopen = () => {
      this.socketSend({ type: "open" });
      console.log("open socket ");
    };

    this.socket.onmessage = (e) => {
      ChatsStore.addMessage(JSON.parse(e.data));
    };
  },

  isEnable() {
    return User.isIntegrationChats();
  },

  getAvatar(uid, id) {
    let size = "45";
    return "/bimages/get/" + size + "-" + uid + "-avatar" + id + "-0.jpg";
  },

  getImage(uid, chat_id, name) {
    return "/bimages/get/chats/" + uid + "-" + chat_id + "-" + name;
  },

  socketSend(data, callback) {
    data["token"] = User.getToken();
    // let timeout = this.reconectTimeout;
    let waitForConnection = function (callback, interval) {
      if (this.socket && this.socket.readyState === 1) {
        callback();
      } else {
        if (this.reconectTimeout) {
          console.log("clear timeout");
          // clearTimeout(this.reconectTimeout)
          return;
        }
        this.initSocket();

        this.reconectTimeout = setTimeout(function () {
          console.log("try reconnect");
          waitForConnection(callback, interval);
        }, interval);
      }
    }.bind(this);

    let self = this;
    waitForConnection(function () {
      self.socket.send(JSON.stringify(data));
      if (typeof callback !== "undefined") {
        callback();
      }
    }, 1000);
  },

  addMessage(data) {
    data["chat_id"] = parseInt(data.chat_id);

    let new_message = true,
      not_read = this.get("not_read") + 1,
      [chat, index] = this.getChatsById(data.chat_id),
      message = this.state.messages,
      chats = this.get("chats");

    if (chat) {
      chat["not_read"] += 1;
      chats[index]["updated_at"] = Date.now() / 1000;
      chats[index]["last_message"] = data.message;
      chats[index]["last_date"] = Date.now() / 1000;
    } else {
      Actions.load();
      console.log("NOT FOUND CHATS");
    }

    if (parseInt(this.state.activeChatID) === data.chat_id) {
      message = message.concat([data]);
    }

    if (data["is_own"]) {
      new_message = false;
      if (chat) {
        chat["not_read"] -= 1;
      }
    }

    if (new_message) {
      const event = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      setTimeout(() => document.dispatchEvent(event), 200);
    }

    const location = window.location.href.split('/')

    const findChats = location.some(item => item === 'chats')

    if(!findChats && UserStore.getModuleEnable('chats')) {
      const client = ClientsStore.getClientById(chat.client_id)
      const name = !!Object.keys(client).length ? client.name : chat.remote_client_name;

      NotificationStore.addScreenNotification({name, message: data.files ? 'files' : data.message, url: `/chats/${data.chat_id}`, icon: <div className={`notification-${chat.system_id}`}/>})
    }

    this.setState({
      messages: message,
      new_message: new_message,
      not_read: not_read,
      chats: chats,
    });
  },

  soundPlayFinish() {
    this.setState({ new_message: false });
  },

  onAdd(message) {
    console.log("start add message", message);
    const data = new FormData();

    if (message) {
      const keysData = Object.keys(message);
      keysData.forEach((item) => {
        if (item === "files" && Array.isArray(message[item])) {
          message[item].forEach((file, index) => {
            data.append(`file[${index}]`, file);
          });
        } else {
          data.append(item, message[item]);
        }
      });
    }
    let headers = {
      "X-Source-Type": "desktop",
    };

    if (!User.isGuest()) {
      headers["X-Auth-Token"] = encodeURIComponent(User.getToken());
    }

    yajax
      .post(this._prefix + "messages", data, {
        raw: true,
        headers: headers,
      })
      .then(Actions.add.completed, Actions.add.failed);
  },

  onAddCompleted(data) {
    const dataParse = JSON.parse(data || "{}");
    if (dataParse["success"]) {
      this.addMessage(dataParse["data"]);
    }
  },

  onAddFailed() {
    console.log("failed add message");
  },

  onDelete(data) {
    console.log("start delete chats product", data);

    const params = {
      method: "DELETE",
      url: this._prefix + "chats",
      data: data,
    };

    yajax(params).then(Actions.delete.completed, Actions.delete.failed);
  },

  onDeleteCompleted(data) {
    if (data["type"] === "chats") {
      Actions.load();
    }
    console.log("result delete chats product: ", data);
  },

  onDeleteFailed() {
    console.log("failed delete chats product");
  },

  onBindingClient(data) {
    console.log("start binding client to chat", data);
    yajax
      .post(this._prefix + "binding", data)
      .then(Actions.bindingClient.completed, Actions.bindingClient.failed);
  },

  onBindingClientCompleted(data) {
    Actions.load();
    Actions.loadBindingClients()
  },

  onBindingClientFailed() {
    console.log("failed binding chats clients");
  },

  onLoadBindingClients() {
      yajax
        .get(this._prefix + "binding")
        .then(Actions.loadBindingClients.completed, Actions.loadBindingClients.failed);
  },

  onLoadBindingClientsCompleted(data) {
    this.setState({
      bindingClients: data.data
    })
  },

  onLoadBindingClientsFailed() {
  },

  getList(list) {
    const chats = this.get(list);
    if (chats === null) {
      Actions.load();
      return {};
    }

    return chats;
  },

  getChats() {
    const chats = this.get("chats");
    if (chats === null) {
      Actions.load();
      this.setState({
        chats: [],
      });

      return [];
    }
    return chats?.sort(
      (a, b) => new Date(b?.last_date * 1000) - new Date(a?.last_date * 1000)
    );
  },

  getChatByClientID(clientID) {
    const getChats = this.state.bindingClients
    return getChats[clientID]?.chat_id;
  },

  markAsRead(mess, chat_id) {
    this.socketSend({
      type: "read",
      chat_id: chat_id,
      message_id: mess["id"],
      is_read: 1,
    });

    let [chat] = this.getChatsById(chat_id);
    chat["not_read"] -= 1;

    let not_read = this.get("not_read") - 1;
    this.setState({
      not_read: not_read,
    });
  },

  getChatsById(sid) {
    for (let i in this.state.chats) {
      const ship = this.state.chats[i];
      if (parseInt(ship["id"]) === parseInt(sid)) {
        return [ship, i];
      }
    }

    return [];
  },

  onLoad(force) {
    if (this.chatsLoading) return;
    console.log("start load chats...");

    this.chatsLoading = true;

    const data = {}

    if(force) {
      data.t = Date.now()
    }

    const filter = this.get('_filter')


    if (filter['date_start']) {
      const date_start = filter['date_start'].setHours(0, 0, 0, 0);
      data.start = parseInt(`${date_start / 1000}`)
    }

    if (filter['date_end']) {
      const date_end = filter['date_end'].setHours(23, 59, 59, 999);
      data.end = parseInt(`${date_end / 1000}`);
    }

    let url = this._prefix + "chats";
    yajax.get(url, data).then(Actions.load.completed, Actions.load.failed);
  },

  onLoadCompleted(data) {
    if (data["success"]) {
      this.setState({
        chats: data["data"]["data"],
        not_read: data["data"]["not_read"],
      });
    }
    this.chatsLoading = false;
    console.log("result load chats: ", data);
  },

  onLoadFailed() {
    this.chatsLoading = false;
    console.log("failed loadimg chats");
  },

  onLoadByChatId(chat_id) {
    if(this.chatsLoading) return false

    this.chatsLoading = true;

    yajax.get(this._prefix + "chats", {chat_id}).then(Actions.loadByChatId.completed, Actions.loadByChatId.failed);
  },

  onLoadByChatIdCompleted(data) {
    this.chatsLoading = false
    const chats = [...this.state.chats]

    const firstData = data.data.data[0]

    const isReadyChat = chats.find(item => item.id === firstData.id)

    if(!isReadyChat) {
      chats.unshift(firstData)
      this.setState({
        chats
      })
    }
  },

  onLoadByChatIdFailed() {
    this.chatsLoading = false
  },

  clearMessage() {
    this.setState({
      messages: [],
      offset: 0,
    });
  },

  onLoadMessages(chat_id, offset) {
    if (this.state.messagesLoading) return;
    console.log("start load messagesLoading...");

    this.setState({
      messagesLoading: true,
    });

    let url =
      this._prefix +
      "messages?chat_id=" +
      chat_id +
      "&offset=" +
      this.state.offset +
      "&limit=" +
      this.limit;

    yajax
      .get(url)
      .then(Actions.loadMessages.completed, Actions.loadMessages.failed);
  },

  onLoadMessagesCompleted(data) {
    if (data["success"]) {
      let message = data["data"].concat(this.state.messages);

      this.setState({
        messagesLoading: false,
        messages: message,
        offset: this.state.offset + this.limit,
      });
    }
    console.log("result load messagesLoading: ", data);
  },

  onLoadMessagesFailed() {
    this.setState({ messagesLoading: false });
    console.log("failed loadimg messagesLoading");
  },


  initSocketBinotel() {
    this.socketBinotel = new WebSocket('wss://ws.binotel.com:9002');

    this.socketBinotel.onopen = (event) => {
      const binotelIntegration = UserStore.getBinotelList()
      const identBinotelIntegration = binotelIntegration[0]?.ident

      console.log('open binotel')
      if(identBinotelIntegration) {
        const authMessage = {
          task: 'authLikeService',
          key: identBinotelIntegration.websoket_key,
          secret: identBinotelIntegration.websoket_secret,
        };
        this.socketBinotel.send(JSON.stringify(authMessage));
      }
    }



    this.socketBinotel.addEventListener('error', (event) => {
      console.error('Error:', event);
    });

    this.socketBinotel.addEventListener('close', (event) => {
      console.log('Close:', event);
    });

    this.socketBinotel.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);

      const getInternalNumber = UserStore.getMoreSetting('internalNumber')


      switch (message.eventName) {
        case 'callStart':
          if(getInternalNumber || UserStore.isMainUser()) {
            const listCall = this.state.listCall
            this.setState({
              listCall: {
                ...listCall,
                [message.generalCallID]: {
                  phone: message.externalNumber,
                  callID: message.generalCallID
                }
              }
            })
          }
          break;
        case 'callAnswer':
          const isCallAnswerByIternalNumber = message?.internalNumber === getInternalNumber

          if(!isCallAnswerByIternalNumber && !UserStore.isMainUser()) {
            this.deletePopupByCallID(message.generalCallID)
          }


          if(UserStore.isMainUser()) {
            const listCall = this.state.listCall
            this.setState({
              listCall: {
                ...listCall,
                [message.generalCallID]: {
                  phone: message.externalNumber,
                  callID: message.generalCallID,
                  internalNumber: message.internalNumber
                }
              }
            })
          }

          break;
        case 'callStop':
          this.deletePopupByCallID(message.generalCallID)
          break;
        default:
          return null
      }
    });
  },

  initSocketRingostat() {
    if(!!UserStore.getRingostatList().length && !isMobile) {
      this.socketRingostat = new WebSocket(new_ringostat);
      this.socketRingostat.onopen = (event) => {
        const authMessage = {
          action: 'login',
          token: UserStore.getToken(),
        };
        this.socketRingostat.send(JSON.stringify(authMessage));
      }

      this.socketRingostat.addEventListener('error', (event) => {
        console.error('Error:', event);
      });

      this.socketRingostat.addEventListener('close', (event) => {
        console.log('Close:', event);
      });

      this.socketRingostat.addEventListener('message', (event) => {
        const message = JSON.parse(event.data);

        switch (message.forAction) {
          case 'callStart':
            if(UserStore.isRingostatByUser() || UserStore.isMainUser()) {
              const listCall = this.state.listCall
              this.setState({
                listCall: {
                  ...listCall,
                  [message.generalCallID]: {
                    phone: message.externalNumber,
                    callID: message.generalCallID
                  }
                }
              })
            }
            break;
          case 'callAnswer':
            const findUser = UserStore.getRingostatUserById(message?.internalNumber)
            const isCallAnswerByIternalNumber = parseFloat(findUser.rid) === parseFloat(UserStore.get('realId'))

            if(!isCallAnswerByIternalNumber && !UserStore.isMainUser()) {
              this.deletePopupByCallID(message.generalCallID)
            }


            if(UserStore.isMainUser()) {
              const listCall = this.state.listCall
              this.setState({
                listCall: {
                  ...listCall,
                  [message.generalCallID]: {
                    phone: message.externalNumber,
                    callID: message.generalCallID,
                    internalNumber: message.internalNumber,
                    type: 'ringostat'
                  }
                }
              })
            }

            break;
          case 'callStop':
            this.deletePopupByCallID(message.generalCallID)
            break;
          default:
            return null
        }
      });
    }
  },

  deletePopupByCallID(callID) {
    const listCall = { ...this.state.listCall };

    if (callID && listCall[callID]) {
      delete listCall[callID];

      this.setState({ listCall });
    }
  },

  onCreateCallBinotel({internalNumber, phoneNumberClient,integrationID }) {
    yajax.post(this._prefix + "binotel/control/create-call", {
      type:'ite',
      integration_id: integrationID || null,
      params: JSON.stringify({
        internalNumber,
        externalNumber: phoneNumberClient,
        pbxNumber: '0443334024',
      })
    }).then(Actions.createCallBinotel.completed, Actions.createCallBinotel.failed)
  },

  onCreateCallBinotelCompleted() {},
  onCreateCallBinotelFailed() {},

  onInternalNumbersBinotel(integrationID) {
    if(this.loadingIternalNumber) return

    this.loadingIternalNumber = true
    if(integrationID) {
      yajax.get(this._prefix + 'binotel/stats/internal_numbers', {integration_id: integrationID}).then(Actions.internalNumbersBinotel.completed, Actions.internalNumbersBinotel.failed);
    }
  },
  onInternalNumbersBinotelCompleted(data) {
    this.loadingIternalNumber = false

    this.setState({
      internalNumbersBinotel: data.data
    })
  },
  onInternalNumbersBinotelFailed() {
    this.loadingIternalNumber = false
  },

  getInternalNumbersBinotel() {
    const list = this.get('internalNumbersBinotel')

    if(list === null) {
      Actions.internalNumbersBinotel()
      this.setState({
        internalNumbersBinotel: []
      })
      return []
    }
    return list.map(item => ({id: item.internalNumber, name: `${item.department ? `${item.department}: ` : ''}${item.name}(${item.internalNumber}) `}))
  },

  onAuthOlx(iid) {
    const data = {}

    if(iid) {
      data.iid = iid
    }
    yajax.post('/bolx/auth', data).then(Actions.authOlx.completed, Actions.authOlx.failed)
  },
  onAuthOlxCompleted() {

  },
  onAuthOlxFailed() {

  },
  onLoadChatStatus() {
    if(this.state.loadingStatus) return

    this.setState({
      loadingStatus: true
    })
    yajax.get(this._prefix + 'new_status').then(Actions.loadChatStatus.completed, Actions.loadChatStatus.failed);
  },
  onLoadChatStatusCompleted(data) {
    if(data.data) {
      this.setState({
        statuses: data.data
      })
    }
    this.setState({
      loadingStatus: false
    })
  },
  onLoadChatStatusFailed() {
    this.setState({
      loadingStatus: false
    })
  },

  onAddChatStatus(data) {
    yajax.put(this._prefix + 'new_status', data).then(Actions.addChatStatus.completed, Actions.addChatStatus.failed);
  },
  onAddChatStatusCompleted() {
    Actions.loadChatStatus()
    Actions.load()
  },
  onAddChatStatusFailed() {

  },

  onDeleteChatStatus(id) {
    const params = {
      method: "DELETE",
      url: this._prefix + "new_status",
      data: {
        id
      },
    }

    yajax(params).then(Actions.deleteChatStatus.completed, Actions.deleteChatStatus.failed);
  },
  onDeleteChatStatusCompleted() {
    Actions.loadChatStatus()
  },
  onDeleteChatStatusFailed() {

  },

  getChatsStatus() {
    const statuses = this.get('statuses')

    if(statuses === null) {
      Actions.loadChatStatus()
      this.setState({
        statuses: []
      })
      return []
    }

    return statuses
  },

  getChatTabsStatus(withoutAll) {
    const list = this.getChatsStatus()

    const reNameList = list.map(item => ({value: item.id, label: item.name}))
    const all = {value: 0, label: 'all', is_global: 1}
    const newStatus = {value: 'created', label: 'created', is_global: 1}

    reNameList.unshift(newStatus)

    if(!withoutAll) {
      reNameList.unshift(all)
    }
    return reNameList
  },

  getChatsStatusName(id) {
    const statuses = this.getChatsStatus()
    const findStatus = statuses.find(status => parseInt(status.id) === parseInt(id))

    if(findStatus) {
      return findStatus.name
    }
    return false
  },

  getChatsStatusById(id) {
    const statuses = this.getChatsStatus()
    return statuses.find(status => parseInt(status.id) === parseInt(id))
  },

  onSyncUserChatStatus(data) {
    yajax.post(this._prefix + 'new_status', data).then(Actions.syncUserChatStatus.completed, Actions.syncUserChatStatus.failed);
  },
  onSyncUserChatStatusCompleted(data) {
    Actions.load()
  },
  onSyncUserChatStatusFailed() {}

});

export default ChatsStore;
