import React, {useEffect, useState} from 'react'
import {
	Control,
	Field,
	Label
} from "react-bulma-components/lib/components/form";
import T from "../i18n";
import DatePicker from "react-datepicker";
import User from "../../user/user-store";
import {getDateRange, setDateWithString} from "../../tools/date-range";


const DateRange = ({label, date_start, date_end, disabledFastButton,range, onChange}) => {
	const [date, setDate] = useState({
		date_start: setDateWithString(date_start) || new Date(),
		date_end: date_end || new Date()
	})

	const handleChange = (name, value) => {
		setDate(prev => ({
			...prev,
			[name]: value
		}))
	}

	useEffect(() => {
		if(onChange) onChange(date)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	useEffect(() => {
		const period = setDateWithString(date_start)
		setDate(prev => ({
			...prev,
			date_start: period
		}))
	}, [date_start]);

	useEffect(() => {
		setDate(prev => ({
			...prev,
			date_end,
		}))
	}, [date_end]);

	return (
		<div className='date-range-wrapper'>
			<Field kind="addons">
				<Control>
					{label ? <Label>{T("sale-date-from")}</Label> : null}
					<DatePicker
						selected={date.date_start}
						onChange={(date) => handleChange('date_start', date)}
						name="start_date"
						className="input"
						selectsStart
						dateFormat="dd-MM-yyyy"
						startDate={date.date_start}
						endDate={date.date_end}
						locale={User.getLang()}
					/>
				</Control>
				<Control>
					{label ? <Label>{T('sale-date-to')}</Label> : null}
					<DatePicker
						selected={date.date_end}
						onChange={date => handleChange("date_end", date)}
						className="input"
						name="end_date"
						selectsEnd
						dateFormat="dd-MM-yyyy"
						locale={User.getLang()}
						startDate={date.date_start}
						endDate={date.date_end}
						minDate={date.date_start}
					/>
				</Control>
			</Field>
			{!disabledFastButton && getDateRange(handleChange, range || ['today', 'yesterday', 'week', '30days-short', 'this-month', 'last-month', '3-month'])}
		</div>
	);
};

export default DateRange;
