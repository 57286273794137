import React from 'react';

import T from "components/i18n";

import Button from 'react-bulma-components/lib/components/button';
import { Link } from "react-router-dom";


const InfoButton = ({text, url, color}) => {
  return (
     <Button renderAs={Link} 
          size="small"  rounded color={color || "info"}
          target="_blank" to={{
            pathname: url
          }}>{T(text)}
      </Button>
  )
}

export default InfoButton;