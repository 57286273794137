import React from "react";
import s from "./styles.module.scss";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../i18n";
import SkeletonText from "../skeleton/skeleton-text";
import InfoWrapper from "../info-wrapper";

const StatisticsBlock = ({
  list,
  classNameText,
  classNameTitle,
  isLoading,
}) => {
  return (
    <div className="display-flex-center flex-wrap margin-bottom-15 margin-top-10">
      {list?.map((item, index) => {
        if (isLoading) {
          if (index < 4) {
            return (
              <div className={s.wrapper} key={item.name}>
                <div className={s.skeleton}>
                  <SkeletonText
                    style={{ maxHeight: "14px", marginBottom: "5px" }}
                    minWidth="100px"
                    maxWidth="100px"
                  />
                  <SkeletonText
                    style={{ maxHeight: "24px", marginBottom: "5px" }}
                    minWidth="150px"
                  />
                  {!!item.additionalComponent && (
                    <div align="left" style={{ width: "100%" }}>
                      <SkeletonText
                        style={{ maxHeight: "13px" }}
                        minWidth="50px"
                        maxWidth="50px"
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          } else {
            return null;
          }
        } else {
          if (item.permission === undefined || item.permission) {
            let heading = (
              <Heading className={classNameTitle} heading>
                {T(item.name)}
              </Heading>
            );
            if (item?.info) {
              heading = (
                <div className="display-flex-center">
                  <InfoWrapper info={T(item?.info)}>
                    <Heading className={classNameTitle} heading>
                      {T(item.name)}
                    </Heading>
                  </InfoWrapper>
                </div>
              );
            }
            return (
              <div className={s.wrapper} key={item.name}>
                <div className={s.container}>
                  {heading}
                  <b className={item.classNameText || classNameText}>
                    {item.value}
                  </b>
                  {!!item.additionalValue?.value &&
                    <InfoWrapper info={T(item.additionalValue.info)}>
                      <b>
                        {item.additionalValue.value}
                      </b>
                    </InfoWrapper>}
                  {!!item.additionalComponent && item.additionalComponent}
                </div>
              </div>
            );
          }
        }
        return null;
      })}
    </div>
  );
};

export default StatisticsBlock;
