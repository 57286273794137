// import moment from "moment";


export default function formatDate(date, milliseconds) {
	let d = new Date(date*1000);
	// d.setTime( d.getTime() - d.getTimezoneOffset()*60*1000 );
	// d.setTime( d.getTime());
	const split = d.toTimeString().split(" ");
	const time_string = split[0].split(':')
	return `${d.toLocaleDateString()} ${time_string[0]}:${time_string[1]}${milliseconds ? `:${time_string[2]}` : ''}`;
	// console.log("timezone: ", User.getTimeZone(), date)
    // return moment(date*1000).utcOffset(User.getTimeZone(), true).format("DD-MM-YYYY HH:mm");
}