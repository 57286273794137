import React, {useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import {
	Checkbox,
	Control,
	Field, Input,
	Label, Radio
} from "react-bulma-components/lib/components/form";
import SelectComponent from "../../../../tools/select-component";
import T from "../../../../components/i18n";
import Button from "react-bulma-components/lib/components/button";
import Heading from "react-bulma-components/lib/components/heading";
import AppStore from "../../../../app-store";
import ImageModal from "../../../../components/image-modal";
import ActionsModal from "./actions-modal";
import ErrorMessageBlock
	from "../../../../components/error-message-block/error-message-block";
import Icon from "react-bulma-components/lib/components/icon";

const PriceTagSettingFieldModal = ({ position, size, height, width,brand, number_key, name, onClose, onSubmit }) => {
	const [valuePosition, setValuePosition] = useState(position ? {value: position} : {value: 1})
	const [valueFontSize, setValueFontSize] = useState(size ? size : 7)
	const [valueHeight, setValueHeight] = useState(height || 5)
	const [valueWidth, setValueWidth] = useState(width || 0.3)
	const [valueIsNumber, setValueIsNumber] = useState(number_key ? 1 : 0)
	const [valueIsBrand, setValueIsBrand] = useState(brand)
	const [errorMessage, setErrorMessage] = useState(null)
	const handleAutoRemoveErrorMessage = (message) => {
		setTimeout(() => setErrorMessage(null), 4000)
		setErrorMessage(message)
	}

	const handlerErrorMessage = () => {
			let errorMessage
			if((valueFontSize < 7 || valueFontSize > 30) && size) {
				errorMessage = T('price-tag-error-font')
			} else if( name === 'barcode' && (valueHeight < 5 || valueHeight > 20)) {
				errorMessage = T('price-tag-error-height')
			}
			if(!valueFontSize || (name === 'barcode' && !valueHeight && !valueWidth)) {
				errorMessage = T('not all fields required')
			}

			if(name === 'barcode' && valueWidth && !(parseFloat(`${valueWidth}`) >= 0.1 && parseFloat(`${valueWidth}`) <= 0.49)) {
				errorMessage = T('price-tag-width-barcode-error')
			}


			if(errorMessage) {
				handleAutoRemoveErrorMessage(errorMessage)
				return false
			}
			return true
	}

	const handleChangeInputValue = (evt) => {
		const value = evt.target.value
		const name = evt.target.name
		if(name === 'fontSize') setValueFontSize(+value)
		if(name === 'height') setValueHeight(+value)
		if(name === 'width') setValueWidth(value)
		if(name === 'number_key') setValueIsNumber(+value)
	}
	const handleChangeCheckbox = ({target: {checked}}) => {
		setValueIsBrand(checked)
	}

	const generatePositionList = Array.from({length: 8},
		(_, index) => ({id: index + 1, name: index + 1 + ' - ' + T(`price-tag-position-${index + 1}`)}) )

	const handleSubmit = () => {
		const data = {}

		if(name === 'barcode') {
			data.height = valueHeight
			data.width = valueWidth
			data.position = 0
			data.size = 15
			data.number_key = valueIsNumber
		} else {
			data.size = valueFontSize
			data.position = valuePosition.value
			if(name === 'name') {
				data.brand = valueIsBrand
			}
		}

		if(handlerErrorMessage()) {
			if(onSubmit) onSubmit(data)
			if(onClose) onClose()
		}
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading>{T('detailed-customization')}: {T(`price-tag-${name}`)}</Heading>
				{name !== 'barcode' && (
					<>
						<Field>
						<div align='right'>
							<Button
								size='small'
								rounded
								color='info'
								onClick={() => AppStore.openModal(<ImageModal src='/img/position-price-tag.jpg' alt='position price tag'/>)}
							>
								{T('revising-positioning-view')}
							</Button>
						</div>
							<Control>
									<SelectComponent
										name="position"
										label="price-tag-position-label"
										value={valuePosition}
										onChange={setValuePosition}
										list={() => generatePositionList}
										creatable={false}
									/>
							</Control>
						</Field>
						<Field >
								<Label>{T('price-tag-font-size')}</Label>
								<Control iconRight>
									<Input
										name='fontSize'
										size='large'
										type='number'
										min='7'
										onChange={handleChangeInputValue}
										value={valueFontSize || ''}
									/>
									<Icon align="right" style={{top: 0}}>
										<span className="small-text">pt</span>
									</Icon>
								</Control>
						</Field>
					</>)}
				{name === 'name' && (
					<Field>
						<Checkbox name='brand' checked={valueIsBrand} onChange={handleChangeCheckbox}>
							{T('reflect-brand-in-name')}
						</Checkbox>
					</Field>
				)}
				{name === 'barcode' && (
					<>
							<Field>
								<Label>{T('need-number-barcode')}</Label>
								<Radio name='number_key' value='1' onChange={handleChangeInputValue} checked={!!valueIsNumber}>
									{T('yes-number-price-tag-label')}
								</Radio>
								<Radio name='number_key' value='0' onChange={handleChangeInputValue} checked={!valueIsNumber}>
									{T('no-number-price-tag-label')}
								</Radio>
							</Field>
							<Field >
								<Label>{T('price-tag-width-barcode')}</Label>
								<Control iconRight >
									<Input
										name='width'
										size='large'
										type='number'
										min='0.1'
										onChange={handleChangeInputValue}
										value={valueWidth || ''}
									/>
									<Icon align="right" style={{top: 0}}>
										<span className="small-text">mm</span>
									</Icon>
								</Control>
							</Field>
							<Field >
								<Label>{T('price-tag-height-barcode')}</Label>
								<Control iconRight >
									<Input
										name='height'
										size='large'
										type='number'
										min='5'
										onChange={handleChangeInputValue}
										value={valueHeight || ''}
									/>
									<Icon align="right" style={{top: 0}}>
										<span className="small-text">mm</span>
									</Icon>
								</Control>
							</Field>
					</>)}
				<ActionsModal onSubmit={handleSubmit} onClose={onClose}/>
			</Box>
		</>
	);
};

export default PriceTagSettingFieldModal;