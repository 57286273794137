import React from 'react';
import T from "components/i18n";
import {
    Field,
    Control,
    Input,
    Label,
    // Checkbox,
} from 'react-bulma-components/lib/components/form';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import SubmitButton from 'components/submit-button';
import Messages from 'components/messages';
import BaseForm from 'components/modules/base-form';
import Actions from '../sales/sales-actions';
import Icon from "react-bulma-components/lib/components/icon";
import SalesStore from "../sales/sales-store";
import SelectComponent from "../../tools/select-component";
import ExpensesStore from "../expenses/expenses-store";
import RadioButtonListControl
    from "../../components/radio-buttons-list/radio-button-list-control";
import SelectComponents from "../../tools/select-component";
import AppStore from "../../app-store";
import AddExpensesCategoryModal from "../expenses/add-expenses-category-modal";
import NumberInput from "../../components/number-input/number-input";


const DEFAULT_COLORS = ["#D8BFD8", "#FFA07A", "#FFD700", "#FFFF00","#90EE90", "#DDA0DD", "#c6a5f5", '#eeee90', "#d4cbf9", "#876fe7", "#5f38ff", "#aeae00", "#00A046"]

const radioList = [
    {
        title: 'write-off-commission-manually',
        value: '0',
        style: {margin: 0},
        info: 'expense-deducted-income-tip'
    },
    {
        title: 'amount-account-minus-fee',
        value: '1',
        style: {margin: 0},
    },

    {
        title: "automatic-entry-of-expenses",
        value: "2",
        style: {margin: 0}
    }
]

class AddChannelModal extends BaseForm {
    constructor(props) {
        super(props);
        const indexColor = SalesStore.getChannelsIndexColor(props.id)
        this.state = {
            close: false,
            waiting: false,
            id:  props.id || 0,
            name: {
                error: false,
                value: props.name || ""
            },
            position: {
                value: props.position || ""
            },
            commission: {
                value: props.fee || "0"
            },
            color: {
                value: props.color || DEFAULT_COLORS[indexColor - 1]
            },
            fee_type: props?.fee_type ? `${props.fee_type}` : '0',
            expensesCategory: props.expensesCategory ? {value: props.expensesCategory, label: ExpensesStore.getCategoryName(props.expensesCategory)} : null,
            account_id: props.account_id ? {
                value: props.account_id
            } : null

        };
        this.submit = this.submit.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
    }



    componentDidMount() {
        const { onClose } = this.props;
        
        this.addChannels = Actions.addChannels.completed.listen((data) => {
            this.setState({
                'waiting': false
            });
            if (typeof(onClose) == "function") {
                onClose();
            }
        
        });

        this.addChannelsFail = Actions.addChannels.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            this.setState({
                'waiting': false,
                'message': {
                'msg': T(data['error'])
                }
            })
        }); 
    }

    componentWillUnmount() {
        if (typeof (this.addChannels) === "function") {
            this.addChannels();
        }

        if (typeof(this.addChannels) === "function") {
            this.addChannelsFail();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    onChangeRadio({name, value}) {
        this.setState({
            [name]: value
        })
    }

    submit() {
        if (this.state.name.value.trim().length === 0) {
        this.setState({
            name: {...this.state.name, error: true}
        });
        return
        }

        this.setState({ "waiting": true });

        const data = {
            'name': this.state.name.value.trim(),
            'position': this.state.name.value.trim(),
            'fee_type': this.state.fee_type,
        }

        if(this.state.fee_type === '2') {
            if(this.state.expensesCategory?.value) {
                data.expensesCategory = this.state.expensesCategory.value
            } else {
                this.setState({message: T('select-category'), waiting: false})
                return
            }
            if(this.state?.account_id?.value) {
                data['account_id'] = this.state.account_id.value
            } else {
                this.setState({message: T('choose-account'), waiting: false})
                return
            }
        }

        if (this.state.position) { data['position'] = parseInt(this.state.position.value); }
        if(this.state.commission) data['fee'] = parseFloat(this.state.commission.value)
        if (this.state.id) { data['id'] = this.state.id; }
        if(this.state.color.value) data['color'] = this.state.color.value

        Actions.addChannels(data)
    }

    render() {
        return (
            <div>
            <Messages message={this.state.message} close={() => this.setState({message: null})} />
            <Box>
                <Field>
                    <Control>
                        <Label>{T('sale-channel-name')}</Label>
                            <Input  size="large"
                            name="name"
                            type="text"
                            placeholder={T('sale-channel-name-placeholder')}
                            autoComplete="nope"
                            onChange={(e) => this.onChange(e)}
                            color={(this.state.name.error) ? "danger" : "primary"}
                            value={T(this.state.name.value)}
                            />
                    </Control>
                    </Field>
                <Field>
                    <Control>
                        <Label>{T('position')}</Label>
                            <Input  size="large"
                            name="position"
                            type="number"
                            min="0" step="1"
                            placeholder={T('position-placeholder')}
                            autoComplete="nope"
                            onChange={(e) => this.onChange(e)}
                            color="primary"
                            value={!!this.state.position.value ? parseInt(this.state.position.value): ""}
                            />
                    </Control>
                </Field>
                <Field>
                    <Label>{T('commission-label')}</Label>
                    <Control iconRight>
                        <NumberInput
                          size="large"
                          name="commission"
                          max={100}
                          placeholder={T('commission-placeholder')}
                          color="primary"
                          autoComplete="nope"
                          value={this.state.commission.value}
                          onChange={(e) => this.onChange(e)}
                        />
                        <Icon align="right" ><>%</></Icon>
                    </Control>
                </Field>
                <RadioButtonListControl propsWrapper={{className: 'display-flex-column'}} valueRadio={this.state.fee_type} name='fee_type' list={radioList} onChange={this.onChangeRadio}/>
                {this.state.fee_type === '2' && (
                  <SelectComponents
                    name='expensesCategory'
                    label='expenses-category'
                    value={this.state.expensesCategory}
                    list={() => ExpensesStore.getCategoryWithProfit(2)}
                    onChange={this.onChangeSelect}
                    placeholder='select-category'
                    addItem={({name}) => AppStore.openModal(<AddExpensesCategoryModal name={name} modal="add-expenses-category" isDisabledReceipt defaultValue="0" is_profit='2' disabledProfit/>)}
                    addItemModal={() => AppStore.openModal(<AddExpensesCategoryModal modal="add-expenses-category" isDisabledReceipt defaultValue="0" is_profit='2' disabledProfit/>)}
                    creatable
                  />
                )}
                {this.state.fee_type === '2' && (
                  <SelectComponent
                    name="account_id"
                    label={'sale-account'}
                    onChange={this.onChangeSelect}
                    value={this.state.account_id}
                    list={ExpensesStore.getAccountsList}
                    creatable={false}/>
                )}
                <Field>
                    <Control>
                        <Label>{T('color-channel-label')}</Label>
                        <Input
                          name='color'
                          type='color'
                          value={this.state.color.value}
                          onChange={(e) => this.onChange(e)}
                          style={{width: '44px'}}
                        />
                    </Control>
                </Field>
                <Button.Group>
                    <SubmitButton text={T(this.state.id ? 'btn-edit' : 'add-sales-channel-btn-label')} waiting={this.state.waiting} submit={this.submit}/>
                </Button.Group>
            </Box>
            </div>
        );

    }  
}

export default AddChannelModal;
