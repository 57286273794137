import React, { Component } from 'react';
import T from "components/i18n";
import LoadFiles from "components/load-files";

import Box from 'react-bulma-components/lib/components/box';
import Actions from './products-actions' 
import Messages from 'components/messages';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';


class LoadXmlModal extends Component {
    constructor(props) {
      super(props);
      
      const propMid = props.location?.state?.mid;
      const mid = propMid && propMid !== "all" ? propMid : null;
      
        this.state = {
          message: null,
          waiting: false,
          mid: mid
        };

        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentWillUnmount() {
      if (typeof(this.loadXml) == "function") {
          this.loadXml();
      }
    }         
    componentDidMount() {      
            const {onClose} = this.props;  
        this.loadXml = Actions.loadXml.completed.listen((data) => {
          this.setState({
            'message': {
                'msg': T('file_need_wait')
              },
              'color': 'success'
          })

          setTimeout(() => {
          if (typeof(onClose) == "function") {
              onClose();
          }}, 10000);
          //window.history.back();
        });

        this.loadXmlFailed = Actions.loadXml.failed.listen((data) => {
          this.setState({
            'message': {
                'msg': T('error-while-xml-load-filed')
            },
            'color': 'warning',
            'waiting': false,
          })
        });
    }
  
    submit(file, url, mid) {
    //  const urlRegExp = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,})([/\w.-]*)*\/?(\?[^\s]*)?$/i;
    //  const isUrlValid = urlRegExp.test(url);
    //  console.log(isUrlValid);
        
        const isValidUrl = url && url.trim().length > 4;
        if (!isValidUrl && !file) {
        this.setState({
            'message': {
                'msg': T('error-while-xml-load-filed')
            },
            'color': 'warning',
            'waiting': false,
          })
        return
        }

        this.setState({
          waiting: true
        })

        Actions.loadXml(file, url, mid);
    }

    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: {
            'value': value
          }
        });
    }


    render () {
        const options = [
          { value: '0', label: T('new-file') },
        ];

        return (
              <Box>
            <Messages message={this.state.message} color={this.state.color} timeout={8000} close={() => this.setState({ message: null })} />
            <LoadFiles submit={this.submit} templates={options} waiting={this.state.waiting} base_text={T('load-from-xml-text')} button_text={T('load-from-xml-button')} add_xml={true} mid={this.state.mid}/>
              </Box>
        );
    }

}

export default withRouter(LoadXmlModal);