import React, {useEffect} from "react";
import SelectComponent from "../../../../tools/select-component";
import WarehouseStore from "../../../warehouse/warehouse-store";
import WarehouseActions from "../../../warehouse/warehouse-actions";
import SelectProducts from "../../../../components/select-products";
import AddWriteOffList from "../add-write-off-list/add-write-off-list";
import Box from "react-bulma-components/lib/components/box";
import ButtonBack from "../UI/button-back";
import InfoBlock from "../../../../components/info-block";
import SubmitButton from "../../../../components/submit-button";
import Columns from "react-bulma-components/lib/components/columns";
import WriteOffStore from "../../write-off-store";
import WriteOffActions from "../../write-off-actions";
import Button from "react-bulma-components/lib/components/button";
import CommentField from "../../../../components/comment-field";
import InfoBlockQuantityAmount from "../../../../components/info-block-quantity-amount";
import AppStore from "../../../../app-store";
import AddWriteOffCategoryModal from "../../modal/add-write-off-category";


const StepZeroAdd = ({
										productsData,
										selectedProducts,
										mid,
										amount,
										allQuantity,
										step,
										comment,
										writeOffCategory,
										disabledSelectionMid,
										onChangeMid,
										onAddProduct,
										onChangeQuantity,
										onChangeCategory,
										onChangeComment,
										onRemove,
										onSubmit,
										isLoaded}) => {

	useEffect(() => {
		WriteOffActions.loadCategory()
	}, []);

	const handleAddCategory = (value) => {
		AppStore.openModal(<AddWriteOffCategoryModal name={value}/>)
	}

	return (
		<Box>
			<InfoBlock title={'write-off-title-step-1'} text=""/>
			<Columns>
				<Columns.Column>
					<SelectComponent
						name="from_mid"
						label="history-data-marketplace_id"
						onChange={onChangeMid}
						value={mid}
						readOnly={disabledSelectionMid}
						list={WarehouseStore.getWarehouseShipmentList}
						load={WarehouseActions.load}
						creatable={false}/>
				</Columns.Column>
				<Columns.Column>
					<SelectComponent
						name="category"
						label='write-off-category'
						empty='select-write-off-category'
						onChange={onChangeCategory}
						value={writeOffCategory}
						autoSelect={false}
						addItem={(value) => handleAddCategory(value.name)}
						addItemModal={() => handleAddCategory()}
						list={WriteOffStore.getWriteOffCategoryForSelect}
						creatable={true}/>
				</Columns.Column>
			</Columns>
			<SelectProducts
				onChange={onAddProduct}
				value={[]}
				selected={() => {
					return selectedProducts
				}}
				quantity={0}
				source='moving'
				mid={mid.value}/>

			<AddWriteOffList
				data={productsData}
				changeQuantity={onChangeQuantity}
				removeProduct={onRemove}
				step={step}
			/>
			<InfoBlockQuantityAmount lengthProduct={productsData?.length} allQuantity={allQuantity} amount={amount} title='write-off-count-item-added'/>
			<CommentField comment={comment} placeholder='write-off-comment-placeholder' onChange={onChangeComment} />
			<Button.Group position="centered" className='margin-top-10'>
				<SubmitButton
					size="large"
					rounded
					color="primary"
					text='next-btn'
					fullwidth={false}
					waiting={isLoaded}
					submit={onSubmit}/>
				<ButtonBack/>
			</Button.Group>

		</Box>
	);
};

export default StepZeroAdd;