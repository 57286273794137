import React, { Component } from 'react';
import T from 'components/i18n';

import {
  NavLink
} from "react-router-dom";

import Navbar from 'react-bulma-components/lib/components/navbar';



class MobileTopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
          navbar: false
        }

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
      this.setState({
        navbar: !this.state.navbar
      })
    }



    render () {
        return (
				<Navbar
              fixed="top"
              active={this.state.navbar}
              transparent={false}
              className="is-hidden-desktop"
            >
              <Navbar.Brand>
                <Navbar.Item renderAs="a" href="/dashboard">
                    <img src="/img/hp-logo-beta-black.svg" alt="Huge Profit" width="99" />
                </Navbar.Item>
                
                <Navbar.Item className="">
                </Navbar.Item>
                <Navbar.Item className="">
                </Navbar.Item>
                <Navbar.Burger onClick={this.toggleMenu}/>
              </Navbar.Brand>

              <Navbar.Menu  onClick={this.toggleMenu}>
                <Navbar.Container>
                  <Navbar.Item renderAs="span">
                      <NavLink activeClassName="sidebar-active" strict to="/dashboard">{T('dashboard')}</NavLink>
                  </Navbar.Item>
                  <Navbar.Item renderAs="span">
                      <NavLink activeClassName="sidebar-active" strict to="/sales">{T('sales')}</NavLink>
                  </Navbar.Item>
                  <Navbar.Item renderAs="span">
                      <NavLink activeClassName="sidebar-active" strict to="/expenses">{T('Payments')}</NavLink>
                  </Navbar.Item>
                  <Navbar.Item renderAs="span">
                    <NavLink activeClassName="sidebar-active"  strict to="/products">{T('Products')}</NavLink>
                  </Navbar.Item>
                  {/* <Navbar.Item renderAs="span">
                    <NavLink activeClassName="sidebar-active"  strict to="/warehouse">{T('Warehouse')}</NavLink>
                  </Navbar.Item> */}
                  <Navbar.Item renderAs="span">
                    <NavLink activeClassName="sidebar-active"  strict to="/shipments">{T('Shipments')}</NavLink>
                  </Navbar.Item>
                  <Navbar.Item renderAs="span">
                    <NavLink activeClassName="sidebar-active"  strict to="/transactions">{T('cash-flow')}</NavLink>
                  </Navbar.Item>
                  <Navbar.Item renderAs="span">
                    <NavLink activeClassName="sidebar-active"  strict to="/settings">{T('Settings')}</NavLink>
                  </Navbar.Item>
                </Navbar.Container>
                <Navbar.Container position="end">

                </Navbar.Container>
              </Navbar.Menu>
            </Navbar>
			)}

      }

export default MobileTopMenu;