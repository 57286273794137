import React from 'react'
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../components/i18n";

const EmptyMessageTerminalReceipts = () => {
	return (
		<Box>
			<Heading>{T('not-have-receipts')}</Heading>
		</Box>
	);
};

export default EmptyMessageTerminalReceipts;