import React from 'react'
import Dropdown from "react-bulma-components/lib/components/dropdown";
import T from "../../../components/i18n";
import { LocalIcon } from 'whitelables/wl-name/icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	changeStatus,
	deleteOrder, deleteReserveOrders,
	editOrderModal,
	openSaleModal
} from "./actions-func";
import AppStore from "../../../app-store";
import CancelledOrder from "./cancelled-order";
import ProductsStore from "../../products/products-store";



const ActionsDropdownOrder = ({data, isReserve}) => {
	let isStatusEdit = <></>
	if(data?.sales_id) {
		isStatusEdit = <Dropdown.Item value='edit'>
								<span onClick={() => editOrderModal(data?.sales_id)} className='nowrap'>
										<FontAwesomeIcon icon='edit' size='1x'/>&nbsp;{T('btn-edit')}
								</span>
						</Dropdown.Item>
	} else {
		isStatusEdit = <>
			{data.status !== 'cancelled' ? <Dropdown.Item value={T('confirm-order')}>
				<span onClick={(evt) => openSaleModal({evt, data})}
							className='text-success nowrap'>
					<LocalIcon icon='circle-success'
										 size='small'/>&nbsp;{T('confirm-order')}
				</span>
			</Dropdown.Item> : <></>}
			{data.status !== 'cancelled' ? <Dropdown.Item value={T('decline-order')}>
				<span className='has-text-danger nowrap'
							onClick={() => AppStore.openModal(<CancelledOrder data={data}/>)}>
					<LocalIcon icon='circle-close'
										 size='small'/>&nbsp;{T('decline-order')}
				</span>
			</Dropdown.Item> : <></>}
			<Dropdown.Item  value={T('change-order-status')}>
				<span onClick={(evt) => changeStatus({evt, data})} className='nowrap'>
					<LocalIcon icon='circle-dotted' size='small'/>&nbsp;{T('change-order-status')}
				</span>
			</Dropdown.Item>
		</>
	}
	let isUndoReserve = <></>

	if(isReserve) {
		const message = data.order_data.map(item => {
			let name = item.name
			if (item.local_product_id) {
				name = ProductsStore.getProductName(item.local_product_id, true)
			}

			return <div key={`${item.id}-${item.local_product_id}-${item.product_id}`}>{name} <b>{item.quantity} {T("items-p")}</b></div>
		})
		isUndoReserve = <Dropdown.Item value={T('withdraw-all-reserves')}>
				<span onClick={(evt) => deleteReserveOrders(data.id, <>{message}</>)} className='nowrap'>
					<FontAwesomeIcon icon='undo'/>&nbsp;{T('withdraw-all-reserves')}
				</span>
		</Dropdown.Item>
	}

	return (
		<Dropdown
			onClick={(evt) => evt.stopPropagation()}
			right
			className="dot"
			style={{marginBottom: '6px'}}
			label={<LocalIcon icon='ellipsis'/>}>
			{isStatusEdit}
			{isUndoReserve}
			<Dropdown.Item value={T('del')}>
				<span onClick={(evt) => deleteOrder({evt, data})} className='nowrap'>
					<FontAwesomeIcon icon='trash-alt' size='1x'/>&nbsp;{T('del')}
				</span>
			</Dropdown.Item>
		</Dropdown>
	);
};

export default ActionsDropdownOrder;