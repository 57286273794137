import React, { useEffect } from 'react'
import List from "react-bulma-components/lib/components/list";
import Level from "react-bulma-components/lib/components/level";
import T from "../../../../components/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import AppStore from "../../../../app-store";
import {confirmDelete} from "../../../../tools/confirm-delete";
import SalesStore from "../../../sales/sales-store";

const CatalogListItem = ({modal, type, typeList, additionalSettings, onDelete, onError, ...rest}) => {
	const {name, id, is_global, is_profit, is_receipt} = rest
	useEffect(() => {
		if (type && ["order", "sale"].includes(type)) {
			rest["type"] = type;
		}
	}, [rest, type])

	const handleDelete = () => {
		if (typeList === "statuses") {
			const currentStatuses = SalesStore.getCountDelivery();
			if (currentStatuses[id] >= 1) {
				onError('you-cant-delete-status-in-use')
				return
			}
		}

		const checkType = type ? type : typeList
		confirmDelete(() => onDelete({type: checkType, id}), 'confirm-delete', 'are-you-sure-to-delete-this-catalog')
	}

	let profitText = null

	if(name === 'shipment' || name === 'shipment-delivery') {
		profitText = 'special-category'
	} else {
		if(is_profit === 0) {
			profitText = 'normal-consumption'
		} else if (is_profit === 1) {
			profitText = 'profit-on-sales'
		} else if(is_profit === 2){
			profitText = 'not-to-be-income'
		}
	}


	return (
		<List.Item align="left">
			<Level className="is-mobile">
					<Level.Item style={{flexShrink: 1, textAlign: 'center', marginRight: is_global === 1 ? '38px': '0.75rem'}}>
						<div>
							{T(name)} {profitText && is_receipt === 0 && <i>({T(profitText)})</i>}
						</div>
					</Level.Item>
				<Level.Side align="right">
					<Level.Item>
						{is_global !== 1  && (
							<Link to="#"
								onClick={(() => AppStore.openModal(modal(rest, additionalSettings)))}
							>
								<FontAwesomeIcon icon="edit" size="1x" />
							</Link>)
						}
					</Level.Item>
					<Level.Item>
						{is_global !== 1 && (
							<Link to="#"
									onClick={handleDelete}
									title={T('delete')}
						>
							<FontAwesomeIcon icon="trash-alt" size="1x" />
						</Link>)}
					</Level.Item>
				</Level.Side>
			</Level>
		</List.Item>
	);
};

export default CatalogListItem;