import React from 'react'
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import BindingClientModal from "../../binding-client";
import ClientsStore from "../../../clients/clients-store";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../components/i18n";
import formatDate from "../../../../tools/format-date";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import UserStore from "../../../../user/user-store";
import {Box} from "react-bulma-components";
import Tag from "../../../../components/tag";
import ClientViewModal
	from "../../../clients/modal/client-view/client-view-modal";

const ClientInfo = ({clientID, activeChat}) => {
	const client = ClientsStore.getClientById(clientID);
	const tagsGroup = ClientsStore.getGroupClientTagByClientId(clientID)

	return client && (
		<Box>
				<Heading size={4}>{client['name']}</Heading>
				<b>{T('created_at')}</b>: {formatDate(client['created_at'])} <br />
				<b>{T('clients-discount')}</b>: {client['discount'] || 0 }% <br />
				<b>{T('clients-total_amount')}</b>: <Money amount={client['total_amount']} wrapper={DOM.span}/> <br />
				<b>{T('responseble')}</b>: {UserStore.getResponsebleName(client['rid'])} <br />
				{!!tagsGroup?.length && <span><b>{T("clients-tags")}: </b> {tagsGroup.map(item => <Tag key={`tag-${item.id}`} color={item.color} name={item.name}/>)}<br/></span>}

			<div className='display-flex-row margin-top-10' style={{justifyContent: 'center'}}>
				<OpenInsideModal  size="small" text="info" color="success">
					<ClientViewModal clientID={clientID}/>
				</OpenInsideModal>
				<OpenInsideModal  size="small" text="change-binding-client-btn">
					<BindingClientModal modal="binding-client" data={activeChat} />
				</OpenInsideModal>
			</div>
		</Box>
	);
};

export default ClientInfo;