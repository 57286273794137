import React from 'react'
import WarehouseStore from "../warehouse/warehouse-store";
import SelectComponent from "../../tools/select-component";
import InventoryStore from "./inventory-store";
import InventoryActions from "./inventory-actions";
import User from "../../user/user-store";
import WarehouseActions from "../warehouse/warehouse-actions";

const InventoryFilters = () => {
	let listWarehouse = WarehouseStore.getWarehouseList();
	let listSubUsers = User.getSubUsersList();


	const handleChangeSelect = (selectedOption, actionMeta) => {
		InventoryActions.filter(actionMeta.name, selectedOption);
	}

	return (
		<>
			{listWarehouse.length >= 1 && <SelectComponent
				name="mid"
				empty="all-warehouse"
				label={"history-data-marketplace_id"}
				autoSelect={false}
				onChange={handleChangeSelect}
				value={InventoryStore.getFilter("mid")}
				list={() => listWarehouse}
				creatable={false}/>}
			{listSubUsers.length >= 1 && <SelectComponent
				name="rid"
				label="manager"
				empty="all"
				onChange={handleChangeSelect}
				value={InventoryStore.getFilter("rid")}
				list={() => listSubUsers}
				load={WarehouseActions.load}
				creatable={false}
			/>}
		</>
	);
};

export default InventoryFilters;