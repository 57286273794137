import { filterSearch } from "../../tools/filters-helper";
import ProductionStore from "./production-store";
import ProductsStore from "../products/products-store";

export const applyFilter = (item, filters, unix, disabledDate) => {
  let result = true;

  if(!disabledDate) {
    let date = new Date(item.created_at);
    filters["date_start"].setHours(0, 0, 0, 0);
    filters["date_end"].setHours(23, 59, 59, 999);

    if (unix) {
      date = new Date(item.created_at * 1000);
    }

    if (date < filters["date_start"] || date > filters["date_end"]) {
      return false;
    }
  }

  if (filters) {
    const getProduct = ProductsStore.getProduct(
      item?.result_product_id || item?.product_id
    );
    if (filters?.search) {
      const search_data = {
        name: item.name,
        id: item.id,
        category: ProductionStore.getCategoryName(item.category_id),
        comment: item.comment,
        product: getProduct?.name,
        skus: getProduct?.skus,
        asin: getProduct?.asin,
        production_id: item?.production_id,
      };
      result =
        result &&
        filterSearch(
          filters?.search,
          [
            "id",
            "name",
            "category",
            "comment",
            "product",
            "asin",
            "skus",
            "production_id",
          ],
          search_data
        );
    }

    if (filters?.category && filters?.category.value) {
      const getIdsCategory = ProductionStore.getChildrenCategoryList(filters.category.value, 'id')
      if(!getIdsCategory.includes(parseFloat(item.category_id))) {
        result = result && false;
      }
    }

    if (filters?.mid && filters?.mid.value) {
      if (
        parseFloat(filters?.mid?.value) !==
        parseFloat(item?.marketplace_id || item?.result_marketplace_id)
      ) {
        result = result && false;
      }
    }
  }

  return result;
};
