import React from 'react'
import PrintButton from "../../../../tools/print/button";
import PrintAction from "../../../../tools/print/action";
import SalesStore from "../../sales-store";
import User from "../../../../user/user-store";
import UserStore from "../../../../user/user-store";

const PrintActionButtons = ({orderID}) => {
	const url_print_slip = SalesStore.getUrlPrintSlip(orderID);

	return User.getModuleEnable("slip") && (
		<>
			<PrintButton url={url_print_slip} text='open-print-slip' />
			<PrintAction url={url_print_slip} text='print-slip' isAutoPrint={UserStore.getMoreSetting('is_auto_print')}/>
		</>
	);
};

export default PrintActionButtons;