import React, { useState } from "react";
import Container from "../../common/container/container";
import s from "./styles.module.scss";
import T from "../../../components/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Title from "../../common/title/title";

const BenefitItem = ({ text, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <article className={s.item}>
      <span className={s.heading} onClick={handleOpen}>
        <FontAwesomeIcon icon={isOpen ? "minus-circle" : "plus-circle"} />
        <h4>{T(title)}</h4>
      </span>
      <p className={`${s.content} ${isOpen ? s.active : ""}`}>{T(text)}</p>
    </article>
  );
};

const SectionBlock = ({ title, list, imgSrc }) => {
  return (
    <Container classNameWrapper={s.wrapper} classNameContainer={s.section}>
      <section style={{ flex: 1 }} className={s.list}>
        <Title>{T(title)}</Title>
        {list.map((item) => (
          <BenefitItem key={item.text} {...item} />
        ))}
      </section>
      <section style={{ flex: 1 }}>
        <img src={imgSrc} alt={title} />
      </section>
    </Container>
  );
};

export default SectionBlock;
