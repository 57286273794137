import React, { useEffect, useState } from "react";
import { Checkbox, Input } from "react-bulma-components/lib/components/form";
import T from "../../i18n";
import User from "../../../user/user-store";

const PickItem = ({
  label,
  isActive,
  position,
  id,
  defaultPosition,
  onChange,
  onChangePosition,
}) => {
  const [checked, setChecked] = useState(isActive || false);

  const handleChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);

    onChange &&
      onChange({
        name: label,
        value: {
          label,
          is_active: newChecked,
          is_global: false,
          position: position || defaultPosition,
          id
        },
      });
  };

  const handleChangePosition = (evt) => {
    evt.stopPropagation();
    const value = evt.target.value;

    onChangePosition && onChangePosition({ name: label, value });
  };

  useEffect(() => {
    setChecked(isActive || false);
  }, [isActive]);

  return (
    <div className="pick-item">
      <div
        style={{ flex: 1 }}
        className="display-flex-row"
        onClick={handleChange}
      >
        <Checkbox name={label} checked={checked} onChange={handleChange} />
        {T(label, {'curr': User.getCurrency()})}
      </div>
      <Input
        size="small"
        placeholder={T("position-label")}
        value={position || ''}
        onChange={handleChangePosition}
        style={{ flex: 0, minWidth: "100px" }}
        type="number"
        min='2'
      />
    </div>
  );
};

export default PickItem;
