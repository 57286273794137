import React from "react";
import ProductsStore from "../../../products-store";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import T from "../../../../../components/i18n";
import { Columns, Heading, List } from "react-bulma-components";
import FieldDesc from "../common/field-desc";
import User from "../../../../../user/user-store";

const MovingProductRow = ({ data, units }) => {
  const comment = JSON.parse(data["comment"]);
  return (
    <List.Item>
            <div><small>{data["created_at"]} </small> | <small>{User.getResponsebleName(data["responsible_user_id"])}</small></div>
      <Columns>
        <Columns.Column>
          <Heading className="margin-bottom-0" size={6}>
            {data?.is_deleted && <small className='has-text-danger'>{T('canceled')} </small>}
            {T("moving")} ID: {comment["mov_id"]} (
            {ProductsStore.getStockUnits(comment["quantity"], units)})
          </Heading>
        </Columns.Column>
        <Columns.Column>
          <FieldDesc
            name="from-product-marketplace"
            value={WarehouseStore.getWarehouseName(comment["from_mid"])}
          />
          <br />
          <FieldDesc
            name="to-product-marketplace"
            value={WarehouseStore.getWarehouseName(comment["to_mid"])}
          />
        </Columns.Column>
      </Columns>
      {comment["comment"] !== "" ? comment["comment"] : ""}
    </List.Item>
  );
};

export default MovingProductRow;
