import React, { Component } from "react";
import Box from 'react-bulma-components/lib/components/box';
import T from "components/i18n";
import ColorControl from 'tools/color-control';
import numberFormat from "tools/number-format";
import InfoWrapper from "../info-wrapper";

class NumberBlock extends Component {

  render() {
    const {children, top, number, bottom, simple, money, className, style, subchild, info} = this.props;
    let cost = numberFormat(number, 2),
        part_one = cost.split(".")[0],
        _className = className || "",
        coins = cost.split(".")[1] || "";

    if (coins !== "" && coins !== "00") {
      _className += " product_main";
      coins = (
          <span className="coins">.{coins}</span>
      )
    } else {
      coins = null;
    }

    if (subchild) {
      _className += " padding-bottom0 padding-left-right5";
    }

    if(info) {
      return (
        <Box className={_className} style={style}>
          <InfoWrapper info={T(info)}>{T(top)}</InfoWrapper>
            <span style={{display: "block"}}>
                {ColorControl(part_one, simple, money)}
                {coins}
            </span>
          <div>{T(bottom)} {children}</div>
          {subchild ? subchild : null}
        </Box>
      );
    }

    return (
          <Box className={_className} style={style}>
                <div>{T(top)}</div>
                <span>
                  {ColorControl(part_one, simple, money)}
                  {coins}
                </span>
                <div>{T(bottom)} {children}</div>
                {subchild ? subchild : null}
          </Box>
      );
  }
};

export default NumberBlock;