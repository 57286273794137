import {useState} from "react";
import T from '../../../../../components/i18n'

const IntegrationControlHook = ({inputDefaultValue, selectDefaultValue, checkboxDefaultValue, radioDefaultValue}) => {
		const [inputValues, setInputValues] = useState(inputDefaultValue || {})
		const [errorMessage, setErrorMessage] = useState(null)
		const [selectValue, setSelectValue] = useState(selectDefaultValue || {})
		const [checkboxValue, setCheckboxValue] = useState(checkboxDefaultValue || {})
		const [radioValue, setRadioValue] = useState( radioDefaultValue || {})

		const handleChangeSelect = (value, { name }) => {
			if(value?.length && (name === 'city' || name === 'postoffice')) return

			setSelectValue(prevState => ({
				...prevState,
				[name]: value
			}))
		}
		const handleAutoRemoveError = (message) => {
			setTimeout(() => setErrorMessage(null), 4000)
			setErrorMessage(T(message))
		}

		const handleChangeRadio = ({name, value}) => {
			setRadioValue(prev => ({
				...prev,
				[name]: value
			}))
		}

		const handleChangeCheckbox = (evt) => {
			const name = evt.target.name
			const checked = evt.target.checked
			setCheckboxValue(prev => ({
				...prev,
				[name]: checked,
			}))
		}

		const handleChangeInputValues = (evt) => {
			const name = evt.target.name;
			const value = evt.target.value
			setInputValues(prevState => ({
				...prevState,
				[name]: value
			}))
		}

		const handleChangeGroupInputValues = (value) => {
			setInputValues(prevState => ({
				...prevState,
				...value
			}))
		}

		return {
				inputValues,
				handleChangeInputValues,
				handleChangeGroupInputValues,
				errorMessage,
				handleAutoRemoveError,
				setErrorMessage,
				selectValue,
				handleChangeSelect,
				setSelectValue,
				checkboxValue,
				handleChangeCheckbox,
				setCheckboxValue,
				handleChangeRadio,
				radioValue,
				setRadioValue,
		}
}

export default IntegrationControlHook