import React, { useState } from "react";
import T from "../../../../components/i18n";
import Spinner from "../../../../templates/spinner";
import { Button } from "react-bulma-components";
import UserStore from "../../../../user/user-store";
import { Link } from "react-router-dom";
import { createMobileAppLink } from "../../../../tools/create-mobile-app-link";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const Preview = ({ onSubmit, name }) => {
  const {isMobileSize} = useIsMobile()
  const [isLoaded, setIsLoaded] = useState(false);
  const handleSubmit = () => {
    if (onSubmit) onSubmit();

    setIsLoaded(true);
    setTimeout(() => setIsLoaded(false), 500);
  };

  const getLink = () => {
    return `/bprint/getslip?preview=${name}&lang=${UserStore.get(
      "lang"
    )}&token=${encodeURIComponent(UserStore.getToken())}`;
  };

  return (
    <>
      {!isMobileSize && isLoaded && (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <Spinner />
        </div>
      )}
      {!isMobileSize && !isLoaded && (
        <iframe
          title="price"
          src={getLink()}
          width="100%"
          height="300"
          style={{ borderRadius: 16 }}
        ></iframe>
      )}
      <Button.Group position="centered" style={{marginTop: 10}}>
        {isMobileSize ? (
          <Link
            className="button is-small is-info is-rounded"
            to={getLink()}
            target="_blank"
            onClick={() => createMobileAppLink(getLink())}
          >
            <span>{T("save-and-view-btn")}</span>
          </Link>
        ) : (
          <Button rounded size="small" color="info" onClick={handleSubmit}>
            <span>{T("save-and-view-btn")}</span>
          </Button>
        )}
      </Button.Group>
    </>
  );
};

export default Preview;
