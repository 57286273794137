import React from "react";
import Section from "react-bulma-components/lib/components/section";
import Box from "react-bulma-components/lib/components/box";
import Content from "react-bulma-components/lib/components/content";
import Heading from "react-bulma-components/lib/components/heading";
import FooterBlock from "../common/footer";

const ReturnEn = () => {
	return (
		<Section>
			<Box>
				<Content>
					<Heading>Payment. Returns. Guarantees</Heading>
					<Heading subtitle size={5}>
						The cost of the selected Tariff, the procedure for calculating and reimbursement terms
					</Heading>
					<p>
						The cost of the selected Tariff is determined in accordance with the Company's Tariffs.
					</p>{" "}
					<p>
						The Company has the right to change the cost of the selected
						Tariff and the conditions for changing tariff plans by publishing them in a
						new edition on the Company's website at h-profit.com/pricing/.
						The new tariffs come into force from the date of their publication.
					</p>{" "}
					<p>
						If the User continues to use the Service after the new Tariffs come into
						of the new Tariffs, it is considered that he has accepted such changes
						Tariffs and has agreed to continue cooperation in accordance with the
						the new Tariffs.
					</p>{" "}
					<p>
						In any case, the recalculation of the Selected Tariff for the paid periods
						is not carried out. is not carried out.
					</p>
					<Heading subtitle size={5}>
						Payment procedure
					</Heading>
					<p>
						The license fee shall be paid in accordance with the selected
						Tariff, in an advance payment of 100% of the amount of the selected tariff,
						not less than two business days before the desired date of commencement of use of the
						Service.
					</p>
					<p>
						All Payment Methods are listed on the Company's website at
						<a href="https://h-profit.com/pricing/">
							{" "}https://h-profit.com/pricing/
						</a>{" "}
						and in the user's account.
					</p>{" "}
					<p>
						The moment of fulfillment of the User's payment obligations is the day of
						of crediting the funds to the Company's current account.
					</p>{" "}
					<p>
						Users who pay for the selected tariff agree to the following services
						automatic regular automatic renewal of the selected Tariff
						for a new term (auto-renewal of the selected Tariff). After activating
						of this service, payment for the new term of the selected tariff is made by
						automatic non-acceptance debiting of funds from the user's account.
						The service of auto-renewal of the selected Tariff is provided until
						The User does not cancel the subscription to the selected Tariff in the Company's service.
					</p>{" "}
					<p>
						The service is activated when you make the first payment for the selected
						Tariff, which means that the user agrees that upon expiration of the
						of the paid validity period of the selected Tariff, the Agreement will be automatically
						prolonged on the same terms for the same period with automatic
						writing off the cost of the selected Tariff from the User's account
					</p>{" "}
					<p>
						In case of insufficient funds on the User's account or inability to
						the selected Tariff is not provided for a new term.
						is provided.
					</p>{" "}
					<p>
						If the Company changes the Tariff, the selected Tariff shall be auto-renewed for
						the next new term following the period during which
						such change occurred will be made at the new rate.
					</p>{" "}
					<p>
						All fees charged by credit organizations / other third
						parties when making a payment shall be paid by the Paying Party.
					</p>
					<Heading subtitle size={5}>
						Terms of reimbursement
					</Heading>
					<p>
						The Company guarantees the User the possibility of a refund
						funds paid as payment for the selected Tariff within 14
						(fourteen) calendar days from the date of payment by
						sending an e-mail about early termination of this
						Agreement and returning the funds paid as an advance from the
						the user's registered e-mail to the e-mail address
						Company's e-mail h.info@h-profit.com. Upon expiration of 14 (fourteen)
						calendar days from the date of payment, no refund will be made.
						shall not be made.
					</p>{" "}
					<p>
						The Company, in case of receipt of an official application within the period specified in clause 4.3.1.
						of the Agreement, the Company undertakes to consider the said application and
						return the funds to the User's account from which the funds were
						within thirty (30) calendar days from the date of receipt of the letter.
						of receipt of the letter. In cases where a refund to the User's account
						from which the funds were paid is not possible, the User shall
						shall indicate the new account in the letter of refund of the funds paid as an advance
						in the letter of refund.
					</p>{" "}
					<p>
						The refund shall mean early termination of thisAgreement.
					</p>
					<Heading subtitle size={5}>
						Payment security guarantee
					</Heading>
					<p>
						Our website is connected to Internet acquiring, and you can pay for
						Subscription with bank cards. After confirming the selected
						a secure window will open with the payment page of the
						processing center, where you will need to enter your
						bank card details. For additional authentication of the cardholder
						3D Secure protocol is used for additional cardholder authentication. If your Bank supports this
						technology, you will be redirected to its server for additional
						identification. For information on the rules and methods of additional
						identification, please contact the Bank that issued your bank card.
					</p>
					<p>
						<b>Security guarantees</b>
					</p>
					<p>
						The processing center protects and processes your bank card data
						card data in accordance with the PCI DSS 3.2 security standard. The transfer of information to the
						the payment gateway is transmitted using the encryption technology
						SSL ENCRYPTION TECHNOLOGY. Further information is transferred via closed
						banking networks that have the highest level of reliability.
						The processing center does not share your card details with us or other third parties.
						parties. For additional authentication of the cardholder
						3D Secure protocol is used for additional cardholder authentication.
					</p>
					<p>
						If you have any questions regarding the payment made, you can
						contact the customer support service of the payment service.
					</p>
					<p>
						<b>Online payment security</b>
					</p>
					<p>
						The personal information you provide (name, address, phone number, e-mail,
						credit card number) is confidential and will not be disclosed.
						Your credit card information is transmitted only in encrypted form and is not stored on our Web server.
						and are not stored on our Web server.
					</p>
					<p>
						We recommend that you check that your browser is secure enough
						to make payments online, on a special page.
					</p>
					<p>
						The security of online payment processing is guaranteed by the processing center. All
						operations with payment cards are carried out in accordance with the requirements of VISA
						International, MasterCard and other payment systems. During the
						special security technologies are used during the transfer of information
						for online card payments, and data is processed on a secure
						high-tech server of the processing company.
					</p>
				</Content>
			</Box>
			<FooterBlock />
		</Section>
	);
};

export default ReturnEn;
