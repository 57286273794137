export const trimNumber = (number, maxDecimalPlaces, currency) => {
	let numberStr = number.toString();

	let decimalPointIndex = numberStr.indexOf('.');

	if (decimalPointIndex === -1) {
		return number;
	}

	let decimalPlaces = numberStr.length - decimalPointIndex - 1;

	if (decimalPlaces > maxDecimalPlaces) {
		return parseFloat(number.toFixed(maxDecimalPlaces));
	}

	if(currency) {
		return `${number} ${currency}`;
	}

	return number;
}