import React from 'react'
import NewProductRowStockMobile from "./new-product-row-stock-mobile";
import NewProductRowMobile from "./new-product-row-mobile";

const SingleRenderMobile = ({data, backgroundLightBlack, isVariable}) => {
	if (Object.values(data.stock).length > 1) {
		return <NewProductRowStockMobile data={data}
															 backgroundLightBlack={backgroundLightBlack} isVariable={isVariable} />;
	} else {
		return <NewProductRowMobile data={data} stock={Object.values(data.stock)[0]}
													backgroundLightBlack={backgroundLightBlack} isVariable={isVariable} disabledShowAndWrapper={isVariable}/>
	}
};

export default SingleRenderMobile;