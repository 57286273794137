import React from 'react'
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../i18n";
import {Label, Textarea} from "react-bulma-components/lib/components/form";
import SubmitButton from "../submit-button";
import {Button} from "react-bulma-components";
import ErrorMessageBlock from "../error-message-block/error-message-block";

const TextareaModal = ({value, heading, errorMessage, waiting, submitTitle, label, onError, onChange, onSubmit, onClose}) => {
	return (
		<>
		<ErrorMessageBlock message={errorMessage} setErrorMessage={onError} />
		<Box>
			<Heading>
				{T(heading)}
			</Heading>
			<Label>{T(label)}</Label>
			<Textarea value={value} onChange={onChange} />
			<div className='display-flex-row margin-top-10' style={{ justifyContent: 'flex-end' }}>
				<SubmitButton
					text={submitTitle}
					fullwidth={false}
					color='success'
					size='medium'
					rounded
					submit={onSubmit}
					waiting={waiting}
				/>
				<Button size='medium' rounded onClick={onClose}>
					{T('close')}
				</Button>
			</div>
		</Box>
		</>
	);
};

export default TextareaModal;