import React, {useEffect, useState} from 'react'
import {
	Control,
	Field,
	Radio
} from "react-bulma-components/lib/components/form";
import ProductsStore from "../../modules/products/products-store";
import T from "../i18n";
import ProductsActions from "../../modules/products/products-actions";
import {connectToStores} from "../../tools/reflux-tools";

const StockFilter = ({ _filter } ) => {
	const [stateValue, setStateValue] = useState(null)

	const handleChange = (evt) => {
		const value = evt.target.value
		ProductsActions.filter('instock', value)
	}

	useEffect(() => {
			setStateValue(_filter.instock)
	}, [_filter.instock]);

	useEffect(() => {
		ProductsActions.filter('instock', _filter.instock)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<Field>
			<Control>
				<Radio
					onChange={handleChange}
					checked={stateValue === "0"}
					value="0"
					size="large"
					name="instock">
					{T("_all")}
				</Radio>
				<Radio
					onChange={handleChange}
					checked={stateValue === "1"}
					value="1"
					size="large"
					name="instock">
					{T("in-stock")}
				</Radio>
				<Radio
					onChange={handleChange}
					checked={stateValue === "2"}
					value="2"
					size="small"
					name="instock" >
					{T("out-of-stock")}
				</Radio>
			</Control>
		</Field>
	);
};

export default connectToStores(StockFilter, {_: ProductsStore}) ;