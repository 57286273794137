import React from 'react';
import PaginationBlock from "components/pagination";
import BaseForm from "components/modules/base-form";

class BaseModule extends BaseForm {
    constructor(props) {
        super(props);
        this.state = {
            page: 0
        };
    }

    getPaginationBlock() {
        return (
            <PaginationBlock 
                    setPage={this.actions.setPage}
                    totalPage={this.store.get("count_page")}
                    currentPage={this.store.get("page")}
            />
        );
    }
} 


export default BaseModule;