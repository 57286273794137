import React, {useState} from 'react'
import SearchString from "./search-string";
import Box from "react-bulma-components/lib/components/box";
import T from "../i18n";
import Button from "react-bulma-components/lib/components/button";
import { LocalIcon } from 'whitelables/wl-name/icons'
import FixedContainerFilter from "./fixed-container-filter";
import DateRange from "./date-range";
import SortMobile from "../sort-mobile";
import {Checkbox} from "react-bulma-components/lib/components/form";
import FilterViewNow from "./filter-view-now";
import {useIsMobile} from "../is-mobile-context/is-mobile-context";


const BaseFilterLayout = ({refresh,filterView, sortSetting, selectAllSetting, searchStringSetting, dateRangeSetting, hiddenFilter, additionalSetting}) => {
	const [showFilter, setShowFilter] = useState(false)
	const [refreshDisabled, setRefreshDisabled] = useState(false)
	const {isMobileSize} = useIsMobile()

	const toggleShop = () => {
		setShowFilter(prevState => !prevState)
	}

	const handleRefresh = () => {
		setRefreshDisabled(true)
		if(refresh?.onChange) refresh.onChange()
		setTimeout(() => setRefreshDisabled(false), refresh?.delayDisabled || 3000)
	}

	return (
		<>
			<Box className='filter'>
				<div className="base-filter-layout">
					<div className='filter-box nowrap'>
						{isMobileSize && selectAllSetting?.onChange && <Checkbox checked={selectAllSetting.isChecked} onChange={selectAllSetting.onChange}/>}
						{isMobileSize && sortSetting?.onChange && sortSetting?.data && <SortMobile {...sortSetting}/>}
						{refresh?.onChange && <Button className="sb" rounded onClick={handleRefresh} disabled={refreshDisabled || refresh?.isLoading}>
							<LocalIcon icon='refresh' size='small'/>
						</Button>}
					</div>

					<div className='filter-box'>
						{searchStringSetting?.onChange && <SearchString {...searchStringSetting}/>}
						{dateRangeSetting?.onChange && <DateRange {...dateRangeSetting}/>}
					</div>
					<div className='filter-box nowrap'>
						{additionalSetting && additionalSetting}
						{hiddenFilter && <Button
							className="sb bicon"
							onClick={toggleShop}
						>
							<LocalIcon icon='filter' />
							<span>{T('filter')}</span>
						</Button>}
					</div>
				</div>
				<FilterViewNow filterView={filterView}/>
			</Box>
			{hiddenFilter && (
				<FixedContainerFilter
								show={showFilter}
								setShow={toggleShop}
								hiddenFilter={hiddenFilter}/>)}
		</>

	);
};

export default BaseFilterLayout;