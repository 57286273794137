import React, { Component } from 'react';

import Content from 'react-bulma-components/lib/components/content';
import Section from 'react-bulma-components/lib/components/section';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import FooterBlock from '../common/footer'
import AppStore from 'app-store'
import { backLink } from 'tools/back-link' 

class Policy extends Component {
    render () {
        return (
        	<Section>
        		{backLink()}
        	<Box align="left">
        	<Content>
				<Heading>Політика конфіденційності та захисту персональних даних</Heading>
				<Heading subtitle renderAs="h2">Розділ І. Загальні положення</Heading>

				<p>1. Web-сайт https://h-profit.com (далі – Сайт) належить на праві власності {AppStore.getContactData("company")} (далі – Компанія) . Всі об'єкти права інтелектуальної власності, які містяться на сайті, окрім випадків, коли про це окремо зазначено, належать на праві власності Компанії.

				</p><p>2. Дана Політика конфіденційності та захисту персональних даних (далі – Політика конфіденційності) є чинною виключно в рамках Правил користування Сайтом та не поширюється на web – сайти третіх осіб.

				</p><p>3. Дана Політика Політики конфіденційності є комплексом правових, адміністративних , організаційних, технічних та інших заходів, які вживає Компанія з метою забезпечення конфіденційності та захисту персональних даних фізичних осіб - споживачів, які є користувачами Сайту, окрім випадків, коли така інформація особисто та свідомо розголошується споживачем – користувачем Сайту.

				</p><p>4. Метою впровадження Політики конфіденційності та захисту персональних даних є недопущення публічного доступу та розголошення інформації, володільцем якої є споживач.

				</p><p>5. Компанія вживає всіх можливих заходів з метою забезпечення виконання заходів, передбачених даним розділом Політики конфіденційності.
				</p>
				<Heading subtitle renderAs="h2">Розділ ІІ. Доступ до персональних даних</Heading>

				<p>1. З метою реалізації функцій та завдань Сайту та інших цілей, пов'язаних з цивільно- правовими відносинами між Компанією та споживачем, споживач, користуючись даним Сайтом та послугами, для надання яких створений Сайт, надає Компанії дозвіл на збирання, накопичення, обробку, зберігання та використання (в тому числі з використанням автоматизованих засобів) персональних даних, володільцем яких він являється.

				</p><p>1.1. З метою реалізації функцій та завдань Сайту та інших цілей, пов'язаних з цивільно- правовими відносинами між Компанією та споживачем, споживач, користуючись даним Сайтом та послугами, для надання яких створений Сайт, надає Компанії дозвіл на передачу власних персональних даних третім особам.

				</p><p>2. Згода споживача, яка надається Компанії на збирання, обробку, зберігання та використання (в тому числі з використанням автоматизованих засобів) персональних даних та їх передачу третім особам оформлюється шляхом проставлення споживачем відмітки про надання дозволу на збирання, накопичення, обробку, зберігання та використання своїх персональних даних.

				</p><p>3. До персональних даних, на збирання, накопичення, обробку, зберігання та використання яких (в тому числі з використанням автоматизованих засобів) споживач надає дозвіл Компанії входить:

				</p>
				<p>а) прізвище, ім'я, по-батькові;</p>

				<p>б) номери засобів зв'язку (телефон, e-mail);</p>

				<p>в) сфера діяльності;</p>

				<p>г) Інша інформація, яка самостійно вноситься споживачем.</p>

				<Heading subtitle renderAs="h2">ІІІ. Строк та місце зберігання Персональних даних</Heading>

				<p>1. Персональні дані, на збирання, накопичення, обробку, зберігання та використання яких споживач надає доступ Компанії зберігаються безстроково, якщо інше не встановлено законодавством України або волевиявленням споживача.
				</p>
				<Heading subtitle renderAs="h2">IV. Права споживача</Heading>

				<p>1. Споживач, відносно власних персональних даних, які надаються для збирання, накопичення, обробку, зберігання та використання Компанії має право:
				</p>
				<p>1.1. Знати про джерела збирання, місцезнаходження своїх персональних даних, мету їх обробки, місцезнаходження Компанії;
				</p>
				<p>1.2. Отримувати інформацію про умови надання доступу до персональних даних;</p>

				<p>1.3. На доступ до своїх персональних даних;</p>

				<p>1.4. Отримувати інформацію стосовного того, чи обробляються його персональні дані;</p>

				<p>1.5. пред'являти вмотивовану вимогу Компанії із запереченням проти обробки своїх персональних даних;</p>

				<p>1.6. пред'являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних, якщо ці дані обробляються незаконно чи є недостовірними;</p>

				<p>1.7. на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;</p>

				<p>1.8. звертатися із скаргами на обробку своїх персональних даних до Уповноваженого Верховної Ради України з прав людини або до суду;</p>

				<p>1.9. застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;</p>

				<p>1.10. вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання згоди;</p>

				<p>1.11. відкликати згоду на обробку персональних даних;</p>

				<p>1.12. знати механізм автоматичної обробки персональних даних;</p>

				<p>1.13. на захист від автоматизованого рішення, яке має для нього правові наслідки.</p>

				<Heading subtitle renderAs="h2">V. Розкриття конфіденційної інформації та Персональних даних</Heading>

				<p>1. Компанія звільняється від обов'язку дотримання Політики конфіденційності стосовно Персональних даних споживача у випадку, якщо споживач самостійно публічно розголошує персональні дані.</p>

				<p>2. Компанія має право поширювати персональні дані споживача з відповідного письмового дозволу споживача.</p>

				<Heading subtitle renderAs="h2">VI. Знищення та видалення персональних даних</Heading>

				<p>1. Персональні дані, на збирання, накопичення, обробку, зберігання та використання яких споживач надає доступ Компанії підлягають видаленню або знищенню у разі:</p>

				<p>- припинення правовідносин між споживачем та Компанією;</p>

				<p>- видання відповідного припису Уповноваженого Верховної ради з прав людини або визначених ним посадових осіб секретаріату Уповноваженого Верховної ради з прав людини;</p>

				<p>- набрання законної сили рішенням суду щодо видалення або знищення персональних даних.</p>


			</Content>
			</Box>
			<FooterBlock />
			</Section>

			)}

}

export default Policy;
