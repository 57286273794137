import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bulma-components/lib/components/modal';

import AppStore from 'app-store';

import { isMobileOnly, isIOS } from "react-device-detect";
import UserStore from "../../user/user-store";

// import {  useHistory } from "react-router-dom";


// Declaration of the component as React Class Component
class OpenModal extends Component {
  static propTypes = {
    modal: PropTypes.object,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    modal: {},
  }

  componentWillUnmount() {
      AppStore.set({openModal: false});
      UserStore.postMessage("show-barcode");
      document.documentElement.style.overflowY = '';
  }

  componentDidMount() {
      AppStore.set({openModal: true});
      UserStore.postMessage("hide-barcode");
      document.documentElement.style.overflowY = 'hidden';
  }
  
  render () {
    const {      
      children,
      className,
      modal,
      close,
    } = this.props;

    const props = this.props;
    // let history = useHistory();

    let back = e => {
      window.history.back();
    }

    if (typeof(close) == "function") {
      back = close;
    }
    
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { onClose: back }, {...props})
    );

    // let back = e => {
    //     e.stopPropagation();
    //     history.goBack();
    // };

    let _className = isMobileOnly ? "mobile" : "";

    if (isMobileOnly && isIOS) { 
      // _className += " ios-app";
    }

    if (className) {
      _className += " "+className;
    }
    
    return (
      <Modal closeOnEsc={false} show={true} onClose={back} {...modal} className={_className}>
        <Modal.Content>
          {childrenWithProps}
        </Modal.Content>
      </Modal>
    );
  }
}

export default OpenModal;
