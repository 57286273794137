/* eslint-disable prefer-promise-reject-errors,no-shadow,no-param-reassign */

import underscoreToUppercase from "./underscore-to-uppercase";
import hyphenToUppercase from "./hyphen-to-uppercase";

function parseToken(token) {
    if (typeof (token) !== "string") {
        return null;
    }

    const items = token.split(":");
    if (items.shift() !== "1") {
        return null;
    }

    const data = {};
    data.ctime = new Date(parseInt(items.shift(), 10) * 1000);
    data.etime = new Date((parseInt(items.shift(), 10) * 1000) + Number(data.ctime));

    if (!items.shift()) {
        return null; // skip IV
    }

    if (!items.pop()) {
        return null; // remove trailing HMAC
    }

    let item;
    let k;

    while (items.length) {
        item = items.shift();
        k = item.indexOf("=");
        if (k > 0) { // non empty name
            data[underscoreToUppercase(hyphenToUppercase(item.substr(0, k)))] = item.substr(k + 1);
        }
    }

    if (data.uid) {
        data.id = data.uid;
    }

    //we don't realy need it, because we will get it from profile as "login"
    delete data.username;
    return data;
}

export default parseToken;
