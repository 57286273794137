import React, {useEffect, useState} from "react";
import {Box, Button, Heading, List} from "react-bulma-components";
import Bottombar, { keyMapNavbar } from "../../../templates/mobile/bottombar";
import UserStore from "../../../user/user-store";
import {
	Checkbox,
	Control,
	Field, Select
} from "react-bulma-components/lib/components/form";
import T from "../../../components/i18n";
import ErrorMessageControlHook
	from "../../../components/error-message-block/error-message-control.hook";
import SubmitButton from "../../../components/submit-button";
import UserActions from "../../../user/user-actions";
import {errorHandler} from "../../../tools/error-handler";

const availablePositions = [1, 2, 3, 4];

const getDefaultValues = () => {
	const defaultListNavbar = {
		sales: {
			name: "sales",
			checked: true,
			position: 2,
		},
		transactions: {
			name: 'transactions',
			checked: true,
			position: 3,
		},
		products: {
			name: 'products',
			checked: true,
			position: 4,
		},
	}

	if (UserStore.isCashier()) {
		defaultListNavbar.clients = {
			name: 'clients',
			checked: true,
			position: 1
		}
	} else {
		defaultListNavbar.dashboard = {
			name: 'dashboard',
			checked: true,
			position: 1
		}
	}

	return defaultListNavbar;
}

const generateData = (data) => {
	return data.reduce((acc, cur, index) => {
		acc[cur] = {
			name: cur,
			checked: true,
			position: index + 1
		}

		return acc
	}, {})
}

const CustomizeBottomMenu = ({onClose}) => {
	const {handleAutoRemoveError,errorMessage,setErrorMessage,ErrorMessageBlock} = ErrorMessageControlHook()

	const defaultListNavbar = getDefaultValues()

	const [waiting, setWaiting] = useState(false)
	const [activeList, setActiveList] = useState(defaultListNavbar)

  const generateList = Object.keys(keyMapNavbar);

	const filterWithPermission = generateList.filter(item => {
		if(item === 'chats') {
			return UserStore.getModuleEnable(item) && UserStore.isIntegrationChats()
		}

		return UserStore.getModuleEnable(item)
	})


	const handleChangeCheckbox = ({target: {checked, name}}) => {
		const checkActiveList = Object.values(activeList).filter(item => !!item.checked)
		if(checkActiveList.length === 4 && checked) {
			setActiveList(prev => ({ ...prev, [name]: {name, position: '', checked: false} }))
			handleAutoRemoveError('cant-add-fifth-element')
		} else {
			const activePosition = checkActiveList.map((item) => item.position)
			const freePositions = availablePositions.filter(num => !activePosition.includes(num));
			setActiveList(prev => ({ ...prev, [name]: {name, position: freePositions[0] || '', checked} }))
		}
	}

	const handleChangePosition = ({target: {value}}, name) => {
		const oldValue = activeList[name].position
		const checkActiveList = Object.values(activeList)
		const find = checkActiveList.find((item) => parseFloat(item.position) === parseFloat(value) && !!item.checked)
		if(find) {
			setActiveList(prev => ({ ...prev, [find.name]: {...prev[find.name], position: parseFloat(oldValue)} }))
		}
		setActiveList(prev => ({ ...prev, [name]: {...prev[name], position: parseFloat(value)} }))
	}

	const navbarList = Object.values(activeList).filter(item => !!item.checked).sort((a, b) => a.position - b.position).map(item => item.name)

	const handleSubmit = () => {
		const list = Object.values(activeList).filter(item => !!item.checked)
		list.sort((a, b) => a.position - b.position)

		if(!list.length) {
			handleAutoRemoveError('add-least-one-element')
		} else {
			setWaiting(true)
			const navbarList = list.map(item => item.name)
			UserStore.setMoreSetting(navbarList, 'navbar')
		}
	}

	const handleReset = () => {
		setActiveList(getDefaultValues())
		setWaiting(true)
		UserStore.setMoreSetting([], 'navbar')
	}

	useEffect(() => {
		const updateIntegrationCompleted = UserActions.updateSettings.completed.listen(() => {
			onClose()
		})

		const updateIntegrationFailed = UserActions.updateSettings.failed.listen(res => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		const getListNavbar = UserStore.getMoreSetting('navbar')

		if(getListNavbar && getListNavbar.length) {
			setActiveList(generateData(getListNavbar))
		}

		return () => {
			updateIntegrationCompleted()
			updateIntegrationFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
			<Box>
				<Heading size={3}>
					{T('customizing-bottom-menu')}
				</Heading>
					<List style={{overflowY: 'auto', maxHeight: '50vh'}}>
						{filterWithPermission.map((item) => (
							<List.Item key={item} className='display-flex-row-gap' style={{alignItems: 'center'}}>
								<Field style={{margin: 0}} className='display-flex-center'>
									<Control>
										<Checkbox name={item} checked={activeList[item]?.checked || false} onChange={handleChangeCheckbox}>{T(item)}</Checkbox>
									</Control>
								</Field>
								{activeList[item]?.checked && <div style={{maxWidth: 200}}>
									<Select value={activeList[item]?.position} onChange={(evt) => handleChangePosition(evt, item)}>
										<option value={1}>1</option>
										<option value={2}>2</option>
										<option value={3}>3</option>
										<option value={4}>4</option>
									</Select>
								</div>}
							</List.Item>
						))}
					</List>
				<Bottombar preView={true} previewList={navbarList} />
				<div className='display-flex-row margin-top-10 flex-wrap' style={{justifyContent: 'flex-end'}}>
					<div style={{flex: 1}}>
							<SubmitButton text='reset-to-default-columns' submit={handleReset}
														waiting={waiting} fullwidth={false} color='warning'
														size='small'/>
					</div>
					<SubmitButton text='save-btn-label' submit={handleSubmit}
												waiting={waiting} fullwidth={false} color='success'
												size='large'/>
					<Button size='large' rounded onClick={onClose}>{T('close')}</Button>
				</div>
			</Box>
		</>
  );
};

export default CustomizeBottomMenu;
