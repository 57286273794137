import React from 'react'
import s from './styles.module.scss'
import T from '../../../components/i18n'
import {calculateBrightness} from "../../../components/tag/utils";
import AppStore from "../../../app-store";
import ChangeChatStatusModal from "../modal/change-chat-status-modal";
import ChatsStore from "../chats-store";

const StatusButton = ({name, chatId}) => {

	const statusInfo = ChatsStore.getChatsStatusById(name)

	const handleChangeStatus = (evt) => {
		evt.stopPropagation();
		AppStore.openModal(<ChangeChatStatusModal modal='without-scroll' chatId={chatId} status={name}/>)
	}

	const statusName = ChatsStore.getChatsStatusName(name)

	let textColor = 'white'

	if(statusInfo?.color) {
		const brightness = calculateBrightness(statusInfo?.color || '#36bcff');

		if (brightness >= 128) {
			textColor = 'black';
		} else {
			textColor = 'white';
		}
	}

	return (
		<div className={s.status} style={{backgroundColor: statusInfo?.color || '#36bcff', color: textColor}} onClick={handleChangeStatus}>
			{statusName ? statusName : T('created')}
		</div>
	);
};

export default StatusButton;