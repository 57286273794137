import React from "react";
import Columns from "react-bulma-components/lib/components/columns";
import T from "../../../../components/i18n";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import ExpensesStore from "../../../expenses/expenses-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bulma-components";
import { LocalIcon } from "whitelables/wl-name/icons";
import User from "../../../../user/user-store";

const AccountSingleMobile = ({
  data,
  onDeleteAccount,
  onEditAccount,
  onOpenStatistics,
}) => {
  return (
    <Columns className="is-mobile">
      <Columns.Column size="four-fifths" align="left">
        <b>{T(data["name"])}:</b>{" "}
        <Money amount={data["balance"]} aid={data["id"]} wrapper={DOM.span} />
      </Columns.Column>
      {!ExpensesStore.isSystemAccount(data["id"]) && (
        <Columns.Column align="right" className="display-flex-row">
          <Button
            className="button-link-without-style"
            onClick={() => onOpenStatistics(data)}
          >
            <LocalIcon icon="statistics" size="small" />
          </Button>
          {User.getPermissionEnable("edit-balance") && <>
            <Button
              className="button-link-without-style"
              onClick={() => onEditAccount(data)}
            >
              <FontAwesomeIcon icon="edit" />
            </Button>
            {data.types !== 1 && <Button
              className="button-link-without-style"
              onClick={() => onDeleteAccount(data.id)}
            >
              <FontAwesomeIcon icon="trash-alt"/>
            </Button>}
          </>}
        </Columns.Column>
      )}
    </Columns>
  );
};

export default AccountSingleMobile;
