import React from "react";
import Money from "../../../components/money";
import UserStore from "../../../user/user-store";
import DOM from "react-dom-factories";

const EmployeesSubrowDesktop = ({ name, data, accrual, accrualType }) => {
  return (
    <tr className="selected-border silver">
      <td />
      <td className="button_link">
        {name}
        {accrual && UserStore.getPermissionEnable("incomeinformation") && accrualType === 'fix' ? <>(<Money amount={accrual} wrapper={DOM.span}/>)</> : `(${accrual}%)`}</td>
      <td>
        <Money amount={data.items_amount} />
      </td>
      {UserStore.getPermissionEnable("incomeinformation") && <td>
        <Money amount={data.items_profit}/>
      </td>}
      <td>{data.sales}</td>
      <td>{data.units}</td>
      <td />
      <td>
        <Money amount={data.amount} />
      </td>
    </tr>
  );
};

export default EmployeesSubrowDesktop;
