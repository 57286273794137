import React, {useEffect, useRef, useState} from 'react';
import T from '../i18n'
import s from './styles.module.scss'

const InfoBox = ({info, rectIcon}) => {
	const ref = useRef(null);
	const [position, setPosition] = useState({ top: 0, left: 0 });

	useEffect(() => {
		if (ref.current) {
			const updatePosition = () => {
				const rect = ref.current.getBoundingClientRect();

				const windowWidth = window.innerWidth;
				const spaceRight = windowWidth - rectIcon.right;
				const spaceLeft = rectIcon.left;

				let left;
				if (spaceRight >= rect.width) {
					left = rectIcon.right;
				} else if (spaceLeft >= rect.width) {
					left = rectIcon.left - rect.width;
				} else {
					left = spaceLeft >= spaceRight ? rectIcon.left - rect.width : rectIcon.right;
					left = Math.max(0, Math.min(left, windowWidth - rect.width));
				}

				setPosition({
					top: rectIcon.top - rect.height,
					left: left,
				});
			};

			updatePosition();
			window.addEventListener('resize', updatePosition);
			return () => window.removeEventListener('resize', updatePosition);
		}
	}, [rectIcon]);

	return (
		<div className={s.info} ref={ref} style={{ top: position.top, left: position.left }}>
			{T(info)}
		</div>
	);
};

export default InfoBox;