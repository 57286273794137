import Reflux from 'reflux';

const StartActions = Reflux.createActions({

    load: { asyncResult: true },
    loadMarketplaces: { asyncResult: true },
    addMarketplace: { asyncResult: true },
  
});


export default StartActions;