import React, {useState} from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import T from '../../../components/i18n'
import RadioButtonListControl
	from "../../../components/radio-buttons-list/radio-button-list-control";
import UserStore from "../../../user/user-store";
import NumberInput from "../../../components/number-input/number-input";
import ErrorMessageControlHook
	from "../../../components/error-message-block/error-message-control.hook";

const list = [
	{
		value: 'percentage',
		title: 'mass-edit-price-type-1'
	},
	{
		value: 'fixed',
		title: 'mass-edit-price-type-2'
	}
]

const ChangeMassPriceModal = ({onClose, onChange, heading, formula}) => {
	const {handleAutoRemoveError,ErrorMessageBlock,setErrorMessage,errorMessage} = ErrorMessageControlHook()
	const [type, setType] = useState('percentage')
	const [inputValue, setInputValue] = useState('')

	const placeholder = type === 'percentage' ? `(${inputValue || 'Х'} * (${formula})) / 100` : `${inputValue || 'X'} ${UserStore.getCurrency()}`

	const handleChangeType = ({value}) => {
		setType(value)
	}

	const handleChangeInput = ({target: {value}}) => {
		setInputValue(value)
	}

	const handleSubmit = () => {
		if (!parseFloat(inputValue)) {
			handleAutoRemoveError(T('not all fields required'));
			return;
		}

		const numericValue = Number(inputValue);

		if (isNaN(numericValue) || !isFinite(numericValue)) {
			handleAutoRemoveError(T('not all fields required'));
			return;
		}

		onChange && onChange({type, value: inputValue});
		onClose()
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
			<Box>
				<Heading>{T(heading)}</Heading>
				<Heading subtitle>{`${T("formula")}: ${formula} + ${placeholder}`}</Heading>
				<RadioButtonListControl label='mass-edit-price-type-label' list={list} name='type' onChange={handleChangeType} valueRadio={type}/>
				<NumberInput onChange={handleChangeInput} value={inputValue}/>
				<div className='display-flex-row margin-top-10' style={{justifyContent: 'flex-end'}}>
					<Button onClick={handleSubmit} color='success'>{T('change')}</Button>
					<Button onClick={onClose}>{T('close')}</Button>
				</div>
			</Box>
		</>
	);
};

export default ChangeMassPriceModal;