import React, {useEffect, useState} from 'react'
import {
	Control,
	Field, Input,
	Label
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import SelectComponent from "../../../../../tools/select-component";
import User from "../../../../../user/user-store";
import Button from "react-bulma-components/lib/components/button";

const AddSender = ({valueInput, onChangeInput, valueSelect, onChangeSelect, additionalSender}) => {
	const [isAddSender, setIsAddSender] = useState(false)

	let senderList = User.getTurboSMSlist()

	if(additionalSender) {
		senderList = [{id: additionalSender, name: additionalSender}, ...senderList]
	}

	useEffect(() => {
		onChangeInput({target: {name: 'sender_text_field', value: ''}})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAddSender]);

	return (
		<>
			{isAddSender ? (
					<Field>
						<Label align="left">{T('sender')}</Label>
						<Control>
							<Input size="large"
										 type="text"
										 name="sender_text_field"
										 placeholder={T('add-sender')}
										 onChange={onChangeInput}
										 value={valueInput}/>
						</Control>
					</Field>
				) : (
					<Field>
						<SelectComponent
							name="sender"
							label="sender"
							onChange={onChangeSelect}
							value={valueSelect}
							list={() => senderList}
							creatable={false}/>
					</Field>)}
			<div align="right" className="margin-bottom-10">
				<Button
					size="small"
					color="success"
					className='is-rounded button'
					onClick={()=>setIsAddSender(prevState => !prevState)}>
					{isAddSender ? T('back-to-standard') : T('add-sender')}
				</Button>
			</div>
		</>)
};

export default AddSender;