import React from 'react'
import {Tabs} from "react-bulma-components";
import T from "../i18n";

const TabItem = ({tab, label, count, isActive, onChange}) => {
	return (
		<Tabs.Tab key={tab} active={isActive === tab} onClick={() => onChange && onChange(tab)}>
			{T(label)} {!!count && <span>({count})</span>}
		</Tabs.Tab>
	)
};

export default TabItem;