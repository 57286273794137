import React from 'react'
import List from "react-bulma-components/lib/components/list";
import CatalogListItem from "./catalog-list-item";
import T from "../../../../components/i18n";

const CatalogSingleList = ({list, additionalSettings, ...rest}) => {


	return !!list?.length ? (
		<List>
			{list?.map(item => <CatalogListItem key={item.id}{...item} additionalSettings={additionalSettings} {...rest}/>)}
		</List>
	) : (
		<List>
			<List.Item align="center" className='margin-bottom-15'>
				{T('no-elements-in-list')}
			</List.Item>
		</List>
	);
};

export default CatalogSingleList;