import React, {useEffect, useState} from 'react'
import SalesStore from "../../modules/sales/sales-store";
import T from '../i18n'
import {calculateBrightness} from "../tag/utils";

const ChannelBlock = ({channel_id, onClick}) => {
	const channelClassName = SalesStore.getChannelsClass(channel_id)
	const channelName = SalesStore.getChannelsName(channel_id)
	const channelColor = SalesStore.getChannelColor(channel_id)

	const [textColor, setTextColor] = useState('white');

	useEffect(() => {
		if(channelColor) {
			const brightness = calculateBrightness(channelColor);

			if (brightness >= 128) {
				setTextColor('black');
			} else {
				setTextColor('white');
			}
		}
	}, [channelColor]);

	return (
		<span
			onClick={onClick ? onClick : () => {}}
			className={channelColor ? 'channel_block' : channelClassName}
			style={channelColor ? {background: channelColor, color: textColor} : {}}
		>
			{T(channelName)}
		</span>
	);
};

export default ChannelBlock;