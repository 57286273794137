import React from "react";
import Level from "react-bulma-components/lib/components/level";
import {Link} from "react-router-dom";
import T from "../../../components/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {confirmAlert} from "react-confirm-alert";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const DeleteButton = ({onClick}) => {
	const {isMobileSize} = useIsMobile()
	const handleClick = () => {
		confirmAlert({
			title: T('confirm-delete'),
			message: '',
			buttons: [
				{
					label: T('delete'),
					onClick: onClick
				},
				{
					label: T('no-delete'),
					onClick: () => {}
				}
			]
		});
	}

	return (
		<Level.Item key="delBut">
			<Link to="#" onClick={handleClick} title={T('delete')}>
				<FontAwesomeIcon icon="trash-alt" size={isMobileSize ? '2x' : '1x'} />
			</Link>
		</Level.Item>
	);
};

export default DeleteButton;