export const formattedDate = (date) => {
	const originalDate = new Date(date);

	if (isNaN(originalDate.getTime())) {
		return ''
	}
	const day = originalDate.getDate().toString().padStart(2, '0');
	const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Месяцы в JavaScript начинаются с 0
	const year = originalDate.getFullYear().toString().slice(-2);

	return `${day}.${month}.${year}`;
}


