import React from "react";
import T from "../../../../components/i18n";
import formatDate from "../../../../tools/format-date";
import ProductsStore from "../../../products/products-store";
import List from "react-bulma-components/lib/components/list";

const NotSaledOrder = ({ data }) => {
  return (
    <List.Item style={{ textAlign: "left" }}>
      <b>
        {T("purchase")}: #{data["order_number"]}
      </b>
      <br />
      {data.items.map((item) => {
        const product = ProductsStore.getProduct(item["product_id"]);
        return (
          <span key={item.product_id}>
            {product["name"]}
            <br />
          </span>
        );
      })}
      <div style={{ textAlign: "right" }}>
        <small>{formatDate(data["purchase_date"])}</small>
      </div>
    </List.Item>
  );
};

export default NotSaledOrder;
