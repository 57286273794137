import React from "react";
import { Box, Button } from "react-bulma-components";
import s from "./styles.module.scss";
import AppStore from "../../../../app-store";
import AddMinBalance from "./modal/add-min-balance/add-min-balance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmDelete } from "../../../../tools/confirm-delete";
import UserStore from "../../../../user/user-store";

const RowMobile = ({ data }) => {
  return (
    <tr>
      <td>
        <Box>
          <div className={s.wrapper}>
            <div className={s.text}>
              {data?.name.map((item) => (
                <b key={item}>{item}</b>
              ))}
            </div>
            <div className={s.number}>{data.minStock}</div>
          </div>
          <div className={s.action}>
            <Button
              className="button-link-without-style"
              onClick={() =>
                AppStore.openModal(
                  <AddMinBalance
                    modal="min-balance"
                    defaultType={data?.type}
                    defaultMinStock={data?.minStock}
                    defaultSelectValue={data.selectList}
                    index={data?.index}
                  />
                )
              }
            >
              <FontAwesomeIcon icon="edit" size="2x" />
            </Button>
            <Button
              className="button-link-without-style"
              onClick={() =>
                confirmDelete(() =>
                  UserStore.deleteMinStockProduct({
                    type: data.type,
                    index: data.index,
                  })
                )
              }
            >
              <FontAwesomeIcon icon="trash-alt" size="2x" />
            </Button>
          </div>
        </Box>
      </td>
    </tr>
  );
};

export default RowMobile;
