import React from 'react'
import Button from "react-bulma-components/lib/components/button";
import T from '../../../components/i18n'
import SubmitButton from "../../../components/submit-button";

const ActionsModal = ({isEdit,waitingSubmit, onSubmit, onClose}) => {
	return (
		<Button.Group position='right'>
			<Button size="large"
							rounded
							color="light"
							onClick={onClose}
			>
				{T('close')}
			</Button>
			<SubmitButton
				size="large"
				rounded
				fullwidth={false}
				color="success"
				submit={onSubmit}
				waiting={waitingSubmit}
				text={isEdit ? T('btn-edit') : T('tag-add-btn')}
			/>
		</Button.Group>
	);
};

export default ActionsModal;