import React from "react";
import Level from "react-bulma-components/lib/components/level";
import Button from "react-bulma-components/lib/components/button";
import T from "../../../components/i18n";

const GroupDaysFilter = ({ group, onChange }) => {
  return (
    <Level mobile>
      <Level.Side />
      <Level.Side align="right">
        <Level.Item>{T("grouping")}:</Level.Item>
        {["days", "weeks", "months", "years"].map((item) => (
          <Level.Item key={item}>
            <Button
              size="small"
              color={item === group ? "success" : "warning"}
              onClick={() => onChange(item)}
            >
              {T(item)}
            </Button>
          </Level.Item>
        ))}
      </Level.Side>
    </Level>
  );
};

export default GroupDaysFilter;
