import React, { useEffect, useRef, useState} from "react";
import ImageGallery from 'react-image-gallery';
import Actions from 'modules/products/products-actions';
import ProductsStore from 'modules/products/products-store';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Messages from 'components/messages';
import "react-image-gallery/styles/scss/image-gallery.scss";
import {confirmDelete} from "../../tools/confirm-delete";
import { LocalIcon } from 'whitelables/wl-name/icons'
import {Box, Button} from "react-bulma-components";
import ProductsActions from "modules/products/products-actions";
import {createMobileAppLink} from "../../tools/create-mobile-app-link";
import T from '../i18n'
import UserStore from "../../user/user-store";

const ProductImages = (props) => {
  const ref = useRef(null);
  const [images, setImages] = useState([])
  const [message, setMessage] = useState(null)
  const [update, setUpdate] = useState(0)
  const [disabledRotate, setDisabledRotate] = useState(false)

  const handleDelete = () => {
    Actions.deleteImage(props.pid, ref?.current?.getCurrentIndex())
  }

  const handleDownloadURl = () => {
    return ProductsStore.makeImageUrl(props.pid, ref?.current?.getCurrentIndex(), 0);
  }

  const makeImageUrl = (pid, i, size) => {
    return ProductsStore.makeImageUrl(pid, i, size);
  }

  const createImagesList = () => {
    const {pid} = props

    const img_list = ProductsStore.getImagesList(pid);

    return  img_list.map((_, i) => ({
      original: makeImageUrl(pid, i, 0),
      thumbnail: makeImageUrl(pid, i, 64),
    }))
  }

  const updateImageComponent = (index) => {
    if(ref.current) {
      setUpdate(prev => prev + 1);
      ref.current.slideToIndex(parseInt(index));
    }
  };

  useEffect(() => {
    const firstInit = createImagesList()
    setImages(firstInit)

    const listen = Actions.getImagesList.completed.listen((res) => {
      const updateImage = createImagesList()
      setImages(updateImage)
      setUpdate(prev => prev + 1)
    });

    const listenDelete = Actions.deleteImage.completed.listen((data)=> {
      if (data['meesage']) {
        setMessage(data['meesage'])
      }
    });

    let listenUpdateCash = null
    const listenRotate = Actions.controlImage.completed.listen((data)=> {
      listenUpdateCash = Actions.updateCashRotate.failed.listen((res) => {
        if (data && res === 64) {
          updateImageComponent(data.i)
          setDisabledRotate(false)
        }
      })
    })

    return () => {
      listen()
      listenDelete()
      listenRotate()
      if(listenUpdateCash) {
        listenUpdateCash()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const handleRotate = () => {
      ProductsActions.controlImage({
        type: 'rotate',
        pid: props.pid,
        i: ref?.current?.getCurrentIndex(),
        rotate: 270
      })
      setDisabledRotate(true)
    }
    const handleThumbnailImage = () => {
      if(ref?.current?.getCurrentIndex() !== 0) {
        ProductsActions.controlImage({
          type: 'make_first',
          pid: props.pid,
          i: ref?.current?.getCurrentIndex(),
        })
      }
    }
  if (images.length === 0) {
    if(!UserStore.getPermissionEnable("editproducts") && !ProductsStore.loadingImagesList) {
      return <Box>{T('not-have-history')}</Box>
    }
    return "";
  }

  return (
    <div>
      <Messages message={message} close={() => setMessage(null)} />
      <ImageGallery
        key={update}
        ref={ref}
        items={images}
        thumbnailPosition="left"
        bulletClass='test'
        renderCustomControls={ () => (
          <div className='button-actions-image image-gallery-icon'>
            <div style={{flex: 1}} className='display-flex-row'>
              <a title={T('download-title')} onClick={() => createMobileAppLink(handleDownloadURl())} href={handleDownloadURl()} download
                 className='button button-link-icon white'>
                <LocalIcon icon="download-file" size="large"/>
              </a>
              {UserStore.getPermissionEnable("editproducts") && <>
                <Button
                  title={T('rotate')}
                  disabled={disabledRotate}
                  className='button-link-icon white'
                  onClick={handleRotate}
                >
                  <LocalIcon icon="rotate" size="large"/>
                </Button>
                {ref?.current?.getCurrentIndex() !== 0 && <Button
                  title={T('set-the-main-image')}
                  disabled={disabledRotate}
                  className='button-link-icon white'
                  onClick={handleThumbnailImage}
                >
                  <LocalIcon icon="thumbnail" size="large"/>
                </Button>}
              </>}
            </div>

            {UserStore.getPermissionEnable("editproducts") && <Button
              className='button-link-icon white'
              onClick={(data) => confirmDelete(handleDelete, 'are-you-sure-to-delete-this-images')}
            >
              <FontAwesomeIcon icon="trash-alt" size="2x"/>
            </Button>}
          </div>
          )}

        showPlayButton={false}
        infinite={false}
      />
    </div>
  );
};

export default ProductImages