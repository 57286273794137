  import React from 'react';


  const Header = () => {
        return (
    		<center className="margin-top-60">
    			<img src="/img/hp-black.png" className="margin-top-20" alt="Huge Profit" width="130" />
    		</center>
        );
   }

  export default Header;