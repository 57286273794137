import React, { useEffect, useState } from "react";
import {
  Field,
  Label,
  Radio,
} from "react-bulma-components/lib/components/form";
import T from "../i18n";

const RadioButtonsList = ({
  list,
  name,
  label,
  defaultValue,
  disabledRadio,
  onChange,
}) => {
  const [valueState, setValueState] = useState(defaultValue || list[0].value);

  const handleChange = (evt) => {
    const target = evt.target;
    const value = target.value;

    setValueState(value);
  };

  useEffect(() => {
    if (onChange) onChange({ name, value: valueState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueState]);

  return (
    <Field>
      {label && <Label>{T(label)}</Label>}
      {list?.map(({ value, title, size, ...rest }) => (
        <Radio
          key={value}
          name={name}
          value={value}
          size={size || "large"}
          checked={value === valueState}
          onChange={handleChange}
          disabled={disabledRadio}
          {...rest}
        >
          {T(title)}
        </Radio>
      ))}
    </Field>
  );
};

export default RadioButtonsList;
