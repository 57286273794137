import yajax from "yajax";


let baseStore = {
    state: {
        _filter: {},
        sort_column: null,
        sort_way: 1,
        page: 0,
        count_page: 0,
        count_per_page: 50,
        isLoading: false,
        isLoadingPD: false,
    },

    loading: false,

    CLEAR_FILTER: {},

	get(field) {
        return this.state[field];
    },

    getFilter(field) {
        return this.state['_filter'][field];
    },

    setCountPerPage(count) {
        return this.setState({
            "count_per_page": count
        })
    },

    getInitialState() {
     //    console.log("clear filter, ", this.CLEAR_FILTER)
    	// this.state._filter = this.CLEAR_FILTER;

        return this.state;
    },


    clearFilter() {
        this.setState({
            _filter: this.CLEAR_FILTER
        })
    },




    onFilter(name, value) {
        let filters = {};

        if(name === 'instock') {
            window.localStorage.setItem("instock", value)
        }

        for (let i in this.state['_filter']) {
            filters[i] = this.state['_filter'][i];
        }

        // let val = value;
        // if (name === "search") {
        //     val = val.replaceAll('-', ' ');
        // }

        filters[name] = value;

        this.setState({
            _filter: filters,
            page: 0
        });
    }, 

    onSetPage(page) {
        this.setState({
            page: parseInt(page)
        });
    },   

    onSetCountPerPage(count) {
        this.setState({
            count_per_page: count
        });
    },

    getList(list) {
        const data = this.get(list || "rows");
        if (data === null) {
            this.listenables.load();
            this.setState({
                [list || 'rows']: []
            })
            return [];
        }
        return JSON.parse(JSON.stringify(data));
    },
 
    getPageList(needStats = false, mid = null, list) {
        const products = this.getList(list),
              start = this.state.page*this.state.count_per_page,
              end = start + this.state.count_per_page;
        let result = products.filter(this.applyFilter);

        if (typeof(this.filterStock) === "function") {
            result = this.filterStock(result, true);
        }


        let products_stats = {
            'cprod': 0,
            'quantity': 0,
            'total_cost': 0,
            'total_profit': 0
        }
        if (needStats && mid) {
            products_stats = this.calcProductsStats(result, mid)
        }

        let sortingResult = result
        if(typeof(this.applySort) === "function") {
            sortingResult = this.applySort(result)

        }


            this.state.count_page = Math.ceil(sortingResult.length / this.state.count_per_page);

        if (this.state.count_page < this.state.page) {
            this.setState({
                page: 0
            });
        }



        if (needStats) {
            return { result: sortingResult.slice(start, end), statistics: products_stats}
        } else {
            return sortingResult.slice(start, end)
        }

    },

    onLoad(force) {
        if (this.loading) return;

        this.loading = this._load_url;

        let url = this._load_url;

        if (force) {
            url += "?t="+Date.now();
        }

        yajax.get(url).then(this.listenables.load.completed, this.listenables.load.failed);
    },
    
    onLoadCompleted(data) {
        if (data['success']) {
            let s = {
               'rows': data['data'],
            }

            if ('reports' in data) {
                s['reports'] = data['reports'];
            }
            this.setState(s);
        }
        console.log("!!!! result load: ", this.loading)
        this.loading = false;

    },

    onLoadFailed(){
        console.log("!!!! failed loading", this.loading)
        this.loading = false;
    }
}

export default baseStore