import React from "react";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import User from "../../../../../user/user-store";
import SalesStore from "../../../../sales/sales-store";
import Money from "../../../../../components/money";
import InstokBlock from "./instok-block";

const ReportWithWarehouseRowDesktop = ({ data, pid }) => {
  const reports = SalesStore.getSaleReportsByProduct(pid, data["mid"]);
  const isDropShipping = WarehouseStore.isDropshipping(data["mid"]);
  return (
    <tr>
      <td>{WarehouseStore.getWarehouseName(data.mid)}</td>
      {User.getPermissionEnable("incomeinformation") && (
        <td>
          {data["netprice"] || 0} {User.getCurrency(data["currency_id"])}
        </td>
      )}
      {User.getPermissionEnable("salesprice") && <td>
        {data["price"] || 0} {User.getCurrency()}
      </td>}
      <td>
        {isDropShipping ? (
          "∞"
        ) : (
          <InstokBlock
            data={data}
            pid={pid}
            instock={data.instock}
            quantity={data.quantity}
            waiting={data.waiting}
            mid={data.mid}
          />
        )}
      </td>
      {User.getPermissionEnable("incomeinformation") && <td>{reports?.items_count}</td>}
      {User.getPermissionEnable("incomeinformation") && (
        <td>
          <Money amount={reports["sales_profit"]} />
        </td>
      )}
    </tr>
  );
};

export default ReportWithWarehouseRowDesktop;
