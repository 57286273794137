import React from "react";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import s from "./styles.module.scss";
import T from "../../../components/i18n";
import { LocalIcon } from "whitelables/wl-name/icons";
import AppStore from "../../../app-store";
import NotificationModal from "./notification-modal";
import { useHistory } from "react-router-dom";
import NotificationActions from "../notification-actions";
import {confirmDelete} from "../../../tools/confirm-delete";
import {parseJSON} from "../../../tools/error-handler";
import UserStore from "../../../user/user-store";

const NotificationItem = ({
  name,
  message,
  created_at,
  color,
  is_read,
  source,
  url,
  id
}) => {
  const history = useHistory();


  let messageData = T(message)

  const messageParse = parseJSON(message);

  if(typeof messageParse === 'object') {
    if(messageParse.type === 'sale') {
      messageData = <div style={{color: 'currentColor'}}>{T('paid-storage-coming-soon')}: {messageParse.id.map((item, index) => <React.Fragment key={item.id}>{item.on}{index < messageParse.id.length - 1 && ', '}</React.Fragment>)}</div>
    }
  }

  let classNameType = s.newsType;
  let iconType = "news";

  if (color === "danger") {
    classNameType = s.errorType;
    iconType = "error";
  } else if (color === "warning") {
    classNameType = s.warningType;
    iconType = "warning";
  } else if(color === "success") {
    classNameType = s.successType;
    iconType = "success-icon";
  }



  const handleClick = () => {
    if (url) {
      history.push(url);
    } else {
      AppStore.openModal(<NotificationModal id={id} name={name} message={message} type={color} source={source}/>);
    }
  };

  const handleDelete = (evt) => {
    evt.stopPropagation();
    confirmDelete(()=> NotificationActions.removeMessage(id))
  };

  const createdAt = new Date(created_at)
  const dateInTwoWeeks = new Date();
  dateInTwoWeeks.setDate(createdAt.getDate() + 14);

  const isOldNews = color === 'news' ? createdAt >= dateInTwoWeeks || is_read : is_read


  const sourceData =  source?.split("-");
  let sourceName = ''
  if (sourceData && sourceData[0] === "integration") {
    let integration = UserStore.getIntegrationNameById(sourceData[1]);
    if(integration['integration_id']) {
      sourceName = T('integration-'+integration['integration_id']);
    }
  }

  return (
    <Dropdown.Item value={T(name)}>
      <div
        className={`${s.item} ${isOldNews ? s.read : ""}`}
        onClick={handleClick}
      >
        {url && (
          <span className={`${s.actions} ${s.link} ${isOldNews ? s.read : ""}`}>
            <LocalIcon icon="line-arrow-right" />
          </span>
        )}
        {!url && color !== 'news' && (
          <span
            className={`${s.actions} ${s.close} ${isOldNews ? s.read : ""}`}
            onClick={handleDelete}
          >
            <LocalIcon icon="close-fill" />
          </span>
        )}
        <div className={s.info}>
          <h4 className={`${s.title}`}>
            <span className={isOldNews ? s.read : classNameType}>
              <LocalIcon icon={iconType} className={s.icon} />
            </span>
            {T(name)}
          </h4>
          <p className={s.message}>
            {messageData?.length > 50 && !sourceName
              ? `${messageData.slice(0, 50)}...`
              : messageData}
            {!!sourceName && sourceName}
          </p>
        </div>
        <div className={s.date}>{created_at}</div>
      </div>
    </Dropdown.Item>
  );
};

export default NotificationItem;
