import React from 'react'
import {
	Control,
	Field,
	Label
} from "react-bulma-components/lib/components/form";
import T from "../i18n";
import NumberInput from "../number-input/number-input";

const PriceFromToFilter = ({label, from, to, onChange}) => {
	return (
		<Field>
			{label && <Label align='left'>{T(label)}</Label>}
			<div className='price-from-to-filter'>
				<Control>
					<NumberInput
						size="medium"
						name={from?.name}
						type="number"
						placeholder={from?.placeholder || T('price-from')}
						autoComplete="nope"
						onChange={onChange}
						value={from?.value}
					/>
				</Control>
				<Control>
					<NumberInput
						size="medium"
						name={to?.name}
						type="number"
						autoComplete="nope"
						placeholder={to?.placeholder || T('price-to')}
						onChange={onChange}
						value={to.value}
					/>
				</Control>
			</div>
		</Field>
	);
};

export default PriceFromToFilter;