import React from 'react'
import Button from "react-bulma-components/lib/components/button";
import T from "../../../../components/i18n";

const ActionsModal = ({onSubmit,onClose}) => {
	return (
		<Button.Group position="right">
			<Button size="large"
							rounded
							color="light"
							onClick={onClose}
			>
				{T('close')}
			</Button>
			<Button size="large"
							rounded
							color="success"
							onClick={onSubmit}
			>
				{T('btn-save')}
			</Button>
		</Button.Group>
	);
};

export default ActionsModal;