import React, {useEffect} from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import T from '../i18n'
import UserStore from "../../user/user-store";
import AppStore from "../../app-store";
const WelcomeMessage = ({onClose}) => {
	const firstReg = UserStore.isFirstReg()

	useEffect(() => {
		UserStore.setMoreSetting(1, 'sm')
	}, []);

	return (
		<Box>
			<Heading>{firstReg ? T('thx-registration') : T('welcome-heading')}</Heading>
			<div style={{fontSize: 16}}>
				{T('welcome-message')}
			</div>
			<div className="margin-top-10" align="center">
				<a href={AppStore.getContactData("telegram_page")} target="_blank" rel="noopener noreferrer">
					<img src={'/img/Telegram_logo.svg'} style={{height: 50}} alt='telegram'/>
				</a>&nbsp;
				<a href={AppStore.getContactData("facebook")} target="_blank" rel="noopener noreferrer">
					<img src='/img/Facebook_F_icon.svg' style={{height: 50}} alt='facebook'/>
				</a>
			</div>
			<div align='right' className='margin-top-10'>
				<Button onClick={onClose}>
					{T('close')}
				</Button>
			</div>
		</Box>
	);
};

export default WelcomeMessage;