import React, {useState} from 'react'
import User from "../../../../user/user-store";
import ProductStore from "../../products-store";
import Image from "react-bulma-components/lib/components/image";
import UserStore from "../../../../user/user-store";
import SalesStore from "../../../sales/sales-store";
import LightActions from "../../light-actions";
import LightStore from "../../light-store";
import Card from "react-bulma-components/lib/components/card";
import Columns from "react-bulma-components/lib/components/columns";
import {
	Checkbox,
	Control,
	Field
} from "react-bulma-components/lib/components/form";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import EditProductsModal from "../../edit-products-modal";
import T from "../../../../components/i18n";
import {
	deleteProduct,
	getStockMobile,
} from "../../common/function";
import Button from "react-bulma-components/lib/components/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NewProductRowMobile from "./new-product-row-mobile";
import EditVariableModal from "../../modal/add-product/edit-variable-modal";
import TypeProductTag
	from "../../../../components/type-product-tag/type-product-tag";

const NewProductRowStockMobile = ({data, isVariable}) => {
	const [isShow, setIsShow] = useState(false);
	const handleToggleShow = () => {
		setIsShow((prev) => !prev);
	};

	let img = ProductStore.makeImageUrl(data.id, 0, 64);

	const img_o = <Image src={img} size={64} />;

	const sumInstock = Object.values(data.stock || {}).reduce(
		(acc, cur) => {
			acc.quantity += cur.quantity;
			acc.waiting += cur.waiting;
			acc.instock += cur.instock;

			const isMinMax = UserStore.isMinimalStock({mid: cur.marketplace_id, pid: cur.product_id, instock: cur.instock});

			if(isMinMax) {
				acc.isMinMaxStock = true
			}

			const salesReport = SalesStore.getSaleReportsByProduct(
				cur.product_id,
				cur.marketplace_id
			);
			acc.items_count += salesReport.items_count;

			return acc;
		},
		{ waiting: 0, instock: 0, quantity: 0, items_count: 0, isMinMaxStock: false }
	);

	const handleChangeGroupCheckbox = (evt) => {
		const checked = evt.target.checked;
		Object.values(data.stock).forEach((item) => {
			LightActions.setProductGroup(
				[item.product_id, item.marketplace_id],
				checked
			);
		});
	};
	const handleIsCheckedProduct = () => {
		return Object.values(data.stock).every((item) =>
			LightStore.getProductGroupCheckbox([item.product_id, item.marketplace_id])
		);
	};

	const isChecked = handleIsCheckedProduct();
	const expirationDate = ProductStore.theExpirationDateByPid(data.id)

	const name = ProductStore.getProductName(data.id)

	const handleDelete = () => {
		const warehouseKey = Object.values(data.stock).map(item => `${data.id}_${item.marketplace_id}`)
		deleteProduct(warehouseKey)
	}

	let classNameBorder = ''

	if(data.type_product === 4) {
		classNameBorder = 'services-border';
	} else if(data.type_product === 3) {
		classNameBorder = 'bundle-border'
	}

	return (
		<tr>
			<td
				colSpan="5"
				className={`${isShow ? "has-background-light-black padding-bottom-10" : ""}`}
			>
				<Card onClick={handleToggleShow} style={(isShow || isVariable) ? {marginBottom: 10} : {}} className={`${classNameBorder} ${expirationDate && sumInstock.instock !== 0 ? expirationDate : ''} ${isVariable ? "has-background-light-black" : ''}`}>
					<Card.Content className={isShow ? `${expirationDate && sumInstock.instock !== 0 ? expirationDate : 'has-background-light-black'}` : ""}>
						<Columns className="is-mobile product-mobile">
							<Columns.Column size="one-quarter">
								<div className="display-flex">
									<Field className="absolute">
										<Control>
											<Checkbox
												name="is_products"
												onChange={handleChangeGroupCheckbox}
												onClick={(evt) => evt.stopPropagation()}
												checked={isChecked}
											/>
										</Control>
									</Field>
									{!User.getPermissionEnable("editproducts") ? (
										<div>{img_o}</div>
									) : (
										<OpenInsideModal
											size="small"
											text=""
											img={img_o}
											renderAs="b"
										>
											<EditProductsModal pid={data.id} step={1} />
										</OpenInsideModal>
									)}
								</div>
							</Columns.Column>
							<Columns.Column className="margin-left-5">
								<b className="product-name">
									<b>
										<p className="product-name">
											<b><TypeProductTag typeProduct={data.type_product}/> {name} ({T('warehouse-count', {count: Object.keys(data.stock).length})})</b>
										</p>
									</b>
									{!isShow && (data.type_product === 4 ? (
										<span className="subtitle">
                      {T('available')}: {" "}
											<strong>
                        <span className='text-success'>
                          {"∞ "}
                        </span>
												{T("items-e")}
                      </strong>
                    </span>
									) : (
										<span className="subtitle">
                      {T("available")}:{" "}
											<strong>
                        {getStockMobile(
													sumInstock.instock,
													sumInstock.quantity,
													sumInstock.waiting,
													false,
													false,
													true,
													sumInstock.isMinMaxStock
												)}
												{T("items-e")}
                      </strong>
                    </span>
									))}
								</b>
							</Columns.Column>
						</Columns>
						{isShow && (
							<Button.Group position="right">
								{User.getPermissionEnable("editproducts") && (
									<OpenInsideModal size="small" text="edit-btn">
										{isVariable ? (
											<EditVariableModal pid={data.id} parent_id={data.parent_id}/>
										) : (
											<EditProductsModal pid={data.id} step={0} />
										)}
									</OpenInsideModal>
								)}
								{User.getPermissionEnable("deleteproducts") && (
									<Button
										size="small"
										rounded
										color="light"
										icon={<FontAwesomeIcon icon="trash-alt" size="1x" />}
										onClick={handleDelete}
									>
										{T("delete")}
									</Button>
								)}
							</Button.Group>
						)}
					</Card.Content>
				</Card>
				{isShow && (
					<table style={{width: '100%'}}>
						<tbody>
						{Object.values(data.stock).map((item) => {
							return (
								<NewProductRowMobile
									key={`${item.marketplace_id}_${item.product_id}`}
									data={data}
									stock={item}
									backgroundLightBlack
									isVariable={isVariable}
									disabledShowAndWrapper
								/>
							);
						})}
						</tbody>
					</table>
				)}
			</td>
		</tr>
	);
};

export default NewProductRowStockMobile;