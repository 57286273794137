import React from 'react'
import Level from "react-bulma-components/lib/components/level";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import ProcessDeliveryModal from "../../process-delivery-modal";

const DeliveryButton = ({accountID, prepaid, totalOrder, prepaidAccountID, deliveryID, orderID}) => {
	return (
		<Level.Item>
			<OpenInsideModal size="small" color="success" text='to-send'>
				<ProcessDeliveryModal
					modal='processdelivery'
					aid={accountID}
					prepaid={prepaid}
					total_order={totalOrder}
					prepaid_account_id={prepaidAccountID}
					clients_delivery_id={deliveryID}
					oid={orderID} />
			</OpenInsideModal>
		</Level.Item>
	);
};

export default DeliveryButton;