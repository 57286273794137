import React, { Component } from 'react';

import NovaPoshta from "./novaposhta";
import UkrPosta from "./ukrposhta";

import OpenModal from 'components/modal/modal';


class AddDeliveryModalLink extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let props;
        if (this.props.location) {
          props = this.props.location.state || {};
        } else {
          props = this.props.date;
        }
        let content;
        // console.log("props: ", props);

        switch (props.delivery) {
          case 1:
          default:
            content = <NovaPoshta modal='add-np' {...props} onClose={this.props.onClose}/>
            break;
          case 3:
            content = <UkrPosta modal='add-up' {...props} onClose={this.props.onClose} />
            break;
          case 'main_sale':
            content = null;
            break;
        }

        if (props.inner) {
          return content;
        } else {
          return (
            <OpenModal modal={{ closeOnBlur: false }} className="DeliveryData" close={this.props.onClose}>
                {content}
            </OpenModal>
          );
        }
    }
}


export default AddDeliveryModalLink;