import React, { Component } from "react";

import Navkolo from "./whitelables/navkolo";
import HugeProfit from "./whitelables/hugeprofit";
import Modeler from "./whitelables/modeler";

const WHITELABLE = process.env.REACT_APP_WHITELABLE;

class Contacts extends Component {
  render() {
    const blocks = {
      navkolo: <Navkolo />,
      hugeprofit: <HugeProfit />,
      modeler: <Modeler />,
    };

    return blocks[WHITELABLE] || <HugeProfit />;
  }
}

export default Contacts;
