import React from 'react'
import T from "../i18n";

const SelectFixPercentage = ({value, onChange, ...rest}) => {
	return (
		<select onChange={onChange} value={value}
						{...rest}>
			<option value='%'>
				%
			</option>
			<option value='fix'>
				{T('fix')}
			</option>
		</select>
	);
};

export default SelectFixPercentage;