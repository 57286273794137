import React, { Component } from 'react';
import T from "components/i18n";
import SelectComponent from 'tools/select-component'

import Box from 'react-bulma-components/lib/components/box';
import Actions from './products-actions' 
import User from 'user/user-store';

import { 
    Field, 
    Control
} from 'react-bulma-components/lib/components/form';

import Button from 'react-bulma-components/lib/components/button';

class ImportItemsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          integration_id: null
        };

        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.submit = this.submit.bind(this);
    }

    submit() {        
        const {onClose} = this.props;

        // console.log("this.state.integration_id", this.state.integration_id)
        if (!this.state.integration_id || !this.state.integration_id.value) {
          return;

        }
        Actions.importFromRemote(this.state.integration_id.value);

        Actions.importFromRemote.completed.listen((data) => {
          if (typeof(onClose) == "function") {
              onClose();
          }
          //window.history.back();
        });
    }

    onChangeSelect(selectedOption, actions) {
      this.setState({
          [actions.name]:  selectedOption,
          message: null
      });
    }

    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: {
            'value': value
          }
        });
    }


    render () {
        const {onClose } = this.props;


        return (
              <Box>
                <Field>
                  <Control>
                     <SelectComponent 
                          name="integration_id"
                          label="import-product-from"
                          onChange={this.onChangeSelect} 
                          value={this.state.integration_id} 
                          list={User.getIntegrationList}/>
                  </Control>
                </Field>

                <Button.Group>
                    <Button size="medium"  rounded color="primary"
                      onClick={this.submit}
                      >{T('import-items')}</Button>
                    <Button size="medium" rounded color="light"
                      onClick={onClose}
                      >{T('close')}</Button>
                  </Button.Group>
              </Box>
        );
    }

}

export default ImportItemsModal;