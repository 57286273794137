import React, { Component } from 'react';

import Box from 'react-bulma-components/lib/components/box';

import Heading from 'react-bulma-components/lib/components/heading';
import Container from 'react-bulma-components/lib/components/container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import T from "components/i18n";
import TarifBlock from "components/tarif-block";
import FooterBlock from '../common/footer'
import Hero from 'react-bulma-components/lib/components/hero';

import Panel from 'react-bulma-components/lib/components/panel';

import OpenModalButton from "components/modal/open-modal-button";
import AppStore from "app-store";

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";


class Tarif extends Component {

    render () {
        return (
        	<Box>
        	<Hero color="info" gradient className="tarif-plan" id="price">
                        <Hero.Body>
                          <Container>
                            <Heading size={3}>
                                {T('tarif-plan')}
                            </Heading>
        		<TarifBlock />

        		<Panel className="has-background-white">
                              <Panel.Header>
                                {T('all-tarif-plan-include')}
                              </Panel.Header>
                              <Panel.Block active>
                                <Panel.Icon renderAs={FontAwesomeIcon} icon={faCheckCircle} />
                                {T('Dashboard')} -&nbsp;<span className="is-size-7">{T('dashboard-desk')}</span>
                              </Panel.Block>
                              <Panel.Block active>
                                <Panel.Icon renderAs={FontAwesomeIcon} icon={faCheckCircle} />
                                {T('sales')} -&nbsp;<span className="is-size-7">
                                   {T('sales-desk')}
                                </span>
                              </Panel.Block>
                              <Panel.Block active>
                                <Panel.Icon renderAs={FontAwesomeIcon} icon={faCheckCircle} />
                                {T('Clients')} -&nbsp;<span className="is-size-7">{T('clients-desk')}</span>
                              </Panel.Block>
                              <Panel.Block active>
                                <Panel.Icon renderAs={FontAwesomeIcon} icon={faCheckCircle} />
                                {T('Payments')} -&nbsp;<span className="is-size-7">{T('payments-desk')}</span>
                              </Panel.Block>
                              <Panel.Block active>
                                <Panel.Icon renderAs={FontAwesomeIcon} icon={faCheckCircle} />
                                {T('Shipments')} -&nbsp;<span className="is-size-7">{T('shipments-desk')}</span>
                              </Panel.Block>
                              <Panel.Block active>
                                <Panel.Icon renderAs={FontAwesomeIcon} icon={faCheckCircle} />
                                {T('Warehouse')} -&nbsp;<span className="is-size-7">{T('warehouse-desk')}</span>
                              </Panel.Block>
                              <Panel.Block active>
                                <Panel.Icon renderAs={FontAwesomeIcon} icon={faCheckCircle} />
                                {T('integrations')}<span className="soon">{T('soon')}</span> -&nbsp;<span className="is-size-7">{T('integration-desk')}</span>
                              </Panel.Block>
                              <Panel.Block style={{display: "block"}}>
                                <OpenModalButton link={AppStore.getLink("registration")} text={T('start-free')} size={this.props.isMobileSize ? "medium" : "large"}  color="success"/>
                              </Panel.Block>
                            </Panel>
                    </Container>
                  </Hero.Body>
                </Hero>
                <FooterBlock />
            </Box>
			)}

      }

export default withIsMobileSize(Tarif);