import React, {useState} from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import NumberInput from "../number-input/number-input";
import T from '../i18n'

const ChangeIndexModal = ({index, onChangeIndex , onClose}) => {
	const [numberInput, setNumberInput] = useState(index)
	const handleChange = ({target: {value}}) => {
		setNumberInput(value)
	}

	const handleSubmit = () => {
		onChangeIndex(parseFloat(`${numberInput}`) - 1)
		onClose()
	}

	return (
		<Box>
			<Heading>{T('change-index')}</Heading>
			<NumberInput onChange={handleChange} value={numberInput}/>
			<div className='display-flex-row margin-top-10' style={{justifyContent: 'flex-end'}}>
				<Button color='success' onClick={handleSubmit}>
					{T('save-btn-label')}
				</Button>
				<Button onClick={onClose}>
					{T('close')}
				</Button>
			</div>
		</Box>
	);
};

export default ChangeIndexModal;