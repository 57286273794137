import React from "react";
import T from '../i18n'


export const createErrorMessage = (nonUniquePositionsObject) => {
	const messages = [];

	for (const position in nonUniquePositionsObject) {
		if (nonUniquePositionsObject.hasOwnProperty(position)) {
			const labels = nonUniquePositionsObject[position];
			const labelsString = labels.map(item => `${T(item)}`).join(', ');
			const message = T('error-message-position', {position, labelsString})
			messages.push(message);
		}
	}
	return !!messages.length && messages.map((item, index) => <React.Fragment key={index}>{item}<br/></React.Fragment>)
}

export const findNonUniquePositions = (obj) => {
	const positionsCount = {};
	const nonUniquePositionsObject = {};

	for (const key in obj) {
		if (obj.hasOwnProperty(key) && typeof obj[key] === "object") {
			const subObject = obj[key];
			const position = subObject.position;
			const isActive = subObject.is_active !== undefined ? subObject.is_active : true;

			if (position !== undefined && isActive) {
				positionsCount[position] = (positionsCount[position] || 0) + 1;
			}
		}
	}

	for (const key in obj) {
		if (obj.hasOwnProperty(key) && typeof obj[key] === "object") {
			const subObject = obj[key];
			const position = subObject.position;
			const isActive = subObject.is_active !== undefined ? subObject.is_active : true;

			if (position !== undefined && isActive && positionsCount[position] > 1) {
				if (!nonUniquePositionsObject[position]) {
					nonUniquePositionsObject[position] = [];
				}
				nonUniquePositionsObject[position].push(subObject.label);
			}
		}
	}

	return createErrorMessage(nonUniquePositionsObject);
};

export const getLabelsWithMissingPositions = (obj) => {
	const labelsWithMissingPositions = [];

	for (const key in obj) {
		if (obj.hasOwnProperty(key) && typeof obj[key] === "object") {
			const subObject = obj[key];
			const position = subObject.position;
			const isActive = !!subObject.is_active ? subObject.is_active : false;

			if (!position && isActive) {
				labelsWithMissingPositions.push(subObject.label);
			}
		}
	}

	return labelsWithMissingPositions;
};
