import WarehouseStore from "../../../../warehouse/warehouse-store";
import UserStore from "../../../../../user/user-store";

export const listToObjectForSelect = ({list}) => {
	return list.reduce((acc, cur) => {
		acc[cur.id] = {value: cur.id, label: cur.name}
		return acc
	}, {})
}

const isTruthy = value => {
	if (Array.isArray(value) && !!value.length) {
		return value[0] !== 0;
	}
	return !!value;
};

const generateDefaultValue = (value, list) => {
	if(Array.isArray(value) && !!value.length && !!value[0]) {
		return value.map(item => list[item])
	}
	if(typeof value === 'number') {
		return list[value]
	}
}

export const getActiveType = (mid, rid) => {
	const warehouse = WarehouseStore.getWarehouseList()
	const subUsers =  UserStore.getSubUsersList()

	const listWarehouse = listToObjectForSelect({list: warehouse})
	const listSubUsers = listToObjectForSelect({list: subUsers})



	const nullValue = null

	const defaultMid = generateDefaultValue(mid, listWarehouse)
	const defaultRid = generateDefaultValue(rid, listSubUsers)


	const isMidTruthy = isTruthy(mid);
	const isRidTruthy = isTruthy(rid);

	if (isMidTruthy && isRidTruthy) {
		return {
			activeType: ['mid', 'rid'],
			defaultValue: {
				mid: defaultMid,
				rid: defaultRid
			}
		}
	} else if (isMidTruthy) {
		return {
			activeType: ['mid'],
			defaultValue: {
				mid: defaultMid,
				rid: nullValue
			}
		}
	} else if (isRidTruthy) {
		return {
			activeType: ['rid'],
			defaultValue: {
				mid: nullValue,
				rid: defaultRid
			}
		}
	}

	return {
		activeType: ['all'],
		defaultValue: {
			mid: nullValue,
			rid: nullValue,
		}
	}
};