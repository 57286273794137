import React from "react";
import ProductsStore from "../../../../products/products-store";
import User from "../../../../../user/user-store";
import calcMargin from "../../../../../tools/calc-margin";
import T from "../../../../../components/i18n";
import Columns from "react-bulma-components/lib/components/columns";
import Image from "react-bulma-components/lib/components/image";
import { Control } from "react-bulma-components/lib/components/form";
import NumberInput from "../../../../../components/number-input/number-input";
import Icon from "react-bulma-components/lib/components/icon";
import Money from "../../../../../components/money";
import { productNameByStep } from "../utils";

const FinishProductLineDesktop = ({
  id,
  mid,
  step,
  productData,
  currency,
  currencyRate,
  onChangeProductData,
  onChangeRetailPrice,
  onChangeDeliveryPrice,
}) => {
  const pid = id;
  const product = ProductsStore.getProductByMid(pid, mid);
  const name = productNameByStep({ prod: product, step, currency });
  const img = "/bimages/get/64-" + User.get("id") + "-" + pid + "-0.jpg";
  let margin = calcMargin(
      parseFloat(productData[pid]["raw_net_price"]) + parseFloat(productData[pid]["fee_per_unit"]),
      productData[pid]["retail_price"]
    ),
    marginAmount =
      productData[pid]["retail_price"] -
      productData[pid]["raw_net_price"] -
      productData[pid]["fee_per_unit"];

  const shCurr = currency;
  const retailCurr = User.getCurrency();

  if (shCurr !== retailCurr) {
    const currRate = currencyRate;
    margin = calcMargin(
      productData[pid]["raw_net_price"] * currRate +
        productData[pid]["fee_per_unit"],
      productData[pid]["retail_price"]
    );
    marginAmount =
      productData[pid]["retail_price"] -
      productData[pid]["raw_net_price"] * currRate -
      productData[pid]["fee_per_unit"];
  }

  if (!product) {
    return (
      <>
        ID: {pid} - {T("product-remove")}
      </>
    );
  }

  const quantityReceived = productData[pid]["quantity_received"];
  const rawNetPrice = productData[pid]["raw_net_price"] || 0;
  const constDeliveryPerUnit = productData[pid]["fee_per_unit"] || 0;
  const currRate = currencyRate;
  let calcNewConstPrice =
    parseFloat(quantityReceived) *
    (parseFloat(rawNetPrice) + parseFloat(constDeliveryPerUnit));

  if (shCurr !== retailCurr) {
    calcNewConstPrice =
      parseFloat(quantityReceived) *
      (parseFloat(rawNetPrice) * parseFloat(currRate) +
        parseFloat(constDeliveryPerUnit));
  }

  return (
    <Columns style={{alignItems: 'center'}}>
      <Columns.Column align="left" size={"two-fifths"}>
        <Image src={img} size={32} className="align-left" />
        {name}
      </Columns.Column>
      <Columns.Column>
        <Control iconRight>
          <NumberInput
            key={"quantity_received-" + pid}
            name={"quantity_received-" + pid}
            className="for-small-icon"
            onChange={(evt) => onChangeProductData(evt, "quantity_received")}
            value={productData[pid]["quantity_received"]}
          />
          <Icon align="right">
            <span className="small-text">
              {ProductsStore.getUnits(productData[pid]["units"])}
            </span>
          </Icon>
        </Control>
      </Columns.Column>
      {User.getPermissionEnable("incomeinformation") && (
        <Columns.Column>
          <Control iconRight>
            <NumberInput
              key={"pid-" + pid}
              name={"pid-" + pid}
              className="for-small-icon"
              onChange={(evt) => onChangeProductData(evt, "raw_net_price")}
              value={productData[pid]["raw_net_price"]}
            />
            <Icon align="right">
              <span className="small-text">{shCurr}</span>
            </Icon>
          </Control>
        </Columns.Column>
      )}
      <Columns.Column>
        <Control iconRight>
          <NumberInput
            key={"retail_price-" + pid}
            name={"retail_price-" + pid}
            className="for-small-icon"
            onChange={onChangeRetailPrice}
            value={productData[pid]["retail_price"]}
          />
          <Icon align="right">
            <span className="small-text">{retailCurr}</span>
          </Icon>
        </Control>
      </Columns.Column>
      <Columns.Column>
        <Control iconRight>
          <NumberInput
            key={"fee_per_unit-" + pid}
            name={"fee_per_unit-" + pid}
            className="for-small-icon"
            onChange={onChangeDeliveryPrice}
            value={productData[pid]["fee_per_unit"]}
          />
          <Icon align="right">
            <span className="small-text">{retailCurr}</span>
          </Icon>
        </Control>
      </Columns.Column>
      <Columns.Column
        className="display-flex-center"
        style={{ height: "auto" }}
      >
        <Money amount={calcNewConstPrice} />
      </Columns.Column>
      {User.getPermissionEnable("incomeinformation") && (
        <Columns.Column>
          <Money amount={marginAmount} />
          {margin}%
        </Columns.Column>
      )}
    </Columns>
  );
};

export default FinishProductLineDesktop;
