import React, {useEffect} from 'react'
import BaseFilterLayout from "../../../components/base-filter-layout";
import OrdersStore from "../orders-store";
import OrdersFilters from "../orders-filters";
import OrdersActions from "../orders-actions";
import {useLocation} from "react-router-dom";
import DropdownDocument
	from "../../../components/document-modal/dropdown-document";
import T from "../../../components/i18n";
import {formattedDate} from "../../../tools/formatted-date-dd-mm-yy";
import ClientsStore from "../../clients/clients-store";
import ProductsStore from "../../products/products-store";
import SalesStore from "../../sales/sales-store";

const COLUMNS = [
	"#",
	"sale-channel",
	"order-price",
	"client",
	"name",
	"quantity",
	"product-info-price",
	"amount",
];

const FilterPanel = ({tab}) => {
	const handleChange = ({name, value}) => {
		if(name) {
			OrdersActions.filter(name, value);
		}
	}

	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value).reverse()
		daysKey.forEach(item => {
			OrdersActions.filter(item, value[item]);
		})
	}

	const handleRefreshFilter = () => {
		OrdersStore.clearFilter()
		OrdersActions.filter("status", tab)
		OrdersActions.refreshOrders()
	}
	const location = useLocation()

	useEffect(() => {
		if(location?.state?.date_start) {
			OrdersActions.filter('date_start', location?.state?.date_start)
		}

		if(location?.state?.search) {
			OrdersActions.filter('search', location?.state?.search)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const orders = OrdersStore.getOrders()
	const createDocumentList = orders.reduce((acc, order) => {
		const client = ClientsStore.getClientById(order.client_id)

		const channel_id = SalesStore.foundChannelsByIntegrationId(order?.integration_id);
		const channelName = SalesStore.getChannelsName(channel_id)

		const groupData = order.order_data.map((item, index) => {
			if(index === 0) {
				return [
					`${T('orders')}: ${order.order_id} \n ${order?.date_created}`,
					`${channelName}`,
					`
							${order.price} ${order.currency} 
						\n 
							${!!order?.discount ? `${T('sales-discount')}: ${Math.abs(order?.discount)}` : ''}
						\n
							${order?.info?.payment_type ? order.info.payment_type : ''} 
					`,
					`
							${!!Object.keys(client).length ? (
								`${client.simple_name}`	
							) : (
								`${order?.first_name} ${order?.last_name}`
							)}
						\n
							${order.phone ? order.phone : ''}
						\n
							${order.email ? order.email : ''}
					`,
					`${item.local_product_id ? ProductsStore.getProductName(item.local_product_id, true) : item.name}`,
					`${item.quantity}`,
					`${item.price} ${order.currency}`,
					`${item.price * item.quantity} ${order.currency}`
				]
			} else {
				return [
					'',
					'$span_h_1',
					'$span_h_1',
					'$span_h_1',
					`${item.local_product_id ? ProductsStore.getProductName(item.local_product_id, true) : item.name}`,
					`${item.quantity}`,
					`${item.price} ${order.currency}`,
					`${item.price * item.quantity} ${order.currency}`
				]
			}
		})

		groupData.forEach((item) => {
			acc.push(item)
		})

		return acc
	}, [])

	const heading = `${T('orders-report')} ${formattedDate(OrdersStore.getFilter("date_start"))} - ${formattedDate(OrdersStore.getFilter("date_end"))}`

	const documentList = {
		"head": {
			"title": heading,
			"table_cols": COLUMNS.map(item => T(item)),
			"table_widths_cols": [1,1,1,2,3,1,1,1],
			"orientation": 1
		},
		"table": createDocumentList,
	}



		return (
			<BaseFilterLayout
				searchStringSetting={{
					onChange: handleChange,
					defaultValue: OrdersStore.getFilter('search')
				}}
				refresh={{
					onChange: handleRefreshFilter,
					isLoading: OrdersStore.get("waiting")
				}}
				dateRangeSetting={{
					onChange: handleChangeDate,
					date_start: location?.state?.date_start || OrdersStore.getFilter('date_start'),
					date_end: OrdersStore.getFilter('date_end'),
				}}
				hiddenFilter={<OrdersFilters/>}
				additionalSetting={<DropdownDocument list={documentList} heading={heading}/>}
			/>)
};

export default FilterPanel;