import React from 'react';
import T from "components/i18n";
// import Money from "components/money";
// import DOM from "react-dom-factories";
// import { redirectTo } from 'tools/redirect-to'

import { 
    Field, 
    Control, 
    Input, 
    Label, 
} from 'react-bulma-components/lib/components/form';

import Columns from 'react-bulma-components/lib/components/columns';

// import Heading from 'react-bulma-components/lib/components/heading';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import Actions from './products-actions'
import Store from './products-store'

import SelectComponent from 'tools/select-component';

import SubmitButton from 'components/submit-button';
import Messages from 'components/messages';
import BaseForm from 'components/modules/base-form';

class AddCategoryModal extends BaseForm {
    constructor(props) {
        super(props);
        this.state = {
          close: false,
          waiting: false,
          id:  props.id || 0,
          parent_id:  {value: props.parent_id ? props.parent_id : 0},
          name: {'value': props.name ? props.name.replace(/—/g, '').trim() : ""},
          step: 0
        };

        this.submit = this.submit.bind(this);
    }

    componentWillUnmount() {
      if (typeof(this.addCategory) === "function") {
          this.addCategory();
      }

      if (typeof(this.addCategoryFail) === "function") {
          this.addCategoryFail();
      }
    }

    componentDidMount() {
      const {onClose} = this.props;

      this.addCategory = Actions.addCategory.completed.listen((data) => {
          this.setState({
              'waiting': false
          });

          if (typeof(onClose) == "function") {
              onClose();
          }
        
      });

      this.addCategoryFail = Actions.addCategory.failed.listen((res) => {
          const data = JSON.parse(res['response']);
            this.setState({
              'waiting': false,
              'message': {
                'msg': T(data['error'])
              }
          })
      }); 
    }

  

    submit() {
        if (this.state.name.value.length < 2) {
          this.setState({
            message: T("name-must-not-two")
          })
          return false;
        }

        this.setState({"waiting": true});


        Actions.addCategory({
          'id': this.state.id || 0,
          'name': this.state.name.value,
          'parent_id': this.state.parent_id.value || 0
        })
    }

    renderStepOne() {
      return (
          <Box>
              <Columns>
                      <Columns.Column>
                        <SelectComponent 
                          name="parent_id"
                          label="parent_category"
                          empty={T('root-cat')}
                          onChange={this.onChangeSelect} 
                          value={this.state.parent_id} 
                          list={() => Store.getCategoryListWithId(this.props.id)}
                          load={Store.loadCategory}
                          creatable={false}/>
                      </Columns.Column>
                      <Columns.Column>
                        <Field>
                              <Control>
                                <Label>{T('category-name')}</Label>
                                <Input  size="large" 
                                      name="name" 
                                      type="text" 
                                      autoComplete="nope"
                                      onChange={(e) => this.onChange(e)} 
                                      value={this.state.name.value}
                                      />
                              </Control>
                        </Field>
                      </Columns.Column>
              </Columns> 
              <Button.Group>
                    <SubmitButton text={this.state.id ? 'btn-edit' : 'add-category-btn-label'} waiting={this.state.waiting} submit={this.submit}/>
              </Button.Group>
          </Box>
      );
    }


    render() {
        return (
              <div>
                <Messages message={this.state.message} close={() => this.setState({message: null})} />
                {this.renderStepOne()}
              </div>
        );

    }  
}

export default AddCategoryModal;