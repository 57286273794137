import React, {useEffect, useState} from 'react'
import {Button} from "react-bulma-components";
import T from "../../../../components/i18n";
import {loadProductsConfirm} from "./utils";
import SubmitButton from "../../../../components/submit-button";
import {confirmDelete} from "../../../../tools/confirm-delete";
import UserActions from "../../../../user/user-actions";
import UserStore from "../../../../user/user-store";
import ProductsActions from "../../../products/products-actions";
import NotificationActions from "../../../notification/notification-actions";


const AdditionalButtonDownload = ({data}) => {
	const [waiting, setWaiting] = useState(false)

	useEffect(() => {
		const loadDownloadList = UserActions.downloadListCategories.completed.listen(() => {
			setWaiting(false)
			setTimeout(() => {
				ProductsActions.loadCategory();
				NotificationActions.loadMessages()
			}, 2000)
		})
		const loadDownloadListFailed = UserActions.downloadListCategories.completed.listen(() => {
			setWaiting(false)
			setTimeout(() => NotificationActions.loadMessages(), 2000)
		})

		return () => {
			loadDownloadListFailed()
			loadDownloadList()
		}
	}, []);

	const handleLoadCategory = () => {
		confirmDelete(() => {
			setWaiting(true)
			UserActions.downloadListCategories(data.id)
		}, 'download-list-categories', false, 'confirm-label', 'cancel-label')
	}

	return (
		<div className='display-flex-row' style={{justifyContent: 'center'}}>
			<Button rounded color="success" size="medium" onClick={() => loadProductsConfirm(data)}>
				{T('btn_load_products')}
			</Button>
			{data.integration_id === UserStore.HS_ID && <SubmitButton submit={handleLoadCategory}
										 text='download-list-categories' fullwidth={false} rounded
										 size='medium' color='info' waiting={waiting}/>}
		</div>
	);
};

export default AdditionalButtonDownload;