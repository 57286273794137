import React from "react";
import { Box, Columns, Heading, List } from "react-bulma-components";
import StatisticsBlock from "../../../components/statistics-block";
import Money from "../../../components/money";
import T from "../../../components/i18n";
import ProductsStore from "../../products/products-store";
import DOM from "react-dom-factories";

const StatisticsItem = ({ name, total, units, accrual,  percentage}) => {
  return (
    <List.Item>
      <Columns className="is-mobile">
        <Columns.Column>{name} {percentage && percentage?.type === 'fix' ? <>(<Money amount={percentage?.value} wrapper={DOM.span}/>)</> : percentage && `(${percentage?.value || percentage}%)`}</Columns.Column>
        <Columns.Column>{total}</Columns.Column>
        <Columns.Column>{units}</Columns.Column>
        <Columns.Column>{accrual}</Columns.Column>
      </Columns>
    </List.Item>
  );
};

const EmployeesStatisticsModal = ({ data }) => {
  const listStatistics = [
    { value: data.sales_total, name: "total-sales" },
    { value: data.sales_units, name: "sold-units" },
    { value: data.numDays, name: "num-days" },
    { value: <Money amount={data.sumAccrual} />, name: "accruals" },
  ];

  return (
    <Box>
      <Heading>
        {data.name} {data.phone && `(${data.phone})`}
      </Heading>
      <StatisticsBlock list={listStatistics} />
      <List>
        <StatisticsItem
          name={T("client-name")}
          total={T("total-sales")}
          units={T("sold-units")}
          accrual={T("accruals")}
        />
        {!!data.accrual_general.amount && (
          <StatisticsItem
            name={T("general")}
            total={data.accrual_general.sales}
            units={data.accrual_general.units }
            accrual={<Money amount={data.accrual_general.amount} />}
            percentage={{
              value: data.general,
              type: data.general_type
            }}
          />
        )}
        {Object.keys(data.accrual.category).map(
          (item) =>
            !!data.accrual.category[item].amount && (
              <StatisticsItem
                key={`${data.user_id}_${item}}`}
                name={ProductsStore.getCategoryName(item)}
                total={data.accrual.category[item].sales}
                units={data.accrual.category[item].units}
                accrual={<Money amount={data.accrual.category[item].amount} />}
                percentage={data.category[item]}
              />
            )
        )}
        {Object.keys(data.accrual.brand).map(
          (item) =>
            !!data.accrual.brand[item].amount && (
              <StatisticsItem
                key={`${data.user_id}_${item}}`}
                name={ProductsStore.getBrandName(item)}
                total={data.accrual.brand[item].sales}
                units={data.accrual.brand[item].units}
                accrual={<Money amount={data.accrual.brand[item].amount} />}
                percentage={data.brand[item]}

              />
            )
        )}
      </List>
    </Box>
  );
};

export default EmployeesStatisticsModal;
