import React from 'react'
import SelectComponent from "../../../../../tools/select-component";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import UserStore from "../../../../../user/user-store";
import {Field} from "react-bulma-components/lib/components/form";

const AdditionalField = ({type, value, onChange}) => {

	let list = () => {}
	let selectLabel

	if(type === 'rid') {
		list = UserStore.getSubUsersList
		selectLabel = 'fiscalize-user-label'
	}
	if(type === 'mid') {
		list = WarehouseStore.getWarehouseList
		selectLabel = 'fiscalize-warehouse-label'
	}

	return type !== 'all' && (
		<Field className='margin-top-10'>
			<SelectComponent
				name={type}
				label={selectLabel}
				isMulti
				onChange={onChange}
				value={value}
				list={list}
				className="full-size-select"
			/>
		</Field>

	);
};

export default AdditionalField;