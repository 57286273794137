import React, {useEffect, useState} from 'react'
import {
	Checkbox,
	Control,
	Field
} from "react-bulma-components/lib/components/form";
import T from "../../../components/i18n";
import {Link} from "react-router-dom";
import { LocalIcon } from "whitelables/wl-name/icons";
import PriceTagSettingPriceModal
	from "./components/price-tag-setting-price-modal";
import AppStore from "../../../app-store";

const PriceTagPriceField = ({name, active, onSubmit}) => {
	const [checkedValue, setCheckedValue] = useState(!!active.active);
	const handleChangeCheckbox = (evt) => {
		const checked = evt.target.checked;
		setCheckedValue(checked);
		if(active.active && checked) {
				onSubmit({price: active})
		} else {
			onSubmit({price: checked})
		}
	};

	const handleChange = (value) => {
		setCheckedValue(!!value)
		onSubmit({price: {active: Number(checkedValue) ,...value}})
	}

	useEffect(() => {
		onSubmit({price: {active: Number(checkedValue), ...active}})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<Field className="display-flex-row-gap">
			<Control>
				<Checkbox
					name={name}
					onChange={handleChangeCheckbox}
					className="termo_print"
					checked={!!checkedValue}
				>
					{T("product-info-price")}
				</Checkbox>
			</Control>
			<Link
				className="link-icon-centred"
				to="#"
				onClick={() => AppStore.openModal(<PriceTagSettingPriceModal modal='oversize' onSubmit={handleChange} activeList={active}/>)}
			>
				<LocalIcon size="small" icon="gear" />
			</Link>
		</Field>
	);
};

export default PriceTagPriceField;