import React from "react";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import { AVAILABLE_LANGS } from "../../configs/wl-name/api";
import { Link } from "react-router-dom";
import UserStore from "../../user/user-store";

const SelectLang = () => {
  const keysLang = Object.keys(AVAILABLE_LANGS);

  let lang_text = (
    <span className="uppercase bold-text">{UserStore.getLang()}</span>
  );

  const handleChangeLang = (lang) => {
    document.location.href = "/" + lang + "/"
  }

  return (
    <Dropdown hoverable right label={lang_text} className='disabled-background'>
      {keysLang.map((lang) => (
        <Dropdown.Item key={lang} value={lang}>
          <Link
            to={"/" + lang + "/"}
            onClick={() => handleChangeLang(lang)}
          >
            {AVAILABLE_LANGS[lang]["full"]}
          </Link>
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

export default SelectLang;
