import Reflux from 'reflux';

const SalesActions = Reflux.createActions({
    add: { asyncResult: true },
    openSaleModal: { asyncResult: true },
    load: { asyncResult: true },
    deleteSale: { asyncResult: true },
    loadChannels: { asyncResult: true },
    addChannels: { asyncResult: true },
    refund: { asyncResult: true },
    cancelReserv: { asyncResult: true },
    finishReserv: { asyncResult: true },
    finishParcel: { asyncResult: true },
    addTrackingNumber: { asyncResult: true },
    createTrackingNumber: { asyncResult: true },
    setToDps: { asyncResult: true },
    filter: { sync: true },
    filterRefund: { sync: true },
    filters: { sync: true },
    getPrintFiscalCheck: { asyncResult: true },
    getSalesByClientId: { asyncResult: true },
    loadReports: { asyncResult: true },
    changeStatus: { asyncResult: true },
    loadPackListNP: { asyncResult: true },
    // convertPdfToImage: { asyncResult: true }
    deliveredKeeperDate: { asyncResult: true },
    loadRefund: { asyncResult: true },
    loadByOid: { asyncResult: true },
    loadCauntingSaleByStatus: { asyncResult: true },
    editComment: { asyncResult: true },
});

export default SalesActions;