import React from 'react';
import AppStore from "app-store";
import User from 'user/user-store';
import { Link } from "react-router-dom";


export const MyLink = (url, text, target) => {
	if (AppStore.isMobileApp()) {
		return (
			<span className="like-link" onClick={() => User.postMessage("open-url::"+url)}>{text}</span>
		);
	} else {
		return (
			<Link target={target || "_blank"} to={{pathname: url}}>
	             {text}
	        </Link>
		);
	}
}