import React, { Component } from 'react';
import OpenModal from 'components/modal/modal';
import ChangeAccountModal from './change-account'


class ChangeAccountModalLink extends Component {
    constructor(props) {
        super(props);
        console.log("props change account modal, ", props)
        this.state = {}
    }

    render() {
        const props = this.props.location.state;

        return (
          <OpenModal modal={{ closeOnBlur: false }}>
              <ChangeAccountModal {...props} />
          </OpenModal>
        );
    }
}

export default ChangeAccountModalLink;