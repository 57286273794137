import React from "react";
import { Box, Heading, List } from "react-bulma-components";
import {LocalIcon} from 'whitelables/wl-name/icons'
import T from "../../../../components/i18n";
import UserStore from "../../../../user/user-store";
import ClientsStore from "../../../clients/clients-store";
import User from "../../../../user/user-store";
import ExpensesStore from "../../../expenses/expenses-store";
import formatDate from "../../../../tools/format-date";
import ChannelList from "../../common/channel-list";
import { getOrderStatus } from "../utils";
import ReceiptViewModal from "../../../settings/terminal/modal/receipt-view-modal";
import AppStore from "../../../../app-store";

const SaleInfo = ({ data }) => {
  const clientDeliveryInfo = ClientsStore.getClientDeliveryShort(
    data["client_id"],
    data["clients_delivery_id"],
    User.getLang()
  );

  let deliveryName;

  if (data["delivery_info"] === "1") {
    deliveryName =
      <span className='display-flex-row' style={{display:'inline-flex'}}>
        <LocalIcon icon='nova-poshta' size='small'/>
        {T("nova_poshta")}
      </span>
  } else if (data["delivery_info"] === "3") {
    deliveryName =
      <span className='display-flex-row' style={{display:'inline-flex'}}>
        <LocalIcon icon='ukr-poshta' size='small'/>
        {T("ukr_poshta")}
      </span>
  }

  let accountName = T(ExpensesStore.getAccountName(data.account_id));
  if (data.prepaid_amount > 0) {
    if (data.prapaid_account_id === data.account_id) {
      accountName = ExpensesStore.getAccountName(data.account_id);
    } else {
      accountName = (
        <span>
          {T(ExpensesStore.getAccountName(data.prapaid_account_id))} /{" "}
          {T(ExpensesStore.getAccountName(data.account_id))}
        </span>
      );
    }
  }

  const dataProductInfo = data.items.reduce(
    (acc, curr) => {
      acc.count += parseFloat(curr.quantity);
      return acc;
    },
    { count: 0 }
  );
  const statusName = getOrderStatus(data.order_status);

  const handleOpenReceiptModal = (receiptID) => {
    AppStore.openModal(<ReceiptViewModal receiptID={receiptID} />);
  };

  return (
    <Box>
      <Heading size={4}>{T("sale-info-label")}:</Heading>
      <div
        className="display-flex-row"
        style={{ alignItems: "flex-start", gap: 10 }}
      >
        <List style={{ flex: 1 }}>
          {UserStore.get("count_subusers") > 0 && (
            <List.Item>
              <b>{T("who-add-sale")}: </b>
              {UserStore.getResponsebleName(data["responsible_user_id"])}
            </List.Item>
          )}
          <List.Item>
            <b>{T("sale-account")}: </b>
            {accountName}
          </List.Item>
          {(clientDeliveryInfo || deliveryName) && (
            <List.Item>
              <b>{T("delivery")}: </b>
              {clientDeliveryInfo ? clientDeliveryInfo : deliveryName}
            </List.Item>
          )}
          {!!data?.tracking_number && (
            <List.Item>
              <b>{T("ttn")}: </b>
              {data["tracking_number"]}
            </List.Item>
          )}
          {!!data?.terminal_trx && !!data?.terminal_trx?.length && (
            <List.Item>
              <b>{T("id-transaction")}: </b>
              {data.terminal_trx.map((receiptID) => (
                <span
                  onClick={() => handleOpenReceiptModal(receiptID)}
                  key={receiptID}
                  className="bold button_link underline cursor-pointer"
                >
                  {receiptID}
                  {data.terminal_trx.length > 1 && " | "}
                </span>
              ))}
            </List.Item>
          )}
        </List>
        <List style={{ flex: 1 }}>
          <List.Item>
            <b>{T("sale-channel")}: </b>
            {<ChannelList channels={data.channel_id} />}
          </List.Item>
          <List.Item>
            <b>{T("status")}: </b>
            {statusName}
          </List.Item>
          <List.Item>
            <b>{T("quantity")}: </b>
            {T("items-count", { count: data.items.length })}(
            {dataProductInfo.count} {T("items-e")})
          </List.Item>
          <List.Item>
            <b>{T("date")}: </b>
            {formatDate(data.purchase_date)}
          </List.Item>
        </List>
      </div>
    </Box>
  );
};

export default SaleInfo;
