import Policy from "../../../public/policy";
import PolicyRU from "../../../public/policy/policy_ru";
import StoreProgram from "../../../public/landing/shop";
import OnlineShop from "../../../public/landing/online-shop";
import SmallProduction from "../../../public/landing/production";
import WarehouseSoftware from "../../../public/landing/warehouse";
import AgreementEN from "../../../public/policy/agreement_en";
import AgreementUA from "../../../public/policy/agreement_ua";
import AgreementRU from "../../../public/policy/agreement_ru";
import ReturnRu from "../../../public/policy/return";
import React from "react";
import Tarif from "../../../public/home/tarif";
import UserStore from "../../../user/user-store";
import Sales from "../../../modules/sales/index-nw";
import Dashboard from "../../../modules/dashboard";
import Home from "../public/home";
import Start from "../start";
import ReturnUa from "../../../public/policy/return-ua";
import ReturnEn from "../../../public/policy/return-en";

function getMainPage(index) {
  let main = <Home />,
    comp = Home;

  if (!UserStore.isGuest()) {
    if (UserStore.isCashier() && UserStore.getModuleEnable("sales")) {
      main = <Sales />;
      comp = Sales;
    } else if (UserStore.getModuleEnable("dashboard")) {
      main = <Dashboard />;
      comp = Dashboard;
    } else {
      main = <Start />;
      comp = Start;
    }
  }

  return [main, comp][index];
}

export const notAuthRoutes = [
  {
    path: "/",
    exact: true,
    main: () => getMainPage(0),
    component: getMainPage(1),
  },
  {
    path: "/:lang/policy",
    auth: false,
    main: () => <Policy />,
    component: Policy,
  },
  {
    path: "/:lang/app/policy",
    auth: false,
    main: () => <Policy />,
    component: Policy,
  },
  {
    path: "/:lang/policy_ru",
    auth: false,
    component: PolicyRU,
    main: () => <PolicyRU />,
  },
  {
    path: "/:lang/landing/store-program",
    auth: false,
    component: StoreProgram,
    main: () => <StoreProgram />,
  },
  {
    path: "/:lang/landing/accounting-software",
    auth: false,
    component: OnlineShop,
    main: () => <OnlineShop />,
  },
  {
    path: "/:lang/landing/small-production",
    auth: false,
    component: SmallProduction,
    main: () => <SmallProduction />,
  },
  {
    path: "/:lang/landing/warehouse-software",
    auth: false,
    component: WarehouseSoftware,
    main: () => <WarehouseSoftware />,
  },

  {
    path: "/en/agreement",
    auth: false,
    main: () => <AgreementEN />,
    component: AgreementEN,
  },
  {
    path: "/en/app/agreement",
    auth: false,
    main: () => <AgreementEN />,
    component: AgreementEN,
  },
  {
    path: "/:lang/agreement",
    auth: false,
    main: () => <AgreementUA />,
    component: AgreementUA,
  },
  {
    path: "/:lang/app/agreement",
    auth: false,
    main: () => <AgreementUA />,
    component: AgreementUA,
  },
  {
    path: "/:lang/agreement_ru",
    auth: false,
    main: () => <AgreementRU />,
    component: AgreementRU,
  },
  // {
  //   path: "/:lang/return",
  //   auth: false,
  //   main: () => <ReturnRu />,
  //   component: ReturnRu,
  // },
  {
    path: "/ru/return",
    auth: false,
    main: () => <ReturnRu />,
    component: ReturnRu,
  },
  {
    path: "/app/ru/return",
    auth: false,
    main: () => <ReturnRu />,
    component: ReturnRu,
  },
  {
    path: "/ua/return",
    auth: false,
    main: () => <ReturnUa />,
    component: ReturnUa,
  },
  {
    path: "/app/ua/return",
    auth: false,
    main: () => <ReturnUa />,
    component: ReturnUa,
  },
  {
    path: "/en/return",
    auth: false,
    main: () => <ReturnEn />,
    component: ReturnEn,
  },
  {
    path: "/app/en/return",
    auth: false,
    main: () => <ReturnEn />,
    component: ReturnEn,
  },
  {
    path: "/pricing",
    auth: false,
    main: () => <Tarif />,
    component: Tarif,
  },
  {
    path: "/:lang",
    exact: true,
    main: () => getMainPage(0),
    component: getMainPage(1),
  },
];
