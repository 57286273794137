import React from "react";
import SelectComponent from "../../../../../../tools/select-component";
import NumberInput from "../../../../../../components/number-input/number-input";
import { Field, Label } from "react-bulma-components/lib/components/form";
import T from "../../../../../../components/i18n";

const MinBalanceContent = ({
  name,
  placeholderSelect,
  listSelect,
  selectValue,
  inputValue,
  ignoreList,
  onChangeSelect,
  onChangeInput,
}) => {
  const filteredList = listSelect?.filter((item) => {
    return !ignoreList?.includes(item.id)
  })
  return (
    <>
      {listSelect && (
        <SelectComponent
          name={name}
          empty={!!ignoreList?.length ? null : "all"}
          value={selectValue}
          list={() => filteredList}
          placeholder={placeholderSelect}
          isMulti
          className="full-size-select"
          onChange={onChangeSelect}
        />
      )}
      <Field>
        <Label>{T("min-balance-products")}</Label>
        <NumberInput
          name="minStock"
          value={inputValue}
          onChange={onChangeInput}
        />
      </Field>
    </>
  );
};

export default MinBalanceContent;
