import React, { useEffect, useState } from "react";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import List from "react-bulma-components/lib/components/list";
import SalesStore from "../sales-store";
import {
  Field,
  Input,
  Label,
} from "react-bulma-components/lib/components/form";
import ClientsStore from "../../clients/clients-store";
import Button from "react-bulma-components/lib/components/button";
import SubmitButton from "../../../components/submit-button";
import User from "../../../user/user-store";
import SalesActions from "../sales-actions";
import { generateOrderName, getClient, getPrice, getDate } from "./utils";
import errorMessageControlHook from "../../../components/error-message-block/error-message-control.hook";
import { errorHandler } from "../../../tools/error-handler";
import SelectStore from "../select-store";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const MassDeliveryModal = ({ massList, onClose }) => {
  const {isMobileSize} = useIsMobile()
  const getSales = massList.map((item) => SalesStore.getSalesByOid(item));
  const list = getSales.reduce((acc, curr) => {
    if (curr.oid) {
      acc[curr.oid] = { oid: curr.oid, tn: curr.tracking_number || "" };
    }

    return acc;
  }, {});

  const {
    errorMessage,
    handleAutoRemoveError,
    ErrorMessageBlock,
    setErrorMessage,
  } = errorMessageControlHook();
  const [currentList, setCurrentList] = useState(list);
  const [waiting, setWaiting] = useState(false);

  const handleChangeInput = ({ target: { name, value } }) => {
    setCurrentList((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        tn: value,
      },
    }));
  };

  const handleSubmit = () => {
    const data = Object.values(currentList);
    setWaiting(true);
    SalesActions.addTrackingNumber(null, null, null, data);
  };

  useEffect(() => {
    const addTracking = SalesActions.addTrackingNumber.completed.listen(() => {
      SelectStore.clearSelectGroup()
      onClose();
    });

    const addTrackingFail = SalesActions.addTrackingNumber.failed.listen(
      (res) => {
        setWaiting(false);
        const message = errorHandler(res);
        handleAutoRemoveError(message);
      }
    );

    return () => {
      addTracking();
      addTrackingFail();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading size={4}>{T("mass-delivery-label")}</Heading>
        <Heading subtitle className="has-text-danger" size={isMobileSize ? 6 : 5}>
                {T('count-edit-sale', {count: getSales.length})}
        </Heading>
        <List style={{overflow: 'auto', maxHeight: '70vh'}}>
          {getSales.map((item) => (
            <List.Item
              key={item.oid}
              className="display-flex-row-gap"
              style={{ alignItems: "center" }}
            >
              <div
                className="display-flex-column-gap"
                style={{ gap: 0, maxWidth: "50%" }}
              >
                <span>
                  <b>{generateOrderName(item)}</b>
                  &nbsp;
                  <b>{getPrice(item)}</b>
                </span>
                {getDate(item)}
                {getClient(item)}
                {ClientsStore.getClientDeliveryNameById(
                  item["client_id"],
                  item["clients_delivery_id"],
                  User.getLang()
                )}
              </div>
              <Field>
                <Label>{T("tracking-number")}</Label>
                <Input
                  name={`${item.oid}`}
                  value={currentList[item.oid]?.tn || ""}
                  onChange={handleChangeInput}
                />
              </Field>
            </List.Item>
          ))}
        </List>
        <Button.Group position="right">
          <SubmitButton
            text="send-parcels"
            size="large"
            submit={handleSubmit}
            fullwidth={false}
            waiting={waiting}
          />
          <Button color="light" rounded onClick={onClose} size="large">
            {T("close")}
          </Button>
        </Button.Group>
      </Box>
    </>
  );
};

export default MassDeliveryModal;
