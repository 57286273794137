import React from 'react'
import NewProductRowStock from "./new-product-row-stock";
import NewProductRow from "./new-product-row";

const SingleRender = ({data, backgroundLightBlack, isVariable}) => {
	if (Object.values(data.stock).length > 1) {
		return <NewProductRowStock data={data}
															 backgroundLightBlack={backgroundLightBlack} isVariable={isVariable}/>;
	} else {
		return <NewProductRow data={data} stock={Object.values(data.stock)[0]}
													backgroundLightBlack={backgroundLightBlack} isVariable={isVariable}/>
	}
}

export default SingleRender;