import React from "react";

import HowStartInfo from "public/info/wl-name/how-start/";
import DashboardInfo from "public/info/wl-name/dashboard/";
import ExpensesInfo from "public/info/wl-name/expenses/";
import SalesInfo from "public/info/wl-name/sales/";
import ProductsInfo from "public/info/wl-name/products/";
import ClientsInfo from "public/info/wl-name/clients/";
import WarehouseInfo from "public/info/wl-name/warehouse/";
import NpApiInfo from "public/info/wl-name/integrations/np.js";

const routes = [
  {
    path: "/help/start",
    exact: true,
    auth: false,
    main: () =>  <HowStartInfo />
  },
  {
    path: "/help/dashboard",
    exact: true,
    auth: false,
    main: () =>  <DashboardInfo />
  },
  {
    path: "/help/expenses",
    exact: true,
    auth: false,
    main: () =>  <ExpensesInfo />
  },
  {
    path: "/help/sales",
    exact: true,
    auth: false,
    main: () =>  <SalesInfo />
  },
  {
    path: "/help/products",
    exact: true,
    auth: false,
    main: () =>  <ProductsInfo />
  },
  {
    path: "/help/clients",
    exact: true,
    auth: false,
    main: () =>  <ClientsInfo />
  },
  {
    path: "/help/warehouse",
    exact: true,
    auth: false,
    main: () =>  <WarehouseInfo />
  },
  {
    path: "/help/npapi",
    exact: true,
    auth: false,
    main: () =>  <NpApiInfo />
  },
  // {
  //   path: "/help/video",
  //   auth: false,
  //   component: VideoHelpModal
  // },

 ];


export default routes;