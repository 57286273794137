import React from "react";
import {Box, Button, Columns, Heading} from "react-bulma-components";
import T from '../../../../components/i18n'
import User from "../../../../user/user-store";
import Money from "../../../../components/money";

const AccountStatisticsModal = ({data, onClose}) => {
  return (
    <Box className='with-border with-border-radius'>
      <Heading>{T('statistics-for', {account: data?.name})}</Heading>
      <Columns style={{display: 'flex'}}>
        {User.getPermissionEnable("showbalance") && (
          <Columns.Column>
            <Box>
              <b>{T("start-period")}</b>
              <br />
              <Money
                amount={data?.statistics?.period_first}
                aid={data["id"]}
                offDept
              />
            </Box>
          </Columns.Column>
        )}
        {User.getPermissionEnable("showbalance") && (
          <Columns.Column>
            <Box>
              <b>{T("end-period")}</b>
              <br />
              <Money amount={data?.statistics?.period_last} aid={data["id"]} offDept />
            </Box>
          </Columns.Column>
        )}
        <Columns.Column>
          <Box>
            <b>{T("count-transaction")}</b>
            <br />
            {data?.statistics?.count || 0}
            {T("items-p")}
          </Box>
        </Columns.Column>
        {User.getPermissionEnable("show-cashflow") && (
          <Columns.Column>
            <Box>
              <b>{T("sum-coming")}</b>
              <br />
              <Money
                className="text-success"
                amount={data?.statistics?.coming}
                aid={data["id"]}
                offDept
              />
            </Box>
          </Columns.Column>
        )}
        {User.getPermissionEnable("show-cashflow") && (
          <Columns.Column>
            <Box>
              <b>{T("sum-expense")}</b>
              <br />
              <Money
                className="text-danger"
                amount={Math.abs(data?.statistics?.expense || 0)}
                aid={data["id"]}
                offDept
              />
            </Box>
          </Columns.Column>
        )}
      </Columns>
      <div align='right'>
        <Button onClick={onClose} color='success'>
          {T('close')}
        </Button>
      </div>
    </Box>
  );
};

export default AccountStatisticsModal;
