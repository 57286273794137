import React from "react";
import List from "react-bulma-components/lib/components/list";
import T from "../../../components/i18n";
import ChannelBlock from "../../../components/channel-block/channel-block";
import { getAddress } from "../common/utils";
import CommunicationClient from "../../../components/communication-client";
import ClientInfoRow from "../../../components/client-info-row/client-info-row";

const OrderDetailInfo = ({ data, client, channel_id }) => {
  const getAddressInfo = getAddress(data);

  const paid = !!data?.order_data[0]["is_paid"] && (
    <span className="channel_block has-background-success">{T("paid")}</span>
  );

  const paymentType =
    !!data?.order_data[0]?.payment_type && data?.order_data[0]?.payment_type;

  return (
    <List>
      <List.Item>
        <b>{T("date")}: </b>
        {data.date_created}
      </List.Item>
      <List.Item>
        <b>
          {T("sale-channel")}: <ChannelBlock channel_id={channel_id} />
        </b>
      </List.Item>
      {client && (
        <List.Item className='display-flex-row'>
          <b>{T("customer")}:</b>
          {client.id ? (
              <ClientInfoRow clientID={data.client_id} withoutPhone withTags/>
            )
            : <span>{client.name}</span>}
          <CommunicationClient phone={data.phone} style={{width: 'fit-content'}}/>
        </List.Item>
      )}
      {data.email && (
        <List.Item>
          <b>{T("Email")}:</b> {data.email}
        </List.Item>
      )}
      {getAddressInfo.addressInfo?.city && getAddressInfo.block && (
        <List.Item>{getAddressInfo.block}</List.Item>
      )}
      <List.Item>
        <b>{T("subscribe")}: </b>
        {paid && <>{paid} / </>} {paymentType && paymentType}
      </List.Item>
    </List>
  );
};

export default OrderDetailInfo;
