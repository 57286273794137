import React from 'react'
import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../i18n";
import ExpensesStore from "../../modules/expenses/expenses-store";
import UserStore from "../../user/user-store";
import InfoWrapper from "../info-wrapper";

const InfoBlockQuantityAmount = ({allQuantity, lengthProduct, amount, title}) => {
	return (
		<Box className="margin-top-10">
			<Columns>
				<Columns.Column>
					<Heading>{T(title)}:</Heading>
					{UserStore.getPermissionEnable('incomeinformation') && <div>
						<InfoWrapper info='cost-price-average'>
							<b>{T('on-amount')}:</b> {amount || 0} {ExpensesStore.getAccountCurrency()}
						</InfoWrapper>
					</div>}
					<div><b>{T('quantity')}: {allQuantity || 0} {T('items-e')} | {lengthProduct || 0} {T('items-short')}</b></div>
				</Columns.Column>
			</Columns>
		</Box>
	);
};

export default InfoBlockQuantityAmount;