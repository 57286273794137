//It's a very important to have variable 'messages'
//because our "lang compiler" expects such format

const messages = {
    // buttons
    "close-btn-label": {
        ru: "Закрыть",
        en: "Close"
    },
    "cancel-btn-label": {
        ru: "Отмена",
        en: "Cancel"
    },
    "cancel-label": {
        ru: "Отменить",
        en: "Cancel",
        ua: "Скасувати"
    },
    "prev-step-btn-label": {
        ru: "Назад",
        en: "Back"
    },
    "next-step-btn-label": {
        ru: "Далее",
        en: "Next"
    },
    "send-btn-label": {
        ru: "Отправить",
        ua: "Відправити",
        en: "Send"
    },
    "save-btn-label": {
        ru: "Сохранить",
        ua: "Зберегти",
        en: "Save"
    },
    "restore-label": {
        ru: "Восстановить",
        en: "Restore",
        ua: "Відновити"
    },
    "on-label": {
        ru: "Вкл",
        en: "On"
    },
    "off-label": {
        ru: "Выкл",
        en: "Off"
    },
    "resend-label": {
        ru: "Отправить повторно",
        en: "Resend"
    },
    "confirm-label": {
        ru: "Подтвердить",
        en: "Confirm",
        ua: "Підтвердити"
    },
    "confirmation-code-label": {
        ru: "Код подтверджения",
        en: "Confirmation code"
    },
    "verified-label": {
        ru: "Верифицирован",
        en: "Verified"
    },
    "code-label": {
        ru: "Код",
        en: "Code"
    },
    "phone-number": {
        ru: "Номер телефона",
        en: "Phone number"
    },
    "by-phone-label": {
        ru: "По телефону",
        en: "By phone"
    },
    "by-email-label": {
        ru: "По Email",
        en: "By Email"
    },
    "clear-label": {
        ru: "Удалить",
        en: "Clear"
    },
    "amount-to-deposit-label": {
        ru: "Сумма для пополнения счета",
        en: "Deposit amount"
    },
    "retry-upload-label": {
        ru: "Повторить загрузку",
        en: "Retry upload",
        ua: 'Повторити завантаження'
    },

    "select-file": {
        ru: "Выбрать файл",
        en: "Select file"
    },

    // messages
    "required-field-error": {
        ru: "Вы не заполнили все обязательные поля",
        en: "You have not filled in all the required fields",
        ua: "Ви не заповнили всі обов'язкові поля"
    },
    "required-field-error-message": {
        ru: "Поле обязательно для заполнения",
        en: "Required field"
    },
    "wrong-format-field-error-message": {
        ru: "Поле заполнено некорректно",
        en: "Incorrect field entry"
    },
    "minlength-field-error-message": {
        ru: "Поле заполнено некорректно",
        en: "Incorrect field entry"
    },
    "maxlength-field-error-message": {
        ru: "Поле заполнено некорректно",
        ua: "Поле заполнено некорректно",
        en: "Incorrect field entry"
    },
    "unknown-server-error": {
        ru: "Ошибка отправки данных. Повторите попытку позже",
        en: "Error while sending data. Please try again later"
    },
    "not-correct-email-or-password": {
        ru: "Неверный E-mail или Пароль",
        ua: "Невірний E-mail чи Пароль",
        en: "Not correct Email or Password"
    },
    "not-correct-password": {
        ru: "Неверный Пароль",
        ua: "Невірний Пароль",
        en: "Not correct Password"
    },
    "auth-error-need-email-confirm": {
        ru: "Для авторизации необходимо подтвердить email",
        en: "For authorization necessary to confirm email"
    },
    "form-validation-error": {
        ru: "Ошибка заполнения",
        en: "Invalid data"
    },
    "default-form-success-message": {
        ru: "Изменения сохранены",
        en: "Changes have been saved"
    },
    "required-accept-policy": {
        ru: "Для прохождения регистрации согласитесь с условиями",
        en: "For continuation of registration accept conditions"
    },
    "forbidden-country": {
        ru: "Регистрация из данной страны невозможна",
        en: "Registration from this country forbbiden"
    },
    "phone-already-exists": {
        ru: "Учетная запись с данным телефоном уже существует",
        en: "Accaunt with this phone number already exist"
    },
    "email-already-exists": {
        ru: "Учетная запись с таким email уже существует",
        ua: "Обліковий запис із таким email вже існує",
        en: "Accaunt with this email already exist"
    },
    "partner-not-found": {
        ru: "Некоректная валюта",
        en: "Incorrect currency"
    },
    "processed-phone-not-found": {
        ru: "Телефон не подтверждён",
        en: "Phone was not confirmed"
    },
    "processed-email-not-confirmed": {
        ru: "Не подтверждён",
        en: "Not confirmed"
    },
    "phone-not-confirmed": {
        ru: "Неверный код подтверждения",
        en: "Wrong confirmation code"
    },
    "phone-or-email-required": {
        ru: "Телефон или почта обязательны для заполнения",
        ua: "Телефон або пошта обов'язкові для заповнення",
        en: "Phone or email are required"
    },
    "country-denied": {
        ru: "Выбрана запрещенная страна",
        en: "Country denied"
    },
    "email-resend": {
        ru: "Ссылка отправлена повторно",
        en: "Link has been resend"
    },
    "profile-verification-fill": {
        ru: "Заполнить",
        en: "Fill"
    },
    "profile-verification-attention": {
        ru: "Внимание",
        en: "Attention"
    },
 
    "user-quota-exceeded": {
        ru: {
            plural: "{filename} не был загружен, так как Вами было загружено слишком много файлов за всё время."
        },
        en: {
            plural: "{filename} was not uploaded because you downloaded too many files all the time."
        }
    },
    "unknown-mime-type": {
        ru: {
            plural: "Не удалось определить формат {filename}. Пожалуйста убедитесь, что загружаете файл правильного формата."
        },
        en: {
            plural: "Failed to determine {filename} format. Please verify that you are uploading the file in the correct format."
        }
    },
    "restricted-mime-type": {
        ru: {
            plural: "{filename} имеет не разрешенный формат. Пожалуйста убедитесь, что загружаете файл правильного формата."
        },
        en: {
            plural: "{filename} has an unallowed format. Please verify that you are uploading the file in the correct format."
        }
    },
    "bad-image-geometry": {
        ru: {
            plural: "{filename} имеет недопустимое разрешение. Пожалуйста, убедитесь что разрешение файле не превышает {max_width} х {max_height}."
        },
        en: {
            plural: "{filename} has an invalid resolution. Please make sure the file resolution does not exceed {max_width} x {max_height}."
        }
    },
    "one-or-more-documents-already-loaded": {
        ru: "Ошибка. Один или несколько файлов уже загружены.",
        en: "Error. One or more files has been already loaded."
    },
    "error-loading-file": {
        ru: "Ошибка. Попробуйте повторить загрузку.",
        en: "Error. Try downloading again."
    },
    "birthday-adult-error": {
        ru: "Услугами сайта можно пользоваться только после достижения 18 лет",
        en: "Site services can only be used after reaching the age of 18"
    }
};

export default messages;
