export default function createOptionSelect(items, first_label) {
      let options = [];

      if (first_label) {
        options.push(
          { value: 0, label: first_label}
        );
      }

      for (let i in items) {
          let p = items[i];
          options.push({ value: p['id'], label: p['name'] });
      }

      return options;
};