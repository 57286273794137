import React from "react";
import {
  deleteOrder, deleteReserveOrders,
  editOrderModal,
  openSaleModal,
} from "../common/actions-func";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalIcon } from "whitelables/wl-name/icons";
import s from "./styles.module.scss";
import AppStore from "../../../app-store";
import CancelledOrder from "../common/cancelled-order";
import T from "../../../components/i18n";
import {Button} from "react-bulma-components";

const ActionButtons = ({ data, isReserve }) => {
  let isStatusEdit = <></>;
  if (data?.sales_id) {
    isStatusEdit = (
      <span
        className="icon-action link"
        onClick={() => editOrderModal(data?.sales_id)}
      >
        <FontAwesomeIcon icon="edit" size="2x" />
      </span>
    );
  } else {
    isStatusEdit = (
      <>
        <span
          onClick={(evt) => openSaleModal({ evt, data })}
          className="icon-action link"
        >
          <LocalIcon icon="circle-success" size="large" />
        </span>
        <span
          className="has-text-danger"
          onClick={() => 	AppStore.openModal(<CancelledOrder data={data}/>)
          }
        >
          <LocalIcon icon="circle-close" size="large" />
        </span>
      </>
    );
  }

  return (
    <>
      {isReserve && <div align='center' className='margin-bottom-10'>
        <Button size='small' rounded color='warning'
                onClick={(evt) => deleteReserveOrders(data.id)}
                className='nowrap'>
          <FontAwesomeIcon icon='undo'/>&nbsp;{T('withdraw-all-reserves')}
        </Button>
      </div>}
      <div className={s.actions}>
        {isStatusEdit}
        <span
          className="icon-action link"
          onClick={(evt) => deleteOrder({evt, data})}
        >
        <FontAwesomeIcon icon="trash-alt" size="2x"/>
      </span>
      </div>
    </>

  );
};

export default ActionButtons;
