import React from 'react'
import HistoryStore from "../history-store";
import HistoryActions from "../history-actions";
import FilterPanel from "../common/filter-panel";
import Box from "react-bulma-components/lib/components/box";
import PaginationBlock from "../../../components/pagination";
import Reports from "../../../components/reports";
import HistoryRow from "./history-row";
import T from "../../../components/i18n";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const EmptyMessage = () => {
	return (
		<Box>
			<h2>{T("not-have-history")}</h2>
		</Box>
	);
};

const COLUMNS = ["history-action", "info-short", "comment"];

const HistoryContent = () => {
	const {isMobileSize} = useIsMobile()
	const listHistory = HistoryStore.getPageList(null, null, "history")

	return (
		<div className="history-report font-size-80">
			{!isMobileSize && <FilterPanel/>}
			<Box>
				{isMobileSize && <FilterPanel/>}
				<PaginationBlock
					setPage={HistoryActions.setPage}
					totalPage={HistoryStore.get("count_page")}
					currentPage={HistoryStore.get("page")}
				/>

				<Reports
					emptyMessage={EmptyMessage}
					columns={COLUMNS}
					hideFooterOnEmpty
					isLoading={HistoryStore.get("isLoaded")}
					rows={listHistory}
				>
					<HistoryRow role="row"/>
				</Reports>
				<PaginationBlock
					setPage={HistoryActions.setPage}
					totalPage={HistoryStore.get("count_page")}
					currentPage={HistoryStore.get("page")}
				/>
			</Box>
		</div>
	);
};

export default HistoryContent;