import React, { Component } from 'react';
import T from "components/i18n";
// import { redirectTo } from 'tools/redirect-to'

import Heading from 'react-bulma-components/lib/components/heading';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

// import Actions from './orders-actions'
// import Store from './orders-store'
import SelectProducts from 'components/select-products';

import UserStore from 'user/user-store';

import SubmitButton from 'components/submit-button';
import Messages from 'components/messages';
import ProductsActions from "../products/products-actions";

class BindingProductModal extends Component {
    constructor(props) {
        super(props);
        console.log("props make binding", props)
        const integration = UserStore.getIntegrationNameById(props.data.integration_id);
        ProductsActions.filter('mid', {value: integration.ident.mid})
        this.state = {
          products: null,
          product_name: "",
          message: null,
          waiting: null,
          iid: props.data.integration_id,
          mid: integration.ident.mid.toString(),
          commission: 0,

          close: false,
          step: 0
        };


        this.submit = this.submit.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.onChangeSelectProduct = this.onChangeSelectProduct.bind(this);

    }

    componentWillUnmount() {

    }

    componentDidMount() {
    }

 
    onChangeSelectProduct(selectedOption) { 
      console.log("selectedOption ", selectedOption);

      if(!!selectedOption.length) {
        const pid_ident = selectedOption[0]['value'],
          name = selectedOption[0]['label'];

        this.setState({
          products: pid_ident.split("_")[0],
          product_name: name
        });
      }
              // data = ProductsStore.getProductByMid(pid, mid);
    }


    submit() {
        const {submit, data, onClose} = this.props;
        if (!this.state.products) {
          this.setState({'message': T('select-product-list')}) 
          return;
        }
        this.setState({"waiting": true});
        submit(this.state.products, data.remote_id, data.integration_id);

        setTimeout(onClose, 100);
    }

    renderStepOne() {
       const {data} = this.props;
      return (
          <Box>
              <Heading>
                  {T('binding-products')}
              </Heading>

              <Box>
                ID: {data['remote_id']}. {data['name']}
              </Box>
              <center><Heading>{T('with')}</Heading></center>
              <Box>
                   {this.state.product_name}
              </Box>

              <SelectProducts 
                  onChange={this.onChangeSelectProduct} 
                  value={this.state.products}
                  single={true}
                  source="sales"
                  mid={this.state.mid}
                  quantity={1} />

              <div className="margin-bottom-10">
              <b>{T('if-not-product')}</b>
              </div>

              <Button.Group>
                    <SubmitButton text='binding-products' disabled={!this.state.products ? true : false} waiting={this.state.waiting} submit={this.submit}/>
              </Button.Group>
          </Box>
      );
    }

    render() {
        const stepNaviganor = [this.renderStepOne],
              currentStep = stepNaviganor[this.state.step];


        return (
              <div>
                <Messages message={this.state.message} close={() => this.setState({message: null})} />
                {currentStep()}
              </div>
        );

    }  
}

export default BindingProductModal;