import React, { Component } from 'react';
import T from "components/i18n";
import Heading from 'react-bulma-components/lib/components/heading';
import Button from 'react-bulma-components/lib/components/button';
import Box from 'react-bulma-components/lib/components/box';
import AppStore from 'app-store'
import {
	withIsMobileSize
} from "../../../components/is-mobile-context/is-mobile-context";


class NotEnableNPModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        	redirect: false
        }
    }

    render() {
    	const {delivery_id} = this.props;
			const sizeClass = this.props.isMobileSize ? "small" : "medium";

	    return (
	          <Box>
	            	<Heading>{T(delivery_id ? 'not-enable-up' : 'not-enable-np')}</Heading>
	            	<Button.Group position="centered">
			              <Button size={sizeClass}  rounded color="light"
			                onClick={() => { AppStore.closeModal()}}
			                >{T('close')}</Button>

			              <Button  size={sizeClass}   rounded color="primary"
			                onClick={() => { window.location.href = "/settings/integrations" }}
			                >{T('set-integration')}</Button>
			        </Button.Group>
	          </Box>
	        )
    }

}


export default withIsMobileSize(NotEnableNPModal);