
const Actions = {
    load: { asyncResult: true },
    filter: { sync: true },
    setPage: { sync: true },
    setSort: { sync: true },
    setCountPerPage: { sync: true },
  
};


export default Actions;