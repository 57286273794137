import React from "react";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import Money from "../../../components/money";
import DOM from "react-dom-factories";
import Button from "react-bulma-components/lib/components/button";

const CreateIsOk = ({ onClose, onCompleted, data }) => {
  return (
    data && (
      <Box>
        <div align="center">
          <Heading size={2} className="text-success">
            {T("ttn-success-created")}
          </Heading>
          <Heading size={1} className="text-danger" subtitle>
            <b>{data["IntDocNumber"] || "000000000000"}</b>
          </Heading>
        </div>

        <p className="big-text">
          <b>
            {T("delivery-cost")}:{" "}
            <Money amount={data["CostOnSite"]} wrapper={DOM.span} />
          </b>
        </p>
        <p className="big-text">
          <b>
            {T("delivery-time")}: {data["EstimatedDeliveryDate"]}
          </b>
        </p>

        <div className="margin-top-10" align="center">
          <Button
            size="large"
            color="success"
            onClick={() => {
              onCompleted(data["IntDocNumber"]);
              onClose();
            }}
          >
            {T("btn-done")}
          </Button>
        </div>
      </Box>
    )
  );
};

export default CreateIsOk;
