import React from 'react'
import T from '../../../components/i18n'
import { 
    Field, 
    Control, 
    Input, 
    Label,
} from 'react-bulma-components/lib/components/form';
import Columns from 'react-bulma-components/lib/components/columns';
import UserStore from 'user/user-store';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";




const DiscountRow = ({ discount, index,type, onChange, onDelete}) => {
  const {isMobileSize} = useIsMobile()
  return (
    <>
      <Columns key={index}>
              <Columns.Column key={index + "purchases"}>
                <Field>
                  <Control>
                    <Label>{T("purchases-amount")}, {UserStore.getCurrency()}:</Label>
                    <Input
                      size="medium"
                      name="purchases"
                      type="number"
                      placeholder={T('purchases-amount-placeholsder')}
                      autoComplete="nope"
                      onChange={(evt) => onChange(evt, index)}
                      value={discount.purchases}
                    />
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column key={index + "discount"}>
                <Field>
                  <Control>
                    <Label>{type === 'bonus' ? T('purchases-credited-bonuses') : `${T("discount-amount")}, %:`}</Label>
                    <Input
                      size="medium"
                      name="discount"
                      type="number"
                      placeholder={type === 'bonus' ? T('bonus-amount-placeholder') : T('discount-amount-placeholsder')}
                      autoComplete="nope"
                      onChange={(evt) => onChange(evt, index)}
                      value={discount.discount}
                    />
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Link className="discounts-delete-btn" to="#" onClick={()=>onDelete(index)} title={T('delete')}>
                {isMobileSize ? <span><FontAwesomeIcon icon="trash-alt" size="1x" /> {T('delete-discount')} </span> : <FontAwesomeIcon icon="trash-alt" size="1x" />}
          </Link>
    </>
  )
}

export default DiscountRow;