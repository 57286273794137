import React, { Component } from 'react';
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";
import { redirectTo } from 'tools/redirect-to'
import { 
    Field, 
    Control, 
    Input,
    Label, 
} from 'react-bulma-components/lib/components/form';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import ClientsStore from '../clients-store'
import Actions from '../clients-actions'

import SelectComponent from 'tools/select-component';
import Columns from 'react-bulma-components/lib/components/columns';

import Messages from 'components/messages';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Link } from "react-router-dom";

class Novaposhta extends Component {
    constructor(props) {
        super(props);
        const data = props || {};
        let phone = "";
        if (data.phone) {
          phone = data.phone.value || data.phone;
          if (["+", "3"].includes(phone[0]) === false) {
              phone = "+38"+phone;
          }
        }

        let name = "";
        if (data.name) {
          name = data.name.value || data.name;

          if (name.value === "") {
            name = name.value;
          }

          if (name && name.indexOf("(") > -1) {
              name = name.split("(")[0];
          }
        }

        this.state = {
          name: {
                error: "",
                value: name || ""
          },
          phone: {
                error: "",
                value: phone || data.phone || "+380"
          },
          city: data.city || 0,
          client_id: data.client_id || null,
          postoffice: data.postoffice || null,
          sender: data.sender || false,
          message: null,
          close: false
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentWillUnmount() {
      if (typeof(this.listen) == "function") {
          this.listen();
      }
    }

    componentDidMount() {
        const {onClose} = this.props;

        this.listen = Actions.addDeliveryService.completed.listen(() => {
            if (typeof(onClose) == "function") {
                setTimeout(() => {onClose()}, 100);
            }
        });

        this.listenFailed = Actions.addDeliveryService.failed.listen((res) => {
          const data = JSON.parse(res['response']);
            this.setState({
              'message':  T(data['error'])
            });
        });
    }

    submit() {
        let data = {
              "did": 1,
              "cid": this.state.client_id,
              "city": this.state.city.value,
              "city_name": this.state.city.label,
              "name": this.state.name.value,
              "postoffice": this.state.postoffice.value,
              "postoffice_name": this.state.postoffice.label,
        }

        if (this.state.sender) {
          data['sender'] = this.state.sender;
        }

        if (this.state.postoffice.value === 0 || this.state.city.value === 0 ) {
          this.setState({
            message: T('not-select-post-office')
          })
          return;
        }

        if (data['name'].length < 5) {
          this.setState({
              message: T('client-name-placeholder')
          })
          return;
        }


        Actions.addDeliveryService(data);
    }

    onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
        [name]: {
          'value': value
        }
        });
    }

    onChangeSelect(selectedOption, actions) {
      console.log("changeSelect ", selectedOption, actions);
      if (actions.name === "city" && ClientsStore.getLastCity() !== selectedOption.value) {
        ClientsStore.cleanPostoffice()
      }
      this.setState({
          [actions.name]:  selectedOption,
      });
    }


    render() {
      const filterData = (inputValue, list) => {
        if (inputValue.length < 1) return [];

        let result = list.filter(i =>
          i.label.toLowerCase().split("(")[0].includes(inputValue.toLowerCase())
        );
        return result.slice(0, 100);
      };

      const button_text = this.state.sender ? 'add-sender' : 'add-delivery-address-btn';

      return (
              <Box>
                <Messages message={this.state.message} close={() => this.setState({message: null})} />
                <Columns>
                  <Columns.Column>
                    <Field>
                      <Control>
                        <Label>{T('client-phone')}</Label>
                        <Input  size="large" 
                              name="phone" 
                              type="text" 
                              disabled={this.state.sender ? false : true}
                              placeholder={T('client-phone-placeholder')}
                              onChange={this.onChange} 
                              color={(this.state.phone.error) ? "danger" : "primary"}
                              value={this.state.phone.value}
                              />
                      </Control>
                    </Field>
                  </Columns.Column>
                  <Columns.Column>
                      <Field>
                        <Control>
                          <Label>{T('client-name')}</Label>
                          <Input  size="large" 
                                name="name" 
                                type="text" 
                                placeholder={T('client-name-placeholder')}
                                onChange={this.onChange} 
                                color={(this.state.name.error) ? "danger" : "primary"}
                                value={this.state.name.value}
                                />
                        </Control>
                      </Field>
                  </Columns.Column>
                </Columns> 
                <Columns>
                  <Columns.Column>
                    <Field>
                      <SelectComponent 
                            name="city"
                            label="city"
                            onChange={this.onChangeSelect} 
                            value={this.state.city} 
                            showLimit={500}
                            empty={T('select-city')}
                            list={ClientsStore.getCityList}
                             _filters={filterData}
                            load={Actions.loadCity}
                            async={true}
                            />
                    </Field>
                  </Columns.Column>
                  <Columns.Column>
                   <Field>
                      <SelectComponent 
                            name="postoffice"
                            label="post-office-number"
                            onChange={this.onChangeSelect} 
                            value={this.state.postoffice} 
                            limitShow={200}
                            empty={T('select-post-office-number')}
                            list={() => {return ClientsStore.getPostOfficeList(this.state.city)}}
                            async={true}
                            // load={() => {return Actions.loadPostoffice(this.state.city)}}
                            // promice={() => {return Actions.loadPostoffice(this.state.city.value)}}
                            />
                    </Field>
                  </Columns.Column>
                </Columns>
                
                <Button.Group>
                  <Button fullwidth size="large"  rounded color="primary"
                    onClick={this.submit}
                    >{T(button_text)}</Button>
                </Button.Group>
                {this.state.close ? redirectTo("/clients") : ""}
              </Box>
        );
    }

}

export default connectToStores(Novaposhta, { 
    client: ClientsStore
});
