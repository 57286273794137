import { createActions } from "tools/reflux-tools";

const TerminalActions = createActions({
  purchase: { asyncResult: true },
  load: { asyncResult: true },
  addTerminalReceipt: { asyncResult: true },
  loadTerminalReceipt: { asyncResult: true },
  filter: { sync: true },
  loadReceiptById: { asyncResult: true }
});

export default TerminalActions;
