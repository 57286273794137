import React, { Component } from 'react';

import Content from 'react-bulma-components/lib/components/content';
import Section from 'react-bulma-components/lib/components/section';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import FooterBlock from '../common/footer'


class ReturnRu extends Component {
    render () {
        return (
        	<Section>
        	<Box>
        	<Content>
				<Heading>Оплата. Возврат. Гарантии</Heading>

                <Heading subtitle size={5}>Стоимость избранное Тарифа, порядок расчетов и условия возмещения </Heading>
 <p>Стоимость  выбранного Тарифа определяется согласно Тарифам Компании.

</p> <p>Компания имеет право по своему усмотрению изменять стоимость выбранной Тарифа и условия изменения тарифных планов путем их публикации в новой редакции на Сайте Компании по адресу h-profit.com/pricing/. Новые тарифы вступают в силу с даты их публикации.

</p> <p>В случае если Пользователь продолжает использования Сервиса после вступления в силу новых Тарифов, считается, что он принял такие изменения Тарифов и дал свое согласие на продолжение сотрудничества в соответствии с новыми Тарифами.

</p> <p>В любом случае, перерасчет Выбранного Тарифа за оплаченные периоды не производится.
</p>
<Heading subtitle size={5}>Порядок расчетов </Heading>
<p>Оплата лицензионного вознаграждения производится в соответствии с выбранным Тарифа, авансовым платежом в размере 100% от суммы выбранного тарифа, не менее чем за два рабочих дня до желаемой даты начала использования Сервиса.

</p> <p>Все Способы оплаты указаны на сайте Компании по адресу <a href="https://h-profit.com/pricing/">https://h-profit.com/pricing/</a> и в аккаунте пользователя.

</p> <p>Моментом исполнения Пользователем обязанностей по оплате считается день зачисления денежных средств на расчетный счет Компании.

</p> <p> Пользователи оплачивающих Выбранный тариф, предоставляют согласие на услуги автоматического регулярного продолжение выбранной тарифа на новый срок (автопродление выбранного Тарифа). После активации данной услуги оплата новый срок избранное тарифа осуществляется путем автоматического безакцептного списания средств со счета пользователя. Услуга автопродления избранное Тарифа предоставляется до тех пор, пока Пользователь не отменит подписку на Выбранный Тариф в сервисе компании.

</p> <p> Услуга активируется при осуществлении первой оплаты выбранной Тарифа, что означает согласие пользователя с тем, что после окончания оплаченного срока действия выбранной тарифа Договор автоматически пролонгируется на тех же условиях на тот же срок с автоматическим списанием стоимости выбранной Тарифа со счета Пользователя

</p> <p> В случае недостаточности средств на счете пользователя или невозможности проведения платежа Выбранный Тариф на новый срок не предоставляется.

</p> <p> При изменении Компанией Тарифы автопродления избранное Тарифа на очередной новый срок, следующий за периодом, в течение которого произошло такое изменение будет осуществлено по новому тарифу.

</p> <p> Все комиссии, взимаемые кредитными организациями / другими третьими лицами при осуществлении платежа, оплачивает Сторона-плательщик.
</p>
<Heading subtitle size={5}>Условия возмещения </Heading>
<p>Компания гарантирует Пользователю возможность возврата денежных средств, внесенных в качестве оплаты выбранной Тарифа, в течение 14 (четырнадцати) календарных дней с даты осуществления оплаты путем отправки электронного письма о досрочном расторжении настоящего Договора и возврата уплаченных в качестве аванса денежных средств с зарегистрированной электронной почты пользователя на электронную почту Компании h.info@h-profit.com.

По окончании 14 (четырнадцати) календарных дней с даты осуществления оплаты возврат не производится.

</p> <p>Компания, в случае получения официального заявления в оговоренный в п. 4.3.1. Договора срок, берет на себя обязанность рассмотреть указанное заявление и вернуть средства на расчетный счет пользователя, с которого были уплачены средства, в течение 30 (тридцати) календарных дней с даты получения письма. В случаях, когда возврат на счет пользователя, с которого были уплачены средства, невозможно, Пользователь указывает новый счет в письме о возврате уплаченных в качестве аванса денежных средств.

</p> <p> Возврат денежных средств означает досрочное расторжение настоящего Договора.
</p>
<Heading subtitle size={5}>Гарантия безопасности платежей</Heading>
<p>Наш сайт подключен к интернет-эквайрингу, и Вы можете оплатить Подписку банковскими картами. После подтверждения выбранной подписки откроется защищенное окно с платежной страницей процессингового центра, где Вам необходимо ввести данные Вашей банковской карты. Для дополнительной аутентификации держателя карты используется протокол 3D Secure. Если Ваш Банк поддерживает данную технологию, Вы будете перенаправлены на его сервер для дополнительной идентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.</p>

<p><b>Гарантии безопасности</b></p>

<p>Процессинговый центр защищает и обрабатывает данные Вашей банковской карты по стандарту безопасности PCI DSS 3.2. Передача информации в платежный шлюз происходит с применением технологии шифрования SSL. Дальнейшая передача информации происходит по закрытым банковским сетям, имеющим наивысший уровень надежности. Процессинговый центр не передает данные Вашей карты нам и иным третьим лицам. Для дополнительной аутентификации держателя карты используется протокол 3D Secure.</p>

<p>В случае, если у Вас есть вопросы по совершенному платежу, Вы можете обратиться в службу поддержки клиентов платежного сервиса.</p>

<p><b>Безопасность онлайн платежей</b></p>

<p>Предоставляемая Вами персональная информация (имя, адрес, телефон, e-mail, номер кредитной карты) является конфиденциальной и не подлежит разглашению. Данные Вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.</p>

<p>Мы рекомендуем вам проверить, что ваш браузер достаточно безопасен для проведения платежей онлайн, на специальной странице.</p>

<p>Безопасность обработки Интернет-платежей гарантирует процессинговый центр. Все операции с платежными картами происходят в соответствии с требованиями VISA International, MasterCard и других платежных систем. При передаче информации используются специальные технологии безопасности карточных онлайн-платежей, обработка данных ведется на безопасном высокотехнологичном сервере процессинговой компании.</p>

			</Content>
			</Box>
            <FooterBlock />
			</Section>
		);
    }
}

export default ReturnRu;
