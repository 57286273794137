import React, {useEffect, useState} from 'react'
import HistoryActions from "../../history-actions";
import {Box, Button, Heading} from "react-bulma-components";
import T from '../../../../components/i18n'
import SelectComponents from "../../../../tools/select-component";
import SubmitButton from "../../../../components/submit-button";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../../../tools/error-handler";

const RestoreModal = ({mids, pid, onClose}) => {

	const [activeMid, setActiveMid] = useState(mids.length === 1 ? [{label: mids[0].name, value: mids[0].id}] : null)
	const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = ErrorMessageControlHook()
	const [waiting, setWaiting] = useState(false)

	const handleRestoreAll = () => {
		HistoryActions.restoreProduct(pid, mids.map(item => item.id).join(','))
	}

	const handleRestore = () => {
		if(activeMid) {
			setWaiting(true)
			HistoryActions.restoreProduct(pid, activeMid.map(item => item.value).join(','))
		} else {
			handleAutoRemoveError('add-least-one-warehouse')
		}
	}

	const handleChangeSelect = (value) => {
		setActiveMid(value)
	}

	useEffect(() => {
		const restoreCompleted = HistoryActions.restoreProduct.completed.listen(() => {
			onClose()
		})
		const restoreFailed = HistoryActions.restoreProduct.failed.listen((res) => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		return () => {
			restoreCompleted()
			restoreFailed()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ErrorMessageBlock onRemove={setErrorMessage} message={errorMessage}/>
			<Box>
				<Heading>{mids > 1 ? T('recovery-products-by-stock') : T('restore-product')}</Heading>
				{mids.length > 1 && <SelectComponents
					onChange={handleChangeSelect}
					isMulti
					value={activeMid}
					placeholder='select-warehouse-label'
					list={() => mids || []}
				/>}
				<div className='display-flex-row' style={{ justifyContent: 'right' }}>
					{mids.length > 1 && <div style={{flex: 1}}>
						<SubmitButton color="info" size='medium' fullwidth={false}
													text='restore-all' submit={handleRestoreAll}/>
					</div>}
					<SubmitButton color="success" size='medium' fullwidth={false} text='restore-label' submit={handleRestore} waiting={waiting}/>
					<Button size='medium' rounded onClick={onClose}>
						{T('close')}
					</Button>
				</div>
			</Box>
		</>
	);
};

export default RestoreModal;