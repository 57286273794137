import React, { Component } from 'react';

import Columns from 'react-bulma-components/lib/components/columns';
import Section from 'react-bulma-components/lib/components/section';
// import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
// import FooterBlock from '../common/footer'
import InfoMenu from './menu.js'
import MobileTopMenu from './mobile-menu.js'

import routes from 'components/routes/info-routes'

import { isBrowser } from "react-device-detect";

import {
  Switch,
  Route
} from "react-router-dom";


class Info extends Component {
    render () {
        return (
            <Box className="help-block">
    			     <Columns>
                    <Columns.Column className="is-2 is-narrow-mobile is-fullheight is-hidden-mobile">
                        <InfoMenu />
                    </Columns.Column>
                    <Columns.Column>
                        {isBrowser ? "" : <MobileTopMenu />}
                        <Section className="is-size-5">
                            <Switch>  
                                {routes.map((route, index) => (
                                  <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    children={<route.main />}
                                  />
                                ))}
                            </Switch>
                        </Section>
                    </Columns.Column>
              </Columns>
          </Box>
		)
    }
}

export default Info;


