import React, {useEffect, useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import {
	Field,
} from "react-bulma-components/lib/components/form";
import IntegrationControlHook from "../common/integration-control.hook";
import Columns from "react-bulma-components/lib/components/columns";
import SelectComponent from "../../../../../tools/select-component";
import ClientsStore from "../../../../clients/clients-store";
import {filterData, isValidKeys, parseObj} from "../utils";
import {connectToStores} from "../../../../../tools/reflux-tools";
import ClientsActions from "../../../../clients/clients-actions";
import ActionsModal from "../common/actions-modal";
import UserActions from "../../../../../user/user-actions";
import InputsGroupField from "../common/inputs-group-field";
import ModalItsOK from "../../../../../components/modal-its-ok";
import {errorHandler} from "../../../../../tools/error-handler";
import {confirmDelete} from "../../../../../tools/confirm-delete";

const DATA_INPUT_STEP_ZERO = [
	{
		name: "api_key",
		placeholder: "np_key-placeholder"
	},
]

const DATA_INPUT_NEXT_STEP = [
	{
		name: "name",
		placeholder: "client-name-placeholder",
		disabled: true
	},
	{
		name: "phone",
		placeholder: "client-phone-placeholder",
		disabled: true
	},
]

const NovaPoshta = ({ data, onClose,}) => {
	const {id, api_key, name, ident, is_active} = data
	let identParse = typeof ident === 'string' ? parseObj(ident) : ident

	const {
		inputValues,
		handleChangeInputValues,
		handleChangeGroupInputValues,
		errorMessage,
		handleAutoRemoveError,
		setErrorMessage,
		selectValue,
		handleChangeSelect
	} = IntegrationControlHook({
		inputDefaultValue: {
			api_key: api_key || '',
			phone: identParse?.phone || '',
			name: name || ''
		},
		selectDefaultValue: {
			city: {value: 0, label: T('select-city')},
			postoffice: {value: 0, label: T('select-post-office-number')}
		}
	})

	const [postOfficeList, setPostOfficeList] = useState([])
	const [senderID, setSenderID] = useState(id)
	const [waiting, setWaiting] = useState(false)
	const [submitOK, setSubmitOK] = useState('')

	const firstInit = !id

	useEffect(() => {
		if(identParse?.city && identParse?.post_office) {
			const label = ClientsStore.getCityNameById(identParse?.city)
			const labelPost = ClientsStore.getPostOfficeNameById(identParse?.city, identParse?.post_office)

			if(label) {
				handleChangeCity({value: identParse?.city, label}, {name: 'city'})
			}
			if(labelPost) {
				handleChangeSelect({value: identParse?.post_office, label: labelPost}, {name: 'postoffice'})
			}

		}

		const loadCity = ClientsActions.loadCity.completed.listen(() => {
			if(identParse?.city) {
				const label = ClientsStore.getCityNameById(identParse?.city)
				handleChangeCity({value: identParse.city, label}, {name: 'city'})
			}
			loadCity()
		})

		const loadPostOffice = ClientsActions.loadPostoffice.completed.listen(() => {
			if(identParse?.post_office) {
				const label = ClientsStore.getPostOfficeNameById(identParse?.city, identParse?.post_office)
				handleChangeSelect({value: identParse?.post_office, label}, {name: 'postoffice'})
				// eslint-disable-next-line react-hooks/exhaustive-deps
				identParse = ''
			} else {
				handleChangeSelect({value: 0, label: T('select-post-office-number')}, {name: 'postoffice'})
			}

			setPostOfficeList(ClientsStore.getPostOfficeList(selectValue?.city))
		})

		return () => {
			loadPostOffice()
		}
	}, []);

	const handleChangeCity = (value, { name }) => {
		if(!value?.length && value.value !== selectValue.city.value && !!value.value) {
			ClientsActions.loadPostoffice(value.value)
			handleChangeSelect(value, { name })
		}
		if(!value.value) {
			handleChangeSelect(value, { name })
			setPostOfficeList([])
			handleChangeSelect({value: 0, label: T('select-post-office-number')}, {name: 'postoffice'})
		}
	}

	useEffect(() => {
		const listenAdd = UserActions.updateIntegrations.completed.listen(({name, phone, sender, error, is_active}) => {
			setWaiting(false)

			if(error) {
				handleAutoRemoveError(error)
			} else {
				setSenderID(sender)
				handleChangeGroupInputValues({name,phone})
				if(!firstInit) {
					handleSubmitDelivery()
				}
				if(!firstInit && is_active === 1) {
					setSubmitOK('add-integration-completed-title')
				} else if(!firstInit && is_active === 0) {
					setSubmitOK('deactivate-integration-completed-title')
				} else if (!firstInit) {
					setSubmitOK('add-integration-completed-title')
				}
			}
		});

		const listenAddFailed = UserActions.updateIntegrations.failed.listen(res => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		const listenDelete = UserActions.deleteIntegration.completed.listen(() => {
			setSubmitOK('delete-integration-completed-title')
		})

		const listenDeleteFailed = UserActions.deleteIntegration.failed.listen(res => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		const listenDeliveryAdd = ClientsActions.addDeliveryService.completed.listen(() => {
				setWaiting(false)
				setSubmitOK('add-integration-completed-title')
		})

		const listenDeliverAddFailed = ClientsActions.addDeliveryService.failed.listen(res => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		return () => {
			listenAdd()
			listenAddFailed()
			listenDeliveryAdd()
			listenDeliverAddFailed()
			listenDelete()
			listenDeleteFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectValue, inputValues]);



	const handleErrorKey = () => {
		if(!isValidKeys(inputValues, ['api_key'], 32)) {
			handleAutoRemoveError('wrong-key')
			return false
		}
		return true
	}

	const handleErrorDelivery = () => {
		const getSelectKeys = Object.keys(selectValue)
		if(getSelectKeys.every(item => !selectValue[item].value)) {
			handleAutoRemoveError('not all fields required')
			return false
		}
		return true
	}

	const handleSubmitNewIntegration = () => {
		const newData = id || senderID ? data :  {
			name: T("new-sender-label"),
			integration_id: 1,
			api_key: inputValues.api_key
		}
		if(handleErrorKey()) {
			setWaiting(true)
			UserActions.updateIntegrations(newData)
		}
	}

	const handleSubmitIntegration = ({deactivate}) => {
		const copyData = JSON.parse(JSON.stringify(data))

		copyData.is_active = deactivate ? 0 : 1

		if(api_key !== inputValues.api_key) copyData.api_key = inputValues.api_key

		if(handleErrorKey()) {
			setWaiting(true)
			UserActions.updateIntegrations(copyData)
		}
	}

	const submit = ({deactivate}) => {
		if(firstInit && !senderID) {
			handleSubmitNewIntegration()
		} else if(firstInit && senderID){
			handleSubmitDelivery()
		} else {
			handleSubmitIntegration({deactivate})
		}
	}

	const handleSubmitDelivery = () => {
			const deliveryData = {
				"did": 1,
				"cid": data?.client_id || null,
				"city": selectValue?.city?.value,
				"city_name": selectValue?.city?.label,
				"name": inputValues?.name,
				"phone": inputValues?.phone,
				"postoffice": selectValue?.postoffice?.value,
				"postoffice_name": selectValue?.postoffice?.label,
			}

			if(senderID) deliveryData.sender = senderID
			if(handleErrorDelivery()) {
				setWaiting(true)
				ClientsActions.addDeliveryService(deliveryData)
			}
	}

	const handleDeleteIntegration = () => {
		if(id || senderID) {
			setWaiting(true)
			UserActions.deleteIntegration(id || senderID)
		}
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!submitOK && <Box>
				<Heading size={3}>{T('setting-integration', [T('nova_poshta')])}</Heading>
				<InputsGroupField dataInput={DATA_INPUT_STEP_ZERO} inputValues={inputValues} onChange={handleChangeInputValues}/>
				{senderID && <>
					<InputsGroupField dataInput={DATA_INPUT_NEXT_STEP} inputValues={inputValues} onChange={handleChangeInputValues}/>
					<Columns>
						<Columns.Column>
							<Field>
								<SelectComponent
									name="city"
									label="city"
									onChange={handleChangeCity}
									value={selectValue?.city}
									showLimit={500}
									empty={T('select-city')}
									list={ClientsStore.getCityList}
									_filters={filterData}
									async={true}
								/>
							</Field>
						</Columns.Column>
						<Columns.Column>
							<Field>
								<SelectComponent
									name="postoffice"
									label="post-office-number"
									onChange={handleChangeSelect}
									value={selectValue?.postoffice}
									limitShow={100}
									empty={T('select-post-office-number')}
									list={() => postOfficeList}
									async={true}
								/>
							</Field>
						</Columns.Column>
					</Columns>
				</>}
				<ActionsModal
					onClose={onClose}
					isEdit={!!senderID && !firstInit}
					isActive={is_active}
					onDelete={() => confirmDelete(handleDeleteIntegration)}
					onSubmit={submit}
					waiting={waiting}/>
			</Box>}
			{submitOK && <ModalItsOK onClose={onClose} title={submitOK}/>}
		</>
	);
};

export default connectToStores(NovaPoshta, {client: ClientsStore});