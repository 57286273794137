import React from 'react'
import SelectComponent from "../../../tools/select-component";
import HistoryStore from "../history-store";
import UserStore from "../../../user/user-store";

const StockFilters = ({onChange, value}) => {
	const handleChangeValue = (value, metaInfo) => {
		onChange(metaInfo.name, value);
	}

	return (
		<>
			<SelectComponent
				name='status'
				value={value['status']}
				empty='all'
				label='status'
				onChange={handleChangeValue}
				list={HistoryStore.getStatusMarketplacesForSelect}
			/>
			<SelectComponent
				name='channel'
				value={value['channel']}
				empty='all'
				label='integration'
				onChange={handleChangeValue}
				list={UserStore.getIntegrationListWithChannelName}
			/>
		</>
	);
};

export default StockFilters;