import React, { useEffect, useState } from "react";
import DynamicTabList from "../../components/dynamic-tab-list/dynamic-tab-list";
import { useParams } from "react-router-dom";
import ProductsReport from "./products/products";
import { connectToStores } from "../../tools/reflux-tools";
import SalesStore from "../sales/sales-store";
import Employees from "./employees/employees";
import UserStore from "../../user/user-store";
import ProductsStore from "../products/products-store";
import FinanceCategory from "./finance-category/finance-category";
import User from "../../user/user-store";
import ExpensesStore from "../expenses/expenses-store";
import GeneralReport from "./general/general-report";

const Reports = () => {
  const { tabs } = useParams();
  const [isActiveTab, setIsActiveTab] = useState(tabs || "general");

  const handleChangeTab = (tab) => {
    setIsActiveTab(tab);
  };

  useEffect(() => {
    setIsActiveTab(tabs || "general");
  }, [tabs]);

  const subUsers = UserStore.getSubUsersDataList().length;
  const enablePayments = User.getModuleEnable("payments");

  let defaultTabs = [
    { label: "general", value: "general", url: "" },
    { label: "products", value: "products", url: "products" },
    { label: "employees", value: "employees", url: "employees" },
    {
      label: "finance-category",
      value: "finance-category",
      url: "finance-category",
    },
  ];
  if (!subUsers) {
    defaultTabs = defaultTabs.filter((item) => item.value !== "employees");
  }

  if (!enablePayments) {
    defaultTabs = defaultTabs.filter(
      (item) => item.value !== "finance-category"
    );
  }

  useEffect(() => {
    return () => {
      ExpensesStore.clearFilter()
    }
  }, []);

  return (
    <div className="reports-page">
      <DynamicTabList
        list={defaultTabs}
        onChangeTab={handleChangeTab}
        defaultTab={isActiveTab}
      />
      {isActiveTab === "general" && <GeneralReport />}
      {isActiveTab === "products" && <ProductsReport />}
      {isActiveTab === "employees" && !!subUsers && <Employees />}
      {isActiveTab === "finance-category" && enablePayments && (
        <FinanceCategory />
      )}
    </div>
  );
};

export default connectToStores(Reports, {
  _: SalesStore,
  user: UserStore,
  product: ProductsStore,
  expenses: ExpensesStore
});
