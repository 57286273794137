import React, { Component } from 'react';

class Investors extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render () {
        return (
			<h1> Investors Modules. Раздел в разработке</h1>
			)}

      }

export default Investors;