import React, {useEffect, useState} from 'react'
import IntegrationControlHook from "../common/integration-control.hook";
import T from "../../../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import Box from "react-bulma-components/lib/components/box";
import InputsGroupField from "../common/inputs-group-field";
import ActionsModal from "../common/actions-modal";
import UserActions from "../../../../../user/user-actions";
import {errorHandler} from "../../../../../tools/error-handler";
import ClientsActions from "../../../../clients/clients-actions";
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import {confirmDelete} from "../../../../../tools/confirm-delete";
import ModalItsOK from "../../../../../components/modal-its-ok";
import {isMinValue, isValidKeys, parseObj} from "../utils";
import isValidPhone from "../../../../../tools/valid-phone";

const DATA_INPUT_STEP_ZERO = [
	{
		name: "api_key",
		placeholder: "up_key-placeholder",
		label: 'BEARER eCom'
	},
	{
		name: "api_pass",
		placeholder: "up_token-placeholder",
		label: "COUNTERPARTY TOKEN"
	}
]

const DATA_INPUT_NEXT_STEP = [
	{
		name: "phone",
		placeholder: "client-phone-placeholder",
		label: "phone"
	},
	{
		name: "name",
		placeholder: "client-name-placeholder",
		label: 'client-name-placeholder'
	},
	{
		name: "postcode",
		placeholder: "postcode-placeholder",
		label: "postcode-placeholder"
	},
	{
		name: "tin",
		placeholder: "tin-placeholder",
		label: "tin-placeholder"
	},
	{
		name: "bankId",
		placeholder: "IBAN",
		label: "IBAN"
	}
]

const UkrPoshta = ({data, wooID, onClose}) => {
	const {id, api_key, api_pass, is_active, ident} = data

	let identParse = typeof ident === 'string' ? parseObj(ident) : ident

	const {
		inputValues,
		handleChangeInputValues,
		errorMessage,
		handleAutoRemoveError,
		setErrorMessage,
		selectValue,
	} = IntegrationControlHook({
		inputDefaultValue: {
			api_key: api_key || '',
			api_pass: api_pass || '',
			name: identParse?.name || '',
			postcode: identParse?.postcode || '',
			phone: identParse?.phone || '+380',
			tin: identParse?.tin || '',
			bankId: identParse?.bankId
		},
	})

	const [waiting, setWaiting] = useState(false)
	const [senderID, setSenderID] = useState(id)
	const [submitOK, setSubmitOK] = useState('')

	useEffect(() => {
		const listenAdd = UserActions.updateIntegrations.completed.listen(({sender, error}) => {
			setWaiting(false)
			if(error) {
				handleAutoRemoveError(error)
			} else {
				setSenderID(sender)
				if(api_key && api_key !== inputValues.api_key) {
					setSubmitOK('add-integration-completed-title')
				}
			}
		});

		const listenAddFailed = UserActions.updateIntegrations.failed.listen(res => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		const listenDelete = UserActions.deleteIntegration.completed.listen(() => {
			setWaiting(false)
			setSubmitOK('delete-integration-completed-title')
		})

		const listenDeleteFailed = UserActions.deleteIntegration.failed.listen(res => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		const listenDeliveryAdd = ClientsActions.addDeliveryService.completed.listen(() => {
			if(api_key !== inputValues.api_key) {
				handleSubmitIntegration({})
			} else {
				setWaiting(false)
				setSubmitOK('add-integration-completed-title')
			}
		})

		const listenDeliverAddFailed = ClientsActions.addDeliveryService.failed.listen(res => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		return () => {
			listenAdd()
			listenAddFailed()
			listenDeliveryAdd()
			listenDeliverAddFailed()
			listenDelete()
			listenDeleteFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectValue, inputValues]);

	const handleError = () => {
		let errorMessage

		if(!isValidKeys(inputValues, ['api_key'], 36)) {
			errorMessage = 'wrong-key'
		}

		if(errorMessage) {
			handleAutoRemoveError(errorMessage)
			return false
		}

		return true
	}
	const handleErrorDelivery = () => {
		let errorMessage

		if(!isValidPhone(inputValues?.phone))  {
			errorMessage = 'wrong-phone'
		}

		if(isMinValue(inputValues, ['postcode', 'tin'], 5)){
			errorMessage = 'not all fields required'
		}

		if(isMinValue(inputValues, ['name'], 2)){
			errorMessage = 'not all fields required'
		}



		if(errorMessage) {
			handleAutoRemoveError(errorMessage)
			return false
		}

		return  true
	}

	const handleSubmitIntegration = ({deactivate}) => {
		const newData = {
			is_new: true,
			name: T("new-sender-label"),
			integration_id: wooID,
			delivery: wooID,
			api_key: inputValues.api_key,
			api_pass: inputValues.api_pass,
			bankId: inputValues.bankId
		}

		if(api_key !== inputValues.api_key) data.api_key = inputValues.api_key
		if(deactivate) data.is_active = 0
		if(handleError()) {
			setWaiting(true)
			UserActions.updateIntegrations(id ? data : newData)

			if(deactivate) onClose()
		}
	}



	const handleSubmitDelivery = ({deactivate}) => {
		if(deactivate) {
			handleSubmitIntegration({deactivate})
		} else {
			const deliveryData = {
				did: wooID,
				cid: data.client_id || null,
				name: inputValues?.name || "",
				phone: inputValues?.phone || '+380',
				postcode: inputValues?.postcode || '',
				region: inputValues?.region || '',
				city: inputValues?.city || '',
				district: inputValues?.district || '',
				street: inputValues?.street || '',
				houseNumber: inputValues?.houseNumber || '',
				apartmentNumber: inputValues?.apartmentNumber || '',
				tin: inputValues?.tin || '',
				bankId: inputValues?.bankId || null
			}

			if(senderID) deliveryData.sender = senderID
			if(handleError() && handleErrorDelivery()) {
				setWaiting(true)
				ClientsActions.addDeliveryService(deliveryData)
			}
		}

	}

	const handleDeleteIntegration = () => {
		if(id || senderID) {
			setWaiting(true)
			UserActions.deleteIntegration(id || senderID)
		}
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!submitOK && <Box>
				<Heading size={3}>{T('setting-integration', [T(`delivery-${wooID}`)])}</Heading>
				<InputsGroupField
					dataInput={DATA_INPUT_STEP_ZERO}
					inputValues={inputValues}
					onChange={handleChangeInputValues}
				/>
				{senderID && (
					<>
						<hr/>
							<InputsGroupField
								dataInput={DATA_INPUT_NEXT_STEP}
								inputValues={inputValues}
								onChange={handleChangeInputValues}
							/>
					</>
					)}
					<ActionsModal
						waiting={waiting}
						isEdit={!!id}
						onClose={onClose}
						isActive={is_active}
						onDelete={() => confirmDelete(handleDeleteIntegration)}
						onSubmit={senderID ? handleSubmitDelivery : handleSubmitIntegration}
				/>
			</Box>}
			{submitOK && <ModalItsOK onClose={onClose} title={submitOK}/>}
		</>
	);
};

export default UkrPoshta;