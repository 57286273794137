import React, {useState} from "react";
import Reports from "../../../../components/reports";
import T from "../../../../components/i18n";
import RowDesktop from "./row-desktop";
import { Button } from "react-bulma-components";
import AppStore from "../../../../app-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCategoryModal from "../../../products/add-category-modal";
import ProductsStore from "../../../products/products-store";
import RowMobile from "./row-mobile";
import usePaginationHook from "../../../../tools/pagination-hook";
import EmptyMessage from "../common/empty-message";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";
import SearchString
  from "../../../../components/base-filter-layout/search-string";
import {filterSearch} from "../../../../tools/filters-helper";
import {LocalIcon} from 'whitelables/wl-name/icons'
import ProductsActions from "../../../products/products-actions";

const COLUMNS = ["name", "reports-actions"];

const ProductCategory = () => {
  const {isMobileSize} = useIsMobile()
  const [search, setSearch] = useState('')
  const [refreshDisabled, setRefreshDisabled] = useState(false)

  const handleChangeSearch = ({name, value}) => {
    if(name) {
      setSearch(value)
    }
  }

  const getCategory = ProductsStore.getFormattingCategoryList();

  const withFilter = getCategory.filter(item => {
    const searchData = {
      name: item.name.trim('')
    }
    return filterSearch(search,['name'], searchData)
  })

  const { start, end, page, total, setPage, PaginationBlock } =
    usePaginationHook({ listLength: withFilter?.length, perPage: 50 });


  const handleRefresh = () => {
    ProductsActions.loadCategory()
    setRefreshDisabled(true)
    setTimeout(() => setRefreshDisabled(false), 3000)
  }

  return (
    <>
      <div className="margin-top-10 margin-bottom-10 display-flex-row-gap">
        <div className="display-flex-row">
          <Button className="sb" rounded onClick={handleRefresh} disabled={refreshDisabled}>
            <LocalIcon icon='refresh' size='small'/>
          </Button>
          <SearchString defaultValue={search} onChange={handleChangeSearch}/>
        </div>
        <Button
          color="info"
          onClick={() =>
            AppStore.openModal(<AddCategoryModal modal="add-category" />)
          }
          className="display-flex-row"
        >
          <FontAwesomeIcon icon="plus-circle" />
          {T("add-category-btn-label")}
        </Button>
      </div>
      <PaginationBlock
        totalPage={total}
        setPage={setPage}
        currentPage={page}
        showPrevNext={false}
      />
      <Reports
        emptyMessage={EmptyMessage}
        rows={withFilter?.slice(start, end) || []}
        columns={COLUMNS}
      >
        {isMobileSize ? <RowMobile role="row" /> : <RowDesktop role="row" />}
      </Reports>
      <PaginationBlock
        totalPage={total}
        setPage={setPage}
        currentPage={page}
        showPrevNext={false}
      />
    </>
  );
};

export default ProductCategory;
