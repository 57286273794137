import { createActions } from "tools/reflux-tools";

const OrdersActions = createActions({

    load: { asyncResult: true },
    updateStatusOrder: { asyncResult: true },
    refreshOrders: { asyncResult: true },
    delete: { asyncResult: true },
    addStatus: { asyncResult: true },
    deleteStatus: { asyncResult: true },
    loadStatus: { asyncResult: true },
    binding: { asyncResult: true },
    deleteBinding: {asyncResult: true},
    filter: { sync: true },
    loadReserveWaiting: {asyncResult: true},
    deleteSuperfluousReserv: {asyncResult: true},
    addOrder: {asyncResult: true},
    loadRefund: { asyncResult: true },
    deleteReserve: { asyncResult: true },
    manualPutProduct: { asyncResult: true },
});


export default OrdersActions;