import React, { Component } from 'react';
import T from "components/i18n";
import { Field, Control, Input, Label, Radio } from 'react-bulma-components/lib/components/form';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Actions from './expenses-actions'
import InfoWrapper from "../../components/info-wrapper";
import Messages from "../../components/messages";
import {connectToStores} from "../../tools/reflux-tools";
import ExpensesStore from "./expenses-store";


class AddExpensesCategoryModal extends Component {
    constructor(props) {
        super(props);

        let changeName = props.name

        if(changeName?.includes(':')) {
          const indexDeleteAdditionField = changeName.indexOf(':')
          changeName = changeName.slice(indexDeleteAdditionField + 2)
        }

        this.state = {
          categoryID: props['id'] || 0,
          name: {
                error: "",
                value: changeName || ''
          },
          types: {
                error: "",
                value: "0"
          },
          is_receipt: {
            error: "",
            value: props['is_receipt']?.toString() || this.props.defaultValue || "1"
          },
          is_profit: {
            error: "",
            value: props['is_profit']?.toString() || "0"
          },
          close: false,
          waiting: false,
          isEdit: props.isEdit || false,
          isDisabledReceipt: props.isDisabledReceipt || props.isEdit
        };

        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentWillUnmount() {
        if (typeof(this.addCategory) === "function") {
          this.addCategory();
        }

        if (typeof(this.addCategoryFail) === "function") {
          this.addCategoryFail();
        }
    }

    componentDidMount() {
        const {onClose,onSubmit} = this.props;

        this.addCategory = Actions.addCategory.completed.listen((data) => {
          this.setState({
            'waiting': false
          });
          if(onSubmit) {
            onSubmit(data, this.state.is_receipt.value)
          }
          if (typeof(onClose) == "function") {
            onClose();
          }

        });

        this.addCategoryFail = Actions.addCategory.failed.listen((res) => {
          const data = JSON.parse(res['response']);
          this.setState({
            'waiting': false,
            'message': {
              'msg': T(data['error'])
            }
          })
        });
    }

  submit() {
      this.setState({"waiting": true});


      const name = this.state.name.value,
            types = this.state.types.value,
            is_receipt = this.state.is_receipt.value,
            is_profit = this.state.is_profit.value;


      const data = {
        name,
        is_receipt,
        types,
        id: this.state.categoryID,
      }

      if(this.state.is_receipt.value === '0') {
        data.is_profit = is_profit
      }

      if (!name) {
        this.setState({
          'message': {
            'msg': T('not all fields required')
          }
        })

        return false;
      }

      Actions.addCategory(data);
  }

    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: {
            'value': value
          }
        });
    }

    renderHeader() {
      return (
        <Field>
          <Control>
            <Label>{T('category-name')}</Label>
            <Input  size="large"
                    name="name"
                    type="text"
                    placeholder={T('category-name-placeholder')}
                    onChange={this.onChange}
                    color={(this.state.name.error) ? "danger" : "primary"}
                    value={this.state.name.value}
            />
          </Control>
        </Field>
      )
    }

    renderExpensesItems() {
      return (
        <Field>
          <Label>{T("expense-category")}</Label>
          <div className='display-flex-column-gap'>
            <Radio
              onChange={this.onChange}
              checked={this.state.is_profit.value === '0'}
              value="0"
              size="large"
              disabled={this.props.disabledProfit}
              name="is_profit"
            >
              {T("normal-consumption")}
            </Radio>
            <InfoWrapper info={T('profit-on-sales-info')}>
                <Radio
                onChange={this.onChange}
                checked={this.state.is_profit.value === '1'}
                value="1"
                size="large"
                disabled={this.props.disabledProfit}
                name="is_profit"
              >
                {T("profit-on-sales")}
              </Radio>
            </InfoWrapper>
            <InfoWrapper info={T('not-to-be-income-info')}>
                <Radio
                  onChange={this.onChange}
                  checked={this.state.is_profit.value === '2'}
                  value="2"
                  size="large"
                  disabled={this.props.disabledProfit}
                  name="is_profit"
                >
                  {T("not-to-be-income")}
                </Radio>
            </InfoWrapper>
          </div>
      </Field>)
    }

    renderReceiptCategory () {
      return (
        <Field>
          <Control>
            <Radio
              onChange={this.onChange}
              checked={this.state.is_receipt.value === '1'}
              value="1"
              size="large"
              name="is_receipt"
              disabled={this.state.isDisabledReceipt}
            >
              {T("enrollment")}
            </Radio>
            <Radio
              onChange={this.onChange}
              checked={this.state.is_receipt.value === '0'}
              value="0"
              size="large"
              name="is_receipt"
              disabled={this.state.isDisabledReceipt}
            >
              {T("reports-name")}
            </Radio>
          </Control>
        </Field>
      )
    }



    render () {
        return (
            <>
              <Messages message={this.state.message} close={() => this.setState({message: null})} />
              <Box>
                {this.renderHeader()}
                {this.renderReceiptCategory()}
                {this.state.is_receipt.value === '0' && this.renderExpensesItems()}
                <Button.Group>
                  <Button fullwidth size="large" rounded color="primary"
                    onClick={this.submit}
                    >{this.state.isEdit ? T('edit-category-btn-label') : T('add-category-btn-label')}</Button>
                </Button.Group>
              </Box>
            </>
        );
    }

}

export default  connectToStores(AddExpensesCategoryModal, {
    expenses: ExpensesStore,
  });