import React, {useEffect, useState} from "react";
import {
  Checkbox,
  Control,
  Field,
} from "react-bulma-components/lib/components/form";
import T from "../../../components/i18n";
import { Link } from "react-router-dom";
import AppStore from "../../../app-store";
import { LocalIcon } from "whitelables/wl-name/icons";
import PriceTagSettingAttrFieldModal from "./components/price-tag-setting-attr-field-modal";

const PriceTagAttrField = ({ name, active, onSubmit }) => {
  const [checkedValue, setCheckedValue] = useState(!!active);

  const handleChangeCheckbox = (evt) => {
    const checked = evt.target.checked;
    setCheckedValue(checked);
    if(active?.length && checked) {
      onSubmit({attr: active})
    } else {
      onSubmit({attr: checked})
    }
  };

  const handleChange = (value) => {
    setCheckedValue(!!value)
    onSubmit({attr: value})
  }

  useEffect(() => {
    onSubmit({attr: active})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Field className="display-flex-row-gap">
      <Control>
        <Checkbox
          name={name}
          onChange={handleChangeCheckbox}
          className="termo_print"
          checked={checkedValue}
        >
          {T("attr")}
        </Checkbox>
      </Control>
      <Link
        className="link-icon-centred"
        to="#"
        onClick={() => AppStore.openModal(<PriceTagSettingAttrFieldModal onSubmit={handleChange} active={checkedValue} activeList={active}/>)}
      >
        <LocalIcon size="small" icon="gear" />
      </Link>
    </Field>
  );
};

export default PriceTagAttrField;
