import React, { useState } from "react";
import { Box, Button, Heading } from "react-bulma-components";
import T from "../../../../components/i18n";
import SocialInput from "./social-input";
import {
	Field,
	Label
} from "react-bulma-components/lib/components/form";
import UserStore from "../../../../user/user-store";
import DatePicker from "react-datepicker/es";

const AdditionalInfoClient = ({ socials, socialChange,dateValue, onChangeDate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAddSocial = (name) => {
    if (socialChange) {
      const newDataSocials = { ...socials };

      if (newDataSocials[name]) {
        newDataSocials[name].value.push("");
      }
      socialChange(newDataSocials);
    }
  };

  const handleDeleteSocial = (name, index) => {
    if (socialChange) {
      const newDataSocials = { ...socials };

      if (newDataSocials[name]) {
        newDataSocials[name].value.splice(index, 1);
      }
      socialChange(newDataSocials);
    }
  };

	const handleChangeValue = (name, index, value) => {
		if (socialChange) {
			const newDataSocials = { ...socials };

			if (newDataSocials[name]) {
				newDataSocials[name].value[index] = value;
			}
			socialChange(newDataSocials);
		}
	}

	const socialsData = Object.entries(socials)

	const handleChangeDate = (value, name) => {
		onChangeDate(value, name);
	}

  return (
    <Box className="margin-top-10">
      <div className="display-flex-row-gap ">
        <Heading style={{ margin: 0 }}>{T("more-info")}</Heading>
        <Button
          color="warning"
          size="small"
          onClick={() => setIsOpen((prev) => !prev)}
          rounded
        >
          {isOpen ? T("hide-more-filter") : T("unfold")}
        </Button>
      </div>
      {isOpen && (
        <div
          className="margin-top-10"
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}
        >
						<Field>
							<Label>{T('client-birth_day_child_1')}</Label>
							<div className='display-flex-row date-picker-full-width'>
								<DatePicker
									name="birth_day_child_1"
									selected={dateValue.birth_day_child_1}
									onChange={(date) => handleChangeDate(date, 'birth_day_child_1')}
									maxDate={new Date()}
									className="input"
									dateFormat="dd-MM-yyyy"
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									locale={UserStore.getLang()}
								/>
								<div style={{width: 13}}/>
							</div>
						</Field>
					<div>
						<Label>{T('client-birth_day_child_2')}</Label>
						<div className='display-flex-row date-picker-full-width'>
							<DatePicker
								name="birth_day_child_2"
								selected={dateValue.birth_day_child_2}
								onChange={(date) => handleChangeDate(date, 'birth_day_child_2')}
								maxDate={new Date()}
								className="input"
								dateFormat="dd-MM-yyyy"
								peekNextMonth
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								locale={UserStore.getLang()}
							/>
							<div style={{width: 13}}/>
						</div>
						</div>
					{socialsData.map(([key, social]) => {
            return (
              <div key={key} className="display-flex-column" style={{ gap: 5 }}>
                {social.value.map((item, index) => (
                  <SocialInput
										is_global={social.is_global}
                    key={`${key}-${index}`}
                    name={key}
                    label={social.label}
                    value={item}
                    index={index}
										type={social.type}
										info={social.info}
                    disabledDelete={social.value.length > 1}
										onChangeValue={handleChangeValue}
										onDeleteSocial={() => handleDeleteSocial(key, index)}
                  />
                ))}
                <div align='right' style={{ paddingRight: 6 }}>
                  <Button
                    size="small"
                    className="button-link-without-style"
                    rounded
                    onClick={() => handleAddSocial(key)}
                  >
                    {T("add-field")}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
			{/*<div>*/}
			{/*		<Label size='large'>{T('consumer-key')}</Label>*/}
			{/*	<div className='display-flex-row'>*/}
			{/*		<Input value={customField} onChange={handleChangeCustomField}/>*/}
			{/*		{!!customField &&<FontAwesomeIcon icon='plus-circle' className="text-success cursor-pointer" size='2x'*/}
			{/*											onClick={handleAddCustomField}/>}*/}
			{/*	</div>*/}
			{/*</div>*/}
    </Box>
  );
};

export default AdditionalInfoClient;
