import React, { Component } from 'react';
// import Reflux from 'reflux';
import T from 'components/i18n';
    
import { Link } from "react-router-dom";

import ProductsStore from 'modules/products/products-store';
import UserStore from 'user/user-store';
import Actions from 'user/user-actions';
import Button from 'react-bulma-components/lib/components/button';

import Messages from 'components/messages';
import AppStore from "../../../app-store";
import PriceTagModal from "../price-tag-modal/price-tag-modal";

class PriceTagsInfo extends Component {
    constructor(props) {
        super(props);


        const pr = ProductsStore.getList();

        this.first_product = "1_1";
        if (pr && pr.length > 0) {
          let f = pr[0],
            mid = Object.keys(f['stock'] || {})[0];
          this.first_product = ProductsStore.getIdent(f['id'], mid);
        }

        this.state = {
          update: false,
        };

    }

    componentDidMount() {
      Actions.updateSettings.completed.listen(() => {
          if (this.state.update) {
            this.setState({message: T('data-saved'),  update: false});
          }
      });
    }

    render () {
        return (
          <div>
            <Messages color="success" message={this.state.message} close={() => this.setState({message: null})} />
            <Button.Group position="right">
              <Button rounded
                      color="primary"
                      size="medium"
                      onClick={() => AppStore.openModal(<PriceTagModal defaultData={UserStore.getPriceTagsSetting()}/>)}>
                {T('price-tag-setting')}
              </Button>
              <Link  to={ProductsStore.getPrintTabLink([this.first_product])} target="_blank" title={T('print-tags')}>
                  <Button rounded 
                    color="warning"
                    size="medium"
                    onClick={() => {}}>{T('test-pricetags')}</Button>
              </Link>

            </Button.Group>
          </div>
      );
      
     }
};

export default  PriceTagsInfo;