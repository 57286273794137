import React from 'react'
import s from './styles.module.scss'
import T from '../i18n'
import SalesStore from "../../modules/sales/sales-store";

const DpkTag = ({orderID, status}) => {
	const isDpk = SalesStore.getDeliveredKeeperDateByOrderId(orderID)
	return isDpk && status === 'arrived' && (
		<span className={`${s.wrapper} ${isDpk ? s[isDpk] : ''}`}>
			{T(isDpk)}
		</span>
	);
};

export default DpkTag;