import React, { Component } from 'react';
import yajax from "yajax";
import T from 'components/i18n';
import UserActions from 'user/user-actions';
import UserStore from 'user/user-store';
import AppStore from 'app-store';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import FacebookLogin from 'react-facebook-login';
import trackEvent from 'tools/track-events';
import GoogleButton from "./google-button";
import { getTrafficSource } from "./trafic-source";
import {
    withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";


class SocialLogin extends Component {
    constructor(props) {
        super(props);
        this.responseFacebook = this.responseFacebook.bind(this);
    }

    responseFacebook(response) {
        const trafficData = getTrafficSource();

        const data = {
            ...response,
            ...trafficData
        };

        const params = {
            method: "POST",
            url: "/userprofile/social",
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            },
            data: {
                "lang": UserStore.getLang(), 
                "app": AppStore.isMobileApp() ? "app" : this.props.isMobileSize ? "mobile" : "desktop",
                "social_network": JSON.stringify(data)

            }
        };

        const partner_id = window.localStorage.getItem('partner_id');
        if (partner_id) {
            params.data['partner_id'] = partner_id;
        }

        return yajax(params).then((data) => {
            if (data.success) {
                if (data.first) {
                    trackEvent('users', 'registration', 'facebook', '1');
                    UserStore.setFirstReg(true);
                } else {
                    UserStore.setFirstReg(false);
                }
                UserActions.changeToken(data.token, true);
                window.localStorage.removeItem('partner_id');
            }
        });
    }

    render () {
        return (
            <Box align="center" style={this.props.disabledEffect ? {background: 'transparent', boxShadow: 'none'} : {}}>
                <Heading size={5}>{T('social-network-auth')}</Heading>

{/*                  <FacebookLogin
                                  appId={AppStore.isEnableForWhiteLable('facebook-app')}
                                  autoLoad={false}
                                  onChange={this.onChangeSimple}
                                  fields="name,email,picture"
                                  scope="pages_show_list,pages_messaging,pages_manage_metadata,business_management,instagram_manage_messages"
                                  callback={responseFacebook}
                                  icon="fa-facebook"
                                />*/}
                <div className='display-flex-row' style={{justifyContent: 'center', flexWrap: 'wrap'}}>
                    {AppStore.isEnableForWhiteLable('facebook-app') ? 
                    <FacebookLogin
                      appId={AppStore.isEnableForWhiteLable('facebook-app')}
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="email,pages_show_list,pages_messaging,pages_manage_metadata,business_management,instagram_manage_messages"
                      textButton={T('login-with-facebook')}
                      redirectUri={AppStore.formatingLink("/dashboard")}
                      cssClass="my-facebook-button-class"
                      icon="fa-facebook"
                      callback={this.responseFacebook}
                    /> : null}

                    <GoogleButton/>
                </div>
            </Box>
        )
    }
};

export default withIsMobileSize(SocialLogin);