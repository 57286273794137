import Reflux from 'reflux';

const WarehouseActions = Reflux.createActions({

    load: { asyncResult: true },
    addWarehouse: { asyncResult: true },
    deleteWarehouse: { asyncResult: true },
  
});


export default WarehouseActions;