import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";
import UserStore from 'user/user-store';
import AppStore from 'app-store';
// import AppActions from 'app-actions';

import T from "components/i18n";
import TarifBlock from "components/tarif-block";

import Hero from 'react-bulma-components/lib/components/hero';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import OpenModalButton from "components/modal/open-modal-button";
import Tile from 'react-bulma-components/lib/components/tile';
import Box from 'react-bulma-components/lib/components/box';
import Image from 'react-bulma-components/lib/components/image';
import Media from 'react-bulma-components/lib/components/media';
import Content from 'react-bulma-components/lib/components/content';
import Panel from 'react-bulma-components/lib/components/panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FooterBlock from '../common/footer'
import SocialLogin from 'modules/registration/social-login';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import AppBlock from '../common/app'
import FacebookReviewBlock from '../common/facebook-review'

import Player from "components/player/player";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";


class SmallProduction extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    render () {
        let name = "HugeProfit - "+T('small-prodiction'),
            offer_url = AppStore.getLink("/landing/accounting-software#price");

        return (
            <div className="shop-landing online-shop">
                <Helmet>
                    <title>{T('small-production-company')}</title>
                    <meta name="description" content={T('small-production-company') + " " + T('phone-number-number')}/>

                    <script type="application/ld+json">{`
                        {
                           "@context": "https://schema.org",
                           "@type": "SoftwareApplication",
                           "name": "${name}",
                           "description": "${T('desc')}",
                           "featureList": "${T('your-service-2')}, ${T('your-service-1')}, ${T('your-service-3')}, ${T('your-service-5')}, ${T('your-service-7')}, ${T('your-service-10')}",
                           "operatingSystem": "Browser, ANDROID, iOS",
                           "applicationCategory": "BusinessApplication",
                           "applicationSubCategory": "Accounting + finance",
                           "screenshot": "https://h-profit.com/img/hugeprofit-.webp",
                           "aggregateRating": {
                              "@type":"AggregateRating",
                              "ratingValue":4.8,
                              "reviewCount":197
                           },
                           "video": {
                              "@type":"VideoObject",
                              "description": "${T('desc')}",
                              "uploadDate": "2023-04-04",
                              "name": "${name}",
                              "thumbnailUrl": "https://h-profit.com/img/overflow.jpg",
                              "thumbnail": {
                                 "@type": "ImageObject",
                                  "author": "HugeProfit",
                                  "name": "${name}",
                                  "contentUrl": "https://h-profit.com/img/overflow.jpg",
                                  "thumbnailUrl": "https://h-profit.com/img/overflow.jpg",
                                  "datePublished": "2022-05-04"
                              },
                              "isFamilyFriendly": "True",
                              "url":"https://www.youtube.com/watch?v=MwfU_uujGS4",
                              "contentUrl":"https://www.youtube.com/watch?v=MwfU_uujGS4",
                              "embedUrl":"https://www.youtube.com/embed/MwfU_uujGS4"
                           },
                           "offers": {
                                "@type": "AggregateOffer",
                                "priceCurrency": "UAH",
                                "highPrice": "999",
                                "lowPrice": "0",
                                "offerCount": "5",
                                "offers": [
                                  {
                                    "@type": "Offer",
                                    "url": "https://h-profit.com${offer_url}"
                                  }
                                ]
                           }
                        }
                    `}
                    </script>

                </Helmet>
                <Hero gradient >
                  <Hero.Body>
                    <Container>
                        <Columns className="is-vcentered">
                            <Columns.Column>
                              <Heading size={1} className="uppercase">
                                {T('small-production-company')}
                              </Heading>
                              <Heading size={4} subtitle>
                                {T('production_desc')}
                              </Heading>
                              <Panel className="bullet">
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('tied-search-production')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('byy-component-production')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('main-landing-top-sub-0-1-online')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('main-landing-top-sub-2-1')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('main-landing-top-sub-3-1')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('main-landing-top-sub-4-1')}
                                    </Panel.Block>
                              </Panel>
                              <OpenModalButton link={AppStore.getLink("registration")} text={T('try-free')} size={this.props.isMobileSize ? "medium" : "large"} color="success"/>
                            </Columns.Column>
                            <Columns.Column>
                            <Link to="/"><img src="/img/hugeprofit-.webp" alt={T('hprofit-title')}border="0" /></Link>
                            </Columns.Column>
                        </Columns>
                    </Container>
                  </Hero.Body>
                </Hero>
                <Hero color="white" >
                  <Hero.Body className="our-service" id="services">
                    <Container>
                     <Player id="MwfU_uujGS4" imageSize="maxresdefault" styles={{
                        width: "100%",
                        height: this.props.isMobileSize ? "200px" : "515px"
                      }}/>
                    </Container>
                 </Hero.Body>
                 </Hero>

                <Hero color="white" >
                  <Hero.Body className="our-service" id="services">
                    <Container>
                        <Heading size={1}>
                                {T('four-title-production')}
                        </Heading>
                        <Columns>
                            <Columns.Column>
                                 <Media>
                                  <Media.Item renderAs="figure" position="left">
                                    <Image size={64} alt="64x64" src="/img/icons-3-12.svg" />
                                  </Media.Item>
                                  <Media.Item>
                                    <Content>
                                        <Heading size={5}>{T('your-service-2-online')}</Heading>
                                        <Heading subtitle size={6} renderAs="h2">{T('your-service-2-online-desk')}</Heading>
                                    </Content>
                                  </Media.Item>
                                 </Media>
                                 <Media>
                                  <Media.Item renderAs="figure" position="left">
                                    <Image size={64} alt="64x64" src="/img/icons-2-11.svg" />
                                  </Media.Item>
                                  <Media.Item>
                                    <Content>
                                        <Heading size={5}>{T('your-service-3-online')}</Heading>
                                        <Heading subtitle size={6} renderAs="h2">{T('your-service-3-desk')}</Heading>
                                    </Content>
                                  </Media.Item>
                                 </Media>
                                 <Media>
                                  <Media.Item renderAs="figure" position="left">
                                    <Image size={64} alt="64x64" src="/img/icons-2-11.svg" />
                                  </Media.Item>
                                  <Media.Item>
                                    <Content>
                                        <Heading size={5}>{T('your-service-10')}</Heading>
                                        <Heading subtitle size={6} renderAs="h2">{T('your-service-10-desk')}</Heading>
                                    </Content>
                                  </Media.Item>
                                 </Media>
                                <Media>
                                  <Media.Item renderAs="figure" position="left">
                                    <Image size={64} alt="64x64" src="/img/icons-1-10.svg" />
                                  </Media.Item>
                                  <Media.Item>
                                    <Content>
                                        <Heading size={5}>{T('your-service-1')}</Heading>
                                        <Heading subtitle size={6} renderAs="h2">{T('your-service-1-desk')}</Heading>
                                    </Content>
                                  </Media.Item>
                                 </Media>
                                 <Media>
                                  <Media.Item renderAs="figure" position="left">
                                    <Image size={64} alt="64x64" src="/img/icons-6-15.svg" />
                                  </Media.Item>
                                  <Media.Item>
                                    <Content>
                                        <Heading size={5}>{T('your-service-8')}<span className="soon">{T('soon')}</span></Heading>
                                        <Heading subtitle size={6} renderAs="h2">{T('your-service-8-desk')}</Heading>
                                    </Content>
                                  </Media.Item>
                                 </Media>
                            </Columns.Column>
                            <Columns.Column>
                                 <Media>
                                  <Media.Item renderAs="figure" position="left">
                                    <Image size={64} alt="64x64" src="/img/icons-4-13.svg" />
                                  </Media.Item>
                                  <Media.Item>
                                    <Content>
                                        <Heading size={5}>{T('your-service-5-shop')}</Heading>
                                        <Heading subtitle size={6} renderAs="h2">{T('your-service-5-desk-shop')}</Heading>
                                    </Content>
                                  </Media.Item>
                                 </Media>
                                 <Media>
                                  <Media.Item renderAs="figure" position="left">
                                    <Image size={64} alt="64x64" src="/img/icons-5-14.svg" />
                                  </Media.Item>
                                  <Media.Item>
                                    <Content>
                                        <Heading size={5}>{T('your-service-4')}</Heading>
                                        <Heading subtitle size={6} renderAs="h2">{T('your-service-4-desk')}</Heading>
                                    </Content>
                                  </Media.Item>
                                 </Media>
                                 <Media>
                                  <Media.Item renderAs="figure" position="left">
                                    <Image size={64} alt="64x64" src="/img/icons-6-15.svg" />
                                  </Media.Item>
                                  <Media.Item>
                                    <Content>
                                        <Heading size={5}>{T('your-service-6')}</Heading>
                                        <Heading subtitle size={6} renderAs="h2">{T('your-service-6-desk')}</Heading>
                                    </Content>
                                  </Media.Item>
                                 </Media>
                                 <Media>
                                  <Media.Item renderAs="figure" position="left">
                                    <Image size={64} alt="64x64" src="/img/icons-1-10.svg" />
                                  </Media.Item>
                                  <Media.Item>
                                    <Content>
                                        <Heading size={5}>{T('your-service-7')}</Heading>
                                        <Heading subtitle size={6} renderAs="h2">{T('your-service-7-desk')}</Heading>
                                    </Content>
                                  </Media.Item>
                                 </Media>
                                 <Media>
                                  <Media.Item renderAs="figure" position="left">
                                    <Image size={64} alt="64x64" src="/img/icons-6-15.svg" />
                                  </Media.Item>
                                  <Media.Item>
                                    <Content>
                                        <Heading size={5}>{T('your-service-9')}</Heading>
                                        <Heading subtitle size={6} renderAs="h2">{T('your-service-9-desk')}</Heading>
                                    </Content>
                                  </Media.Item>
                                 </Media>
                            </Columns.Column>
                        </Columns>
                       
                    </Container>
                  </Hero.Body>
                </Hero>
                <Section>
                <Hero>
                  <Hero.Body>
                    <Container>
                    <Box className="padding-top-bottom">
                        <Heading size={3}>
                            {T('second-block-title-production')}
                        </Heading>
                        <Tile kind="ancestor">
                            <Tile renderAs="article" kind="parent" >
                                <Box>
                                    <Image size="4by3" src="/img/hugeprofit/prod1.webp" />
                                    <Heading size={5} className="margin-top-10">{T('prod-need1')}</Heading>
                                </Box>
                            </Tile>
                            <Tile renderAs="article" kind="parent">
                                <Box>
                                    <Image size="4by3" src="/img/hugeprofit/prod2.webp" />
                                    <Heading size={5}  className="margin-top-10">{T('prod-need2')}</Heading>
                                </Box>
                            </Tile>
                            <Tile renderAs="article" kind="parent" >
                                <Box>
                                    <Image size="4by3" src="/img/hugeprofit/prod3.webp" />
                                    <Heading size={5}  className="margin-top-10">{T('prod-need3')}</Heading>
                                </Box>
                            </Tile>
                            <Tile renderAs="article" kind="parent" >
                                <Box>
                                    <Image size="4by3" src="/img/hugeprofit/prod4.webp" />
                                    <Heading size={5}  className="margin-top-10">{T('prod-need4')}</Heading>
                                </Box>
                            </Tile>
                        </Tile>
                        <Heading subtitle size={3}>
                                {T('second-block-subtitle')}
                        </Heading>
                    </Box>
                    </Container>
                  </Hero.Body>
                </Hero>
                </Section>
                <Hero className="free-tarif-block" gradient >
                  <Hero.Body>
                    <Container>
                             <Heading size={1}>
                                {T('free-tarif-production')}
                              </Heading>
                              <Heading subtitle size={3}>
                                {T('free-tarif-desk')}
                              </Heading>
                              <OpenModalButton link={AppStore.getLink("registration")} text={T('start-free')} size={this.props.isMobileSize ? "medium" : "large"}  color="success"/>
                    </Container>
                  </Hero.Body>
                </Hero>
                <Hero gradient >
                  <Hero.Body>
                    <Container>
                        <Columns className="is-vcentered">
                            <Columns.Column>
                                <Link to="/"><img src="/img/secure.png" alt={T('hprofit-title')}border="0" /></Link>
                            </Columns.Column>
                            <Columns.Column>
                              <Heading size={1} className="uppercase">
                                {T('secure-save')}
                              </Heading>
                              <Panel className="bullet">
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('secure-save-sub-1')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('secure-save-sub-2')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('secure-save-sub-3')}
                                    </Panel.Block>                                    
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('secure-save-sub-4')}
                                    </Panel.Block>
                              </Panel>
                            </Columns.Column>
                        </Columns>
                    </Container>
                  </Hero.Body>
                </Hero>
                <Section>
                  <Hero>
                  <Hero.Body>
                    <Container>
                    <Box className="padding-top-bottom our-clients">
                        <Heading size={1}>
                            {T('our-clients')}
                        </Heading>
                        <Tile kind="ancestor" className="feature-block">
                              <Tile renderAs="article" kind="child">
                                  <Content>
                                      <div className="very_big_amount">826 {T('k.')}+</div>
                                      <Heading size={4}>{T('many-products')}</Heading>
                                  </Content>
                              </Tile>
                              <Tile renderAs="article" kind="child">
                                  <Content>
                                      <div className="very_big_amount">322 {T('k.')}+</div>
                                      <Heading size={4}>{T('many-sales')}</Heading>
                                  </Content>
                              </Tile>
                        </Tile>
                        <Tile kind="ancestor" className="feature-block">
                              <Tile renderAs="article" kind="child">
                                  <Content>
                                      <div className="very_big_amount">1.2 {T('mlr')}+</div>
                                      <Heading size={4}>{T('many-sell')}</Heading>
                                  </Content>
                              </Tile>
                              <Tile renderAs="article" kind="child">
                                  <Content>
                                      <div className="very_big_amount">799 {T('mln')}+</div>
                                      <Heading size={4}>{T('many-profit')}</Heading>
                                  </Content>
                              </Tile>
                        </Tile>
                    </Box>
                  </Container>
                  </Hero.Body>
                </Hero>
                </Section>
                <Section>
                  <Hero gradient >
                  <Hero.Body>
                    <Container>
                      <Box className="padding-top-bottom">
                        <Columns className="is-vcentered">
                            <Columns.Column>
                              <Heading size={1} className="uppercase">
                                {T('support-team')}
                              </Heading>
                              <Panel className="bullet">
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('support-team-item-1')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('support-team-item-2')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('support-team-item-3')}
                                    </Panel.Block>                                    
                              </Panel>
                            </Columns.Column>
                            <Columns.Column>
                                <Link to="/"><img src="/img/support.png" alt={T('hprofit-title')}border="0" /></Link>
                            </Columns.Column>
                        </Columns>
                      </Box>
                    </Container>
                  </Hero.Body>
                </Hero>
              </Section>
              <AppBlock title='mobile-app-title-app'/>
              <FacebookReviewBlock />
                
                <Hero color="info" gradient className="tarif-plan" id="price">
                        <Hero.Body>
                          <Container>
                            <Heading size={3}>
                                {T('tarif-plan')}
                            </Heading>
                            <TarifBlock />
                    </Container>
                  </Hero.Body>
                </Hero>
                <Hero gradient>
                  <Hero.Body>
                    <Container>
                        <Columns className="is-vcentered">
                            <Columns.Column>
                              <Heading size={1}>
                                {T('try-free-last')}
                              </Heading>
                              <Heading size={4} subtitle>
                                {T('try-free-last-desk')}
                              </Heading>
                              <Panel className="bullet">
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('try-free-last-desk-1')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('try-free-last-desk-2')}
                                    </Panel.Block>
                                    <Panel.Block active>
                                        <Panel.Icon renderAs={FontAwesomeIcon} size="2x" icon={faCheckCircle} />
                                            {T('try-free-last-desk-3')}
                                    </Panel.Block>
                              </Panel>
                              <OpenModalButton link={AppStore.getLink("registration")} text={T('try-free')} size={this.props.isMobileSize ? "medium" : "large"} color="success"/>
                            </Columns.Column>
                            <Columns.Column>
                            <Link to="/"><img src="/img/hugeprofit-.webp" alt={T('hprofit-title')}border="0" /></Link>
                            </Columns.Column>
                        </Columns>
                    </Container>
                  </Hero.Body>
                </Hero>
                <Hero color="info"  className="">
                  <Hero.Body>
                    <Container>
                        <Box align="left">
                          <Content>
                            {T('home-text-h1-dest-production')}
                          </Content>
                        </Box>
                        <h1>{T('default-title', {name: AppStore.getAppName()})}</h1>
                    </Container>
                </Hero.Body>
                </Hero>
                <FooterBlock />
                <div className="is-hidden">
                  <SocialLogin />
                </div>
            </div>
			)}

      }

export default connectToStores(withIsMobileSize(SmallProduction), {
    users: UserStore,
  });