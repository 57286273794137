import React from 'react'
import NewProductVariable from "./desktop/new-product-variable";
import SingleRender from "./desktop/single-render";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import NewProductRowVariableMobile
	from "./mobile/new-product-row-variable-mobile";
import SingleRenderMobile from "./mobile/single-render-mobile";

const NewWrapperProductRow = ({data}) => {
	const {isMobileSize} = useIsMobile()
	if (Array.isArray(data)) {
		if(isMobileSize) {
			return <NewProductRowVariableMobile data={data}/>
		}
		return <NewProductVariable data={data}/>
	}

	if(isMobileSize) {
		return <SingleRenderMobile data={data}/>
	}
	return <SingleRender data={data}/>
};

export default NewWrapperProductRow;