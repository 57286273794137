import React, { Component } from 'react';

import Content from 'react-bulma-components/lib/components/content';
import Section from 'react-bulma-components/lib/components/section';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import FooterBlock from '../common/footer'
import { backLink } from 'tools/back-link' 

class AgreementEN extends Component {
    render () {
        return (
            <Section>
            {backLink()}
            <Box>
            <Content align="left">
                <Heading> Term of Use</Heading>
                <div align = "right"> September 1, 2020</div>

                <p> The website https://h-profit.com (hereinafter - the Service) is owned by FOP "Piddubny Oleksiy Yuriyovych", USREOU code 3195405297 (hereinafter - the Company), offers to any individual / legal entity / entrepreneur, hereinafter referred to as The "User", hereinafter referred to as the "Parties", and separately - the "Party", enter into this Public Agreement, set out in standard and unchangeable for all forms in the form of a public offer, by accepting in general and unconditionally the following conditions:</p>

                <p> Terms used in the contract and their meaning: </ p>
                <ul>
<li> a) Service and Appliction - "HugeProfit" software, designed for simple warehousing and financial accounting. The exclusive rights to the Service belong to the Company. The transfer of exclusive rights (in whole or in part) is not the subject of this Agreement. Description of the properties of the Service is available to the User on the website of the service at h-profit.com.</li> 
<li> b) Tariffs - the price list of the Company, located on the Internet at https://h-profit.com/pricing/.</li> 
<li> c) Selected Tariff - Tariff selected and paid by the User from the Tariffs section, which regulates the scope of services provided to the User.</li> 
<li> d) The Company's website is a website (Service) located on the Internet at h-profit.com.</li> 
<li> e) Account - a method of recording statistical, accounting and other information that is necessary to identify the User for the purpose of this Agreement. The account is linked to information related to the User, including information specified during registration, settings for working with the Service, data entered by the User into the Service.</li> 
<li> f) Login and Password - a unique set of characters created by the User during registration, intended for access to the Service.</li> 
<li> g) Accounting period - a period of time not less than one calendar month for which access to the service is paid.</li> 
<li> h) Server - a physical server designed to install the Service.</li> 
<li> i) Auto-renewal of subscription - service of automatic renewal of the Selected Tariff.</li> 
</ul>


<Heading subtitle size={5}> 1.Subject of the contract </Heading>
<p>1.1. In accordance with the terms of this Agreement, the Company provides the User with access to the Service, and the User undertakes to use the said Service under the terms specified in this Agreement and pay for access to the Service in the manner prescribed by the Agreement.</p>

<p>1.2. Registration of the User in the Company's Service is considered to be full and unconditional acceptance of the terms of this Agreement. If the User does not agree with the terms of this Agreement, he must not use the Service.</p>

<p>1.3. The territory of the granted rights - all countries of the world.</p>

<p>1.4. The current version of the Agreement is always available on the Company's website at https://h-profit.com/en/agreement/.</p>

<Heading subtitle size={5}> 2. Rights and obligations of the parties </Heading>
<p>2.1. The company has the right to:</p>

<p>2.1.1. receive from the User information on compliance with the terms of this Agreement and (or) information necessary for the implementation of this Agreement and additional agreements to it;</p>

<p>2.1.2. receive remuneration in accordance with the terms of this Agreement;</p>

<p>2.1.3. make changes to the Service, including publishing new versions of the Service, without prior notice to the User;</p>

<p>2.1.5. unilaterally terminate the Agreement and (or) block the User's access to the Service in cases of violation by the User of the legislation of Ukraine and (or) the terms of this Agreement;</p>

<p>2.1.6. to carry out preventive work on the Server, for which to temporarily suspend access to the Service;</p>

<p>2.1.7. delete the account and all data of the User after the end of the period specified in p.2.2.3. of this Agreement, or upon receipt of a request from the User to delete data, or in case of violation by the User of the terms of this Agreement.</p>
<Heading subtitle size={5}> 2.2. The company is obliged to:</Heading>
<p> 2.2.1. provide daily round-the-clock access to the Service, except for the cases specified in this Agreement;</p> 

<p> 2.2.2. to ensure the confidentiality of the User's data taking into account the current legislation of Ukraine;</p>

<p> 2.2.3. ensure the preservation of data entered by the User into the Service during the term of this Agreement and 1 (one) year from the date of termination of the Agreement, except as provided in this Agreement;</p>

<p> 2.2.4. within a reasonable time to eliminate malfunctions of the Service caused by the fault of the Company;</p>

<p> 2.2.5. to carry out preventive work if possible at night and on weekends;</p>

<p> 2.2.6. to back up the User's data and information.</p>
<p> 2.2.7. upon receipt of the request in accordance with paragraph 2.3.4. of this Agreement within 10 working days and within the technical ability of the Company to provide the User with the upload of his data. </ p>

<Heading subtitle size={5}> 2.3. The user has the right to: </Heading>
<p> 2.3.1. use the Service in accordance with the terms of the Selected Tariff and this Agreement;</p>

<p>2.3.2. receive daily and round-the-clock access to the Server, except for the cases specified in this Agreement;</p>

<p>2.3.3. at any time send a request to the Company to delete your account and all data entered into the Service.</p>
<Heading subtitle size={5}> 2.4. The user must: </Heading>
<p>2.4.1. Adhere to the restrictions on the use of the Service specified in this Agreement;</p>

<p>2.4.2. to pay remuneration according to the Selected Tariff of the Company in the amount and within the terms established by this Agreement and Tariffs;</p>

<p>2.4.3. ensure the confidentiality of the Login and Password;</p>

<p>2.4.4. immediately notify the Company of any unauthorized use of your Login and (or) Password or any other security breach by the User;</p>

<p>2.4.5. comply with the legislation on the protection of personal data processed through the Service (including obtaining consent for the processing of personal data);</p>

<p>2.4.6. periodically gets acquainted with the current version of this Agreement;</p>

<p>2.4.7. not to grant under any circumstances to any third party the right to use the Service (sublicense).</p>
<Heading subtitle size={5}> 3. The order and conditions of use of the program </Heading>
<p> 3.1. To start using the Service, the User fills in the registration forms on the Company's website.</p>

<p>3.1.1. In the process of filling in the registration forms, the User independently selects the Login and Password required for further access to the Service.</p>

<p>3.1.2. When registering, the User enters the following data: login, password, e-mail address and other information.</p>

<p>3.2. Transactions performed using a combination of Login and Password or the User's e-mail are considered to be actions performed by the User.</p>

<p>3.3. The rights to the Service are granted to the User for a period not exceeding 2 (two) working days from the moment of crediting funds to the Company's current account for a period equal to the accounting period.</p>

<p>3.3.1. In case of early payment of the Selected Tariff (taking into account the provisions of clause 4.7 of the Agreement) for the next accounting period, access to the Service is provided not earlier than the expiration of the prepayment.</p>

<p>3.4. The fact of providing the User with the Selected Tariff is confirmed by opening full access to the User to the Service in accordance with clause 3.3 of this Agreement.</p>

<p>3.5. The Company does not verify the information (data) provided by the User for the purpose of fulfilling this Agreement, is not responsible to any third parties for the accuracy and reliability of the data.</p>

<p>3.7. For technical support, the User may contact the contacts listed on the Company's Website at h-profit.com.</p>
<Heading subtitle size={5}> 4. Cost of the Selected Tariff, procedure of calculations and conditions of reimbursement </Heading>
<p> 4.1. The cost of the Selected Tariff

<p>4.1.1. The cost of the Selected Tariff is determined in accordance with the Company's Tariffs.</p>

<p>4.1.2. The Company has the right to change the cost of the Selected Tariff and the terms of change of tariff plans by publishing them in a new version on the Company's website at h-profit.com/pricing/. The new Tariffs shall enter into force on the date of their publication.</p>

<p>4.1.3. If the User continues to use the Service after the entry into force of the new Tariffs, it is considered that he has accepted such changes in the Tariffs and has agreed to continue cooperation in accordance with the new Tariffs.</p>
<p>4.1.4. In any case, the recalculation of the Selected Tariff for paid periods is not performed.</p>
<Heading subtitle size={5}> 4.2. Settlement procedure </Heading>
<p>4.2.1. Payment of the license fee is made in accordance with the selected Tariff, in advance payment in the amount of 100% of the amount of the selected tariff, not less than two working days before the desired start date of the Service.</p>
<p>4.2.2. Methods of payment are listed on the Company's website at https://h-profit.com/pricing/ and in the Service.</p>
<p>4.2.3. The moment of fulfillment of payment obligations by the User is considered to be the day of crediting funds to the Company's current account.</p>
<p>4.2.4. Users who pay for the Selected Tariff agree to the services of automatic regular extension of the Selected Tariff for a new term (auto-renewal of the Selected Tariff). After activating this service, payment of the new term of the Selected Tariff is made by automatic non-acceptance debiting of funds from the User's account. The Selected Tariff auto-renewal service is provided until the User cancels the subscription to the Selected Tariff in the Company's Service.</p>
<p>4.2.5. The Service is activated when making the first payment of the Selected Tariff, which means the User's consent that after the expiration of the paid term of the Selected Tariff the Agreement is automatically extended on the same terms for the same period with automatic debiting of the Selected Tariff from the User's account;</p>
<p>4.2.6. In case of insufficient funds on the User's account or impossibility to make a payment, the Selected Tariff for the new term is not provided.</p>
<p>4.2.7. If the Company changes the Tariffs, the auto-renewal of the Selected Tariff for the next new term following the period during which such change took place will be carried out at the new tariff.</p>
<p>4.2.8. All fees charged by credit institutions / other third parties upon payment shall be paid by the Paying Party.</p>
<Heading subtitle size={5}> 4.3. Terms of reimbursement </Heading>
4.3.1. The Company guarantees to the User the possibility of refunding the funds paid as payment for the Selected Tariff within 14 (fourteen) calendar days from the date of payment by sending an e-mail on early termination of this Agreement and refund of funds paid in advance from the registered e-mail to the User. e-mail of the Company h.info@h-profit.com. After 14 (fourteen) calendar days from the date of payment, no refund is made.</p>
<p>4.3.2.Company, in case of receipt of an official application stipulated in clause 4.3.1. The term of the Agreement undertakes to consider the said application and return the funds to the current account of the User from whom the funds were paid, within 30 (thirty) calendar days from the date of receipt of the letter. In cases when it is impossible to return to the account of the User from whom the funds were paid, the User indicates a new account in the letter of return of funds paid as an advance.</p>
<p>4.3.3. Refund means early termination of this Agreement.</p>
<Heading subtitle size={5}> 5. Restrictions </Heading>
<p>5.1. The Service may be used exclusively in the economic activities of the User, taking into account its functional purpose.</p>
<p>5.2. The user has no right to:</p>
<p>5.2.1. try to circumvent the technical limitations in the Service;</p>
<p>5.2.2. disclose technology, decompile or disassemble the Service;</p>
<p>5.2.3. use the components of the Service to call applications that do not work on this Service;</p>
<p>5.2.4. create copies of the Service;</p>
<p>5.2.5. publish the Service, allowing other users to copy it;</p>
<p>5.2.6. provide the Service for rent, lease or temporary use;</p>
<p>5.2.7. use the Service to provide network services on a commercial basis;</p>
<p>5.2.8. to use the Service or its parts, as well as any other online services of the company (API, online chat users) after the expiration of this Agreement, or its early termination;</p>
<p>5.2.9. make attempts to gain access to other Users' data.</p>
<p>5.3. In case of termination of this Agreement, the User undertakes to immediately terminate the operation of the Service and / or its parts, and the User's data in the Service are stored for 365 calendar days from the termination of the agreement or the end of the Deferral Period.</p>
<Heading subtitle size={5}> 6. Responsibility of the parties </Heading>
<p>6.1. The Parties shall be liable for non-performance and / or improper performance of obligations under this Agreement in accordance with the legislation of Ukraine.</p>
<p>6.2. The service is provided on an "as is" basis. The Company shall not be liable for any damages directly or indirectly related to the use, incorrect use or unsuitability of the Service by the User, loss or damage to data.</p>
<p>6.3. The Company's liability is always limited to the amount paid by the User.</p>
<Heading subtitle size={5}> 6.4. The company is not responsible for: </Heading>
<p>6.4.1. for poor performance of the Service (and damages) in the event that the User (or third parties) have made changes to the Service or such failures are caused by incompatibility of the Service with equipment or other software of the User;</p>
<p>6.4.2. for any actions of the User related to the use of the Service, documents created within the use of the Service;</p>
<p>6.4.3. for lack of access to the Service, when it is associated with the actions of the User, the User's access to the Internet, the actions of third parties, preventive work.</p>
<p>6.5. Use by the User of the Service in a manner not provided for in this Agreement, or after the termination of the Agreement, or otherwise outside the rights granted under this Agreement, which entails liability for violation of the exclusive right to intellectual property established by Ukrainian law and the Company for unilateral extrajudicial termination of this Agreement and termination of the License.</p>
<p>6.6. The Company is not responsible for restricting access to the Service in accordance with the provisions of this Agreement.</p>
<Heading subtitle size={5}> 7. Dispute resolution</Heading>
<p>7.1. The Parties will seek to resolve all possible disputes and disagreements that may arise under or in connection with this Agreement through negotiations.</p>
<p>7.2. Disputes that are not settled through negotiations are transferred to the relevant court of Kharkiv and are subject to resolution in the manner prescribed by the legislation of Ukraine and duly ratified international treaties.</p>
<p>7.3. The parties recognize the necessity of pre-trial claims before going to court.</p>
<p>7.4. The company is obliged to respond to the pre-trial claim within 30 (thirty) working days from the date of its receipt.</p>
<Heading subtitle size={5}> 8. Force majeure </Heading>
<p>8.1. In case of force majeure (force majeure), namely: war, hostilities, blockades, embargoes, fires, floods, other natural disasters or natural phenomena that prevent the Party from fulfilling its obligations under this Agreement, the Party, who has been affected by such circumstances, is released from liability for such non-performance and / or improper performance of its obligations under the Agreement for the duration of these circumstances.</p>
<p>8.2. The Party that is unable to fulfill the terms of the Agreement due to force majeure, must notify the other Party of the occurrence or termination of these circumstances no later than 2 (two) calendar days from their occurrence or termination, in writing. Untimely notification of the occurrence of force majeure deprives the injured Party of the right to refer to these circumstances in the future.</p>
<p>8.3. The onset and termination of force majeure shall be confirmed by a certificate issued by the Chamber of Commerce or other competent authority of the country of residence of the Party affected by force majeure.</p>
<p>8.4. In the event that these circumstances last for more than one accounting period in a row and affect the parties' performance of their obligations under this Agreement, each Party has the right to early termination of the Agreement, provided that it notifies the other Party no later than 10 (ten) calendar days before the expected date of termination.</p>
<Heading subtitle size={5}> 9. Confidentiality </Heading>
<p>9.1. The Parties undertake to keep confidential information provided by one Party to the other Party.</p>
<p>9.2. For the terms of this Agreement, the Parties have agreed on the following definitions: "Confidential information" means information, any scientific, technical, technological, industrial, legal, financial, economic or other information, including trade secrets and know-how that has a valid or potential commercial value due to its unknownness to third parties, it is not freely accessible on legal grounds, and the Party takes measures to protect its confidentiality.</p>
<p>9.3. The Parties undertake not to disclose Confidential Information to third parties, except in cases where Confidential Information may be disclosed with the prior written consent of the Party-owner of the information or in other cases provided by the legislation of Ukraine. In any case, the Parties shall notify each other of the need to disclose confidential information at the request of public authorities.</p>
<p>9.4. Neither Party to this Agreement shall seek, directly or indirectly, to contact or cooperate with a partner, consultant, counterparty, client of the other Party on issues related to the relationship between the Parties; except by acting through that other Party or obtaining written consent to such actions by that other Party, and shall not attempt to circumvent the other Party in any manner intended to circumvent the remuneration, income and / or profits to be received by each from the Parties as a result of the relationship between the Parties.</p>
<p>9.5. The Parties shall ensure that the personal data of their founders, employees representing the Parties or other representatives will be used by the Parties for decision-making and the conclusion between the Parties of any agreements, contracts, agreements and other documents aimed at taking legal action. consequences for the parties. The parties guarantee the confidentiality and security of personal data in accordance with the legislation of Ukraine.</p>
<Heading subtitle size={5}> 10. Procedure for concluding, term of validity, procedure for terminating the contract</Heading>
<p>10.1. This Agreement is concluded (accepted) by registering the User on the Company's server and payment by the User of the Tariff and is valid for the period selected by the User. The date of concluding the Agreement is the date of crediting funds to the Company's current account in the amount determined in accordance with the Tariffs.</p>
<p>10.2. The Offer (offer to enter into this Agreement) is valid indefinitely subject to the terms set forth in this offer - the License Agreement, until the Company terminates it or replaces this offer with a new version. Changing the terms of the offer does not entail changing the terms of this Agreement, if the User entered into it before the entry into force of such changes or its termination.</p>
<p>10.3. This Agreement applies to updates and new versions of the Service.</p>
<p>10.4. Except as expressly provided in this Agreement, the Agreement shall be terminated by mutual agreement of the Parties.</p>
<p>10.5. Deletion of the User's account automatically terminates this Agreement.</p>
<Heading subtitle size={5}> 11. Final provisions </Heading>
<p>11.1. This Agreement is mixed and contains elements of accession agreements, public contract.</p>
<p>11.2. The User confirms that, prior to the conclusion of this Agreement, he has read its terms, which are clear to him, and knowingly entered into this Agreement without coercion.</p>
<p>11.3. The user can not offer their terms, but can only join the offer.</p>
<p>11.4. All appendices and additional agreements to this Agreement are an integral part of it.</p>
<p>11.5. The Parties agree that all documents with facsimile reproduction of the signature by means of mechanical or other means of copying, electronic digital signature or other analogue of the handwritten signature shall have the same force as documents with the handwritten signature.</p>
<p>11.6. The Company reserves the right to amend this Agreement unilaterally. If the User does not agree with the changes, he is obliged within 5 working days from the date of change of the Agreement to terminate the use of the Service and terminate this Agreement unilaterally.</p>
<p>11.7. Details and contact details of the Company are specified in Section 12 of this Agreement.</p>
<p>11.8. Contact details of the User are indicated when filling in the registration forms, in the Personal Account in the Service or in another way agreed by the Parties. The user bears the risk of reporting incorrect (invalid) contact information.</p>
<Heading subtitle size={5}> 12. Company details</Heading>
<p>Private individual Piddubny Oleksiy Yuriyovych</p>
<p>Recipient code: 3195405297</p>
<p>Recipient's account in the format according to the IBAN standard UA023515330000026009052243590</p>
<p>Name of the bank</p>
<p>KHARKIV GROUP JSC CB "PRIVATBANK"</p>
<p>E-mail: h.info@h-profit.com</p>
            </Content>
            </Box>
            <FooterBlock />
            </Section>
            )}

}

export default AgreementEN;