import React from "react";
import List from "react-bulma-components/lib/components/list";
import AppStore from "../../../../app-store";
import LinkAccountToTerminalModal from "../modal/link-account-to-terminal-modal";
import TerminalRowList from "./terminal-row-list";

const TerminalContentMobile = ({ list }) => {


  const handleLinkAccountToTerminal = (terminalData) => {
    AppStore.openModal(
      <LinkAccountToTerminalModal
        modal="link-account-terminal"
        terminalData={terminalData}
      />
    );
  };

  return (
      <List className="terminal-list">
        {list?.map((item) => (
          <TerminalRowList
            key={item.id}
            data={item}
            onEdit={handleLinkAccountToTerminal}
          />
        ))}
      </List>
  );
};

export default TerminalContentMobile;
