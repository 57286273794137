import React, { useEffect, useState } from "react";
import T from "../../../components/i18n";
import { Heading, Box, Button, Columns } from "react-bulma-components";
import {
  Field,
  Input,
  Label,
} from "react-bulma-components/lib/components/form";
import SubmitButton from "../../../components/submit-button";
import ErrorMessageControlHook from "../../../components/error-message-block/error-message-control.hook";
import ProductionActions from "../production-actions";
import { errorHandler } from "../../../tools/error-handler";
import SelectComponent from "../../../tools/select-component";
import ProductionStore from "../production-store";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const AddProductionCategory = ({ name, parent_id, id, onClose }) => {
  const {isMobileSize} = useIsMobile()
  const {
    handleAutoRemoveError,
    errorMessage,
    ErrorMessageBlock,
    setErrorMessage,
  } = ErrorMessageControlHook();

  const [waiting, setWaiting] = useState(false);
  const [value, setValue] = useState(name?.replace(/—/g, "") || "");

  const defaultValueSelect = parent_id
    ? { value: parent_id, label: ProductionStore.getCategoryName(parent_id) }
    : {
        value: 0,
        label: T("root-cat"),
      };
  const [parentCategory, setParentCategory] = useState(defaultValueSelect);

  const handleChangeValue = ({ target: { value } }) => {
    setValue(value);
  };

  const handleSubmit = () => {
    if (value) {
      setWaiting(true);
      const data = {
        name: value,
      };
      if (parentCategory.value) {
        data.parent_id = parentCategory.value;
      }
      if (id) {
        data.id = id;
      }

      ProductionActions.addCategory(data);
    } else {
      handleAutoRemoveError("not all fields required");
    }
  };

  const handleChangeSelect = (selectedOption) => {
    setParentCategory(selectedOption);
  };

  useEffect(() => {
    const addCategory = ProductionActions.addCategory.completed.listen(() => {
      onClose();
    });
    const addCategoryFailed = ProductionActions.addCategory.failed.listen(
      (res) => {
        const message = errorHandler(res);
        setWaiting(false);
        handleAutoRemoveError(message);
      }
    );

    return () => {
      addCategory();
      addCategoryFailed();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parentCategories = ProductionStore.getCategoryListWithId(id);

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading>{T("add-production-category")}</Heading>
        <Columns>
          {!!parentCategories && !!parentCategories?.length && (
            <Columns.Column>
              <SelectComponent
                name="parent_id"
                label="parent_category"
                onChange={handleChangeSelect}
                empty={T("root-cat")}
                value={parentCategory}
                list={() => parentCategories}
              />
            </Columns.Column>
          )}
          <Columns.Column>
            <Field>
              <Label>{T("category-name")}</Label>
              <Input value={value} onChange={handleChangeValue} size="large" />
            </Field>
          </Columns.Column>
        </Columns>
        <div
          className="display-flex-row"
          style={{ justifyContent: "flex-end" }}
        >
          <SubmitButton
            text="save-btn-label"
            submit={handleSubmit}
            waiting={waiting}
            fullwidth={false}
            color="success"
            size={isMobileSize ? "small" : "medium"}
          />
          <Button
            onClick={onClose}
            size={isMobileSize ? "small" : "medium"}
            rounded
          >
            {T("close")}
          </Button>
        </div>
      </Box>
    </>
  );
};

export default AddProductionCategory;
