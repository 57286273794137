import React from "react";

export const getProductName = (prod) => {
	const pid = prod.id;

	let name = "";

	if (prod.asin && prod.asin !== "") name += prod.asin+". ";


	if (prod.sku  && prod.sku !== "") name += prod.sku+". ";


	if (prod.brand && prod.brand !== "") name += prod.brand+". ";

	name += prod.name;

	return  (
		<span>ID: {pid}. {name}</span>
	);

}