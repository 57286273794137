import React from 'react';
import T from "components/i18n";

import { Field, Control, Input, Label, Textarea } from 'react-bulma-components/lib/components/form';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Actions from './shipments-actions' 
import BaseForm from 'components/modules/base-form';
import SubmitButton from 'components/submit-button';
import ShipmentsStore from './shipments-store';


class AddSupplierModal extends BaseForm  {
    constructor(props) {
      super(props);
      
      let supplier = {name: "", phone: "", address: "", comment:""}
      if (props.id) {
        supplier = ShipmentsStore.getSupplier(props.id);
      }

        this.state = {
          id:  props.id || 0,
          name: {
                error: false,
                value: supplier.name || ""
          },
          phone: {
                error: false,
                value: supplier.phone || ""
          },
          address: {
                error: false,
                value: supplier.address || ""
          },
          comment: {
                error: false,
                value: supplier.comment || ""
          },
          waiting: false
        };

        this.submit = this.submit.bind(this);
    }
  
    componentDidMount() {
        const { onClose } = this.props;
        
        this.addSupplier = Actions.addSupplier.completed.listen((data) => {
            this.setState({
                'waiting': false
            });
            if (typeof(onClose) == "function") {
                onClose();
            }
        
        });

        this.addSupplierFail = Actions.addSupplier.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            this.setState({
                'waiting': false,
                'message': {
                'msg': T(data['error'])
                }
            })
        }); 
    }
  
  componentWillUnmount() {
        if (typeof (this.addSupplier) === "function") {
            this.addSupplier();
        }

        if (typeof(this.addSupplier) === "function") {
            this.addSupplierFail();
        }
    }
  
    submit() {
      if (this.state.name.value.trim().length === 0) {
          this.setState({
                name: {...this.state.name, error: true}
          });
        return
      }
      
      this.setState({ "waiting": true });

      const data = { 'name': this.state.name.value.trim() }
      if (this.state.id) { data['id'] = this.state.id }
      if (this.state.phone) { data['phone'] = this.state.phone.value }
      if (this.state.address) { data['address'] = this.state.address.value }
      if (this.state.comment) { data['comment'] = this.state.comment.value }
      
      Actions.addSupplier(data);
    }

    render () {
        return (
              <Box>
                <Field>
                  <Control>
                    <Label>{T('shipments-supplier-name')}</Label>
                    <Input  size="large" 
                          name="name" type="text" 
                          placeholder={T('shipments-supplier-name-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.name.error) ? "danger" : "primary"}
                          value={this.state.name.value}
                          />
                  </Control>
            </Field>
            <Field>
                  <Control>
                    <Label>{T('supplier-phone')}</Label>
                    <Input  size="large" 
                          name="phone" type="number" 
                          placeholder={T('supplier-phone-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.phone.error) ? "danger" : "primary"}
                          value={this.state.phone.value}
                          />
                  </Control>
            </Field>
            <Field>
                  <Control>
                    <Label>{T('supplier-address')}</Label>
                    <Input  size="large" 
                          name="address" type="text" 
                          placeholder={T('supplier-address-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.address.error) ? "danger" : "primary"}
                          value={this.state.address.value}
                          />
                  </Control>
            </Field>
            <Field>
                  <Control>
                    <Label>{T('comment')}</Label>
                    <Textarea  size="large" 
                          name="comment" type="text" 
                          placeholder={T('supplier-comment-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.comment.error) ? "danger" : "primary"}
                          value={this.state.comment.value}
                          />
                  </Control>
                </Field>
            <Button.Group>
              <SubmitButton text={T(this.state.id ?'btn-edit' : 'add-supplier-btn-label')} waiting={this.state.waiting} submit={this.submit}/>
                </Button.Group>
              </Box>
        );
    }

}

export default AddSupplierModal;