import React from "react";
import { Box, Heading } from "react-bulma-components";
import T from "../../../components/i18n";
import User from "../../../user/user-store";
import OpenModalButton from "../../../components/modal/open-modal-button";

const EmptyMessage = () => {
  return (
    <Box align="center">
      <Heading>{T("not-have-sales")}</Heading>
      {User.getModuleEnable("sales") && !User.isWarehouseMan() ? (
        <OpenModalButton
          link="/sales/add-sale"
          text={T("add-first-sale-btn")}
        />
      ) : null}
    </Box>
  );
};

export default EmptyMessage;
