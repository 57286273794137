import React, {useState} from "react";
import { Button } from "react-bulma-components";
import AppStore from "../../../../app-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import T from "../../../../components/i18n";
import Reports from "../../../../components/reports";
import ProductsStore from "../../../products/products-store";
import usePaginationHook from "../../../../tools/pagination-hook";
import EmptyMessage from "../common/empty-message";
import RowMobile from "./row-mobile";
import RowDesktop from "./row-desktop";
import AddBrandModal from "../../../products/add-brand-modal";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";
import {filterSearch} from "../../../../tools/filters-helper";
import SearchString
  from "../../../../components/base-filter-layout/search-string";

const COLUMNS = ["name", "reports-actions"];

const ProductBrand = () => {
  const {isMobileSize} = useIsMobile()
  const [search, setSearch] = useState('')
  const handleChangeSearch = ({name, value}) => {
    if(name) {
      setSearch(value)
    }
  }

  const getBrand = ProductsStore.getBrandsList();

  const withFilter = getBrand.filter(item => {
    const searchData = {
      name: item.name.trim('')
    }
    return filterSearch(search,['name'], searchData)
  })

  const { start, end, page, total, setPage, PaginationBlock } =
    usePaginationHook({ listLength: withFilter?.length, perPage: 50 });

  return (
    <>
      <div className="margin-top-10 margin-bottom-10 display-flex-row-gap">
        <SearchString defaultValue={search} onChange={handleChangeSearch}/>
        <Button
          color="info"
          onClick={() => AppStore.openModal(<AddBrandModal modal="brand"/>)}
          className="display-flex-row"
        >
          <FontAwesomeIcon icon="plus-circle"/>
          {T("add-brand-btn-label")}
        </Button>
      </div>
      <PaginationBlock
        totalPage={total}
        setPage={setPage}
        currentPage={page}
        showPrevNext={false}
      />
      <Reports
        emptyMessage={EmptyMessage}
        rows={withFilter?.slice(start, end) || []}
        columns={COLUMNS}
      >
        {isMobileSize ? <RowMobile role="row" /> : <RowDesktop role="row" />}
      </Reports>
      <PaginationBlock
        totalPage={total}
        setPage={setPage}
        currentPage={page}
        showPrevNext={false}
      />
    </>
  );
};

export default ProductBrand;
