import React from 'react'
import BaseFilterLayout from "../../../components/base-filter-layout";
import StockFilters from "./stock-filters";
import HistoryActions from "../history-actions";

const FilterPanel = ({onChangeFilter, valueFilter}) => {

	const handleChangeDate = (values) => {
		onChangeFilter("date_start", values.date_start);
		onChangeFilter("date_end", values.date_end);
	}

	const handleChangeSearch = ({name, value}) => {
		if(name) {
			onChangeFilter('search', value);
		}
	}

	const handleRefresh = () => {
		HistoryActions.stockMarketplaces(true)
	}

	return (
		<BaseFilterLayout
			searchStringSetting={{
				onChange: handleChangeSearch,
				defaultValue: valueFilter.search
			}}
			refresh={{
				onChange: handleRefresh
			}}
			dateRangeSetting={{
				onChange: handleChangeDate,
				date_start: valueFilter.date_start,
				date_end: valueFilter.date_end,
			}}
			hiddenFilter={<StockFilters onChange={onChangeFilter} value={valueFilter}/>}
		/>
	);
};

export default FilterPanel;