import React, { Component } from 'react';
import T from "components/i18n";
import Money from "components/money";
import User from "user/user-store";
import DOM from "react-dom-factories";
import { redirectTo } from 'tools/redirect-to'
import { 
    Field, 
    Textarea, 
    Control, 
    Input, 
    Label, 
} from 'react-bulma-components/lib/components/form';
import Icon from 'react-bulma-components/lib/components/icon';

import Columns from 'react-bulma-components/lib/components/columns';

import Heading from 'react-bulma-components/lib/components/heading';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import ExpensesActions from 'modules/expenses/expenses-actions'
import ExpensesStore from 'modules/expenses/expenses-store'

import SelectComponent from 'tools/select-component';

import OpenModal from 'components/modal/modal';

import TransactionActions from './transactions-actions';
import Messages from 'components/messages';

import SubmitButton from 'components/submit-button';
import NumberInput from "../../components/number-input/number-input";


class MoveFundsModal extends Component {
    constructor(props) {
        super(props);
        this.rates = User.getCurrencyRates() || {};
        this.state = {
          start_amount: props.amount || "",
          amount: props.amount || 0,
          fromAccount: props.fromAccount || null,
          toAccount: null,
          commission_from: 0,
          commission_to: 0,
          exc_rate: "",
          comment: '',
          message: null,
          waiting: false,
          exchange: false,
          change_account: false,
          close: false,
          step: 0,
          commission: 0
        };

        this.onChangeAccount = this.onChangeAccount.bind(this);
        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.renderStepSecond = this.renderStepSecond.bind(this);
        this.calcCommission = this.calcCommission.bind(this);

    }

    componentDidMount() {
        this.listener = ExpensesActions.transferFunds.completed.listen(() => {
          TransactionActions.load();
          this.setState({step: 1, waiting: false});
        });   

        this.listenerFail = ExpensesActions.transferFunds.failed.listen((res) => {
            let data = {};

            if (res && typeof(res['response']) != "undefined") {
              data = JSON.parse(res['response']);
            } else {
              data = {
                'error': "unhandled_error"
              }
            }

            this.setState({
              'waiting': false,
              'message': T(data['error'])            
            });
        });        

    }

    componentWillUnmount() {
      if (typeof(this.listener) == "function") {
          this.listener();
      }   

      if (typeof(this.listenerFail) == "function") {
          this.listenerFail();
      }

    }

     onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
            [name]: value
        });

        setTimeout(this.calcCommission, 10);
    } 

    calcCommission(amount) {
        if (typeof(amount) == "undefined") {
          amount = this.state.start_amount;
        }

        const main_currency_id = parseInt(User.get("currency_id"));

        let commission_from = 0,
            commission_to = this.state.commission || 0,
            amount_to = amount || 0,
            exchange_amount = amount || 0,
            amount_from = amount_to,
            exc_rate = 1,
            fcid = main_currency_id,
            cuid = main_currency_id,
            exchange_currency_id = main_currency_id,
            from_account,
            account,
            exchange = false,
            invert = false;

        if (this.state.fromAccount) {
          from_account = ExpensesStore.getAccount(this.state.fromAccount.value);
          commission_from = from_account['commission_from'];
        }

        if (this.state.toAccount) {
          account = ExpensesStore.getAccount(this.state.toAccount.value);
          if(!this.state.commission && this.state.commission !== '') {
            commission_to = account['commission_to'];
          }
        }

        if (this.state.fromAccount && this.state.toAccount && this.state.toAccount.value !== 0) {
          if (from_account) {
            fcid = parseInt(from_account['currency_id']);
          }
          if (account) {
            cuid = parseInt(account['currency_id']);
          }
              

          exchange_currency_id = fcid;

          if (fcid !== cuid && from_account && account) {
            exchange = true;
            if (cuid !== main_currency_id) {
              exchange_currency_id = cuid
              invert = true;
            }

            if (this.state.exc_rate === "") {
              return;
            }

            if (this.state.exc_rate > 1 && !this.state.change_account) {
              exc_rate = this.state.exc_rate;
            } else if (typeof(this.rates[exchange_currency_id]) !== "undefined") {
              exc_rate = this.rates[exchange_currency_id];
            }

            if (typeof(this.rates[exchange_currency_id]) === "object") {
                this.rates[exchange_currency_id] = exc_rate;
            }

            if (!invert) {
                amount_to = amount_to * exc_rate;
            } else {
                amount_to = amount_to / exc_rate;
            }
          }

          exchange_amount = amount_to;
        }

        if (commission_from > 0) {
          amount_from = parseFloat(amount_from) + (amount_from/100*commission_from);
        }

        if (commission_to > 0) {
          amount_to = amount_to - (amount_to/100*commission_to);
        }

        this.setState({
          "exchange": exchange,
          "start_amount": amount,
          "change_account": false,
          "exchange_amount": exchange_amount,
          "commission_from": commission_from || 0,
          "commission_to": commission_to || 0,
          "commission": commission_to || '',
          "amount_from": amount_from,
          "exc_rate": exc_rate,
          "amount": amount_to
        });
    }

    onChangeAmount(evt) {
        const value = evt.target.value;
        
        this.calcCommission(value);
    }

    onChangeAccount(selectedOption, actions) {
      if (!selectedOption) return;
     
      this.setState({
        [actions.name]: selectedOption,
        'change_account': true,
      });
      if(actions.name === 'toAccount') {
        this.setState({
          commission: 0
        })
      }

      setTimeout(this.calcCommission, 10);
    }

    submit() {
        if (this.state.fromAccount.value === 0 || 
          this.state.toAccount.value === 0 ||
          this.state.start_amount === "" || parseFloat(this.state.start_amount) === 0
          ) {
          this.setState({"message": T('not all fields required')});
          return;
        }
        this.setState({"waiting": true});

        // User.setCurrencyRate(this.rates); update rates

        ExpensesActions.transferFunds(
          this.state.fromAccount.value,
          this.state.toAccount.value,
          this.state.exchange ? this.state.exchange_amount : this.state.start_amount,
          null,
          this.state.comment,
          this.state.start_amount,
          false,
          this.state.exchange ? parseFloat(this.state.exc_rate) : null,
          this.state.commission
        );

    }

    getCurrencyRate() {
      return (
           <Field >
              <Label>{T('exchange-rate')}</Label>
              <Control iconRight>
                      <Input  size="large" 
                            name="exc_rate"
                            onChange={this.onChange} 
                            value={this.state.exc_rate}
                            />
                  <Icon align="right">
                    <span className="small-text">{User.getCurrency()}</span>
                  </Icon>
              </Control>
          </Field>   
        );

    }

    renderStepOne() {
      const to_aid = (this.state.toAccount && this.state.toAccount.value) ? this.state.toAccount.value : false;
      const from_aid = (this.state.fromAccount && this.state.fromAccount.value) ? this.state.fromAccount.value : false;
      return (
          <Box>
              <Field>
                  <Control>
                    <Label>{T('amount-transfer')}</Label>
                    <Input  size="large" 
                          name="start_amount" 
                          type="number" 
                          onChange={this.onChangeAmount} 
                          value={this.state.start_amount}
                          />
                  </Control>
                </Field>
              <Columns>
                      <Columns.Column>
                        <SelectComponent 
                          name="fromAccount"
                          label="from-account"
                          onChange={this.onChangeAccount} 
                          value={this.state.fromAccount} 
                          list={()=>ExpensesStore.getPersonalAccounts()}
                          load={ExpensesActions.loadAccounts}
                          isOptionDisabled={option => option.value === to_aid}
                          creatable={false}/>
                      </Columns.Column>
                      <Columns.Column>
                        <SelectComponent 
                          name="toAccount"
                          label="to-account"
                          onChange={this.onChangeAccount} 
                          value={this.state.toAccount} 
                          empty={true}
                          list={()=>ExpensesStore.getPersonalAccounts()}
                          load={ExpensesActions.loadAccounts}
                          isOptionDisabled={option => option.value === from_aid}
                          creatable={false}/>
                      </Columns.Column>
              </Columns>
              <Field>
                <Label>{T('transfer-fund-commissin-to')}</Label>
                <Control iconRight>
                  <NumberInput
                    size="large"
                    name="commission"
                    type="number"
                    max={100}
                    onChange={this.onChange}
                    value={this.state.commission}
                  />
                  <Icon align='right'>
                    <span>%</span>
                  </Icon>
                </Control>
              </Field>
              {this.state.exchange ? this.getCurrencyRate() : null}
               <Field>
                  <Control>
                    <Label>{T('comment')}</Label>
                    <Textarea  size="small" 
                          name="comment" 
                          type="text" 
                          placeholder={T('move-funds-comment-placeholder')}
                          onChange={this.onChange} 
                          value={this.state.comment}
                          />
                  </Control>
                </Field> 
              <Box>
                 <div>{T('transfer-fund-commissin-from')}: {this.state.commission_from}%</div>
                 <div>{T('transfer-fund-amount-from')}: <Money amount={this.state.amount_from} aid={from_aid} wrapper={DOM.span}/></div>
                 
                 <div>{T('transfer-fund-commissin-to')}: {this.state.commission_to}%</div>
                 <div className="important">{T('transfer-fund-finish-amount')}: <Money amount={this.state.amount}  aid={to_aid} wrapper={DOM.span}/></div>
              </Box>
              <Button.Group>
                    <SubmitButton text='move-money-btn' waiting={this.state.waiting} submit={this.submit}/>
              </Button.Group>
          </Box>
      );
    }

    renderStepSecond() {
      return (
        <Box>
          <Heading className="title">{T('transfer-success')}</Heading>
          <Button.Group>
              <Button fullwidth size="large" rounded color="primary"
                onClick={() => this?.props?.onClose ? this?.props?.onClose() : this.setState({close: true})}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    render() {
        const stepNaviganor = [this.renderStepOne, this.renderStepSecond],
              currentStep = stepNaviganor[this.state.step];
        return (
              <div>
                <Messages message={this.state.message} close={() => this.setState({message: null})} />
                {currentStep()}
                {this.state.close ? redirectTo("/transactions") : ""}
              </div>
        );

    }
       
}

class MoveFundsModalLink extends Component {
    constructor(props) {
        super(props);
        console.log("props change account modal, ", props)
        this.state = {}
    }

    render() {
        const props = this.props?.location?.state;


        return (
          <OpenModal modal={{ closeOnBlur: false }} close={this.props?.onClose}>
              <MoveFundsModal {...props} />
          </OpenModal>
        );
    }
}

export default MoveFundsModalLink;