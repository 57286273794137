import T from "../../../components/i18n";
import {LocalIcon} from 'whitelables/wl-name/icons'
import React from "react";

export const getDeliveryName = (delivery) => {
	const splitDeliverInfo = !!delivery && String(delivery)?.split(':')
	if (splitDeliverInfo[0] === '1') {
		return  <span className='display-flex-row' style={{display: "inline-flex"}}>
							<LocalIcon icon='nova-poshta' size='small'/>
							{T("nova_poshta")}
						</span>
	} else if (splitDeliverInfo[0] === '3') {
		 return <span className='display-flex-row' style={{display: "inline-flex"}}>
							<LocalIcon icon='ukr-poshta' size='small'/>
							{T("ukr_poshta")}
		 				</span>
	} else if(splitDeliverInfo[0] === '6') {
		return <span className='display-flex-row' style={{display: "inline-flex"}}>
							<LocalIcon icon='rozetka' size='small'/>
							{T("rozetka")}
		 				</span>
	} else {
		return delivery
	}
}