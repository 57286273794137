import React from "react";
import Reports from "../../../../components/reports";
import Box from "react-bulma-components/lib/components/box";
import T from "../../../../components/i18n";
import GeneralSubReportRowDesktop from "./general-sub-report-row-desktop";
import { useIsMobile } from "../../../../components/is-mobile-context/is-mobile-context";
import GeneralSubReportRowMobile from "./general-sub-report-row-mobile";
import usePaginationHook from "../../../../tools/pagination-hook";

const EmptyMessage = () => {
  return (
    <Box>
      <h2>{T("not-have-history")}</h2>
    </Box>
  );
};

const GeneralSubReports = ({ data, activeFilter, filterList }) => {
  const COLUMNS = [
    "name",
    "sales-amounts",
    "dashboard-sales-profit",
    "sales",
    "average-check",
    "many-margin",
  ];

  const { isMobileSize } = useIsMobile();
  const { start, end, page, setPage, total, PaginationBlock } =
    usePaginationHook({
      perPage: isMobileSize ? 5 : 10,
      listLength: Object.values(data || {}).length,
    });

  if (activeFilter === "mid") {
    COLUMNS.push("Expenses");
  }

  const rows = Object.values(data || {});

  const rowsWithFilter = rows.filter((item) => {
    if (activeFilter === "mid" && !!filterList.mid[0].value) {
      return filterList.mid.some(
        (filter) => parseInt(filter.value) === parseInt(item.name)
      );
    } else if (activeFilter === "clients" && !!filterList.clients[0].value) {
      return filterList.clients.some(
        (filter) => parseInt(filter.value) === parseInt(item.name)
      );
    } else if (activeFilter === "channels" && !!filterList.channels[0].value) {
      return filterList.channels.some(
        (filter) => parseInt(filter.value) === parseInt(item.name)
      );
    }
    return item;
  });

  return (
    <>
      <PaginationBlock setPage={setPage} totalPage={total} currentPage={page} />
      <Reports
        emptyMessage={EmptyMessage}
        columns={COLUMNS}
        rows={rowsWithFilter.slice(start, end)}
      >
        {isMobileSize ? (
          <GeneralSubReportRowMobile role="row" activeFilter={activeFilter} />
        ) : (
          <GeneralSubReportRowDesktop role="row" activeFilter={activeFilter} />
        )}
      </Reports>
      <PaginationBlock setPage={setPage} totalPage={total} currentPage={page} />
    </>
  );
};

export default GeneralSubReports;
