import { StylesConfig } from 'react-select';

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

export interface ColourOption {
  value: string;
  label: string;
  color: string;
  isFixed ? : boolean;
  isDisabled ? : boolean;
}

export const colourStyles: StylesConfig<ColourOption> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = data.color;
    return {
      ...styles,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 'white'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};