import React from 'react';
import T from "components/i18n";

const getDateRange = (func_send, range) => {
	let response = [];

	for (let i in range) {
		let r = range[i];
		response.push(
			<span key={r} onClick={() => setDate(func_send, r)}>{T(r)} | </span>
			);
	}

	return (
		<div className="filters-date-string">
              {response}
        </div>
     )
}

const setDate = (func_send, range) => {
    let date_start = new Date(),
        date_end = new Date();
    if (range === "today") {
    } else if (range === "yesterday") {
      date_start.setDate(date_start.getDate() - 1);
      date_end.setDate(date_end.getDate() - 1);
    } else if (range === "7 days" || range === "week") {
      date_start.setDate(date_start.getDate() - 7);
    } else if (range === "30 days" || range === "30days" || range === "30days-short") {
      date_start.setDate(date_start.getDate() - 30);
    } else if (range === "current" || range === "this-month") {
      date_start.setDate(1);     
    } else if (range === "last-month") {
      date_start.setMonth(date_start.getMonth()-1);  
      date_start.setDate(1);     
      date_end.setDate(0);
    } else if (range === "3-month") {
      date_start.setMonth(date_start.getMonth()-2);
      date_start.setDate(1);    
    } else if (range === "half-year") {
      date_start.setMonth(date_start.getMonth()-5);
      date_start.setDate(1);    
    } else if (range === "f-year") {
      date_start.setYear((1900+date_start.getYear())-1);  
      date_start.setDate(1);    
    } else if (range === "all-period") {
      date_start.setMonth(date_start.getMonth()-50);  
      date_start.setDate(1);    
    } else if (range === "next-week") {
			const currentDayOfWeek = date_start.getDay();
			const daysUntilNextMonday = (currentDayOfWeek === 0) ? 1 : (8 - currentDayOfWeek);
			date_start.setDate(date_start.getDate() + daysUntilNextMonday);
			date_end.setDate(date_start.getDate() + 6);
			date_end.setMonth(date_start.getMonth());
		} else if (range === "next-month") {
			date_start.setMonth(date_start.getMonth() + 1);
			date_start.setDate(1);
			date_end.setMonth(date_end.getMonth() + 2);
			date_end.setDate(0);
		}

    func_send("date_start", date_start);
    func_send("date_end", date_end);
}

export const setDateWithString = (range) => {
	let date_start = new Date(),
		date_end = new Date();
	if (range === "today") {
	} else if (range === "yesterday") {
		date_start.setDate(date_start.getDate() - 1);
		date_end.setDate(date_end.getDate() - 1);
	} else if (range === "7 days" || range === "week") {
		date_start.setDate(date_start.getDate() - 7);
	} else if (range === "30 days" || range === "30days" || range === "30days-short") {
		date_start.setDate(date_start.getDate() - 30);
	} else if (range === "current" || range === "this-month") {
		date_start.setDate(1);
	} else if (range === "last-month") {
		date_start.setMonth(date_start.getMonth()-1);
		date_start.setDate(1);
		date_end.setDate(0);
	} else if (range === "3-month") {
		date_start.setMonth(date_start.getMonth()-2);
		date_start.setDate(1);
	} else if (range === "half-year") {
		date_start.setMonth(date_start.getMonth()-5);
		date_start.setDate(1);
	} else if (range === "f-year") {
		date_start.setYear((1900+date_start.getYear())-1);
		date_start.setDate(1);
	} else if (range === "all-period") {
		date_start.setMonth(date_start.getMonth()-50);
		date_start.setDate(1);
	} else {
		return range
	}

	return date_start
}

const getDatePeriod = (period) => {
	const dayNow = new Date()
	const twoWeeksAgo = dayNow.setDate(dayNow.getDate() - period)
	return  new Date(twoWeeksAgo)
}

const formatDateString = (date) => {
	let year = date.getFullYear();
	let month = (date.getMonth() + 1).toString().padStart(2, '0');
	let day = date.getDate().toString().padStart(2, '0');

	return `${year}${month}${day}`;
}

export  {
	setDate,
	getDateRange,
	getDatePeriod,
	formatDateString
}