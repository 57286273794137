import React, { Component } from 'react';
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";
// import getQueryParams from "tools/query-params";2000034200757

import Money from "components/money";

// import Box from 'react-bulma-components/lib/components/box';
import Level from 'react-bulma-components/lib/components/level';
// import Button from 'react-bulma-components/lib/components/button';

// import ExpensesStore from './expenses-store'
// import ExpensesActions from './expenses-actions'
import ProductsStore from '../products/products-store'
import SalesStore from '../sales/sales-store'
import SalesActions from '../sales/sales-actions'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";

import User from 'user/user-store';

import Card from 'react-bulma-components/lib/components/card';
// import Media from 'react-bulma-components/lib/components/media';
// import Image from 'react-bulma-components/lib/components/image';
import Content from 'react-bulma-components/lib/components/content';
import Heading from 'react-bulma-components/lib/components/heading';
import WarehouseStore from 'modules/warehouse/warehouse-store';



class InfoProductModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        // this.onChange = this.onChange.bind(this);
        // this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        SalesActions.loadReports();
    }

    renderWarehouse(pid, stocks) {
      let response = [];

      for (let i in stocks) {
        const stock = stocks[i],
              reports = SalesStore.getSaleReportsByProduct(pid, stock['marketplace_id']);
              
        response.push(
          <div key={"stock"+i}>
            <div className="margin-bottom-10">{T(WarehouseStore.getWarehouseName(stock['marketplace_id']))}. {stock['sku'] !== "" ? <span><b>{T('product-vendor-code')}</b>: {stock['sku']}</span> : null}</div>
            <Level renderAs="nav" className="text-align-center ">
                {!User.getPermissionEnable("incomeinformation") ? null : 
                <Level.Item>
                  <div>
                    <Heading renderAs="p" heading>
                      {T('product-info-netcost')}
                    </Heading>
                    <Heading renderAs="p" size={4}>
                    {stock['net_price'] || 0} {User.getCurrency()}
                    </Heading>
                  </div>
                </Level.Item>
                }
                <Level.Item>
                  <div>
                    <Heading renderAs="p" heading>
                      {T('product-info-price')}
                    </Heading>
                    <Heading renderAs="p"  size={4}>
                    {stock['price'] || 0} {User.getCurrency()}
                    </Heading>
                  </div>
                </Level.Item>
                <Level.Item>
                  <div>
                    <Heading renderAs="p" heading>
                      {T('product-info-warehouse')}
                    </Heading>
                    <Heading renderAs="p"  size={4}>
                    {stock['instock']}
                    </Heading>
                  </div>
                </Level.Item>
                <Level.Item>
                  <div>
                    <Heading renderAs="p" heading>
                    {T('product-info-sales')}
                    </Heading>
                    <Heading renderAs="p" size={4}>
                    {reports['items_count']}
                    </Heading>
                  </div>
                </Level.Item>
                {!User.getPermissionEnable("incomeinformation") ? null : 
                <Level.Item>
                  <div>
                    <Heading renderAs="p" heading>
                    {T('product-info-profit')}
                    </Heading>
                    <Heading size={4}>
                      <Money amount={reports['sales_profit']} />
                    </Heading>
                  </div>
                </Level.Item>
                }
            </Level>
            <hr className="margin-top-10 margin-bottom-10"/>
        </div>
        )
      }

      return response;
    }

    render() {
        const {product} = this.props,
              pid = product['id'],
              mid = product['stock']['marketplace_id'],
              imgTop = "/bimages/get/0-"+User.get("id")+"-"+pid+"-0.jpg",
              reportLink = "/products/report",
              // editLink = "/products/edit-products",
              saleLink = "/sales/add-sale",
              deliveryLink = "/shipments/add-shipment",
              CustomLink = ({
                  href,
                  params,
                  children,
                  className,
              }) => (
                  <Link 
                      className={className} 
                      to={{
                        pathname: href,
                        state: params || {
                          pid: pid
                        }
                      }} >{children}</Link>
              );

        return (
            <Card>
              <Card.Image size="16by9" src={imgTop} />
              <Card.Header>
                <Card.Header.Title>{product['name']}</Card.Header.Title>
              </Card.Header>
              <Card.Content>
                  {this.renderWarehouse(pid, product['stock'])}
                  <Content>
                        <ul>
                            <li><b>{T('ID')}</b>: {pid}</li>
                            <li><b>{T('product-brand')}</b>: {product['brand']}</li>
                            <li><b>{T('category')}</b>: {ProductsStore.getCategoryName(product['category_id'])}</li>
                            <li><b>{T('product-barcode')}</b>: {product['asin']}</li>
                        </ul>
                        <div className="max-height150" dangerouslySetInnerHTML={{__html: "<div>"+product['description']+"</div>"}}>
                        </div>
                  </Content>

              </Card.Content>
              <Card.Footer className="text-align-center">
                {User.getModuleEnable("reports") ? 
                <Card.Footer.Item renderAs={CustomLink} href={reportLink}>{T('product-info-report')}</Card.Footer.Item>
                : null}
                <Card.Footer.Item renderAs={CustomLink} params={{"select_product": {'value': ProductsStore.getIdent(pid, mid), 'label': product['name'] }}} href={saleLink}>{T('add-sale-btn')}</Card.Footer.Item>
                {User.getModuleEnable("shipments") ? 
                <Card.Footer.Item renderAs={CustomLink} params={{
                  products: [{'value': pid, 'label': product['name'] }],
                  mid: product['stock']['marketplace_id']
                }} href={deliveryLink}>{T('make-delivery-btn')}</Card.Footer.Item>
                : null}
              </Card.Footer>
            </Card>
        );
    }

}

//                <Card.Footer.Item renderAs={CustomLink} href={editLink}>{T('product-info-edit')}</Card.Footer.Item>



export default connectToStores(InfoProductModal, { 
    products: ProductsStore,
    sales: SalesStore 
  });
