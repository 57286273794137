import React from 'react'
import Box from "react-bulma-components/lib/components/box";
import T from "../../../../components/i18n";
import Button from "react-bulma-components/lib/components/button";
import ButtonBack from "../UI/button-back";
import SubmitButton from "../../../../components/submit-button";

const StepFourRevert = ({onSubmit, isLoaded}) => {
	return (
		<Box>
			<h1 className="title">{T('revert-write-off')}</h1>
			<div className="big-text">
				{T('write-off-revert-desc')}
			</div>
			<Button.Group position="centered">
				<SubmitButton
					size="large"
					rounded
					color="primary"
					text='revert-write-off'
					fullwidth={false}
					waiting={isLoaded}
					submit={onSubmit}/>
				<ButtonBack/>
			</Button.Group>
		</Box>
	);
};

export default StepFourRevert;