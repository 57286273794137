import React from "react";
import TerminalStore from "../terminal-store";
import Reports from "../../../../components/reports";
import { Box } from "react-bulma-components";
import TerminalReceiptRow from "./terminal-receipt-row";
import EmptyMessageTerminalReceipts from "./empty-message-terminal-receipts";
import FilterPanel from "./filter-panel";

const COLUMNS = [
  "id-transaction",
  "date",
  "main_sale",
  "responsible",
  "account",
  "RRN",
];

const TerminalReceiptsTab = () => {
  const list = TerminalStore.getReceiptList();

  return (
    <>
      <FilterPanel />
      <Box>
        <Reports
          emptyMessage={EmptyMessageTerminalReceipts}
          columns={COLUMNS}
          hideFooterOnEmpty
          isLoading={TerminalStore.get("isLoadingReceipt")}
          rows={list}
        >
          <TerminalReceiptRow role="row" />
        </Reports>
      </Box>
    </>
  );
};

export default TerminalReceiptsTab;
