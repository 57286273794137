import React, { useEffect, useState } from "react";
import T from "../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import PriceTagField from "./price-tag-field";

import List from "react-bulma-components/lib/components/list";

import UserStore from "../../../user/user-store";
import ActionsModal from "./components/actions-modal";
import TypeSelection from "./components/type-selection";
import IframeComponent from "./components/iframe-component";
import SettingTemplates from "./components/setting-templates";
import SettingCustom from "./components/setting-custom";
import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import ErrorMessageBlock from "../../../components/error-message-block/error-message-block";
import PriceTagAttrField from "./price-tag-attr-field";
import PriceTagPriceField from "./price-tag-price-field";

const PriceTagModal = ({ defaultData, onClose }) => {
  const [data, setData] = useState({});
  const [type, setType] = useState(defaultData.type || "templates");
  const [errorMessage, setErrorMessage] = useState(null);
  const handleAutoRemoveErrorMessage = (message) => {
    setTimeout(() => setErrorMessage(null), 4000);
    setErrorMessage(message);
  };
  const errorHandler = () => {
    let errorMessage;
    if (type === "custom") {
      const keysData = Object.keys(data).filter(item => item !== 'attr')
      const positions = keysData.map((item) => data[item].position);
      const uniquePositions = new Set(positions);
      if (positions.length !== uniquePositions.size) {
        errorMessage = T("error-message-positions-different");
      }
      if(data['discount'] && !data['discount']?.field) {
        errorMessage = T("error-message-discount-field");
      }
    }
    if (errorMessage) {
      handleAutoRemoveErrorMessage(errorMessage);
      return false;
    }

    return true;
  };

  const handleChangeType = (evt) => {
    const name = evt.target.name;
    const checked = evt.target.checked;

    if (checked) {
      setType(name);
    }
    setData({});
  };

  const handleChangeData = (value) => {
    setData((prevState) => ({
      ...prevState,
      ...value,
    }));
  };

  const handleSubmit = ({ isClose }) => {
    if (errorHandler()) {
      const dataState = JSON.parse(JSON.stringify(data))
      if(type === 'templates') {
        delete dataState?.discount
      }
      UserStore.setPriceTagsInfo(dataState);
      if (onClose && !isClose) onClose();
    }
  };

  useEffect(() => {
    if (type === "custom") {
      setData((prevState) => ({
        ...prevState,
        page: {
          format: defaultData?.page?.format || "termo",
          size: defaultData?.page?.size || {
            width: 6,
            height: 4,
          },
          quantity: defaultData?.page?.quantity === 0 ? 0 : 1,
        },
      }));
    }

    if (type === "templates") {
      setData((prevState) => ({
        ...prevState,
        size: defaultData?.size || 1,
        quantity: defaultData?.quantity || 1,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading size={2} align="center">
          {T("price-tag-setting")}
        </Heading>
        <TypeSelection type={type} onChange={handleChangeType} />

        <Columns>
          <Columns.Column>
            <IframeComponent onSubmit={handleSubmit} />
            {type === "custom" && (
              <SettingCustom
                onSubmit={handleChangeData}
                data={data?.page || defaultData?.page}
              />
            )}
            {type === "templates" && (
              <SettingTemplates
                defaultSize={defaultData?.size || data?.size}
                defaultQuantity={defaultData?.quantity || data?.size}
                onChange={handleChangeData}
              />
            )}
          </Columns.Column>

          <Columns.Column>
            <Heading align="center" size={5}>
              {T("info-on-pricetags")}
            </Heading>
            <List>
              {defaultData?.field?.map((item) => {
                if (item.name === "attr") {
                  return (
                    <List.Item key={item.name}>
                      <PriceTagAttrField
												onSubmit={handleChangeData}
                        name={item.name}
                        active={data[item.name] || item.active}
                      />
                    </List.Item>
                  );
                }

                if(item.name === 'price' && type === 'custom') {
                  return <List.Item key={item.name}>
                      <PriceTagPriceField
                        onSubmit={handleChangeData}
                        name={item.name}
                        active={data[item.name] || item}
                      />
                  </List.Item>
                }

                return (
                  <List.Item key={item.name}>
                    <PriceTagField
                      onSubmit={handleChangeData}
                      name={item.name}
                      size={data[item.name]?.size || item.size}
                      active={data[item.name]?.active || item.active}
                      position={data[item.name]?.position || item.position}
                      height={data[item.name]?.height || item.height}
                      width={data[item.name]?.width || item.width}
                      number_key={
                        data[item.name]?.number_key || item.number_key
                      }
                      type={type}
                      brand={data[item.name]?.brand || item?.brand}
                    />
                  </List.Item>
                );
              })}
            </List>
          </Columns.Column>
        </Columns>
        <ActionsModal onClose={onClose} onSubmit={handleSubmit} />
      </Box>
    </>
  );
};

export default PriceTagModal;
