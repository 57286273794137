import React from "react";
import Box from "react-bulma-components/lib/components/box";
import { Helmet } from "react-helmet";
import T from "../../../components/i18n";
import Heading from "react-bulma-components/lib/components/heading";
import Button from "react-bulma-components/lib/components/button";
import Image from "react-bulma-components/lib/components/image";

/* eslint-disable jsx-a11y/anchor-is-valid */
const Modeler = () => {

  return (
    <Box>
      <Helmet>
        <title>{T("contact-title")}</title>
        <meta name="description" content={T("contact-desc")} />
      </Helmet>
      <Heading>{T("contact-link")}</Heading>
      <p>{T("contact-text")}</p>

      <div className="margin-top-20 margin-botton-10">
        <Button.Group position="centered">
          <a
            href="https://t.me/GoodProfitCrmSupportBot"
            className="margin-side-10"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image size={64} src="/img/Telegram_logo.svg" border="0" />
          </a>
        </Button.Group>
      </div>
    </Box>
  );
};

export default Modeler;
