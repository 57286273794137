import T from "../../../../components/i18n";
import ProductsStore from "../../../products/products-store";
import User from "../../../../user/user-store";
import numberFormat from "../../../../tools/number-format";
import React from "react";

export const productNameByStep = ({ prod, step, currency }) => {
  const pid = prod.id;
  let stock_text = prod.instock;

  if (prod.instock !== prod.quantity) {
    stock_text += "/" + prod.quantity;
  }

  let name = "",
    instock =
      T("product-info-warehouse-one") +
      " " +
      stock_text +
      " " +
      ProductsStore.getUnits(prod.units) +
      ".";

  if (User.getPermissionEnable("incomeinformation")) {
    if (prod["netprice"]) {
      instock +=
        " " +
        T("netcost") +
        ": " +
        numberFormat(prod["netprice"]) +
        " " +
        User.getCurrency();
    } else if (step === 1 && prod["raw_net_price"]) {
      instock +=
        " " +
        T("netcost") +
        ": " +
        numberFormat(prod["raw_net_price"]) +
        " " +
        currency;
    }
  }

  if (prod.sku && prod.sku !== "") {
    name += prod.sku + ". ";
  }

  if (prod.brand && prod.brand !== "") {
    name += prod.brand + ". ";
  }

  name += prod.name;

  if (prod.asin && prod.asin !== "") {
    name += " | " + prod.asin;
  }

  return (
    <span>
      ID: {pid}. {name} <br /> <b>{instock}</b>
    </span>
  );
};

export const productNameMobile = (prod) => {
  const pid = prod.id;

  let name = ["ID: " + pid];

  if (prod.sku && prod.sku !== "") {
    name.push(prod.sku);
  }

  if (prod.brand && prod.brand !== "") {
    name.push(prod.brand);
  }

  name.push(prod.name);

  return <span>{name.join(". ")} </span>;
};

export const instockMobileText = (prod, step) => {
  let stock_text = prod.instock;

  if (prod.instock !== prod.quantity) {
    stock_text += "/" + prod.quantity;
  }

  let instock = stock_text + " " + ProductsStore.getUnits(prod.units);

  let net_price = "";
  if (prod["netprice"]) {
    net_price = (
      <span>
        {" "}
        {T("netcost-short")}:{" "}
        <span className="text-success">
          {numberFormat(prod["netprice"])} {User.getCurrency()}{" "}
        </span>
      </span>
    );
  } else if (step === 1 && prod["raw_net_price"]) {
    net_price = (
      <span>
        {" "}
        {T("netcost-short")}:{" "}
        <span className="text-success">
          {numberFormat(prod["raw_net_price"])} {User.getCurrency()}{" "}
        </span>
      </span>
    );
  }

  return (
    <>
      <span className="text-success">{instock}</span> <br />
      {!User.getPermissionEnable("incomeinformation") && step === 3
        ? ""
        : net_price}
    </>
  );
};
