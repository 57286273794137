import React, { Component } from 'react';
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";
import getQueryParams from "tools/query-params";
import {
    Field, 
    Control, 
    Input,
    Label
} from 'react-bulma-components/lib/components/form';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Messages from 'components/messages';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import ExpensesStore from './expenses-store'
import ExpensesActions from './expenses-actions'
import ProductsStore from '../products/products-store'

import SelectComponent from 'tools/select-component';
import AppStore from 'app-store';
import User from 'user/user-store';
import AddExpensesCategoryModal from "./add-expenses-category-modal";

class AddFundsModal extends Component {
    constructor(props) {
      super(props);
    
        let account = null;
        if (this.props.account) {
          account = {
              'value': this.props.account,
              'label': "",
          };
        }
      
        let category = null;
        if (this.props.category_id) {
          category = {
              'value': this.props.category_id
          };
        } 
      
        let date = new Date();
        if (this.props.date) {
          date = new Date(this.props.date * 1000)
        } 

        this.state = {
          comment: {
                error: "",
                value: this.props.comment || ""
          },
          amount: {
                error: "",
                value: this.props.amount || ""
          },
          account: account,
          date: date,
          category: category,
          fund_id: this.props.fund_id || null,
          message: null,
          close: false
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeDateSaled = this.onChangeDateSaled.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        let product_id = getQueryParams("product");
        if (product_id) {
          this.setState({"product": {"value": product_id, "error": ""}});
        }

        this.listenAddFaild = ExpensesActions.addExpenses.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            this.setState({
              'message': {
                'msg': T(data['error'])
              }
            })
        });

        this.listenAddCompleted = ExpensesActions.addExpenses.completed.listen(() => {
            AppStore.closeModal();
        });
    }

    componentWillUnmount() {
      if (typeof(this.listenAddFaild) == "function") {
          this.listenAddFaild();
      }
      if (typeof(this.listenAddCompleted) == "function") {
          this.listenAddCompleted();
      }
    }


    submit() {
        const account = this.state.account.value,
              comment = this.state.comment.value,
              category = this.state.category.value,
              date =  this.state.date,
              amount = this.state.amount.value || 0,
              id = this.state.fund_id;

        if (comment.trim() === "" || amount <= 0) {
          this.setState({
              'message': {
                'msg': T('not all fields required')
              }
          })

          return false;
        }

        ExpensesActions.addExpenses(account, amount, comment, "1", category, 0, date, 0, id);
    }

    onChangeDateSaled(date) {
        this.setState({
            "date": date
        });
    }

    // getMessage() {

    //   return (
    //           <Notification color="danger">
    //                 <ScrollToTopOnMount />
    //                 {this.state.message['msg']}
    //                 <Button remove onClick={() => this.setState({message: null})} />
    //           </Notification>
    //     )
    // }

    onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
        [name]: {
          'value': value
        }
        });
    }

    onChangeSelect(selectedOption, actionMeta) {
        this.setState({
          [actionMeta.name]: selectedOption
        })
    }

    render() {
        const mess = (this.state.message && this.state.message['msg']) ? this.state.message['msg'] : null;
        return (
          <div>
              <Messages message={mess} close={() => this.setState({message: null})} />
              <Box>
                 <Field>
                     <SelectComponent 
                          name="category"
                          label="receipt-category"
                          onChange={this.onChangeSelect} 
                          value={this.state.category} 
                          list={ExpensesStore.getFundsList}
                          addItem={(value) => AppStore.openModal(<AddExpensesCategoryModal modal="add-funds-category" defaultValue='1' isDisabledReceipt name={value.name}/>)}
                          addItemModal={() => {AppStore.openModal(<AddExpensesCategoryModal modal="add-funds-category" defaultValue='1' isDisabledReceipt/>)}}
                          load={ExpensesActions.loadCategory}
                          creatable={true}/>
                </Field>
                <Field>
                  <Control>
                    <Label>{T('receipt-amount')}</Label>
                    <Input  size="large" 
                          name="amount" 
                          type="number" 
                          min="0.01"
                          placeholder={T('receipt-amount-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.amount.error) ? "danger" : "primary"}
                          value={this.state.amount.value}
                          />
                  </Control>
                </Field>
                <Field>
                <SelectComponent 
                          name="account"
                          label="funds-the-account"
                          onChange={this.onChangeSelect} 
                          value={this.state.account} 
                          list={() => ExpensesStore.getAccountsList(1, false, true)}
                          load={ExpensesActions.loadAccounts}
                          creatable={false}/>
                </Field>
                <Field>
                  <Control>
                    <Label>{T('comment')}</Label>
                    <Input  size="large" 
                          name="comment" 
                          type="text" 
                          placeholder={T('receipt-comment-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.comment.error) ? "danger" : "primary"}
                          value={this.state.comment.value}
                          />
                  </Control>
                </Field>
                <Field>
                  <Label>{T("sale-date")}</Label>
                  <Control>
                     <DatePicker
                        selected={this.state.date}
                        onChange={this.onChangeDateSaled}
                        className="input"
                        dateFormat="dd-MM-yyyy"
                        popperPlacement="top-left"
                        maxDate={new Date()}
                        showTimeInput
                        locale={User.getLang()}
                        timeFormat="HH:mm"
                      />
                  </Control>
              </Field>
                <Button.Group>
                  <Button fullwidth size="large"  rounded color="primary"
                    onClick={this.submit}
                    >{this.state.fund_id ? T('edit-funds-btn'): T('add-funds-btn-1')}</Button>
                </Button.Group>
              </Box>
        </div>
        );
    }

}


export default connectToStores(AddFundsModal, { 
    expenses: ExpensesStore,
    products: ProductsStore 
  });
