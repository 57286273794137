import React, { useEffect, useState } from "react";
import { Box } from "react-bulma-components";
import DynamicTabList from "../../../components/dynamic-tab-list/dynamic-tab-list";
import MinBalanceProduct from "./min-balance-product ";
import ProductCategory from "./product-category";
import { connectToStores } from "../../../tools/reflux-tools";
import UserStore from "../../../user/user-store";
import ProductsStore from "../../products/products-store";
import ProductBrand from "./product-brand";
import ProductAttr from "./product-attr";
import ProductAddPrices from "./product-add-prices";
import ProductAddField from "./product-add-field";

const list = [
  {
    label: "min-balance-label",
    value: "minStock",
  },
  {
    label: "product-category",
    value: "category",
  },
  {
    label: "product-brand",
    value: "brand",
  },
  {
    label: "attr",
    value: "attr",
  },
  {
    label: "custom-prices-short",
    value: "add-price",
  },
  {
    label: "additional-fields-short",
    value: "add-field",
  },
];

const ProductsTab = () => {
  const [isActiveTab, setIsActiveTab] = useState("minStock");

  const handleChangeTab = (tab) => {
    setIsActiveTab(tab);
  };

  useEffect(() => {}, []);

  return (
    <Box>
      <DynamicTabList
        list={list}
        defaultTab={isActiveTab}
        onChangeTab={handleChangeTab}
      />
      {isActiveTab === "minStock" && <MinBalanceProduct />}
      {isActiveTab === "category" && <ProductCategory />}
      {isActiveTab === "brand" && <ProductBrand />}
      {isActiveTab === "attr" && <ProductAttr />}
      {isActiveTab === "add-price" && <ProductAddPrices />}
      {isActiveTab === "add-field" && <ProductAddField />}
    </Box>
  );
};

export default connectToStores(ProductsTab, {
  user: UserStore,
  product: ProductsStore,
});
