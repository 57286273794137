import React, {useState} from 'react'
import T from "../../../components/i18n";
import { LocalIcon } from 'whitelables/wl-name/icons'
import CopyText from "../../../components/copy-text/copy-text";
import User from "../../../user/user-store";
import {Button} from "react-bulma-components";
import SalesStore from "../sales-store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import printPdf from "../../../tools/print";
import ClientsStore from "../../clients/clients-store";

const ClientDelivery = ({clientID, clientDeliverID, clientDeliveryInfo, trackingNumber}) => {
	const [timout, setTimout] = useState(null)
	const [disabledPrint, setDisabledPrint] = useState(false)

	const deliveryInfoSplit = `${clientDeliveryInfo}`.split(':')
	const deliveryId = deliveryInfoSplit[0]

	let deliverName = clientDeliveryInfo
	let deliveryIcon = ''
	let isNoWrap = true

	const clientDeliveryShort = ClientsStore.getClientDeliveryShortLabel(
		clientID,
		clientDeliverID,
		User.getLang()
	);

	if(parseInt(deliveryId) === 1){
		if(clientDeliveryShort) {
			deliverName = clientDeliveryShort
		} else {
			deliverName = 'nova_poshta'
		}
		deliveryIcon = 'nova-poshta'
	} else if (parseInt(deliveryId) === 3) {
		deliverName = `${T("ukr_poshta")}${clientDeliveryShort ? clientDeliveryShort : ''}`
		deliveryIcon = 'ukr-poshta'
	} else if(parseInt(deliveryId) === 6) {
		deliverName = 'rozetka'
		deliveryIcon = 'rozetka'
	} else {
		if(`${clientDeliveryInfo}`.length > 20){
			isNoWrap = false
		}
	}

	const handlePrint = (e) => {
		e.stopPropagation();
		clearTimeout(timout)

		printPdf(SalesStore.printTTN(trackingNumber));
		setDisabledPrint(true)
		const timeoutPrint = setTimeout(() => {
			setDisabledPrint(false)
			clearTimeout(timout)
		}, 2000)

		setTimout(timeoutPrint)
	}

	return !!deliverName && (
		<div>
			<span className={`display-flex-row ${isNoWrap ? 'nowrap' : ''}`}>
				<LocalIcon
					icon={deliveryIcon}
					size='small'
				/>
				{T(deliverName)}
			</span>
			{!!trackingNumber && (
				<div className='display-flex-row'>
					<b>{T("ttn")}:</b> <CopyText text={trackingNumber}/>
					{(User.isNpCreateTTNEnable() || User.isUpCreateTTNEnable() || parseInt(deliveryId) === 6) && (
						<Button
							className='button-link-icon'
							disabled={disabledPrint}
							onClick={handlePrint}
						>
							<FontAwesomeIcon icon="print" size="1x"/>
						</Button>
					)}
				</div>
			)}
		</div>
	);
};

export default ClientDelivery;