import React, {useState} from "react";
import { redirectTo } from "../../tools/redirect-to";
import {Box, Button} from "react-bulma-components";
import NotificationRow from "./mobile/notification-row";
import s from "./styles.module.scss";
import NotificationStore from "./notification-store";
import {connectToStores} from "../../tools/reflux-tools";
import T from "../../components/i18n";
import SearchString from "../../components/base-filter-layout/search-string";
import {filterSearch} from "../../tools/filters-helper";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";
import NotificationActions from "./notification-actions";

const NotificationMobilePage = () => {
  const {isMobileSize} = useIsMobile()
  const messages = NotificationStore.get('messages')
  const [searchFilter, setSearchFilter] = useState('')

  const filterMessage = messages.filter(item => {
      return filterSearch(searchFilter, ['message', 'name'], item);
  })

  const handleSearchFilter = ({name, value}) => {
    if(name) {
      setSearchFilter(value);
    }
  }

  const listRead = messages?.filter(item => !item.is_read)
  const handleReadAllMessage = () => {
    messages.forEach(item => {
      if(item.color === "news" && !NotificationStore.getReedNew(item.id)) {
        NotificationStore.setReadNew(item.id, true);
      }
    })

    const withoutNews = listRead.filter(item => item.color !== 'news')

    if(withoutNews.length) {
      NotificationActions.readMessage(withoutNews.map(item => item.id));
    } else {
      NotificationActions.loadMessages()
    }
    NotificationStore.removeAllFrontNotification()
  }
  return (
    <>
      <Box>
        <div className='margin-bottom-10'>
          <SearchString onChange={handleSearchFilter} defaultValue={searchFilter}/>
            {!!listRead.length && <div className='display-flex-row'
                                       style={{justifyContent: 'flex-end', width: '100%'}}>
              <Button className='button-link-without-style'
                      onClick={handleReadAllMessage}>{T('read-all')}</Button>
          </div>}
        </div>
        <div className={s.list}>
          {filterMessage.map((message) => (
            <NotificationRow key={message.id} {...message} />
          ))}
        </div>
        {!filterMessage.length && (
          <Box>{T("not-have-notification")}</Box>
        )}
      </Box>
      {!isMobileSize && redirectTo("/")}
    </>
  );
};

export default connectToStores(NotificationMobilePage, {_: NotificationStore})
