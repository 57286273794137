import React, {useEffect, useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from	'../../../components/i18n'
import {Label, Textarea} from "react-bulma-components/lib/components/form";
import SubmitButton from "../../../components/submit-button";
import {Button} from "react-bulma-components";
import {updateOrder} from "./actions-func";
import OrdersActions from "../orders-actions";
import {errorHandler} from "../../../tools/error-handler";
import errorMessageControlHook
	from "../../../components/error-message-block/error-message-control.hook";
const CancelledOrder = ({data, onClose}) => {
	const [value, setValue] = useState('')
	const [waiting, setWaiting] = useState(false)
	const {handleAutoRemoveError,ErrorMessageBlock,errorMessage,setErrorMessage} = errorMessageControlHook()
	const handleSubmit = () => {
		setWaiting(true)
		updateOrder(data, "cancelled", value)
	}

	useEffect(() => {
		const loadEdit = OrdersActions.updateStatusOrder.completed.listen(() => {
			OrdersActions.loadRefund()
			onClose()
		})
		const loadEditFail = OrdersActions.updateStatusOrder.failed.listen((res) => {
			const message = errorHandler(res)
			handleAutoRemoveError(message)

		})
		return () => {
			loadEdit()
			loadEditFail()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ErrorMessageBlock message={errorMessage} setErrorMessage={setErrorMessage} />
			<Box>
				<Heading>
					{T('are-you-sure-cancel the order')}
				</Heading>
				<Label>{T('reason-cancellation')}</Label>
				<Textarea value={value} onChange={({target: {value}}) => setValue(value)} />
				<div className='display-flex-row margin-top-10' style={{ justifyContent: 'flex-end' }}>
					<SubmitButton
						text='confirm-order-shift'
						fullwidth={false}
						color='success'
						size='medium'
						rounded
						submit={handleSubmit}
						waiting={waiting}
					/>
					<Button size='medium' rounded onClick={onClose}>
						{T('close')}
					</Button>
				</div>
			</Box>
		</>
	);
};

export default CancelledOrder;