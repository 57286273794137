import React from "react";
import T from "../../../../../components/i18n";

const FieldDesc = ({ name, value, withoutTwoDots }) => {
  return (
    value && (
      <>
        <span className="silver">
          {T(name)}
          {!withoutTwoDots && ": "}
        </span>
        <b>{value}</b>
      </>
    )
  );
};

export default FieldDesc;
