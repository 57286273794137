import UserActions from "../../../../user/user-actions";

export const filterData = (inputValue, list) => {
	if (inputValue.length < 1) return [];

	let result = list.filter(i =>
		i.label.toLowerCase().split("(")[0].includes(inputValue.toLowerCase())
	);
	return result.slice(0, 100);
};

export const isValidURL = (value) => {
	return /^(?:(?:(?:https?|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
}


export const isValidKeys = (list, field, isNumber) => {
	return field.every(item => list[item]?.length === isNumber)
}

export const isMinValue = (list, field, min) => {
	return field.every(item => list[item] < min)
}

export const handleDeleteIntegration = (id) => {
	if(id) {
		UserActions.deleteIntegration(id)
	}
}

export const parseObj = (json) => {
	let result = {}

	try {
		result = JSON.parse(json)
	} catch (err) {
		result = {}
	}

	return result
}
