import React from "react";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../components/i18n";
import WarehouseStore from "../../../warehouse/warehouse-store";

const WriteOffInfoTitleBlock = ({mid}) => {
	return (
		<>
			<Heading>{T('write-off-count-item-added')}</Heading>
			<Heading subtitle>
				{T('source')}: {T(WarehouseStore.getWarehouseName(mid))} <br/>
			</Heading>
		</>

	);
};

export default WriteOffInfoTitleBlock;