import React from 'react'
import WarehouseStore from "../../warehouse/warehouse-store";
import SelectComponent from "../../../tools/select-component";
import HistoryStore from "../history-store";
import HistoryActions from "../history-actions";

const ProductsDeletedFilter = () => {
	let listWarehouse = WarehouseStore.getWarehouseList();


	const handleChangeSelect = (selectedOption, actionMeta) => {
			HistoryActions.filterDeleted(actionMeta.name, selectedOption);
	}

	return listWarehouse.length > 1 && (
		<SelectComponent
			name="mid"
			empty="all-warehouse"
			label='history-data-marketplace_id'
			onChange={handleChangeSelect}
			value={HistoryStore.getFilterDeleted("mid")}
			list={() => listWarehouse}
		/>
	)
};

export default ProductsDeletedFilter;