import React from "react";
import Button from "react-bulma-components/lib/components/button";
import T from "../../../../components/i18n";

const PriceActions = ({ onClose, onSubmit }) => {
  return (
    <div>
      <div align="right">
        <Button size="small" rounded color="primary" onClick={onSubmit}>
          {T("generate")}
        </Button>
      </div>
      <Button.Group>
        <Button size="medium" rounded color="light" onClick={onClose}>
          {T("close")}
        </Button>
      </Button.Group>
    </div>
  );
};

export default PriceActions;
