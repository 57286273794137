import React from "react";
import Container from "../../common/container/container";
import T from "../../../components/i18n";
import s from "./styles.module.scss";
import Title from "../../common/title/title";

const StatisticsItem = ({ title, text }) => {
  return (
    <article className={s.item}>
      <span className={s.item_title}>{T(title)}</span>
      <span className="very_big_amount">{text}</span>
    </article>
  );
};

const ClientStatistics = () => {
  return (
    <Container classNameContainer={s.section}>
      <Title>
        {T("our-clients")}
      </Title>
      <section className={s.list}>
        <StatisticsItem title="many-products" text={`1.5 ${T("mln")}`} />
        <StatisticsItem title="many-sales" text={`1.35 ${T("mln")}`} />
        <StatisticsItem title="many-sell" text={`3.3 ${T("mlr")}`} />
        <StatisticsItem title="many-profit" text={`1.7 ${T("mlr")}`} />
      </section>
    </Container>
  );
};

export default ClientStatistics;
