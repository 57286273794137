import React from 'react';
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";
import { redirectTo } from 'tools/redirect-to'
import { 
    Field, 
    Control, 
    Input,
    Label, 
} from 'react-bulma-components/lib/components/form';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import ClientsStore from '../clients-store'
import Actions from '../clients-actions'

import Columns from 'react-bulma-components/lib/components/columns';

import Messages from 'components/messages';
import BaseForm from 'components/modules/base-form';
import isValidPhone from 'tools/valid-phone';

class UkrPoshta extends BaseForm {
    constructor(props) {
        super(props);
        this.UP = 3;
        const data = props || {};
        let phone = "";
        if (data.phone) {
          phone = data.phone.value || data.phone;
          if (["+", "3"].includes(phone[0]) === false) {
              phone = "+38"+phone;
          }
        }

        let name = "";
        if (data.name) {
          name = data.name.value || data.name;

          if (name.value === "") {
            name = name.value;
          }

          if (name && name.indexOf("(") > -1) {
              name = name.split("(")[0];
          }
        }

        this.state = {
          client_id: data.client_id || null,
          name: name || "",
          phone: phone || data.phone || "+380",
          postcode: data.postcode || "",
          region: data.region || "",
          city: data.city || 0,
          district: data.district || 0,
          street: data.street || "",
          tin: data.tin || "",
          houseNumber: data.houseNumber || "",
          apartmentNumber: data.apartmentNumber || "",
          sender: data.sender || false,
          message: null,
          close: false
        };

        this.submit = this.submit.bind(this);
    }

    componentWillUnmount() {
      if (typeof(this.listen) == "function") {
          this.listen();
      }
    }

    componentDidMount() {
        const {onClose} = this.props;

        this.listen = Actions.addDeliveryService.completed.listen(() => {
            if (typeof(onClose) == "function") {
                setTimeout(() => {onClose()}, 100);
            }
        });

        this.listenFailed = Actions.addDeliveryService.failed.listen((res) => {
          const data = JSON.parse(res['response']);
            this.setState({
              'message':  T(data['error'])
            });
        });
    }

    submit() {
        let data = {
              did: this.UP,
              cid: this.state.client_id,
              name: this.state.name,
              phone: this.state.phone,
              postcode: this.state.postcode,
              region: this.state.region,
              city: this.state.city,
              district: this.state.district,
              street: this.state.street,
              houseNumber: this.state.houseNumber,
              apartmentNumber: this.state.apartmentNumber,
              tin: this.state.tin
        }

        if (this.state.sender) {
          data['sender'] = this.state.sender;

          if (!data['tin'] || data['tin'].length < 5) {
            this.setState({
              message: T('not all fields required')
            })
            return;
          }
        }

        if (!isValidPhone(data['phone'])) {
          this.setState({
              message: T('not all fields required')
          })
          return;
        }

        if (data['name'].length < 5) {
          this.setState({
              message: T('client-name-placeholder')
          })
          return;
        }


        Actions.addDeliveryService(data);
    }


    render() {
      const button_text = this.state.sender ? 'add-sender' : 'add-delivery-address-btn';

      return (
              <Box>
                <Messages message={this.state.message} close={() => this.setState({message: null})} />
                <Columns>
                  <Columns.Column>
                    <Field>
                      <Control>
                        <Label>{T('client-phone')}</Label>
                        <Input  size="large" 
                              name="phone" 
                              type="text" 
                              disabled={this.state.sender ? false : true}
                              placeholder={T('client-phone-placeholder')}
                              onChange={this.onChangeSimple} 
                              value={this.state.phone}
                              />
                      </Control>
                    </Field>
                  </Columns.Column>
                  <Columns.Column>
                      <Field>
                        <Control>
                          <Label>{T('client-name')}</Label>
                          <Input  size="large" 
                                name="name" 
                                type="text" 
                                placeholder={T('client-name-placeholder')}
                                onChange={this.onChangeSimple} 
                                value={this.state.name}
                                />
                        </Control>
                      </Field>
                  </Columns.Column>
                </Columns> 
                <Columns>
                  <Columns.Column>
                    <Field>
                        <Control>
                          <Label>{T('postcode')}</Label>
                          <Input  size="large" 
                                name="postcode" 
                                type="text" 
                                placeholder={T('postcode-placeholder')}
                                onChange={this.onChangeSimple} 
                                value={this.state.postcode}
                                />
                        </Control>
                      </Field>
                  </Columns.Column>
                  {this.state.sender ? 
                  <Columns.Column>
                    <Field>
                        <Control>
                          <Label>{T('tin')}</Label>
                          <Input  size="large" 
                                name="tin" 
                                type="text" 
                                placeholder={T('tin-placeholder')}
                                onChange={this.onChangeSimple} 
                                value={this.state.tin}
                                />
                        </Control>
                      </Field>
                  </Columns.Column>
                  : null}
                 
                </Columns>
                
                <Button.Group>
                  <Button fullwidth size="large"  rounded color="primary"
                    onClick={this.submit}
                    >{T(button_text)}</Button>
                </Button.Group>
                {this.state.close ? redirectTo("/clients") : ""}
              </Box>
        );
    }

}

export default connectToStores(UkrPoshta, { 
    client: ClientsStore
});
