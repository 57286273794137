import {createActions} from "../../tools/reflux-tools";

const ChatsActions = createActions({
    add: { asyncResult: true },
    delete: { asyncResult: true },
    load: { asyncResult: true },
    loadMessages: { asyncResult: true },
    bindingClient: { asyncResult: true },
    createCallBinotel: { asyncResult: true },
    internalNumbersBinotel: { asyncResult: true },
    authOlx: {asyncResult: true },
    loadChatStatus: {asyncResult: true },
    addChatStatus: {asyncResult: true },
    deleteChatStatus: {asyncResult: true },
    syncUserChatStatus: {asyncResult: true },
    loadBindingClients: {asyncResult: true },
    loadByChatId: {asyncResult: true },
    filter: {sync: true },
});

export default ChatsActions;