import React, { Component } from "react";
import T from "components/i18n";
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import { redirectTo } from 'tools/redirect-to'
import trackEvent from 'tools/track-events';
import User from 'user/user-store';
import UserActions from 'user/user-actions';
import Money from "components/money";

class PaymentSuccess extends Component {
	constructor(props) {
        super(props);
        this.state = {
          redirect: false
        };

        this.redirect = this.redirect.bind(this);
    }

    componentDidMount() {
        let tarif_data = User.getTarifDataAccount()

        trackEvent('users', 'payment', 'liqpay', tarif_data['amount'][1], 'USD');
        UserActions.loadProfile()
    	setTimeout(this.redirect, 5000)

    }


    redirect() {
    	this.setState({
    		"redirect": true
    	})
    }

    render() {
        return (
        	<Box>
        	<Heading className="has-text-success">{T('subscribe-success-check')}</Heading>
            <Heading className="has-text-success" subtitle>{T('expected_goods_worth_short')}: <Money className="tarif-cost" amount={User.getTarifCost()} /></Heading>
        	{this.state.redirect ? redirectTo("/dashboard") : "" }
        	</Box>
        );
    }
}

export default PaymentSuccess;