import { createActions } from "../../tools/reflux-tools";

const MovingActions = createActions({
  add: { asyncResult: true },
  delete: { asyncResult: true },
  load: { asyncResult: true },
  movingAll: { asyncResult: true },
  filter: { sync: true },
});

export default MovingActions;
