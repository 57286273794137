
import React, { useEffect, useState } from "react";
import T from "components/i18n";
import yajax from 'yajax';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import ReportsList from 'components/reports-list';
import Messages from 'components/messages';
import PartnerRow from './partner-row';
import PartnersStats from './partners-stats';
import PartnersLink from './partners-link';
import Content from 'react-bulma-components/lib/components/content';
import CopyBanner from "./copy-banner/copy-banner";
import { bannerList } from 'whitelables/wl-name/banners/banner-list'
import BeforeBannerText from "./copy-banner/before-banner-text";




const PartnersTab = () => {
  const [partners, setPartners] = useState([]);
  const [message, setMessage] = useState(null);
  const [msgColor, setMsgColor] = useState(null)
  
  const fetchPartners = async () => {
      try {
        const response = await yajax.get("/userprofile/get_partners");
        if (response.data?.length > 0) {
          setPartners(response.data);
        }
      } catch (error) {
        setMessage(T("getting_partners_error"));
        console.error('Getting partners error:', error);
      }
    };

  useEffect(() => {
    fetchPartners();
  }, [])

  const COLUMNS = [
    "partner-id",
    "partner-email",
    "date-of-registration",
    "partners-balance",
    "partners-pay-out"
  ];
  
  const emptyMessage = () => {
    return (
      <Box align="center">
        <Heading>{T('no-partners')}</Heading>
      </Box>
    ) 
  }

  return (
    <>
    <Box className="partners-tab">
      <PartnersLink setMessage={setMessage.bind(this)} setMsgColor={setMsgColor.bind(this)}/>
      <Messages message={message} color={msgColor} close={() => setMessage(null)} />
      <hr/>
      {partners.length > 0 ? <><Heading size={5} className="margin-bottom-10">{T('partners-list-heading')}</Heading><Content>{T("how-to-get-pay-out")}</Content></> : null}
      <PartnersStats partners={partners} />
      <ReportsList
        emptyMessage={emptyMessage} 
        columns={COLUMNS} 
        hideFooterOnEmpty
        rows={partners}>
          <PartnerRow role="row" />
      </ReportsList>
    </Box>
      {!!bannerList.length && <Box>
        {!!bannerList?.length && <BeforeBannerText/>}
        {bannerList?.map(item => <CopyBanner key={item} img={item}/>)}
      </Box>}
    </>)

}

export default PartnersTab