import React from 'react'
import User from "../../../user/user-store";
import OpenInsideModal from "../../../components/modal/open-inside-modal";
import T from "../../../components/i18n";
import RefundModal from "../refund-modal";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const RefundProductButton = ({data, name, orderID, aid, status}) => {
	const {isMobileSize} = useIsMobile()
	if (!User.getPermissionEnable("refund-products")) {
		return <></>
	}

	if (status !== "saled") return <></>

	return (
			<OpenInsideModal
				size="large"
				color="success"
				renderAs={isMobileSize ? "a" : "button"}
				text={T(isMobileSize ? '' : 'refund-item')}
				icon="undo"
			>
				<RefundModal {...data} fromAccount={aid} product_name={name} oid={orderID}/>
			</OpenInsideModal>
	);
};

export default RefundProductButton;