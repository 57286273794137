import React from 'react';
import ScrollToTopOnMount from 'tools/scroll-top'
// import T from "components/i18n";
import Button from 'react-bulma-components/lib/components/button';

// import Heading from 'react-bulma-components/lib/components/heading';
import Notification from 'react-bulma-components/lib/components/notification';

const Messages = ({message, close, color, timeout}) => {
	if (!message) return null;
	if (!color) color = "danger";

	if (typeof(message['msg']) !== "undefined") {
		message = message['msg'];
	}
	
	if (typeof(timeout) === "undefined") {
		timeout = 5000;
	}

	setTimeout(()=>close(), timeout);
	
	return (
		<Notification color={color}>
            <ScrollToTopOnMount />
            {message}
            <Button remove onClick={close} />
        </Notification>
	)

}

export default Messages;