import React, {useEffect, useState} from 'react'
import Columns from "react-bulma-components/lib/components/columns";
import T from "../../../../../../components/i18n";
import {
	Checkbox,
	Control,
	Field, Textarea
} from "react-bulma-components/lib/components/form";

const SmsNotificationItem = ({name, id, defaultValue, onChange}) => {
	const [value, setValue] = useState(defaultValue || {
		active: false,
		text: ''
	})

	const handleUpdateCheckbox = (evt) => {
		const target = evt.target
		const name = target.name
		const value = name === 'active' ? target.checked : target.value

		setValue(prevState => ({
			...prevState,
			[name]: value
		}))
	}

	useEffect(() => {
		onChange(value, id)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<Columns>
			<Columns.Column >
				<b>{T(name)}</b>
				<Field>
					<Control>
						<Checkbox
							name="active"
							onChange={handleUpdateCheckbox}
							checked={value.active}
						>
							{T('send')}
						</Checkbox>
					</Control>
				</Field>
			</Columns.Column >
			<Columns.Column >
				<Control iconRight>
					<Textarea
						name="text"
						type="text"
						rows={2}
						placeholder={T('text-sms')}
						onChange={handleUpdateCheckbox}
						value={value.text}
					/>
				</Control>
			</Columns.Column>
		</Columns>
	);
};

export default SmsNotificationItem;