import React, { useEffect, useRef, useState } from "react";
import ChatsStore from "../chats-store";
import { connectToStores } from "../../../tools/reflux-tools";
import ChatsActions from "../chats-actions";
import ClientsActions from "../../clients/clients-actions";
import s from "./styles.module.scss";
import { useParams } from "react-router-dom";
import UsersList from "./users-list";
import ActiveChat from "./active-chat/active-chat";
import ActiveClient from "./active-client/active-client";
import ClientsStore from "../../clients/clients-store";
import UserStore from "../../../user/user-store";

const UsersChat = () => {
  const { id } = useParams();

  useEffect(() => {
    ChatsActions.load();
    ClientsActions.load();
  }, []);

  const userChats = ChatsStore.getChats()
  const [isActiveUsers, setIsActiveUsers] = useState(true);
  const [isActiveClient, setIsActiveClient] = useState(true);

  const [isFloatUsers, setIsFloatUsers] = useState(false);
  const [isFloatClient, setIsFloatClient] = useState(false);

  const ref = useRef();

  const toggleIsActiveClient = () => {
    setIsActiveClient((prev) => !prev);
  };
  const toggleIsActiveUsers = () => {
    setIsActiveUsers((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = ref?.current?.getBoundingClientRect().width;
      if (containerWidth < 900) {
        setIsActiveClient(false);
        setIsFloatClient(true);
      } else {
        setIsActiveClient(true);
        setIsFloatClient(false);
      }
      if (containerWidth < 600) {
        setIsActiveUsers(false);
        setIsFloatUsers(true);
      } else {
        setIsActiveUsers(true);
        setIsFloatUsers(false);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className={s.wrapper} ref={ref}>
        <UsersList
          data={userChats}
          activeChat={id}
          isActiveUsers={isActiveUsers}
          isFloat={isFloatUsers}
          onClose={toggleIsActiveUsers}
        />
        <ActiveChat
          id={id}
          onIsActiveClient={toggleIsActiveClient}
          onIsActiveUsers={toggleIsActiveUsers}
          isActiveUsers={isActiveUsers}
          isActiveClient={isActiveClient}
        />
        <ActiveClient
          id={id}
          isActiveClient={isActiveClient}
          isFloat={isFloatClient}
          onClose={toggleIsActiveClient}
        />
      </div>
    </div>
  );
};

export default connectToStores(UsersChat, {
  chats: ChatsStore,
  client: ClientsStore,
  user: UserStore,
});
