import React from "react";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";
import s from "./styles.module.scss";

const FieldDescMobile = ({ name, value, classNameText }) => {
  return (
    <div className={s.field_desk}>
      <Heading heading>{T(name)}</Heading>
      <b className={classNameText}>{value}</b>
    </div>
  );
};

export default FieldDescMobile;
