import React from "react";
import User from "../../user/user-store";
import { Box, Columns } from "react-bulma-components";
import T from "../../components/i18n";
import Money from "../../components/money";
import UserStore from "../../user/user-store";

const TransactionsStatistics = ({ statistics }) => {
  if (!statistics) return <></>;
  const keysStatistics = Object.keys(statistics);
  const userCurrency = UserStore.get("currency_id");

  return (
    <Columns style={{ display: "flex" }}>
      {User.getPermissionEnable("showbalance") && (
        <Columns.Column>
          <Box>
            <b>{T("start-period")}</b>
            <br />
            {keysStatistics?.map((item) => (
              <React.Fragment key={`period_first-${item}`}>
                <Money
                  currency={item}
                  amount={statistics[item]?.period_first}
                  offDept
                />
              </React.Fragment>
            ))}
            {!keysStatistics.length && (
              <Money currency={userCurrency} amount={0} offDept />
            )}
          </Box>
        </Columns.Column>
      )}
      {User.getPermissionEnable("showbalance") && (
        <Columns.Column>
          <Box>
            <b>{T("end-period")}</b>
            <br />
            {keysStatistics.map((item) => (
              <React.Fragment key={`period_last-${item}`}>
                <Money
                  currency={item}
                  amount={statistics[item]?.period_last}
                  offDept
                />
              </React.Fragment>
            ))}
            {!keysStatistics.length && (
              <Money currency={userCurrency} amount={0} offDept />
            )}
          </Box>
        </Columns.Column>
      )}
      <Columns.Column>
        <Box>
          <b>{T("count-transaction")}</b>
          <br />
          {keysStatistics.map((item) => (
            <React.Fragment key={`count-${item}`}>
              {T(`currency-${item}`).toUpperCase()} - {statistics[item]?.count}
              {T("items-p")}
              <br />
            </React.Fragment>
          ))}
          {!keysStatistics.length && <>0 {T("items-p")}</>}
        </Box>
      </Columns.Column>
      {User.getPermissionEnable("show-cashflow") && (
        <Columns.Column>
          <Box>
            <b>{T("sum-coming")}</b>
            <br />
            {keysStatistics.map((item) => (
              <React.Fragment key={`coming-${item}`}>
                <Money
                  className="text-success"
                  currency={item}
                  amount={statistics[item]?.coming}
                  offDept
                />
              </React.Fragment>
            ))}
            {!keysStatistics.length && (
              <Money
                className="text-success"
                currency={userCurrency}
                amount={0}
                offDept
              />
            )}
          </Box>
        </Columns.Column>
      )}
      {User.getPermissionEnable("show-cashflow") && (
        <Columns.Column>
          <Box>
            <b>{T("commission-sum")}</b>
            <br />
            {keysStatistics.map((item) => (
              <React.Fragment key={`coming-${item}`}>
                <Money
                  signClassname
                  currency={item}
                  amount={statistics[item]?.commission}
                  offDept
                />
              </React.Fragment>
            ))}
            {!keysStatistics.length && (
              <Money
                signClassname
                currency={userCurrency}
                amount={0}
                offDept
              />
            )}
          </Box>
        </Columns.Column>
      )}
      {User.getPermissionEnable("show-cashflow") && (
        <Columns.Column>
          <Box>
            <b>{T("sum-expense")}</b>
            <br />
            {keysStatistics.map((item) => (
              <React.Fragment key={`coming-${item}`}>
                <Money
                  className="text-danger"
                  currency={item}
                  amount={Math.abs(statistics[item]?.expense || 0)}
                  offDept
                />
              </React.Fragment>
            ))}
            {!keysStatistics.length && (
              <Money
                className="text-danger"
                currency={userCurrency}
                amount={0}
                offDept
              />
            )}
          </Box>
        </Columns.Column>
      )}
    </Columns>
  );
};

export default TransactionsStatistics;
