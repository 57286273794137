import React from 'react'
import SelectComponent from "../../../tools/select-component";
import MovingStore from "../moving-store";
import MovingActions from "../moving-actions";
import WarehouseStore from "../../warehouse/warehouse-store";
import SelectProducts from "../../../components/select-products";

const MovingFilter = () => {
	const handleChangeSelect = (selectedOption, actionMeta) => {
			MovingActions.filter(actionMeta.name, selectedOption);
	}

	const handleChangeProduct = (selectedOption) => {
		MovingActions.filter("product", selectedOption);
	}

	return (
		<>
			<SelectProducts
				onChange={handleChangeProduct}
				value={MovingStore.getFilter("product")}
				single={true}
				quantity={1} />
			<SelectComponent
				name='status'
				label="status"
				list={() => [
					{id: 'completed', name: 'completed'},
					{id: 'pending', name: 'pending'},
				]}
				empty={'all'}
				onChange={handleChangeSelect}
				value={MovingStore.getFilter('status')}
			/>
			<SelectComponent
				name="from_mid"
				empty="all-warehouse"
				label='from-product-marketplace'
				onChange={handleChangeSelect}
				value={MovingStore.getFilter("from_mid")}
				list={WarehouseStore.getWarehouseList}
			/>
			<SelectComponent
				name="to_mid"
				empty="all-warehouse"
				label='to-product-marketplace'
				onChange={handleChangeSelect}
				value={MovingStore.getFilter("to_mid")}
				list={WarehouseStore.getWarehouseList}
				/>
		</>
	);
};

export default MovingFilter;