import React from 'react'
import GroupButtonsWrapperDesktop from "./group-buttons-wrapper-desktop";
import GroupButtonsWrapperMobile from "./group-buttons-wrapper-mobile";
import {useIsMobile} from "../is-mobile-context/is-mobile-context";

const MassButton = ({view, count,onDeSelect, children}) => {
	const {isMobileSize} = useIsMobile()
	return  view ? (isMobileSize ?
			(
				<GroupButtonsWrapperMobile count={count} onDeSelect={onDeSelect}>
					{children}
				</GroupButtonsWrapperMobile>
			) : (
				<GroupButtonsWrapperDesktop count={count} onDeSelect={onDeSelect}>
					{children}
				</GroupButtonsWrapperDesktop>
			)
	) : null
};

export default MassButton;