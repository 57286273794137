import React, {useEffect} from "react";
import s from "./styles.module.scss";
import { LocalIcon } from "whitelables/wl-name/icons";
import AppStore from "../../../../app-store";
import LoadImages from "./load-images/load-images";
import ChatsActions from "../../chats-actions";
import ChatsStore from "../../chats-store";

const FileInput = ({ chatId, message, blobFilesInPaste, onChangeMessage, onDisabledSend }) => {
  const handleSubmitData = ({ files, message }) => {
    const data = {
      chat_id: chatId,
    };
    if (message) {
      data.message = message;
    }
    if (files?.length) {
      data.files = files;
    }

    if (files.length || message) {
      ChatsActions.add(data);
      onChangeMessage && onChangeMessage('')
    }
  };

  const chatData = ChatsStore.getChatsById(chatId)[0]

  const handleClickAddFile = () => {
    onDisabledSend(true)
    AppStore.openModal(
      <LoadImages
        chatId={chatId}
        onSubmit={handleSubmitData}
        messageData={message}
        onDisabledSendParent={onDisabledSend}
        withVideoFiles={chatData.system_id === 13}
      />
    );
  };

  useEffect(() => {
    if(blobFilesInPaste) {
      onDisabledSend(true)
      AppStore.openModal(
        <LoadImages
          chatId={chatId}
          onSubmit={handleSubmitData}
          blobFile={blobFilesInPaste}
          messageData={message}
          onDisabledSendParent={onDisabledSend}
          onChangeMessage={onChangeMessage}
          withVideoFiles={chatData.system_id === 13}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[blobFilesInPaste])

  return (
    <div>
      <span className={s.clip_file} onClick={handleClickAddFile}>
        <LocalIcon icon="paper-clip" size="large" />
      </span>
    </div>
  );
};

export default FileInput;
