import React from "react";
import T from "../../../components/i18n";
import s from "./styles.module.scss";
import AppStore from "../../../app-store";
import { Link } from "react-router-dom";

const NavItem = ({ label, link }) => {
  return (
    <li>
      <a className={s.link} href={link}>
        {T(label)}
      </a>
    </li>
  );
};

const NavMenu = ({ list }) => {
  return (
    <nav className={s.nav}>
      <input id={s.burger} type="checkbox" />
      <label htmlFor={s.burger} className={s.burger_btn}>
        <span></span>
      </label>
      <ul className={s.list_link}>
        {list.map((item, index) => (
          <NavItem key={item.label} {...item} index={index} />
        ))}
        <li className={s.actions}>
          <Link
            to={AppStore.getLink("registration")}
            className={`button is-info ${s.link_action}`}
          >
            {T("try-free")}
          </Link>
          <Link
            to={AppStore.getLink("login")}
            className={`button is-info-light ${s.link_action}`}
          >
            {T("login-btn-label")}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavMenu;
