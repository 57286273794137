import React, { useEffect } from "react";

import Button from "react-bulma-components/lib/components/button";
import ChangeStatusModal from "../change-status-modal";
import AppStore from "../../../app-store";
import T from "../../../components/i18n";
import SelectStore from "../select-store";
import SalesStore from "../sales-store";
import { confirmDelete } from "../../../tools/confirm-delete";
import SalesActions from "../sales-actions";
import MassDeliveryModal from "../mass-modals/mass-delivery-modal";
import MassCodModal from "../mass-modals/mass-cod-modal";
import UserStore from "../../../user/user-store";
import PrintModal from "../print-modal";
import MassFinishReserv from "../mass-modals/mass-finish-reserv";

const GroupButtonList = () => {
  const ids = Object.keys(SelectStore.getListSelect());
  const idsWithoutSaled = SalesStore.getSalesWithoutSaled(ids);
  const idsWithoutSaledWithDelivering = SalesStore.getSalesWithoutSaledWithDeliveringRejection(ids);
  const getCodSales = SalesStore.getSalesCODWithIds(ids);
  const salesByIds = SalesStore.getSalesByIdsWithoutCOD(ids)
  const salesReserv = SalesStore.getSalesByReserv(ids)

  const handleDelete = () => {
    confirmDelete(
      () => SalesActions.deleteSale(JSON.stringify(ids)),
      "confirm-delete",
      "are-you-sure-remove-sales"
    );
  };

  useEffect(() => {
    const deleteSale = SalesActions.deleteSale.completed.listen(() => {
      SelectStore.clearSelectGroup();
    });

    return () => {
      deleteSale();
    };
  }, []);

  return (
    <>
      {!!ids.length && (
        <Button
          size="small"
          rounded
          color="info"
          onClick={() => AppStore.openModal(<PrintModal oid={ids} />)}
        >
          {T("mass-printing")}
        </Button>
      )}
      {!!idsWithoutSaledWithDelivering.length && (
        <Button
          size="small"
          rounded
          color="success"
          onClick={() =>
            AppStore.openModal(
              <ChangeStatusModal
                modal="change-status"
                massList={idsWithoutSaledWithDelivering}
              />
            )
          }
        >
          {T("change-order-status")}
        </Button>
      )}
      {!!idsWithoutSaled.length && (
        <Button
          size="small"
          rounded
          color="success"
          onClick={() =>
            AppStore.openModal(
              <MassDeliveryModal
                modal="change-status"
                massList={idsWithoutSaled}
              />
            )
          }
        >
          {T("send-parcels")}
        </Button>
      )}
      {!!getCodSales.length && (
        <Button
          size="small"
          rounded
          color="success"
          onClick={() =>
            AppStore.openModal(<MassCodModal massList={getCodSales} />)
          }
        >
          {T("pick-up-transfer")}
        </Button>
      )}
      {!!salesByIds.length && (
        <Button
          size="small"
          rounded
          color="success"
          onClick={() =>
            AppStore.openModal(<MassCodModal massList={salesByIds} isNotCod/>)
          }
        >
          {T("change-account")}
        </Button>
      )}
      {!!salesReserv.length && (
        <Button
          size="small"
          rounded
          color="success"
          onClick={() =>
            AppStore.openModal(<MassFinishReserv massList={salesReserv}/>)
          }
        >
          {T("finish-reserv-title")}
        </Button>
      )}
      {UserStore.getPermissionEnable("deletesales") && (
        <Button size="small" rounded color="warning" onClick={handleDelete}>
          {T("delete")}
        </Button>
      )}
    </>
  );
};

export default GroupButtonList;
