import React from "react";
import { Button } from "react-bulma-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppStore from "../../../../app-store";
import AddMinBalance from "./modal/add-min-balance/add-min-balance";
import { confirmDelete } from "../../../../tools/confirm-delete";
import UserStore from "../../../../user/user-store";

const RowDesktop = ({ data }) => {
  return (
    <tr key={`wrapper-${data.name[0]}`}>
      <td>
        {data?.name.map((item) => (
          <React.Fragment key={item}>
            <b>{item}</b>
            <br />
          </React.Fragment>
        ))}
      </td>
      <td>{data?.minStock}</td>
      <td>
        <div className="display-flex-row">
          <Button
            className="button-link-without-style"
            onClick={() =>
              AppStore.openModal(
                <AddMinBalance
                  modal="min-balance"
                  defaultType={data?.type}
                  defaultMinStock={data?.minStock}
                  defaultSelectValue={data.selectList}
                  index={data?.index}
                />
              )
            }
          >
            <FontAwesomeIcon icon="edit" />
          </Button>
          <Button
            className="button-link-without-style"
            onClick={() =>
              confirmDelete(() =>
                UserStore.deleteMinStockProduct({
                  type: data.type,
                  index: data.index,
                })
              )
            }
          >
            <FontAwesomeIcon icon="trash-alt" />
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default RowDesktop;
