import Reflux from "reflux";

const WriteOffActions = Reflux.createActions({
	add: { asyncResult: true },
	delete: { asyncResult: true },
	addCategory: { asyncResult: true },
	deleteCategory: { asyncResult: true },
	loadCategory: { asyncResult: true },
	load: { asyncResult: true },
});

export default WriteOffActions;