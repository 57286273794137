export const GOOGLE_API = "686256594077-q5r4upvtjceppmkihgov27n7qkiqevhd.apps.googleusercontent.com"

export const AVAILABLE_LANGS = {
    ru: {
        full: "Русский",
        short: "Рус"
    },    
    ua: {
        full: "Українська",
        short: "Укр"
    },
    en: {
        full: "English",
        short: "Eng"
    }
};