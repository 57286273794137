import {useState} from "react";
import DateRange from "../../components/base-filter-layout/date-range";

const useDateRangeHook = ({defaultDateStart, defaultDateEnd}) =>  {
	const [dateStart, setDateStart] = useState(defaultDateStart || new Date())
	const [dateEnd, setDateEnd] = useState(defaultDateEnd || new Date())


	const handleChangeDate = (value) => {
		setDateStart(value.date_start)
		setDateEnd(value.date_end)
	}

	return {
		dateStart,
		dateEnd,
		DateRange,
		onChangeDate: handleChangeDate
	}
}

export default useDateRangeHook;