import React from "react";
import BaseFilterLayout from "../../../components/base-filter-layout";
import MovingStore from "../moving-store";
import MovingActions from "../moving-actions";
import MovingFilter from "./moving-filter";

const FilterPanel = () => {
  const handleChangeDate = (value) => {
    const daysKey = Object.keys(value).reverse();
    daysKey.forEach((item) => {
      MovingActions.filter(item, value[item]);
    });
  };

  const handleRefreshFilter = () => {
    MovingStore.clearFilter()
    MovingActions.load()
  }

  return (
    <BaseFilterLayout
      refresh={{ onChange: handleRefreshFilter }}
      dateRangeSetting={{
        onChange: handleChangeDate,
        date_start: MovingStore.getFilter("date_start"),
        date_end: MovingStore.getFilter("date_end"),
      }}
      hiddenFilter={<MovingFilter/>}
    />
  );
};

export default FilterPanel;
