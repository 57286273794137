import React from 'react'
import {Box, Button} from "react-bulma-components";
import T from '../../../components/i18n'
import yajax from "yajax";
import {LocalIcon} from 'whitelables/wl-name/icons'
import AppStore from "../../../app-store";
import TelegramSettings from "./telegram-settings";
import {createMobileAppLink} from "../../../tools/create-mobile-app-link";

const TelegramSubscribe = () => {

	const handleSubscribe = () => {
		yajax.get('/bmail/tg_deeplink?type=subscribe').then((res) => {
			if(res.link && !AppStore.isMobileApp()) {
					window.open(res.link, '_blank')
			}
			if(AppStore.isMobileApp()) {
					createMobileAppLink(res.link)
			}
		});
	}

	return (
		<Box>
			<div className='display-flex-center'>
				<Button color='info' rounded onClick={handleSubscribe}>{T('subscribe-telegram-bot')}</Button>
				<Button className='button-icon' onClick={() => AppStore.openModal(<TelegramSettings/>)}>
					<LocalIcon icon='gear' size='large'/>
				</Button>
			</div>
		</Box>
	);
};

export default TelegramSubscribe;