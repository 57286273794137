import React from "react";
import OpenModalButton from "../../../components/modal/open-modal-button";
import T from "../../../components/i18n";
import Level from "react-bulma-components/lib/components/level";

const RegistrationButton = ({data, status}) => {
	let color
	let text
	let state = {}

	if(status === 'completed'){
		color = 'info'
		text = 'see-product-btn'
		state.onlyShow = true
		state.step = 5
	} else {
		color = 'success'
		state.step = 2
		text = 'finish-write-off-btn'
	}

	return (
		<Level.Item key="regBut">
			<OpenModalButton size="small"  color={color} link={{
				pathname: '/write-off/edit',
				state: {...state, 'data': data }
			}} text={T(text)} />
		</Level.Item>
	);
};

export default RegistrationButton;