
export const ToLocaleDateString = (data) => {
	let date = data

	if (typeof date === "string") {
		const parsedDate = Date.parse(date);

		if (!isNaN(parsedDate)) {
			date = new Date(parsedDate);
		} else {
			return date;
		}
	}

	if (!(date instanceof Date) || isNaN(date)) {
		return date;
	}

	return date.toLocaleDateString();
};

