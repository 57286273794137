import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bulma-components";

const CopyButton = ({text}) => {
	const handleCopy = (evt) => {
		setTimeout(async () => {
			if (navigator.clipboard) {
				try {
					await navigator.clipboard.writeText(text);
				} catch (error) {
					console.log('failed copy')
				}
			} else {
				console.log('failed copy')
			}
		}, 100);
	};

	return (
		<div>
			<Button className='button-link-icon' onClick={handleCopy}>
				<FontAwesomeIcon icon={'copy'} />
			</Button>
		</div>
	);
};

export default CopyButton;