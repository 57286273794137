import React from 'react'
import SelectComponent from "../../../tools/select-component";
import ExpensesStore from "../../expenses/expenses-store";
import HistoryStore from "../history-store";
import HistoryActions from "../history-actions";
import SalesStore from "../../sales/sales-store";
import ClientsStore from "../../clients/clients-store";
import WarehouseStore from "../../warehouse/warehouse-store";
import UserStore from "../../../user/user-store";
import ProductsStore from "../../products/products-store";
import {connectToStores} from "../../../tools/reflux-tools";

const SalesDeletedFilter = () => {
	const handleChangeSelect = (selectedOption, actionMeta) => {
		HistoryActions.filterDeletedSales(actionMeta.name, selectedOption);
	}

	const listClients = ClientsStore.getClientsList();
	const listWarehouse = WarehouseStore.getWarehouseList()
	const listUsers = UserStore.getSubUsersList();
	const listCategory = ProductsStore.getCategoryList();

	return (
		<>
			<SelectComponent
				name="account"
				label='sale-account'
				empty="all-account"
				onChange={handleChangeSelect}
				value={HistoryStore.getFilterDeletedSales("account")}
				list={ExpensesStore.getAccountsList}
			/>
			<SelectComponent
				name="channel"
				label="sale-channel"
				empty="all"
				onChange={handleChangeSelect}
				value={HistoryStore.getFilterDeletedSales("channel")}
				list={SalesStore.getChannelsList}
			/>
			{listClients.length >= 1 && <SelectComponent
				name="clients"
				label="clients"
				empty="all"
				onChange={handleChangeSelect}
				value={HistoryStore.getFilterDeletedSales("clients")}
				list={() => listClients}
				async
			/>}
			{UserStore.getModuleEnable("warehouse") && listWarehouse.length >= 1 && <SelectComponent
				name="mid"
				empty="all-warehouse"
				label='history-data-marketplace_id'
				autoSelect={false}
				onChange={handleChangeSelect}
				value={HistoryStore.getFilterDeletedSales("mid")}
				list={WarehouseStore.getWarehouseList}
			/>}
			{listUsers.length >= 1 && UserStore.getPermissionEnable("show-all-sales") && <SelectComponent
				name="rid"
				label="manager"
				empty="all"
				onChange={handleChangeSelect}
				value={HistoryStore.getFilterDeletedSales("rid")}
				list={UserStore.getSubUsersList}
			/>}
			{listCategory.length >= 1 && <SelectComponent
				name="category"
				label="product-category"
				empty="all-categories"
				onChange={handleChangeSelect}
				value={HistoryStore.getFilterDeletedSales("category")}
				list={ProductsStore.getFormattingCategoryList}
			/>}
			<SelectComponent
				name="delivery"
				label="delivery-service"
				empty="all"
				onChange={handleChangeSelect}
				value={HistoryStore.getFilterDeletedSales("delivery")}
				list={UserStore.getDeliveryServiceBase}
			/>
			{!!ClientsStore.getDeliveryListCity()?.length && (
				<SelectComponent
					name="deliveryCity"
					label="delivery-city"
					empty="all"
					onChange={handleChangeSelect}
					value={HistoryStore.getFilterDeletedSales("deliveryCity")}
					list={ClientsStore.getDeliveryListCity}
				/>)}
		</>
	);
};

export default connectToStores(SalesDeletedFilter, {ProductsStore,
	UserStore,
	ClientsStore});