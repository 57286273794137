import React from "react";
import SubmitButton from "../../../../components/submit-button";

const ActionButton = ({ waiting, onSubmit }) => {
  return (
    <div
      className="display-flex-column"
      style={{
        flex: 1,
        alignItems: "flex-end",
        gap: 5,
        justifyContent: "flex-end",
      }}
    >
      <SubmitButton
        text="save-btn-label"
        size="large"
        submit={onSubmit}
        waiting={waiting}
      />
    </div>
  );
};

export default ActionButton;
