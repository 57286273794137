import React from 'react'
import Dropdown from "react-bulma-components/lib/components/dropdown";
import Money from "../../../../components/money";

const CustomPriceDropdownItem = ({name, value, pid_mid, typePrice, onChange, onChangeType, refresh}) => {

		const handleClick = () => {
			const evt = {
				target: {
					name: `pid-${pid_mid}`,
					value: value || 0
				}
			}
			onChange && onChange(evt)
			onChangeType && onChangeType({type: 'custom-price', pid: pid_mid, value: name})
			refresh(prev => prev + 1)
		}

		return (
			<Dropdown.Item value={name} key={name} style={name === typePrice ? {fontWeight: '700'} : {}}>
				<div onClick={handleClick} className='dropdown-custom-price-item'>
              <span>
                {name}
              </span>
					<Money amount={value}/>
				</div>
			</Dropdown.Item>)
	}

export default CustomPriceDropdownItem;