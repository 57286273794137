import React, { useState } from "react";
import {Box, Heading, Icon} from "react-bulma-components";
import T from "../../../../components/i18n";
import SelectComponent from "../../../../tools/select-component";
import UserStore from "../../../../user/user-store";
import {
	Checkbox, Control,
	Field, Input,
	Label
} from "react-bulma-components/lib/components/form";
import ActionsModal from "./actions-modal";
import NumberInput from "../../../../components/number-input/number-input";
import errorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import Button from "react-bulma-components/lib/components/button";
import AppStore from "../../../../app-store";
import ImageModal from "../../../../components/image-modal";
import InfoWrapper from "../../../../components/info-wrapper";

const PriceTagSettingPriceModal = ({ activeList, onSubmit, onClose }) => {
	const {errorMessage,setErrorMessage,ErrorMessageBlock,handleAutoRemoveError} = errorMessageControlHook()
  const customPrice = UserStore.getCustomPriceForList();
  const [selectValue, setSelectValue] = useState(
		activeList && activeList?.field
      ? { value: activeList.field, label: activeList.field }
      : { value: 0, label: T('basic') }
  );
	const [crossedOut, setCrossedOut] = useState(!!activeList && !!activeList?.crossed_out )
	const [crossedSize, setCrossedSize] = useState((!!activeList && activeList?.crossed_size) || 5 )
	const [valuePosition, setValuePosition] = useState(activeList.position ? {value: activeList.position} : {value: 1})
	const [valueFontSize, setValueFontSize] = useState(activeList.size ? activeList.size : 7)

	const handleChangeSelect = (selectMeta) => {
		setSelectValue(selectMeta)
	}
	const handleChangeCheckbox = ({target: {checked}}) => {
		setCrossedOut(checked)
	}

	const handleChangeInput = ({target: {value}}) => {
		setCrossedSize(value)
	}

	const handleErrorFontSize = () => {
		let error
		if ((crossedSize < 5 || crossedSize > 30)) {
			error = 'price-tag-error-font-5'
		}
		if(valueFontSize < 7 || valueFontSize > 30) {
			error = T('price-tag-error-font')
		}
		if(error) {
			handleAutoRemoveError(error)
		}

			return !error
	}

	const handleSubmit = () => {
		if(handleErrorFontSize()) {
			const data = {
				size: valueFontSize,
				position: valuePosition.value
			}
			if(selectValue.value !== 0) {
				data.field = selectValue.value
				data.crossed_out = crossedOut
				data.crossed_size = crossedSize
			}

			onSubmit(data)
			onClose && onClose()
		}
	}

	const handleChangeInputValue = (evt) => {
		const value = evt.target.value
		const name = evt.target.name
		if(name === 'fontSize') setValueFontSize(+value)
	}

	const generatePositionList = Array.from({length: 8},
		(_, index) => ({id: index + 1, name: index + 1 + ' - ' + T(`price-tag-position-${index + 1}`)}) )

  return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
			<Box>
				<Heading>
					{T("detailed-customization")}: {T("price-tag-price")}
				</Heading>
				<>
					<Field>
						<div align='right'>
							<Button
								size='small'
								rounded
								color='info'
								onClick={() => AppStore.openModal(<ImageModal src='/img/position-price-tag.jpg' alt='position price tag'/>)}
							>
								{T('revising-positioning-view')}
							</Button>
						</div>
						<Control>
							<SelectComponent
								name="position"
								label="price-tag-position-label"
								value={valuePosition}
								onChange={setValuePosition}
								list={() => generatePositionList}
								creatable={false}
							/>
						</Control>
					</Field>
					<Field >
						<Label>{T('price-tag-font-size')}</Label>
						<Control iconRight>
							<Input
								name='fontSize'
								size='large'
								type='number'
								min='7'
								onChange={handleChangeInputValue}
								value={valueFontSize || ''}
							/>
							<Icon align="right" style={{top: 0}}>
								<span className="small-text">pt</span>
							</Icon>
						</Control>
					</Field>
				</>
				<SelectComponent
					label={<InfoWrapper info={T('when-additional-price-tip')}>{T("price-tag-price")}</InfoWrapper>}
					name="field"
					empty='basic'
					value={selectValue}
					onChange={handleChangeSelect}
					list={() => customPrice}
					creatable={true}
				/>
				{selectValue.value !== 0 && <>
					<Field>
						<Label>{T('font-size-crossed-out')}</Label>
						<Control iconRight>
							<NumberInput
								name='crossed_size'
								value={crossedSize}
								onChange={handleChangeInput}
							/>
							<Icon align='right'>
								<span className="small-text">pt</span>
							</Icon>
						</Control>
					</Field>
					<Field>
						<Checkbox name='crossed_out' checked={crossedOut}
											onChange={handleChangeCheckbox}>
							{T('original-crossed-out')}
						</Checkbox>
					</Field>
				</>}
				<ActionsModal onSubmit={handleSubmit} onClose={onClose}/>
			</Box>
		</>
  );
};

export default PriceTagSettingPriceModal;
