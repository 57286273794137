import React from "react";
import FieldDescMobile from "./field-desc-mobile";

const StatisticsBlock = ({ list }) => {
  return (
    <div className="display-flex-center flex-wrap margin-bottom-15 margin-top-10">
      {list?.map((item) => (
        <FieldDescMobile
          key={item.name}
          classNameText="font-size-120"
          {...item}
        />
      ))}
    </div>
  );
};

export default StatisticsBlock;
