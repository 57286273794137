import React, { Component } from "react";
import PropTypes from "prop-types";
import T from "components/i18n";
import Spinner from "templates/spinner";
import Columns from 'react-bulma-components/lib/components/columns';
import List from 'react-bulma-components/lib/components/list';
import Loader from 'react-loader-spinner'
import { LocalIcon } from 'whitelables/wl-name/icons'
import ProgressBar from "../progress-bar";
import {withIsMobileSize} from "../is-mobile-context/is-mobile-context";

class ReportsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: {}
        }
        this.handleSort = this.handleSort.bind(this);
    }

    static contextTypes = {
        addScrollListener: PropTypes.func,
        removeScrollListener: PropTypes.func
    };

    onScroll = ({ scrollPos, scrollSize, clientSize }) => {
        const { date, slice, isAllSliceLoaded, isLoading } = this.props;

        if (date || isLoading || isAllSliceLoaded) {
            return;
        }

        if (((scrollSize - scrollPos) / clientSize) < 1.5) {
            this.props.load({ slice: slice + 1 });
        }
    };

    renderEmpty() {
        // const date = this.props.filter.date;
        const Message = this.props.emptyMessage;
             // columns = this.props.columns;
              // date = "08.01.2020";
              //_toUserTimeZone(date) === _toUserTimeZone()
        return (
            <List.Item align="center">{(true) ? <Message /> : T('not-found-records')}</List.Item>
        );
    }

    renderContent(components) {
        const { isLoading, rows } = this.props;
        const { spinner, rowsWrapper, empty } = components;

        if (isLoading && !rows.length) {
            return spinner || <Spinner />;
        }

        if (!rows || !rows.length) {
            return empty || this.renderEmpty(components);
        }

        const clonedRows = rows.map((row, i) => React.cloneElement(components.row, {
            key: i + 1,
            num: i + 1,
            data: row
        }));

        if (isLoading) {
            clonedRows.push(spinner || <Spinner key="spin" />);
        }

        return rowsWrapper ? React.cloneElement(rowsWrapper, {}, clonedRows) : clonedRows;
    }

    handleSort(value, onChangeSortActions, isSortNotRefValue) {
        let sortFilter = (this.state.sort[value] && this.state.sort[value] !== 2) ? 0 : 1

        if(this.state.sort[value] === 0) {
            sortFilter = 2
        }

        this.setState({
            sort: {[value]: sortFilter}
        })

        let refactoringValue = value
        if(!isSortNotRefValue) {
            refactoringValue = value?.split('-')[1] === 'date' ? 'created_at' : value?.split('-')[1]
        }
        onChangeSortActions({[refactoringValue]: sortFilter})
    }

    renderHeader(_columns, isSortingColumns, sortActions, isSortNotRefValue, stickyFirstColumnHeader) {
        if (_columns.length === 0) return null;
        let { sizes } = this.props;
        if (!sizes) {
            sizes = [null,null,null,null,null,null];
        }
        return (
            <List.Item>
                <Columns className={this.props.isMobileSize ? "is-mobile" : ""}>
                    {_columns.map((title, idx) => (
                      <Columns.Column key={idx} size={sizes[idx] || null} className={stickyFirstColumnHeader && idx === 0 ? 'column-name-wrapper' : '' }>
                          {stickyFirstColumnHeader && idx === 0 ? (
                            <div className='column-name'>
                                <b>{T(title)}</b>
                                {!!isSortingColumns?.length && isSortingColumns[idx] && (
                                  <div
                                    className='icon-wrapper'
                                    onClick={() => this.handleSort(title, sortActions, isSortNotRefValue)}
                                  >
                                      <LocalIcon icon="sort-arrow-up" size="small" className={`arrow-up ${this.state.sort[title] === 1  ? 'active' : ''}` }/>
                                      <LocalIcon icon="sort-arrow-down" size="small" className={`arrow-down ${this.state.sort[title] === 0 ? 'active' : ''}`}/>
                                  </div>)}
                            </div>
                          ) : (
                            <>
                                <b>{T(title)}</b>
                                {!!isSortingColumns?.length && isSortingColumns[idx] && (
                                  <div
                                    className='icon-wrapper'
                                    onClick={() => this.handleSort(title, sortActions, isSortNotRefValue)}
                                  >
                                      <LocalIcon icon="sort-arrow-up" size="small" className={`arrow-up ${this.state.sort[title] === 1  ? 'active' : ''}` }/>
                                      <LocalIcon icon="sort-arrow-down" size="small" className={`arrow-down ${this.state.sort[title] === 0 ? 'active' : ''}`}/>
                                  </div>)}
                            </>
                          )}
                      </Columns.Column>
                    ))}
                </Columns>
            </List.Item>
        );
    }

    renderFooter(_columns) {
        const { rows, columns } = this.props;

        if (!rows || !rows.length || rows.length < 10) { 
            return null;
        }

        return this.renderHeader(columns)
    }


    render() {
        const components = {};
        const { children, columns, store, isSortingColumns, sortActions,isSortNotRefValue, isLoading, hideFooterOnEmpty, minWidthList, stickyFirstColumnHeader } = this.props;

        React.Children.forEach(children, (child) => {
            components[child.props.role] = child;
        });
 
        return (
            <>
                {isLoading && <ProgressBar/>}
                {store && store.get("isLoading") ? 
                    <div className="is-vcentered">
                        <Loader type="Rings" color="#00BFFF" height={80} width={80} />
                    </div>
 
                : 
                <List hoverable style={this.props.isMobileSize ? {minWidth: '0'} : minWidthList ? { minWidth: minWidthList } : {}} >
                    {this.renderHeader(columns, isSortingColumns, sortActions, isSortNotRefValue, stickyFirstColumnHeader)}
                    {this.renderContent(components)}
                    {!hideFooterOnEmpty && this.renderFooter(columns)}
                </List> 
            }
            </>
        );
    }
}

export default withIsMobileSize(ReportsList);
