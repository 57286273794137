import React, { Component } from 'react';
import T from "components/i18n";
import LoadFiles from "components/load-files";

import Box from 'react-bulma-components/lib/components/box';
import Actions from './shipments-actions' 
import Messages from 'components/messages';


class LoadExcelShipmentsModal extends Component {
    constructor(props) {
        super(props);
      this.state = {
          sid: props.sid,
          mid: props.mid,
          message: null,
          waiting: false,
        };

        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentWillUnmount() {
      if (typeof(this.loadExcel) == "function") {
          this.loadExcel();
      }
    }         
    componentDidMount() {      
            const {onClose} = this.props;  
      this.loadExcel = Actions.loadExcel.completed.listen(() => {
          this.setState({
            'message': {
                'msg': T('file_need_wait')
             },
             'color': 'success'
          })

          setTimeout(() => {
          this.props.checkShipment();
          if (typeof(onClose) == "function") {
              onClose();
          }}, 10000);
        });

      this.loadExcelFailed = Actions.loadExcel.failed.listen(() => {
          this.setState({
            'message': {
                'msg': T('error-while-load-filed')
            },
            'color': 'warning',
            'waiting': false,
          })
        });

    }
    submit(sid, mid, file, template) {        
        this.setState({
          waiting: true
        })

      Actions.loadExcel(sid, mid, file, template);
    }

    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: {
            'value': value
          }
        });
    }


    render () {
        const options = [
          { value: '0', label: T('new-file') },
        ];
        return (
              <Box>
                <Messages message={this.state.message} color={this.state.color} timeout={10000} close={() => this.setState({message: null})} />
                  <LoadFiles base_text="load-excel-shipments-desk" submit={this.submit} templates={options} {...this.props} waiting={this.state.waiting}/>
              </Box>
        );
    }

}

export default LoadExcelShipmentsModal;