import React, {useState} from 'react'
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";
import IntegrationControlHook from "../common/integration-control.hook";
import Box from "react-bulma-components/lib/components/box";
import InputsGroupField from "../common/inputs-group-field";
import ActionsModal from "../common/actions-modal";
import ModalItsOK from "../../../../../components/modal-its-ok";
import UserActions from "../../../../../user/user-actions";
import {handleDeleteIntegration, parseObj} from "../utils";
import {confirmDelete} from "../../../../../tools/confirm-delete";
import IntegrationListenerHook from "../common/integration-listener.hook";
import AddSender from "./add-sender";
import User from "../../../../../user/user-store";

const DATA_INPUT = [
	{
		name: "api_key",
		placeholder: "login-placeholer"
	},
	{
		name: "api_pass",
		placeholder: 'password-placeholder'
	},
]

const TurboSMS = ({data, wooID, onClose}) => {
	const {api_key, api_pass, ident, is_active, id} = data

	let identParse = typeof ident === 'string' ? parseObj(ident) : ident

	const {
		handleAutoRemoveError,
		setErrorMessage,
		errorMessage,
		inputValues,
		handleChangeInputValues,
		selectValue,
		handleChangeSelect
	} = IntegrationControlHook({
		inputDefaultValue: {
			api_key: api_key || null,
			api_pass: api_pass || null,
			url: identParse?.url || ''
		},
		checkboxDefaultValue: {
			update_stock: identParse?.update_stock || false,
			update_price: identParse?.update_price || false,
			update_orders: identParse?.update_orders || false
		},
		selectDefaultValue: {
			sender: {value: identParse?.sender || 0}
		}
	})

	const [waiting, setWaiting] = useState(false)
	const {submitOK, additionalMessage} = IntegrationListenerHook({handleError: handleAutoRemoveError, setWaiting})

	const handleError = () => {
		let errorMessage;

		if(!inputValues.api_key.length){
			errorMessage = 'wrong-key'
		}

		if(errorMessage) {
			handleAutoRemoveError(errorMessage)
			return false
		}

		return  true
	}

	const handleSubmit = ({deactivate}) => {
		const data = {
			"integration_id": wooID,
			"api_key": inputValues.api_key,
			"api_pass": inputValues?.api_pass,
			"ident": JSON.stringify({
				sender: inputValues?.sender_text_field || selectValue?.sender?.value,
				settings: ident?.notification
			}),
			is_active: 1
		}

		if(id) data.id = id
		if(deactivate) data.is_active = 0

		if(handleError()) {
			setWaiting(true)
			UserActions.updateIntegrations(data)
		}
	}

	const cheackDefaultNameTurboSMS = User.getTurboSMSlist().some(item => item.name === ident?.sender)
	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!submitOK && <Box>
				<Heading size={3}>{T('setting-integration', [T(`integration-${wooID}`)])}</Heading>
				<InputsGroupField inputValues={inputValues} dataInput={DATA_INPUT}  onChange={handleChangeInputValues}/>
				<AddSender
					valueInput={inputValues?.sender_text_field}
					valueSelect={selectValue?.sender}
					onChangeInput={handleChangeInputValues}
					onChangeSelect={handleChangeSelect}
					additionalSender={!cheackDefaultNameTurboSMS && ident?.sender}
				/>
				<ActionsModal
					onSubmit={handleSubmit}
					onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
					onClose={onClose}
					isEdit={!!id}
					isActive={is_active}
					waiting={waiting}/>
			</Box>}
			{submitOK && <ModalItsOK onClose={onClose} title={submitOK} additionalMessage={additionalMessage}/>}
		</>
	);
};

export default TurboSMS;