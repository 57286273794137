import React from "react";
import { Button } from "react-bulma-components";
import s from "./styles.module.scss";
import { LocalIcon } from "whitelables/wl-name/icons";

const ButtonArrow = ({ onClick, orientation }) => {
  return (
    <Button
      onClick={onClick}
      className={`button-link-icon ${
        orientation === "left" ? s.not_rotate_icon : s.rotate_icon
      }`}
    >
      <LocalIcon icon="left-arrow" />
    </Button>
  );
};

export default ButtonArrow;
