import React from "react";
import ChatUserInfo from "../common/chat-user-info";
import s from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import ChatsStore from "../chats-store";
import ButtonArrow from "./common/button-arrow";
import {Input} from "react-bulma-components/lib/components/form";
import T from '../../../components/i18n'
import { LocalIcon } from 'whitelables/wl-name/icons'
import {filterChats} from "../utils";
import ChatsActions from "../chats-actions";

const UsersList = ({ data, activeChat, isActiveUsers, isFloat, onClose }) => {
  const history = useHistory();

  const handleClickUser = (userId) => {
    if (parseInt(activeChat) !== userId) {
      ChatsStore.clearMessage();
      history.push(`/chats/${userId}`);
      if (isFloat) {
        onClose();
      }
    }
  };

  const handleSearchChange = (event) => {
    ChatsActions.filter('search', event.target.value);
  };


  return (
    isActiveUsers && (
      <div className={`${s.wrapper_users} ${isFloat ? s.is_float : ""}`}>
        {isFloat && (
          <div className={s.close_button_wrapper}>
            <ButtonArrow onClick={onClose} orientation="left" />
          </div>
        )}
        <div className={s.wrapper_search}>
          <Input
            value={ChatsStore.getFilter("search")}
            onChange={handleSearchChange}
            placeholder={T('search')}
          />
          {ChatsStore.getFilter("search").length > 0 && <span className={s.delete_search} onClick={() => ChatsActions.filter('search', '')}>
            <LocalIcon icon='close-fill'/>
          </span>}
        </div>
        {filterChats(data, ChatsStore.get("_filter"))?.map((item) => (
          <div
            key={item.id}
            onClick={() => handleClickUser(item.id)}
            className={
              parseInt(activeChat) === item.id ? s.user_item_active : ""
            }
          >
            <ChatUserInfo data={item} isActive={parseInt(activeChat) === item.id}/>
          </div>
        ))}
      </div>
    )
  );
};

export default UsersList;
