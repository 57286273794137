import yajax from "yajax";

import T from "components/i18n";

import { createStore } from "../../tools/reflux-tools";
import Actions from './history-actions';
import ProductsStore from "../products/products-store";
import {filterSearch} from "../../tools/filters-helper";
import {parseJSON} from "../../tools/error-handler";
import {DTO_products, DTO_stock_products} from "../products/utils";
import ProductsActions from "../products/products-actions";
import React from "react";
import UserStore from "../../user/user-store";
import {getDatePeriod} from "../../tools/date-range";
import SalesStore from "../sales/sales-store";
import ClientsStore from "../clients/clients-store";
import {
    checkAndParseInt
} from "../../tools/check-and-parse-int/check-and-parse-int";
// import ExpensesActions from 'modules/expenses/expenses-actions';
// import ProductActions from '../products/products-actions';
// import User from '../../user/user-store';

const CLEAR_FILTER_DELETED = () => ({
    date_start: getDatePeriod(30),
    date_end: new Date(),
    mid: {value: 0, label: T('all')},
    search: ''
})

const CLEAR_FILTER_DELETED_SALES = () => ({
    status: "all",
    date_start: new Date(),
    date_end: new Date(),
    account: { value: 0, label: T("all-account") },
    clients: { value: 0, label: T("all") },
    brand: { value: 0, label: T("all-brands") },
    channel: { value: 0, label: T("all") },
    mid: { value: 0, label: T("all") },
    rid: { value: 0, label: T("all") },
    category: { value: 0, label: T("all-categories") },
    search: "",
    delivery: { value: 0, label: T("all") },
    deliveryCity: { value: 0, label: T("all") },
    deliveredKeeperDate: { value: 0, label: T("all") },
    commission: { value: 0, label: T("all") },
})

const HistoryStore = createStore({
	listenables: Actions,
    _prefix: '/breports/',
    _prefix_product: '/bproducts/',
    _prefix_sales: '/bsales/',
    state: {
        history: null,
        products_deleted: null,
        stock_marketplaces: null,
        salesDeleted: [],
        _filter: {
            status: "all",
            date_start: new Date(),
            date_end: new Date(),
            account: {'value': 0, label: T('all')},
            action: null,
            product: null,
            category: {'value': 0, label: T('all')},
            mid: {value: 0, label: T('all')},
            search: "",
            rid: {value: 0, label: T('all')},
            brand: {value: 0, label: T('all')},
            source: {value: 0, label: T('all')}
        },
        _filterDeleted: CLEAR_FILTER_DELETED(),
        _filterDeletedSales: CLEAR_FILTER_DELETED_SALES(),
        isLoaded: false,
    },

    getActionList() {
        let list = [];

        for (let i=1; i<=54; i++) {
            list.push({
                'name': T('history-action-'+i),
                'id': i
            });
        }

        return list;
    },

    get(field) {
        return this.state[field];
    },

    getFilter(field) {
        return this.state['_filter'][field];
    },

    clearFilter() {
      this.setState({
          _filter: {
              status: "all",
              date_start: new Date(),
              date_end: new Date(),
              account: {'value': 0, label: T('all')},
              action: null,
              product: null,
              category: {'value': 0, label: T('all')},
              mid: {value: 0, label: T('all')},
              search: "",
              rid: {value: 0, label: T('all')},
              brand: {value: 0, label: T('all')},
              source: {value: 0, label: T('all')}
          },
      })
    },

    onFilter(name, value) {
        let filters = {};

        for (let i in this.state['_filter']) {
            filters[i] = this.state['_filter'][i];
        }
        let date_start = filters['date_start'];
        let date_end = filters['date_end'];

        filters[name] = value;

        this.setState({
            _filter: filters
        });

        if (name === "date_start" && value < date_start) {
            Actions.load();
        }
        if(name === 'date_end' && value > date_end) {
            Actions.load();
        }
    },


    getFilterDeleted(field) {
        return this.state['_filterDeleted'][field];
    },

    getFilterDeletedSales(field) {
        return this.state['_filterDeletedSales'][field];
    },


    onFilterDeleted(name, value) {
        let filters = {};

        for (let i in this.state['_filterDeleted']) {
            filters[i] = this.state['_filterDeleted'][i];
        }
        let date_start = filters['date_start'];
        let date_end = filters['date_end'];

        filters[name] = value;

        this.setState({
            _filterDeleted: filters
        });

        if (name === "date_start" && value < date_start) {
            Actions.loadProductsDeleted();
        }

        if(name === 'date_end' && value > date_end) {
            Actions.loadProductsDeleted();
        }
    },

    onFilterDeletedSales(name, value) {
        let filters = {};

        for (let i in this.state['_filterDeletedSales']) {
            filters[i] = this.state['_filterDeletedSales'][i];
        }
        let date_start = filters['date_start'];
        let date_end = filters['date_end'];

        filters[name] = value;

        this.setState({
            _filterDeletedSales: filters
        });

        if (name === "date_start" && value < date_start) {
            Actions.loadSalesDeleted();
        }

        if(name === 'date_end' && value > date_end) {
            Actions.loadSalesDeleted();
        }
    },

    clearFilterSales() {
            this.setState({
                _filterDeletedSales: CLEAR_FILTER_DELETED_SALES(),
            });
    },

    applyFilter(expenses) {
        const filter = this.state._filter,
              date = new Date(expenses['created_at']*1000);
        let result = true;

        filter['date_start'].setHours(0,0,0,0);
        filter['date_end'].setHours(23,59,59,999);

        if (date < filter['date_start'] || date > filter['date_end']) {
            return false;
        }

        if (filter['search'] !== "" && typeof(filter['search']) !== "undefined") {
            const parseComment = parseJSON(expenses.comment);
            const search_data = {
                order_id: expenses['order_id'],
                product_id: expenses['product_id'],
                shipment_id: expenses['shipment_id'],
                name: parseComment['name'],
                asin: parseComment['asin'],
                order_number: parseComment['order_number'],
                expenses_id: parseComment['expenses_id'] || expenses.expenses_id,
                is_reserved: parseComment?.is_reserved,
                comment: typeof parseComment === 'string' ? parseComment : parseComment['comment'],
            }

            result = result && filterSearch(filter['search'], ['order_id',
                'product_id',
                'shipment_id',
                'name',
                'asin',
                'order_number',
                'expenses_id',
              'is_reserved',
              'comment'
            ], search_data);
        }


        if (filter['source'] && filter['source']['value'] !== 0) {
            const parseComment = parseJSON(expenses.comment);
            const integration = UserStore.getIntegrationNameById(parseComment.integration_id);


            const listStringIntegration = {
                "checkbox": 4,
                "Horoshop": 8,
                "vchasno": 9,
                "OpenCart": 7,
                "PromUa": 5,
                "Rozetka": 6,
                "WooCommerce": 2,
            }

            if(filter['source']?.value !== parseComment['source']) {
                if (`integration-${filter['source']['value']}` !== parseComment['source'] && filter['source']['value'] !== listStringIntegration[parseComment['source']] && `integration-${filter['source']['value']}` !== `integration-${integration?.integration_id}`) {
                    result = result && false;
                }
            }
        }

        if (filter['account'] && filter['account']['value'] !== 0) {
            if (parseInt(filter['account']['value']) !== parseInt(expenses['account_id'])) {
                result = result && false;
            }
        }

        if (filter['category'] && filter['category']['value'] !== 0) {
            if(expenses.product_id) {
                const  getCategoryProduct = ProductsStore.getCategoryById(expenses.product_id)
                if (getCategoryProduct) {
                    result = result && parseInt(filter['category']['value']) === parseInt(getCategoryProduct);
                } else {
                    result = result && false;
                }
            } else {
                result = result && false;
            }
        }
        if (filter['brand'] && filter['brand']['value'] !== 0) {
            if(expenses.product_id) {
                const  getBrandProduct = ProductsStore.getBrandById(expenses.product_id)
                if (getBrandProduct) {
                    result = result && parseInt(filter['brand']['value']) === parseInt(getBrandProduct);
                } else {
                    result = result && false;
                }
            } else {
                result = result && false;
            }
        }


        let isExistProduct = false;
        if (filter['product'] && filter['product'].length > 0 && filter['product'][0]['value'] !== 0) {
            filter["product"].forEach(product => {
                if (parseInt(product['value']) === parseInt(expenses['product_id'])) {
                    isExistProduct = true;
                }

                const parseComment = parseJSON(expenses.comment);

                if(parseComment?.pid_mid && typeof parseComment?.pid_mid === 'string') {
                    parseComment.pid_mid.split(',').forEach(ident => {
                        if(parseInt(ident) === parseInt(product['value'])) {
                            isExistProduct = true;
                        }
                    })
                }

            })

            result = result && isExistProduct;
        }

        let isExistAction = false;
        if (filter['action'] && filter['action'].length > 0 && filter['action'][0]['value'] !== 0) {
            for (let i in filter['action']) {
                let action = filter['action'][i];
                if (parseInt(action['value']) === parseInt(expenses['action_id'])) {
                    isExistAction = true;
                }
            }

            result = result && isExistAction;
        }

        if (filter['mid'] && filter['mid']['value'] !== 0) {
            if (parseInt(filter['mid']['value']) !== parseInt(expenses['marketplace_id'])) {
                result = result && false;
            }
        }
        if (filter['rid'] && filter['rid']['value'] !== 0) {
            if (parseInt(filter['rid']['value']) !== parseInt(expenses['responsible_user_id'])) {
                result = result && false;
            }
        }

        return result;
    },

    onLoad(start, end) {
        if (this.isLoaded) {
            console.log("already start loading history")
            return this.isLoaded;
        }

        this.setState({
            isLoaded: true
        })

        const filter = this.get('_filter')
        const date = {}

        if (filter['date_start']) {
            const date_start = filter['date_start'].setHours(0, 0, 0, 0);
            date.start = parseInt(`${date_start / 1000}`)
        }

        if (filter['date_end']) {
            const date_end = filter['date_end'].setHours(23, 59, 59, 999);
            date.end = parseInt(`${date_end / 1000}`);
        }

        console.log("start load history...", date)
        this.isLoaded = yajax.get(this._prefix+"history", date).then(
            Actions.load.completed, Actions.load.failed
        );
        return  this.isLoaded
    },
    
    onLoadCompleted(data) {
        let result = data['data']
        if(!UserStore.getPermissionEnable('show-all-sales')) {
            result = data['data'].filter(item => {
                console.log(item, item.order_id, parseInt(item.responsible_user_id) === parseInt(UserStore.get('realId')), UserStore.get('realId'))
                if(item.order_id) {
                    return parseInt(item.responsible_user_id) === parseInt(UserStore.get('realId'))
                }
                return item
            })
        }
        if (data['success']) {
            this.setState({
                'history': result
            });
        }
        this.isLoaded = false;

        this.setState({
            isLoaded: false
        })
        console.log("result load history: ", data)
    },
    onLoadFailed(){
        this.isLoaded = false;
        this.setState({
            isLoaded: false
        })
        console.log("failed load history")
    },

    getListHistory() {
      const history = this.get('history')

      if(history === null)  {
          Actions.load()
          this.setState({
              history: []
          })
          return []
      }

      return history
    },

    getHistory() {
        const expenses = this.getListHistory();
        return expenses.filter(this.applyFilter);
    },


    onLoadProductsDeleted() {
      if(this.loadingProductsDeleted) return
        this.loadingProductsDeleted = true;


        const data = {
            t: Date.now()
        }

        const filter = this.get('_filterDeleted')

        if (filter['date_start']) {
            const date_start = filter['date_start'].setHours(0, 0, 0, 0);
            data.start = parseInt(`${date_start / 1000}`)
        }

        if (filter['date_end']) {
            const date_end = filter['date_end'].setHours(23, 59, 59, 999);
            data.end = parseInt(`${date_end / 1000}`);
        }

        yajax.get(this._prefix_product + 'deleted', data).then(Actions.loadProductsDeleted.completed, Actions.loadProductsDeleted.failed)
    },

    getAttrName(id) {
        if (!ProductsStore.state.attrDict) {
            return "";
        }
        const attr = ProductsStore.state.attrDict[id];

        if (typeof(attr) === "undefined") return "";

        const name = attr['name'];
        // return name.indexOf("t-") > -1 ? T(name) : name;
        return T(name)
    },

    buildAttr(data, text){
        let res = [];
        for (let attr_id in data) {
            let attr = data[attr_id],
              attr_name = this.getAttrName(attr_id);

            if (text) {
                res.push(attr_name+": "+ attr);
            } else {
                res.push(
                  <span key={attr_id}><b>{attr_name}:</b> {attr}. </span>
                )
            }
        }

        return res;
    },

    onLoadProductsDeletedCompleted(data) {
        this.loadingProductsDeleted = false;

        const dictProduct = {}

        const loadData = data.data.map(item => {
                const product = DTO_products(item)
                product.updated_at = item.updated_at

                if (product['parent_id']) {
                    let attr = this.buildAttr(JSON.parse(product['attr_desc']), true);
                    product.name += ". "+attr.join(". ");
                }
                for (let i in product['stock']) {
                    product['stock'][i] = DTO_stock_products(product['stock'][i]);
                }
                dictProduct[product.id] = product
                return product
        })

      this.setState({
          products_deleted: loadData,
          dictProductDeleted: dictProduct
      })
    },
    onLoadProductsDeletedFailed() {
        this.loadingProductsDeleted = false;

    },

    getProductDeletedById(id) {
      const data = this.state.dictProductDeleted
      if(data) {
          return data[id] || false
      }

      return false
    },

    getProductsDeletedList() {
      const getList = this.get('products_deleted')

        if(getList === null) {
            Actions.loadProductsDeleted();
            this.setState({
                products_deleted: []
            })
            return []
        }

        return getList
    },
    onRestoreProduct(pid, mid) {
      const data = {pid}

      if(mid) {
          data.mid = mid
      }

        yajax.put(this._prefix_product + 'deleted', data).then((res) => Actions.restoreProduct.completed(pid, res), Actions.restoreProduct.failed)
    },

    onRestoreProductCompleted(pid) {
        Actions.loadProductsDeleted()
        ProductsActions.loadByPid([pid])
    },
    onRestoreProductFailed(){
    },

    onStockMarketplaces(refresh) {
        if(this.loadingStock) return

        this.loadingStock = true

        const data = {}

        if(refresh) {
            data.t= Date.now();
        }

        yajax.get(this._prefix + "get_remote_stock", data).then(Actions.stockMarketplaces.completed, Actions.stockMarketplaces.failed)
    },

    onStockMarketplacesCompleted(data) {
        this.loadingStock = false
        this.setState({
            stock_marketplaces: data.data
        })
    },
    onStockMarketplacesFailed() {
        this.loadingStock = false
    },

    getStockMarketplaces() {
      const data = this.get('stock_marketplaces')
        if(data === null) {
            this.setState({
                stock_marketplaces: []
            })
            Actions.stockMarketplaces()
            return []
        }

        return data
    },
    onRepeatStockMarketplaces(id) {
        yajax.post('/bremote/repeat', {id}).then(Actions.repeatStockMarketplaces.completed, Actions.repeatStockMarketplaces.failed)
    },
    onRepeatStockMarketplacesCompleted() {
        Actions.stockMarketplaces()
    },
    onRepeatStockMarketplacesFailed() {

    },

    getStatusMarketplacesForSelect() {
      return [
          {
              name: 'status-successful',
              id: "1"
          },
          {
              name: 'status-created',
              id: "0",
          },
          {
              name: "status-error",
              id: "2",
          }
      ]
    },

    getStatusMarketplace(status) {
      const data = {
          0: {
              background: '#d4d700',
              name: T('status-created')
          },
          1: {
              background: '#5feeaa',
              name: "status-successful"
          },
          2: {
              background: "#f14668",
              name: "status-error"
          },
          3: {
              background: '#5feeaa',
              name: "status-successful-upload"
          },
          4: {
              background: "#f14668",
              name: "status-error-upload"
          },
      }
      return data[status] || false
    },
    onLoadSalesDeleted(){
            if(this.loadingSalesDeleted) return
            this.loadingSalesDeleted = true;


            const data = {
            }

            const filter = this.state._filterDeletedSales

            if (filter['date_start']) {
                const date_start = filter['date_start'].setHours(0, 0, 0, 0);
                data.start = parseInt(`${date_start / 1000}`)
            }

            if (filter['date_end']) {
                const date_end = filter['date_end'].setHours(23, 59, 59, 999);
                data.end = parseInt(`${date_end / 1000}`);
            }

            yajax.get(this._prefix_sales + `recovery`, data).then(Actions.loadSalesDeleted.completed, Actions.loadSalesDeleted.failed)
    },
    onLoadSalesDeletedCompleted(data) {
        this.loadingSalesDeleted = false;
        let response = [];
        if (data["success"]) {
            response = SalesStore.proccessingSales(data);
            this.setState({
                salesDeleted: response,
            });
        }
    },
    onLoadSalesDeletedFailed() {

    },

    getDeletedSalesById(oid) {
            const sales = this.get('salesDeleted')
            let response = {};
            for (let i in sales) {
                let sale = sales[i];
                if (parseInt(sale["oid"]) === parseInt(oid)) {
                    response = sale;
                }
            }

            return response;
    },

    applyFilterSales(sale) {
        const filter = this.state._filterDeletedSales,
          date = new Date(sale["purchase_date"] * 1000);
        let result = true;


        filter["date_start"].setHours(0, 0, 0, 0);
        filter["date_end"].setHours(23, 59, 59, 999);

        if (date < filter["date_start"] || date > filter["date_end"]) {
            return false;
        }

        if (filter["search"] !== "" && typeof filter["search"] !== "undefined") {
            let search_data = {
                barcode: "",
                sku: "",
                product_name: "",
                order_number:
                  sale["order_number_text"] || sale["order_number"] || sale["id"],
                comment: sale["comment"],
                tracking_number: sale["tracking_number"],
                oid: sale["oid"],
                pids: "",
            };

            for (let i in sale["items"]) {
                let item = sale["items"][i];
                let pr_d = ProductsStore.getProduct(item["product_id"]);
                item["product_name"] = pr_d["name"];
                search_data["pids"] += ` ${item["product_id"]}`;
                search_data["barcode"] += " " + item["barcode"];
                search_data["sku"] += " " + pr_d["skus"];
                search_data["product_name"] +=
                  " " + sale["oid"] + " " + item["product_name"];
            }

            result = filterSearch(
              filter["search"],
              [
                  "barcode",
                  "tracking_number",
                  "product_name",
                  "oid",
                  "sku",
                  "comment",
                  "order_number",
                  "pids",
              ],
              search_data
            );
        }

        if (filter["account"] && filter["account"]["value"] !== 0) {
            if (
              parseInt(filter["account"]["value"]) !== parseInt(sale["account_id"])
            ) {
                if (
                  parseInt(filter["account"]["value"]) !==
                  parseInt(sale["prapaid_account_id"])
                ) {
                    result = result && false;
                }
            }
        }

        if (filter["deliveryCity"] && filter["deliveryCity"]["value"] !== 0) {
            if (sale.clients_delivery_id) {
                result =
                  result &&
                  ClientsStore.hasDeliveryIdForCity(
                    sale.clients_delivery_id,
                    filter["deliveryCity"]["value"]
                  );
            } else {
                result = result && false;
            }
        }

        if (filter["delivery"] && filter["delivery"]["value"] !== 0) {
            if (
              checkAndParseInt(filter["delivery"]["value"]) !==
              checkAndParseInt(sale["delivery_info"])
            ) {
                result = result && false;
            }
        }

        if (filter["channel"] && filter["channel"]["value"] !== 0) {
            if (
              sale["channel_id"].indexOf(parseInt(filter["channel"]["value"])) === -1
            ) {
                result = result && false;
            }
        }

        if (filter["rid"] && filter["rid"]["value"] !== 0) {
            if (
              parseInt(filter["rid"]["value"]) !==
              parseInt(sale["responsible_user_id"])
            ) {
                result = result && false;
            }
        }

        if (filter["clients"] && filter["clients"]["value"] !== 0) {
            if (
              parseInt(filter["clients"]["value"]) !== parseInt(sale["client_id"])
            ) {
                result = result && false;
            }
        }

        if (filter["mid"] && filter["mid"]["value"] !== 0) {
            let warehouse = false;
            for (let i in sale["items"]) {
                let item = sale["items"][i];
                if (parseInt(filter["mid"]["value"]) === parseInt(item["mid"])) {
                    warehouse = true;
                }
            }

            result = result && warehouse;
        }

        if (filter["category"] && filter["category"]["value"] !== 0) {
            let category = false;
            for (let i in sale["items"]) {
                let item = sale["items"][i],
                  product = ProductsStore.getProduct(item["product_id"]);

                let ccat = filter["category"]["value"];
                let childs_category = ProductsStore.get("categoryChild")[ccat];
                if (typeof childs_category === "undefined") {
                    childs_category = [ccat];
                }

                if (childs_category.indexOf(product["category_id"]) > -1) {
                    category = true;
                }
            }

            result = result && category;
        }

        return result;
    },
});

export default HistoryStore;