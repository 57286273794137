import React from 'react';
import ClientsStore from "./clients-store";
import Actions from './clients-actions';
import BaseFilter from "components/modules/base-filters";
import SelectComponent from "../../tools/select-component";
import ClientsActions from "./clients-actions";
import PriceFromToFilter
  from "../../components/base-filter-layout/price-from-to-filter";
import RadioButtonsListControl
  from "../../components/radio-buttons-list/radio-button-list-control";
import T from '../../components/i18n'
import DateRange from "../../components/base-filter-layout/date-range";

class ClientsFilter extends BaseFilter {
    constructor(props) {
        super(props);
        this.state = {};

        this.store = ClientsStore;
        this.actions = ClientsActions;

        this.onChangeRadioBirthday = this.onChangeRadioBirthday.bind(this)
    }

    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name

        Actions.filter(name, value);
    }

    onChangeRadioBirthday({name, value}) {
      if(name === 'withBirthday' && value === '1') {
        Actions.filter('withBirthdayChild', "0");
      } else if(name === 'withBirthdayChild' && value === '1') {
        Actions.filter('withBirthday', "0");
      }
      Actions.filter(name, value);
    }

    componentDidMount() {

    }

    componentWillUnmount() {
      ClientsStore.clearFilter()
    }

    cityFilter() {

      return (
          <SelectComponent
            name="city"
            label='delivery-city'
            onChange={this.onChangeSelect}
            value={ClientsStore.getFilter('city')}
            empty='all'
            list={()=> ClientsStore.getCityList()}
            async={true}
          />
      )
    }

    tagFilter() {
      return (
        <SelectComponent
          label='clients-tags'
          name='tags'
          empty='all'
          onChange={this.onChangeSelect}
          value={ClientsStore.getFilter('tags')}
          list={ClientsStore.getClientTagList}
        />
      )
    }
    birthdayFilter(whoBirthday, withBirthday, label) {
      const list = [
        {name: withBirthday, value: '1', title: 'btn-active'},
        {name: withBirthday, value: '0', title: 'disabled'}
      ]

      const handleChangeDate = (value) => {
        const daysKey = Object.keys(value).reverse()
        daysKey.forEach(item => {
          const name = item === 'date_start' ? `${whoBirthday}_start` : `${whoBirthday}_end`
          this.actions.filter(name, value[item]);
        })
      }

      return (
        <>
          <RadioButtonsListControl
            list={list}
            name={withBirthday}
            label={T(label)}
            valueRadio={ClientsStore.getFilter(withBirthday)}
            onChange={this.onChangeRadioBirthday}
          />
          {ClientsStore.getFilter(withBirthday) === '1' && (
            <DateRange
              label
              date_start={ClientsStore.getFilter(`${whoBirthday}_start`)}
              date_end={ClientsStore.getFilter(`${whoBirthday}_end`)}
              onChange={handleChangeDate}
              range={['week','next-week','this-month','next-month']}
            />)}
        </>


      )
    }

    filterCompany() {
      const list = ClientsStore.getCompanyForSelect();
      if(list) {
        return <SelectComponent
          label='company'
          name='company'
          empty='all'
          onChange={this.onChangeSelect}
          value={ClientsStore.getFilter('company')}
          list={() => list}
        />
      }

      return null
    }

    filterCityClient() {
      const list = ClientsStore.getClientsCity();

      if(list.length) {
        return <SelectComponent
          name="clientsCity"
          label='by-city'
          onChange={this.onChangeSelect}
          value={ClientsStore.getFilter('clientsCity')}
          empty='all'
          list={()=> list}
        />
      }
    }

    render () {
        return (
          <>
            {this.cityFilter()}
            {this.tagFilter()}
            {this.filterCompany()}
            {this.filterCityClient()}
            <PriceFromToFilter
              label='clients-total_amount'
              from={{
                name: 'total_amount_from',
                value: ClientsStore.getFilter('total_amount_from'),
              }}
              to={{
                name: "total_amount_to",
                value: ClientsStore.getFilter('total_amount_to')
              }}
              onChange={this.onChange}
            />
            <PriceFromToFilter
              label='clients-debt'
              from={{
                name: 'debt_from',
                value: ClientsStore.getFilter('debt_from'),
              }}
              to={{
                name: "debt_to",
                value: ClientsStore.getFilter('debt_to')
              }}
              onChange={this.onChange}
            />
            {this.birthdayFilter('birthday', 'withBirthday', 'birthday-clients')}
            {this.birthdayFilter('birthday_child', 'withBirthdayChild', 'client-birth_day_child_1')}
          </>
        )

     }

}

export default ClientsFilter