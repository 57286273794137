import React, { Component } from "react";
import T from "components/i18n";
import Money from "components/money";
// import Level from 'react-bulma-components/lib/components/level';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Columns from 'react-bulma-components/lib/components/columns';

import ExpensesStore from "./expenses-store";


class ExpensesTotal extends Component {
  _sumAmountReport(data) {
      let response = {};
      let receipt = {};

      for(let i in data) {
          const expenses = data[i],
                aid = expenses['account_id'];

          if (expenses['is_receipt']) {
            if (typeof(receipt[aid]) == "undefined") {
              receipt[aid] = 0;
            }
            receipt[aid] += parseFloat(expenses['amount']);
          } else {
            if (typeof(response[aid]) == "undefined") {
              response[aid] = 0;
            }
            response[aid] += parseFloat(expenses['amount']);
          }
      }

      return [response, receipt];
  }

  getItem(data, class_name) {
    let response = [],
        style = { textAlign: 'center' }

    for (let i in data) {
      const d = data[i];

      response.push(
        <Columns.Column>
          <Box style={style} key={"t"+i}>
              <Heading  heading>
                {T(ExpensesStore.getAccountName(i))}
              </Heading>
              <Heading className={class_name}>
                <Money amount={class_name === "has-text-danger" ? d*-1 : d} aid={i} />
              </Heading>
          </Box>
        </Columns.Column>
      );
    }

    return response;
  }
                  // <Heading subtitle className="total-subtitle">{T('total-amount-expenses')}:</Heading>
                  // <Heading subtitle className="total-subtitle">{T('total-amount-receipt')}:</Heading>

  render() {
        let response = [];
        const {sales} = this.props,
              data = this._sumAmountReport(sales);

        if (Object.keys(data[0]).length > 0 ) {
            response.push(this.getItem(data[0], "has-text-danger"));
        }

        if (Object.keys(data[1]).length > 0 ) {
            response.push(this.getItem(data[1], "has-text-success"));
        }

        return  (
          <Columns className="statisctic">
            {response}
          </Columns>
        )
  }

}


export default ExpensesTotal;

