import React, {useEffect, useState} from 'react'
import {parsePhoneNumber} from "../../../../tools/parse-phone-number";
import AppStore from "../../../../app-store";
import UserStore from "../../../../user/user-store";
import ChatsStore from "../../../chats/chats-store";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import {Box, Button, Heading} from "react-bulma-components";
import T from "../../../../components/i18n";
import SelectComponents from "../../../../tools/select-component";
import SubmitButton from "../../../../components/submit-button";



const RingostatModal = ({phone, onClose}) => {
	const workers = UserStore.getRingostatWorkersBySelectByRid(UserStore.get('realId'))


	const [loginWorker, setLoginWorker] = useState({value: workers[0]?.id, label: workers[0]?.name})
	const [waiting, setWaiting] = useState(false)

	const {setErrorMessage,ErrorMessageBlock,errorMessage} = ErrorMessageControlHook()


	const handleChangeWorkers = (selectValue) => {
		setLoginWorker(selectValue)
	}

	const handleSubmit = () => {
		const authMessage = {
			action: 'createCall',
			workerLogin: loginWorker.value,
			phone: phone
		};
		if(phone) {
			setWaiting(true)
			ChatsStore.socketRingostat.send(JSON.stringify(authMessage));
		}
	}

	useEffect(() => {
		const listener = (event) => {
			const message = JSON.parse(event.data);

			if(message.forAction === 'callAnswer') {
				onClose()
			}
			if(message.forAction === 'callStop') {
				setWaiting(false)
			}
		}

		ChatsStore.socketRingostat.addEventListener('message', listener)

		return () => {
			ChatsStore.socketRingostat.removeEventListener('message', listener)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return <>
		<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
		<Box>
			<Heading size={3}>{T('creating-a-call')}</Heading>
			<SelectComponents
				onChange={handleChangeWorkers}
				value={loginWorker}
				list={() => workers}
				label='operator'
				placeholder='select-an-operator'
			/>
			<div className='display-flex-row' style={{ justifyContent: 'flex-end' }}>
				<SubmitButton text='create-call' color='info' size='medium' waiting={waiting} fullwidth={false} submit={handleSubmit}/>
				<Button onClick={onClose} size='medium'>
					{T('close')}
				</Button>
			</div>
		</Box>
	</>
}

const RingostatCreateCall = ({phone}) => {
	const parsePhone = parsePhoneNumber(phone)

	return (
		<img
			className='cursor-pointer'
			src='/img/integrations/ringostat-phone.svg'
			style={{width:'20px',height:'20px'}} alt={`ringostat-${parsePhone}`}
			onClick={() => AppStore.openModal(<RingostatModal phone={parsePhone} modal='integrations'/>)}
		/>
	);
};

export default RingostatCreateCall;