import React, {useState} from "react";
import ProductsStore from "../../../products-store";
import sortByKey from "../../../../../tools/sort-by-key";
import { applyFilter } from "../utils";
import List from "react-bulma-components/lib/components/list";
import {Box, Heading} from "react-bulma-components";
import T from "../../../../../components/i18n";
import usePaginationHook from "../../../../../tools/pagination-hook";
import MovingProductRow from "./moving-product-row";
import StatisticsBlock from "../common/statistics-block";
import {parseJSON} from "../../../../../tools/error-handler";
import {getDatePeriod} from "../../../../../tools/date-range";
import DateRange from "../../../../../components/base-filter-layout/date-range";

const MovingProductBlock = ({ units }) => {
  const history = ProductsStore.get("history");
  const [date, setDate] = useState({
    date_start: getDatePeriod(30),
    date_end: new Date(),
  })
  const filtered = sortByKey(
    history.filter((data) => applyFilter(data, "created_at", 24,false, date)),
    "created_at"
  );

  const handleChangeDate = (value) => {
    setDate(value)
  };


  const objectUniqMoveId = {}

  const checkInUniqMoveId = filtered.map(item => {
    const parseComment = parseJSON(item.comment)
    if(objectUniqMoveId[parseComment.mov_id]) {
      item.is_deleted = true
    } else {
      objectUniqMoveId[parseComment.mov_id] = true;
    }

    return item
  })

  const { page, end, start, total, setPage, PaginationBlock } =
    usePaginationHook({ listLength: checkInUniqMoveId.length, perPage: 4 });

  const calcStatistics = checkInUniqMoveId.reduce(
    (acc, curr) => {
      const comment = JSON.parse(curr["comment"]);
      acc.count += comment["quantity"];
      return acc;
    },
    { count: 0 }
  );

  const statisticsList = [
    {
      name: "moved",
      value: ProductsStore.getStockUnits(calcStatistics["count"], units),
    },
  ];


  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          borderRadius: "0 0 16px 16px",
        }}
      >
        <DateRange
          date_start={date.date_start}
          date_end={date.date_end}
          onChange={handleChangeDate}
        />
      </Box>
      <StatisticsBlock list={statisticsList} />
      <List>
        {checkInUniqMoveId?.slice(start, end).map((item) => (
          <MovingProductRow key={item.id} data={item} units={units} />
        ))}
        {filtered.length === 0 && (
          <div align="center" className="margin-10">
            <Heading size={4}>{T("not-have-operation")}</Heading>
          </div>
        )}
      </List>
      <div className="display-flex-center">
        <PaginationBlock
          totalPage={total}
          setPage={setPage}
          currentPage={page}
          showPrevNext={false}
        />
      </div>
    </>
  );
};

export default MovingProductBlock;
