import SalesStore from "../../../sales/sales-store";

export const applyFilter = (data, date_name, action_id, store, dateFilter, withMultiplication) => {
	let date;
	if (withMultiplication) {
		date = new Date(data[date_name]*1000);
	}  else {
		date = new Date(data[date_name]);
	}

	const storeNow = store || SalesStore
	let date_start = dateFilter ? dateFilter.date_start : storeNow.getFilter("date_start"),
			date_end = dateFilter ? dateFilter.date_end : storeNow.getFilter("date_end");

	date_start.setHours(0,0,0,0);
	date_end.setHours(23,59,59,999);

	if (date < date_start || date > date_end) {
		return false;
	}

	if (action_id) {
		return data.action_id === action_id
	}

	return true;
}

