import React from 'react'
import Box from "react-bulma-components/lib/components/box";
import ProductsImages from "../../../../components/product-images";
import LoadImagesForm from "../../../../components/load-images";
import UserStore from "../../../../user/user-store";
import T from '../../../../components/i18n'
import {Button} from "react-bulma-components";

const LoadImagesWithList = ({pid, submit, back, done}) => {
	return (
		<Box>
			<ProductsImages pid={pid} />
			<div><br /></div>
			{UserStore.getPermissionEnable("editproducts") ? (
				<LoadImagesForm submit={submit} back={back} done={done}/>
			) : (
				<div align='right'>
					<Button onClick={done}>{T('close')}</Button>
				</div>
			)}
		</Box>
	);
};

export default LoadImagesWithList;