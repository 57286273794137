import React, { Component } from 'react';
import yajax from "yajax";
import T from 'components/i18n';
import UserStore from 'user/user-store';
import UserActions from 'user/user-actions';
import SocialLogin from './social-login';
import { getTrafficSource } from "./trafic-source";
import { Field, Control, Input, Help, Label } from 'react-bulma-components/lib/components/form';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Heading from 'react-bulma-components/lib/components/heading';
import isValidEmail from 'tools/valid-email';
import isValidPhone from 'tools/valid-phone';
import trackEvent from 'tools/track-events';
import { loadReCaptcha } from 'react-recaptcha-v3'
import AppStore from 'app-store';
import { backLink } from 'tools/back-link'     
import {withRouter} from "react-router-dom";
import {redirectTo} from "../../tools/redirect-to";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";

class RegForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
          email: {
                error: "",
                value: ""
          },
          phone: {
                error: "",
                value: ""
          },
          password: {
                error: "",
                value: ""
          },
          isCheckBot: false,
          tarif: this.props.location?.tarif || false
        };

        this.onChangeReg = this.onChangeReg.bind(this);
        this.onSendRegistrationData = this.onSendRegistrationData.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.submit = this.submit.bind(this);
        this.whereToSend = this.whereToSend.bind(this);
    }

    componentDidMount() {
       trackEvent('guest', 'registration_page', 'modal');
      loadReCaptcha("6LeTocIZAAAAAIRfeIJfDD8aBCHAczTNqGg8Pqm_", () => {this.setState({'isCheckBot': true})});

      this.loginComplete = UserActions.login.completed.listen((data) => {

        if (data['success']) {
          this.setState({
            login: true
          });
        }
      });
    }

    componentWillUnmount() {
      if (typeof(this.loginComplete) == "function") {
        this.loginComplete();
      }
    }

    _validation(name, val) {
        this._cleanError(name, val);

        let res = this.state, error;

        if (name === "email") {
            error = (!isValidEmail(val)) ? "wrong-email" : "";
        } 

        else if (name === "phone") {
            error = (!isValidPhone(val)) ? "wrong-phone" : "";
        } 
        else if (name === "password" ) {
            error = (!this.isValidPassword(this.state.password.value)) ? "wrong-password" : "";
        }
        
        if (val !== "") {
            res = { [name]: {'error': error, 'value': val} }
            this.setState(res);
        }
    }

    isValidPassword(pass) {
        return (pass.length > 3 );
    }


    _cleanError(name, val) {
        this.setState({
          [name]: {
            'error': '',
            'value': val
          }
        });
    }

    onChangeReg(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: {
            'value': value
          }
        });
    }

    onBlur(evt) {
        const value = evt.target.value,
              name = evt.target.name;
        
        this._validation(name, value);
    }

    submit() {
        console.log("start submit")
        if (!this.state.isCheckBot) {
            trackEvent('guest', 'registration_page', 'problem_check_box');
            console.log("not check box");
            // return false;
        }

        const email = this.state.email.value,
              phone = this.state.phone.value,
              pass = this.state.password.value,
              cur_id = UserStore.get("currency_id");

        if (this.state.email.error !== "" || this.state.password.error !== "") {
          console.log("problem with password or email");
            return false;
        }

        if (phone !== "" && this.state.phone.error !== "") {
            console.log("problem with phone");
            return false;
        }


        const trafficData = getTrafficSource();


        const params = {
            method: "POST",
            url: "/userprofile/register",
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            },
            data: {
                "email": email, 
                "password": pass, 
                "app": AppStore.isMobileApp() ? "app" : this.props.isMobileSize ? "mobile" : "desktop",
                "source": trafficData.source,
                "medium": trafficData.medium,
                "campaign": trafficData.campaign,
                "phone": phone, 
                "currency_id": cur_id, "lang": UserStore.getLang()}
        };

        const partner_id = window.localStorage.getItem('partner_id');
        if (partner_id) {
          params.data['partner_id'] = partner_id;
        }

        return yajax(params).then(this.onSendRegistrationData, (error) => {
          console.log("response error ", error);
          const response = JSON.parse(error['response']);
            if ('error' in response) {
              if (response['error']['code'] === "email-already-exists") {
                this.setState({
                  "email": {
                    "error": T(response['error']['code'])
                  }
                });
              } 
            }
        });
    }

    onSendRegistrationData(data) {
        if (data.success) {
            const email = this.state.email.value,
                  pass = this.state.password.value;

            trackEvent('users', 'registration', 'manual', '1');
            UserStore.setFirstReg(true);
            UserActions.login(email, pass);
            window.localStorage.removeItem('partner_id');
        }
    }

    _getError(name) {
        return (
            <Help color="danger">{T(this.state[name].error)}</Help>
        );
    }

  whereToSend(){

    let main_rounte = "/dashboard";
    if(this.state.tarif) {
      main_rounte = '/settings/subscribe'
    } else if (UserStore.isCashier()) {
      main_rounte = "/sales";
    } else if (UserStore.isManager()) {
      main_rounte = "/sales";
    } else if (UserStore.isWarehouseMan()) {
      main_rounte = "/products";
    }

    if (!UserStore.getModuleEnable(main_rounte.substring(1))) {
      main_rounte = "/products";
    }

    return main_rounte
  }

    render () {
        return (
            <>
          <Box>
            {AppStore.isMobileApp() ? null : <SocialLogin />}
            {AppStore.isMobileApp() ? null : <div align="center"><Heading size={5}>{T('or')}</Heading></div>}
            <Field>
                <Label>E-mail</Label>
                <Control>
                  <Input size="large" type="email" 
                    name="email"
                    color={(this.state.email.error) ? "danger" : "primary"}
                    placeholder={T('email-placeholder')}
                    onChange={this.onChangeReg} 
                    onBlur={this.onBlur} 
                    value={this.state.email.value} />
                </Control>
               {this.state.email.error && this._getError("email")}
            </Field>

            <Field>
                <Label>{T('phone')}</Label>
                <Control>
                  <Input size="large" type="text" 
                    name="phone"
                    color={(this.state.phone.error) ? "danger" : "primary"}
                    placeholder={T('phone-placeholder')}
                    onChange={this.onChangeReg} 
                    onBlur={this.onBlur} 
                    value={this.state.phone.value} />
                </Control>
               {this.state.phone.error && this._getError("phone")}
            </Field>
             <Field>
                  <Label>{T('password')}</Label>
                  <Control>
                    <Input size="large" name="password" type="password" 
                    placeholder={T('password')}
                    color="primary"
                    autoComplete="nope"
                    onChange={this.onChangeReg} 
                    onBlur={this.onBlur} 
                    value={this.state.password.value} />
                  </Control>
             </Field>  

            
            <Button.Group>
              <Button fullwidth rounded 
                color="primary"
                size="large"
                id="registration"
                onClick={this.submit}>{T('registration')}</Button>
            </Button.Group>
          </Box>
          {AppStore.isMobileApp() ? backLink() : null}
          {(!UserStore.isGuest() || this.state.login) ? redirectTo(this.whereToSend(), {tarif_register: this.state.tarif}) : null}
          </>
      );
      
     }
};

export default withIsMobileSize(withRouter(RegForm));