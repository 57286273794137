import yajax from "yajax";

import { createStore } from "../../tools/reflux-tools";
import Actions from "./moving-actions";
import T from "../../components/i18n";
import UserStore from "../../user/user-store";
import ProductsActions from "../products/products-actions";
import {getDatePeriod} from "../../tools/date-range";
// import User from '../../user/user-store';

const CLEAR_FILTER = () => ({
  date_start: getDatePeriod(30),
  date_end: new Date(),
  status: {value: 0, label: T('all')},
  from_mid: {value: 0, label: T('all-warehouse')},
  to_mid: {value: 0, label: T('all-warehouse')},
  product: null
});

const MovingStore = createStore({
  listenables: Actions,
  _prefix: "/bproducts-loads/",
  state: {
    rows: null,
    lastAdd: 0,
    isLoaded: false,
    isLoadedDelivery: false,
    _filter: CLEAR_FILTER(),
  },

  get(field) {
    return this.state[field];
  },

  onFilter(name, value) {
    let filters = {};

    for (let i in this.state['_filter']) {
      filters[i] = this.state['_filter'][i];
    }

    let date_start = filters['date_start'];
    let date_end = filters['date_end'];
    filters[name] = value;

    this.setState({
      _filter: filters
    });

    if (name === "date_start" && value < date_start) {
      Actions.load();
    }
    if(name === 'date_end' && value > date_end) {
        Actions.load();
    }
  },

  onAdd(data) {
    console.log("start add moving", data);
    yajax
      .post(this._prefix + "moving", JSON.stringify(data))
      .then(Actions.add.completed, Actions.add.failed);
  },

  onAddCompleted(data) {
    console.log("result add moving: ", data);
    
    if (data.waiting) {
      setTimeout(Actions.load, 10*1000)
    } else {
      Actions.load();
    }
  },

  onAddFailed() {
    console.log("failed add moving");
  },

  onDelete(data) {
    console.log("start delete moving product", data);

    const params = {
      method: "DELETE",
      url: this._prefix + "moving",
      data: data,
    };

    yajax(params).then(Actions.delete.completed, Actions.delete.failed);
  },
  onDeleteCompleted(data) {
    if (data["type"] === "moving") {
      Actions.load();
    }
    console.log("result delete shhipment product: ", data);
  },

  onDeleteFailed() {
    console.log("failed delete moving product");
  },

  getList(list) {
    const moving = this.get(list);
    if (moving === null) {
      Actions.load();
      return [];
    }
    return moving;
  },

  applyFilter(moving) {
    const filter = this.state._filter;
    const date = new Date(moving['created_at']);

    filter['date_start'].setHours(0,0,0,0);
    filter['date_end'].setHours(23,59,59,999);

    if (date < filter['date_start'] || date > filter['date_end']) {
      return false;
    }

    let result = true

    if(filter['from_mid'] && filter['from_mid']["value"] !== 0) {
      result = result && parseInt(filter['from_mid']["value"]) === parseInt(moving['from_marketplace_id']);
    }

    if(filter['to_mid'] && filter['to_mid']["value"] !== 0) {
      result = result && parseInt(filter['to_mid']["value"]) === parseInt(moving['to_marketplace_id']);
    }
    if(filter['status'] && filter['status']['value'] !== 0) {
      result = result && filter['status']['value'] === moving['status'];
    }

    if (filter['product'] && filter['product'].length > 0 && filter['product'][0]['value'] !== 0) {
      const someProduct = filter['product'].some(item => {
        const pid = item.value.split("_")[0]
        return moving.items.some(product => parseInt(product.product_id) === parseInt(pid))
      });
      result = result && someProduct;
    }

    return result;
  },

  clearFilter() {
    this.setState({
      _filter: CLEAR_FILTER(),
    })
  },

  getMovingById(sid) {
    for (let i in this.state.rows) {
      const ship = this.state.rows[i];
      if (parseInt(ship["id"]) === parseInt(sid)) {
        return ship;
      }
    }
  },

  getMovingByProductId(pid) {
    let moving_by_product = [];
    for (let i in this.state.rows) {
      const ship = this.state.rows[i];
      for (let j in ship["items"]) {
        const item = ship["items"][j];
        if (parseInt(item["product_id"]) === parseInt(pid)) {
          moving_by_product.push({
            id: ship["id"],
            name: ship["name"],
            marketplace_id: ship["marketplace_id"],
            supplier_id: ship["supplier_id"],
            currency_id: ship["currency"],
            amount: ship["amount"],
            created_at: ship["created_at"],
            delivered_date: ship["delivered_date"],
            item_amount: item["amount"],
            purchase_price: item["purchase_price"],
            quantity_received: item["quantity_received"],
            quantity_shipped: item["quantity_shipped"],
            quantity_sold: item["quantity_sold"],
          });
          break;
        }
      }
    }

    return moving_by_product;
  },

  onLoad(force) {
    if (this.movingLoading) return;
    console.log("start load moving...");

    this.movingLoading = true;

    let url = this._prefix + "moving";

    const filter = this.get('_filter')
    const date = {}

    if (filter['date_start']) {
      const date_start = filter['date_start'].setHours(0, 0, 0, 0);
      date.start = parseInt(`${date_start / 1000}`)
    }

    if (filter['date_end']) {
      const date_end = filter['date_end'].setHours(23, 59, 59, 999);
      date.end = parseInt(`${date_end / 1000}`);
    }

    yajax.get(url, date).then(Actions.load.completed, Actions.load.failed);
  },

  onLoadCompleted(data) {
    if (data["success"]) {
      const permissionWarehouse = UserStore.getPermissionWarehouse();

      const filterWithWarehouse = data["data"].filter((item) => {
        let result = true;
        if (permissionWarehouse?.length) {
          result =
            result &&
            permissionWarehouse.some(
              (permissionID) => permissionID === item?.to_marketplace_id
            );
        }

        return result;
      });

      const dataWithName = filterWithWarehouse.map((item) => {
        item.name = `ID: ${item["id"]}. ${T("moving-from")} ${
          item["created_at"]
        }`;
        return item;
      });
      this.setState({
        rows: dataWithName,
      });
    }

    this.movingLoading = false;
    console.log("result load moving: ", data);
  },

  onLoadFailed() {
    this.movingLoading = false;
    console.log("failed loadimg moving");
  },

  onMovingAll(data) {
    if (this.movingAll) return;

    this.movingAll = true;

    yajax
      .post(this._prefix + "moving-all", data)
      .then(Actions.movingAll.completed, Actions.movingAll.failed);
  },
  onMovingAllCompleted(data) {
    this.movingAll = false;
    ProductsActions.load();
  },
  onMovingAllFailed(res) {
    this.movingAll = false;
  },
});

export default MovingStore;
