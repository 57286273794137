import React, {useEffect, useState} from 'react'
import {
	Checkbox,
	Control,
	Field
} from "react-bulma-components/lib/components/form";
import T from "../../../components/i18n";
import { LocalIcon } from 'whitelables/wl-name/icons'
import {Link} from "react-router-dom";
import AppStore from "../../../app-store";
import PriceTagSettingFieldModal from "./components/price-tag-setting-field-modal";

const PriceTagField = ({name, position, height, width, brand, number_key, active, size, type, onSubmit}) => {
	const [checkedValue, setCheckedValue] = useState(!!active)

	const defaultData = {
			position,
			size
	}
	if(height) defaultData.height = height
	if(width) defaultData.width = width

	const handleChangeCheckbox = (evt) => {
		const checked = evt.target.checked
		setCheckedValue(checked)
	}

	const handleSubmit = (data) => {
		if(onSubmit && type === 'custom') onSubmit({[name]: { active: Number(checkedValue), ...data }})
		if(onSubmit && type === 'templates') onSubmit({[name]: checkedValue})
	}

	useEffect(() => {
			handleSubmit(defaultData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkedValue, type]);

	return (
		<>
		<Field className='display-flex-row-gap'>
			<Control>
				<Checkbox
					name={name}
					onChange={handleChangeCheckbox}
					className="termo_print"
					checked={checkedValue}
				>
					{T(`price-tag-${name}`)}
				</Checkbox>
			</Control>
			{checkedValue && type !== 'templates' && (
				<Link
					className='link-icon-centred'
					to='#'
					onClick={() => (
						AppStore.openModal(<PriceTagSettingFieldModal
																									size={size}
																									position={position}
																									height={height}
																									width={width}
																									name={name}
																									brand={brand}
																									number_key={number_key}
																									onSubmit={handleSubmit}/>))}
				>
						<LocalIcon size='small' icon='gear'/>
				</Link>)}
		</Field>
		</>
	);
};

export default PriceTagField;