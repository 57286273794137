import React, {useEffect, useState} from 'react'
import Reports from "../../../components/reports";
import EmptyMessage from "./empty-message";
import OrdersStore from "../orders-store";
import OrdersActions from "../orders-actions";
import OrderRowMobile from "../mobile/order-row-mobile";
import OrderRowDesktop from "../desktop/order-row-desktop";
import usePaginationHook from "../../../tools/pagination-hook";
import Button from "react-bulma-components/lib/components/button";
import T from "../../../components/i18n";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const COLUMNS = [
	"",
	"id",
	"order",
	"customer",
	"product-info-price",
	"status",
];


const ReportRefund = () => {
	const {isMobileSize} = useIsMobile()
	const [isOpenRow, setIsOpenRow] = useState(false);

	const rows = OrdersStore.get('refundRows')
	const rowsWithFilter = rows.filter((item) => OrdersStore.applyFilter(item, 'refund'))
	const {start,end,PaginationBlock,setPage,page,total} = usePaginationHook({listLength: rowsWithFilter.length});


	useEffect(() => {
		OrdersActions.loadRefund()
	}, []);

	return (
		<>
			{isMobileSize && rowsWithFilter.length > 1 && (
				<div align="right">
					<Button
						onClick={() => setIsOpenRow((prev) => !prev)}
						style={{ fontSize: "12px" }}
					>
						{isOpenRow ? T("hide-all-orders-btn") : T("show-all-order-btn")}
					</Button>
				</div>
			)}
			<Reports
				emptyMessage={EmptyMessage}
				columns={COLUMNS}
				hideFooterOnEmpty
				isStriped={false}
				isLoading={OrdersStore.loadingRefund}
				load={OrdersActions.load}
				rows={rowsWithFilter.slice(start, end)}
			>
				{isMobileSize ? (
					<OrderRowMobile
						role="row"
						columnLength={COLUMNS.length}
						triggerIsOpen={isOpenRow}
						isRefund
					/>
				) : (
					<OrderRowDesktop role="row" isRefund/>
				)}
			</Reports>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
			/>
		</>
	);
};

export default ReportRefund;