import React, { Component } from "react";
import T from "components/i18n";
import Money from "components/money";
import WarehouseStore from "modules/warehouse/warehouse-store";
import ProductsStore from "modules/products/products-store";
import Level from "react-bulma-components/lib/components/level";
import OpenModalButton from "components/modal/open-modal-button";
import NumberBlock from "components/number-block";
import User from "user/user-store";
import OpenInsideModal from "components/modal/open-inside-modal";
import AddProductions from "../add-production";
import DOM from "react-dom-factories";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Actions from "../production-actions";
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import ProductionStore from "../production-store";
import { Button } from "react-bulma-components";
import AppStore from "../../../app-store";
import ViewProductModal from "../../products/modal/view-product/view-product-modal";
import {
  withIsMobileSize
} from "../../../components/is-mobile-context/is-mobile-context";


class ProductionRow extends Component {
  getRegistrationButton() {
    const { data } = this.props;
    return (
      <Level.Item key="regBut">
        <OpenModalButton
          size={this.props.isMobileSize ? "2x" : "1x"}
          color="success"
          link={{
            pathname: "/production/edit",
            state: { step: 2, edit: true, data: data },
          }}
          text={T("finish-production-btn")}
        />
      </Level.Item>
    );
  }

  getEditButton(copy) {
    const { data } = this.props;

    return (
      <Level.Item key={`edit-btn-${copy ? 'copy' : ''}`} title={T(copy ? 'copy' : "edit-btn")}>
        <OpenInsideModal size={this.props.isMobileSize ? "2x" : "1x"} renderAs="icon" icon={copy ? 'copy' : "edit"}>
          <AddProductions data={data} edit={true} copy={copy} />
        </OpenInsideModal>
      </Level.Item>
    );
  }

  getProductionButton() {
    const { data } = this.props;

    return (
      <Level.Item key="produce-btn">
        <OpenInsideModal text="produce-goods" color="success">
          <AddProductions data={data} step={2} />
        </OpenInsideModal>
      </Level.Item>
    );
  }

  getDeleteButton() {
    const { data } = this.props;

    return (
      <Level.Item key="delBut">
        <Link
          to="#"
          onClick={() => this.deleteProduction(data["id"])}
          title={T("delete")}
        >
          <FontAwesomeIcon icon="trash-alt" size={this.props.isMobileSize ? "2x" : "1x"} />
        </Link>
      </Level.Item>
    );
  }

  getRestoreButton() {
    const { data } = this.props;

    return (
      <Level.Item key="restore_button">
        <Button color='warning' onClick={() => this.restoreProduction(data["id"])}>
          {T('restore-label')}
        </Button>
      </Level.Item>
    );
  }

  getButton() {
    let response = null

    this.props.isDeleted ? (
      response = this.getRestoreButton()
      ) : (
      response = [
        this.getProductionButton(),
        this.getEditButton(true),
        this.getEditButton(),
        this.getDeleteButton(),
      ]
      )

    return response;
  }

  getPriceButton() {
    const { data } = this.props;

    return (
      <Level.Item>
        <OpenModalButton
          size="small"
          color="info"
          link={{
            pathname: "/production/edit",
            state: { step: 2, only_show: true, data: data },
          }}
          text={T("see-product-btn")}
        />
      </Level.Item>
    );
  }

  getPrintButtonOne(sid) {
    const { data } = this.props;
    let pids = [];
    for (let key in data["items"]) {
      const pr = data["items"][key];
      pids.push(
        ProductsStore.getIdent(pr["product_id"], data["to_marketplace_id"])
      );
    }

    return (
      <Dropdown
        up={this.props.isMobileSize}
        label={
          <FontAwesomeIcon
            icon="print"
            size={"1x"}
            title={T("print-shipment")}
          />
        }
      >
        <Dropdown.Item
          renderAs={Link}
          to={this.getPrintUrl(sid, "sale")}
          target="_blank"
          title={T("with-sale-price")}
          value="item"
        >
          {T("with-sale-price")}
        </Dropdown.Item>
        <Dropdown.Item
          renderAs={Link}
          to={ProductsStore.getPrintTabLink(pids)}
          target="_blank"
          title={T("print-tags")}
          value="item"
        >
          {T("print-tags")}
        </Dropdown.Item>
      </Dropdown>
    );
  }
  getPrintButton(sid) {
    const { data } = this.props;
    let pids = [];
    for (let key in data["items"]) {
      const pr = data["items"][key];
      pids.push(
        ProductsStore.getIdent(pr["product_id"], data["to_marketplace_id"])
      );
    }
    if (!User.getPermissionEnable("incomeinformation")) {
      return this.getPrintButtonOne(data.id);
    }
    return (
      <Dropdown
        up={this.props.isMobileSize}
        label={
          <FontAwesomeIcon
            icon="print"
            size={"1x"}
            title={T("print-shipment")}
          />
        }
      >
        <Dropdown.Item
          renderAs={Link}
          to={this.getPrintUrl(data.id, "net")}
          target="_blank"
          title={T("with-netprice")}
          value="item"
        >
          {T("with-netprice")}
        </Dropdown.Item>
        <Dropdown.Item
          renderAs={Link}
          to={this.getPrintUrl(data.id, "sale")}
          target="_blank"
          title={T("with-sale-price")}
          value="item"
        >
          {T("with-sale-price")}
        </Dropdown.Item>
        <Dropdown.Item
          renderAs={Link}
          to={ProductsStore.getPrintTabLink(pids)}
          target="_blank"
          title={T("print-tags")}
          value="item"
        >
          {T("print-tags")}
        </Dropdown.Item>
      </Dropdown>
    );
  }

  deleteProduction(sid) {
    confirmAlert({
      title: T("confirm-delete"),
      message: "",
      buttons: [
        {
          label: T("delete"),
          onClick: () =>
            Actions.delete({
              mov_id: sid,
              type: "production",
            }),
        },
        {
          label: T("no-delete"),
          onClick: () => {},
        },
      ],
    });
  }

  restoreProduction(sid) {
    confirmAlert({
      title: T("confirm-recovery"),
      message: "",
      buttons: [
        {
          label: T("confirm-label"),
          onClick: () =>
            Actions.restore({
              mov_id: sid,
              type: "production",
            }),
        },
        {
          label: T("cancel-label"),
          onClick: () => {},
        },
      ],
    });
  }

  getPrintUrl(sid, _type) {
    return (
      "/bprint/production?mid=" +
      sid +
      "&type=" +
      _type +
      "&uid=" +
      User.get("id") +
      "&token=" +
      encodeURIComponent(User.getToken())
    );
  }

  mobileRender() {
    const { data } = this.props;
    return (
      <tr>
        <td colSpan="5">
          <Heading size={6} className="margin-top-10">
            ID: {data["id"]}. <b>{T("production")}: </b>{" "}
            <Button
              className="button-link-without-style"
              onClick={() =>
                AppStore.openModal(
                  <ViewProductModal
                    pid={data["result_product_id"]}
                    mid={data["result_marketplace_id"]}
                  />
                )
              }
            >
              {ProductsStore.getProduct(data["result_product_id"])["name"]}
            </Button>
            <br />
            <b>{T("mid")}:</b>{" "}
            {T(WarehouseStore.getWarehouseName(data["result_marketplace_id"]))}{" "}
            <br />
          </Heading>

          <List>
            {data["category_id"] && (
              <List.Item>
                <b>{T("category")}: </b>{" "}
                {ProductionStore.getCategoryName(data["category_id"])}
                <br />
              </List.Item>
            )}
            {data["comment"] !== "" ? (
              <List.Item>
                <b>{T("comment")}:</b> {data["comment"]}
              </List.Item>
            ) : null}
          </List>
          <Columns className="is-mobile product-mobile-block">
            <Columns.Column>
              <NumberBlock
                top={T("сomponents")}
                number={data["items"].length || 0}
                bottom={T("units")}
                money={true}
                className="small-number-box"
              />
            </Columns.Column>
            <Columns.Column>
              <NumberBlock
                top={T("production-count-item-added", { count: 1 })}
                number={data["amount"] || 0}
                bottom={User.getCurrency(data["currency"])}
                money={true}
                className="small-number-box"
              />
            </Columns.Column>
          </Columns>
          <Level
            renderAs="nav"
            className="is-mobile margin-bottom-10"
            breakpoint="mobile"
          >
            <Level.Side align="left">{this.getButton()}</Level.Side>
          </Level>
        </td>
      </tr>
    );
  }

  desktopRender() {
    const { data } = this.props;
    let comment = "";
    if (data["comment"] && data["comment"] !== "") {
      comment = (
        <>
          <b>{T("comment")}:</b> {data["comment"]}{" "}
        </>
      );
    }
    return (
      <tr className="is-size-7">
        <td align="left" width="50%">
          ID: {data["id"]}
          <br />
          <b>{T("production")}: </b>
          <Button
            className="button-link-without-style"
            onClick={() =>
              AppStore.openModal(
                <ViewProductModal
                  pid={data["result_product_id"]}
                  mid={data["result_marketplace_id"]}
                />
              )
            }
          >
            {ProductsStore.getProduct(data["result_product_id"])["name"]}
          </Button>
          <br />
          <b>{T("mid")}: </b>
          {T(WarehouseStore.getWarehouseName(data["result_marketplace_id"]))}
          <br />
        </td>
        <td>
          {data["name"] && (
            <>
              <b>{T("name")}: </b> {data["name"]}
              <br />
            </>
          )}
          {data["category_id"] && (
            <>
              <b>{T("category")}: </b>{" "}
              {ProductionStore.getCategoryName(data["category_id"])}
              <br />
            </>
          )}
          <b>{T("production-count-item-added", { count: 1 })}: </b>
          <Money amount={data["amount"]} wrapper={DOM.span} /> <br />
          {comment}
        </td>
        <td align="right">
          <Level className="margin-top-5">
            <Level.Side align="left">{this.getButton()}</Level.Side>
          </Level>
        </td>
      </tr>
    );
  }

  render() {
    return this.props.isMobileSize ? this.mobileRender() : this.desktopRender();
  }
}

export default withIsMobileSize(ProductionRow);
