import React from "react";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../components/i18n";
import List from "react-bulma-components/lib/components/list";
import {
	useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const EmptyMessage = () => {
	const {isMobileSize} = useIsMobile()
	return (
		<List.Item className="font-size-80">
				<Heading size={isMobileSize ? 6 : 5} className="has-text-danger ">
					{T('select-min-one-item-write-off')}
				</Heading>
		</List.Item>)
};

export default EmptyMessage;