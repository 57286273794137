import React, {createContext, useContext, useEffect, useState} from 'react'

const MyContext = createContext();


const IsMobileContext = ({ children }) => {
	const [isMobileSize, setIsMobileSize] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
			if(window.innerWidth <= 768){
				setIsMobileSize(true)
			} else {
				setIsMobileSize(false)
			}
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<MyContext.Provider value={{ isMobileSize }}>
			{children}
		</MyContext.Provider>
	);
};

const useIsMobile = () => {
	return useContext(MyContext);
};

const withIsMobileSize = (WrappedComponent) => {
	return function WrapperComponent(props) {
		const { isMobileSize } = useIsMobile();
		return <WrappedComponent isMobileSize={isMobileSize} {...props} />;
	};
};


export{
	IsMobileContext,
	useIsMobile,
	withIsMobileSize,
};