import React, { Component } from 'react';

import AddClientModal from "./modal/add-client-modal/add-client-modal";

import OpenModal from 'components/modal/modal';


class AddClientModalLink extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const props = this.props.location.state;

        return (
          <OpenModal modal={{ closeOnBlur: false }}>
              <AddClientModal {...props} />
          </OpenModal>
        );
    }
}


export default AddClientModalLink;