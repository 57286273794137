import React, {useState} from 'react'
import SelectComponent from "../../../../tools/select-component";
import UserStore from "../../../../user/user-store";
import {
	Control,
	Field, Input,
	Label
} from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";

const SettingTemplates = ({defaultQuantity,defaultSize, onChange}) => {
	const [quantity, setQuantity] = useState(defaultQuantity || 1)
	const [size, setSize] = useState(defaultSize ? {value: defaultSize} : null)


	const handleChangeSelect = (value) => {
		setSize(value)

		if(onChange) onChange({size: value.value})
	}

	const handleChangeInput = (evt) => {
		const value = evt.target.value
		setQuantity(value)

		if(onChange) onChange({quantity: value})
	}

	return (
		<>
			<SelectComponent
				name="size"
				label="size-pricetags"
				onChange={handleChangeSelect}
				value={size}
				list={UserStore.getPriceTagsSizeList}
				creatable={false}
			/>

			<Field>
				<Label>{T('pricetags-quantity')}</Label>
				<small>{T('pricetags-quantity-desk')}</small>
				<Control>
					<Input size="large"
								 name="quantity"
								 autoComplete="nope"
								 type="number"
								 min="0"
								 onChange={handleChangeInput}
								 value={quantity}
					/>
				</Control>
			</Field>
		</>
	);
};

export default SettingTemplates;