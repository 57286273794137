import React, { Component } from 'react';
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";

import { 
    Field, 
    Control, 
    Input, 
    Label, 
    Select
} from 'react-bulma-components/lib/components/form';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Actions from './start-actions' 
import StartStore from './start-store' 


class AddMarketplaceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          name: {
                error: "",
                value: ""
          },
          marketplace: {
                error: "",
                value: "US"
          },
          mws_token: {
                error: "",
                value: ""
          },
          seller_id: {
                error: "",
                value: ""
          }
        };

        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    submit() {
        Actions.addMarketplace(this.state);

        // Actions.addCategory.completed.listen(() => {
        //   window.history.back();
        // });
    }

    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: {
            'value': value
          }
        });
    }


    getMarketplaceList() {
        const marketplaces = StartStore.get("marketplaces");

        let result = [];
        for (let code in marketplaces) {
            let p = marketplaces[code];
            result.push(<option value={code} key={code}>{T(p['name'])}</option>)
        }

        return result;
    }

    render() {
        const marketplace = this.getMarketplaceList();
        return (
              <Box>
                <Field>
                  <Label>{T("Marketplaces")}</Label>
                    <Control>
                      <Select onChange={this.onChange} name="marketplace" size="large" value={this.state.marketplace.value}>
                        {marketplace}
                      </Select>
                    </Control>
                </Field>
                <Field>
                  <Control>
                    <Label>{T('martketplace-name')}</Label>
                    <Input  size="large" 
                          name="name" type="text" 
                          placeholder={T('martketplace-name-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.name.error) ? "danger" : "primary"}
                          value={this.state.name.value}
                          />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Label>{T('seller-id')}</Label>
                    <Input  size="large" 
                          name="seller_id" type="text" 
                          placeholder={T('seller_id-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.seller_id.error) ? "danger" : "primary"}
                          value={this.state.seller_id.value}
                          />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Label>{T('mws-token')}</Label>
                    <Input  size="large" 
                          name="mws_token" type="text" 
                          placeholder={T('mws_token-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.mws_token.error) ? "danger" : "primary"}
                          value={this.state.mws_token.value}
                          />
                  </Control>
                </Field>
                
                <Button.Group>
                  <Button fullwidth size="large" rounded color="primary"
                    onClick={this.submit}
                    >{T('add-martketplace-btn-label')}</Button>
                </Button.Group>
              </Box>
        );
    }

}

export default connectToStores(AddMarketplaceModal, { _: StartStore });
