import React, {useEffect, useState} from "react";
import Container from "../../common/container/container";
import T from "../../../components/i18n";
import { LocalIcon } from "whitelables/wl-name/icons";
import s from "./styles.module.scss";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const WorkflowsBlock = ({ workflowsList, contentList }) => {
  const {isMobileSize} = useIsMobile()
  const [isActive, setIsActive] = useState("products");
  const [isOpenImg, setIsOpenImg] = useState(false)

  const handleChangeActive = (value) => {
    setIsActive(value);
    setIsOpenImg(false)
  };

  const handleToggleOpenImg = () => {
    if(!isMobileSize) {
      setIsOpenImg(prev => !prev)
    }
  }

  useEffect(() => {
    if(isOpenImg) {
    document.documentElement.style.overflow = 'hidden';
  } else {
    document.documentElement.style.overflow = '';
  }

  }, [isOpenImg]);

  return (
    <Container
      id="services"
      classNameContainer={s.section}
      classNameWrapper={s.wrapper}
    >
      <section className={s.tab_list}>
        {workflowsList?.map((item) => (
          <span
            key={item.label}
            className={`${s.tab} ${isActive === item.label ? s.active : ""}`}
            onClick={() => handleChangeActive(item.label)}
          >
            <LocalIcon icon={item.icon} size="large" className={s.icon} />
            <span>{T(item.label)}</span>
          </span>
        ))}
      </section>
      {contentList?.map(
        (item) =>
          isActive === item.value && (
            <section className={s.content} key={`content-${item.value}`}>
              <span className={`${s.content_img}`}
                    onClick={handleToggleOpenImg}>
                <img src={item.img} alt={item.value}/>
              </span>
              {isOpenImg && <span className={`${s.content_img} ${s.isOpen}`}
                     onClick={handleToggleOpenImg}>
                <img src={item.img} alt={item.value}/>
              </span>}
              <section className={s.content_text}>
                <h3 className={s.title}>{T(item.value)}</h3>
                <h4 className={s.sub_title}>{T(item.subTitle)}</h4>
                <ul className={s.benefit_list}>
                  {item.benefits.map((benefit, index) => (
                    <li key={`${benefit}-index`} className={s.benefit_item}>
                      {T(benefit)}
                    </li>
                  ))}
                </ul>
              </section>
            </section>
          )
      )}
    </Container>
  );
};

export default WorkflowsBlock;
