import React, { useState } from "react";
import ActionAccountButtons from "./action-account-buttons";
import AccountListBlock from "./account-list-block";
import ExpensesStore from "../../../expenses/expenses-store";
import AccountListTable from "./account-list-table";
import AccountListMobile from "./account-list-mobile";
import AppStore from "../../../../app-store";
import AccountStatisticsModal from "./account-statistics-modal";
import T from "../../../../components/i18n";
import { confirmAlert } from "react-confirm-alert";
import ExpensesActions from "../../../expenses/expenses-actions";
import AddAccountModal from "../../add-account-modal";
import User from "../../../../user/user-store";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const Account = ({ statisticsAccount, onErrorMessage }) => {
  const {isMobileSize} = useIsMobile()
  const [isActiveAccount, setIsActiveAccount] = useState(true);
  const [isTableVariant, setIsTableVariant] = useState(
    window.localStorage.getItem("variantAccount") || "row"
  );

  const handleToggleIsActiveAccount = () => {
    setIsActiveAccount((prev) => !prev);
  };

  const accounts = ExpensesStore.getSortedAccounts();
  const list = accounts.map((item) => ({
    ...item[1],
    statistics: statisticsAccount[item[0]],
  }));

  const handleChangeVariantAccount = (value) => {
    window.localStorage.setItem("variantAccount", value);
    setIsTableVariant(value);
  };

  const handleEditAccount = (data) => {
    AppStore.openModal(<AddAccountModal {...data} />);
  };

  const handleDeleteAccount = (id) => {
    let account = ExpensesStore.getAccountById(id);
    if (
      parseFloat(account["balance"]) !== 0 ||
      parseInt(account["types"]) === 1
    ) {
      onErrorMessage && onErrorMessage(T("not-empty-account"));
      return;
    }

    confirmAlert({
      title: T("confirm-delete"),
      message: T("are-you-sure-to-delete-this-account"),
      buttons: [
        {
          label: T("delete"),
          onClick: () => ExpensesActions.deleteAccount(id),
        },
        {
          label: T("no-delete"),
          onClick: () => {},
        },
      ],
    });
  };

  const handleOpenStatistics = (data) => {
    AppStore.openModal(<AccountStatisticsModal data={data} />);
  };

  return (
    <>
      <ActionAccountButtons
        onToggleIsActiveAccount={handleToggleIsActiveAccount}
        isActiveAccount={isActiveAccount}
        isTableVariant={isTableVariant}
        onChangeTableVariant={handleChangeVariantAccount}
      />
      {isActiveAccount &&
        !isMobileSize &&
        (isTableVariant === "row" ? (
          <AccountListTable
            list={list}
            onDeleteAccount={handleDeleteAccount}
            onEditAccount={handleEditAccount}
          />
        ) : (
          <AccountListBlock
            list={list}
            onDeleteAccount={handleDeleteAccount}
            onEditAccount={handleEditAccount}
          />
        ))}
      {isActiveAccount &&
        isMobileSize &&
        User.getPermissionEnable("showbalance") && (
          <AccountListMobile
            list={list}
            onDeleteAccount={handleDeleteAccount}
            onEditAccount={handleEditAccount}
            onOpenStatistics={handleOpenStatistics}
          />
        )}
    </>
  );
};

export default Account;
