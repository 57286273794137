import React from 'react'
import List from "react-bulma-components/lib/components/list";

const AdditionalField = ({af}) => {
	if(af) {
		return Object.keys(af).reduce((acc, key, index) => {

			const value = af[key];
			if (value) {
				acc.push(
					<List.Item key={index}>
						<b>{key}:</b> {af[key]}
					</List.Item>
				);
			}

			return acc;
		}, []);
	}

	return null
};

export default AdditionalField;