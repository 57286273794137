import React from "react";
import "./spinner.scss";

const Spinner = ({ className }) => (
    <div className={`spinner ${className}`}>
        <div className="spinner__wrapper">
            <div className="spinner__inner">
                <div className="spinner__gap" />
                <div className="spinner__left">
                    <div className="spinner__circle" />
                </div>
                <div className="spinner__right">
                    <div className="spinner__circle" />
                </div>
            </div>
        </div>
    </div>
);

Spinner.defaultProps = {
    className: ""
};

export default Spinner;
