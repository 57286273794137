import React from "react";
import BaseFilterLayout from "../../../components/base-filter-layout";
import HistoryStore from "../history-store";
import HistoryActions from "../history-actions";
import SalesDeletedFilter from "./sales-deleted-filter";


const FilterPanel = () => {

	const refreshFilter = () => {
		HistoryStore.clearFilterSales();
		HistoryActions.loadSalesDeleted()
	};

	const handleChange = ({ name, value }) => {
		if(name) {
			HistoryActions.filterDeletedSales('search', value);
		}
	};

	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value);
		daysKey.forEach((item) => {
				HistoryActions.filterDeletedSales(item, value[item]);
		});
	};

	return (
		<BaseFilterLayout
			searchStringSetting={{
				onChange: handleChange,
				defaultValue: HistoryStore.getFilterDeletedSales("search"),
			}}
			refresh={{
				onChange: refreshFilter,
				isLoading: HistoryStore.loadingSalesDeleted,
			}}
			dateRangeSetting={{
				onChange: handleChangeDate,
				date_start: HistoryStore.getFilterDeletedSales("date_start"),
				date_end: HistoryStore.getFilterDeletedSales("date_end"),
			}}
			hiddenFilter={<SalesDeletedFilter/>}
		/>
	);
};

export default FilterPanel;
