import React, { Component } from "react";
import Button from 'react-bulma-components/lib/components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class IconButton extends Component {

    render() {
        const props = this.props,
              size = props.size,
              text = props.text || "",
              icon = props.icon,
              iconSize = props.iconSize,
              CustomComponent = ({
                  link,
                  children,
                  className,
                  onClick,
              }) => (
                  <span onClick={onClick} className={className}><FontAwesomeIcon icon={icon || "times-circle"} size={iconSize || "1x"} />&nbsp;{text}</span>
              );

        return (
          <Button
            onClick={props.onClick}
            className={props.className}
            size={size || "small"}
            color={props.color}
            renderAs={CustomComponent}
          />
        );
    }
}

export default IconButton;