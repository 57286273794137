import Notification from "react-bulma-components/lib/components/notification";
import ScrollToTopOnMount from "../../tools/scroll-top";
import Button from "react-bulma-components/lib/components/button";
import React from "react";

const ErrorMessageBlock = ({ message, onRemove, color, disabledScroll }) => {
  const handleRemove = () => {
    onRemove(null);
  };

  return message ? (
    <Notification color={color || "danger"}>
      {!disabledScroll && <ScrollToTopOnMount/>}
      {message}
      <Button remove onClick={handleRemove} />
    </Notification>
  ) : null;
};

export default ErrorMessageBlock;
