import React from "react";
import SalesStore from "../../../../sales/sales-store";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import Box from "react-bulma-components/lib/components/box";
import T from "../../../../../components/i18n";
import User from "../../../../../user/user-store";
import Heading from "react-bulma-components/lib/components/heading";
import Money from "../../../../../components/money";
import FieldDescMobile from "../common/field-desc-mobile";
import InstokBlock from "./instok-block";

const ReportWithWarehouseRowMobile = ({ data, pid }) => {
  let instock = data["instock"];
  if (instock !== data["quantity"]) {
    instock = instock + "/" + data["quantity"];
  }
  const reports = SalesStore.getSaleReportsByProduct(pid, data["mid"]);

  const isDropShipping = WarehouseStore.isDropshipping(data["mid"]);

  const fields = [
    {
      name: "available",
      value: <span>{isDropShipping ? "∞" : <InstokBlock
        data={data}
        pid={pid}
        instock={data.instock}
        quantity={data.quantity}
        waiting={data.waiting}
        mid={data.mid}
      />}</span>,
    },
  ];

  if(User.getPermissionEnable("salesprice")) {
    fields.unshift({
      name: "product-info-price",
      value: `${data["price"] || 0} ${User.getCurrency()}`,
    })
  }

  if(User.getPermissionEnable("show-cashflow")) {
    fields.push({
      name: "product-info-sales",
      value: <span>{reports["items_count"]}</span>,
    },)
  }

  if (User.getPermissionEnable("incomeinformation")) {
    fields.unshift({
      name: "product-info-netcost",
      value: (
        <span>
          {data["netprice"] || 0} {User.getCurrency(data["currency_id"])}
        </span>
      ),
    });
    fields.push({
      name: "product-info-profit",
      value: <Money amount={reports["sales_profit"]} />,
    });
  }

  return (
    <tr>
      <td>
        <Box>
          <div className="margin-bottom-10">
            <Heading renderAs="p">
              {T(WarehouseStore.getWarehouseName(data["mid"]))}
            </Heading>
          </div>
          <div className="display-flex-center flex-wrap">
            {fields.map((item) => (
              <FieldDescMobile key={item.name} {...item} />
            ))}
          </div>
        </Box>
      </td>
    </tr>
  );
};

export default ReportWithWarehouseRowMobile;
