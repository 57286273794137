import React from "react";
import UserStore from "../../../../user/user-store";
import ExpensesStore from "../../../expenses/expenses-store";
import AppStore from "../../../../app-store";
import ReceiptViewModal from "../modal/receipt-view-modal";
import { Link } from "react-router-dom";

const TerminalReceiptRow = ({ data }) => {
  const handleViewModal = () => {
    if (data.id) {
      AppStore.openModal(<ReceiptViewModal receiptID={data.id} />);
    }
  };

  return (
    <tr>
      <td>
        <span
          onClick={handleViewModal}
          className="bold button_link cursor-pointer"
        >
          {data.id}
        </span>
      </td>
      <td>{data.created_at}</td>
      <td>
        <Link
          to={{
            pathname: "/sales",
            state: {
              search: `${data["order_id"]}`,
              date_start: new Date(data["created_at"]),
            },
          }}
        >
          <span className="underline hover cursor-pointer">
            {data.order_id}
          </span>
        </Link>
      </td>
      <td>{UserStore.getResponsebleName(data.rid)}</td>
      <td>{ExpensesStore.getAccountName(data.account_id) || "-"}</td>
      <td>{data.rrn}</td>
    </tr>
  );
};

export default TerminalReceiptRow;
