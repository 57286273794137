import React from "react";
import Columns from "react-bulma-components/lib/components/columns";
import T from "../../../../../components/i18n";
import User from "../../../../../user/user-store";
import InfoWrapper from "../../../../../components/info-wrapper";
import {Button, List} from "react-bulma-components";
import AppStore from "../../../../../app-store";
import ChangeMassPriceModal
  from "../../../modal/change-mass-price-modal";

const RetailLineTitle = ({onChangeSalePrice, onChangeNetPrice}) => {
  return (
    <List.Item
      className="font-size-80 bold has-background-light"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ minWidth: 34 }} />
      <Columns  style={{alignItems: 'center', flex: 1}}>
        <Columns.Column size={"two-fifths"}>{T("product-name")}</Columns.Column>
        <Columns.Column>{T("received")}</Columns.Column>
        {User.getPermissionEnable("incomeinformation") && (
          <Columns.Column>
            {T("new_netprice")}
            <Button
              className='button-link-without-style'
              style={{fontSize: '0.7rem', textAlign: 'center'}}
              onClick={
                () => AppStore.openModal(<ChangeMassPriceModal
                  onChange={onChangeNetPrice}
                  heading='mass-edit-net-price'
                  formula={`${T('new_netprice')}`}
                />)}
            >
              {T("automatic-net-prices")}
            </Button>
          </Columns.Column>
        )}
        <Columns.Column>
          {T("products-price")}
          <Button
            className='button-link-without-style'
            style={{fontSize: '0.7rem', textAlign: 'center'}}
            onClick={
            () => AppStore.openModal(<ChangeMassPriceModal
                                              onChange={onChangeSalePrice}
                                              heading='mass-edit-price'
                                              formula={`${T('new_netprice')} + ${T('delivery')}`}
                                            />)}
          >
            {T("automatic-markup")}
          </Button>
        </Columns.Column>
        <Columns.Column>
          <div className="display-flex-column" style={{ alignItems: "center" }}>
            <InfoWrapper info="change-delivery-shipment-tip">
              {T("delivery-delivery")}
            </InfoWrapper>
            <small className="silver font-size-07">
              ({T("cost-for").toLocaleLowerCase()})
            </small>
          </div>
        </Columns.Column>
        <Columns.Column>{T("cost-of-sales")}</Columns.Column>
        {User.getPermissionEnable("incomeinformation") && (
          <Columns.Column>{T("margin")}</Columns.Column>
        )}
      </Columns>
    </List.Item>
  );
};

export default RetailLineTitle;
