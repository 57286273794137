import React from 'react';
import { Redirect } from "react-router-dom";

export const redirectTo = (url, state, search) => {
	let params = { pathname: url}

	if (search) {
		params['search'] = search;
	}

	if (state) {
		params['state'] = state;
	}

    return (   
        <Redirect
             to={params}
          />
      )

  }