import React, {useEffect, useState} from 'react'
import LoadFileImage from "../../../../components/load-file-image";
import {Button, Icon} from "react-bulma-components";
import AppStore from "../../../../app-store";
import ProductsActions from "../../../products/products-actions";
import UserStore from "../../../../user/user-store";
import {
	Checkbox, Control,
	Field,
	Label,
} from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import NumberInput from "../../../../components/number-input/number-input";
import SelectComponent from "../../../../tools/select-component";

const allowedTypes=[  "image/jpeg",
	"image/png",
	"image/gif",
	"image/webp",
	"image/svg+xml",
	"image/tiff",
	"image/bmp",]

const selectListA4 = [
	{name: "price-tag-position-1", id: 'top_left'},
	{name: "price-tag-position-2", id: 'top_center'},
	{name: "price-tag-position-3", id: 'top_right'},
	{name: "price-tag-position-6", id: 'bottom_left'},
	{name: "price-tag-position-8", id: 'bottom_right'},
]
const selectListTermo = [
	{name: 'top', id: 'top'},
	{name: 'bottom', id: 'bottom'},
]



const LogoSetting = ({isTermo, values, setValues}) => {
	const [updateLogo, setUpdateLogo] = useState(0)
	const handleSubmit = ({files}) => {
		if(Array.isArray(files)){
			ProductsActions.uploadImages(files[0], 'logo')
		}
	}

	const handleActiveLogo = ({target: {checked}}) => {
		let logo = checked
		if(checked) {
			logo = {
				placement: false,
				size: 150,
			}
		}
		setValues(prev => ({
			...prev,
			logo
		}))
	}

	const handleChangeInput = ({target: {name, value}}) => {
		setValues(prev => ({
			...prev,
			logo: {
				...prev.logo,
				[name]: value
			}
		}))
	}
	const handleChangeSelect = (selectValue) => {
		setValues(prev => ({
			...prev,
			logo: {
				...prev.logo,
				placement: selectValue
			}
		}))
	}

	useEffect(() => {
		if(values && values.placement) {
			setValues(prev => ({
				...prev,
				logo: {
					...prev.logo,
					placement: false
				}
			}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTermo]);

	useEffect(() => {
		const updateLogoImg = ProductsActions.uploadImages.completed.listen((data) => {
			if(data) {
				setUpdateLogo(prevState => prevState + 1)
			}
		})

		return () => {
			updateLogoImg()
		}
	}, []);
	return (
		<div style={{textAlign: 'left', marginBottom: '0.75rem'}}>
				<Field>
					<Label size="large">
						<Checkbox
							name='logo'
							checked={!!values}
							onChange={handleActiveLogo}
						>
							{T('logo')}
						</Checkbox>
					</Label>
				</Field>
			{!!values && <>
				<div className='display-flex-column margin-bottom-10'
						 style={{alignItems: 'center', gap: 5}}>
					<img
						key={updateLogo}
						src={`/bimages/get/64-${UserStore.getUserId()}-logo-0.jpg?t=${Date.now()}`}
						style={{maxWidth: 300}}
						alt='logo-print'
					/>
					<Button
						onClick={() => AppStore.openModal(<LoadFileImage
							allowedTypes={allowedTypes}
							multiple={false}
							onSubmit={handleSubmit}/>)}
						color='success'
						size='small'
						rounded
					>
						{T('logo-upload')}
					</Button>
				</div>
				<Field>
					<Label>{T('width')}</Label>
					<Control iconRight>
						<NumberInput
							name='size'
							value={values ? values.size : ''}
							onChange={handleChangeInput}
						/>
						<Icon align='right' style={{top: "3px"}}>
							<span>
								px
							</span>
						</Icon>
					</Control>
				</Field>
				<SelectComponent
					label='position-label'
					placeholder='select-position'
					onChange={handleChangeSelect}
					value={values && values.placement ? typeof values.placement === 'string' ? {value: values.placement} : values.placement  : false }
					list={() => isTermo ? selectListTermo : selectListA4}
				/>
			</>}
		</div>
	);
};

export default LogoSetting;