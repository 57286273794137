import React, { Component } from 'react';
import T from "components/i18n";


class AccessDenied extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render () {
        return (
			<h1> {T('access-denied')}</h1>
			)}

      }

export default AccessDenied;