export default function filterClient(candidate, input) {
        if (candidate.value === 0) {
            return true;
        }

        if (candidate.label === undefined) {
          return false;
        }

        let label = candidate.label.toLowerCase();
        let _input = input.toLowerCase();

        if (typeof(candidate.label.replaceAll) === "function") {
          label = candidate.label.replaceAll("(","").replaceAll(")","").replaceAll(" ", "").replaceAll("-","").toLowerCase();
          _input = input.replaceAll("(","").replaceAll(")","").replaceAll(" ", "").replaceAll("-","").toLowerCase();
        }
        
        return candidate.data.__isNew__ || label.includes(_input);
 }