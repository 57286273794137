import React from "react";
import T from "../../../../components/i18n";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import ExpensesStore from "../../../expenses/expenses-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import User from "../../../../user/user-store";
import { Button } from "react-bulma-components";

const AccountSingleTable = ({ data, onDeleteAccount, onEditAccount }) => {
  return (
    <tr>
      <td>{T(data["name"])}</td>
      {User.getPermissionEnable("showbalance") && (
        <td>
          <b><Money
            amount={data["balance"]}
            aid={data["id"]}
            wrapper={DOM.span}
            offDept
          /></b>
        </td>
      )}
      {User.getPermissionEnable("showbalance") && (
        <td>
          <Money
            amount={data?.statistics ? data.statistics["period_first"] : 0}
            aid={data["id"]}
            wrapper={DOM.span}
            offDept
          />
        </td>
      )}
      {User.getPermissionEnable("showbalance") && (
        <td>
          <Money
            amount={data?.statistics ? data.statistics["period_last"] : 0}
            aid={data["id"]}
            wrapper={DOM.span}
            offDept
          />
        </td>
      )}
      <td>
        {data?.statistics ? data.statistics["count"] : 0} {T("items-p")}
      </td>
      {User.getPermissionEnable("show-cashflow") && (
        <td>
          <Money
            className="text-success"
            amount={data?.statistics ? data.statistics["coming"] : 0}
            aid={data["id"]}
            wrapper={DOM.span}
            offDept
          />
        </td>
      )}
      {User.getPermissionEnable("show-cashflow") && (
        <td>
          <Money
            className="text-danger"
            amount={data?.statistics ? Math.abs(data.statistics["expense"]) : 0}
            aid={data["id"]}
            wrapper={DOM.span}
            offDept
          />
        </td>
      )}
      {User.getPermissionEnable("showbalance") && User.getPermissionEnable("edit-balance") && (
        <td>
          {!ExpensesStore.isSystemAccount(data["id"]) && (
            <div className="display-flex-row">
              <Button
                title={T('edit-btn')}
                className="button-link-without-style"
                onClick={() => onEditAccount(data)}
              >
                <FontAwesomeIcon icon="edit" size="1x" />
              </Button>
              <Button
                title={T('delete')}
                className="button-link-without-style"
                onClick={() => onDeleteAccount(data.id)}
              >
                <FontAwesomeIcon icon="trash-alt" size="1x"/>
              </Button>
            </div>
          )}
        </td>
      )}
    </tr>
  );
};

export default AccountSingleTable;
