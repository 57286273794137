import {confirmAlert} from "react-confirm-alert";
import T from "../../../components/i18n";
import Actions from "../clients-actions";

export const handleStopPropagation = (event) => {
	event.stopPropagation()
}

export const deleteClient = (event, id) => {
	event.stopPropagation()
	confirmAlert({
		title: T('confirm-delete'),
		message: T('are-you-sure-to-delete-this-client'),
		buttons: [
			{
				label: T('delete'),
				onClick: () => Actions.deleteClient(id)
			},
			{
				label: T('no-delete'),
				onClick: () => {}
			}
		]
	});
}