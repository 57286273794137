import React from "react";
import List from "react-bulma-components/lib/components/list";
import T from "../../../../components/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TerminalStore from "../terminal-store";
import ExpensesStore from "../../../expenses/expenses-store";
import { Radio } from "react-bulma-components/lib/components/form";

const TerminalRowList = ({ data, onEdit, isActive, onChangeRadio }) => {
  const connectedAccountInfo = TerminalStore.getLinkAccountToTerminalById(
    data?.id
  );

  const accountName = ExpensesStore.getAccountName(
    connectedAccountInfo?.account_id
  );

  const handleEdit = (evt) => {
    evt.stopPropagation();
    onEdit && onEdit(data);
  };

  const handleChangeRadio = () => {
    onChangeRadio && onChangeRadio(data.id);
  };

  return (
    <List.Item
      key={data.id}
      className={`list-item is-one-odd ${
        onChangeRadio ? "cursor-pointer" : ""
      } ${isActive === data.id ? "list-bg-fa" : ""}`}
      onClick={handleChangeRadio}
    >
      <div className="wrapper-flex">
        {onChangeRadio && (
          <Radio
            name={data.id}
            value={data.id}
            checked={isActive === data.id}
            onChange={handleChangeRadio}
          />
        )}
        <img
          className="img-logo"
          src={`/img/banks/${data.type}.svg`}
          alt={data.type + "-logo"}
        />
        <div className="display-flex-column">
          <p>
            <strong>{data.name}</strong> {data.model && `(${data.model})`}
          </p>
          {accountName && (
            <small>
              {T("connected-account")}:{" "}
              <b className="text-success">{accountName}</b>
            </small>
          )}
        </div>
      </div>
      <div className="wrapper-flex">
        {onEdit && (
          <span onClick={handleEdit} className="button_link cursor-pointer">
            <FontAwesomeIcon icon="edit" />
          </span>
        )}
      </div>
    </List.Item>
  );
};

export default TerminalRowList;
