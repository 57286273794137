import {createActions} from "../../tools/reflux-tools";

const InventoryActions = createActions({
    add: { asyncResult: true },
    put: { asyncResult: true },
    delete: { asyncResult: true },
    load: { asyncResult: true },
    addAllProduct: { asyncResult: true },
    filter: { sync: true}
});

export default InventoryActions;