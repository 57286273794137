import React from 'react'
import User from "../../../../user/user-store";
import Level from "react-bulma-components/lib/components/level";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import RefundModal from "../../refund-modal";

const SaledButton = ({accountID, orderID, amount, productName, }) => {

	if (!User.getPermissionEnable("refund-products")) {
		return null
	}

	return (
		<Level.Item>
			<OpenInsideModal size="small" color="warning" text='make-refund' icon="undo">
				<RefundModal
					fromAccount={accountID}
					amount={amount}
					product_name={productName}
					oid={orderID}
				/>
			</OpenInsideModal>
		</Level.Item>
	);
};

export default SaledButton;