import {Link} from "react-router-dom";
import T from "../../../components/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Level from "react-bulma-components/lib/components/level";
import React from "react";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const RevertButton = ({data}) => {
	const {isMobileSize} = useIsMobile()
	return (
		<Level.Item key='undo-btn'>
			<Link to={{
				pathname: '/write-off/edit',
				state: { 'step': 4, 'data': data }
			}} title={T('revert-write-off')}>
				<FontAwesomeIcon icon="undo" size={isMobileSize ? '2x' : '1x'} />
			</Link>
		</Level.Item>
	);
};

export default RevertButton;