import React, { Component } from 'react';
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";
import getQueryParams from "tools/query-params";
import { redirectTo } from 'tools/redirect-to'
import { 
    Field, 
    Control, 
    Input, 
    Radio, 
    Label 
} from 'react-bulma-components/lib/components/form';

import DatePicker from "react-datepicker";

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import ExpensesStore from './expenses-store'
import NotificationActions from '../notification/notification-actions'
import ProductsStore from '../products/products-store'

import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Link } from "react-router-dom";

// import SelectComponent from 'tools/select-component'

class AddPlanExpensesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          comment: {
                error: "",
                value: ""
          },
          amount: {
                error: "",
                value: ""
          },
          types: {
            value: "1"
          },
          date: new Date(),
          close: false
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeDateSaled = this.onChangeDateSaled.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        let product_id = getQueryParams("product");
        if (product_id) {
          this.setState({"product": {"value": product_id, "error": ""}});
        }
    }

    componentWillUnmount() {
      if (typeof(this.listener) == "function") {
          this.listener();
      }
    }

    submit() {
        const types = this.state.types.value,
              comment = this.state.comment.value,
              date = this.state.date,
              is_expenses = 1,
              amount = this.state.amount.value || 0;


        NotificationActions.add(types, amount, date, comment, is_expenses);

        this.listener = NotificationActions.add.completed.listen(() => {
            setTimeout(() => {this.setState({"close": true})}, 100);
        });
    }

    onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
        [name]: {
          'value': value
        }
        });
    }

    onChangeDateSaled(date) {
        this.setState({
            "date": date
        });
    }


    getInterval() {
      if (this.state.types.value === "2") {
        return [
          addDays(this.state.date, 7),
          addDays(this.state.date, 14),
          addDays(this.state.date, 21),
          addDays(this.state.date, 28),
          addDays(this.state.date, 35),
          ]
      } else if (this.state.types.value === "1") {
        return [
          addMonths(this.state.date, 1),
          addMonths(this.state.date, 2),
          addMonths(this.state.date, 3),
          ]
      }

      return false;
    }

    render() {
        return (
              <Box>
                <Field>
                  <Control>
                    <Label>{T('expenses-amount')}</Label>
                    <Input  size="large" 
                          name="amount" 
                          type="number" 
                          min="0.01"
                          placeholder={T('expenses-amount-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.amount.error) ? "danger" : "primary"}
                          value={this.state.amount.value}
                          />
                  </Control>
                </Field>
                <Field>
                  <Label>{T("type-of-expenses")}</Label>
                    <Control>
                        <Radio
                            onChange={this.onChange} 
                            checked={this.state.types.value === "1"} 
                            value="1"
                            size="large"
                            name="types">
                          {T("eachmonth")}
                        </Radio>
                        <Radio 
                            onChange={this.onChange} 
                            checked={this.state.types.value === "2"}
                            value="2"
                            size="large"
                            name="types">
                          {T("eachweek")}
                        </Radio>
                        <Radio 
                            onChange={this.onChange} 
                            checked={this.state.types.value === "3"}
                            value="3"
                            size="large"
                            name="types">
                          {T("onetime")}
                        </Radio>
                    </Control>
                </Field>
                <Field>
                    <Label>{T("expenses-date")}</Label>
                    <Control>
                       <DatePicker
                          selected={this.state.date}
                          onChange={this.onChangeDateSaled}
                          className="input"
                          dateFormat="dd-MM-yyyy HH:mm"
                          showTimeInput
                          highlightDates={this.getInterval()}
                          showTimeSelect
                          locale="ru"
                          timeFormat="HH:mm"
                        />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Label>{T('description')}</Label>
                        <Input  size="large" 
                              name="comment" 
                              type="text" 
                              placeholder={T('expenses-description-placeholder')}
                              onChange={this.onChange} 
                              color={(this.state.comment.error) ? "danger" : "primary"}
                              value={this.state.comment.value}
                              />
                    </Control>
                </Field>
                <Button.Group>
                  <Button fullwidth size="large"  rounded color="primary"
                    onClick={this.submit}
                    >{T('add-plan-expenses-btn')}</Button>
                </Button.Group>
                {this.state.close ? redirectTo("/expenses") : ""}
              </Box>
        );
    }

}


export default connectToStores(AddPlanExpensesModal, { 
    expenses: ExpensesStore,
    products: ProductsStore 
  });
