import React, { Component } from "react";
import T from "components/i18n";
import Money from "components/money";
import DOM from "react-dom-factories";
import Button from "react-bulma-components/lib/components/button";
import ProductsStore from "../../products/products-store";
import Columns from "react-bulma-components/lib/components/columns";
import List from "react-bulma-components/lib/components/list";
import { Link } from "react-router-dom";
import AppStore from "../../../app-store";
import ViewProductModal
  from "../../products/modal/view-product/view-product-modal";
import TypeProductTag
  from "../../../components/type-product-tag/type-product-tag";
import {
  withIsMobileSize
} from "../../../components/is-mobile-context/is-mobile-context";

class ProductsRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderMobile() {
    const { data } = this.props;
    const getProduct = ProductsStore.getProduct(data.pid)

    return (
      <List.Item className="hover left" key={data?.pid + data?.name}>
        {getProduct ? <Link to="#" onClick={() => AppStore.openModal(
          <ViewProductModal pid={data['pid']}/>)}>
          {data["products-name"]}
        </Link> : data["products-name"] }
        <br />
        <Columns className="is-mobile">
          <Columns.Column>
            <b>{T("product-info-warehouse")}:</b>{" "}
            {ProductsStore.getStockUnits(data["stock"], data["units"])}
            <br />
            <b>{T("days-groceries-left")}:</b> {data["days-groceries-left"]}
            <br />
            <b>{T("total-sales")}:</b>{" "}
            {ProductsStore.getStockUnits(data["count"], data["units"])}
            <br />
            {/*<b>{T('average-inventory')}:</b> {data['average-inventory']}<br />*/}
            <b>{T("profit-percentage")}:</b> {data["profit-percentage"]}% <br />
            <b>{T("margin")}: </b> {data["margin"]}%
          </Columns.Column>
          <Columns.Column>
            {!!data.category_name && (
              <>
                <b>{T("category")}:</b> {data.category_name}
                <br />
              </>
            )}
            {!!data.brand_name && (
              <>
                <b>{T("brand")}:</b> {data.brand_name}
                <br />
              </>
            )}
            <b>{T("net-amounts")}:</b>{" "}
            <Money amount={data["sum-net"] || 0} wrapper={DOM.span} />
            <br />
            <b>{T("sales-amounts")}:</b>{" "}
            <Money amount={data["sum-revenue"] || 0} wrapper={DOM.span} />
            <br />
            <b>{T("dashboard-sales-profit")}:</b>{" "}
            <Money amount={data["sum-gross-profit"] || 0} wrapper={DOM.span} />
            <br />
            <b>{T("average-sales")}:</b>{" "}
            {ProductsStore.getStockUnits(data["average-sales"], data["units"])}
          </Columns.Column>
        </Columns>
      </List.Item>
    );
  }

  getClassName(number) {
    if (number) {
      if (number === 0) {
        return "has-text-danger";
      } else if (number > 10 && number < 20) {
        return "has-text-warning";
      } else {
        return "has-text-success";
      }
    } else {
      return "has-text-danger";
    }
  }

  getColumnName(data) {
    const getProduct = ProductsStore.getProduct(data.pid)
    return (
      <Columns.Column
        size="one-fifth"
        align="left"
        className="column-name-wrapper"
      >

        <div className="column-name">
          <TypeProductTag typeProduct={getProduct.type_product}/>{' '}
          {getProduct ? <Button
            title={
              data["products-name"]?.length > 80 ? data["products-name"] : ""
            }
            renderAs="a"
            className="button-link-without-style"
            onClick={() => AppStore.openModal(
              <ViewProductModal pid={data['pid']}/>)}
          >

            {data["products-name"]?.length > 80
              ? `${data["products-name"].slice(0, 80)}...`
              : data["products-name"]}
          </Button> : data["products-name"]}
          <div align="left">
            ID: {data.pid}{" "}
            {data.brand_name && `| ${T("brand")}: ${data.brand_name}`}{" "}
            {data.category_name && `| ${T("category")}: ${data.category_name}`}
          </div>
        </div>
      </Columns.Column>
    );
  }

  getColumnStock({ className, stock, units, pid, isDrop }) {
    const isServices = ProductsStore.isServicesProduct(pid)
    return (
      <Columns.Column>
        <span className={isServices || isDrop ? 'has-text-success' : className} style={{ fontWeight: 700 }}>
          {isServices || isDrop ? "∞" : ProductsStore.getStockUnits(stock, units)}
        </span>
      </Columns.Column>
    );
  }

  getColumnDaysGroceriesLeft({ daysGroceriesLeft }) {
    return (
      <Columns.Column>
        <span
          className={this.getClassName(daysGroceriesLeft)}
          style={{ fontWeight: 700 }}
        >
          {daysGroceriesLeft}
        </span>
      </Columns.Column>
    );
  }

  getColumnCountUnits({ count, units }) {
    return (
      <Columns.Column>
        {ProductsStore.getStockUnits(count, units)}
      </Columns.Column>
    );
  }

  getColumnMoney({ amount }) {
    return (
      <Columns.Column>
        <b>
          <Money amount={amount || 0} wrapper={DOM.span} />
        </b>
      </Columns.Column>
    );
  }

  getColumnPercentage({ amount }) {
    return <Columns.Column>{amount || 0}%</Columns.Column>;
  }

  getColumnField({ title }) {
    return <Columns.Column>{title}</Columns.Column>;
  }

  getRender(data, className) {
    const { activeColumn } = this.props;
    const sorting = activeColumn?.sort((a, b) => a?.position - b?.position);
    const keyMap = {
      "products-name": this.getColumnName(data),
      "product-info-warehouse": this.getColumnStock({
        className,
        stock: data["stock"],
        units: data["units"],
        pid: data['pid'],
        isDrop:data['isDrop']
      }),
      "days-groceries-left": this.getColumnDaysGroceriesLeft({
        daysGroceriesLeft: data["days-groceries-left"],
      }),
      // "average-inventory": "average-inventory",
      "total-sales-report": this.getColumnCountUnits({
        count: data["count"],
        units: data["units"],
      }),
      "average-sales": this.getColumnCountUnits({
        count: data["average-sales"],
        units: data["units"],
      }),
      "net-amounts": this.getColumnMoney({ amount: data["sum-net"] }),
      "sales-amounts": this.getColumnMoney({ amount: data["sum-revenue"] }),
      "dashboard-sales-profit": this.getColumnMoney({
        amount: data["sum-gross-profit"],
      }),
      "profit-percentage": this.getColumnPercentage({
        amount: data["profit-percentage"],
      }),
      margin: this.getColumnPercentage({ amount: data["margin"] }),
    };
    const response = [];

    sorting.forEach((item) => {
      if (item?.is_active && item.label) {
        response.push(
          <React.Fragment key={item.label}>
            {keyMap[item?.label]}
          </React.Fragment>
        );
      }
    });

    return response;
  }

  renderDesktop() {
    const { data } = this.props;

    let className = "";
    if (data["stock"] === 0) {
      className = "has-text-danger";
    } else if (data["stock"] === 1) {
      className = "has-text-warning";
    } else {
      className = "has-text-success";
    }
    return (
      <List.Item className="hover">
        <Columns style={{ position: "relative" }}>
          {this.getRender(data, className)}
        </Columns>
      </List.Item>
    );
  }

  render() {
    return this.props.isMobileSize ? this.renderMobile() : this.renderDesktop();
  }
}

export default withIsMobileSize(ProductsRow);
