
const calcMargin = (netprice, price, sale_price) => {
  	const  work_price = (sale_price) ? sale_price : price; 
  	if (!work_price) {
  		return "-";
  	}
    // is't for margin.. 
    //let margin = ((((work_price || 0) - (netprice || 0)) / (work_price || 0)) * 100);
    let margin = ((((work_price || 0) - (netprice || 0)) / (netprice || work_price || 0)) * 100);
    if (margin === Infinity) {
        margin = 0;
    }
    margin = margin || 0;
    const result = (Math.round(margin * 100)/100);

    // return  `${result}%`;
    return result;
};

export default calcMargin;