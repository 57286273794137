import React from "react";
import WarehouseStore from "../../../warehouse/warehouse-store";
import ClientsStore from "../../../clients/clients-store";
import SalesStore from "../../../sales/sales-store";
import T from "../../../../components/i18n";
import { Box, List } from "react-bulma-components";
import { Label } from "react-bulma-components/lib/components/form";
import DOM from "react-dom-factories";
import Money from "../../../../components/money";

const GeneralSubReportRowMobile = ({ data, activeFilter }) => {
  let name = data.name;

  if (activeFilter === "mid") {
    name = WarehouseStore.getWarehouseName(data.name);
  } else if (activeFilter === "clients") {
    const client = ClientsStore.getClientById(data.name);
    name = client?.name || data.name;
  } else if (activeFilter === "channels") {
    const channelName = SalesStore.getChannelsName(data.name);
    name = T(channelName);
  }

  return (
    <tr>
      <td>
        <Box style={{ marginBottom: 5 }}>
          <Label>{name}</Label>
          <List>
            <List.Item>
              <span className="silver">{T("sales-amounts")}: </span>
              <Money
                amount={data?.amount_sale || 0}
                wrapper={DOM.span}
                signClassname
              />
            </List.Item>
            <List.Item>
              <span className="silver">{T("dashboard-sales-profit")}: </span>
              <Money
                amount={(data?.profit || 0) - (data?.expensesProfit || 0)}
                wrapper={DOM.span}
                signClassname
              />
            </List.Item>
            <List.Item>
              <span className="silver">{T("sales")}: </span>
              {data?.count || 0} ({data?.quantity || 0} {T("items-e")})
            </List.Item>
            <List.Item>
              <span className="silver">{T("average-check")}: </span>
              <Money amount={data?.averageCheck || 0} wrapper={DOM.span} />
            </List.Item>
            <List.Item>
              <span className="silver">{T("many-margin")}: </span>
              {data?.margin || 0}%
            </List.Item>
          </List>
          <Label>{T('Expenses')}</Label>
          <List>
            <List.Item>
              <span className="silver">{T("expenses-shipment")}: </span>
              <b>
                <Money
                  amount={data?.shipmentExpenses || 0}
                  wrapper={DOM.span}
                  className="has-text-danger"
                />
              </b>
            </List.Item>
            <List.Item>
              <span className="silver">{T("expenses-profit-sales")}: </span>
              <b>
                <Money
                  amount={data?.expensesProfit || 0}
                  wrapper={DOM.span}
                  className="has-text-danger"
                />
              </b>
            </List.Item>
            <List.Item>
              <span className="silver">{T("expenses-without-shipment")}: </span>
              <b>
                <Money
                  amount={data?.amountExpenses || 0}
                  wrapper={DOM.span}
                  className="has-text-danger"
                />
              </b>
            </List.Item>
            {!!data?.notCalcExpenses && <List.Item>
              <span className="silver">{T("expenses-not-deducted")}: </span>
              <b>
                <Money
                  amount={data?.notCalcExpenses || 0}
                  wrapper={DOM.span}
                />
              </b>
            </List.Item>}
          </List>
        </Box>
      </td>
    </tr>
  );
};

export default GeneralSubReportRowMobile;
