import React from "react";
import s from "./styles.module.scss";

const Container = ({ classNameContainer, classNameWrapper, id,children }) => {
  return (
    <section
      id={id}
      className={`${s.wrapper} ${classNameWrapper ? classNameWrapper : ""}`}
    >
      <section
        className={`${s.section} ${
          classNameContainer ? classNameContainer : ""
        }`}
      >
        {children}
      </section>
    </section>
  );
};

export default Container;
