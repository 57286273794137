import React, { Component } from "react";
import T from "components/i18n";
import Money from "components/money";
import WarehouseStore from "modules/warehouse/warehouse-store";
import ProductsStore from "modules/products/products-store";
import Level from 'react-bulma-components/lib/components/level';
import OpenModalButton from "components/modal/open-modal-button";
import NumberBlock from "components/number-block";
import User from 'user/user-store';
import OpenInsideModal from "components/modal/open-inside-modal";
import AddProductions from "../add-production";
import DOM from "react-dom-factories";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Link } from "react-router-dom";
import formatDate from 'tools/format-date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Actions from '../production-actions'
import List from 'react-bulma-components/lib/components/list';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';
import Dropdown from 'react-bulma-components/lib/components/dropdown';
import AppStore from "../../../app-store";
import ViewProductModal
  from "../../products/modal/view-product/view-product-modal";
import {Button} from "react-bulma-components";
import ProductionStore from "../production-store";
import ProductionActions from "../production-actions";
import {confirmDelete} from "../../../tools/confirm-delete";
import {
  withIsMobileSize
} from "../../../components/is-mobile-context/is-mobile-context";

class HistoryRow extends Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.onUndoProduction =  this.onUndoProduction.bind(this);
  }


    getRegistrationButton() {
        const {data} = this.props;

        return (
            <Level.Item key="regBut">
                <OpenModalButton size="small"  color="success" link={{
                              pathname: '/production/edit',
                              state: {'step': 2, 'edit': true, 'data': data }
                            }} text={T('finish-production-btn')} />
            </Level.Item>

        );
    }

    getEditButton() {
        const {data} = this.props;

        return (
            <Level.Item key='edit-btn'>
                <OpenInsideModal renderAs="icon" icon="edit">
                        <AddProductions data={data} edit={true} />
                </OpenInsideModal>
              </Level.Item>
        );

    }

    getProductionButton() {
        const {data} = this.props;

        return (
            <Level.Item key='produce-btn'>
                <OpenInsideModal text='produce-goods' size="small"  color="success">
                        <AddProductions data={data} step={2}/>
                </OpenInsideModal>
              </Level.Item>
        );

    }

    getDeleteButton() {
        const {data} = this.props;

        return (
            <Level.Item key="delBut">
                <Link to="#" onClick={() => this.deleteProduction(data['id'])} title={T('delete')}>
                        <FontAwesomeIcon icon="trash-alt" size="1x" />
                </Link>
            </Level.Item>

        );
    }

    getButton() {
        let response = [
            this.getProductionButton(),
            this.getEditButton(),
            this.getDeleteButton()
        ]

        return response;
    }

    getPriceButton() {
        const {data} = this.props;

        return (
            <Level.Item>
                <OpenModalButton size="small"  color="info" link={{
                              pathname: '/production/edit',
                              state: {'step': 2, 'only_show': true, 'data': data }
                            }} text={T('see-product-btn')} />
            </Level.Item>

        );
    }


    getPrintButtonOne(sid) {
        const {data} = this.props;
        let pids = [];
        for (let key in data['items']) {
            const pr = data['items'][key];
            pids.push(ProductsStore.getIdent(pr['product_id'], data['to_marketplace_id']))
        }

      return (<Dropdown up={this.props.isMobileSize}
          label={<FontAwesomeIcon icon="print" size={"1x"}  title={T('print-shipment')}/>}
        >
        <Dropdown.Item renderAs={Link} to={this.getPrintUrl(sid, "sale")} target="_blank" title={T('with-sale-price')} value="item">
            {T('with-sale-price')}
        </Dropdown.Item>
         <Dropdown.Item renderAs={Link} to={ProductsStore.getPrintTabLink(pids)} target="_blank" title={T('print-tags')} value="item">
                {T('print-tags')}
        </Dropdown.Item>
      </Dropdown>
      )
    }
    getPrintButton(sid) {
        const {data} = this.props;
        let pids = [];
        for (let key in data['items']) {
            const pr = data['items'][key];
            pids.push(ProductsStore.getIdent(pr['product_id'], data['to_marketplace_id']))
        }
        if (!User.getPermissionEnable("incomeinformation")) {
            return this.getPrintButtonOne(data.id);
        }
      return (<Dropdown up={this.props.isMobileSize}
          label={<FontAwesomeIcon icon="print" size={"1x"}  title={T('print-shipment')}/>}
        >
            <Dropdown.Item renderAs={Link} to={this.getPrintUrl(data.id, "net")} target="_blank" title={T('with-netprice')} value="item">
                {T('with-netprice')}
            </Dropdown.Item>
            <Dropdown.Item renderAs={Link} to={this.getPrintUrl(data.id, "sale")} target="_blank" title={T('with-sale-price')} value="item">
                {T('with-sale-price')}
            </Dropdown.Item>
            <Dropdown.Item renderAs={Link} to={ProductsStore.getPrintTabLink(pids)} target="_blank" title={T('print-tags')} value="item">
                {T('print-tags')}
            </Dropdown.Item>
          </Dropdown>
      )
    }

    deleteProduction(sid) {
        confirmAlert({
          title: T('confirm-delete'),
          message: '',
          buttons: [
            {
              label: T('delete'),
              onClick: () => Actions.delete({
                'mov_id': sid, 'type': 'production'
              })
            },
            {
              label: T('no-delete'),
              onClick: () => {}
            }
          ]
        });
    }

    getPrintUrl(sid,_type) {
        return "/bprint/production?mid="+sid+"&type="+_type+"&uid="+User.get("id")+"&token="+encodeURIComponent(User.getToken())
    }

    onUndoProduction() {
      confirmDelete(
        () => ProductionActions.cancelProduction({hid:this.props.data.id, type: 'undo_history'}),
        'confirm-production-cancel',
        false,
        'confirm-label',
        'cancel-label'
      )
    }

    mobileRender() {
        const {data} = this.props;
        let product = ProductsStore.getProduct(data['product_id']);
        return (
            <tr>
                <td colSpan="5">
                        <Heading size={6} className="margin-top-10">
                             <b>{T('production')}: </b>
                          <Button
                            className="button-link-without-style"
                            onClick={() =>
                              AppStore.openModal(
                                <ViewProductModal
                                  pid={data['product_id']}
                                  mid={data["marketplace_id"]}
                                />
                              )
                            }
                          >
                            {product['name']}
                          </Button>
                          <br />
                            <b>{T("history-data-to_mid")}:</b> {T(WarehouseStore.getWarehouseName(data['marketplace_id']))}
                        </Heading>

                        <List>
                            <List.Item>
                                <b>{T("date")}:</b> {formatDate(data['created_at'])}
                            </List.Item>
                            <List.Item>
                                <b>{T("amount-on-producations")}:</b> <Money amount={data['total']} wrapper={DOM.span}/>
                            </List.Item>
                            <List.Item>
                                <b>{T("conponent-on-amount")}:</b> <Money amount={data['products_prices']}  wrapper={DOM.span}/>
                            </List.Item>
                            <List.Item>
                                <b>{T("expenses-productions")}:</b> <Money amount={data['expenses_amount']} wrapper={DOM.span} />
                            </List.Item>
                            {data["category_id"] && (
                              <List.Item>
                                  <b>{T("category")}: </b>
                                  {ProductionStore.getCategoryName(data["category_id"])}
                              </List.Item>)}

                        </List>
                        <Columns className="is-mobile product-mobile-block">
                              <Columns.Column size="half">
                                     <NumberBlock top={'production-quantity'} number={data['quantity'] || 0}  bottom={ProductsStore.getUnits(product['units'])} className="small-number-box" />
                              </Columns.Column>
                              <Columns.Column>
                                    <NumberBlock top="production-netcost" number={data['net_price'] || 0} bottom={User.getCurrency(data['currency'])} money={true} className="small-number-box" />
                              </Columns.Column>
                        </Columns>

                        <div>{data['comment']}</div>
                        <Level>
                          <Level.Item>
                            <Button className='button-link-icon' onClick={this.onUndoProduction}>
                              <FontAwesomeIcon icon='undo' size='2x'/>
                            </Button>
                          </Level.Item>
                        </Level>
                </td>
            </tr>
        );
    }

                // <!--<td>{formatDate(data['created_at'])}</td>-->
    desktopRender() {
        const {data} = this.props;
        let product = ProductsStore.getProduct(data['product_id']);

        return (
            <tr className='is-size-7'>
                <td>{data['id']}</td>
                <td>{formatDate(data['created_at'])}</td>
                <td align="left" width="50%"> <b>{T('production')}: </b>
                  <Button
                    className="button-link-without-style"
                    onClick={() =>
                      AppStore.openModal(
                        <ViewProductModal
                          pid={data['product_id']}
                          mid={data["marketplace_id"]}
                        />
                      )
                    }
                  >
                    {product['name']}
                  </Button>
                  <br />
                  <b>{T("history-data-to_mid")}:</b> {T(WarehouseStore.getWarehouseName(data['marketplace_id']))}
                  {data["category_id"] && (
                    <>
                      <br/>
                      <b>{T("category")}: </b>{" "}
                      {ProductionStore.getCategoryName(data["category_id"])}
                    </>
                  )}
                </td>
                <td align="center">
                    {data['quantity']} {ProductsStore.getUnits(product['units'])}
                </td>
                <td align="center">
                    <Money amount={data['net_price']} />
                </td>
                <td align="center">
                    <Money amount={data['total']} />
                </td>
                <td align="center">
                    <Money amount={data['products_prices']} />
                </td>
                <td align="center">
                    <Money amount={data['expenses_amount']} />
                </td>
                <td align="right">
                  <Button className='button-link-icon' onClick={this.onUndoProduction}>
                    <FontAwesomeIcon icon='undo' size='1x'/>
                  </Button>
                </td>
            </tr>
        );
    }

    render() {
        return this.props.isMobileSize ? this.mobileRender() : this.desktopRender();
    }
}

export default withIsMobileSize(HistoryRow);
