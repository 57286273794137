import React, { Component } from 'react';
import T from "components/i18n";

// import { redirectTo } from 'tools/redirect-to'
import AppStore from 'app-store';


import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import User from 'user/user-store';

// import OpenModalFactory from 'components/modal/open-modal-factory';


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Link } from "react-router-dom";

// import SelectComponent from 'tools/select-component'

class VideoHelpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          close: false
        };

    }

    componentDidMount() {
        if (User.getOnBoaringStep() === 0) {
            User.setOnBoaringStep(1);
        }
    }

    componentWillUnmount() {

    }

// <iframe width="560" height="315" src="https://www.youtube.com/embed/kZhjQKp0pmU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    render() {
        console.log("VIDEO , ", AppStore.isEnableForWhiteLable("video-help"))
        if (!AppStore.isEnableForWhiteLable("video-help")) return null
        return (
              <Box className="video-help">
                <iframe style={{width: '100%'}} title="video-help-main" width="660" height="400" src={this.props.video} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <Button.Group>
                    <Button fullwidth size="medium" onClick={this.props.onClose} rounded color="light" >{T('close')}</Button>
                </Button.Group>
              </Box>
        );
    }

}


export default VideoHelpModal;
