import React from 'react'
import BaseFilterLayout from "../../../components/base-filter-layout";
import ShipmentsStore from "../shipments-store";
import ShipmentsActions from "../shipments-actions";

const FilterPanel = ({searchFilter, onChangeSearch}) => {

	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value).reverse()
		daysKey.forEach(item => {
			ShipmentsActions.filter(item, value[item]);
		})
	}

	return (
		<BaseFilterLayout
			searchStringSetting={{
				onChange: onChangeSearch,
				defaultValue: searchFilter,
			}}
			dateRangeSetting={{
				onChange: handleChangeDate,
				date_start: ShipmentsStore.getFilter("date_start"),
				date_end: ShipmentsStore.getFilter("date_end"),
		}}
		/>
	);
};

export default FilterPanel;