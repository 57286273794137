import yajax from "yajax";

import { createStore } from "../../tools/reflux-tools";
import Actions from './inventory-actions';
import ProductActions from '../products/products-actions';
import UserStore from "../../user/user-store";
import User from "../../user/user-store";
import {getDatePeriod} from "../../tools/date-range";
// import User from '../../user/user-store';
import T from '../../components/i18n'

const CLEAR_FILTER = () => ({
    date_start: getDatePeriod(90),
    date_end: new Date(),
    search: '',
    mid: {value: 0, label: T('all')},
    rid: {value: 0, label: T('all')}
})

const InventoryStore = createStore({
	listenables: Actions,
    _prefix: '/bproducts/',
    state: {
        inventory: null,
        lastAdd: 0,
        isLoaded: false,
        isLoadedDelivery: false,
        isLoading: false,
        _filter: CLEAR_FILTER()
    },

    get(field) {
        return this.state[field];
    },

    clearFilter() {
      this.setState({
          _filter: CLEAR_FILTER()
      })
    },

    onAdd(data) {
        console.log("start add inventory", data)
        let url = this._prefix+"inventory"

        if(data.step === 3) {
            url = "/bproducts-loads/inventory"
        }
        yajax.post(url, JSON.stringify(data)).then(
            Actions.add.completed, Actions.add.failed
        );
    },

    onFilter(name, value) {
        let filters = {};

        for (let i in this.state['_filter']) {
            filters[i] = this.state['_filter'][i];
        }

        let date_start = filters['date_start'];
        let date_end = filters['date_end'];
        filters[name] = value;

        this.setState({
            _filter: filters,
        });

        if (name === "date_start" && value < date_start) {
            Actions.load();
        }
        if(name === 'date_end' && value > date_end) {
            Actions.load();
        }
    },

    onAddCompleted(data) {
        console.log("result add inventory: ", data)
        //Actions.load();
        if (data['finish']) {
            setTimeout(() => Actions.load(true), 1000);
        } 

        if ([3,4].indexOf(data['step']) > -1) {
            setTimeout(() => ProductActions.load(true), 5000);
        }
    },
    
    onAddFailed(){
        console.log("failed add inventory")
    },

    onPut(data) {
        const params = {
          method: "PUT",
          url: this._prefix+"inventory",
          data: JSON.stringify(data)
        };

        const ids = data.data.map(item => item.product_id)

        yajax(params).then(
          (data) => Actions.put.completed(data, ids), (res) => Actions.put.failed(res, ids)
        );
    },
    
    onPutCompleted(data, ids) {
        if (data['type'] === "inventory") {
            Actions.load();
        }
        console.log("result add product: ", data)
    },

    onPutFailed(res, ids){
        console.log("failed delete inventory product")
    },

    onDelete(data) {
        console.log("start delete inventory product", data);
        
        const params = {
          method: "DELETE",
          url: this._prefix+"inventory",
          data: data
        };

        yajax(params).then(
            Actions.delete.completed, Actions.delete.failed
        );
    },
    onDeleteCompleted(data) {
        if (data['type'] === "inventory") {
            Actions.load();
        }
        console.log("result delete shhipment product: ", data)
    },

    onDeleteFailed(){
        console.log("failed delete inventory product")
    },

    getList(list) {
        const inventory = this.get(list);
        if (inventory === null) {
            Actions.load();
            return {};
        }

        return inventory;
    },

    getInventoryById(sid) {
        let result = this.state.inventory.filter((ship) => parseInt(ship['id']) === parseInt(sid))
        return result.length > 0 ? result[0] : {}
    }, 

    getInventoryByProductId(pid) {
        let inventory_by_product = [];
        for (let i in this.state.inventory) {
            const ship = this.state.inventory[i];
            for (let j in ship['items']) {
                const item = ship['items'][j];
                if (parseInt(item['product_id']) === parseInt(pid)) {
                    inventory_by_product.push({
                        'id': ship['id'],
                        'name': ship['name'],
                        'marketplace_id': ship['marketplace_id'],
                        'supplier_id': ship['supplier_id'],
                        'currency_id': ship['currency'],
                        'amount': ship['amount'],
                        'created_at': ship['created_at'],
                        'delivered_date': ship['delivered_date'],
                        'item_amount': item['amount'],
                        'purchase_price': item['purchase_price'],
                        'quantity_received': item['quantity_received'],
                        'quantity_shipped': item['quantity_shipped'],
                        'quantity_sold': item['quantity_sold']
                    });
                    break;
                }
            }
        }

        return inventory_by_product;
    },

    onLoad(force) {
        if (this.inventoryLoading) return;
        console.log("start load inventory...")

        this.inventoryLoading = true;
        this.setState({
            isLoading: true
        })
        let url = this._prefix+"inventory";

        const data = {

        }

        if(force) {
            data.t = Date.now()
        }

        const filter = this.get('_filter')

        if (filter['date_start']) {
            const date_start = filter['date_start'].setHours(0, 0, 0, 0);
            data.start = parseInt(`${date_start / 1000}`)
        }

        if (filter['date_end']) {
            const date_end = filter['date_end'].setHours(23, 59, 59, 999);
            data.end = parseInt(`${date_end / 1000}`);
        }

        yajax.get(url, data).then(Actions.load.completed, Actions.load.failed);
    },
    
    onLoadCompleted(data) {
        if (data['success']) {

            const permissionWarehouse = UserStore.getPermissionWarehouse()


            const filterWithWarehouse = data['data'].filter(item => {
                let result = true
                if(permissionWarehouse?.length) {
                    result = result && permissionWarehouse.some(permissionID => permissionID === item?.marketplace_id)
                }

                return result
            })

            this.setState({
                'inventory': filterWithWarehouse
            });
        }
        this.setState({
            isLoading: false
        })
        this.inventoryLoading = false;
        console.log("result load inventory: ", data)
    },

    onLoadFailed(){
        this.inventoryLoading = false;
        this.setState({
            isLoading: false
        })
        console.log("failed loadimg inventory")
    },

    printInventoryURL(type, id) {
      return `/bprint/inventory?inventory_ids=${id}&lang=${UserStore.getLang()}&format=${type}&token=${encodeURIComponent(User.getToken())}`;
    },

    onAddAllProduct(inventoryID) {
      yajax.post(this._prefix + 'inventory-all-products', {inventory_id: inventoryID}).then(Actions.addAllProduct.completed, Actions.addAllProduct.failed,)
    },
    onAddAllProductCompleted() {
      Actions.load()
    },
    onAddAllProductFailed(){

    },

});

export default InventoryStore;