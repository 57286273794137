import React from 'react'
import User from "../../../user/user-store";
import {Link} from "react-router-dom";
import T from "../../../components/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { LocalIcon } from 'whitelables/wl-name/icons'
import {deleteClient, handleStopPropagation} from "./utils";
import AppStore from "../../../app-store";
import AddToClientTagModal from "../clients-tags/add-to-client-tag.modal";
import Level from "react-bulma-components/lib/components/level";
import AddClientModal from "../modal/add-client-modal/add-client-modal";
import ClientsStore from "../clients-store";
import {Button} from "react-bulma-components";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";


const ActionsColumn = ({id}) => {
	const {isMobileSize} = useIsMobile()
	const handleOpenModalEditTags = (event) => {
		handleStopPropagation(event)

		AppStore.openModal(<AddToClientTagModal modal='add-to-client-tags' id={id}/>)
	}

	const handleEditClient = (evt) => {
		evt.stopPropagation()
		AppStore.openModal(<AddClientModal
			edit={true}
			data={ClientsStore.getClientById(id)}
			client_id={id}
		/>)
	}

	return (
		<Level renderAs="div"  breakpoint="mobile" className={`is-mobile ${isMobileSize ? '' : 'action-buttons'}`}>
			<Level.Item>
				<Link
					to="#"
					title={T('add-clients-tags')}
					onClick={handleOpenModalEditTags}
				>
					<LocalIcon icon='tag' size={isMobileSize ? 'large' : "small"}/>
				</Link>
			</Level.Item>

			<Level.Item>
				<Button className='button-link-icon' onClick={handleEditClient}>
					<FontAwesomeIcon icon="edit" size={isMobileSize ? '2x' : "1x"} />
				</Button>
				{/*<Link*/}
				{/*	to={{*/}
				{/*		pathname: '/clients/edit-client',*/}
				{/*		state: {*/}
				{/*			cid: id*/}
				{/*		}*/}
				{/*	}}*/}
				{/*	title={T('edit-btn')}*/}
				{/*	onClick={handleStopPropagation}*/}
				{/*>*/}
				{/*	<FontAwesomeIcon icon="edit" size={isMobileSize ? '2x' : "1x"} />*/}
				{/*</Link>*/}
			</Level.Item>

			<Level.Item>
				{!User.getPermissionEnable("delete-client") ? null :
					<Link
						to="#"
						onClick={(event) => deleteClient(event, id)}
						title={T('delete')}
					>
						<FontAwesomeIcon icon="trash-alt" size={isMobileSize ? '2x' : "1x"} />
					</Link>
				}
			</Level.Item>
		</Level>
	);
};

export default ActionsColumn;