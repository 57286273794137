import React, { Component } from "react";
import User from 'user/user-store';
import { Link } from "react-router-dom";
import T from "components/i18n";
import { Button } from 'react-bulma-components';
import {
    withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";


class PrintButton extends Component {
    constructor(props) {
        super(props);

        this.url = props.url;
        this.text = props.text;
        this.size = this.props.isMobileSize ? "small": "medium"
    }


    defaultButton(){
        return (
             <Button renderAs={Link} size={this.size} rounded color="light" target="_blank" to={this.url}>{T(this.text)}</Button>
        )
    }

    appButton() {
        return (
            <Button onClick={() => User.postMessage("open-url::"+this.url)} size={this.size} rounded color="light">{T(this.text)}</Button>
            )

    }
    render() {
        return User.isMobileApp() ? this.appButton() : this.defaultButton();
    }
}


export default withIsMobileSize(PrintButton);
