import yajax from "yajax";

import { createStore } from "../../tools/reflux-tools";
import Actions from "./production-actions";
import ProductActions from "../products/products-actions";
import ExpensesActions from "../expenses/expenses-actions";
import UserStore from "../../user/user-store";
import sortByKey from "../../tools/sort-by-key";
// import User from '../../user/user-store';

const ProductionStore = createStore({
  listenables: Actions,
  _prefix: "/bproducts/",
  state: {
    production: null,
    history: null,
    lastAdd: 0,
    isLoaded: false,
    isLoadedDelivery: false,
    category: null,
    isLoadingCategory: false,
  },

  get(field) {
    return this.state[field];
  },

  onAdd(data) {
    console.log("start add production", data);
    yajax
      .post(this._prefix + "production", JSON.stringify(data))
      .then(Actions.add.completed, Actions.add.failed);
  },

  onAddCompleted(data) {
    console.log("result add production: ", data);
    Actions.load();

    // if ([3,5].indexOf(data['step']) > -1) {
    ProductActions.load(true);
    ExpensesActions.load(true);
    ExpensesActions.loadAccounts(true);
    Actions.loadHistory();
    // }
  },

  onAddFailed() {
    console.log("failed add production");
  },

  onDelete(data) {
    console.log("start delete production product", data);

    const params = {
      method: "DELETE",
      url: this._prefix + "production",
      data: data,
    };

    yajax(params).then(Actions.delete.completed, Actions.delete.failed);
  },
  onDeleteCompleted(data) {
    Actions.load();
    console.log("result delete shhipment product: ", data);
  },

  onDeleteFailed() {
    console.log("failed delete production product");
  },

  onRestore(data) {
    console.log("start restore production product", data);

    const params = {
      method: "PUT",
      url: this._prefix + "production",
      data: data,
    };

    yajax(params).then(Actions.restore.completed, Actions.restore.failed);
  },

  onRestoreCompleted(data) {
    Actions.load(false, true);
  },

  onRestoreFailed() {
  },

  getList(list) {
    const production = this.get(list);
    if (production === null) {
      Actions.load();
      return {};
    }

    return production;
  },

  getHistory() {
    const production = this.get("history");
    if (production === null) {
      Actions.loadHistory();
      this.setState({
        history: [],
      });
      return [];
    }

    return production;
  },

  getProductionById(sid) {
    for (let i in this.state.production) {
      const ship = this.state.production[i];
      if (parseInt(ship["id"]) === parseInt(sid)) {
        return ship;
      }
    }
  },

  getProductionByProductId(pid) {
    let production_by_product = [];
    for (let i in this.state.production) {
      const ship = this.state.production[i];
      for (let j in ship["items"]) {
        const item = ship["items"][j];
        if (parseInt(item["product_id"]) === parseInt(pid)) {
          production_by_product.push({
            id: ship["id"],
            name: ship["name"],
            marketplace_id: ship["marketplace_id"],
            supplier_id: ship["supplier_id"],
            currency_id: ship["currency"],
            amount: ship["amount"],
            created_at: ship["created_at"],
            delivered_date: ship["delivered_date"],
            item_amount: item["amount"],
            purchase_price: item["purchase_price"],
            quantity_received: item["quantity_received"],
            quantity_shipped: item["quantity_shipped"],
            quantity_sold: item["quantity_sold"],
          });
          break;
        }
      }
    }

    return production_by_product;
  },

  onLoad(force, is_deleted) {
    if (this.productionLoading) return;
    console.log("start load production...");

    this.productionLoading = true;
    let url = this._prefix + "production";

    if(is_deleted) {
      url += '?is_deleted=1'
    }

    yajax.get(url).then((res) => Actions.load.completed(res, is_deleted), Actions.load.failed);
  },

  onLoadCompleted(data, is_deleted) {
    if (data["success"]) {
      const permissionWarehouse = UserStore.getPermissionWarehouse();

      const filterWithWarehouse = data["data"].filter((item) => {
        let result = true;
        if (permissionWarehouse?.length) {
          result =
            result &&
            permissionWarehouse.some(
              (permissionID) => permissionID === item?.result_marketplace_id
            );
        }

        return result;
      });

      if(is_deleted) {
        this.setState({
          productionDeleted: filterWithWarehouse,
        });
      } else {
        this.setState({
          production: filterWithWarehouse,
        });
      }

    }
    this.productionLoading = false;
    console.log("result load production: ", data);
  },

  onLoadFailed() {
    this.productionLoading = false;
    console.log("failed loadimg production");
  },

  onLoadHistory(force) {
    if (this.productionHistoryLoading) return;
    console.log("start load production...");

    this.productionHistoryLoading = true;
    let url = this._prefix + "production?type=history";
    yajax
      .get(url)
      .then(Actions.loadHistory.completed, Actions.loadHistory.failed);
  },

  onLoadHistoryCompleted(data) {
    if (data["success"]) {
      const permissionWarehouse = UserStore.getPermissionWarehouse();

      const filterWithWarehouse = data["data"].filter((item) => {
        let result = true;
        if (permissionWarehouse?.length) {
          result =
            result &&
            permissionWarehouse.some(
              (permissionID) => permissionID === item?.marketplace_id
            );
        }

        return result;
      });

      this.setState({
        history: filterWithWarehouse,
      });
    }
    this.productionHistoryLoading = false;
    console.log("result load productionHistoryLoading: ", data);
  },

  getCategory() {
    const category = this.get("category");
    if (category === null) {
      Actions.loadCategory();
      this.setState({
        category: [],
      });
      return [];
    }
    return category;
  },

  getFormattingCategoryList() {
    function formatting(list, level) {
      for (let i in list) {
        let cat = list[i],
          space = "—".repeat(level);

        cat["name"] = space + " " + cat["name"];

        response.push(cat);

        if (parent[cat["id"]] !== "undefined") {
          sortByKey(parent[cat["id"]], "name");
          formatting(parent[cat["id"]], level + 1);
        }
      }
    }
    let categories = this.getCategory();

    let response = [],
      parent = {},
      main_category = [],
      level = 0;
    for (let i in categories) {
      let category = categories[i],
        pid = category["parent_id"];

      if (pid) {
        if (typeof parent[pid] === "undefined") {
          parent[pid] = [];
        }

        parent[pid].push({
          name: category["name"],
          parent_id: pid,
          id: category["id"],
        });
      } else {
        main_category.push({
          name: category["name"],
          parent_id: pid,
          id: category["id"],
        });
      }
    }

    main_category = sortByKey(main_category, "name");

    formatting(main_category, level);

    return response;
  },

  getCategoryById(id) {
    const category = this.getCategory();
    return category.find(item => parseInt(item.id) === parseInt(id))
  },

  getChildrenCategoryList(id, field) {
    const category = this.getCategory();

    const result = [];

    const findChildren = (elements, parentId) => {
      for (const element of elements) {
        if (parseInt(element.parent_id) === parseInt(parentId)) {
          result.push(element);
          findChildren(elements, element.id);
        }
      }
    };

    const currentCategory = this.getCategoryById(id);
    if (currentCategory) {
      result.push(currentCategory);
      findChildren(category, id);
    }


    if (field) {
      return result.map(item => {
        if (item[field]) {
          return item[field];
        }
        return item;
      });
    } else {
      return result;
    }
  },

  getParentCategoryList(id, field) {
    const category = this.getCategory();

    const result = []

    const findElement = (elements, id) => {
      for (const element of elements) {
        if (parseInt(element.id) === parseInt(id)) {
          result.push(element);
          if (element.parent_id) {
            findElement(elements, element.parent_id);
          }
          break;
        }
      }
    };

    findElement(category, id)

    if(field) {
      return result.map(item => {
        if(item[field]) {
          return item[field]
        }
        return item
      }).reverse()
    } else {
      return result.reverse()
    }
  },

  getCategoryName(id, solo) {

    const result = this.getParentCategoryList(id, 'name')

    if(solo) {
      return this.getCategoryById(id)?.name;
    }

    if(result.length > 0) {
      return result.join(" | ");
    }

    return false;
  },
  getCategoryListWithId(id) {
    const category = this.getCategory();
    if (id) {
      const excludeByIdAndChildren = (arr, targetId) => {
        const filteredArray = arr.filter((item) => {
          return item.id !== targetId;
        });

        const filterDescendants = (items, parentId) => {
          let descendants = items.filter((item) => item.parent_id === parentId);
          descendants.forEach((descendant) => {
            filteredArray.splice(filteredArray.indexOf(descendant), 1);
            filterDescendants(items, descendant.id);
          });
        };

        const targetItem = arr.find((item) => item.id === targetId);

        if (targetItem) {
          filterDescendants(arr, targetId);
        }

        return filteredArray;
      };

      return excludeByIdAndChildren(category, id);
    }
    return category;
  },

  onLoadHistoryFailed() {
    this.productionHistoryLoading = false;
    console.log("failed loadimg productionHistoryLoading");
  },
  onAddCategory(data) {
    yajax
      .post(this._prefix + "production_category", JSON.stringify(data))
      .then(Actions.addCategory.completed, Actions.addCategory.failed);
  },
  onAddCategoryCompleted() {
    Actions.loadCategory();
  },
  onAddCategoryFailed() {
    console.log("failed add category");
  },
  onDeleteCategory(id) {
    const params = {
      method: "DELETE",
      url: this._prefix + "production_category",
      data: { id },
    };
    yajax(params).then(
      Actions.deleteCategory.completed,
      Actions.deleteCategory.failed
    );
  },
  onDeleteCategoryCompleted() {
    Actions.loadCategory();
  },
  onDeleteCategoryFailed() {
    console.log("delete category failed");
  },

  onLoadCategory() {
    if (this.state.isLoadingCategory) return;
    yajax
      .get(this._prefix + "production_category")
      .then(Actions.loadCategory.completed, Actions.loadCategory.failed);
    this.setState({
      isLoadingCategory: true,
    });
  },
  onLoadCategoryCompleted(data) {
    if (data["success"]) {
      this.setState({
        category: data["categories"],
        isLoadingCategory: false,
      });
    }
  },
  onLoadCategoryFailed() {
    this.setState({
      isLoadingCategory: false,
    });
    console.log("load production category failed");
  },

  onCancelProduction({hid, type}) {
    const params = {
      method: "DELETE",
      url: this._prefix + "production",
      data: {
        hid,
        type
      }
    }
    yajax(params).then(Actions.cancelProduction.completed, Actions.cancelProduction.failed);
  },
  onCancelProductionCompleted() {
      Actions.loadHistory()
      ProductActions.load(true);
      ExpensesActions.load(true);
      ExpensesActions.loadAccounts(true);
  },
  onCancelProductionFailed() {

  }
});

export default ProductionStore;
