import React, {useState} from 'react'
import {Box} from "react-bulma-components";
import {handleDeleteIntegration, parseObj} from "../utils";
import T from "../../../../../components/i18n";
import IntegrationControlHook from "../common/integration-control.hook";
import IntegrationListenerHook from "../common/integration-listener.hook";
import UserActions from "../../../../../user/user-actions";
import ActionsModal from "../common/actions-modal";
import {confirmDelete} from "../../../../../tools/confirm-delete";
import ModalItsOK from "../../../../../components/modal-its-ok";
import InputsGroupField from "../common/inputs-group-field";
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import Heading from "react-bulma-components/lib/components/heading";

const DATA_INPUT = [
	{
		name: "api_key",
		label: "REST API Key"
	},
	{
		name: "api_pass",
		label: 'REST API Secret'
	},
	{
		name: "websoket_key",
		label: 'WebSoket Key'
	},
	{
		name: "websoket_secret",
		label: 'WebSoket Secret'
	},
	{
		name: 'pbxNumber',
		label: "pbx-number",
		type: 'number',
		className: 'disabled_scroll'
	},
	{
		name: 'name',
		label: "name",
	},
	{
		name: 'telephone_line',
		label: 'binotel-telephone-line-admin',
		type: 'number',
		className: 'disabled_scroll'
	}
]

const Binotel = ({data, wooID, onClose}) => {
	const {api_key, api_pass, id, is_active, ident, name} = data

	let identParse = typeof ident === 'string' ? parseObj(ident) : ident

	const {
		handleAutoRemoveError,
		setErrorMessage,
		errorMessage,
		inputValues,
		handleChangeInputValues,
	} = IntegrationControlHook({
		inputDefaultValue: {
			api_key: api_key || null,
			api_pass: api_pass || null,
			pbxNumber: identParse?.pbxNumber || '',
			name: name || '',
			websoket_key: identParse?.websoket_key || '',
			websoket_secret: identParse?.websoket_secret || '',
			telephone_line: identParse?.telephone_line || ''
		},
	})

	const [waiting, setWaiting] = useState(false)
	const {submitOK, additionalMessage} = IntegrationListenerHook({handleError: handleAutoRemoveError, setWaiting})


	const handleError = () => {
		let error = "";

		const checkedInputValue = !Object.entries(inputValues).filter(([key]) => key !== 'telephone_line').every(
			([key, item]) => key === 'pbxNumber' || !!item
		);

		if (checkedInputValue) {
			error = T("not all fields required");
		}

		if(inputValues.pbxNumber.length !== 10) {
			error = T("not all fields required");
		}

		if (error) {
			handleAutoRemoveError(error);
			return false;
		}

		return true;
	}

	const handleSubmit = ({deactivate}) => {
		const data = {
			"integration_id": wooID,
			"api_key": inputValues.api_key,
			"api_pass": inputValues?.api_pass,
			ident: JSON.stringify({
				pbxNumber: inputValues.pbxNumber,
				websoket_key: inputValues.websoket_key,
				websoket_secret: inputValues.websoket_secret,
				telephone_line: inputValues.telephone_line || ''
			}),
			is_active: 1,
			name: inputValues.name || ''
		}

		if(id) data.id = id
		if(deactivate) data.is_active = 0

		if(handleError()) {
			setWaiting(true)
			UserActions.updateIntegrations(data)
		}
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!submitOK && <Box>
				<Heading size={3}>
					{T('setting-integration', [T(`integration-${wooID}`)])}
				</Heading>
				<InputsGroupField
					inputValues={inputValues}
					dataInput={DATA_INPUT}
					onChange={handleChangeInputValues}
				/>
				<ActionsModal
					onSubmit={handleSubmit}
					onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
					onClose={onClose}
					isEdit={!!id}
					isActive={is_active}
					waiting={waiting}/>
			</Box>}
			{submitOK && <ModalItsOK onClose={onClose} title={submitOK}
															 additionalMessage={additionalMessage}/>}
		</>

	);
};

export default Binotel;