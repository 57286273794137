import React, { Component } from "react";
// import T from "components/i18n";
// import List from 'react-bulma-components/lib/components/list';

class CardRow extends Component {
    render() {
        const {data} = this.props;

        let response = [];

        response.push(
                <tr key={data['id']} >
                    <td>{data['created_at']}</td>
                    <td>{data['account_num']}</td>
                </tr>

            )

        return response;
    }
}

export default CardRow;
