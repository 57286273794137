import { setMessages, merge } from "../components/i18n";
import { AVAILABLE_LANGS } from "./wl-name/api"

export { AVAILABLE_LANGS } from "./wl-name/api"



function getDefaultLang() {
    if (process.env.FORCE_DEFAULT_LANG) {
        return process.env.FORCE_DEFAULT_LANG;
    }

    if ((window.location.search.match(/lang=(\w+)/i) || [])[1]) {
        return (window.location.search.match(/lang=(\w+)/i) || [])[1];
    }

    let path_a = window.location.pathname.split("/");

    for (let l in AVAILABLE_LANGS) {
            if (!l) {continue;}

            for (let i in path_a) {
                let la = path_a[i]
                if (!la || la === "") continue;
                if (la === l) {
                    window.localStorage.setItem("lang", l); 
                    window.parent.postMessage('lang::'+l, "*");
                    console.log("post message "+  l)
                    return l;
                }
            }
    }

    let localStorageLand = window.localStorage.getItem("lang");
    if (localStorageLand) {
        return localStorageLand;
    }

    let lang = "ru";
    const navigatorLanguage = typeof navigator !== "undefined" && (navigator.language || navigator.browserLanguage);

    if (navigatorLanguage) {
        lang = navigatorLanguage.split("-")[0];

        if (["ua", "uk", "en", "ru"].indexOf(lang)) {
            if (lang === "uk") {
                lang = "ua";
            }
        } else {
            lang = ["kk", "lt", "lv", "et", "uz", "az", "hy", "ka", "tt", "ky", "tk", "md", "tg"].indexOf(lang) !== -1 ? "ru" : "en";
        }
    }

    return lang;
}

export const DEFAULT_LANG = getDefaultLang();

const messages = {};

// merge(messages, require("../components/i18n/messages/currencies").default);
merge(messages, require("../components/i18n/messages/account").default);
merge(messages, require("../components/i18n/messages/home").default);
merge(messages, require("../components/i18n/messages/dates").default);
merge(messages, require("../components/i18n/messages/auth").default);
merge(messages, require("../components/i18n/messages/forms").default);
merge(messages, require("../components/i18n/messages/registration").default);
merge(messages, require("../components/i18n/messages/account-tips").default);
merge(messages, require("../components/i18n/messages/modeler").default);

setMessages(messages);
window.I18N_LOADED = true;
