import {createStore} from "../../tools/reflux-tools";
import Actions from "./voting-actions";
import yajax from "yajax";

const VotingStore = createStore({
	listenables: Actions,
	_prefix: "/breports/vote",
	state: {
		rows: null
	},


	onLoad() {
		yajax.get(this._prefix).then(Actions.load.completed, Actions.load.failed);
	},
	onLoadCompleted(data) {
		this.setState({
			rows: Object.values(data.data),
			vote: data.vote?.reduce((acc, cur) => {
				acc[cur.vote_id] = cur
				return acc
			}, {})
		})
	},
	onLoadFailed() {},

	getVote() {
		const data = this.state.rows

		if(data === null) {
			Actions.load()
			this.setState({
				rows: []
			})

			return []
		}

		return data
	},
	getVoteResult(id) {
		const data = this.state.vote

		return data[id] || {}
	},

	onVoting(data) {
		yajax.post(this._prefix, data).then(Actions.voting.completed, Actions.voting.failed);
	},
	onVotingCompleted() {
		Actions.load()
	},
	onVotingFailed() {}
})


export default VotingStore;