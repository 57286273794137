import React, { Component } from 'react';
import { connectToStores } from "tools/reflux-tools";
// import Section from 'react-bulma-components/lib/components/section';


import OrdersStore from "./orders-store";
import Actions from './orders-actions';
import ClientsStore from "modules/clients/clients-store";
import ClientsActions from 'modules/clients/clients-actions';
import SelectComponent from 'tools/select-component';
import UserStore from "user/user-store";
import UserActions from "../../user/user-actions";


class OrdersFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showStatus: true
        };

    }

    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name

        Actions.filter(name, value);
    }

    onChangeSelect(selectedOption, actionMeta) {
          Actions.filter(actionMeta.name, selectedOption);
    }


    selectClients() {
        return (
                <SelectComponent 
                          name="clients"
                          label="clients"
                          empty="all"
                          onChange={this.onChangeSelect} 
                          value={OrdersStore.getFilter("clients")} 
                          list={ClientsStore.getClientsList}
                          load={ClientsActions.load}
                          creatable={false}/>
        );
    } 

    selectIntegration() {
        return (
                <SelectComponent 
                          name="integration"
                          label="integrations"
                          empty="all"
                          onChange={this.onChangeSelect} 
                          value={OrdersStore.getFilter("integration")}
                          list={UserStore.getIntegrationList}
                          load={UserActions.loadProfile}
                          creatable={false}/>
        );
    }

    filterPaymentType() {
        const payment_row = OrdersStore.getPaymentTypeForSelect()

        return payment_row?.length && (
                <SelectComponent
                          name="payment_type"
                          label="payment-type"
                          empty="all"
                          onChange={this.onChangeSelect}
                          value={OrdersStore.getFilter("payment_type")}
                          list={() => payment_row}
                          creatable={false}/>
        );
    }
                
    render () {

        return (
          <>
            {this.selectClients()}
            {this.selectIntegration()}
              {/*{this.filterPaymentType()}*/}
          </>

          )
     }

}

export default connectToStores(OrdersFilters, { _: OrdersStore });