import React from "react";
import { LocalIcon } from "whitelables/wl-name/icons";
import s from "./styles.module.scss";

const loc = window.location;

let url = "";

if (loc.host === "localhost:3000") {
  url = "https://ac1.h-profit.com";
}

const MessageItemDocument = ({ data }) => {
  return (
    <div className={s.message_document_content}>
      <LocalIcon icon="file" size="middle" />
      <a href={`${url}${data.url}`} download>
        {data.name}
      </a>
    </div>
  );
};

export default MessageItemDocument;
