import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import s from "./styles.module.scss";
const PreviewImage = ({ file, onRemove }) => {
  return (
    <div className={s.preview_image}>
      <img
        src={URL.createObjectURL(file)}
        alt={file.name}
      />
      <span
        onClick={() => onRemove && onRemove(file)}
        className={s.preview_image_delete}
      >
        <FontAwesomeIcon icon="trash-alt" size="2x" />
      </span>
    </div>
  );
};

export default PreviewImage;
