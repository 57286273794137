import React from 'react'
import ProductsStore from "../../products/products-store";
import AppStore from "../../../app-store";
import ViewProductModal
	from "../../products/modal/view-product/view-product-modal";
import {Button} from "react-bulma-components";
import WarehouseStore from "../../warehouse/warehouse-store";
import User from "../../../user/user-store";
import T from '../../../components/i18n'
import {parseJSON} from "../../../tools/error-handler";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
const StockMarketplaceProductRow = ({data, errors}) => {
	const {isMobileSize} = useIsMobile()
	const productName = ProductsStore.getProductName(data.product_id, true)
	const warehouseName = WarehouseStore.getWarehouseName(data.mid)
	const img = "/bimages/get/64-" + User.get("id") + "-" + data.product_id + "-0.jpg";

	const parseErrors = parseJSON(errors || [])

	const findErrorByPid = parseErrors.find(item => parseInt(item?.pid) === parseInt(data?.product_id))

	return (
		<div style={isMobileSize ? {} : {borderBottom: '1px solid #dfdfdf', padding: '10px'}}>
			<div className="display-flex-row" style={{alignItems: "flex-start"}}>
				<img
					className="order-img"
					alt={productName}
					src={img}
					style={{margin: 0}}
				/>
				<Button className='button-link-without-style'
								onClick={() => AppStore.openModal(<ViewProductModal
									pid={data.product_id} mid={data.mid}/>)}>
					{`ID: ${data.product_id} ${productName} ${warehouseName && `| ${warehouseName}`}`}
				</Button>
			</div>
			<div className='display-flex-row flex-wrap' style={{columnGap: 20}}>
				<span>
					<span className='silver'>{T('remote-identifier')}: </span>
					{data.remote_id}
				</span>
				<span>
					<span className='silver'>{T('transferred-quantity')}: </span>
					{data.stock} {T('items-e')}
				</span>
			</div>
			<div>
				{findErrorByPid && <div>{T('status-error')}: <span className='has-text-danger'>{findErrorByPid.error}</span></div>}
			</div>
		</div>
	);
};

export default StockMarketplaceProductRow;