import React, { useEffect, useState } from "react";
import T from "components/i18n";
import AddClientModal from "modules/clients/modal/add-client-modal/add-client-modal";
import AddDeliveryLink from "modules/clients/delivery";
import Button from "react-bulma-components/lib/components/button";
import { connectToStores } from "tools/reflux-tools";
import { filterClient } from "tools/filters-helper";
import ClientsStore from "modules/clients/clients-store";
import ClientsActions from "modules/clients/clients-actions";
import {
  Control,
  Field,
  Input,
  Label,
} from "react-bulma-components/lib/components/form";
import SelectComponent from "tools/select-component";
import SalesStore from "../sales-store";
import Actions from "../sales-actions";
import User from "user/user-store";
import UserStore from "user/user-store";
import Box from "react-bulma-components/lib/components/box";
import Icon from "react-bulma-components/lib/components/icon";
import Columns from "react-bulma-components/lib/components/columns";
import SubmitButton from "components/submit-button";
import OpenInsideModal from "components/modal/open-inside-modal";
import DimensionsBlock from "./dimensions-block";
import RadioButtonListControl from "../../../components/radio-buttons-list/radio-button-list-control";
import errorMessageControlHook from "../../../components/error-message-block/error-message-control.hook";
import { errorHandler } from "../../../tools/error-handler";
import AppStore from "../../../app-store";
import CreateIsOk from "./create-is-ok";
import {
  useIsMobile,
} from "../../../components/is-mobile-context/is-mobile-context";


const getSize = (products) => {
  let sizes = {};

  for (let i in products) {
    const prod = products[i],
      size = prod["size"]?.split("x"),
      sum = size?.reduce((partialSum, a) => partialSum + parseFloat(a), 0);

    sizes[sum] = size;
  }

  let max = Math.max(Object.keys(sizes));

  return sizes[max] || ["", "", "", ""];
};

const buildDesc = (products) => {
  let name = "";

  for (let i in products) {
    const prod = products[i];
    name += prod["name"] ? prod["name"] + ".": '';
  }

  return name?.slice(0, 49);
};

const senderList = [
  {
    title: "sender",
    value: "Sender",
  },
  {
    title: "recipient",
    value: "Recipient",
  },
];

const getMoneyList = (disabledGrade) => {
  return [
    {
      title: "grade",
      value: "1",
      disabled: disabledGrade,
    },
    {
      title: "remittance",
      value: "2",
    },
    {
      title: "payment-control",
      value: "3",
    },
  ];
}

const radioTypeList = [
  {
    title: 'parcels-and-cargoes',
    value: 'packages'
  },
  {
    title: 'documents',
    value: 'Documents'
  }
]


const CreateNovaposhtaModal = ({
  client,
  postoffice,
  amount,
  money,
  product,
  onCompleteCreate,
  onClose,
  checkLocalStorage,
}) => {
  const {isMobileSize} = useIsMobile()
  const sizeClass = isMobileSize ? "small" : null;

  const {
    handleAutoRemoveError,
    setErrorMessage,
    errorMessage,
    ErrorMessageBlock,
  } = errorMessageControlHook();
  const [waiting, setWaiting] = useState(false);
  const [createIsOk, setCreateIsOk] = useState("");
  const [cargoType, setCargoType] = useState('packages')
  const [clientInfo, setClientInfo] = useState({
    sender: null,
    client: client || { value: 0, label: T("enter-client-phone") },
    client_data: null,
    postoffice: postoffice || {
      value: 0,
      label: T("select-the-delivery-address"),
    },
    paid: "Recipient",
    money: checkLocalStorage ? window.localStorage.getItem('money-np') || money || "3" : money || "1",
    desc: buildDesc(product),
    amount: amount,
  });

  const size = getSize(product);

  const [dimensionsValues, setDimensionsValues] = useState([
    {
      height: size[0],
      length: size[1],
      width: size[2],
      weight: size[3],
    },
  ]);

  const handleChangeClientInfoSelect = (selectedOption, actions) => {
    setClientInfo((prev) => ({
      ...prev,
      [actions.name]: selectedOption,
    }));
  };

  const handleChangeClient = (selectedOption) => {
    const clientData = ClientsStore.getClientById(selectedOption.value);

    let postOffice = { value: 0, label: T("select-the-delivery-address") };

    if (selectedOption?.value === clientInfo?.client?.value) {
      postOffice = postoffice;
    }

    setClientInfo((prev) => ({
      ...prev,
      client: selectedOption,
      postoffice: postOffice,
      client_data: clientData,
    }));
  };

  const handleChangeRadio = ({ name, value }, saveLocal) => {

    if(saveLocal && checkLocalStorage) {
      window.localStorage.setItem(`${name}-np`, value)
    }
    setClientInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleChangeInputValue = ({ target: { value, name } }) => {
    setClientInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddCountPlace = () => {
    setDimensionsValues((prev) => [
      ...prev,
      {
        height: "",
        length: "",
        width: "",
        weight: "",
      },
    ]);
  };

  const handleCopyCountPlace = (values) => {
    setDimensionsValues((prev) => [...prev, values]);
  };

  const handleDeleteCountPlace = (index) => {
    setDimensionsValues((prev) => {
      const updatedValues = [...prev];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
  };

  const handleDeletePacking = () => {
    setDimensionsValues(prev => {
      return prev.map(item => {
        delete item?.pack
        return item
      })
    })
  }

  const handleEditDimensionsValues = (evt, index) => {
    const { name, value } = evt.target;
    setDimensionsValues((prev) => {
      const updatedValues = [...prev];
      updatedValues[index] = { ...updatedValues[index], [name]: value };
      return updatedValues;
    });
  };

  useEffect(() => {
    if (client) {
      handleChangeClient(client, { name: "client" });
    }

    const listenAdd = Actions.createTrackingNumber.completed.listen((res) => {
      setWaiting(false);
      if (res["success"]) {
        setCreateIsOk(res);
      } else {
        handleAutoRemoveError(res.error);
      }
    });

    const listenAddError = Actions.createTrackingNumber.failed.listen((res) => {
      const message = errorHandler(res);
      setWaiting(false);
      handleAutoRemoveError(message);
    });

    return () => {
      listenAddError();
      listenAdd();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);


  const isError = () => {
    let message = "";
    if (
      !clientInfo?.client_data?.first_name ||
      clientInfo?.client_data?.first_name === "" ||
      !clientInfo?.client_data?.last_name ||
      clientInfo?.client_data?.last_name === ""
    ) {
      message = "client-first-last-name-error";
    }
    if (!clientInfo?.postoffice || !clientInfo?.postoffice?.value) {
      message = "post-office-error";
    }
    const nullValues = dimensionsValues.filter((item) =>
      Object.keys(item).some((dimen) => !item[dimen])
    );

    if (dimensionsValues.length > 1 && !!nullValues.length && cargoType !== 'Documents') {
      message = "dimensions-error";
    }

    dimensionsValues.forEach(item => {
      if(item.hasOwnProperty("pack")) {
        if(!item.pack) {
          message = "choose-package";
        }
      }
    })

    if (message) {
      handleAutoRemoveError(message);
    }

    return message;
  };

  const handleSubmit = () => {
    if (!isError()) {
      const request = {
        paid: clientInfo.paid,
        money: clientInfo.money,
        amount: parseFloat(`${clientInfo.amount}`) ? clientInfo.amount : 10,
        count_place: dimensionsValues.length,
        postoffice: clientInfo?.postoffice?.value,
        sender: clientInfo.sender.value,
        desc: clientInfo.desc || " ",
        height: dimensionsValues[0].height || 10,
        length: dimensionsValues[0].length || 10,
        width: dimensionsValues[0].width || 10,
        weight: dimensionsValues[0].weight || 1,
        option_seats: dimensionsValues,
      };

      if(cargoType === 'Documents') {
        request.cargo_type = 'Documents';
        request.option_seats = dimensionsValues.map(item => ({
            height: 10,
            length: 10,
            width: 10,
            weight: item.weight || 1,
        }))
      }

      if (dimensionsValues.length === 1) {
        const dataOptionSeats =           {
            height: dimensionsValues[0].height || 10,
            length: dimensionsValues[0].length || 10,
            width: dimensionsValues[0].width || 10,
            weight: dimensionsValues[0].weight || 1,
          }
          if(dimensionsValues[0].pack) {
            dataOptionSeats.pack = dimensionsValues[0].pack
          }
        request.option_seats = [dataOptionSeats];
      }
      setWaiting(true);
      Actions.createTrackingNumber(request);
    }
  };

  const handleAddPostOffice = () => {
    AppStore.openModal(
      <AddDeliveryLink
        modal="add-delivery"
        date={{
          delivery: 1,
          phone: clientInfo.client_data["phone"],
          client_id: clientInfo.client_data["id"],
          name: clientInfo.client_data["name"],
          inner: true,
        }}
      />
    );
  };

  const handleChangeType = ({value}) => {
    setCargoType(value)
    setDimensionsValues([{
      height: size[0],
      length: size[1],
      width: size[2],
      weight: size[3],
    }])
  }

  const moneyList = getMoneyList(checkLocalStorage)


  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      {createIsOk && (
        <CreateIsOk
          data={createIsOk}
          onClose={onClose}
          onCompleted={onCompleteCreate}
        />
      )}
      {!createIsOk && (
        <>
          <Box>
            <SelectComponent
              name="sender"
              label="sender"
              onChange={handleChangeClientInfoSelect}
              value={clientInfo.sender}
              list={() => UserStore.getSenderInfoList(1)}
              creatable={false}
            />

            <Columns>
              <Columns.Column>
                <Field kind="group">
                  <Control fullwidth>
                    <SelectComponent
                      name="client"
                      label="client"
                      onChange={handleChangeClient}
                      value={clientInfo.client}
                      filterOption={filterClient}
                      list={ClientsStore.getClientsList}
                      load={ClientsActions.load}
                      addItem={({name}) => AppStore.openModal(<AddClientModal phone={name}/>)}
                      addItemModal={() => AppStore.openModal(<AddClientModal/>)}
                      empty={"enter-client-phone"}
                      creatable={true}
                    />
                  </Control>
                  {clientInfo.client && clientInfo.client.value !== 0 && (
                    <Control className="margin-top-10">
                      <Label>&nbsp;</Label>
                      <OpenInsideModal renderAs="icon" size="1x" icon="edit">
                        <AddClientModal
                          edit={true}
                          data={clientInfo.client_data}
                          client_id={clientInfo.client.value}
                        />
                      </OpenInsideModal>
                    </Control>
                  )}
                </Field>
              </Columns.Column>
              <Columns.Column>
                <SelectComponent
                  name="postoffice"
                  label="post-office-number"
                  readOnly={!clientInfo.client || clientInfo.client.value === 0}
                  onChange={handleChangeClientInfoSelect}
                  value={clientInfo.postoffice}
                  list={() => {
                    return ClientsStore.getClientDeliveryListSelect(
                      clientInfo.client ? clientInfo.client.value : false,
                      UserStore.getLang(),
                      UserStore.NP_ID
                    );
                  }}
                  load={ClientsActions.load}
                  addItem={handleAddPostOffice}
                  addItemModal={handleAddPostOffice}
                  empty="select-the-delivery-address"
                  creatable={true}
                />
              </Columns.Column>
            </Columns>
            <div align="right" className="margin-bottom-10 display-flex-row-gap">
              <RadioButtonListControl list={radioTypeList} name='cargo_type' onChange={handleChangeType}  valueRadio={cargoType} />
              {cargoType !== 'Documents' && <Button
                size="small"
                rounded
                color="info"
                onClick={handleAddCountPlace}
              >
                {T('add-seat')}
              </Button>}
            </div>
            {dimensionsValues.map((item, index) => (
              <DimensionsBlock
                type={cargoType}
                key={`dimensions-${index}`}
                index={index}
                values={item}
                onChangeInput={handleEditDimensionsValues}
                onDelete={handleDeleteCountPlace}
                onCopy={handleCopyCountPlace}
                onError={handleAutoRemoveError}
                onDeletePack={handleDeletePacking}
              />
            ))}
            <Columns className="is-mobile">
              <Columns.Column>
                <RadioButtonListControl
                  valueRadio={clientInfo.paid}
                  label="why-paid"
                  name="paid"
                  list={senderList}
                  onChange={handleChangeRadio}
                />
              </Columns.Column>
              <Columns.Column>
                <RadioButtonListControl
                  valueRadio={clientInfo.money}
                  name="money"
                  list={moneyList}
                  onChange={(evt) => handleChangeRadio(evt, true)}
                />
                <Control iconRight className="margin-top-10">
                  <Input
                    size={sizeClass}
                    name="amount"
                    placeholder={T("amount")}
                    type="number"
                    min="0"
                    onChange={handleChangeInputValue}
                    value={clientInfo.amount}
                  />
                  <Icon align="right">
                    <span>{User.getCurrency()}</span>
                  </Icon>
                </Control>
              </Columns.Column>
            </Columns>
            <Columns className="is-mobile">
              <Columns.Column>
                <Control iconRight className="margin-top-10">
                  <Input
                    size={sizeClass}
                    name="desc"
                    placeholder={T("desc-parcel")}
                    type="text"
                    maxLength={50}
                    onChange={handleChangeInputValue}
                    value={clientInfo.desc}
                  />
                </Control>
              </Columns.Column>
            </Columns>

            <Button.Group position="centered">
              <Button
                size={isMobileSize ? "small" : "medium"}
                rounded
                color="light"
                onClick={onClose}
              >
                {T("close")}
              </Button>
              <SubmitButton
                size={isMobileSize ? "small" : "medium"}
                text="create-novaposhta-ttn"
                fullwidth={false}
                waiting={waiting}
                submit={handleSubmit}
              />
            </Button.Group>
          </Box>
        </>
      )}
    </>
  );
};

export default connectToStores(CreateNovaposhtaModal, {
  sales: SalesStore,
  clients: ClientsStore,
});