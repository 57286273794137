import React, { Component } from "react";
import Button from 'react-bulma-components/lib/components/button';
import { Link } from "react-router-dom";
import T from 'components/i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {withIsMobileSize} from "../../is-mobile-context/is-mobile-context";

class OpenModalButton extends Component {

    render() {
        const props = this.props,
              CustomComponent = ({
                  link,
                  disabled,
                  children,
                  className,
              }) => (
                  <Link className={className} to={link} disabled={disabled}>{children}</Link>
              );

        let size = props.size || (this.props.isMobileSize ? "small" : "large"),
            className = (props.renderAs === "a" ? "button_link" : "");

        if (props.className) {
          className += " " +  props.className;
        }

        return (
          <Button
            size={size} rounded color={props.color || "info"}
            renderAs={CustomComponent}
            link={props.link}
            disabled={props.disabled || false}
            className={className}
          >
          {(props.icon) ?  <><FontAwesomeIcon icon={props.icon} size="1x" />&nbsp;</> : ""}{T(props.text)}
          </Button>

        );
    }
}

export default withIsMobileSize(OpenModalButton);