import React, { Component } from 'react';

import Content from 'react-bulma-components/lib/components/content';
import Section from 'react-bulma-components/lib/components/section';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import FooterBlock from '../common/footer'
import { backLink } from 'tools/back-link'

class PolicyRU extends Component {
    render () {
        return (
        	<Section>
        	{backLink()}
        	<Box>
        	<Content>
				<Heading> Политика конфиденциальности и защиты персональных данных </Heading>
<Heading subtitle renderAs = "h2"> Раздел I. Общие положения </Heading>

<p> 1. Web-сайт https://h-profit.com (далее - Сайт) принадлежит на праве собственности LLC HugeProfit, код ЕДРПОУ 44330291 (далее - Компания). Все объекты права интеллектуальной собственности, которые содержатся на сайте, кроме случаев, когда об этом отдельно указано, принадлежащих на праве собственности Компании.

</p> <p> 2. Данная Политика конфиденциальности и защиты персональных данных (далее - Политика конфиденциальности) действует исключительно в рамках Правил пользования Сайтом и не распространяется на web - сайты третьих лиц.

</p> <p> 3. Данная Политика политикой конфиденциальности представляет собой комплекс правовых, административных, организационных, технических и других мероприятий, которые принимает Компания с целью обеспечения конфиденциальности и защиты персональных данных физических лиц - потребителей, которые являются пользователями сайта, кроме случаев, когда информация лично и сознательно разглашается потребителем - пользователем сайта.

</p> <p> 4. Целью внедрения Политики конфиденциальности и защиты персональных данных является недопущение публичного доступа и разглашения информации, владельцем которой является потребитель.

</p> <p> 5. Компания принимает все возможные меры с целью обеспечения выполнения мероприятий, предусмотренных настоящим разделом Политики конфиденциальности.
</p>
<Heading subtitle renderAs = "h2"> Раздел II. Доступ к персональным данным </Heading>

<p> 1. С целью реализации функций и задач сайта и других целей, связанных с гражданско-правовым отношениями между Компанией и потребителем, потребитель, пользуясь данным сайтом и услугами, для оказания которых создан Сайт предоставляет Компании разрешение на сбор, накопление, обработку, хранение и использования (в том числе с использованием автоматизированных средств) персональных данных, владельцем которых он является.

</p> <p> 1.1. С целью реализации функций и задач сайта и других целей, связанных с гражданско-правовым отношениями между Компанией и потребителем, потребитель, пользуясь данным сайтом и услугами, для оказания которых создан Сайт предоставляет Компании разрешение на передачу собственных персональных данных третьим лицам.

</p> <p> 2. Согласие потребителя, которая предоставляется Компании на сбор, обработку, хранение и использование (в том числе с использованием автоматизированных средств) персональных данных и их передачу третьим лицам оформляется путем проставления потребителем отметки о предоставлении разрешения на сбор, накопление, обработку, хранение и использование своих персональных данных.

</p> <p> 3. К персональным данным, на сбор, накопление, обработку, хранение и использование которых (в том числе с использованием автоматизированных средств) потребитель предоставляет разрешение Компании входит:

</p>
<p> а) фамилия, имя, отчество; </p>

<p> б) номера средств связи (телефон, e-mail); </p>

<p> в) сфера деятельности; </p>

<p> г) Другая информация, которая самостоятельно вносится потребителем. </p>

<Heading subtitle renderAs = "h2"> ИИИ. Срок и место хранения персональных данных </Heading>

<p> 1. Персональные данные, на сбор, накопление, обработку, хранение и использование которых потребитель предоставляет доступ Компании хранятся бессрочно, если иное не установлено законодательством Украины или волеизъявлением потребителя.
</p>
<Heading subtitle renderAs = "h2"> IV. Права потребителя </Heading>

<p> 1. Потребитель, относительно собственных персональных данных, предоставляемых для сбора, накопления, обработку, хранение и использование Компании имеет право:
</p>
<p> 1.1. Знать об источниках сбора, местонахождение своих персональных данных, цели их обработки, местонахождение компании;
</p>
<p> 1.2. Получать информацию об условиях предоставления доступа к персональным данным; </p>

<p> 1.3. На доступ к своим персональным данным; </p>

<p> 1.4. Получать информацию относительно того, или обрабатываются его персональные данные; </p>

<p> 1.5. предъявлять мотивированное требование Компании с возражением против обработки своих персональных данных; </p>

<p> 1.6. предъявлять мотивированное требование об изменении или уничтожение своих персональных данных, если эти данные обрабатываются незаконно или являются недостоверными; </p>

<p> 1.7. на защиту своих персональных данных от незаконной обработки и случайной потери, уничтожения, повреждения в связи с умышленным сокрытием, непредоставлением или несвоевременным их предоставлением, а также на защиту от предоставления сведений, которые являются недостоверными или порочащих честь, достоинство и деловую репутацию физического лица ; </p>

<p> 1.8. обращаться с жалобами на обработку своих персональных данных к Уполномоченному Верховной Рады Украины по правам человека или в суд; </p>

<p> 1.9. применять средства правовой защиты в случае нарушения законодательства о защите персональных данных; </p>

<p> 1.10. вносить оговорки об ограничении права на обработку своих персональных данных при предоставлении согласия; </p>

<p> 1.11. отозвать согласие на обработку персональных данных; </p>

<p> 1.12. знать мехаизм автоматической обработки персональных данных; </p>

<p> 1.13. на защиту от автоматизированного решения, которое имеет для него правовые последствия. </p>

<Heading subtitle renderAs = "h2"> V. Раскрытие конфиденциальной информации и персональных данных </Heading>

<p> 1. Компания освобождается от обязанности соблюдения Политики конфиденциальности в отношении персональных данных потребителя в случае, если потребитель самостоятельно публично разглашает персональные данные. </p>

<p> 2. Компания вправе распространять персональные данные потребителя с соответствующего письменного разрешения потребителя. </p>

<Heading subtitle renderAs = "h2"> VI. Уничтожение и удаление персональных данных </Heading>

<p> 1. Персональные данные, на сбор, накопление, обработку, хранение и использование которых потребитель предоставляет доступ Компании подлежат удалению или уничтожению в случае: </p>

<p> - прекращение правоотношений между потребителем и Компанией; </p>

<p> - издание соответствующего предписания Уполномоченного Верховной Рады по правам человека или определенных им должностных лиц секретариата Уполномоченного Верховной Рады по правам человека; </p>

<p> - вступления в законную силу решения суда об удалении или уничтожение персональных данных. </p>

			</Content>
			</Box>
			<FooterBlock />
			</Section>

			)}

}

export default PolicyRU;
