import {useEffect, useState} from 'react'
import T from '../i18n'
import ErrorMessageBlock from "./error-message-block";

const ErrorMessageControlHook = (delay) => {
	const [errorMessage, setErrorMessage] = useState(null)
	const [timeoutId, setTimeoutId] = useState(null);

	const handleAutoRemoveError = (message) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		const newTimeoutId = setTimeout(() => setErrorMessage(null), delay || 4000);
		setTimeoutId(newTimeoutId);

		setErrorMessage(T(message));
	};

	useEffect(() => {
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [timeoutId]);

	return {errorMessage, setErrorMessage, handleAutoRemoveError, ErrorMessageBlock}
};

export default ErrorMessageControlHook;