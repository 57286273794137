import React from "react";
import OpenModalButton from "../../../components/modal/open-modal-button";
import T from "../../../components/i18n";
import ExportDocumentDropdown from "../common/export-document-dropdown";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const ActionsButton = () => {
  const {isMobileSize} = useIsMobile()
  return (
    <div
      className="display-flex-row flex-wrap margin-bottom-10 top-button"
      style={{ justifyContent: "flex-end" }}
    >
      <OpenModalButton
        link="/shipments/add-shipment-base"
        text={T("create-product-delivery")}
        icon="plus-circle"
        size={isMobileSize ? 'small' : 'medium'}
      />
      <ExportDocumentDropdown />
    </div>
  );
};

export default ActionsButton;
