import React, {useEffect, useState} from "react";
import { Box, Button, Heading } from "react-bulma-components";
import T from "../../../../../../components/i18n";
import RadioButtonListControl from "../../../../../../components/radio-buttons-list/radio-button-list-control";
import MinBalanceContent from "./min-balance-content";
import ProductsStore from "../../../../../products/products-store";
import WarehouseStore from "../../../../../warehouse/warehouse-store";
import UserStore from "../../../../../../user/user-store";
import SubmitButton from "../../../../../../components/submit-button";
import errorMessageControlHook
  from "../../../../../../components/error-message-block/error-message-control.hook";
import UserActions from "../../../../../../user/user-actions";
import {errorHandler} from "../../../../../../tools/error-handler";

const radioList = [
  {
    title: "general",
    value: "general",
  },
  {
    title: "product-category",
    value: "category",
  },
  {
    title: "history-data-marketplace_id",
    value: "warehouse",
  },
  {
    title: "product-brand",
    value: "brand",
  },
];

const AddMinBalance = ({ defaultType, defaultMinStock, defaultSelectValue,index, ignoreValuesList, onClose }) => {
  const productCategoryList = ProductsStore.getFormattingCategoryList();
  const brandList = ProductsStore.getBrandsList();
  const warehouseList = WarehouseStore.getWarehouseList();

  const hideCategory = ignoreValuesList?.category?.length >= productCategoryList.length || ignoreValuesList?.category[0] === 0
  const hideBrandList = ignoreValuesList?.brand?.length >= brandList.length || ignoreValuesList?.brand[0] === 0
  const hideWarehouseList = ignoreValuesList?.warehouse?.length >= warehouseList.length || ignoreValuesList?.warehouse[0] === 0

  const filteredRadioList = radioList.filter(item => {
    if(hideCategory && item.value === 'category') {
      return false;
    }
    if(hideBrandList && item.value === 'brand') {
      return false;
    }
    if(hideWarehouseList && item.value === 'warehouse') {
      return false;
    }
    if(ignoreValuesList?.general && item.value === 'general') {
      return false;
    }

    return true;
  });



  const [type, setType] = useState(defaultType || filteredRadioList[0]?.value);
  const [selectValue, setSelectValue] = useState(defaultSelectValue, false);
  const [minStock, setMinStock] = useState(defaultMinStock ||"");
  const [waiting, setWaiting] = useState(false)
  const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = errorMessageControlHook()

  const handleChangeRadio = ({ value }) => {
    setType(value);
    setSelectValue(false);
    setMinStock("");
  };

  const handleChangeSelect = (selectedValue) => {
    if (!selectedValue) {
      setSelectValue(false);
    } else if (selectedValue[selectedValue.length - 1].value === 0) {
      setSelectValue([{ value: 0, label: T("all") }]);
    } else {
      setSelectValue(selectedValue.filter((item) => item?.value !== 0));
    }
  };

  const handleChangeInput = ({ target: { value } }) => {
    setMinStock(value);
  };

  const handleSubmit = () => {
      if(selectValue && parseFloat(minStock)) {
        UserStore.setMinStockProduct({type, minStock: parseFloat(minStock), ids: selectValue.map(item => item.value), index});
        setWaiting(true)
      } else if(type === "general" && parseFloat(minStock)) {
        UserStore.setMinStockProduct({type, minStock: parseFloat(minStock), index});
        setWaiting(true)
      } else {
        handleAutoRemoveError('not all fields required')
      }
  }

  useEffect(() => {
    const updateCompleted = UserActions.updateSettings.completed.listen(() => {
      onClose()
    })
    const updateFailed = UserActions.updateSettings.failed.listen((res) => {
      const message = errorHandler(res)
      setWaiting(false)
      handleAutoRemoveError(message)
    })

    return ()=>{
      updateFailed()
      updateCompleted()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!filteredRadioList.length) {
    return <Box>
      <Heading>{T('configured-all-instocks')}</Heading>
      <div align='left'>
        <Button onClick={onClose} fullwidth size='large' rounded color='success'>
          {T('close')}
        </Button>
      </div>
    </Box>
  }

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading>{T("add-min-balance-products")}</Heading>
        <RadioButtonListControl
          name="type"
          value={type}
          list={filteredRadioList}
          valueRadio={type}
          disabledRadio={!!defaultType}
          onChange={handleChangeRadio}
        />
        {type === "general" && (
          <MinBalanceContent
            name="general"
            inputValue={minStock}
            onChangeInput={handleChangeInput}
          />
        )}
        {type === "category" && (
          <MinBalanceContent
            name="category"
            placeholderSelect="select-category"
            listSelect={productCategoryList}
            inputValue={minStock}
            onChangeSelect={handleChangeSelect}
            selectValue={selectValue}
            onChangeInput={handleChangeInput}
            ignoreList={ignoreValuesList?.category}
          />
        )}
        {type === "brand" && (
          <MinBalanceContent
            name="brand"
            placeholderSelect="select-brand"
            listSelect={brandList}
            inputValue={minStock}
            onChangeSelect={handleChangeSelect}
            selectValue={selectValue}
            onChangeInput={handleChangeInput}
            ignoreList={ignoreValuesList?.brand}
          />
        )}
        {type === "warehouse" && (
          <MinBalanceContent
            name="warehouse"
            placeholderSelect="select-warehouse"
            listSelect={warehouseList}
            inputValue={minStock}
            onChangeSelect={handleChangeSelect}
            selectValue={selectValue}
            onChangeInput={handleChangeInput}
            ignoreList={ignoreValuesList?.warehouse}
          />
        )}
        <div className="margin-top-20 display-flex-row" style={{ justifyContent: "flex-end" }}>
          <SubmitButton fullwidth={false} text='save-btn-label' submit={handleSubmit} waiting={waiting} size="medium" />
          <Button onClick={onClose} size="medium" rounded>{T("close")}</Button>
        </div>
      </Box>
    </>
  );
};

export default AddMinBalance;
