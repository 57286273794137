
import React from "react";
import UserStore from 'user/user-store';
import List from 'react-bulma-components/lib/components/list';
import Columns from 'react-bulma-components/lib/components/columns';
import {formattedDate} from "../../../tools/formatted-date-dd-mm-yy";

const PartnerRow = (props) => {
  const { partner_id, email, balance, payout, created_at } = props.data;
  
  const hideEmail = (email) => {
    const parts = email?.split('@');
    if (parts?.length === 2) {
      const username = parts[0];
      const domain = parts[1];
      let visibleChars = 2;
      if (username.length <= 2) {
        visibleChars = 1;
      }
      const hiddenCharacters = '*'.repeat(username.length - visibleChars); const visibleUsername = username.slice(0, visibleChars);
      
      return visibleUsername + hiddenCharacters + '@' + domain;
  }
  return email;
  }
  const hiddenEmail = hideEmail(email);
  const partnerEmail = hiddenEmail || "Email is not available";

  return (
    <List.Item>
      <Columns>
        <Columns.Column>{partner_id}</Columns.Column>
        <Columns.Column>{partnerEmail}</Columns.Column>
        <Columns.Column>{formattedDate(created_at)}</Columns.Column>
        <Columns.Column>{balance} {UserStore.getCurrency()}</Columns.Column>
        <Columns.Column>{payout} {UserStore.getCurrency()}</Columns.Column>
      </Columns>
    </List.Item>)
}
  
export default PartnerRow;
  