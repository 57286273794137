import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import InfoBox from "./Info-box";
import s from './styles.module.scss'

const getScrollParents = (element) => {
  const scrollParents = [];
  let parent = element.parentElement;

  while (parent) {
    const isScrollable = parent.scrollHeight > parent.clientHeight || parent.scrollWidth > parent.clientWidth;

    if (isScrollable) {
      scrollParents.push(parent);
    }

    parent = parent.parentElement;
  }

  return scrollParents;
};


const InfoWrapper = (props) => {
  const { info, children, defaultPosition } = props;
  const [isOpen, setIsOpen] = useState(false);
	const [rectIcon, setRectIcon] = useState({})

  const wrapperRef = useRef(null);
  const iconRef = useRef(null);
  const scrollListeners = useRef([]);

  const handleToggleOnClick = (e) => {
    e.stopPropagation();
    setTimeout(() => setIsOpen((prevState) => !prevState), 200);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleResizeOrScroll = () => {
    const icon = iconRef?.current;
    if (icon) {
      const rectIcon = icon.getBoundingClientRect();
      setRectIcon(rectIcon)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false);
      handleResizeOrScroll()
    };

    const wrapper = wrapperRef.current;
    if (wrapper) {
      const scrollParents = getScrollParents(wrapper);

      scrollParents.forEach((parent) => {
        parent.addEventListener("scroll", handleScroll);
        scrollListeners.current.push(parent);
      });
    }

    return () => {
      scrollListeners.current.forEach((parent) => {
        parent.removeEventListener("scroll", handleScroll);
      });
      scrollListeners.current = [];
    };
  }, []);

  useEffect(() => {
    handleResizeOrScroll()

    const wrapper = wrapperRef.current;

    if (wrapper) {
      getScrollParents(wrapper);
    }

    window.addEventListener("resize", handleResizeOrScroll);
    window.addEventListener("scroll", handleResizeOrScroll);
    return () => {
      window.removeEventListener("resize", handleResizeOrScroll);
      window.removeEventListener("scroll", handleResizeOrScroll);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="radio-with-info-wrapper" ref={wrapperRef}>
      <span>
      {children}
      </span>
      <span
        className={s.info_icon}
        onClick={handleToggleOnClick}
				ref={iconRef}
        style={defaultPosition ? { right: defaultPosition + "px" } : null}
      >
        <FontAwesomeIcon icon="question-circle" size="1x" />
      </span>
			{isOpen && (
				<InfoBox
					rectIcon={rectIcon}
					info={info}
				/>)
			}
    </div>
  );
};

export default InfoWrapper;
