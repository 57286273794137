import React, {useEffect, useState} from "react";
import InfoBlock from "../../../components/info-block";
import {Box} from "react-bulma-components";
import {
  Checkbox, Textarea
} from "react-bulma-components/lib/components/form";
import s from "./styles.module.scss";
import SubmitButton from "../../../components/submit-button";
import VotingActions from "../voting-actions";
import T from '../../../components/i18n'
import ErrorMessageControlHook
  from "../../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../../tools/error-handler";

const VoteList = ({ data, voteResult }) => {
  const defaultVote = voteResult?.option_ids?.reduce((acc, cur) => {
    acc[cur] = {value: true, name: cur}
    return acc
  }, {}) || {}

  const [voteValue, setVoteValue] = useState(defaultVote);
  const [isCustom, setIsCustom] = useState(!!voteResult.custom || false)
  const [customValue, setCustomValue] = useState(voteResult.custom || '')
  const [waiting, setWaiting] = useState(false)
  const [hideButton, setHideButton] = useState(false)
  const {handleAutoRemoveError,errorMessage,setErrorMessage,ErrorMessageBlock} = ErrorMessageControlHook()

  const handleChangeVote = ({target: {name, checked}}) => {
    setVoteValue(prev => ({ ...prev, [name]: {value: checked, name} }) );
  };

  const handleSubmit = () => {
    const list = Object.entries(voteValue).reduce((acc, [key, value]) => {
      if(value.value) {
        acc.push(key)
      }
      return acc
    },[])

    const dataSend = {vote_id: data.id, option_ids: list, custom: ''}

    if(isCustom) {
      dataSend.custom = customValue
    }

    if(!dataSend.custom && !dataSend.option_ids.length) {
      handleAutoRemoveError('voting-should-blank-slate')
    } else {
      setWaiting(true)
      VotingActions.voting(dataSend);
    }
  }

  const handleChangeIsCustom = ({target: {checked}}) => {
    setIsCustom(checked);
  }

  const handleChangeCustom = ({target: {value}}) => {
    setCustomValue(value);
  }

  useEffect(() => {
    const votingLoad = VotingActions.voting.completed.listen(() => {
      setWaiting(false)
      setHideButton(true)
    })

    const votingLoadFailed = VotingActions.voting.failed.listen((res) => {
      setWaiting(false)
      const message = errorHandler(res)
      handleAutoRemoveError(message)
    })

    return () => {
      votingLoad()
      votingLoadFailed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
      <InfoBlock title={data.name} />
      <div className="display-flex-column" align="left" style={{ gap: 10 }}>
        {data.items.map((item) => (
            <Checkbox
              key={item.id}
              name={String(item.id)}
              className={`${s.radio} ${voteValue[item.id]?.value ? s.is_vote : ''}`}
              style={{margin: 0, display: 'flex', gap: 5, alignItems: 'center'}}
              checked={voteValue[item.id]?.value}
              onChange={handleChangeVote}
            >
              {item.name}
            </Checkbox>
        ))}
        <Checkbox
          name='custom'
          className={`${s.radio} ${isCustom ? s.is_vote : ''}`}
          style={{margin: 0, display: 'flex', gap: 5, alignItems: 'center'}}
          checked={isCustom}
          onChange={handleChangeIsCustom}
        >
          {isCustom ? <Textarea style={{minWidth: 'fit-content'}} value={customValue} onChange={handleChangeCustom}/> : T('my-own-version')}
        </Checkbox>
      </div>
      {!hideButton && <div className='margin-top-20'>
        <SubmitButton
          text={!!Object.keys(defaultVote || {})?.length ? 'btn-edit' : 'send-btn-label'}
          submit={handleSubmit} size='large' waiting={waiting}/>
      </div>}
    </Box>
  );
};

export default VoteList;
