import React from "react";
import { Button, Columns, Heading, List } from "react-bulma-components";
import { Label } from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppStore from "../../../../app-store";
import AddAccrualSales from "./add-accrual-sales";
import { getActiveIdsItem } from "./utils";
import { confirmDelete } from "../../../../tools/confirm-delete";
import RadioButtonsListControl from "../../../../components/radio-buttons-list/radio-button-list-control";
import Money from "../../../../components/money";

const typeList = [
  {
    title: "turnover",
    value: "turnover",
  },
  {
    title: "profits",
    value: "profits",
  },
];

const AccrualItem = ({ data, onDelete, onEdit }) => {
  return (
    <List.Item>
      <Columns className='is-mobile'>
        <Columns.Column size={8} style={{ flex: 1 }}>
          <div>
            {data.data.map((item) => (
              <div key={item.value}>{item.label}</div>
            ))}
          </div>
        </Columns.Column>
        <Columns.Column
          size={2}
          className="display-flex-center"
          style={{ height: "auto" }}
        >
          <span className="bold nowrap">
            {data.type === "fix" ? (
              <Money amount={data.value} />
            ) : (
              `${data.value}  %`
            )}
          </span>
        </Columns.Column>
        <Columns.Column
          size={2}
          style={{ width: "fit-content", height: "auto" }}
          className="display-flex-center"
        >
          <div className="display-flex-row">
            <Button onClick={onEdit} className="button-link-icon">
              <FontAwesomeIcon icon="edit" />
            </Button>
            <Button
              className="button-link-icon"
              onClick={() => confirmDelete(onDelete)}
            >
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </div>
        </Columns.Column>
      </Columns>
    </List.Item>
  );
};

const AccrualList = ({ data, onDelete, onEdit }) => {
  const handleEditAccrual = (index, name, item) => {
    const onSubmit = (valueName, value) => {
      if (valueName === "general") {
        onEdit("general", null, value);
      } else {
        onEdit(valueName, index, value);
      }
    };
    AppStore.openModal(
      <AddAccrualSales
        modal="accrual"
        disabledRadio
        defaultRadio={name}
        activeBrand={getActiveIdsItem(data.brand)}
        activeCategory={getActiveIdsItem(data.category)}
        activeItem={item}
        onSubmit={onSubmit}
      />
    );
  };

  const handleChangeType = ({ name, value }) => {
    onEdit(name, null, value);
  };

  return (
    (data.general || !!data.category.length || !!data.brand.length) && (
      <>
        <Heading>{T("accrual-list")}</Heading>
        {!!data.general && (
          <List>
            <List.Item>
              <Columns className='is-mobile'>
                <Columns.Column size={8} style={{ flex: 1 }}>
                  {T("general")}
                </Columns.Column>
                <Columns.Column
                  size={2}
                  className="display-flex-center"
                  style={{ height: "auto" }}
                >
                  <span className="bold nowrap">
                    {data.general_type === "fix" ? (
                      <Money amount={data.general} />
                    ) : (
                      `${data.general} %`
                    )}
                  </span>
                </Columns.Column>
                <Columns.Column
                  size={2}
                  style={{ width: "fit-content", height: "auto" }}
                  className="display-flex-center"
                >
                  <div className="display-flex-row">
                    <Button
                      onClick={() =>
                        handleEditAccrual(null, "general", {
                          value: data.general,
                          type: data.general_type,
                        })
                      }
                      className="button-link-icon"
                    >
                      <FontAwesomeIcon icon="edit" />
                    </Button>
                    <Button
                      className="button-link-icon"
                      onClick={() => confirmDelete(() => onDelete("general"))}
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </div>
                </Columns.Column>
              </Columns>
            </List.Item>
          </List>
        )}
        {!!data.category.length && (
          <>
            <Label>{T("product-category")}</Label>
            <List>
              {data.category.map((item, index) => (
                <AccrualItem
                  key={`category-${index}`}
                  data={item}
                  onEdit={() => handleEditAccrual(index, "category", item)}
                  onDelete={() => onDelete("category", index)}
                />
              ))}
            </List>
          </>
        )}
        {!!data.brand.length && (
          <>
            <Label>{T("product-brand")}</Label>
            <List>
              {data.brand.map((item, index) => (
                <AccrualItem
                  key={`brand-${index}`}
                  data={item}
                  onEdit={() => handleEditAccrual(index, "brand", item)}
                  onDelete={() => onDelete("brand", index)}
                />
              ))}
            </List>
          </>
        )}
        <RadioButtonsListControl
          name="accrual_type"
          list={typeList}
          label="calculation-accruals-from"
          valueRadio={data["accrual_type"] || "turnover"}
          onChange={handleChangeType}
        />
      </>
    )
  );
};

export default AccrualList;
