import React from 'react'
import s from './styles.module.scss'
import ClientPopupItem from "./client-popup-item";
import {connectToStores} from "../../tools/reflux-tools";
import ClientsStore from "../../modules/clients/clients-store";
import ChatsStore from "../../modules/chats/chats-store";
import {parsePhoneNumber} from "../../tools/parse-phone-number";

const ClientPopupList = () => {
	const listCall = ChatsStore.get('listCall')

	const listPhone = Object.values(listCall || {})

	return !!listPhone?.length && (
		<div className={s.wrapper}>
			{listPhone.map(item => <ClientPopupItem type={item.type || ''} key={item.callID} clientPhone={parsePhoneNumber(item.phone, true)} callID={item.callID} internalNumber={item?.internalNumber}/>)}

		</div>
	);
};

export default connectToStores(ClientPopupList, {_: ClientsStore, chat: ChatsStore});