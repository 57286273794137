import React, { Component } from 'react';
import Tile from 'react-bulma-components/lib/components/tile';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Container from 'react-bulma-components/lib/components/container';
import Columns from 'react-bulma-components/lib/components/columns';
import Section from 'react-bulma-components/lib/components/section';
import Hero from 'react-bulma-components/lib/components/hero';
import T from "components/i18n";
import { useEffect } from 'react';
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";

function FacebookSDK() {
  useEffect(() => {
    // Проверяем, загружен ли скрипт уже
    if (document.getElementById('facebook-sdk')) {
      return;
    }

    const script = document.createElement('script');
    script.src = "https://connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v18.0&appId=625806625009198&autoLogAppEvents=1";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.nonce = "BQCNAHzq";
    script.id = 'facebook-sdk';

    document.body.appendChild(script);
  }, []);

  return null;
}


class FacebookReviewBlock extends Component { 
  	render () {
		return (
			<Section>
                  <FacebookSDK />
                  <Hero>
                  <Hero.Body id="review">
                    <Container>
                    <Box className="padding-top-bottom">
                        <Heading size={3}>
                            {T('review-salesman')}
                        </Heading>
                        <Tile kind="ancestor" style={{flexWrap: 'wrap', justifyContent: 'center', gap: '10px'}}>
                        	<Tile renderAs="article" kind="parent" style={{flex: 0}}>
                                <Box className="review-block">
                                    <div className="fb-post" data-lazy={true} data-href="https://www.facebook.com/vkvetal/posts/pfbid02oh3mFUTrMQAoeFQPNtSzPujuTu79TuCHxHnMAGsTk1zBgxEW41iaY2L7w1mtkRnpl" data-show-text="true" data-width={this.props.isMobileSize ? "300" : "350"}><blockquote cite="https://www.facebook.com/irina.ponarovska/posts/4093688123981290" className="fb-xfbml-parse-ignore"><p>Добрый день. Хочу написать первое впечатление о пользовании вашим сервисом! Мы в восторге! Очень удобный интерфейс, весь...</p>Опубликовано <a href="https://www.facebook.com/irina.ponarovska">Ириной Понаровской</a>&nbsp;<a href="https://www.facebook.com/irina.ponarovska/posts/4093688123981290">Четверг, 8 октября 2020 г.</a></blockquote></div>
                                </Box>
                            </Tile>

                            <Tile renderAs="article" kind="parent" style={{flex: 0}}>
                                <Box className="review-block">
                                    <div className="fb-post" data-lazy={true} data-href="https://www.facebook.com/Tummma/posts/pfbid02tPT4A1FTdVcAvUHXejXkgVtWGbkj9UwYZ2yqEQWBu9fmGC7ZUYNqagiuyDtg3Hrel" data-width={this.props.isMobileSize ? "300" : "350"}  data-show-text="true"><blockquote cite="https://www.facebook.com/Tummma/posts/5346202748797001" className="fb-xfbml-parse-ignore"><p>Дуже крутий сервіс, переходжу з клаудшоп рашиського, HugeProfit це единий український сервіс, який закриває більшість...</p>Опубликовано <a href="https://www.facebook.com/Tummma">Туммой Урманн</a>&nbsp;<a href="https://www.facebook.com/Tummma/posts/5346202748797001">Пятница, 22 июля 2022 г.</a></blockquote></div>
                                </Box>
                            </Tile>
                            <Tile renderAs="article" kind="parent" style={{flex: 0}}>
                                <Box className="review-block">
                                    <div className="fb-post" data-lazy={true} data-href="https://www.facebook.com/darina.pywa/posts/4604202329705209" data-show-text="true" data-width={this.props.isMobileSize ? "300" : "350"}></div>
                                </Box>
                            </Tile>
                            
                        </Tile>
                        <Columns>
                            <Columns.Column>
                                <img alt="HugeProfit Reviews" src="https://h-profit.com/blog/wp-content/uploads/2022/05/100-85-1.jpg" border="0" width="100%" />
                            </Columns.Column>
                            <Columns.Column>
                                <Heading size={3} className="margin-top-20">
                                    <a rel="noopener noreferrer" href="https://h-profit.com/blog/dekilka-vidgukiv-nashih-kli%d1%94ntiv-dyaku%d1%94mo-shho-vi-z-nami/" target="_blank">{T('more-review')}</a>
                                </Heading>

                                <Heading size={3} className="margin-top-20">
                                    <a rel="noopener noreferrer" href="https://www.facebook.com/myhprofit/reviews" target="_blank">{T('more-review-facebook')}</a>
                                </Heading>
                            </Columns.Column>
                        </Columns>
                    </Box>
                  </Container>
                  </Hero.Body>
                </Hero>
                </Section>
		);
	}
}

export default withIsMobileSize(FacebookReviewBlock);