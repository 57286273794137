import Reflux from 'reflux';

const ExpensesActions = Reflux.createActions({

    load: { asyncResult: true },    
    filter: { sync: true },
    loadCategory: { asyncResult: true },
    loadAccounts: { asyncResult: true },
    addCategory: { asyncResult: true },
    addFundsCategory: { asyncResult: true },
    addExpenses: { asyncResult: true },
    deleteExpenses: { asyncResult: true },
    deleteAccount: { asyncResult: true },
    addPlanExpenses: { asyncResult: true },
    transferFunds: { asyncResult: true },
  
});


export default ExpensesActions;