import T from "../../../components/i18n";
import React from "react";

const getAddressLine = (data) => {
  return (
    (
      <>
        <b>{T("address")}:</b> {data?.city}, {data?.address_1 || ""}
      </>
    ) || null
  );
};

export const getAddress = (data) => {
  const addressParse = JSON.parse(data?.address_1 || "{}");

  return {
    addressInfo: addressParse,
    block: getAddressLine(addressParse),
  };
};
