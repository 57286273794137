import React from 'react'
import {useHistory} from "react-router-dom";
import {Button} from "react-bulma-components";
import T from '../../../components/i18n'

const OpenBindingPage = ({data}) => {
	const history = useHistory()

	const handleNavigate = () => {
		history.push(`/settings/integrations/mass-binding`, data)
	}

	return (

		<Button onClick={handleNavigate} color='success'>
			{T('mass-binding')}
		</Button>
	);
};

export default OpenBindingPage;
