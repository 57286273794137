import React from 'react'
import {Field, Label, Radio} from "react-bulma-components/lib/components/form";
import T from '../../../../components/i18n'

const TypeSelection = ({type, onChange}) => {
	return (
		<Field>
			<Label>{T('type-price-tag')}</Label>
			<Radio checked={type === 'custom'} name='custom' onChange={onChange}>
				{T('price-tag-custom-label')}
			</Radio>
			<Radio checked={type === 'templates'} name='templates' onChange={onChange}>
				{T('price-tag-templates-label')}
			</Radio>
		</Field>

	);
};

export default TypeSelection;