import React, { Component } from "react";
import T from "components/i18n";
import ScrollToTopOnMount from "tools/scroll-top";
import Actions from "../../products-actions";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import Columns from "react-bulma-components/lib/components/columns";
import Notification from "react-bulma-components/lib/components/notification";
import {
  Field,
  Control,
  Input,
  Label,
  // Textarea,
} from "react-bulma-components/lib/components/form";
import { getDefaultWarehouseValue, buildStock } from "../../common/function";
import ProductsStore from "../../products-store";
import RenderWarehouseBlock from "../../desktop/add-product-warehouse-block";
import UserActions from "../../../../user/user-actions";
import UserStore from "user/user-store";
import User from "../../../../user/user-store";
import { Icon } from "react-bulma-components";
import { LocalIcon } from "whitelables/wl-name/icons";
import AdditionalFieldsInfo from "./common/additional-fields-info";
import {
  withIsMobileSize
} from "../../../../components/is-mobile-context/is-mobile-context";

class AddVariableModal extends Component {
  constructor(props) {
    super(props);
    const { attr, edit } = this.props;

    this.edit = edit || false;
    this.attr = attr;
    let attr_data = {},
      attr_desc = props.attr_desc ? JSON.parse(props.attr_desc) : {};
    if (this.attr?.length) {
      for (let i in this.attr) {
        let _a = this.attr[i];
        attr_data[_a["value"]] = { value: attr_desc[_a["value"]] || "" };
      }
    } else {
      if(attr && this.attr?.value) {
        attr_data[this.attr["value"]] = {
          value: attr_desc[this.attr["value"]] || "",
        };
      }
    }

    const stock = buildStock(props);
    let sku = props.sku;
    if (stock?.length > 0) {
      sku = stock[0].sku;
    }

    let af = {};
    let custom_price = {};
    if (props.af) {
      const parseAf = JSON.parse(props.af || "{}");

      const keysAf = Object.keys(parseAf);
      keysAf.forEach((key) => {
        if (key === "custom_price") {
          custom_price = parseAf[key];
        } else {
          af[key] = parseAf[key];
        }
      });
    }
    let expirated_date = null
    let additionalInfo = {}
    if(props.id) {
      additionalInfo = ProductsStore.getAdditionalInfoByPid(props.id)
    }
    const getProduct = ProductsStore.getProduct(props.id)
    if(getProduct.expirated_date) {
      expirated_date = getProduct.expirated_date
    }

    this.state = {
      warehouses: stock || [getDefaultWarehouseValue({})],
      fields: UserStore.getAdditionalFieldsList("product"),
      af: af,
      custom_price: custom_price,
      barcode: {
        error: "",
        value: props.asin || "",
      },
      vendorCode: {
        error: "",
        value: sku || "",
      },
      pid: props.id || null,
      message: null,
      attr: attr_data,
      button: edit ? T("change") : T("add-variable"),
      expirated_date,
      ...additionalInfo
    };

    this.submit = this.submit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeAttr = this.onChangeAttr.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onChangeWarehouse = this.onChangeWarehouse.bind(this);
    this.onChangeCustomPrice = this.onChangeCustomPrice.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeAdditional = this.onChangeAdditional.bind(this);
  }

  updateFields(fields) {
    this.setState({
      fields: fields,
    });
  }

  onChangeInput({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
  }

  generateSku() {
    var result = [];
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (let j = 0; j < 3; j++) {
      let r = "";
      for (let i = 0; i < 3; i++) {
        r += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      result.push(r);
    }
    this.setState({
      vendorCode: { value: result.join("-") },
    });
  }

  generateBarcode() {
    this.setState({
      barcode: { value: Date.now().toString().substring(1, 13) },
    });
  }

  onChangeAdditional(evt) {
    const value = evt.target.value,
      name = evt.target.name;

    let field = this.state.af;
    field[name] = value;

    this.setState({
      af: field,
      message: null,
    });
  }

  onChangeCustomPrice(evt) {
    const value = evt.target.value,
      name = evt.target.name;

    let field = this.state.custom_price;

    field[name] = value;

    this.setState({
      custom_price: field,
    });
  }

  submit() {
    const { submit } = this.props;
    const {size_height, size_width, size_length, weight, ukzt, storage_place, expirated_date} = this.state

    let name = "",
      error,
      data = {
        warehouses: this.state.warehouses,
        barcode: this.state.barcode.value || "",
        af: this.state.af || {},
        vendorCode: this.state.vendorCode.value || "",
        custom_price: this.state.custom_price,
        size_height: size_height ? size_height : null,
        size_width: size_width ? size_width : null,
        size_length: size_length ? size_length : null,
        weight: weight ? weight : null,
        ukzt: ukzt ? ukzt : null,
        storage_place: storage_place ? storage_place : null,
        expirated_date: expirated_date ? new Date(this.state.expirated_date).getTime() / 1000 : null
      };

    if (this.state.pid) {
      data["pid"] = this.state.pid;
    }

    if (error) {
      this.setState({
        [name]: {
          value: data[name],
          error: true,
        },
      });
      return;
    }

    let prepare_attr = {};
    if (this.attr?.length > 0 || Object.keys(this.attr)?.length > 0) {
      for (let i in this.state.attr) {
        let a = this.state.attr[i];
        if (a["value"] === "") {
          this.setState({
            message: {
              msg: T("error-enter-attr"),
            },
          });

          return;
        }

        prepare_attr[i] = a["value"];
      }
    }

    submit(data, prepare_attr);
  }

  componentWillUnmount() {
    if (typeof this.listenAddSuccess == "function") {
      this.listenAddSuccess();
    }
    if (typeof this.listenAddFaild == "function") {
      this.listenAddFaild();
    }
    if (typeof this.listenFromScanner == "function") {
      this.listenFromScanner();
    }
    if (typeof this.listenAddFieldCompleted == "function") {
      this.listenAddFieldCompleted();
    }
  }

  componentDidMount() {
    const { onClose } = this.props;

    this.listenAddSuccess = Actions.addProduct.completed.listen((data) => {
      if (typeof onClose == "function") {
        onClose();
      }
    });

    this.listenAddFaild = Actions.addProduct.failed.listen((res) => {
      const data = JSON.parse(res["response"]);
      this.setState({
        message: {
          msg: T(data["error"]["error"] || data["error"]),
          pid: data["error"]["pid"],
          product_name: data["error"]["name"],
        },
      });
    });

    this.listenFromScanner = Actions.setProductFromScanner.completed.listen(
      (barcode) => {
        const _scan_product = ProductsStore.get("fromScanner");
        console.log("listen from scanner ", _scan_product);
        this.setState({
          barcode: { value: barcode },
        });
      }
    );

    this.listenAddFieldCompleted = UserActions.loadProfile.completed.listen(
      () => {
        const fieldsList = UserStore.getAdditionalFieldsList("product");

        this.updateFields(fieldsList);
      }
    );
  }

  onChangeSelect(selectedOption, actions) {
    this.setState({
      [actions.name]: selectedOption,
    });
  }

  onChange(evt) {
    const value = evt.target.value,
      name = evt.target.name;

    this.setState({
      [name]: {
        value: value,
        error: "",
      },
    });
  }

  onChangeAttr(evt) {
    const value = evt.target.value,
      name = evt.target.name.split("-"),
      aid = name[name?.length - 1];

    let attr = this.state.attr;
    attr[aid] = { value: value };

    this.setState({
      attr: attr,
    });
  }

  getProductName() {
    if (!this.state.message["pid"]) {
      return null;
    }

    return (
      <span>
        ID: {this.state.message["pid"]}. {this.state.message["product_name"]}
      </span>
    );
  }

  getMessage() {
    return (
      <Notification color="danger">
        <ScrollToTopOnMount />
        {this.state.message["msg"]} {this.getProductName()}
        <Button remove onClick={() => this.setState({ message: null })} />
      </Notification>
    );
  }

  renderAttrBlock() {
    const generateLine = (aid, label) => {
      return (
        <Columns.Column key={`${label}-${aid}`}>
          <Field>
            <Control>
              <Label>{label}</Label>
              <Input
                size="large"
                name={"attr-" + aid}
                type="text"
                onChange={this.onChangeAttr}
                value={this.state.attr[aid]?.value}
              />
            </Control>
          </Field>
        </Columns.Column>
      );
    };

    let response = [];
    let blocks = [];
    if (this.attr?.length) {
      let counting = 1;
      for (let i in this.attr) {
        const attr = this.attr[i],
          aid = attr["value"],
          label = attr["label"];

        blocks.push(generateLine(aid, label));

        if (counting % 3 === 0) {
          response.push(<Columns key={i}>{blocks}</Columns>);
          blocks = [];
        }
        counting++;
      }
    } else if (Object.keys(this.attr || {})?.length > 0) {
      blocks.push(generateLine(this.attr["value"], this.attr["label"]));
    }

    if (blocks?.length > 0) {
      response.push(<Columns key="last-1">{blocks}</Columns>);
    }

    return response;
  }

  onChangeWarehouse(warehouses) {
    this.setState({
      warehouses: warehouses,
    });
  }

  render() {
    const { onClose, onCloseProduct, source } = this.props;

    const handleBarcodeIconClick = (event) => {
      event.stopPropagation();
      User.postMessage("init-scan");
    };

    return (
      <Box>
        {this.state.message ? this.getMessage() : ""}

        <Columns className={this.props.isMobileSize ? "is-mobile" : ""}>
          <Columns.Column>
            <Field>
              <Control>
                <Label>{T("product-barcode")}</Label>
                <Control
                  iconRight={!!User.isMobileApp()}
                  style={{ width: "100%" }}
                >
                  <Input
                    size="large"
                    name="barcode"
                    type="text"
                    autoComplete="nope"
                    onChange={this.onChange}
                    color={this.state.barcode.error ? "danger" : "primary"}
                    value={this.state.barcode.value}
                  />
                  {User.isMobileApp() && (
                    <Icon align="right">
                      <span
                        style={{ marginTop: "5px" }}
                        onClick={handleBarcodeIconClick}
                        onTouchStart={handleBarcodeIconClick}
                      >
                        <LocalIcon icon="barcode-scan" width={14} />
                      </span>
                    </Icon>
                  )}
                </Control>
              </Control>
              <div align="right">
                <Button
                  onClick={() => this.generateBarcode()}
                  className="button_link sml"
                  renderAs="a"
                >
                  {T("gen-barcode")}
                </Button>
              </div>
            </Field>
          </Columns.Column>
          <Columns.Column>
            <Field>
              <Control>
                <Label>{T("product-vendor-code")}</Label>
                <Input
                  size="large"
                  name="vendorCode"
                  type="text"
                  autoComplete="nope"
                  onChange={this.onChange}
                  color={this.state.vendorCode.error ? "danger" : "primary"}
                  value={this.state.vendorCode.value}
                />
              </Control>
              <div align="right">
                <Button
                  onClick={() => this.generateSku()}
                  className="button_link sml"
                >
                  {T("gen-sku")}
                </Button>
              </div>
            </Field>
          </Columns.Column>
        </Columns>

        <Label size="large">{T("attr")}</Label>
        {this.renderAttrBlock()}
        {source !== "shipment" ? (
          <RenderWarehouseBlock
            {...this.props}
            mid={this.props.mid}
            onCloseProduct={onCloseProduct}
            onClose={onClose}
            onChange={this.onChangeWarehouse}
            pid={this.state.pid}
          />
        ) : null}
        <AdditionalFieldsInfo
          source={source}
          state={this.state}
          onChangeCustomPrice={this.onChangeCustomPrice}
          onChangeAdditional={this.onChangeAdditional}
          onChangeInput={this.onChangeInput}
        />
        <Button.Group>
          <Button
            size={this.props.isMobileSize ? "small" : "large"}
            rounded
            color="primary"
            onClick={this.submit}
          >
            {this.state.button}
          </Button>
          <Button
            size={this.props.isMobileSize ? "small" : "large"}
            rounded
            color="light"
            onClick={onClose}
          >
            {T("close")}
          </Button>
        </Button.Group>
      </Box>
    );
  }
}

export default withIsMobileSize(AddVariableModal);
