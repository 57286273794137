import React from "react";
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import formatDate from "../../../tools/format-date";
import Money from "../../../components/money";
import Tag from "../../../components/tag";
import DebtLink from "./debt-link";
import ActionsRow from "./actions-row";
import ClientsStore from "../clients-store";
import UserStore from "../../../user/user-store";
import AppStore from "../../../app-store";
import ClientViewModal from "../modal/client-view/client-view-modal";

const ReportRowDesktop = ({ data }) => {
  const tagsGroup = ClientsStore.getGroupClientTagByClientId(data["id"]);

  const handleEdit = (event) => {
    event.stopPropagation();
    AppStore.openModal(<ClientViewModal clientID={data["id"]} />);
  };

  return (
    <div onClick={handleEdit} className="cursor-pointer">
      <List.Item className="hover">
        <Columns>
          <Columns.Column>
            {formatDate(data["created_at"])}
            <div className='tag-group tag-grow'>
              {!!tagsGroup?.length &&
                tagsGroup.map((item) => (
                  <Tag
                    key={`tag-${item.id}`}
                    color={item.color}
                    name={item.name}
                  />
                ))}
            </div>
          </Columns.Column>
          <Columns.Column >
            {data["name"]}
          </Columns.Column>

          <Columns.Column>{data["discount"] || 0}%</Columns.Column>

          {UserStore.getPermissionEnable("show-cashflow") && (
            <Columns.Column>
              <Money amount={data["total_amount"] || 0} />
            </Columns.Column>
          )}

          <Columns.Column>
            {data["debt"] > 0 ? (
              <DebtLink debt={data["debt"]} id={data["id"]} />
            ) : (
              <Money amount={data["debt"] || 0} />
            )}
          </Columns.Column>

          <Columns.Column>
            {
              <Money
                amount={data["balance"]}
                currency={UserStore.get("currency_id")}
              />
            }
          </Columns.Column>

          <Columns.Column>
            <ActionsRow id={data["id"]} />
          </Columns.Column>
        </Columns>
      </List.Item>
    </div>
  );
};

export default ReportRowDesktop;
