import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

import WriteOffStore from "../write-off-store";
import {useProductsWriteOffData} from "./useProductsWriteOffData";
import StepZeroAdd from "./steps/step-zero-add";
import StepOnlyShow from "./steps/step-only-show";
import StepFourRevert from "./steps/step-four-revert";
import StepOneAndThirdFinish from "./steps/step-one-and-third-finish";
import StepTwoConfirm from "./steps/step-two-confirm";
import T from "../../../components/i18n";
import ErrorMessageBlock
	from "../../../components/error-message-block/error-message-block";
import WriteOffActions from "../write-off-actions";
import {errorHandler} from "../../../tools/error-handler";
import {connectToStores} from "../../../tools/reflux-tools";

const AddWriteOff = () => {
	const location = useLocation()
	const history = useHistory()

	const [waiting, setWaiting] = useState(false)
	const [step, setStep] = useState(location?.state?.step || 0)

	const {
		comment,
		setComment,
		writeOffCategory,
		setWriteOffCategory,
		allQuantity,
		amount,
		errorMessage,
		setErrorMessage,
		generateErrorMessage,
		stateMid,
		handleChangeMid,
		selectedProductsIDs,
		productsData,
		handleRemoveProductData,
		handleChangeQuantityProduct,
		handleAddProduct } = useProductsWriteOffData(location?.state?.data)

	const handleErrorMessage = () => {
		let error_message

		if (Object.keys(productsData).length < 1) {
			error_message = T("select-min-one-item-write-off");
		} else if (allQuantity === 0) {
			error_message = T("not-received-product");
		} else if (stateMid.value === 0) {
			error_message = T("select-warehouse");
		} else if(!writeOffCategory.value) {
			error_message = T("select-category")
		}

		if (error_message) {
			generateErrorMessage(error_message)
			return false
		} else {
			return  true
		}
	}


	const handleSubmitProduct = () => {
		const submitData = {
			amount: amount,
			step: step,
			from_mid: stateMid.value,
			products: productsData,
			quantity: allQuantity,
			comment,
			category_id: !!writeOffCategory?.value ? writeOffCategory.value : null
		}

		if(location?.state?.data?.id) {
			submitData.id = location.state.data.id
		}

		if(handleErrorMessage()) {
			setWaiting(true)
			WriteOffStore.onAdd(submitData)
		}
	}

	useEffect(() => {
		const addCompleted = WriteOffActions.add.completed.listen(() => {
			if(step === 4) {
				history.go(-1)
			} else {
				setStep(prevState => prevState + 1)
			}
		})
		const addFailed = WriteOffActions.add.failed.listen(res => {
			const message = errorHandler(res)
			generateErrorMessage(message)
			setWaiting(false)
		})

		return () => {
			addCompleted()
			addFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{step === 0 && !location?.state?.onlyShow && (
				<StepZeroAdd
					productsData={productsData}
					selectedProducts={selectedProductsIDs}
					mid={stateMid}
					amount={amount}
					allQuantity={allQuantity}
					step={step}
					comment={comment}
					disabledSelectionMid={location?.state?.disabledSelectionMid}
					writeOffCategory={writeOffCategory}
					onChangeMid={handleChangeMid}
					onAddProduct={handleAddProduct}
					onChangeQuantity={handleChangeQuantityProduct}
					onRemove={handleRemoveProductData}
					onSubmit={handleSubmitProduct}
					onChangeComment={setComment}
					onChangeCategory={setWriteOffCategory}
					isLoaded={waiting}
				/>)}
			{step === 2 && (
				<StepTwoConfirm
					productsData={productsData}
					mid={stateMid}
					step={step}
					amount={amount}
					allQuantity={allQuantity}
					onChangeQuantity={handleChangeQuantityProduct}
					onRemove={handleRemoveProductData}
					onSubmit={handleSubmitProduct}
					isLoaded={waiting}
				/>)}
			{(step === 1 || step === 3) && <StepOneAndThirdFinish step={step}/>}
			{step === 4 && <StepFourRevert onSubmit={handleSubmitProduct} isLoaded={waiting}/>}
			{location?.state?.onlyShow && (
				<StepOnlyShow
					productsData={productsData}
					mid={stateMid}
					step={step}
					amount={amount}
					allQuantity={allQuantity}
					onlyShow={location.state.onlyShow}
					onChangeQuantity={handleChangeQuantityProduct}
					onRemove={handleRemoveProductData}
				/>
			)}
		</>
	);
};

export default connectToStores(AddWriteOff, {_: WriteOffStore})