import React from "react";
import { LocalIcon } from "whitelables/wl-name/icons";
import s from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PreviewDocument = ({ file, onRemove }) => {
  return (
    <div className={s.preview_document}>
      <div className="display-flex-row">
        <span className={s.file_icon}>
          <LocalIcon icon="file" size="large" />
        </span>
        <span className={s.text}>{file.name}</span>
      </div>
      <span
        onClick={() => onRemove && onRemove(file)}
        className={s.preview_document_delete}
      >
        <FontAwesomeIcon icon="trash-alt" size="2x" />
      </span>
    </div>
  );
};

export default PreviewDocument;
