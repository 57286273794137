import React, {useEffect, useState} from "react";
import HistoryStore from "../../history-store";
import {Box, Button, Heading, List} from "react-bulma-components";
import T from "../../../../components/i18n";
import ProductsStore from "../../../products/products-store";
import SelectComponent from "../../../../tools/select-component";
import WarehouseStore from "../../../warehouse/warehouse-store";
import {Label} from "react-bulma-components/lib/components/form";
import SubmitButton from "../../../../components/submit-button";
import HistoryActions from "../../history-actions";
import {errorHandler} from "../../../../tools/error-handler";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import Notification from "react-bulma-components/lib/components/notification";
import SelectStore from "../../../sales/select-store";

const MassRestoreModal = ({ data, onClose }) => {
	const [selectWarehouse, setSelectWarehouse] = useState({value: 0, label: T('select-warehouse-label')})
	const [waiting, setWaiting] = useState(false)
	const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = ErrorMessageControlHook()
	const [errors, setErrors] = useState({})

	const handleChangeSelect = (select) => {
		setSelectWarehouse(select)
	}


	const handleSubmit = () => {
		const submitData = Object.keys(data).reduce((acc,cur) => {
			const product = HistoryStore.getProductDeletedById(cur)
			if(product) {
					if(product.stock[selectWarehouse.value]) {
						acc.push(cur)
					}
			}

			return acc
		},[])

		if(!selectWarehouse.value) {
			handleAutoRemoveError('select-warehouse-label')
		} else if(!submitData.length) {
			handleAutoRemoveError('no-products-to-recover')
		} else {
			setWaiting(true)
			HistoryActions.restoreProduct(submitData, selectWarehouse.value);
		}
	}

	useEffect(() => {
		const restoreCompleted = HistoryActions.restoreProduct.completed.listen((_, res) => {
			SelectStore.clearSelectGroup()
			setWaiting(false)
			if(res?.errors && res?.errors.length > 0){
				const errorsCreate = res.errors.reduce((acc, cur) => {
					acc[cur.pid] = cur.type
					return acc
				}, {})
				setErrors(prev => ({...prev, ...errorsCreate}))
				setWaiting(false)
			} else {
				onClose()
			}
		})
		const restoreFailed = HistoryActions.restoreProduct.failed.listen((res) => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})

		return () => {
			restoreCompleted()
			restoreFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uniqueWarehouse = Object.keys(data || {}).reduce((acc, cur) => {
		const product = HistoryStore.getProductDeletedById(cur)
		Object.keys(product.stock).forEach(warehouse => {
			if(!acc[warehouse]) {
				acc[warehouse] = {id: warehouse, name: WarehouseStore.getWarehouseName(warehouse)}
			}
		})

		return acc
	},{})

  return (
		<>
			<ErrorMessageBlock onRemove={setErrorMessage} message={errorMessage}/>
			<Box>
				<Heading size={3}>{T("mass-recovery-del-prod")}</Heading>
				<SelectComponent
					name="mid"
					empty='select-warehouse-label'
					label='warehouse-for-recovery'
					onChange={handleChangeSelect}
					value={selectWarehouse}
					list={() => Object.values(uniqueWarehouse)}
				/>
				<Label>{T('list-products')}</Label>
				<List style={{overflow: 'auto', maxHeight: '50vh'}}>
					{Object.keys(data || {})?.map((item) => {
						const product = HistoryStore.getProductDeletedById(item)
						const category = ProductsStore.getCategoryName(product.category_id);
						const brand = ProductsStore.getBrandName(product.brand_id);

						let withWarehouse = true

						if(product && selectWarehouse.value) {
							withWarehouse = product.stock[selectWarehouse.value]
						}

						return product && withWarehouse &&  (
							<List.Item key={item}>
								<div>
									<strong>
										ID: {product.id}. {product.name}
									</strong>
									<br />
									{category && (
										<span>
											{T("product-category")}: {category} {brand && " | "}
										</span>
									)}
									{brand && (
										<span>
											{T("product-brand")}: {brand}
										</span>
									)}
								</div>
								{errors[item] && <Notification color="danger">
									{T(errors[item])}
								</Notification>}
							</List.Item>
						);
					})}
				</List>
				<div className='display-flex-row' style={{justifyContent: 'flex-end'}}>
					<Button onClick={onClose} size="medium">{T('close')}</Button>
					<SubmitButton size="medium" text='restore-label' fullwidth={false} submit={handleSubmit} waiting={waiting}/>
				</div>
			</Box>
		</>
  );
};

export default MassRestoreModal;
