export const calcDiffPeriodDate = (start, end) => {
	const diffInMilliseconds = Math.abs(end - start);

	const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

	const newStart = new Date(start.getTime() - diffInDays*24 * 60 * 60 * 1000)
	const newEnd = new Date(start.getTime() - 24 * 60 * 60 * 1000);

	return {
		start: newStart,
		end: newEnd
	}
}