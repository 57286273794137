import React, { useState, useRef, useEffect } from "react";
import { Button, Heading, List } from "react-bulma-components";
import { LocalIcon } from "whitelables/wl-name/icons";
import T from "../i18n";
import s from './styles.module.scss'
import AppStore from "../../app-store";
import ChangeIndexModal from "./change-index-modal";
import usePaginationHook from "../../tools/pagination-hook";

const DraggableList = ({
  data = [],
  heading,
  Component,
  componentProps,
  setData,
  empty,
	classNameList,
	classNameListItem,
	perPage,
	onChangePerPage
}) => {
  const [items, setItems] = useState(data);
	const {end,page,total,setPage,start,PaginationBlock} = usePaginationHook({listLength: data?.length, perPage: perPage || 20})

  const [draggingIndex, setDraggingIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const dragItem = useRef(null);

  const handleDragStart = (index) => {
    setDraggingIndex(index);
    dragItem.current = index;
  };

  const handleDragEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleDragEnd = () => {
    const itemsCopy = [...items];
    const draggedItemContent = itemsCopy[dragItem.current];

		if(hoveredIndex !== null) {
			itemsCopy.splice(dragItem.current, 1);

			let newIndex = hoveredIndex

			if (dragItem.current < hoveredIndex) {
				newIndex -= 1;
			}

			itemsCopy.splice(newIndex, 0, draggedItemContent);

			setItems(itemsCopy);
			setData(itemsCopy);
		}
		setDraggingIndex(null);
		setHoveredIndex(null);
		dragItem.current = null;
  };

  const handleDragLeave = (index) => {
    if (index === items.length - 1 && draggingIndex !== items.length - 1) {
      setHoveredIndex(null);
    }
  };

	const handleChangeItems = (index) => {
		const handleChangeIndex = (newIndex) => {
			const itemsCopy = [...items];

			const content = itemsCopy[index];

			itemsCopy.splice(index, 1);

			let validIndex = newIndex;

			if (newIndex >= itemsCopy.length) {
				validIndex = itemsCopy.length;
			} else if (newIndex < 0) {
				validIndex = 0;
			}

			itemsCopy.splice(validIndex, 0, content);
			setItems(itemsCopy);
			setData(itemsCopy)
		};
		AppStore.openModal(<ChangeIndexModal modal='changeIndex' index={index + 1} onChangeIndex={handleChangeIndex}/>)
	}

  useEffect(() => {
		if(data) {
			setItems(data);
		}
  }, [data]);

  return (
		<>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
				countPerPage={perPage}
				onChangePerPage={onChangePerPage}
			/>
			<List onDragOver={(e) => e.preventDefault()} className={classNameList}>
				{heading}
				{!!items?.length && (
					<>
						{items?.slice(start, end).map((item, index) => (
							<React.Fragment key={index}>
								{hoveredIndex === (index + (page ? page * perPage || 20 : 0)) && draggingIndex !== null && (
									<List.Item
										className="placeholder"
										style={{
											height: 40,
											backgroundColor: "#e0e0e0",
											margin: "5px 0",
											border: "1px dashed grey",
										}}
									/>
								)}
								<List.Item
									className={classNameListItem}
									style={{
										opacity: draggingIndex === index ? 0.2 : 1,
										backgroundColor:
											draggingIndex === index ? "#fff" : "transparent",
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-start",
										gap: 5,
									}}
								>
									<span
										draggable
										onDragStart={() => handleDragStart(index + (page ? page * perPage || 20 : 0))}
										onDragEnter={() => handleDragEnter(index + (page ? page * perPage || 20 : 0))}
										onDragLeave={() => handleDragLeave(index + (page ? page * perPage || 20 : 0))}
										onDragEnd={handleDragEnd}
										className='display-flex-row'
									>
										<span
											className={s.dragDrop}
										>
											<LocalIcon icon="dragDrop" size='middle'/>
										</span>
										<Button className="button-link-without-style"
														onClick={() => handleChangeItems(index + (page ? page * perPage || 20 : 0))}>
											{(index + 1) + (page * (perPage || 20))}.
										</Button>
									</span>
									<div
										style={{flex: 1}}
										onDragStart={() => handleDragStart(index + (page ? page * perPage || 20 : 0))}
										onDragEnter={() => handleDragEnter(index + (page ? page * perPage || 20 : 0))}
										onDragLeave={() => handleDragLeave(index + (page ? page * perPage || 20 : 0))}
										onDragEnd={handleDragEnd}
									>
										<Component {...componentProps}  id={item}/>
									</div>
								</List.Item>
							</React.Fragment>
						))}
						{draggingIndex !== null &&
							hoveredIndex === null &&
							draggingIndex !== items.length - 1 && (
								<List.Item
									className="placeholder"
									onDragEnter={() => setHoveredIndex(null)}
									style={{
										height: 40,
										backgroundColor: "#e0e0e0",
										margin: "5px 0",
										border: "1px dashed grey",
									}}
								/>
							)}
					</>
				)}
				{!items.length && (empty || <Heading>{T("not-have-history")}</Heading>)}
			</List>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
				countPerPage={perPage}
			/>
		</>
  );
};

export default DraggableList;
