import React from "react";
import ShipmentsStore from "../shipments-store";
import Reports from "../../../components/reports";
import Actions from "../shipments-actions";
import ShipmentsRow from "./shipments-row";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import PaginationBlock from "../../../components/pagination";
import ShipmentsActions from "../shipments-actions";

const COLUMNS = [
  "shipments-name",
  "shipments-status",
  "shipments-quantity",
  "shipments-amount",
];

const EmptyMessage = () => {
  return (
    <Box align="center">
      <Heading>{T("not-have-shipments")}</Heading>
    </Box>
  );
};

const ReportList = () => {
  const rows = ShipmentsStore.getPageList();

  return (
    <>
      <PaginationBlock
        setPage={ShipmentsActions.setPage}
        totalPage={ShipmentsStore.get("count_page")}
        currentPage={ShipmentsStore.get("page")}
      />
      <Reports
        load={Actions.load}
        emptyMessage={EmptyMessage}
        columns={COLUMNS}
        hideFooterOnEmpty
        isLoading={ShipmentsStore.get('loadingShipment')}
        rows={rows}
      >
        <ShipmentsRow role="row" />
      </Reports>
      <PaginationBlock
        setPage={ShipmentsActions.setPage}
        totalPage={ShipmentsStore.get("count_page")}
        currentPage={ShipmentsStore.get("page")}
      />
    </>
  );
};

export default ReportList;
