import React, { Component } from 'react';
// import Reflux from 'reflux';
import T from 'components/i18n';
    
import UserStore from 'user/user-store';
import Actions from 'user/user-actions';
// import Icon from 'react-bulma-components/lib/components/icon';
import { Field, Control, Input, Checkbox, Help, Label, Textarea } from 'react-bulma-components/lib/components/form';
import Columns from 'react-bulma-components/lib/components/columns';
import Button from 'react-bulma-components/lib/components/button';


import Messages from 'components/messages';


class SlipInfo extends Component {
    constructor(props) {
        super(props);


        const slip_info = UserStore.getSlipInfo();
        this.state = {
          update: false,
          name: {
                error: "",
                value: slip_info['name'] || ""
          },
          company_info: {
                error: "",
                value: slip_info['company_info'] || ""
          },
          termo_print: {
                error: "",
                value: slip_info['termo_print'] || 0
          },
          add_info: {
                error: "",
                value: slip_info['add_info'] || ""
          },
          images: {
                error: "",
                value: slip_info['images']
          },
          is_auto_print: {
            value: slip_info['is_auto_print']
          },
          is_auto_fiscal_print: {
            value: slip_info['is_auto_fiscal_print']
          }
        };

        

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);

        this.complete = this.complete.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
      Actions.updateSettings.completed.listen(() => {
          if (this.state.update) {
            this.setState({message: T('data-saved'),  update: false});
          }
      });
    }

    onChangeCheckbox(evt) { 
        const value = evt.target.checked,
              name = evt.target.name

        this.setState({
          [name]: {
            'value': value
          }
        });
    }

    onChange(evt) {
        const value = evt.target.value,
              name = evt.target.name;

        this.setState({
          [name]: {
            'value': value
          }
        });
    }

    submit() {
        const name = this.state.name.value,
              termo_print = this.state.termo_print.value,
              images = this.state.images.value,
              add_info = this.state.add_info.value || "",
              company_info = this.state.company_info.value,
              is_auto_print = this.state.is_auto_print.value,
              is_auto_fiscal_print = this.state.is_auto_fiscal_print.value

        if (name === "" && company_info === "") {
            console.log("problem with data");
            return false;
        }

        this.setState({
          update: true
        });

        const data = {
            "company_info": company_info, 
            "add_info": add_info, 
            "termo_print": termo_print, 
            "images": images, 
            "slip_name": name,
            is_auto_print,
            is_auto_fiscal_print
        }

        UserStore.setSlipInfo(data);
    }

    complete(data) {

    }


    _getError(name) {
        return (
            <Help color="danger">{T(this.state[name].error)}</Help>
        );
    }


    render () {
        return (
          <div className="">
             <Messages color="success" message={this.state.message} close={() => this.setState({message: null})} />
             <Field>
                <Label>{T('check-name')}</Label>
                <Control>
                  <Input size="large" 
                    name="name"
                    autoComplete="nope"
                    onChange={this.onChange} 
                    value={this.state.name.value} />
                </Control>
               {this.state.name.error && this._getError("name")}
            </Field>

             <Field>
                <Label>{T('company-info')}</Label>
                <Control>
                  <Textarea size="large" 
                    name="company_info"
                    onChange={this.onChange}
                    value={this.state.company_info.value}>
                  </Textarea>
                </Control>
               {this.state.company_info.error && this._getError("company_info")}
            </Field>

              <Field>
                <Label>{T('additional-info')}</Label>
                <Control>
                  <Textarea size="large" 
                    name="add_info"
                    onChange={this.onChange}
                    value={this.state.add_info.value}>
                  </Textarea>
                </Control>
            </Field>

            <Columns>
              <Columns.Column>
                <Field >
                  <Control>
                     <Checkbox 
                          name="termo_print" 
                          className="termo_print"
                          onChange={this.onChangeCheckbox} 
                          checked={!!this.state.termo_print.value} 
                        >{T('termo-print')}</Checkbox>
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column>
                  <Field >
                  <Control>
                     <Checkbox 
                          name="images" 
                          className="termo_print"
                          onChange={this.onChangeCheckbox} 
                          checked={!!this.state.images.value} 
                        >{T('product-images')}</Checkbox>
                  </Control>
                </Field>
              </Columns.Column>
            </Columns>
            <Field >
              <Control>
                <Checkbox
                  name="is_auto_print"
                  className="termo_print"
                  onChange={this.onChangeCheckbox}
                  checked={!!this.state.is_auto_print.value}
                >{T('auto-print-label')}</Checkbox>
              </Control>
            </Field>
            <Field >
              <Control>
                <Checkbox
                  name="is_auto_fiscal_print"
                  className="termo_print"
                  onChange={this.onChangeCheckbox}
                  checked={!!this.state.is_auto_fiscal_print.value}
                >{T('auto-print-label-fiscal')}</Checkbox>
              </Control>
            </Field>
            <Button.Group>
              <Button fullwidth rounded 
                color="primary"
                size="large"
                onClick={this.submit}>{T('btn-save')}</Button>
            </Button.Group>
          </div>
      );
      
     }
};

export default SlipInfo;