import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bulma-components";
import { confirmDelete } from "../../../../tools/confirm-delete";
import AppStore from "../../../../app-store";
import ProductsActions from "../../../products/products-actions";
import AddBrandModal from "../../../products/add-brand-modal";

const RowDesktop = ({ data }) => {
  return (
    <tr>
      <td style={{ width: "80%" }}>{data.name}</td>
      <td>
        <div className="display-flex-row">
          <Button
            className="button-link-without-style"
            onClick={() =>
              AppStore.openModal(<AddBrandModal modal="brand" {...data} />)
            }
          >
            <FontAwesomeIcon icon="edit" />
          </Button>
          <Button
            className="button-link-without-style"
            onClick={() =>
              confirmDelete(
                () => ProductsActions.deleteCatalog("brand", data.id),
                "confirm-delete",
                "are-you-sure-to-delete-this-catalog"
              )
            }
          >
            <FontAwesomeIcon icon="trash-alt" />
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default RowDesktop;
