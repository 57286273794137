import React, { Component } from "react";
import T from "components/i18n";
// import Money from "components/money";
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Columns from 'react-bulma-components/lib/components/columns';
import Button from 'react-bulma-components/lib/components/button';

import Dropdown from 'react-bulma-components/lib/components/dropdown';
import { LocalIcon } from 'whitelables/wl-name/icons'
import { Link } from "react-router-dom";
import SalesStore from 'modules/sales/sales-store';
import SingleSaleModal from "modules/sales/mobile/single-sale-modal";
import OpenInsideModal from "components/modal/open-inside-modal";

import {printPdf, printElem} from 'tools/print';

// import User from 'user/user-store';
import {createMobileAppLink} from "../../tools/create-mobile-app-link";

class PrintModal extends Component {
  constructor(props) {
        super(props);
        this.state = {
          printURLVchasno: ''
        }
  }

  getButton(text, icon) {
    return (
      <><LocalIcon icon={icon}  width="25px" height="25px"/> {T(text)} </>
    )
  }

  getDropdownPDFDps(checkID, title, type) {
    let link
    if(type === 'vchasno') {
      link = SalesStore.getVchasnoKasaPrintUrl(checkID)
    } else {
      link = SalesStore.getCheckboxPrintSlip(checkID, "pdf")
    }
    return <Dropdown.Item
      key={`link-download${checkID}`}
      title={`${T(title)} PDF`}
      value={`${T(title)} PDF`}
    >
      <Link
        className='drop-item'
        onClick={(evt) => createMobileAppLink(link, evt)}
        to={{pathname: link}}
        target="_blank"
      >
        <LocalIcon icon={'download'} /> {T(title)} PDF
      </Link>
    </Dropdown.Item>
  }
  getDropdownPrintDps(checkID, title, type) {
    let link
    if(type === 'vchasno') {
      link = SalesStore.getConvertPdfToImage(SalesStore.getVchasnoKasaPrintUrl(checkID, "pdf"))
    } else {
      link = SalesStore.getCheckboxPrintSlip(checkID, "png")
    }
    return <Dropdown.Item
      key={`dps-check${checkID}`}
      renderAs="p"
      value={`${T(title)} ${T('print')}`}
    >
      <span
        className='drop-item'
        onClick={() => printElem(link)} >
        <LocalIcon icon={'printer'} />
        {T(title)} {T('print')}
      </span>
    </Dropdown.Item>
  }

   getCheckMenu() {
      const {oid, dps} = this.props;
      let response = [];
      response.push(
          <Dropdown.Item key='download' title={T('print-slip-pdf')} value={T('print-slip-pdf')}>
            <Link
              className='drop-item'
              onClick={(evt) => createMobileAppLink(SalesStore.getUrlPrintSlip(oid, "termo", 'packing'), evt)}
              to={SalesStore.getUrlPrintSlip(oid, "termo", 'packing')}
              target="_blank"
            >
              <LocalIcon icon={'download'} /> PDF
            </Link>
          </Dropdown.Item>
      );
      response.push(
            <Dropdown.Item key='printer' renderAs="p" value={T('print')}>
                <span className='drop-item' onClick={() => printPdf(SalesStore.getUrlPrintSlip(oid, "termo", 'packing'))} ><LocalIcon icon={'printer'} /> {T('print')}</span>
            </Dropdown.Item>
      );

      // if (!User.getCheckboxEnable()) return response;

      if (!dps) return response;

      if(dps && Array.isArray(dps) && !!dps.length) {
        dps.forEach(item => {
          if(item.prepaid) {
            response.push(
              this.getDropdownPDFDps(item.check_id, 'dps-check-prepaid', item.type)
            )
            response.push(
              this.getDropdownPrintDps(item.check_id, 'dps-check-prepaid', item.type)
            )
          } else {
            response.push(
              this.getDropdownPDFDps(item.check_id, 'dps-check', item.type)
            )
            response.push(
              this.getDropdownPrintDps(item.check_id, 'dps-check', item.type)
            )
          }
        })
      }

      return response
    }

  render() {
        const {oid, onClose} = this.props;

        return (
          <Box align="center" className="print-modal">
              <div className="modal-icon">
                  <img src="/img/icons/print.png" alt="print-icon" width="88px" border="0"/>
              </div>
              <Heading >{T('documents')}</Heading>
              <Columns style={{width: '100%'}}>
                  <Columns.Column>
                    <Dropdown className='full-width-dropdown-mobile' right={false} label={this.getButton('packing-list', 'download')}>
                        <Dropdown.Item title={T('print-slip-pdf')} value={T('print-slip-pdf')}>
                          <Link
                            className='drop-item'
                            onClick={(evt) => createMobileAppLink(SalesStore.getUrlPrintSlip(oid, "base"), evt)}
                            to={SalesStore.getUrlPrintSlip(oid, "base")}
                            target="_blank"
                          >
                            <LocalIcon icon={'download'} /> PDF
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item title={T('print-slip')} value={T('print-slip')} >
                          <Link
                            className='drop-item'
                            to={SalesStore.getUrlPrintSlip(oid, "xls")}
                            target="_blank"
                            onClick={(evt) => createMobileAppLink(SalesStore.getUrlPrintSlip(oid, "xls"), evt)}
                          >
                            <LocalIcon icon={'excel'} />  Excel
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item title={T('print-slip')} value="web">
                              <OpenInsideModal text='Web' local_icon="web" renderAs="p">
                                  <SingleSaleModal oid={oid}/>
                              </OpenInsideModal>
                        </Dropdown.Item>
                        <Dropdown.Item renderAs="p" value={T('print')}>
                            <span className='drop-item' onClick={() => printPdf(SalesStore.getUrlPrintSlip(oid, "base"))} ><LocalIcon icon={'printer'} /> {T('print')}</span>
                        </Dropdown.Item>
                    </Dropdown>
                  </Columns.Column>
                  <Columns.Column>
                      <Dropdown className='full-width-dropdown-mobile' right={false} label={this.getButton('payment-invoice', 'payment')}>
                        <Dropdown.Item  title={T('print-slip-pdf')} value={T('print-slip-pdf')}>
                          <Link
                            className='drop-item'
                            onClick={(evt) => createMobileAppLink(SalesStore.getUrlPrintSlip(oid, "pdf", 'payment'),evt)}
                            to={SalesStore.getUrlPrintSlip(oid, "pdf", 'payment')}
                            target="_blank"
                          >
                            <LocalIcon icon={'download'} /> PDF
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item title={T('print-slip')} value={T('print-slip')} >
                          <Link
                            className='drop-item'
                            onClick={(evt) => createMobileAppLink(SalesStore.getUrlPrintSlip(oid, "xls", 'payment'), evt)}
                            to={SalesStore.getUrlPrintSlip(oid, "xls", 'payment')}
                            target="_blank"
                          >
                            <LocalIcon icon={'excel'} />  Excel
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item renderAs="p" value={T('print')}>
                            <span className='drop-item' onClick={() => printPdf(SalesStore.getUrlPrintSlip(oid, "pdf", 'payment'))} ><LocalIcon icon={'printer'} /> {T('print')}</span>
                        </Dropdown.Item>
                    </Dropdown>
                  </Columns.Column>
              </Columns>
              <Columns style={{width: '100%'}}>
                  <Columns.Column>
                      <Dropdown className='full-width-dropdown-mobile' right={false} label={this.getButton('sales-invoice', 'doc1')}>
                        <Dropdown.Item title={T('print-slip-pdf')} value={T('print-slip-pdf')}>
                          <Link
                            className='drop-item'
                            to={SalesStore.getUrlPrintSlip(oid, "pdf", 'sales-invoice')}
                            onClick={(evt) => createMobileAppLink(SalesStore.getUrlPrintSlip(oid, "pdf", 'sales-invoice'),evt)}
                            target="_blank"
                          >
                            <LocalIcon icon={'download'} /> PDF
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item   title={T('print-slip')} value={T('print-slip')} >
                          <Link
                            className='drop-item'
                            onClick={(evt) => createMobileAppLink(SalesStore.getUrlPrintSlip(oid, "xls", 'sales-invoice'), evt)}
                            to={SalesStore.getUrlPrintSlip(oid, "xls", 'sales-invoice')}
                            target="_blank"
                          >
                            <LocalIcon icon={'excel'} />  Excel
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item renderAs="p" value={T('print')}>
                            <span className='print' onClick={() => printPdf(SalesStore.getUrlPrintSlip(oid, "pdf", 'sales-invoice'))} ><LocalIcon icon={'printer'} /> {T('print')}</span>
                        </Dropdown.Item>
                    </Dropdown>
                  </Columns.Column>
                  <Columns.Column>
                      <Dropdown className='full-width-dropdown-mobile' right={false} label={this.getButton('product_slip', 'check')}>
                        {this.getCheckMenu()}
                      </Dropdown>
                  </Columns.Column>
              </Columns>
              <Button className="margin-top-40" fullwidth={true} rounded color="primary" onClick={() => {onClose();}}>{T('close')}</Button>
          </Box>
        );
    }

}

export default PrintModal;
