import React, {useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";
// import ApiKeyInputWithGenerate from "../common/api-key-input-with-generate";
import ActionsModal from "../common/actions-modal";
import ModalItsOK from "../../../../../components/modal-its-ok";
import IntegrationListenerHook from "../common/integration-listener.hook";
import {confirmDelete} from "../../../../../tools/confirm-delete";
import {handleDeleteIntegration, parseObj} from "../utils";
import UserActions from "../../../../../user/user-actions";
import InputsGroupField from "../common/inputs-group-field";
import IntegrationControlHook from "../common/integration-control.hook";
import UserStore from "../../../../../user/user-store";
import isValidPhone from "../../../../../tools/valid-phone";
import isValidEmail from "../../../../../tools/valid-email";
import {generateKey} from "../../../../../tools/genarete-key";

const DATA_INPUT = [
	{
		name: 'api_key',
		placeholder: 'api-key-integration-label',
		label: "api-key-integration-label"
	},
	{
		name: "business_name",
		placeholder: "business_name",
		label: 'business_name'
	},
	{
		name: "email",
		placeholder: "email",
		label: 'Email'
	},
	{
		name: "phone",
		placeholder: "phone",
		label: 'phone'
	},
]

const LoyalCadsModal = ({data, wooID, onClose}) => {
	const {id, api_key, ident, is_active} = data

	let identParse = typeof ident === 'string' ? parseObj(ident) : ident ? ident : {}

	const [waiting, setWaiting] = useState(false)
	const {
		inputValues,
		handleChangeInputValues,
		errorMessage,
		setErrorMessage,
		handleAutoRemoveError
	} = IntegrationControlHook({
		inputDefaultValue: {
			api_key: api_key || generateKey( 32),
			phone: identParse?.phone || UserStore.get('phone'),
			email: identParse?.email || UserStore.get('email'),
			business_name: identParse?.business_name || ""
		}
	})

	const {submitOK, additionalMessage} = IntegrationListenerHook({handleError: handleAutoRemoveError, setWaiting})

	const handleError = () => {
		let message = null

		if(inputValues.phone && !isValidPhone(inputValues.phone)) {
			message = 'wrong-phone'
		}
		if(!isValidEmail(inputValues.email)) {
			message = 'wrong-email'
		}

		if(!inputValues.api_key && !inputValues.business_name) {
			message = 'not all fields required'
		}

		if(inputValues.api_key.length !== 32) {
			message = 'wrong-key'
		}

		if(message) {
			handleAutoRemoveError(message)
			return false
		}

		return true
	}

	const handleSubmit = ({deactivate}) => {
		const {api_key, ...restValue} = inputValues
		const data = {
			integration_id: 16,
			api_key: api_key,
			is_active: 1,
			ident: JSON.stringify(restValue)
		}
		if(id) data.id = id
		if(deactivate) data.is_active = 0

		if(handleError()) {
			UserActions.updateIntegrations(data)
		}
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!submitOK && <Box>
				<Heading size={3}>{T('setting-integration', [T(`integration-${wooID}`)])}</Heading>
				<InputsGroupField dataInput={DATA_INPUT} inputValues={inputValues} onChange={handleChangeInputValues}/>
				<ActionsModal
					onClose={onClose}
					waiting={waiting}
					isEdit={id}
					isActive={is_active}
					onSubmit={handleSubmit} onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
				/>
			</Box>}
			{submitOK && <ModalItsOK onClose={onClose} title={submitOK} additionalMessage={additionalMessage}/>}
		</>
	);
};

export default LoyalCadsModal;