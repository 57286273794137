import React, {useState} from "react";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import List from "react-bulma-components/lib/components/list";
import ClientsStore from "../clients-store";
import Columns from "react-bulma-components/lib/components/columns";
import UserStore from "../../../user/user-store";
import Box from "react-bulma-components/lib/components/box";
import SelectComponent from "../../../tools/select-component";
import OpenModalButton from "../../../components/modal/open-modal-button";
import Button from "react-bulma-components/lib/components/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Actions from "../clients-actions";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const ClientDeliveryList = ({ clientID, name, phone }) => {
  const {isMobileSize} = useIsMobile()
  const client_delivery = ClientsStore.getClientDeliveryList(clientID);
  const [deliveryServices, setDeliveryServices] = useState(null)

  const handleChangeDelivery = (selectedValue) => {
    if(!selectedValue.length) {
      setDeliveryServices(selectedValue)
    }
  }

  const handleDeleteDelivery = (deliveryID) => {
    Actions.deleteDelivery(deliveryID);
  }

  return (
    <Box>
      <Heading renderAs="h2" size={4}>
        {T("delivery-services")}
      </Heading>
      <List className='view-list-scroll-400'>
        {client_delivery?.length > 0 ? (
          client_delivery.map((item) => (
            <List.Item key={"delivery" + item?.id}>
              <Columns>
                <Columns.Column>
                  <b>{T("delivery-" + item["delivery_id"])}: </b>
                  {ClientsStore.getClientDeliveryNane(
                    item,
                    UserStore.getLang()
                  )}
                </Columns.Column>
                <Columns.Column size={1} align='left'>
                  <Button className="without-border" style={{background: 'transparent'}} onClick={() => {handleDeleteDelivery(item['id']);}} title={T('delete')}>
                    <FontAwesomeIcon icon="trash-alt" size="1x" />
                  </Button>
                </Columns.Column>
              </Columns>
            </List.Item>
          ))
        ) : (
          <List.Item className="text-align-center" key='not-history'>
            <Heading size={6}>{T("not-have-history")}</Heading>
          </List.Item>
        )}
      </List>
      <Columns className={isMobileSize ? "is-mobile is-vcentered " : "is-mobile"}>
        <Columns.Column>
          <SelectComponent
            name="add_delivery_service"
            label=""
            onChange={handleChangeDelivery}
            value={deliveryServices}
            list={UserStore.getCreatableDeliveryService}
            creatable={false}/>
        </Columns.Column>
        <Columns.Column className="alignright">
          <OpenModalButton size="medium" link={{
            pathname: '/clients/add-delivery',
            state: {
              delivery: deliveryServices?.value,
              phone,
              client_id: clientID,
              name
            }
          }} text={T('add-delivery-btn')} />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default ClientDeliveryList;
