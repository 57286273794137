import React from 'react';
import T from "components/i18n";
import Box from 'react-bulma-components/lib/components/box';
import LightStore from './light-store'
import MovingStore from "../moving/moving-store";
import MovingActions from "../moving/moving-actions";
import Button from 'react-bulma-components/lib/components/button';
import Heading from 'react-bulma-components/lib/components/heading';
import {
	Field,
} from 'react-bulma-components/lib/components/form';
import BaseModule from "components/modules/base-module";
import SelectComponent from 'tools/select-component'
import OpenModalButton from "components/modal/open-modal-button";
import {connectToStores} from "../../tools/reflux-tools";
import {withRouter} from "react-router-dom";
import ProductsStore from "./products-store";
import {
	withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";

class AddToMoving extends BaseModule {
	constructor(props) {
		super(props);

		this.state = {};
		this.onChangeSelectMoving = this.onChangeSelectMoving.bind(this);
		this.onEditMoving = this.onEditMoving.bind(this);
	}

	componentDidMount() {
		MovingActions.load()
	}

	onChangeSelectMoving(selectedOption, action) {
		let data = MovingStore.getMovingById(selectedOption.value);

		this.setState({
			[action.name]: selectedOption,
			'data': data
		});
	}

	onEditMoving() {

		this.props.history.push({
			pathname: '/moving/edit',
			state: {'step': 0, 'edit': true, 'group_select': LightStore.getProductsSelectOptionsWithoutDropAndServicesAndBundle(), disabledSelectMid: true, 'data': this.state.data }
		})
	}

	render () {
		const {onClose} = this.props;
		return (
			<Box>
				<Heading size={this.props.isMobileSize ? 5 : 3}>
					{T('add-to-moving')}: {LightStore.getCountWithoutDropAndServicesProduct()} {T('moving-product')} <br />
				</Heading>

				<Field>
					<SelectComponent
						name="moving"
						label='select-moving-products'
						onChange={this.onChangeSelectMoving}
						value={this.state.moving}
						list={()=>MovingStore.getList('rows')?.filter(item => item.status === 'pending')}
						load={MovingActions.load}
						creatable={false}/>
				</Field>

				<div align="right" className="margin-bottom-10">
					<OpenModalButton size={"small"}  color="info" link={{
						pathname: '/moving/add-moving',
						state: {
							'step': 0,
							'received': false,
							'edit': false,
							'data': ProductsStore.getFilter('mid').value ? {from_marketplace_id: ProductsStore.getFilter('mid').value} : null,
							'group_select': LightStore.getProductsSelectOptionsWithoutDropAndServicesAndBundle() }
					}} text={T('add-moving-btn-label')} />
				</div>

				<Button.Group position="centered">
					<Button onClick={this.onEditMoving} color='success' size={this.props.isMobileSize ? "small": "medium"} disabled={this.state.moving && this.state.moving.value ? false : true}>
						{T('add-to-moving')}
					</Button>
					<Button size={this.props.isMobileSize ? "small": "medium"} rounded color="light"
									onClick={onClose}
					>{T('close')}</Button>
				</Button.Group>
			</Box>
		);
	}

}

export default connectToStores(withIsMobileSize(withRouter(AddToMoving)), {_: MovingStore});

