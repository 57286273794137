import React, { useEffect, useState } from "react";
import Box from "react-bulma-components/lib/components/box";
import SelectComponent from "../../../../tools/select-component";
import { Button, Heading } from "react-bulma-components";
import T from "../../../../components/i18n";
import ErrorMessageControlHook from "../../../../components/error-message-block/error-message-control.hook";
import TerminalStore from "../terminal-store";
import UserActions from "../../../../user/user-actions";
import ExpensesStore from "../../../expenses/expenses-store";

const LinkAccountToTerminalModal = ({ terminalData, onClose }) => {
  const connectedAccountInfo = TerminalStore.getLinkAccountToTerminalById(
    terminalData.id
  );

  const [selectAccount, setSelectAccount] = useState({
    value: connectedAccountInfo?.account_id
      ? connectedAccountInfo?.account_id
      : 0,
  });

  const {
    errorMessage,
    setErrorMessage,
    handleAutoRemoveError,
    ErrorMessageBlock,
  } = ErrorMessageControlHook();

  const handleChangeSelect = (select) => {
    setSelectAccount(select);
  };

  const handleSubmit = () => {
    if (selectAccount.value === 0) {
      handleAutoRemoveError("choose-account");
    } else {
      TerminalStore.setLinkAccountToTerminal(
        selectAccount.value,
        terminalData.id
      );
    }
  };

  const handleDelete = () => {
    TerminalStore.deleteLinkAccountToTerminalById(connectedAccountInfo?.account_id );
  };

  useEffect(() => {
    const updateUserData = UserActions.updateSettings.completed.listen(() => {
      onClose();
    });

    return () => {
      updateUserData();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading>
          {T("link-account-to-terminal")}: {terminalData.name}{" "}
          {terminalData?.model && `(${terminalData.model})`}
        </Heading>
        <SelectComponent
          name="account"
          label="sale-account"
          empty="choose-account"
          onChange={handleChangeSelect}
          list={() => ExpensesStore.getAccountsList(3)}
          value={selectAccount}
          creatable={false}
        />
        <div
          className="display-flex-row"
          style={{ justifyContent: "flex-end" }}
        >
          {connectedAccountInfo.account_id && (
            <Button size="medium" color='danger' rounded onClick={handleDelete}>
              {T("del")}
            </Button>
          )}
          <Button color="info" size="medium" rounded onClick={handleSubmit}>
            {T("save-btn-label")}
          </Button>
          <Button size="medium" rounded onClick={onClose}>
            {T("close")}
          </Button>
        </div>
      </Box>
    </>
  );
};

export default LinkAccountToTerminalModal;
