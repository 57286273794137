import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import s from "./styles.module.scss";
import { LocalIcon } from "whitelables/wl-name/icons";
import SelectItem from "./select-item";
import T from "../i18n";
import { filterSearch } from "../../tools/filters-helper";
import InfoWrapper from "../info-wrapper";

const Select = ({
  name,
  placeholder,
  list = [],
  isCreate,
  label,
  empty,
  infoLabel,
  value,
  addItem,
  addItemModal,
  disabledAutoSelect,
  onChange,
}) => {
  const refWrapper = useRef(null);
  const refInput = useRef(null);
  const refValue = useRef(null);
  const refMenu = useRef(null);

  const [isFocus, setIsFocus] = useState(false);
  const [selectListInfo, setSelectListInfo] = useState({
    width: 0,
    top: 0,
    height: 220,
  });
  const [search, setSearch] = useState("");
  const [selectValue, setSelectValue] = useState(
    empty && !value ? { value: 0 } : value ? value : {}
  );

  const [isOpen, setIsOpen] = useState(false);
  const [clickTimer, setClickTimer] = useState(null);

  const handleOnFocusInput = () => {
    setIsFocus(true);
  };


  const handleOnBlurInput = () => {

    setIsFocus(false);
    setTimeout(() => {
      setSearch("");
      isOpen && setIsOpen(false)
      updateSelectListInfo()
    }, 200);
  };

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
    if(refInput.current && document.activeElement !== refInput.current) {
      refInput.current.focus()
    }

    updateSelectListInfo()
  };

  const handleChangeSearch = ({ target: { value } }) => {
    setSearch(value);
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleChangeSelectValue = (value) => {
    setIsOpen(false);
    setSelectValue({ value: value.id, label: value.name });
    if (onChange) {
      onChange({ value: { value: value.id, label: value.name }, name });
    }
  };

  const handleAddItem = () => {
    if (addItem) {
      addItem(search);
    }
  };
  const handleAddItemModal = () => {
    if (addItemModal) {
      addItemModal();
    }
  };

  const updateSelectListInfo = useCallback(() => {
    if (refWrapper.current && refMenu.current) {
      const rect = refWrapper.current.getBoundingClientRect();
      const rectMenu = refMenu.current.getBoundingClientRect();

      const windowHeight = window.innerHeight;
      let blockHeight = rectMenu.height;

      while (blockHeight > 0) {
        if (rect.bottom + blockHeight <= windowHeight) {
          setSelectListInfo({
            width: rect.width,
            top: rect.bottom + 5,
            height: blockHeight,
          });
          return;
        } else if (rect.top - blockHeight >= 0) {
          setSelectListInfo({
            width: rect.width,
            top: rect.top - blockHeight - 5,
            height: blockHeight,
          });
          return;
        } else {
          blockHeight -= 40;
        }
      }
      setSelectListInfo({
        width: rect.width,
        top: rect.bottom + 5,
        height: 220,
      });
    }
  }, [refWrapper, refMenu]);


  const stateList = useMemo(() => {
    const listWithEmpty = Array.isArray(list) ? [...list] : [];

    if (empty) {
      listWithEmpty.unshift({ name: empty, id: 0 });
    }

    return listWithEmpty.filter((item) => {
      if (search) {
        return filterSearch(search, ["name"], item, 1);
      }
      return true;
    });
  }, [search, list, empty]);

  const dictList = useMemo(() => {
    const listWithEmpty = Array.isArray(list) ? [...list] : [];

    if (empty) {
      listWithEmpty.unshift({ name: empty, id: 0 });
    }
    return listWithEmpty.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
  }, [list, empty]);

  useEffect(() => {
    const handleResizeOrScroll = () => {
      updateSelectListInfo();
    };

    const findScrollableParent = (element) => {
      let parent = element.parentElement;

      while (parent) {
        const overflowY = window.getComputedStyle(parent).overflowY;
        if (overflowY === 'auto' || overflowY === 'scroll') {
          return parent;
        }
        parent = parent.parentElement;
      }

      return null;
    };

    let elementWithScroll = null

    if(refWrapper.current) {
      elementWithScroll = findScrollableParent(refWrapper.current)
    }

    window.addEventListener("resize", handleResizeOrScroll);
    window.addEventListener("scroll", handleResizeOrScroll);

    if(elementWithScroll) {
      elementWithScroll.addEventListener("scroll", handleResizeOrScroll)
    }

    return () => {
      window.removeEventListener("resize", handleResizeOrScroll);
      window.removeEventListener("scroll", handleResizeOrScroll);
      if(elementWithScroll) {
        elementWithScroll.removeEventListener("scroll", handleResizeOrScroll);
      }
    };
  }, [updateSelectListInfo, refWrapper]);

  useEffect(() => {
    if(refWrapper.current && refMenu.current) {
      updateSelectListInfo();
    }

  }, [refWrapper, refMenu, isOpen, updateSelectListInfo]);

  let labelComponent = label && <div className={s.label}>{T(label)}</div>;

  if (infoLabel) {
    labelComponent = (
      <div
        className="display-flex-row"
        style={{ justifyContent: "flex-start" }}
      >
        <InfoWrapper info={T(infoLabel)}>
          <div className={s.label}>{T(label)}</div>
        </InfoWrapper>
      </div>
    );
  }

  const handleTextSelection = () => {
    if (refValue.current) {
      const range = document.createRange();
      range.selectNodeContents(refValue.current);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleMouseDownOnValue = (e) => {
    e.preventDefault();
    const timer = setTimeout(() => {
      handleTextSelection();
    }, 500);
    setClickTimer(timer);
  };

  const handleMouseUpOnValue = () => {
    if (clickTimer) {
      clearTimeout(clickTimer);
      setClickTimer(null);
    }
  };

  useEffect(() => {
    if(!disabledAutoSelect) {
      setSelectValue({ value: stateList[0].id, label: stateList[0].name });
      onChange && onChange({ value: { value: stateList[0].id, label: stateList[0].name }, name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledAutoSelect]);

  return (
    list &&
    !!list?.length && (
      <div>
        {label && labelComponent}
        <div
          onClick={handleOpen}
          className={`${s.wrapper} ${isFocus ? s.active : ""}`}
          ref={refWrapper}
        >
          {!search && (
            <div
              className={s.value}
              ref={refValue}
              onMouseDown={handleMouseDownOnValue}
              onMouseUp={handleMouseUpOnValue}
              title={selectValue?.value && T(selectValue.label)}
            >
              {T(dictList[selectValue?.value]?.name)}
            </div>
          )}
          <input
            placeholder={T(placeholder)}
            className={s.input}
            onChange={handleChangeSearch}
            value={search}
            onFocus={handleOnFocusInput}
            ref={refInput}
            onBlur={handleOnBlurInput}
          />
          <div className={`${s.open} ${isFocus ? s.active : ""}`}>
            <LocalIcon icon="arrow-left" size="small" className={s.icon_open} />
          </div>
        </div>
        {isOpen && (
          <div
            ref={refMenu}
            className={s.select_list}
            style={{
              width: selectListInfo.width,
              top: selectListInfo.top,
              maxHeight: selectListInfo.height,
            }}
          >
            {isCreate && search && (
              <div className={s.create_input} onClick={handleAddItem}>
                <b>{T("create")}: </b>
                {search}
              </div>
            )}
            {stateList.map((item) => (
              <SelectItem
                key={item.id}
                value={selectValue.value}
                {...item}
                onClick={handleChangeSelectValue}
              />
            ))}
            {!stateList.length && !isCreate && (
              <div className="bold">{T("not-have-history")}</div>
            )}
            {isCreate && !search && (
              <div className={s.create} onClick={handleAddItemModal}>
                + {T("create")}
              </div>
            )}
          </div>
        )}
      </div>
    )
  );
};

export default Select;
