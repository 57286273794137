import React, { Component } from 'react';


import DefaultLayout from './whitelables/wl-name/default-layout.js'
import AccountLayout from './account-layout.js'

import UserStore from 'user/user-store';


class BaseLayout extends Component {
	render () {
	    return UserStore.isGuest() ?  <DefaultLayout /> : <AccountLayout />
  	}

}

export default BaseLayout;