import React, { useEffect, useState } from "react";
import T from "components/i18n";
import SalesStore from "modules/sales/sales-store";
import { printElem } from "tools/print";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import Loader from "react-loader-spinner";
import UserStore from "user/user-store";
import errorMessageControlHook from "../../../../components/error-message-block/error-message-control.hook";
import { errorHandler } from "../../../../tools/error-handler";
import SelectComponent from "../../../../tools/select-component";
import SalesActions from "modules/sales/sales-actions";

const DpsCheckModal = ({ oid, onClose }) => {
  const listPrro = UserStore.getPRROList(oid);

  const {
    handleAutoRemoveError,
    errorMessage,
    ErrorMessageBlock,
    setErrorMessage,
  } = errorMessageControlHook();
  const [waiting, setWaiting] = useState(false);
  const [text, setText] = useState("fiscalize-heading");
  const [urlCheck, setUrlCheck] = useState(null);
  const [integrationInfo, setIntegrationInfo] = useState(
    listPrro &&
      Array.isArray(listPrro) && {
        value: listPrro[0].id,
        label: listPrro[0].name,
      }
  );

  useEffect(() => {
    const listenSetToDps = SalesActions.setToDps.completed.listen((data) => {
      setWaiting(false);
      setText("dps-send-success");
      if (data["type"] === "vchasno") {
        setUrlCheck(SalesStore.getConvertPdfToImage(SalesStore.getVchasnoKasaPrintUrl(data["check_id"], "pdf")));
      } else {
        setUrlCheck(SalesStore.getCheckboxPrintSlip(data["check_id"]));
      }
    });

    const listenSetToDpsFail = SalesActions.setToDps.failed.listen((res) => {
      const error = errorHandler(res);
      handleAutoRemoveError(error);
      setWaiting(false);
      setText("dps-send-error");
    });

    return () => {
      listenSetToDps();
      listenSetToDpsFail();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!listPrro.length) {
    return <Box>{T("problem-with-integration")}</Box>;
  }

  const handleChangeSelect = (select) => {
    setIntegrationInfo(select);
  };

  const handleSubmit = () => {
    if(integrationInfo) {
      const getInfoIntegration = UserStore.getIntegrationNameById(integrationInfo.value);
      const type = UserStore.getPRROName(getInfoIntegration.integration_id);
      SalesActions.setToDps(oid, false, type, getInfoIntegration.id);
      setWaiting(true);
    } else {
      handleAutoRemoveError("not all fields required");
    }
  };

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box align="center">
        {waiting ? (
          <div>
            {<h1 className="title">{T("sending-to-dps")}</h1>}
            <Loader
              type="Circles"
              className="button_loader"
              color="#000000"
              height={30}
              width={30}
            />
          </div>
        ) : (
          <div>
            <h1 className="title">{T(text)}</h1>
            {!urlCheck && !!listPrro.length && listPrro.length > 1 && (
              <SelectComponent
                label='cashier'
                value={integrationInfo}
                onChange={handleChangeSelect}
                list={() => listPrro}
              />
            )}
            <Button.Group className="is-centered is-grouped">
              {!urlCheck && (
                <Button
                  size="medium"
                  rounded
                  color="primary"
                  onClick={handleSubmit}
                >
                  {T("send-to-dps")}
                </Button>
              )}
              {urlCheck && (
                <Button
                  size={"medium"}
                  rounded
                  color="info"
                  target="_blank"
                  onClick={() => printElem(urlCheck, "pdf")}
                >
                  {T("print-fiscal-slip")}
                </Button>
              )}
              <Button size={"medium"} rounded color="light" onClick={onClose}>
                {T("close")}
              </Button>
            </Button.Group>
          </div>
        )}
      </Box>
    </>
  );
};

export default DpsCheckModal;
