import React from "react";
import Reports from "../../../components/reports";
import Actions from "../shipments-actions";
import ShipmentsStore from "../shipments-store";
import ShipmentsRow from "../shipments-component/shipments-row";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../components/i18n";
import usePaginationHook from "../../../tools/pagination-hook";

const EmptyMessage = () => {
  return (
    <Box align="center">
      <Heading>{T("not-have-shipments")}</Heading>
    </Box>
  );
};

const COLUMNS = [
  "shipments-name",
  "shipments-status",
  "shipments-quantity",
  "shipments-amount",
];

const SupplierShipmentsList = ({ list }) => {
  const { PaginationBlock, setPage, page, end, total, start } =
    usePaginationHook({ listLength: list?.length, perPage: 5 });
  return (
    list && (
      <>
        <Reports
          load={Actions.load}
          emptyMessage={EmptyMessage}
          columns={COLUMNS}
          hideFooterOnEmpty
          isLoading={ShipmentsStore.loading}
          rows={list.slice(start, end)}
        >
          <ShipmentsRow role="row" />
        </Reports>
        <PaginationBlock
          setPage={setPage}
          totalPage={total}
          currentPage={page}
        />
      </>
    )
  );
};

export default SupplierShipmentsList;
