import React from 'react'
import Content from "react-bulma-components/lib/components/content";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../components/i18n";
import Button from "react-bulma-components/lib/components/button";
import AppStore from "../../../../app-store";
import CatalogSingleList from "./catalog-single-list";
import CatalogMultiList from "./catalog-multi-list";

const CatalogList = ({name, btnText,modal,listItems,isMulti, ...rest}) => {
	const getList = listItems()

	const checkArray = getList && (Array.isArray(getList) ? getList : Object.values(getList))
	return (
		<Content>
			<Heading size={5}>{T(name)}</Heading>
			<div align="right">
				<Button
					rounded
					size='small'
					color='info'
					className='margin-bottom-10'
					onClick={(() => AppStore.openModal(modal()))}
				>
					{T(btnText)}
				</Button>
			</div>
			{isMulti ? (
				<CatalogMultiList lists={checkArray} typeList={name} modal={modal} {...rest}/>
			) : (
				<CatalogSingleList list={checkArray} typeList={name} modal={modal} {...rest}/>
			)}
		</Content>
	);
};

export default CatalogList;