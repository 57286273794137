import React from "react";
import List from "react-bulma-components/lib/components/list";
import ListTitleDesktop from "./list-title-desktop";
import EmptyMessage from "./empty-message";
import AddWriteOffRowDesktop from "./add-write-off-row-desktop";
import AddWriteOffRowMobile from "./add-write-off-row-mobile";
import {
	useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const AddWriteOffList = ({data,onlyShow,step, changeQuantity, removeProduct}) => {
	const {isMobileSize} = useIsMobile()
	return (
		<>
			{isMobileSize ? (
				<>
					<List hoverable className="shipments-order-list">
							{!!data?.length ? data.map(item => (
								<AddWriteOffRowMobile
									key={item.id}
									data={item}
									changeQuantity={changeQuantity}
									removeProduct={removeProduct}
									onlyShow={onlyShow}
									step={step}
								/>)
							) : <EmptyMessage />}
					</List>
				</>
			) : (
				<>
					<ListTitleDesktop onlyShow={onlyShow} step={step}/>
					{!!data?.length ? data?.map(item => (
						<AddWriteOffRowDesktop
							key={item.id}
							data={item}
							changeQuantity={changeQuantity}
							removeProduct={removeProduct}
							onlyShow={onlyShow}
							step={step}
						/>)
					) : <EmptyMessage />}
				</>
			)}
		</>
	);
};

export default AddWriteOffList;