import yajax from "yajax";
import { createStore } from "../../tools/reflux-tools";
import Actions from './start-actions';
import T from "components/i18n";

const StartStore = createStore({
	listenables: Actions,
    _prefix: "/userprofile/",
    state: {
        rows: null,
        marketplaces: null,
        isLoaded: false
    },

    get(field) {
        return this.state[field];
    },

    getCategoryName(cat_id) {
        let name = "";
        if (this.state.category) {
            name = T(this.state.category[cat_id]['name']);
        }

        console.log("get name ", name)
        return name;
    }, 

    getProductName(pid) {
        if (!pid) {
            return T('general-expenses');
        }
    },

    getInitialState() {
        return this.state;
    },

    onLoad() {
        yajax.get(this._prefix).then(Actions.load.completed, Actions.load.failed);
    },

    onLoadCompleted(data) {
    	console.log("completed loading expenses", data);

        let result = [];

        for (let i in data['data']) {
            let d = data['data'][i];
            d['category_name'] = this.getCategoryName(d['category_id'])
            d['product_name'] = this.getProductName(d['product_id'])
            d['category_type'] = this.getTypeCategory(d['category_id'])

            result.push(d);
        }

        this.setState({
        	rows: result
        })
    },

    onLoadFailed() {
        console.log("failed load expenses");
    },

    onAddMarketplace(data) {
        yajax.post(
            this._prefix+'marketplaces',
            {
                "name": data.name.value, 
                "seller_id": data.seller_id.value, 
                "mws_token": data.mws_token.value, 
                "marketplace": data.marketplace.value
            },
        ).then(Actions.addMarketplace.completed, Actions.addMarketplace.failed);
    },

    onAddMarketplaceCompleted(data) {
        console.log("completed add martket place", data);
        // Actions.load();
    },

    onAddMarketplaceFailed() {
        console.log("failed add expenses");
    },

    onLoadMarketplaces() {
        if (this.get("marketplaces")) {
            return;
        }

        yajax.get(this._prefix+'marketplaces').then(Actions.loadMarketplaces.completed, Actions.loadMarketplaces.failed);
    },

    onLoadMarketplacesCompleted(data) {
        console.log("completed load marketplaces", data);

        this.setState({
            marketplaces: data
        })
    },

    onLoadMarketplacesFailed() {
        console.log("failed load marketplaces");
    },

});
	

export default StartStore;