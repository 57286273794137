import React, { Component } from "react";
// import { connectToStores } from "tools/reflux-tools";

import { Field, Control, Input, Label, Radio } from 'react-bulma-components/lib/components/form';
import Messages from "components/messages";
import UserAction from 'user/user-actions';
import User from 'user/user-store';
import Icon from 'react-bulma-components/lib/components/icon';

// import InfoBslock from 'components/info-block';
import T from "components/i18n";
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import Button from 'react-bulma-components/lib/components/button';
// import List from 'react-bulma-components/lib/components/list';
import ExpensesStore from 'modules/expenses/expenses-store';
import ProductsActions from 'modules/products/products-actions';


class CurrencyRate extends Component {
     constructor(props) {
        super(props);
        this.state = {
            submit: false,
            rates: User.getCurrencyRates() || {},
            types: {'value': User.getCurrencyType()},
            curr: props.curr || {},
            color: 'warning'
        };

        this.currencies = ExpensesStore.getAccountsCurrencyList();
        this.onChange = this.onChange.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {

        UserAction.updateSettings.completed.listen((data) => {
            if (data.success && this.state.submit) {
                this.setState({
                  "submit": false,
                  "message": T('exchange-rate-success-change'),
                  'color': 'success'
                });

                ProductsActions.load(true);
            } else if(this.state.submit && !data.success) {
                this.setState({
                  "submit": false,
                  "message": T(data.error || 'unhandled_error')
                });           
            }
        });
    }

    submit() {
        this.setState({"submit": true});
        User.setCurrencyRate(this.state.rates, this.state.types.value);

    }

    onChange(id, value) {
          let rates = this.state.rates;

          if (typeof(rates) !== "object") {
            rates = {};
          }
          rates[id] = value;

          this.setState({
            'rates': rates
          });
    }

    onChangeRadio(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
        [name]: {
          'value': value
        }
        });
    }

    getCurrencyLine(id) {
      let rate = 1;
      if (!id) return null;

      if (typeof(this.state.rates[id]) !== "undefined") {
        rate = this.state.rates[id];
      }

      if (rate === 1 && typeof(this.state.curr[id]) !== "undefined") {
        rate = this.state.curr[id].rate;
      }

      return (<div key={"curre-" + id}>
                  <Field >
                        <Label>{User.getCurrencyCode(id)}</Label>
                    <Control iconRight>
                              <Input  size="large" 
                                    name={"currency-"+id}
                                    onChange={(evt) =>{this.onChange(id, evt.target.value)}} 
                                    value={rate}
                                    type='number'
                                    />
                          <Icon align="right">
                            <span className="small-text">{User.getCurrency()}</span>
                          </Icon>
                    </Control>
                  </Field>     
            </div>
        );
    }

    render () {

        let lines = [];

        for (let i in this.currencies) {
            let cur = this.currencies[i];
            if (parseInt(cur['id']) === parseInt(User.get("currency_id"))) {
              continue;
            }
            lines.push(this.getCurrencyLine(cur['id']));
        }
        return (
            <Box >
                <Messages message={this.state.message} color={this.state.color || 'danger'} close={() => this.setState({message: null})} />
                <Heading >{T('exchange-rate')}</Heading>
                <Field>
                  <Label>{T("calc-netprice-rate")}</Label>
                    <Control>
                        <Radio
                            onChange={this.onChangeRadio} 
                            checked={this.state.types.value === '1'} 
                            value="1" 
                            size="large"
                            name="types">
                          {T("global-rate")}
                        </Radio>
                        <Radio 
                            onChange={this.onChangeRadio} 
                            checked={this.state.types.value === '2'}
                            value="2" 
                            size="large"
                            name="types" >
                          {T("shipment-rate")}
                        </Radio>
                    </Control>
                </Field>
                <Box>
                  {lines}
                 </Box>
                <Button.Group position="right">
                  <Button  size="large" rounded color="success"
                    onClick={this.submit}
                    >{T('change-exchange-rates')}</Button>
                </Button.Group>
            </Box>
        )
    }
}


export default CurrencyRate;
