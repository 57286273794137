import React from 'react'
import Button from "react-bulma-components/lib/components/button";
import T from "../../../../../components/i18n";

const ButtonAddAnotherIntegration = ({onClick}) => {
	return (
		<Button.Group position='right'>
			<Button
				rounded
				size='small'
				onClick={onClick}>
				{T('add-another-integration')}
			</Button>
		</Button.Group>
	);
};

export default ButtonAddAnotherIntegration;