// import React from "react";
// import AppStore from "../../../app-store";

const messages = {
	"your-service-9-kz": {
		ru: "Интеграция с Woocommerce, OpenCart",
		en: "Integration with Woocommerce, OpenCart",
		ua: "Інтеграція з Woocommerce, Prom, Rozetka, Хорошоп, OpenCart"
	},
	"your-service-9-desk-kz": {
		ru: "Продаешь свои товары с использованием Woocommerce или OpenCart? Загружай товары, получай заказы, обновляй остатки в одном кабинете ",
		en: "Selling your products on Woocommerce or OpenCart? Upload products, receive orders, update balances in one account",
		ua: "Продаєш свої товари на Woocommerce чи Prom? Завантажуй товари, отримуй замовлення, оновлюй залишки в одному кабінеті"
	},
    "marketplaces-desc-kz": {
        ru: "Принимайте заказы с OpenCart, Woocommerce в одном месте!",
        en: "Take orders from OpenCart, Woocommerce in one place!",
        ua: "Приймайте замовлення з OpenCart, Woocommerce в одному місці!"
    },
    "try-free-last-desk-4-kz": {
        ru: "Получай заказы с Instagram, Facebook, OpenCart, Woocommerce",
        en: "Receive orders from Instagram, Facebook, OpenCart, Woocommerce",
        ua: "Отримуй замовлення з Instagram, Facebook, OpenCart, Woocommerce"
    },
};

export default messages;