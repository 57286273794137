import React, { useRef} from "react";
import T from "components/i18n";
import AppStore from 'app-store';
import UserStore from 'user/user-store';
import Heading from 'react-bulma-components/lib/components/heading';
import Button from 'react-bulma-components/lib/components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PartnersLink = ({setMessage, setMsgColor}) => {
  const encodedUserId = btoa(UserStore.get("realId"));
  const link = AppStore.formatingLink(`/?partner_id=${encodedUserId}`);
  const linkRef = useRef(null);

  const handleCopy = () => {
    if (linkRef.current) {
      linkRef.current.select();
      navigator.clipboard.writeText(link)
        .then(() => {
          setMessage(T("link-copied"));
          setMsgColor('success')
        })
        .catch((error) => {
          setMessage(T("unable-to-copy-link"));
          console.error(error)
        })
    }
  };
  
  return (
    <>
      <Heading>{T('partners-programm')}</Heading>
      <p className='margin-bottom-10'><b>{T('partners-link-desk', { 'var': AppStore.getAppName() })}</b></p>
      <p className='margin-bottom-10'>{T('partners-link-label')}</p>
      <div className='partners-link-thumb'>
        <input
        type="text"
        className='partners-link-input'
        rows="1"
        ref={linkRef}
        value={link}
        readOnly
        />
        <Button onClick={handleCopy} className='partners-link-btn'>
          <FontAwesomeIcon icon={"copy"} />
        </Button>
      </div>
  </>)
}

export default PartnersLink