import React, {Component, useEffect, useState} from "react";
import {
  Field,
  Control,
  Input,
  Label,
} from "react-bulma-components/lib/components/form";
import OpenInsideModal from "components/modal/open-inside-modal";
import UserAction from "user/user-actions";
import { AVAILABLE_LANGS } from "configs/i18n";
import ExpensesStore from "modules/expenses/expenses-store";
import T from "components/i18n";
import AppStore from "app-store";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import Button from "react-bulma-components/lib/components/button";
import Columns from "react-bulma-components/lib/components/columns";
import DisableModules from "./disable-modules";
import ChangePassword from "../change-password";
import CurrencyRate from "../currency-rate";
import { redirectTo } from "tools/redirect-to";
import UserStore from "user/user-store";
import SelectComponent from "../../../tools/select-component";
import { confirmDelete } from "../../../tools/confirm-delete";
import TelegramSubscribe from "../telegram-subscribe/telegram-subscribe";
import AddAccrualSalesUser from "../common/add-accrual-sales";
import SubmitButton from "../../../components/submit-button";
import ErrorMessageControlHook
  from "../../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../../tools/error-handler";
import CustomizeBottomMenu from "./customize-bottom-menu";

const ClearAccount = ({onClose}) => {
  const [password, setPassword] = useState('')
  const [waiting, setWaiting] = useState(false)
  const {handleAutoRemoveError,setErrorMessage,ErrorMessageBlock,errorMessage} = ErrorMessageControlHook()

  const handleSubmit = () => {
    if (!password || password.length < 3) {
      handleAutoRemoveError('enter-passwrod-error')
      return false;
    }

    confirmDelete(() => {
      UserAction.clearAccount(password)
      setWaiting(true)
    });
  }

  const handleChangeInput = ({target: {value}}) => {
    setPassword(value);
  }

  useEffect(() => {
    const listenClearAccount = UserAction.clearAccount.completed.listen(() => {
      if(onClose) {
        onClose()
      }
    })
    const listenClearAccountFail = UserAction.clearAccount.failed.listen((res) => {
      const message = errorHandler(res)

      if(message === T("not-correct-email-or-password")) {
        handleAutoRemoveError('not-correct-password')
      } else {
        handleAutoRemoveError(message)
      }
      setWaiting(false)
    })
    return () => {
      listenClearAccount()
      listenClearAccountFail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
      <Box align="center">
        <Heading color="warning">{T("are-you-sure-to-clear-account")}</Heading>
        <Heading subtitle>{T("are-you-sure-to-clear-account-desk")}</Heading>

        <Field>
          <Control>
            <Label>{T("password-placeholder")}</Label>
            <Input
              size="large"
              name="password"
              type="password"
              placeholder={T("password-placeholder")}
              onChange={handleChangeInput}
              color="primary"
              value={password}
            />
          </Control>
        </Field>

        <Button.Group align="center">
          <Button
            size="large"
            onClick={onClose}
            rounded
            color="primary"
          >
            {T("close")}
          </Button>
          <SubmitButton submit={handleSubmit} waiting={waiting} size="large" fullwidth={false} color="light" text='clear-account'/>
        </Button.Group>
      </Box>
    </>
  )
}


const WHITELABEL = process.env.REACT_APP_WHITELABLE;

class SettingTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      close: false,
      cleaning: false,
      currency_id: {
        value: UserStore.get("currency_id"),
      },
    };
    this.showDisableModules =
      UserStore.isMainUser() ||
      UserStore.getModuleEnable("slip") ||
      UserStore.getModuleEnable("pricetags");
    // this.goToPay = this.goToPay.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.submitChangeCurrencyId = this.submitChangeCurrencyId.bind(this);
  }

  componentDidMount() {
    this.listenerClear = UserAction.clearAccount.completed.listen(
      (response) => {
        this.setState({
          cleaning: true,
        });

        setTimeout(() => {
          AppStore.closeModal();
          this.setState({
            close: true,
          });
        }, 4000);
      }
    );
  }

  componentWillUnmount() {
    if (typeof this.listenerClear == "function") {
      this.listenerClear();
    }
  }

  onChangeSelect(selectedOption, actionMeta) {
    this.setState({
      [actionMeta.name]: selectedOption,
    });
  }

  submitChangeCurrencyId() {
    confirmDelete(
      () => UserStore.setCurrencyAccount(this.state.currency_id.value),
      "change-your-account-currency-title",
      "change-your-account-currency-message",
      "confirm-order-shift",
      "undo"
    );
  }

  render() {
    const curr = ExpensesStore.getAccountsCurrency();

    let langs = [];
    for (let lang in AVAILABLE_LANGS) {
      if (lang === "ru" && AppStore.getWlName() === "navkolo") continue;
      if (lang === "ua" && AppStore.getWlName() === "modeler") continue;

      langs.push(
        <a key={lang} href={"/" + lang + "/src/public"}>
          {lang}
        </a>
      );

      langs.push(<span key={`--${lang}`}> | </span>);
    }

    langs.pop();
    return (
      <div>
        <Columns>
          <Columns.Column>
            <ChangePassword />
            {WHITELABEL === "hugeprofit" && <TelegramSubscribe />}
            <Box className="select-lang">
              <Heading>{T("change-language")}</Heading>
              {langs}
            </Box>
            {UserStore.isMainUser() && <AddAccrualSalesUser/>}
          </Columns.Column>
          <Columns.Column>
            {Object.keys(curr).length > 1 ? <CurrencyRate curr={curr} /> : null}
            <Box>
              <Heading>{T("base-currency")}:</Heading>
              <Field>
                <SelectComponent
                  name="currency_id"
                  onChange={this.onChangeSelect}
                  value={this.state.currency_id}
                  list={UserStore.getCurrencyList}
                />
              </Field>
              <Button
                size="small"
                color="success"
                rounded
                onClick={this.submitChangeCurrencyId}
              >
                {T("change-currency")}
              </Button>
            </Box>
            <Box>
              <Button color='success' onClick={() => AppStore.openModal(<CustomizeBottomMenu/>)}>{T('customizing-bottom-menu')}</Button>
            </Box>
          </Columns.Column>
        </Columns>
        {this.showDisableModules ? (
          <Box>
            <DisableModules />
          </Box>
        ) : null}
        {AppStore.isEnableForWhiteLable("cleaner") && UserStore.isMainUser()  ? (
          <div align="left">
            <OpenInsideModal
              size="small"
              color="warning"
              text={T("clear-account")}
            >
              {this.state.cleaning ? (
                <Heading>{T("cleaning")}</Heading>
              ) : (
                <ClearAccount />
              )}
            </OpenInsideModal>
          </div>
        ) : null}
        {this.state.close ? redirectTo("/dashboard") : ""}
      </div>
    );
  }
}

export default SettingTab;
