import T from "components/i18n";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export default function confirm(title, text, call, ok_button, false_button) {
        confirmAlert({
          title: T(title),
          message: T(text),
          buttons: [
          	{
              label: T(false_button || 'back'),
              onClick: () => {}
            },
            {
              label: T(ok_button || 'add'),
              onClick: () => (typeof(call) === "function" ? call() : null)
            }
          ]
        });
    }