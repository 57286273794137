import React, {useEffect, useState} from 'react'
import s from './styles.module.scss'
import T from '../i18n'

const CopyText = ({text}) => {
	const [timer, setTimer] = useState(null);
	const [copyCompleted, setCopyCompleted] = useState(false)
	const [processesCopy, setProcessesCopy] = useState(false)

	const handleMouseDown = (evt) => {
		setProcessesCopy(true)
		const timeoutId = setTimeout(async () => {
			if (navigator.clipboard) {
				try {
					await navigator.clipboard.writeText(text);
					setCopyCompleted(true)
				} catch (error) {
					console.log('failed copy')
				}
			} else {
				console.log('failed copy')
			}
		}, 500);

		setTimer(timeoutId);
	};

	const handleMouseUp = () => {
		setProcessesCopy(false)
		clearTimeout(timer);
	};

	useEffect(() => {
		let deleteAlertCopyCompleted
		if(copyCompleted) {
			deleteAlertCopyCompleted = setTimeout(() => setCopyCompleted(false),1000)
		}
		return () => {
			clearTimeout(deleteAlertCopyCompleted);
		}
	}, [copyCompleted]);

	return (
		<div className={s.wrapper}>
			{copyCompleted && <span className={s.tooltip}>{T('text-copied')}</span>}
			<p
				className={s.text}
				onClick={evt => evt.stopPropagation()}
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
				onMouseLeave={handleMouseUp}
				onTouchStart={handleMouseDown}
				onTouchEnd={handleMouseUp}
				onTouchCancel={handleMouseUp}
				style={{ transform: processesCopy ? 'scale(1.02)' : "none" }}
			>
				{text}
			</p>
		</div>
	);
};

export default CopyText;