import React from "react";

import OpenModal from 'components/modal/modal';

const OpenModalFactory = (children, close) => {
    return (
    	<OpenModal className={"modal-factory"+(children.props.modal || "")} key={"modal-"+children.type.name + (children?.props?.modal || "")} close={close} onClose={close}  modal={{ closeOnBlur: false }}>
            {children}
      	</OpenModal>
   	);
}
 

export default OpenModalFactory;
