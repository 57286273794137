import React, { Component } from 'react';
import T from 'components/i18n';

import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Image from 'react-bulma-components/lib/components/image';

import {Helmet} from "react-helmet";


class Contacts extends Component {
    render () {
        return (
        	<Box>
                <Helmet>
                    <title>{T('contact-title')}</title>
                    <meta name="description" content={T('contact-desc')}/>
                </Helmet>
                <Heading>{T('contact-link')}</Heading>
               <p>{T('contact-text')}</p>
               <Heading size={4}> 0 800 3311891 </Heading>
               <div className="margin-top-20 margin-botton-10">
               <Button.Group position="centered">
                <a href="https://t.me/navkoloone_bot" className="margin-side-10" target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Telegram_logo.svg" border="0" /></a>
                <a href="https://www.viber.com/navkoloone_bot" target="_blank" rel="noopener noreferrer"><Image size={64}  src="/img/Viber-Icon.svg" border="0" /></a>
               </Button.Group>
               </div>
        	
			</Box>

			)}

}

export default Contacts;
