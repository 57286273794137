import {filterSearch} from "../../tools/filters-helper";

export const applyFilter = (inventory, filter) => {
	let result = true
	const date = new Date(inventory['created_at']);
	filter['date_start'].setHours(0,0,0,0);
	filter['date_end'].setHours(23,59,59,999);

	if (date < filter['date_start'] || date > filter['date_end']) {
		return false;
	}

	if (filter['search'] !== "" && typeof(filter['search']) !== "undefined") {
		result = filterSearch(filter['search'], ['id'], inventory);
	}


	if(filter.mid && filter.mid?.value) {
		result = result && parseFloat(inventory.marketplace_id) === parseFloat(filter.mid.value);
	}

	if(filter.rid && filter.rid?.value) {
		result = result && parseFloat(inventory.responsible_user_id) === parseFloat(filter.rid.value);
	}

	return result
}