import T from "../../../components/i18n";

const tarifData = {
	1: {
		"index": 1,
		"name": "traine",
		"amount": {1: 0, 2: 0, 3: 0},
		"sales": 50 ,
		"products": 300,
		"sort": 1,
		"active": 1,
		"color": 'green',
		"desc": 'tarif-1-desc'
	},
	2: {
		"index": 2,
		"name": "begin",
		"amount": {1: 3.99, 2: 99, 3: 299},
		"sales": 100,
		"products": 1000,
		"sort": 2,
		"active": 0,
	},
	3: {
		"index": 3,
		"name": "standart",
		"amount": {1: 10.99, 2: 299, 3: 899},
		"sales": 1000,
		"products": 5000,
		"sort": 4,
		"active": 0,
	},
	4: {
		"index": 4,
		"name": "pro",
		"amount": {1: 17.99, 2: 599, 3: 1299},
		"sales": 2000,
		"products": 15000,
		"sort": 5,
		"active": 0,
	},
	5: {
		"index": 5,
		"name": "shark",
		"amount": {1: 33.99, 2: 999, 3: 2999},
		"sales": 99999,
		"products": 100000,
		"sort": 6,
		"active": 0,
	},
	6: {
		"index": 6,
		"name": "middle",
		"amount": {1: 6.99, 2: 199, 3: 6.99},
		"sales": 500,
		"products": 3000,
		"sort": 3,
		"active": 0,
	},
	8: {
		"index": 8,
		"name": "surenew",
		"amount": {1: 5.99, 2: 199, 3: 6.99},
		"sales": 200,
		"products": 2000,
		"sort": 1,
		"active": 1,
		"color": 'yellow',
		'desc': 'tarif-8-desc'
	},
	9: {
		"index": 9,
		"name": "pronew",
		"amount": {1: 16.99, 2: 599, 3: 17.99},
		"sales": 1000,
		"products": 10000,
		"sort": 2,
		"active": 1,
		"color": 'orange',
		'popular': true,
		'desc': 'tarif-9-desc'
	},
	10: {
		"index": 10,
		"name": "sharknew",
		"amount": {1: 33.99, 2: 1199, 3: 33.99},
		"sales": T('unlim'),
		"products": 100000,
		"sort": 3,
		"active": 1,
		'color': 'red',
		'desc': 'tarif-10-desc'
	},
}

export default tarifData