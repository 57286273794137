import React from "react";
import {
  Field,
  Label,
  Radio,
} from "react-bulma-components/lib/components/form";
import T from "../i18n";
import InfoWrapper from "../info-wrapper";

const RadioButtonsListControl = ({
  list,
  name,
  label,
  labelComponent,
  valueRadio,
  disabledRadio,
  onChange,
  propsWrapper,
}) => {
  const handleChange = (evt) => {
    const target = evt.target;
    const value = target.value;
    if (onChange) onChange({ name, value: value });
  };

  return (
    <Field {...propsWrapper}>
      {label && <Label>{T(label)}</Label>}
      {labelComponent && <Label>{labelComponent}</Label>}
      {list?.map(({ value, title, size, info, ...rest }) => (
        <Radio
          key={value}
          name={name}
          value={value}
          size={size || "large"}
          checked={value === valueRadio}
          onChange={handleChange}
          disabled={disabledRadio}
          {...rest}
        >

          {info ? <InfoWrapper info={info}>{T(title)}</InfoWrapper> : T(title)}
        </Radio>
      ))}
    </Field>
  );
};

export default RadioButtonsListControl;
