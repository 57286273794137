import React, { useEffect, useState } from "react";
import { Columns } from "react-bulma-components";
import User from "../../../user/user-store";
import SelectComponent from "../../../tools/select-component";
import IntegrationBindingStore from "./integration-binding-store";
import Button from "react-bulma-components/lib/components/button";
import { LocalIcon } from "whitelables/wl-name/icons";
import { confirmDelete } from "../../../tools/confirm-delete";
import IntegrationBindingActions from "./integration-binding-actions";

const ProductRow = ({ data, remoteProducts, iid }) => {
  const [bindingValue, setBindingValue] = useState(false);
  const [isChangeBinding, setIsChangeBinding] = useState(false);
  const [isBinding, setIsBinding] = useState(false);

  const img = "/bimages/get/64-" + User.get("id") + "-" + data.id + "-0.jpg";

  const handleChange = (selectValue) => {
    setBindingValue(selectValue);
    setIsChangeBinding(true);
  };

  useEffect(() => {
    const isBindingProductByPid = IntegrationBindingStore.isBindingProductByPid(
      data.id
    );
    setBindingValue(isBindingProductByPid);
    setIsChangeBinding(false);
    setIsBinding(isBindingProductByPid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteProducts]);

  const handleDeleteBinding = () => {
    IntegrationBindingActions.deleteBinding({
      iid,
      pid: data.id,
      rid: bindingValue.value,
    });
  };

  const handleBinding = () => {
    IntegrationBindingActions.binding({
      iid,
      pid: data.id,
      rid: bindingValue.value,
    });
  };


  return (
    <div style={{ padding: "10px", borderBottom: "1px solid gray" }}>
      <Columns align="center">
        <Columns.Column size="two-fifths" align="left">
          <div className="display-flex-row" style={{ alignItems: "center" }}>
            <img
              src={img}
              alt={img}
              style={{ maxWidth: "64px", width: "100%", minHeight: "41px" }}
            />
            ID: {data.id}. {data.full_name} {data.sku}
          </div>
        </Columns.Column>
        <Columns.Column>
          <div
            className="full-width-field-flex"
            style={{
              display: "flex",
              height: "100%",
              gap: "10px",
              alignItems: "center",
            }}
          >
              <SelectComponent
                placeholder="not-sync-product"
                value={bindingValue}
                readOnly={isBinding}
                onChange={handleChange}
                list={() => remoteProducts}
              />
          </div>
        </Columns.Column>
        <Columns.Column size={1}>
          <div className="display-flex-center">
            {bindingValue && isChangeBinding && (
              <Button
                color="success"
                className="button-icon"
                onClick={handleBinding}
              >
                <LocalIcon icon="circle-success" size="middle" />
              </Button>
            )}
            {bindingValue && !isChangeBinding && (
              <Button
                color="danger"
                className="button-icon"
                onClick={() => confirmDelete(handleDeleteBinding, 'confirm-deletion-synchronization')}
              >
                <LocalIcon icon="circle-close" size="middle" />
              </Button>
            )}
          </div>
        </Columns.Column>
      </Columns>
    </div>
  );
};

export default ProductRow;
