import React from 'react'
import Dropdown from "react-bulma-components/lib/components/dropdown";
import T from '../i18n'
import AppStore from "../../app-store";
import CustomizeColumnsModal from "./modal/customize-columns-modal";

const CustomizeColumns = ({customizeColumns, customizeColumnsActive, columnsName, onReset}) => {

	const handleCustomizeColumns = () => {
		AppStore.openModal(<CustomizeColumnsModal
																	modal='customize-columns'
																	customizeColumnsActive={customizeColumnsActive}
																	customizeColumns={customizeColumns}
																	columnsName={columnsName}
																/>)
	}

	return (
		<Dropdown label={T('columns')} className='dropdown-custom dropdown-small' right={true}>
			<Dropdown.Item value={T('customize-columns')}>
				<div onClick={handleCustomizeColumns} className='customize-item'>
					{T('customize-columns')}
				</div>
			</Dropdown.Item>
			<Dropdown.Item value={T('reset-to-default-columns')}>
				<div onClick={onReset} className='customize-item'>
					{T('reset-to-default-columns')}
				</div>
			</Dropdown.Item>
		</Dropdown>
	);
};

export default CustomizeColumns;