import React from 'react'
import {Button} from "react-bulma-components";
import ClientsStore from "../../modules/clients/clients-store";
import s from './styles.module.scss'
import T from '../i18n'
import {LocalIcon} from 'whitelables/wl-name/icons'
import AppStore from "../../app-store";
import ClientViewModal
	from "../../modules/clients/modal/client-view/client-view-modal";
import ChatsStore from "../../modules/chats/chats-store";
import AddClientModal from "../../modules/clients/modal/add-client-modal/add-client-modal";
import UserStore from "../../user/user-store";


const ClientPopupItem = ({type, clientPhone, callID, internalNumber}) => {
	const client = ClientsStore.getClientByPhone(clientPhone)

	const handleOpenClient = () => {
		if(client.id) {
			AppStore.openModal(<ClientViewModal clientID={client.id}/>)
		} else {
			AppStore.openModal(<AddClientModal phone={clientPhone}/>)
		}
	}

	const handleDeletePopup = (evt) => {
		evt.stopPropagation()
		ChatsStore.deletePopupByCallID(callID)
	}

	let usersInfoByIternalNumber = UserStore.getBinotelUserById(internalNumber)

	if(type === 'ringostat') {
		usersInfoByIternalNumber = UserStore.getRingostatUserById(internalNumber)
	}

	return (
		<div className={s.popup} onClick={handleOpenClient}>
			<div style={{flex: 1}}>
				<p style={{fontSize: 10}}>{T('a-call-from')}</p>
				<h3 className={s.name}>{client ? `${client.last_name} ${client.first_name} ${client.middle_name}` : T('unknown-phone')}</h3>
				<h4 className={s.phone}>{clientPhone}</h4>
				{UserStore.isMainUser() && usersInfoByIternalNumber && <p key={usersInfoByIternalNumber?.internalNumber}>
					<b>{T('operator')}</b>
					<br/>
					{usersInfoByIternalNumber?.department ? `${usersInfoByIternalNumber?.department}:` : ''} {usersInfoByIternalNumber?.name}
				</p>}
			</div>
			<Button className='button-link-icon' onClick={handleOpenClient}>
				{client ? <LocalIcon icon='client-info' size='middle'/> : <LocalIcon icon='client-add' size='middle'/>}
			</Button>
			<span className={s.close} onClick={handleDeletePopup}>
					<LocalIcon icon='close-fill' size='small'/>
			</span>
		</div>
	);
};

export default ClientPopupItem;