import React, {useState} from 'react'
import List from "react-bulma-components/lib/components/list";
import T from '../i18n'
import { LocalIcon } from 'whitelables/wl-name/icons'


const SortItem = ({label, value, defaultActive, onClick}) => {
	const [status, setStatus] = useState(defaultActive === 0 ? 0 : (defaultActive  || 2))

	const handleClick = () => {
		let sortFilter = (status && status !== 2) ? 0 : 1

		if(status === 0) {
			sortFilter = 2
		}

		setStatus(sortFilter)

		onClick && onClick({value, status: sortFilter})
	}

	return (
		<List.Item className='display-flex-row-gap' onClick={handleClick}>
			{T(label)}
			<div
				className='icon-wrapper'
			>
				<LocalIcon
					icon="sort-arrow-up"
					size="small"
					className={`arrow-up ${status === 1  ? 'active' : ''}`}
				/>
				<LocalIcon
					icon="sort-arrow-down"
					size="small"
					className={`arrow-down ${status === 0 ? 'active' : ''}`}
				/>
			</div>
		</List.Item>
	);
};

export default SortItem;