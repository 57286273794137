import { Component } from "react";
import numberFormat from "tools/number-format";
import DOM from "react-dom-factories";
import ExpensesStore from "modules/expenses/expenses-store";

let _pattern = "{amount} {currency}";
let _currencies = {};
let _defaultCurrency = null;

function formatPattern(pattern, props) {
  return pattern.replace(/{([^}]+)}/g, ($0, $1) =>
    props.hasOwnProperty($1) ? props[$1] : $0
  );
}

class Money extends Component {
  static configure = (currencies, defaultCurrency, pattern) => {
    _currencies = currencies;
    _defaultCurrency = defaultCurrency;
    if (pattern) {
      _pattern = pattern;
    }
    _onConfigChange();
  };

  static defaultProps = {
    wrapper: DOM.div,
    pattern: "{amount} {currency}",
    signClassname: false,
    prepend: "",
    precision: 2,
    dot: ".",
    groupBy: 3,
    groupSeparator: " ",
    sign: false,
  };

  // componentDidMount() {
  //     _onConfigChangeAdd(this);
  // }

  // componentWillUnmount() {
  //     _onConfigChangeRemove(this);
  // }

  render() {
    const props = this.props;
    let currency = "",
      is_dept = false;

    if (props.aid) {
      currency = ExpensesStore.getAccountCurrency(props.aid);
      is_dept = ExpensesStore.isDebt(props.aid);
    } else if (props.currency) {
      currency = _currencies[props.currency];
    } else {
      currency = _currencies[_defaultCurrency || ""];
    }

    let value = formatPattern(
      _pattern,
      Object.assign({}, props, {
        amount: numberFormat(props.amount || props.children, props),
        currency: currency,
      })
    );

    if (props.prepend) {
      value = props.prepend + value;
    }

    let className = props.className ? props.className : "";
    if (props.signClassname && props.amount !== 0) {
      className += props.amount > 0 ? " text-success" : " text-danger";
    }

    if (is_dept && !props.offDept) className += " is-debt";

    className += " nowrap";

    const wrapperProps = {
      className,
      onClick: props.onClick,
      title: props.title || null,
    };

    //"data-tooltip": props.tooltip

    return props.wrapper(wrapperProps, value.trim());
  }
}

const _configChangeListeners = [];

function _onConfigChange() {
  for (let i = 0, l = _configChangeListeners.length; i < l; i += 1) {
    _configChangeListeners[i].forceUpdate();
  }
}

// function _onConfigChangeAdd(inst) {
//     _configChangeListeners.push(inst);
// }

// function _onConfigChangeRemove(inst) {
//     const idx = _configChangeListeners.indexOf(inst);
//     if (idx !== -1) {
//         _configChangeListeners.splice(idx, 1);
//     }
// }

export default Money;
