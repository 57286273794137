import React from "react";
import Button from "react-bulma-components/lib/components/button";
import T from "../../../../components/i18n";
import {useHistory} from "react-router-dom";

const ButtonBack = () => {
	const history = useHistory()
	return (
		<Button size="large"
						rounded
						color="light"
						onClick={() => history.go(-1)}
		>
			{T('close')}
		</Button>
	);
};

export default ButtonBack;