import React from "react";
import { Box, Button } from "react-bulma-components";
import AppStore from "../../../../app-store";
import AddCategoryModal from "../../../products/add-category-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmDelete } from "../../../../tools/confirm-delete";
import ProductsActions from "../../../products/products-actions";

const RowMobile = ({ data }) => {
  return (
    <tr>
      <td>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <span style={{ flex: 1 }} className="font-size-110 bold">
            {data.name}
          </span>
          <div className="display-flex-row">
            <Button
              className="button-link-without-style"
              onClick={() =>
                AppStore.openModal(
                  <AddCategoryModal modal="add-category" {...data} />
                )
              }
            >
              <FontAwesomeIcon icon="edit" size="2x" />
            </Button>
            <Button
              className="button-link-without-style"
              onClick={() =>
                confirmDelete(
                  () =>
                    ProductsActions.deleteCatalog("product-category", data.id),
                  "confirm-delete",
                  "are-you-sure-to-delete-this-catalog"
                )
              }
            >
              <FontAwesomeIcon icon="trash-alt" size="2x" />
            </Button>
          </div>
        </Box>
      </td>
    </tr>
  );
};

export default RowMobile;
