import React from 'react';
import BaseFilter from "components/modules/base-filters";
import ShipmentsStore from "../shipments-store";
import Actions from '../shipments-actions';
import SelectComponent from "../../../tools/select-component";
import ExpensesStore from "../../expenses/expenses-store";
import SelectProducts from "../../../components/select-products";

class ShipmentFilters extends BaseFilter {
    constructor(props) {
        super(props);

        this.store = ShipmentsStore;
        this.actions = Actions;
    }

  onChangeSelectProduct(selectedOption) {
    Actions.filter("product", selectedOption);
  }

  productFilter() {
      return <SelectProducts
        onChange={this.onChangeSelectProduct}
        value={ShipmentsStore.getFilter("product")}
        single={true}
        source="shipment"
        create_mid={true}
        quantity={1} />
  }

  supplierFilter() {
      return <SelectComponent
        name="supplier"
        label="suppliers"
        empty="all"
        onChange={this.onChangeSelect}
        value={this.store.getFilter("supplier")}
        list={ShipmentsStore.getSuppliersList}
        creatable={false}/>
    }

    currencyFilter() {
      const list = ExpensesStore.getAccountsCurrencyList()
      return list.length > 1 && (
        <SelectComponent
            name='currency'
            label='shipment-currency'
            empty="all"
            onChange={this.onChangeSelect}
            value={this.store.getFilter("currency")}
            list={() => list}
        />
      )
    }

    render () {
        return (
          <>
            {this.productFilter()}
            {this.selectStatus('shipments-status')}
            {this.getWarehouseFilter('mid')}
            {this.supplierFilter()}
            {this.currencyFilter()}
          </>

          )
     }

}

export default ShipmentFilters;