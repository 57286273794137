import {createStore} from "../../tools/reflux-tools";

const SelectStore = createStore({
	_prefix: '?????',
	state: {
		group_select: {},
	},

	get(field) {
		return this.state[field]
	},

	getListSelect() {
		return this.get('group_select')
	},

	getIsSelectById(id) {
		const groupSelect = this.get('group_select')
		return !!groupSelect[id];
	},

	getIsSelectAll(list, fieldName) {

		if(!list.length) return false


		return list.every(item => this.state.group_select[item[fieldName]])
	},

	getCountSelect() {
		const groupSelect = this.get('group_select')

		return Object.keys(groupSelect).length
	},

	onSelectAll(arrayIDs, checked) {

		const objectFromIDs = arrayIDs.reduce((obj, id) => {
			obj[id] = 1;
			return obj;
		}, {});

		if(checked) {
			this.setState({
				group_select: objectFromIDs,
			})
		} else {
			this.setState({
				group_select: {},
			})
		}

	},

	onSelectGroup(id) {
		const groupSelect = this.get('group_select')

			if (groupSelect[id]) {
				delete groupSelect[id];
				this.setState({
					group_select: {
						...groupSelect
					}
				})
			} else {
				this.setState({
					group_select: {
						...groupSelect,
						[id]: 1,
					},
				});
			}
	},

	clearSelectGroup() {
		this.setState({group_select: {}});
	},
})

export default SelectStore;