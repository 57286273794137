import React from 'react'
import Box from "react-bulma-components/lib/components/box";
import WriteOffInfoTitleBlock from "../UI/write-off-info-title-block";
import AddWriteOffList from "../add-write-off-list/add-write-off-list";
import ButtonBack from "../UI/button-back";
import SubmitButton from "../../../../components/submit-button";
import Button from "react-bulma-components/lib/components/button";
import InfoBlockQuantityAmount from "../../../../components/info-block-quantity-amount";


const StepTwoConfirm = ({productsData, mid, step, allQuantity, amount, onChangeQuantity, onRemove, onSubmit, isLoaded}) => {
	return (
		<Box>
			<WriteOffInfoTitleBlock mid={mid.value}/>
			<AddWriteOffList
				data={productsData}
				changeQuantity={onChangeQuantity}
				removeProduct={onRemove}
				step={step}
			/>
			<Button.Group position="centered" className='margin-top-10'>
				<SubmitButton
					size="large"
					rounded
					color="primary"
					text='write-off-product'
					fullwidth={false}
					waiting={isLoaded}
					submit={onSubmit}/>
				<ButtonBack/>
			</Button.Group>
			<InfoBlockQuantityAmount lengthProduct={productsData?.length} allQuantity={allQuantity} amount={amount} title='total-products'/>
		</Box>
	);
};

export default StepTwoConfirm;