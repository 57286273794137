import { useState } from "react";

const useFilterHook = ({ defaultValues }) => {
  const [filters, setFilters] = useState(defaultValues);
  const handleChangeFilter = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeSearch = ({ name, value }) => {
    if (name) {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleChangeDate = (values) => {
    const daysKey = Object.keys(values);
    daysKey.forEach((name) => {
      handleChangeFilter(name, values[name]);
    });
  };

  const clearFilter = () => {
    setFilters(defaultValues);
  };

  return {
    filters,
    setFilters,
    onChangeFilter: handleChangeFilter,
    onChangeSearch: handleChangeSearch,
    onChangeDate: handleChangeDate,
    onClearFilter: clearFilter,
  };
};

export default useFilterHook;
