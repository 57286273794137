import React, { Component } from 'react';

// import T from "components/i18n";
import InfoImage from "public/common/image";
import Heading from 'react-bulma-components/lib/components/heading';
import Content from 'react-bulma-components/lib/components/content';


class HowStartInfo extends Component {

    render () {
        return (
        		<Content>
        			<Heading>Регистрация</Heading>
        			
<p>Для начала работы с сервисом надо пройти процедуру регистрации. Это можно сделать двумя способами: </p>
<ul>
	<li>через соцсети (Facebook), просто нажав соответствующую иконку в поле регистрации;</li>
	<li>через электронную почту, указав почтовый адрес или номер телефона и пароль.</li>
</ul>
<InfoImage text="Скрин 1. Регистрация" link="/screen/2.jpg" />
<p>После регистрации на вашу почту придет письмо с подтверждением. Если папке “Входящие” письма нет, проверьте “Спам”. Для авторизации в системе надо нажать кнопку “Войти”, 
указанную в письме, а затем ввести e-mail и пароль. 
После авторизации в системе программа сразу открывает аккаунт и можно приступать к работе.</p>
<InfoImage text="Скрин 2. Главная страница сервиса" link="/screen/6.jpg" />

<Heading>Отключение разделов</Heading>
<p>Сразу после регистрации на сайте открывается страница с отключением разделов, если вы не планируете пользоваться какими-то функциями сервиса: </p>
<ul>
<li>учет остатков товаров</li> 
<li>поставки</li> 
<li>склад</li> 
<li>клиенты</li> 
<li>резерв</li> 
</ul>
<InfoImage text="Скрин 3. Отключение не используемых разделов" link="/screen/4.jpg" />

<p>По умолчанию все разделы активированы <br />
Если вы хотите отключить какие-то опции – уберите напротив них галочку. После начала пользования сервером вы в любой момент можете активировать ранее отключенные функции, 
нажав кнопку с изображением шестеренки, зайдя во вкладку “Настройки” и поставив галочки напротив нужных опций. </p>
<p>После завершения отключения разделов, нажимайте Готово и после этого сразу открывается аккаунт и можно приступать к работе.</p>
        		</Content>
			)}

      }

export default HowStartInfo;

// 33, 35, 51, 60, 64, 71,75, 107, 35, 131