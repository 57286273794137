import React, { Component } from "react";
import T from "components/i18n";
import Money from "components/money";
import Columns from 'react-bulma-components/lib/components/columns';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import User from 'user/user-store';

// import ExpensesStore from "./expenses-store";


class ClientsTotal extends Component {
  _sumAmountReport(data) {
      let response = {
        'total_amount': 0,
        'debt': 0,
        'count': 0
      };

      for(let i in data) {
          const client = data[i];
          response['count']++;
          response['total_amount'] += parseFloat(client['total_amount']);
          response['debt'] += parseFloat(client['debt']);
      }

      response['average_check'] = parseFloat(response['total_amount']/response['count'], 2);

      return response;
  }

  render() {
      if (!User.getPermissionEnable("show-cashflow")) return null;

        const 
              {clients} = this.props,
              data = this._sumAmountReport(clients);
              // style = { textAlign: 'center' };

        return (
              <Columns className="statisctic">
                <Columns.Column>
                  <Box>
                    <Heading  heading>
                      {T('clients-count')}
                    </Heading>
                    <Heading >
                       {data['count']}
                    </Heading>
                  </Box>
                </Columns.Column>
                <Columns.Column>
                    <Box>
                      <Heading  heading>
                        {T('clients-total')}
                      </Heading>
                      <Heading >
                        <Money amount={data['total_amount']} />
                      </Heading>
                    </Box>
                </Columns.Column> 
                <Columns.Column>
                    <Box>
                      <Heading  heading>
                        {T('average-check')}
                      </Heading>
                      <Heading >
                        <Money amount={data['average_check']} />
                      </Heading>
                    </Box>
                </Columns.Column> 
                <Columns.Column>
                    <Box>
                      <Heading  heading>
                        {T('debt')}
                      </Heading>
                      <Heading className="has-text-danger">
                        <Money amount={data['debt']*-1} />
                      </Heading>
                    </Box>
                </Columns.Column>
              </Columns>
        );
    }

}


export default ClientsTotal;

