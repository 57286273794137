import { createStore } from "../../../tools/reflux-tools";
import Actions from "./terminal-actions";
import yajax from "yajax";
import UserStore from "../../../user/user-store";
import T from '../../../components/i18n'
import {filterSearch} from "../../../tools/filters-helper";

const terminalCheckboxDTO = (data) => {
  if (data && data.length) {
    return data.map((item) => ({
      name: item?.name,
      model: item?.model,
      type: item?.type,
      id: item?.id,
      connected: item?.connected,
    }));
  } else {
    return [];
  }
};

const TerminalStore = createStore({
  listenables: Actions,
  _prefix: "http://localhost:9020/api",
  _prefix_pos: "/pos",
  _devices: "/devices",
  _device: "/device",
  _register: "/register",
  _connect: "/connect",
  _save_config: "/saveconfig",
  _purchase: "/purchase",
  _prefix_sales_terminal: "/bsales/terminal",
  state: {
    list: null,
    isLoading: false,
    isLoadingReceipts: false,
    isLoadingReceiptById: false,
    receipts: null,
    _filter: {
      date_start: new Date(),
      date_end: new Date(),
      account: {value: 0, label: T('all')},
      rid: {value: 0, label: T('all')},
      search: '',
    },
  },

  get(field) {
    return this.state[field];
  },

  getList() {
    const list = this.get("list");
    if (!list) {
      Actions.load();
      this.setState({
        list: [],
      });
      return [];
    }
    return list;
  },

  clearFilter() {
    this.setState({
      _filter: {
        date_start: new Date(),
        date_end: new Date(),
        search: '',
        account: {value: 0, label: T('all')},
        rid: {value: 0, label: T('all')},
      }
    })
  },

  onFilter(name, value) {
    let filters = {};
    for (let i in this.state["_filter"]) {
      filters[i] = this.state["_filter"][i];
    }

    let date_start = filters["date_start"];

    filters[name] = value;

    this.setState({
      _filter: filters,
    });

    if (name === "date_start" && value < date_start) {
      Actions.loadTerminalReceipt();
    }
  },

  applyFilter(receipt) {
    const filter = this.state._filter;
    const date = new Date(receipt['created_at']);

    let result = true;

    filter['date_start'].setHours(0,0,0,0);
    filter['date_end'].setHours(23,59,59,999);

    if (date < filter['date_start'] || date > filter['date_end']) {
      return false;
    }

    if (filter['search'] !== "" && typeof(filter['search']) !== "undefined") {

      result = filterSearch(filter['search'], ['account_id', 'id', 'order_id', 'rrn'], receipt);
    }

    if (filter['account'] && filter['account']['value'] !== 0) {
      if (parseInt(filter['account']['value']) !== parseInt(receipt['account_id'])) {
        result = result && false;
      }
    }
    if (filter['rid'] && filter['rid']['value'] !== 0) {
      if (parseInt(filter['rid']['value']) !== parseInt(receipt['rid'])) {
        result = result && false;
      }
    }

    return result
  },

  getReceiptList(){
      const list = this.get('receipts')

      return list.filter(this.applyFilter)
  },

  setLinkAccountToTerminal(accountID, terminalID) {
    const listLinkAccount = UserStore.getMoreSetting("linkAccountToTerminal");

    const data = {
      ...listLinkAccount,
      [accountID]: {
        account_id: accountID,
        terminal_id: terminalID,
      },
    };
    UserStore.setMoreSetting(data, "linkAccountToTerminal");
  },

  deleteLinkAccountToTerminalById(accountID) {
    const listLinkAccount = UserStore.getMoreSetting("linkAccountToTerminal");

    if (accountID) {
      delete listLinkAccount[accountID];
    }

    UserStore.setMoreSetting(listLinkAccount, "linkAccountToTerminal");
  },

  getLinkAccountToTerminalById(terminalID) {
    if (!terminalID) return false;
    const linkAccounts = UserStore.getMoreSetting("linkAccountToTerminal");

    const valuesLinkAccount = Object.values(linkAccounts || []);

    return (
      valuesLinkAccount?.find((item) => item.terminal_id === terminalID) || {}
    );
  },

  getTerminalIdByAccountId(accountID) {
    const linkAccounts = UserStore.getMoreSetting("linkAccountToTerminal");

    if (linkAccounts && linkAccounts[accountID]) {
      return linkAccounts[accountID];
    }

    return {};
  },

  isCheckTheBindingAccountToTerminal(accountID) {
    const linkAccounts = UserStore.getMoreSetting("linkAccountToTerminal");

    const getList = this.getList();

    if (getList && getList?.length && linkAccounts && linkAccounts[accountID]) {
      const findAccount = linkAccounts[accountID];
      const findActiveTerminal = getList.find(
        (item) => item.id === findAccount.terminal_id
      );

      if (findActiveTerminal) {
        return linkAccounts[accountID];
      }
    }

    return false;
  },

  onPurchase({ terminalID, amount, merchant_id }) {
    const data = {};
    if (merchant_id) data.merchant_id = merchant_id;

    if (!!amount && !isNaN(amount) && amount > 0) {
      const toFixedTwoAmount = parseFloat(amount).toFixed(2);
      data.amount = toFixedTwoAmount * 100;
    }

    yajax
      .post(
        this._prefix + this._prefix_pos + `/${terminalID}` + this._purchase,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(Actions.purchase.completed, Actions.purchase.failed);
  },
  onPurchaseCompleted(data) {
    console.log(data);
  },
  onPurchaseFailed(res) {
    return res;
  },

  onAddTerminalReceipt(data, account_id) {
    if (data) {
      const result = {
        data: JSON.stringify(data),
        account_id,
      };
      yajax
        .post(this._prefix_sales_terminal, result)
        .then(
          Actions.addTerminalReceipt.completed,
          Actions.addTerminalReceipt.failed
        );
    }
  },
  onAddTerminalReceiptCompleted(data) {
    Actions.loadTerminalReceipt()
    return data;
  },
  onAddTerminalReceiptFailed(res) {
    return res;
  },

  onLoadTerminalReceipt() {
    if (this.state.isLoadingReceipts) return;

    this.setState({
      isLoadingReceipts: true,
    });

    const filter = this.state._filter;

    filter["date_start"].setHours(0, 0, 0, 0);
    filter["date_end"].setHours(23, 59, 59, 999);

    const data = {
      start: parseInt(filter["date_start"].getTime() / 1000),
      end: parseInt(filter["date_end"].getTime() / 1000),
    };

    yajax
      .get(this._prefix_sales_terminal, data)
      .then(
        Actions.loadTerminalReceipt.completed,
        Actions.loadTerminalReceipt.failed
      );
  },
  onLoadTerminalReceiptCompleted({ data }) {
    this.setState({
      receipts: data,
      isLoadingReceipts: false,
    });
  },
  onLoadTerminalReceiptFailed(res) {
    this.setState({
      receipts: [],
      isLoadingReceipts: false,
    });
    return res;
  },

  onLoad() {
    if (this.state.isLoading) return;

    this.setState({
      isLoading: true,
    });
    yajax
      .get(this._prefix + this._devices)
      .then(Actions.load.completed, Actions.load.failed);
  },
  onLoadCompleted(data) {
    this.setState({
      list: terminalCheckboxDTO(data),
      isLoading: false,
    });
  },
  onLoadFailed(res) {
    this.setState({
      isLoading: false,
    });
    return res;
  },

  onLoadReceiptById(receiptId) {
    if(this.state.isLoadingReceiptById) return;

    this.setState({
      isLoadingReceiptById: true
    })

    yajax
      .get(this._prefix_sales_terminal, {id: receiptId})
      .then(
        Actions.loadReceiptById.completed,
        Actions.loadReceiptById.failed
      );
  },

  onLoadReceiptByIdCompleted(data) {
    this.setState({
      isLoadingReceiptById: false
    })
  },
  onLoadReceiptByIdFailed(res) {
    this.setState({
      isLoadingReceiptById: false
    })
  }

});

export default TerminalStore;
