import React from "react";
import Container from "../../common/container/container";
import Box from "react-bulma-components/lib/components/box";
import T from "../../../components/i18n";
import { Checkbox } from "react-bulma-components/lib/components/form";
import OpenModalButton from "../../../components/modal/open-modal-button";
import AppStore from "../../../app-store";
import { checkboxEnable } from "../utils";
import s from "./styles.module.scss";
import Title from "../../common/title/title";
import SubTitle from "../../common/sub-title/sub-title";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const QuizBlock = ({ title, subTitle, footerTitle, questionList }) => {
  const {isMobileSize} = useIsMobile()
  return (
    <Container>
      <Box renderAs='section' className="home-question-block">
        <Title>
          {T(title)}
        </Title>
        <SubTitle>
          {T(subTitle)}
        </SubTitle>
        <ol className="gradient-list todo-cmp__list">
          {questionList.map((item, index) => (
            <li key={`question-${index}`}>
              <Checkbox onChange={checkboxEnable} name={item.name}>
                <span>{T(item.title)}</span>
              </Checkbox>
            </li>
          ))}
        </ol>
        <p className={s.footer_title}>{T(footerTitle)}</p>
        <OpenModalButton
          link={AppStore.getLink("registration")}
          text={T("start-accounting-sales")}
          size={isMobileSize ? "medium" : "large"}
          color="success"
        />
      </Box>
    </Container>
  );
};

export default QuizBlock;
