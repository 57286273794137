// import React from "react";
// import axios from 'axios';

// import T from "components/i18n";

// import moment from "moment";
import { createStore } from "../../tools/reflux-tools";
import Actions from './light-actions';
import ProductStore from './products-store';
import LightActions from "./light-actions";
import WarehouseStore from "../warehouse/warehouse-store";

// import AppStore from '../../app-store';
// import { filterSearch } from 'tools/filters-helper';


const LightStore = createStore({
	listenables: Actions,
	state: {
		group_change: {},
		count: 0,
	},

    getInitialState() {
        return this.state;
    },

    get(field) {
        return this.state[field];
    },

    isGroupSettings() {
    	return this.state.count > 0
    },

    getProductGroupCheckbox(pid) {
            return !!this.state.group_change[pid] || false
    },

    getProductsIdsWithMid(disabledType) {
        let d = this.get("group_change");

        let pids = []
        for (let i in d) {

            let j = i.split(",")

          if(disabledType) {
            const product = ProductStore.getProduct(j[0])
            if(product && parseInt(product.type_product) === parseInt(disabledType)) {
              continue;
            }
          }

            pids.push(j[0]+"_"+j[1]);
        }

        return pids;
    },

    getProductsIdsWithMidForDelete() {
      const list = this.get("group_change");
      const keys = Object.keys(list || {})

      return keys.reduce((acc, key) => {
        const keySplit = key.split(",");
        const pid = keySplit[0];
        const mid = keySplit[1];

        const variable = ProductStore.getIdsProductVariable(pid)

        if(!variable || variable?.length === 1) {
          acc.push(`${pid}_${mid}`);
        }

        return acc
      }, [])
    },

    getProductsSelectOptions() {
        let pids = this.getProductsIdsWithMid();

        const options = [];
        for (let pid of pids) {
            options.push({'value': pid, 'label': 123})
        }
        return {"select_product": options}
    },

    getProductsIds() {
        let d = this.get("group_change");

        let pids = []
        for (let i in d) {
            let j = i.split(",")
            pids.push(j[0]);
        }

        return pids;
    },

    clearSelectGroup() {
        return this.setState({group_change: {}, "count": 0, productsCount: 0});
    },

    getCheckedParentChecked(childrenPid) {
      if(childrenPid.length) {
        return childrenPid.every(item => this.getProductGroupCheckbox(item))
      }
    },

    getIsChecked() {
      if(!this.state.productsCount) return false
      return !(this.state.count < this.state.productsCount)
    },

    onSetVariableProductsGroup(identsVariable, value) {
        identsVariable.forEach(ident => {
          const splitIdent = ident.split(',')
          const pid = splitIdent[0]
          const mid = splitIdent[1]
          LightActions.setProductGroup([pid, mid], value)
        })
    },


    onSetProductGroup(pid, status) { 
        let group = this.state.group_change;
      const productState = ProductStore.getProductNew()
        if (pid === "all") {
            if (status) {
              productState.forEach((data) => {
                if(Array.isArray(data)) {
                  data.forEach(item => {
                    Object.values(item.stock).forEach(stock => {
                      group[[stock['product_id'], stock['marketplace_id']]] = 1
                    })
                  })
                } else {
                  Object.values(data.stock).forEach(stock => {
                    group[[stock['product_id'], stock['marketplace_id']]] = 1
                  })
                }
                });
            } else {
                    group = {};
            }

        } else {
            if (status) {
                group[pid] = 1;
            } else {
                delete group[pid];
            }
        }

        const productsCount = productState.reduce((acc,cur) => {
          if(Array.isArray(cur)) {
            acc += cur.length
          } else {
            acc += 1
          }
          return acc
        }, 0)

        return this.setState({group_change: group, "count": Object.keys(group).length, productsCount: productsCount});
    },

    getSelectGroupProductWithoutDropAndServicesProduct() {
      const product = this.get('group_change')
        const getKeys = Object.keys(product)

        const filter = getKeys.filter(item => {
            const splitItem = item.split(',')
            const pid = splitItem[0]
            const mid = splitItem[1]

            return !ProductStore.isServicesProduct(pid) && !WarehouseStore.isDropshipping(mid) && !ProductStore.isBundleProduct(pid)
        })

        return filter.reduce((acc, cur) => {
            acc[cur] = 1;
            return acc
        }, {})
    },

    getProductsIdsWithMidWithoutDropAndServicesAndBundle() {
      let d = this.getSelectGroupProductWithoutDropAndServicesProduct()

      let pids = []
      for (let i in d) {
        let j = i.split(",")
        pids.push(j[0]+"_"+j[1]);
      }

      return pids;
    },

  getProductsSelectOptionsWithoutDropAndServicesAndBundle() {
    let pids = this.getProductsIdsWithMidWithoutDropAndServicesAndBundle();

    const options = [];
    for (let pid of pids) {
      options.push({'value': pid, 'label': 123})
    }
    return {"select_product": options}
  },

    getCountWithoutDropAndServicesProduct() {
        const getSelectWithoutDropAndServices = this.getSelectGroupProductWithoutDropAndServicesProduct()

        return Object.keys(getSelectWithoutDropAndServices)?.length
    }
});

export default LightStore;
