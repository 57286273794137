import React from "react";
import s from "./styles.module.scss";
import T from "../../../components/i18n";
import { Link } from "react-router-dom";
import AppStore from "../../../app-store";
import Container from "../../common/container/container";
import AppBlock from "../../common/app-block/app-block";

const HeroBlock = ({video, android, ios}) => {
  return (
    <Container classNameWrapper={s.wrapper} classNameContainer={s.section}>
      <section className={s.section_info}>
        <h1 className={s.title}>{T("hero-title")}</h1>
        <h3 className={s.sub_title}>{T("hero-sub-title")}</h3>

        <Link to={AppStore.getLink("registration")} className={s.link}>
          {T("try-free")}
        </Link>
      </section>
      <section className={s.section_video}>
        <video autoPlay muted loop controls={false} className={s.video}>
          <source src={video} type="video/mp4" />
        </video>
        <AppBlock android={android} ios={ios}/>
      </section>
    </Container>
  );
};

export default HeroBlock;
