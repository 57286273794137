import React from 'react'
import T from "../i18n";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import Button from "react-bulma-components/lib/components/button";
import {useIsMobile} from "../is-mobile-context/is-mobile-context";

const ModalItsOK = ({title,additionalMessage, onClose, children}) => {
	const {isMobileSize} = useIsMobile()
	return (
		<Box align="center">
			<div className="modal-icon">
				<img src="/img/icons/ok.png" alt="print-icon" width="66" border="0"/>
			</div>

			<Heading>{T(title)}</Heading>
			{additionalMessage && <Heading size={6}>{additionalMessage}</Heading>}
			<Button
				size={isMobileSize ? "small": "medium"}
				className="margin-bottom-10"
				fullwidth={true}
				rounded
				color="primary"
				onClick={onClose}
			>
				{T('close')}
			</Button>
			{children}
		</Box>
	);
};

export default ModalItsOK;