import React, { useEffect } from "react";
import { connectToStores } from "../../tools/reflux-tools";
import SalesStore from "./sales-store";
import ClientsStore from "../clients/clients-store";
import SelectStore from "./select-store";
import SaleReportList from "./common/sale-report-list";
import FilterPanel from "./common/filter-panel";
import AddStatusModal from "./add-status-modal";
import DynamicTabList from "../../components/dynamic-tab-list/dynamic-tab-list";
import { useLocation } from "react-router-dom";
import SalesActions from "./sales-actions";
import ClientsActions from "../clients/clients-actions";
import UserActions from "../../user/user-actions";
import Actions from "./sales-actions";
import SaleRefundReportList from "./common/sale-refund-reports-list";
import WarehouseStore from "../warehouse/warehouse-store";
import SalesStatistics from "./common/sales-statistics";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";

const Sales = () => {
  const {isMobileSize} = useIsMobile()
  const location = useLocation()

  const printElem = (elem) => {
    let mywindow = window.open("", "PRINT", "width=300,height=1000");
    mywindow.document.write(elem);
    // mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(() => mywindow.print(), 1000);
    return true;
  };

  const pathname = location.pathname;
  const splitPathname = pathname.split("/");

  let defaultTab = splitPathname[2];
  if (defaultTab === "reserv") {
    defaultTab = "reserve_sale";
  } else if (defaultTab === "refund-list") {
    defaultTab = "refund";
  }

  useEffect(() => {
    SalesActions.load(false, defaultTab === "saled");
    SalesActions.loadReports();
    ClientsActions.load();
    UserActions.getSubUsers();

    const fiscal = Actions.getPrintFiscalCheck.completed.listen((res) => {
      printElem(res.response);
    });

    const fiscal_failed = Actions.getPrintFiscalCheck.failed.listen((res) => {
      printElem(res.response);
    });

    SalesActions.deliveredKeeperDate();

    return () => {
      fiscal();
      fiscal_failed();
      SalesStore.clearFilter(true);
      SelectStore.clearSelectGroup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCheckboxSelectAll = (evt) => {
    const salesOIDs = SalesStore.getSales().map((item) => item?.oid);
    SelectStore.onSelectAll(salesOIDs, evt.target.checked);
  };



  useEffect(() => {
    const pathname = location.pathname;
    const splitPathname = pathname.split("/");
    const activeTab = splitPathname[2];
    let status = activeTab;

    if (activeTab === "delivering") {
      status = ["delivering", "arrived", "on-delivering"];
    } else if (activeTab === "reserv") {
      status = "reserve_sale";
    } else if (activeTab === "refund-list") {
      status = "refund";
    }
    if (status) {
      SalesActions.filter("status", status, activeTab === "saled");
    } else {
      SalesActions.filter("status", "all");
    }

    if (location.state?.search) {
      SalesActions.filter("search", location.state.search);
    }
  }, [location]);

  return (
    <div className="sales-page">
      {isMobileSize && (
        <SalesStatistics
          isSaleTab={defaultTab === "saled"}
          data={
            defaultTab === "returns"
              ? SalesStore.getRefund()
              : SalesStore.getSales()
          }
          tab={defaultTab}
        />
      )}
      <DynamicTabList
        list={SalesStore.getTabList()}
        settingModal={AddStatusModal}
        propsSettingModal={{ defaultType: "sales", showDeleteBtn: true }}
        addModal={AddStatusModal}
        propsAddModal={{ defaultType: "sales" }}
        defaultTab={defaultTab}
      />
      {!isMobileSize && (
        <>
          <FilterPanel onChangeSelectAllCheckbox={onChangeCheckboxSelectAll} isRefund={defaultTab === "returns"}/>
          <SalesStatistics
            isSaleTab={defaultTab === "saled"}
            data={
              defaultTab === "returns"
                ? SalesStore.getRefund()
                : SalesStore.getSales()
            }
            tab={defaultTab}
          />
        </>
      )}
      {defaultTab !== "returns" && (
        <SaleReportList onChangeSelectAllCheckbox={onChangeCheckboxSelectAll} />
      )}
      {defaultTab === "returns" && (
        <SaleRefundReportList
          onChangeSelectAllCheckbox={onChangeCheckboxSelectAll}
        />
      )}
    </div>
  );
};

export default connectToStores(Sales, {
  _: SalesStore,
  client: ClientsStore,
  select: SelectStore,
  warehouse: WarehouseStore,
});
