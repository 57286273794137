import React, { Component } from "react";
import PropTypes from "prop-types";
import T from "components/i18n";
import Spinner from "templates/spinner";
import Table from 'react-bulma-components/lib/components/table';
import { 
    Field, 
    Checkbox, 
    Control 
} from 'react-bulma-components/lib/components/form';
import User from 'user/user-store';

// import List from 'react-bulma-components/lib/components/list';

import Actions from "modules/products/light-actions";
// import moment from "moment";
import { LocalIcon } from 'whitelables/wl-name/icons'
import ProgressBar from "../progress-bar";
import {withIsMobileSize} from "../is-mobile-context/is-mobile-context";

// function _toUserTimeZone(date) {
//     return moment(date).utcOffset(User.getTimeZone()).format("YYYYMMDD");
// }

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "select_all": false,
            "sort": {}
        }

      this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    }

    onChangeCheckbox(evt) { 
        const value = evt.target.checked,
              name = evt.target.name

        Actions.setProductGroup("all", value);

        this.setState({
          [name]: value
        });
    }

    static contextTypes = {
        addScrollListener: PropTypes.func,
        removeScrollListener: PropTypes.func
    };

    onScroll = ({ scrollPos, scrollSize, clientSize }) => {
        const { date, slice, isAllSliceLoaded, isLoading } = this.props;

        if (date || isLoading || isAllSliceLoaded) {
            return;
        }

        if (((scrollSize - scrollPos) / clientSize) < 1.5) {
            this.props.load({ slice: slice + 1 });
        }
    };

    // componentDidMount() {
    //     this.context.addScrollListener(this.onScroll);
    // }

    // componentWillUnmount() {
    //     this.context.removeScrollListener(this.onScroll);
    // }

    renderEmpty() {
        // const date = this.props.filter.date;
        const Message = this.props.emptyMessage,
             columns = this.props.columns;
              // date = "08.01.2020";
              //_toUserTimeZone(date) === _toUserTimeZone()
        return (
            <tr>
                <td colSpan={columns.length} align="center">{(true) ? <Message /> : T('not-found-records')} </td>
            </tr>
        );
    } 

    renderLoader(spinner) {
        let columns = this.props.columns;

        return (
            <tr key="loader">
                <td colSpan={columns.length} align="center">{(spinner) ? spinner : <Spinner />} </td>
            </tr>
        );
    }

    renderContent(components) {
        const { isLoading, rows } = this.props;
        const { rowsWrapper, empty } = components;

        if (!rows || !rows.length) {
            return empty || this.renderEmpty(components);
        }

        const clonedRows = rows.map((row, i) => React.cloneElement(components.row, {
            key: i + 1,
            num: i + 1,
            data: row
        }));

        if (isLoading) {
            // clonedRows.push(this.renderLoader(spinner));
        }

        return rowsWrapper ? React.cloneElement(rowsWrapper, {}, clonedRows) : clonedRows;
    }

    getTitle(idx, title) {
        const { isSelectAll, selectAllChange, selectAllValue } = this.props;

        //sorting
        // if (sorting && idx !== 0) {
        //     return (
        //        <Link to="#" onClick={() => ProductsActions.setSort(title)} title={T(title)}>
        //           <FontAwesomeIcon icon="sort" size="1x"/> {T(title, {'curr': User.getCurrency()})}
        //       </Link>
        //     );
        // }

        if (!isSelectAll || idx !== 0) return T(title, {'curr': User.getCurrency()});

        return (
            <>
            <div className="align-left">
            <Field>
                <Control>
                    <Checkbox 
                          name="select_all" 
                          onChange={selectAllChange || this.onChangeCheckbox}
                          checked={selectAllValue || this.state.select_all}
                        />
                </Control>
            </Field>
            </div>
            <span>
                {T(title, {'curr': User.getCurrency()})}
            </span>
            </>
            );
    }

    handleSort(value, onChangeSortActions) {
        let sortFilter = (this.state.sort[value] && this.state.sort[value] !== 2) ? 0 : 1

        if(this.state.sort[value] === 0) {
            sortFilter = 2
        }

        this.setState({
            sort: {[value]: sortFilter}
        })
        const refactoringValue = value === 'date' ? 'created_at' : value

        if(onChangeSortActions) onChangeSortActions({[refactoringValue]: sortFilter})
    }

    renderHeader(_columns, isSortingColumns, sortActions) {
        
       // return (
       //       <List hoverable>
       //          <List.Item>
       //              {_columns.map((title, idx) => (<th key={idx}>{T(title)}</th>))}
       //          </List.Item>
       //      </List>
       //  );

        if (this.props.isMobileSize) {
            return (
                <thead></thead>
            )
        }

        return (
            <thead>
                <tr>
                  {_columns.map((title, idx) => (<th key={idx}>
                      {this.getTitle(idx, title)}
                      {!!isSortingColumns?.length && isSortingColumns[idx] && (
                        <div
                          className='icon-wrapper'
                          onClick={() => this.handleSort(title, sortActions)}
                        >
                            <LocalIcon icon="sort-arrow-up" size="small" className={`arrow-up ${this.state.sort[title] === 1  ? 'active' : ''}` }/>
                            <LocalIcon icon="sort-arrow-down" size="small" className={`arrow-down ${this.state.sort[title] === 0 ? 'active' : ''}`}/>
                        </div>)}

                  </th>))}
                </tr>
            </thead>
        );
    }

    renderFooter(_columns) {
        const { rows } = this.props;

        if (this.props.isMobileSize) {
            return (
                <tfoot></tfoot>
                )
        }

        if (!rows || !rows.length || rows.length < 10) { 
            return null;
        }

        return (
            <tfoot>
                <tr>
                  {_columns.map((title, idx) => (<th  key={idx}>{T(title)}</th>))}
                </tr>
            </tfoot>
        );
    }


    render() {
        const components = {};
        const { children, columns,isSortingColumns, sortActions, isLoading, minWidthList } = this.props;

        React.Children.forEach(children, (child) => {
            components[child.props.role] = child;
        });

        return (
          <>
              {isLoading && <ProgressBar/>}
            <Table style={this.props.isMobileSize ? {} :{minWidth: minWidthList || 0}}>
                {this.renderHeader(columns, isSortingColumns, sortActions)}
                <tbody>
                    {this.renderContent(components)}
                </tbody>  
                {/*this.renderFooter(columns)*/}
            </Table>
          </>
        );
    }
}

export default withIsMobileSize(Reports);
