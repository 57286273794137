import React from 'react'
import s from './styles.module.scss'

const Title = ({children, color}) => {
	return (
		<h2 className={`${s.title} ${color ? s[color] : s.main}`}>
			{children}
		</h2>
	);
};

export default Title;