import React from "react";
import {Button, Dropdown} from "react-bulma-components";
import NotificationItem from "./notification-item";
import { connectToStores } from "../../../tools/reflux-tools";
import NotificationStore from "../notification-store";
import s from "./styles.module.scss";
import T from "../../../components/i18n";
import NotificationIcon from "../common/notification-icon";
import usePaginationHook from "../../../tools/pagination-hook";
import NotificationActions from "../notification-actions";

const NotificationDropdown = ({ icon, marginTop }) => {

  const list = NotificationStore.get('messages')
  const { start, end, page, total, setPage, PaginationBlock } =
    usePaginationHook({ listLength: list?.length });

  const notificationList = list?.length ? (
    list
      .slice(start, end)
      .map((item) => <NotificationItem key={item.id} {...item} />)
  ) : (
    <Dropdown.Item value={T("not-have-notification")}>
      <div className={s.default}>{T("not-have-notification")}</div>
    </Dropdown.Item>
  );

  const listRead = list.filter(item => !item.is_read)

  const handleReadAllMessage = () => {
    list.forEach(item => {
      if(item.color === "news" && !NotificationStore.getReedNew(item.id)) {
        NotificationStore.setReadNew(item.id, true);
      }
    })

    const withoutNews = listRead.filter(item => item.color !== 'news')

    if(withoutNews.length) {
      NotificationActions.readMessage(withoutNews.map(item => item.id));
    } else {
      NotificationActions.loadMessages()
    }

    NotificationStore.removeAllFrontNotification()
  }

  return (
    <Dropdown
      label={icon || <NotificationIcon list={list}/>}
      className="disabled-default max-height-300-scroll-dropdown"
      style={{marginTop: marginTop || '-6px'}}
      right
    >
      {notificationList}
      <div className='display-flex-center' style={{
        padding: '5px',
        position: 'sticky',
        bottom: '-8px',
        backgroundColor: 'inherit',
        flexDirection: 'column'
      }}>

        <PaginationBlock
          totalPage={total}
          setPage={setPage}
          currentPage={page}
          showPrevNext={false}
          listStyle={{justifyContent: 'center'}}
          style={{margin: 0}}
        />
        {!!listRead.length && <div className='display-flex-row'
              style={{justifyContent: 'flex-end', width: '100%'}}>
          <Button className='button-link-without-style'
                  onClick={handleReadAllMessage}>{T('read-all')}</Button>
        </div>}
      </div>
    </Dropdown>
  );
};

export default connectToStores(NotificationDropdown, {_: NotificationStore});
