import React from "react";
import BaseFilterLayout from "../../../../components/base-filter-layout";
import TerminalStore from "../terminal-store";
import TerminalActions from "../terminal-actions";
import ReceiptFilter from "./receipt-filter";

const FilterPanel = () => {
  const handleChangeDate = (value) => {
    const daysKey = Object.keys(value);
    daysKey.forEach((item) => {
      TerminalActions.filter(item, value[item]);
    });
  };

  const handleChange = ({name, value}) => {
    if(name) {
      TerminalActions.filter(name, value);
    }
  }

  const handleRefresh = () => {
    TerminalStore.clearFilter()
    TerminalActions.loadTerminalReceipt()
  }

  return (
    <BaseFilterLayout
			searchStringSetting={{
        onChange: handleChange,
        defaultValue: TerminalStore.getFilter("search")
			}}
      refresh={{
        onChange: handleRefresh,
        isLoading: TerminalStore.get("isLoadingReceipts")
      }}
      dateRangeSetting={{
        onChange: handleChangeDate,
        date_start: TerminalStore.getFilter("date_start"),
        date_end: TerminalStore.getFilter("date_end"),
      }}
      hiddenFilter={<ReceiptFilter/>}
    />
  );
};

export default FilterPanel;
