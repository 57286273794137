import {createStore} from "../../tools/reflux-tools";
import Actions from "./select-product-actions";
import T from "../i18n";
import ProductsStore from "../../modules/products/products-store";
import WarehouseStore from "../../modules/warehouse/warehouse-store";
import UserStore from "../../user/user-store";
import {filterSearch} from "../../tools/filters-helper";
import User from "../../user/user-store";
import ExpirationDateTag from "../expiration-date-tag/expiration-date-tag";
import React from "react";
import TypeProductTag from "../type-product-tag/type-product-tag";

const CLEAR_FILTER = () => {
	return {
	status: "all",
	category: { value: 0, label: T('all-categories') },
	shipment: { value: 0, label: T('all')},
	brand: { value: 0, label: T('all-brands') },
	mid: { value: 0, label: T('all') },
	instock: window.localStorage.getItem("select-instock") || "0",
	withoutbarcode: false,
	withoutprice: false,
	search: "",
	margin_from: "",
	margin_to: "",
	price_from: "",
	price_to: "",
	sort: {},
	attr: {},
	reserv: { value: 0, label: T('all') },
	integration: { value: 0, label: T('all') },
}}

const SelectProductStore = createStore({
	listenables: Actions,
	_prefix: '/bproducts/',
	state: {
		isScanActive: false,
		_filter:CLEAR_FILTER()
	},

	get(field) {
		return this.state[field]
	},

	toggleIsActiveScan() {
		this.setState({isScanActive: !this.state.isScanActive});
	},

	clearFilter() {
		this.setState({
			_filter: CLEAR_FILTER()
		})
	},

	onFilter(name, value) {
		let filters = {};

		for (let i in this.state['_filter']) {
			filters[i] = this.state['_filter'][i];
		}

		if(name === 'instock') {
			window.localStorage.setItem("select-instock", value)
		}

		filters[name] = value;

		this.setState({
			_filter: filters
		});
	},

	clearMid(product, source) {
			const filter = this.get('_filter')

			const copyProduct = JSON.parse(JSON.stringify(product))

			const stock = Object.values(copyProduct.stock)

			let result = true

			const disabledServicesInSource = ['shipment', 'inventory', 'moving', 'production']

			if(source && disabledServicesInSource.includes(source) && (product.type_product === 4 || product.type_product === 3)) {
				result = result && false
			}

			if(source === "shipment") {
				const values  = Object.entries(copyProduct.stock)
				if(!!values.length) {
					copyProduct.stock = {[values[0][0]]: values[0][1]};
				} else {
					result = false
				}
			} else {
				if(filter.mid.value) {
					if(copyProduct.stock[filter.mid.value]) {
						copyProduct.stock = {[filter.mid.value]: copyProduct.stock[filter.mid.value]};
					} else {
						result = result && false
					}
				}
			}

			if (filter['instock'] !== "0") {
				stock.forEach(item => {
					if(filter['instock'] === '1' && parseFloat(item.instock) <= 0 && !WarehouseStore.isDropshipping(item.marketplace_id) && !ProductsStore.isServicesProduct(item.product_id)) {
						delete copyProduct.stock[item.marketplace_id]
					} else if(filter['instock'] === '2' && (parseFloat(item.instock) > 0 || WarehouseStore.isDropshipping(item.marketplace_id) || ProductsStore.isServicesProduct(item.product_id))) {
						delete copyProduct.stock[item.marketplace_id]
					}
				})
			}

			if(!Object.keys(copyProduct.stock).length) {
				result = result && false
			}

			if(result) {
				return copyProduct
			}

			return false
	},

	applyFilter(product, search) {
		const filter = this.get('_filter')

		let sku = [];
		for (let i in product['stock']) {
			let stock = product['stock'][i];
			sku.push(stock['sku'])
		}

		product['sku'] = sku.join(" ");
		let result = true
		if(search) {
			result = filterSearch(search, ['id', 'name', 'brand', 'asin', 'asin2', 'sku', 'af', 'attr_dict'], product);
		}

		let categoryFilter = filter['category']['value'];
		if (categoryFilter !== 0) {
			let childs_category = ProductsStore.get("categoryChild")[categoryFilter]
			if (typeof(childs_category) === "undefined") {
				childs_category = [categoryFilter];
			}
			result = result && (childs_category.indexOf(product['category_id']) > -1);
		}

		if (filter['brand']['value'] !== 0) {
			result = result && (parseInt(filter['brand']['value']) === product['brand_id']);
		}

		return result
	},


	getProductList(search, source) {
		const dictList = ProductsStore.get('dictProduct')
		const list = Object.values(dictList || {}).reverse()


		return list.reduce((acc, cur) => {
			const withMidFilter = this.clearMid(cur, source)
			if(!withMidFilter) return acc
		const withFilter = this.applyFilter(withMidFilter, search)

		if(!withFilter) return acc

		acc.push(withMidFilter)
			return acc
		}, [])

	},

	generateSelectOptions(list, single) {
		const regenerateList = list.reduce((acc, cur) => {
			let name = cur.name

			if(cur.parent_id) {
				    let attr = ProductsStore.buildAttr(JSON.parse(cur['attr_desc']), true);
				    name += ". "+attr.join(". ");
			}

			for (let j in cur['stock']) {
				const stock = cur['stock'][j];
				const brand = (cur['brand'] && cur['brand'] !== "") ? cur['brand'] + ". " : ""
				const sku = (stock['sku'] && stock['sku'] !== "") ? ". " + stock['sku'] : ""
				const price = (stock['price'] || 0) + " " + User.getCurrency();
				let count = ProductsStore.getStockUnits(stock['instock'], cur['units'])

				if (WarehouseStore.isDropshipping(stock.marketplace_id) || ProductsStore.isServicesProduct(stock.product_id)) {
					count = ProductsStore.getStockUnits('∞', cur['units'])
				}

				let warehouse_name = ""
				if (WarehouseStore.isNeedName()) {
					warehouse_name = T(WarehouseStore.getWarehouseName(stock['marketplace_id'])) + ". ";
				}

				let name_string;
				const services = <TypeProductTag typeProduct={cur.type_product}
																						 short/>
				const exp_date = <ExpirationDateTag pid={cur.id} short/>
				if (single) {
					name_string = <>{exp_date} {services} {cur['id']}. {brand + name + sku}. {UserStore.getPermissionEnable('salesprice') ? price : ''}</>
				} else {

					name_string = (<div
						className='mySelect'>{exp_date} {services} {cur['id']}. {brand}{name}{sku}.<br/><b>{warehouse_name} {count}. {UserStore.getPermissionEnable('salesprice') ? price : ''}</b>
					</div>);
				}

				acc.push({ value: ProductsStore.getIdent(cur['id'],stock['marketplace_id']), label: name_string });

			}
			return acc
		}, [])

		return regenerateList;
	}

})

export default SelectProductStore;
