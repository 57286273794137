import React from "react";
import InfoButton from "../../../components/info-button";
import AppStore from "../../../app-store";
import OpenInsideModal from "../../../components/modal/open-inside-modal";
import T from "../../../components/i18n";
import AddProductions from "../add-production";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const ActionButton = () => {
  const {isMobileSize} = useIsMobile()
  return (
    <div
      className="display-flex-row margin-bottom-10 margin-top-10 top-button"
      style={{ justifyContent: "space-between" }}
    >
      <InfoButton
        color="warning"
        text="how-use-production"
        url={AppStore.getDocsLink("production")}
      />
      <OpenInsideModal text={T("create-production")} icon="plus-circle" size={isMobileSize ? 'small' : 'medium'}>
        <AddProductions />
      </OpenInsideModal>
    </div>
  );
};

export default ActionButton;
