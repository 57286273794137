import React, { useEffect, useState } from "react";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import T from "../../../../components/i18n";
import Loader from "react-loader-spinner";
import { Heading } from "react-bulma-components";
import TerminalActions from "../terminal-actions";
import ErrorMessageControlHook from "../../../../components/error-message-block/error-message-control.hook";
import { errorHandler } from "../../../../tools/error-handler";
import ReceiptViewContent from "./receipt-view-content";

const ReceiptViewModal = ({ receiptID, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [receiptInfo, setReceiptInfo] = useState(null);
  const {
    handleAutoRemoveError,
    errorMessage,
    setErrorMessage,
    ErrorMessageBlock,
  } = ErrorMessageControlHook();

  useEffect(() => {
    TerminalActions.loadReceiptById(receiptID);

    const loadReceiptByIdCompl =
      TerminalActions.loadReceiptById.completed.listen((data) => {
        if (data && data?.data && data?.data?.length) {
          const receipt = data.data.find((item) => `${item.id}` === `${receiptID}`);
          setReceiptInfo(receipt);
        }
        setIsLoading(false);
      });

    const loadReceiptByIdFail = TerminalActions.loadReceiptById.failed.listen(
      (res) => {
        const error = errorHandler(res);
        setIsLoading(false);
        handleAutoRemoveError(error);
      }
    );
    return () => {
      loadReceiptByIdCompl();
      loadReceiptByIdFail();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading>
          {T("detail-info-receipt")}: #{receiptID}
        </Heading>
        {isLoading && (
          <div align="center">
            <Loader type="Rings" color="#00BFFF" height={80} width={80} />
          </div>
        )}
				{!isLoading && (!!receiptInfo ? <ReceiptViewContent response={receiptInfo.response}/> : <Heading subtitle>{T('fail-find-receipt')}</Heading>) }
        <div align="right">
          <Button onClick={onClose}>{T("close")}</Button>
        </div>
      </Box>
    </>
  );
};

export default ReceiptViewModal;
