import Reflux from 'reflux';

const ClientsActions = Reflux.createActions({

    load: { asyncResult: true },
    add: { asyncResult: true },
    filter: { sync: true },
    addClient: { asyncResult: true },
    deleteClient: { asyncResult: true },
    deleteDelivery: { asyncResult: true },
    importClients: { asyncResult: true },
    addDeliveryService: { asyncResult: true },
    loadCity: { asyncResult: true },
    loadDelivery: { asyncResult: true },
    loadPostoffice: { asyncResult: true },
    addCity: { asyncResult: true },
    setPage: { sync: true },
    setCountPerPage: { sync: true },
    addClientsTags: {asyncResult: true},
    deleteClientsTag: {asyncResult: true},
    addToClientsTags: {asyncResult: true},
    loadClientsTags: { asyncResult: true },
    loadDiscounts: {asyncResult: true},
    setDiscountSystem: { asyncResult: true },
    loadByCids: { asyncResult: true },
    loadClientsCount: { asyncResult: true },
    loadNewPostoffice: { asyncResult: true },
    // recalcDiscounts: {asyncResult: true},
});


export default ClientsActions;