import React from 'react'
import SelectComponent from "../../../../tools/select-component";
import ExpensesStore from "../../../expenses/expenses-store";
import ExpensesActions from "../../../expenses/expenses-actions";
import TerminalActions from "../terminal-actions";
import TerminalStore from "../terminal-store";
import WarehouseActions from "../../../warehouse/warehouse-actions";
import UserStore from "../../../../user/user-store";

const ReceiptFilter = () => {
	const handleChangeSelect = (selectedOption, actionMeta) => {
			TerminalActions.filter(actionMeta.name, selectedOption);
	}

	return (
		<>
			<SelectComponent
				name="account"
				label="account"
				empty="all"
				onChange={handleChangeSelect}
				value={TerminalStore.getFilter("account")}
				list={() => ExpensesStore.getAccountsList(3)}
				load={ExpensesActions.loadAccounts}
				creatable={false}
			/>
			<SelectComponent
				name="rid"
				label="manager"
				empty="all"
				onChange={handleChangeSelect}
				value={TerminalStore.getFilter("rid")}
				list={UserStore.getSubUsersList}
				load={WarehouseActions.load}
				creatable={false}/>
		</>
	);
};

export default ReceiptFilter;