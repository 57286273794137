import React from 'react'
import {Button} from "react-bulma-components";
import AppStore from "../../../../app-store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import T from "../../../../components/i18n";
import Reports from "../../../../components/reports";
import EmptyMessage from "../common/empty-message";
import usePaginationHook from "../../../../tools/pagination-hook";
import UserStore from "../../../../user/user-store";
import RowMobile from "./row-mobile";
import RowDesktop from "./row-desktop";
import AddAdditionalField from "../../modal/add-additional-field";
import {
	useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const COLUMNS = ["name", "reports-actions"];

const ProductAddFields = () => {
	const {isMobileSize} = useIsMobile()
	const getAddField = UserStore.getAdditionalFieldsList('product');
	const refactoringField = getAddField.map(item => ({id: item, name: item}))

	const { start, end, page, total, setPage, PaginationBlock } =
		usePaginationHook({ listLength: refactoringField?.length, perPage: 50 });

	return (
		<>
			<div align="right" className="margin-top-10 margin-bottom-10">
				<Button
					color="info"
					onClick={() => AppStore.openModal(<AddAdditionalField type='product' disabledRadio/>)}
					className="display-flex-row"
				>
					<FontAwesomeIcon icon="plus-circle" />
					{T("add-additional-field-btn")}
				</Button>
			</div>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
			/>
			<Reports
				emptyMessage={EmptyMessage}
				rows={refactoringField?.slice(start, end) || []}
				columns={COLUMNS}
			>
				{isMobileSize ? <RowMobile role="row" /> : <RowDesktop role="row" />}
			</Reports>
			<PaginationBlock
				totalPage={total}
				setPage={setPage}
				currentPage={page}
				showPrevNext={false}
			/>
		</>
	);
};

export default ProductAddFields;