import React from "react";
import T from "../i18n";
import s from "./styles.module.scss";

const SelectItem = ({ name, id, value, onClick }) => {

  const handleOnDown = (evt) => {
    evt.stopPropagation();
    onClick({ name, id })
  }

  return (
    <div
      className={`${s.select_item} ${value === id ? s.active : ""}`}
      onClick={() => onClick({ name, id })}
      onMouseDown={handleOnDown}
      title={T(name)}
    >
      {T(name)}
    </div>
  );
};

export default SelectItem;
